.convert-label-container {
    position: absolute;
    left: auto;
    right: $ts-product-label-position-right-small;
    top: $ts-product-label-position-top-small;
    z-index: 3;
    display: block;
    font-weight: $ts-product-label-font-weight;
    line-height: normal;
    width: map-get($ts-product-label-size, small);

    @if ($ts-product-label-view == square) {
        height: map-get($ts-product-label-size, small) !important;
    } @else {
        height: map-get($ts-product-label-size-height, small) !important;
    }

    @include breakpoint($ts-product-label-small-from) {
        width: map-get($ts-product-label-size, $ts-product-label-small-from);
        top: $ts-product-label-position-top !important;
        right: $ts-product-label-position-right !important;

        @if ($ts-product-label-view == square) {
            @if map-has-key($ts-product-label-size, $ts-product-label-small-from) {
                height: map-get($ts-product-label-size, $ts-product-label-small-from) !important;
            }
        } @else {
            @if map-has-key($ts-product-label-size-height, $ts-product-label-small-from) {
                height: map-get($ts-product-label-size-height, $ts-product-label-small-from) !important;
            }
        }
    }

    .convert-label {
        width: 100%;
        height: 100%;
        border-radius: $ts-product-label-radius;
        text-align: center;
        font-family: $ts-product-label-font-family;
        line-height: map-get($ts-product-label-size-height, small);

        @include breakpoint($ts-product-label-small-from) {
            line-height: map-get($ts-product-label-size-height, $ts-product-label-small-from);
        }

        &-discount {
            background: $ts-product-label-background-color;
            font-size: map-get($ts-product-label-font-size, small);

            @include breakpoint($ts-product-label-small-from) {
                font-size: map-get($ts-product-label-font-size, $ts-product-label-small-from);
            }

            .convert-label-text {
                color: $ts-product-label-color;

                b,
                strong {
                    font-size: map-get($ts-product-label-font-size, small);
                    font-family: $ts-product-label-new-number-font-family;
                    font-weight: $ts-product-label-new-number-font-weight;

                    @include breakpoint($ts-product-label-small-from) {
                        font-size: map-get($ts-product-label-font-size, $ts-product-label-small-from);
                    }

                    .checkout-cart-index .cart-container &,
                    .courses-list &,
                    .table-order-items &,
                    .form-wishlist-items &,
                    .add-to-cart-popup & {
                        font-size: $ts-product-label-checkout-font-size;
                    }
                }
                span {
                    display: none;
                    font-size: $ts-product-label-new-notice-font-size;
                    font-family: $ts-product-label-new-notice-font-family;
                    font-weight: $ts-product-label-new-notice-font-weight;
                    color: $ts-product-label-new-notice-color;
                    margin: .5rem auto 0;
                    max-width: 75%;
                    white-space: nowrap;
                    text-transform: $ts-product-label-new-notice-text-transform;

                    @if $ts-product-label-text-dash {
                        padding-top: .5rem;
                        margin-top: .3rem;
                        max-width: 96%;
                        border-top: $ts-product-label-text-dash-color;
                    }
                }
                @include breakpoint($ts-product-label-small-from) {
                    @if map-has-key($ts-product-label-size, xlarge-new) {
                        width: map-get($ts-product-label-size, xlarge-new) !important;
                    }
                    @if map-has-key($ts-product-label-font-size, xlarge-new) {
                        font-size: map-get($ts-product-label-font-size, xlarge-new);
                    }


                    @if ($ts-product-label-view == square) {
                        @if map-has-key($ts-product-label-size, xlarge-new) {
                            height: map-get($ts-product-label-size, xlarge-new) !important;
                        }
                    } @else {
                        @if map-has-key($ts-product-label-size-height, xlarge-new) {
                            height: map-get($ts-product-label-size-height, xlarge-new) !important;
                        }
                    }

                    span {
                        @if $ts-product-label-new-notice-text-show {
                            display: block;
                        }
                    }
                }
            }
        }
        &-is_new {
            text-transform: $ts-product-label-new-text-transform;
            background: $ts-product-label-new-background-color;
            font-size: map-get($ts-product-label-new-number-font-size, small);

            @include breakpoint($ts-product-label-small-from) {
                font-size: map-get($ts-product-label-new-number-font-size, $ts-product-label-small-from);

                @if map-has-key($ts-product-label-new-number-font-size, xlarge-new) {
                    font-size: map-get($ts-product-label-new-number-font-size, xlarge-new);
                }
            }

            .convert-label-text {
                color: $ts-product-label-new-text-color;
            }
        }
    }
}

.product.media {
    .convert-label-container {
        z-index: 2;
        top: $ts-product-label-position-top-detail !important;

        @if type-of($ts-product-label-position-right-detail) == 'map' {
            right: map-get($ts-product-label-position-right-detail, small) !important;

            @include breakpoint(medium) {
                right: map-get($ts-product-label-position-right-detail, medium) !important;
            }
        } @else {
            right: $ts-product-label-position-right-detail !important;
        }
    }
}
