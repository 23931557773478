@if $ts-algolia--version > 2 {
    .#{$ts-algolia-list--selector}-list,
    .#{$ts-algolia-infinite-list--selector}-list {
        @include lsn;
    }
}

.product-items,
.#{$ts-algolia-list--selector},
.#{$ts-algolia-infinite-list--selector} {
    @include lsn;
    @include grid-row;

    @if not($ts-product-item-divider) and not($ts-product-item-collapse-grid) {
        @include get-gutter((-1*$ts-product-item-gap-size),'margin-left');
        @include get-gutter((-1*$ts-product-item-gap-size),'margin-right');
    }

    @if $ts-product-item-collapse-grid {
        @include get-gutter(-$ts-product-item-collapse-grid-product-spacing-coeff,'margin-left');
        @include get-gutter(-$ts-product-item-collapse-grid-product-spacing-coeff,'margin-right');
    }

    &.widget-product-grid,
    &.bestseller-products-grid,
    &.widget-new-grid,
    &.items {
        @if $ts-product-item-collapse-grid {
            @include breakpoint(large) {
                @include get-gutter((-1*$ts-product-item-collapse-grid-product-spacing-coeff),'margin-left');
                @include get-gutter((-1*$ts-product-item-collapse-grid-product-spacing-coeff),'margin-right');
            }
            @include breakpoint(xlarge) {
                @include get-gutter((-1*$ts-product-item-collapse-grid-product-spacing-coeff),'margin-left');
                @include get-gutter((-1*$ts-product-item-collapse-grid-product-spacing-coeff),'margin-right');
            }
        }
    }

    &.widget-product-grid,
    &.bestseller-products-grid,
    &.widget-new-grid,
    &.items {

        .algolia-right-container & {

            @if $ts-product-item-collapse-grid {
                @include get-gutter((-1*$ts-product-item-collapse-grid-product-spacing-coeff),'margin-left');
                @include get-gutter((-1*$ts-product-item-collapse-grid-product-spacing-coeff),'margin-right');

                @include breakpoint(xlarge) {
                    @include get-gutter((-1*$ts-product-item-collapse-grid-product-spacing-coeff),'margin-left');
                    @include get-gutter((-1*$ts-product-item-collapse-grid-product-spacing-coeff),'margin-right');
                }
            }

        }

        .page-layout-2columns-left & {

            @if $ts-product-item-collapse-grid {
                @include get-gutter((-1*$ts-product-item-collapse-grid-product-spacing-coeff),'margin-left');
                @include get-gutter((-1*$ts-product-item-collapse-grid-product-spacing-coeff),'margin-right');
            }

        }
    }

    @include breakpoint(xlarge) {
        @if $ts-product-item-divider {
            margin-left: auto;
            margin-right: auto;
        } @elseif not($ts-product-item-divider) and not($ts-product-item-collapse-grid) {
            @include get-gutter((-1*$ts-product-item-gap-size),'margin-left');
            @include get-gutter((-1*$ts-product-item-gap-size),'margin-right');
        } @else {
            @include grid-row-nest;
        }
    }

    .block-wishlist &,
    .block-reorder &,
    .block-compare & {
        @include grid-row-nest;
    }

    .product-item,
    .#{$ts-algolia-item--selector},
    .#{$ts-algolia-infinite-item--selector} {
        @include grid-col;
        @if $ts-product-item-divider {
            @include grid-col-size(6);
        } @elseif $ts-product-item-collapse-grid {
            @include grid-col-size(6);
        }
        @include grid-column-end;
        position: relative;
        text-align: $ts-product-item-text-align;
        @include get-gutter(.5,'padding-top');
        @include get-gutter(.5,'padding-bottom');
        transition: box-shadow .3s ease;

        @if not($ts-product-item-divider) and not($ts-product-item-collapse-grid) {
            @include get-gutter($ts-product-item-gap-size,'padding-top');
            @include get-gutter($ts-product-item-gap-size,'padding-bottom');
        }


        @if not($ts-product-item-divider) and not($ts-product-item-collapse-grid) and ($ts-product-item-mobile-two-inline) {
            @include breakpoint(large down) {
                @include grid-col-size(6);
                @include get-gutter(.25,'padding-left');
                @include get-gutter(.25,'padding-right');
            }
        }

        &:nth-child(2n+1) {
            clear: both;

            &:before {
                border-right: 0;
            }
        }

        .product-item-info {
            min-height: 100%;
            position: relative;
            background-color: $ts-product-item-bg;
            z-index: 1 !important;

            @each $tmp-mquery, $tmp-properties in $ts-product-item-inner-indent {
                @include breakpoint($tmp-mquery) {
                    padding: #{$tmp-properties};
                } // @media
            } // @each

            @if $ts-product-item-divider {
                position: static;
            }

        }// .product-item-info

        .amasty-label-container {
            z-index: 2 !important;
        }// .amasty-label-container

        .product-item-actions {
            a {
                text-decoration: none;
            }

            button.action {
                margin-bottom: 0;

                .no-touchevents & {
                    opacity: 0;
                    position: relative;
                    z-index: -1;
                    transition: opacity .3s ease;
                }
                &:focus,
                &:active {
                    .no-touchevents & {
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }

            .actions-secondary {
                display: none;
            }
        }// .product-item-actions

        .no-touchevents & {
            &:hover,
            &:focus {
                box-shadow: $ts-product-item-hover-shadow;
                background-color: $ts-product-item-hover-bg;

                & + .product-item:before,
                & + .#{$ts-algolia-item--selector}:before,
                & + .#{$ts-algolia-infinite-item--selector}:before {
                    border-right: 0 none!important;
                }

                .product-item-actions button.action {
                    opacity: 1;
                    z-index: 1;
                }

                .product-price-stock .stock {
                    @if ($ts-product-item-stock__show--hover) {
                        opacity: 1;
                        z-index: 1;
                    }
                }

            }// &:hover, focus
        }

        @if $ts-product-item-hide-btn {
            .product-item-actions {
                display: none;
            }
        }//@if $ts-product-item-hide-btn

        @if $ts-product-item-stock__hide {
            .product-price-stock .stock {
                display: none;
            }
        }


        // PM-retail 103, visibility is configured by admin panel
        // .swatch-attribute {
        //  display: none;
        // }

        @if $ts-product-item-divider {
            &:before {
                content: "";
                position: absolute;
                @include get-gutter(.5,'top');
                @include get-gutter(.5,'bottom');
                left: 0;
                width: 0;
                border-right: $ts-product-item-divider-width $ts-product-item-divider-style $ts-product-item-divider-color;
            }

            @include breakpoint(xlarge) {
                &:before {
                    @include get-gutter(1.1,'top');
                    @include get-gutter(1.1,'bottom');
                }

                &:nth-child(2n+1) {
                    clear: none;

                    &:before {
                        border-right: $ts-product-item-divider-width $ts-product-item-divider-style $ts-product-item-divider-color;
                    }
                }

                &:nth-child(4n+1) {
                    clear: left;

                    &:before {
                        border-right: 0;
                    }
                }

                .catalog-category-view &,
                .page-layout-2columns-left &,
                .algolia-right-container & {

                    @if ($ts-product-item-qty-line == 4) {
                        @include grid-col-size(3);
                        &:nth-child(2n+1) {
                            clear: none;
                        }
                        &:nth-child(4n+1) {
                            clear: left;
                        }
                    } @else {
                        @include grid-col-size(4);
                        &:nth-child(2n+1) {
                            clear: none;
                        }
                        &:nth-child(4n+1) {
                            clear: none;

                            &:before {
                                border-right: $ts-product-item-divider-width $ts-product-item-divider-style $ts-product-item-divider-color;
                            }
                        }
                        &:nth-child(3n+1) {
                            clear: left;

                            &:before {
                                border-right: 0;
                            }
                        }
                    }
                }
            }

            .no-touchevents & {
                &:hover,
                &:focus {
                    &:before {
                        border-right: 0 none!important;
                    }
                }
            }
        }// @if $ts-product-item-divider

        @if $ts-product-item-collapse-grid {
            z-index: 1;
            @include get-gutter-shadow($ts-product-item-collapse-grid-product-spacing-coeff, 'box-shadow',transparent);
            @include get-gutter($ts-product-item-collapse-grid-product-spacing-coeff,'padding');

            .no-touchevents & {
                &:hover,
                &:focus {
                    z-index: 2;
                    @include get-gutter-shadow($ts-product-item-collapse-grid-product-spacing-coeff, 'box-shadow',$ts-product-item-hover-bg,$ts-product-item-hover-shadow);
                }
            }
        }// @if $ts-product-item-collapse-grid


        .product-image-container {
            max-width: 100%;
            @if $ts-product-item-image-container-width {
                width: $ts-product-item-image-container-width;
            }
            margin-left: auto;
            margin-right: auto;
            display: block;

            .product-image-wrapper {
                display: block;
                padding-bottom: 0 !important;
                font-size: 0;

                &:after {
                    display: inline-block;
                    vertical-align: middle;
                    content: '';
                    margin-right: -0.03em;
                    width: 0;
                    height: 100%;
                }

                img {
                    max-width: 99%;
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            @if $ts-product-item-absolute-image {
                position: relative !important;
                padding: 0 0 $ts-product-item-absolute-image-ratio;
                height: 0;

                .product-image-wrapper,
                .product-image-fade-item {
                    position: absolute !important;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: auto;
                    max-height: inherit;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            } @else {
                height: 100%;
                position: static !important;

                &[style="height: auto;"] {
                    .product-image-wrapper {
                        max-height: rem-calc(175);
                        @include breakpoint(large) {
                            max-height: rem-calc(280);
                        }
                        @include breakpoint(xxlarge) {
                            max-height: rem-calc(400);
                        }
                    }
                }

                .product-image-wrapper {
                    height: 100%;
                }
            }

            @if ($ts-product-item-collapse-grid) or ($ts-product-item-divider) {
                position: relative !important;
            }

            @if $ts-product-photo-overlay {
                @extend %product-layer-overlay;
            }

            .amasty-label-container {
                opacity: 0;
                &:last-child {
                    opacity: 1;
                }
            }
        }// .product-image-container

        .product-item-photo {
            display: block !important;
            text-align: center;
            text-decoration: none;
            position: static !important;

            @include breakpoint(medium) {
                margin-bottom: $ts-product-item-photo-bottom-indent;
            }

            @if $ts-product-item-divider {
                position: relative !important;
            }

            img {
                width: auto;
                max-width: 100%;
            }
        }// .product-item-photo

        .product-item-details {
            text-align: $ts-product-item-info-align;
            position: relative;
            z-index: 1;

            @if ($ts-product-item-nameprice-align == 'block') {
                display: flex;
                flex-direction: column;
            } @elseif ($ts-product-item-nameprice-align == 'inline') {
                @include grid-row;
                @include grid-row-nest;
            }

            @include breakpoint(medium) {
                padding-left: $ts-product-item-info-indent;
                padding-right: $ts-product-item-info-indent;
            }

            .product-item-manufacturer {
                display: block;
                text-transform: $ts-product-brand-text-transform;
                color: $ts-product-brand-color;
                font-size: $ts-product-brand-font-size;
                font-weight: $ts-product-brand-font-weight;
                line-height: $ts-product-brand-line-height;
                @include header-font-family($ts-product-brand-font-family);
                padding: $ts-product-brand-padding;

                &:empty {
                    display: none;
                }
            }

            .product-item-inner,
            .product-item-actions {
                margin-top: auto;

                @include breakpoint(medium down) {
                    @include at;
                }
            }

            em {
                font-style: normal;
            }
        }// .product-item-details

        .product-item-name {
            display: block;
            overflow: hidden;
            font-size: map-get($ts-product-item-product-name-font-size, small);
            font-weight: map-get($ts-product-item-product-name-font-weight, small);
            line-height: map-get($ts-product-item-product-name-line-height, small);
            text-transform: $ts-product-item-product-name-text-transform;
            color: $ts-product-item-product-name-color;
            margin: rem-calc(5) $ts-product-item-product-name-margin;
            @include header-font-family($ts-product-item-product-name-font-family);

            @include breakpoint(large) {
                margin: $ts-product-item-product-name-margin;
            }

            @include breakpoint(large) {
                font-size: map-get($ts-product-item-product-name-font-size, large);
                font-weight: map-get($ts-product-item-product-name-font-weight, large);
                line-height: map-get($ts-product-item-product-name-line-height, large);
            }

            @include breakpoint(xlarge) {
                font-size: map-get($ts-product-item-product-name-font-size, xlarge);
                font-weight: map-get($ts-product-item-product-name-font-weight, xlarge);
                line-height: map-get($ts-product-item-product-name-line-height, xlarge);
            }

            @include breakpoint(xxlarge) {
                font-size: map-get($ts-product-item-product-name-font-size, xxlarge);
                line-height: map-get($ts-product-item-product-name-line-height, xxlarge);
            }

            @if ($ts-product-item-nameprice-align == 'inline') {
                @include grid-col;
                @include grid-col-size(8);

                @include breakpoint(xlarge only) {
                    @include grid-col-size(12);
                    margin-bottom: 1rem;
                }
            }

            a {
                text-decoration: none;
                color: $ts-product-item-product-name-color;
            }
        } // .product-item-name

        .product-price-stock {
            @include clearfix;

            @if ($ts-product-item-nameprice-align == 'block') {
                display: flex;
                align-items: flex-end;
                flex-flow: row wrap;

                @if ($ts-product-item-info-align == 'center') {
                    align-items: center;
                }

                @if ($ts-product-item-stock-price__layout == vertical) {
                    flex-direction: column;
                    align-items: flex-start;

                    @if ($ts-product-item-info-align == 'center') {
                        align-items: center;
                    }
                }

            } @elseif ($ts-product-item-nameprice-align == 'inline') {
                @include grid-col;
                @include grid-col-size(4);
                text-align: right;
                @include get-gutter(0, 'padding-left');

                @include breakpoint(xlarge only) {
                    @include grid-col-size(12);
                }

                .price-box {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-flow: row-reverse wrap;
                    .special-price {
                        margin-right: 0;
                        width: 100%;
                        margin-bottom: .5rem;
                    }
                    .old-price {
                        line-height: 1;
                    }
                }
            }

            @if not ($ts-product-item-hide-btn) {
                margin-bottom: .75rem;
            }

            .product-attr-list {
                flex-basis: 100%;
            }

            @include breakpoint(large) {
                @if ($ts-product-item-hide-btn == false) {
                    margin-bottom: 1.25rem;
                }
            }


            .price-box {
                margin-bottom: 0;
                position: relative;
                // min-height: rem-calc(39);

                @if ($ts-product-item-nameprice-align == 'block') {
                    @if ($ts-product-item-stock-price__layout == horizontal) {
                        flex-basis: 60%;
                        width: 60%;
                    }

                    @if $ts-product-item-stock-over {
                        flex-basis: 100%;
                        width: 100%;
                    }
                }

                + .stock {
                    @if ($ts-product-item-nameprice-align == 'block') {
                        @if ($ts-product-item-stock-price__layout == horizontal) {
                            flex-basis: 35%;
                            width: 35%;
                        }
                    }

                    @include breakpoint(medium down) {
                        @include at;
                    }
                }

                @include breakpoint(medium) {
                    float: left;
                }
            }

            .stock {
                color: $success-color;
                @include header-font-family($header-font-family);
                font-weight: $ts-product-item-product-stock-weight;
                font-size: $ts-product-item-product-stock__font-size;
                margin-top: .5rem;
                @if not ($ts-product-item-product-stock__line-height) {
                line-height: map-get($ts-product-item-product-price-font-size, small);
                }

                @if ($ts-product-item-nameprice-align == 'block') {
                    flex-basis: 100%;
                }

                &.backorder {
                    color: rgba($body-font-color,.8);

                    span {
                        padding-left: 1.5rem;

                        @include breakpoint(xlarge only) {
                            font-size: rem-calc(13);
                            padding-top: 1px;
                        }

                        @include breakpoint(xxlarge only) {
                            font-size: rem-calc(15);
                        }
                    }
                }

                @include breakpoint(medium) {
                    float: right;
                    margin-top: 0;
                    text-align: right;
                }

                .no-touchevents & {
                    @if ($ts-product-item-stock__show--hover) {
                        opacity: 0;
                        transition: opacity .3s ease;
                    }
                }

                span {
                    display: inline-block;
                    vertical-align: middle;
                    padding: 0 0 0 1.1rem;
                    line-height: 1;
                }

                @include breakpoint(xlarge) {
                    line-height: map-get($ts-product-item-product-price-font-size, xlarge);
                }

                @include breakpoint(xxlarge) {
                    line-height: map-get($ts-product-item-product-price-font-size, xxlarge);
                }
            }

            .mva_label {
                color: $ts-product-detail-old-price-color;
                font-size: ($ts-product-detail-old-price-font-size * .75);
                font-weight: $ts-product-detail-old-price-font-weight;
                letter-spacing: normal;
            }

            .special-price {
                .mva_label {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    margin-top: -.25rem;
                }
            }
        }// .product-price-stock

        .price-box {
            line-height: 1;
            padding-bottom: 0;
            margin: 0 0 1.25rem;
            white-space: normal;

            .price-label {
                @include at;
            }

            > .price-container,
            .special-price {
                font-size: map-get($ts-product-item-product-price-font-size, small);
                @include header-font-family($ts-product-item-product-price-font-family);
                font-weight: $ts-product-item-product-price-font-weight;
                color: $ts-product-item-product-price-font-color;
                line-height: 1;

                @include breakpoint(xlarge) {
                    font-size: map-get($ts-product-item-product-price-font-size, xlarge);
                }

                @include breakpoint(xxlarge) {
                    font-size: map-get($ts-product-item-product-price-font-size, xxlarge);
                }
            }

            .special-price {
                color: $ts-product-item-product-price-special-color;
                line-height: 1;
                display: inline-block;
                vertical-align: sub;
                margin-right: $ts-product-item-product-price-special-right-spacing;
                padding: $ts-product-item-product-price-special-spacing;
                background: $ts-product-item-product-price-special-background;
            }


            .old-price,
            .from-txt {
                text-decoration:$ts-product-item-product-price-old-text-decoration;
                color: $ts-product-item-product-price-old-color;
                margin-left: 0;
                font-weight: $ts-product-item-product-price-old-font-weight;
                @include header-font-family($body-font-family);
                line-height: map-get($ts-product-item-product-price-font-size, small);

                @include breakpoint(xlarge) {
                    line-height: map-get($ts-product-item-product-price-font-size, xlarge);
                }

                @include breakpoint(xxlarge) {
                    line-height: map-get($ts-product-item-product-price-font-size, xxlarge);
                }

                .price {
                    font-size: ($ts-product-item-product-price-old-font-size*.85);
                    font-family: $ts-product-item-product-price-old-font-family;

                    @include breakpoint(large) {
                        font-size: $ts-product-item-product-price-old-font-size;
                    }
                } // .price
            } // .old-price

            .from-txt {
                @if ($ts-product-item-product-price-fra-show == false ) {
                    display: none;
                }
                color: $ts-product-item-product-price-font-color;
                text-decoration: none;
                font-size: $ts-product-item-product-price-old-font-size;
            }

            .price {
                white-space: nowrap;

                @if ( $ts-product-item-product-price-currency-show == false ) {
                    .currency {
                        @include element-invisible;
                    } // .currency
                } // @if
            } // .price
        } // .price-box

        .product-attr-list {
            display: none;
            @include lsn;
            color: $ts-product-item-product-attr-color;
            font-size: map-get($ts-product-item-product-attr-font-size, small);
            line-height: $ts-product-item-product-attr-line-height;
            margin-bottom: 1.4rem;

            &:empty {
                display: none;
            }

            @include breakpoint(large) {
                display: block;
            }

            @include breakpoint(xlarge) {
                font-size: map-get($ts-product-item-product-attr-font-size, xlarge);
            }


            > li {
                position: relative;
                padding: 0 0 .25em 1.3em;

                &:after {
                    display: block;
                    top: 0;
                    color: $ts-product-item-product-attr-dot-color;
                }
            }
        } // .product-attr-list

        .product-reviews-summary {
            display: none;
        }// product-reviews-summary

        .comment-box,
        .box-tocart {
            .label {
                span {
                    @include label-colon;
                }
            }
        }// .box-tocart and .comment-box

        .field.choice {
            .control {
                display: inline-block;
                vertical-align: middle;
                margin-left: .25rem;

                input {
                    margin-bottom: 0;
                }
            }

            .label {
                cursor: pointer;
            }
        }// .field.choice

        .box-tocart {
            .field {
                width: 1%;
                display: table-cell;
                vertical-align: bottom;
                text-align: left;
                padding-right: .5rem;

                .input-text {
                    width: 3.5rem;
                    text-align: center;
                }
            }
            .product-item-actions {
                width: 99%;
                display: table-cell;
                vertical-align: bottom;

                .action {
                    @include button-expand;
                }
            }
        }// .box-tocart

        .tooltip {
            &.wrapper {
                margin-bottom: 1rem;
            }
            &.wrapper,
            &.toggle {
                position: relative;
                color:inherit;
                font-size: inherit;
                padding: 0;
                top: auto;
                z-index: inherit;
                max-width: 100% !important;
                background: none;

                &:before {
                    display: none;
                }
            }
            &.toggle {
                display: inline-block;
                text-decoration: underline;
                color:$anchor-color;
                cursor: pointer;

                &:hover,
                &:active {
                    color:$body-font-color;

                    + .tooltip.content {
                        display:block;
                    }
                }
            }
            &.content {
                display: none;
                min-width: 100%;
                left: 50%;
                text-align: left;
                transform: translateX(-50%);

                dl {
                    margin: 0;
                }

                .subtitle {
                    display: block;
                    font-size: 120%;
                    margin-bottom: .5rem;
                    border-bottom: 1px solid white;
                }

                .label {
                    @include no-label;
                    @include label-colon;
                    color: white;
                    margin-bottom: 0;
                }
                .values {
                    margin-bottom: .5rem;
                }
            }
        }// .tooltip

        .action.tocart {
            @include button-expand;
        }// .action.tocart


        @if $ts-product-item-stock-over {
            .product-item-info {
                position: relative;
            }
            .price-box + .stock.unavailable {
                display: block;
                width: 90px;
                float: none;
                text-align: center;
                flex-basis:inherit;
                background: $light-gray;
                color: $success-color;
                font-family: $body-font-family;
                font-weight: $ts-product-item-product-stock-weight;
                font-size: rem-calc(12);
                line-height: map-get($ts-product-item-product-price-font-size, small);
                padding: rem-calc(9);
                position: absolute;
                bottom: 20px;
                left: 50%;
                line-height: 1;
                margin-left: -45px;

                @include breakpoint(medium) {
                    width: 120px;
                    margin-left: -60px;
                    font-size: rem-calc(13);
                }
                @include breakpoint(xlarge) {
                    width: 170px;
                    margin-left: -85px;
                    font-size: rem-calc(16);
                }

                .no-touchevents & {
                    opacity: 0;
                    transition: opacity .3s ease;
                }

                &.available {
                    @include at;
                }
            }

            .no-touchevents & {
                &:hover,
                &:focus {
                    .price-box + .stock.unavailable {
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }
        }// @if $ts-product-item-stock-over

        @include breakpoint(medium) {
            @include grid-col-size(6);
            margin-bottom: ($ts-product-item-bottom-indent/2);

            @if not($ts-product-item-divider) and not($ts-product-item-collapse-grid) {
                @include get-gutter($ts-product-item-gap-size,'padding-left');
                @include get-gutter($ts-product-item-gap-size,'padding-right');
            }
        }// @include breakpoint(medium)

        @include breakpoint(xlarge) {
            @include grid-col-size(3);

            &:nth-child(2n+1) {
                clear: none;
            }
            &:nth-child(4n+1) {
                clear: both;
            }

            .page-layout-2columns-left &,
            .algolia-right-container & {
                margin-bottom: $ts-product-item-bottom-indent;

                @if ($ts-product-item-qty-line == 4) {
                    @include grid-col-size(3);

                    @if not($ts-product-item-divider) and not($ts-product-item-collapse-grid) {
                        @include get-gutter($ts-product-item-gap-size,'padding-left');
                        @include get-gutter($ts-product-item-gap-size,'padding-right');
                    }

                    &:nth-child(4n+1) {
                        clear: left;
                    }

                } @else {
                    @include grid-col-size(4);

                    @if not($ts-product-item-divider) and not($ts-product-item-collapse-grid) {
                        @include get-gutter($ts-product-item-gap-size,'padding-left');
                        @include get-gutter($ts-product-item-gap-size,'padding-right');
                    }

                    &:nth-child(4n+1) {
                        clear: none;
                    }
                    &:nth-child(3n+1) {
                        clear: left;
                    }
                }

            }

            .block-wishlist &,
            .block-reorder &,
            .block-compare & {
                @include grid-col-size(6);
                @include get-gutter(.25,'padding');
                margin-bottom: .5rem;
            }
        }// @include breakpoint(xlarge)

    }// .product-item

    .no-results {
        @if not($ts-product-item-divider) and not($ts-product-item-collapse-grid) {
            @include get-gutter($ts-product-item-gap-size,'padding-left');
            @include get-gutter($ts-product-item-gap-size,'padding-right');
        }

        @if $ts-product-item-collapse-grid {
            @include get-gutter($ts-product-item-collapse-grid-product-spacing-coeff,'padding-left');
            @include get-gutter($ts-product-item-collapse-grid-product-spacing-coeff,'padding-right');
        }

        @include breakpoint(xlarge) {
            @if $ts-product-item-divider {
                padding-left: 0;
                padding-right: 0;
            } @elseif not($ts-product-item-divider) and not($ts-product-item-collapse-grid) {
                @include get-gutter($ts-product-item-gap-size,'padding-left');
                @include get-gutter($ts-product-item-gap-size,'padding-right');
            } @else {
                @include get-gutter(.5,'padding-left');
                @include get-gutter(.5,'padding-right');
            }
        }
    }
}// .product-items



@if $ts-widget-grid-product-item-center-in-line {
    // centered items
    .product-items {
        // &.widget-product-grid,
        &.bestseller-products-grid,
        &.widget-new-grid,
        &.clerk-slider,
        .products-related &,
        .products-upsell &,
        .products-crosssell & {
            .product-item,
            .#{$ts-algolia-item--selector},
            .#{$ts-algolia-infinite-item--selector} {
                @include breakpoint(large down) {
                    @include grid-layout-center-last(2);
                }
                @include breakpoint(xlarge) {
                    @include grid-layout-center-last(4);
                }
            }
        }
    }// .product-items
}

.block {
    &.grid,
    &.related,
    &.crosssell,
    &.upsell,
    &.block-new-products-names {

        + .block,
        + .nosto_element {
            margin-top: 2rem;
        }
        @include grid-row;

        .block-title {
            strong {
                display: block;
                @extend .extra-title;

                span {
                    display: inline-block;
                    vertical-align: middle;
                    max-width: 75%;
                }

                @each $tmp-mquery, $tmp-properties in $ts-pro-block-title-responsive {
                    @include breakpoint(#{$tmp-mquery}) {
                        @each $tmp-property, $tmp-value in $tmp-properties {
                            #{$tmp-property} : #{$tmp-value};
                        } // @each
                    } // @media
                } // @each
            }

            .page-layout-0column & {
                @include get-gutter(.5,'padding-left');
                @include get-gutter(.5,'padding-right');
            }

        } // .block-title

        .block-content {
            > .block-actions,
            .field.choice.related {
                display: none;
            }
            .page-layout-0column & {
                @include get-gutter(.5,'padding-left');
                @include get-gutter(.5,'padding-right');
            }
        }// .block-content

        &.block-products-list {
            .page-layout-0column & {
                .block-title,
                .block-content {
                    @include get-gutter(0,'padding-left');
                    @include get-gutter(0,'padding-right');
                }
            }
        }
    }
}// .block


.block-products-list,
.block-new-products {
    margin: $ts-pro-block-margin;

    @include breakpoint(xlarge) {
        & + .block-products-list {
            margin-top: 4rem;
        }
    } // $xlarge

    .no-rating {
        @include element-invisible;
    }
} // .block-products-list

.product-items .#{$ts-algolia-item--selector} .product-price-stock .special-price .mva_label,
.product-items .#{$ts-algolia-infinite-item--selector} .product-price-stock .special-price .mva_label {
    margin-top: 0;
}// .mva_label

.#{$ts-algolia-infinite-list--selector}--empty {
    margin-top: 2rem;
}

.prices-tier {
    @include lsn;

    @if ($ts-product-price-tier__default-styling) {
        @include clearfix;
        margin-bottom: 1.5rem;

        li {
            float: left;
            clear: both;
            color: $primary-color;
            font-size: rem-calc(18);
            @include header-font-family($header-font-family);
            text-decoration: underline;
            font-weight: 700;
            @include breakpoint(large) {
                font-size: rem-calc(22);
            }
        }
    }
}// .prices-tier

