//
//  Pagination - Algolia and Magento
//  _____________________________________________
@if $ts-pagination-enable {
	.pages-label {
		@include at;
	}

    .#{$ts-algolia-pagination--selector}-list,
	.convert-paging .pages-items {
		@include lsn;
        display: flex;
		position: relative;
        text-align: $ts-pagination-text-align;


        @if $ts-pagination-centered {
            padding: 0;
        } @else {
            @include mq-specific-property(padding, $ts-pagination-inner-indent);
        }
        @include mq-specific-property(margin, $ts-pagination-outside-indent);

        @include breakpoint(medium down) {
            flex-wrap: wrap;
        }

        @include breakpoint(large) {
            justify-content: center;
            align-items: center;
        }

		// page-wrapper item
		.#{$ts-algolia-pagination-item--selector},
		.item {
            user-select: none;
            @include mq-specific-property(margin, $ts-pagination-item-outside-indent);

			@if $ts-pagination-centered {
				vertical-align: middle;
			} @else {
				vertical-align: top;
			}

			.label {
				@include at;
			}

    		// page-item
            .#{$ts-algolia-pagination-link--selector},
            .link,
    		.page {
    			display: block;
                @include mq-specific-property(width, $ts-pagination-item-width);
                @include mq-specific-property(height, $ts-pagination-item-height);
                @include mq-specific-property(line-height, $ts-pagination-item-line-height);
    			font-size: $ts-pagination-item-font-size;
    			font-family: $ts-pagination-item-font-family;
    			font-weight: $ts-pagination-item-font-weight;
    			text-align: center;
    			border-radius: $ts-pagination-item-border-radius;
    			color: $ts-pagination-item-color;
    			text-decoration: none;
    			border: $ts-pagination-item-border;
    			background-color: $ts-pagination-item-bg;
    			cursor: pointer;

    			&:hover,
    			&:focus {
    				text-decoration: none;
    				color: $ts-pagination-item-color-active;
    				border-color: $ts-pagination-item-border-color-active;
    				background-color: $ts-pagination-item-bg-active;
    				box-shadow: $ts-pagination-item-box-shadow-active;
    			}
            }

			// page item - active state
			&.#{$ts-algolia-pagination-item--selector}__active,
            &.#{$ts-algolia-pagination-item--selector}--selected,
			&.current {
				.#{$ts-algolia-pagination-link--selector},
				.page {
					text-decoration: none;
					color: $ts-pagination-item-color-active;
					border-color: $ts-pagination-item-border-color-active;
					background-color: $ts-pagination-item-bg-active;
					box-shadow: $ts-pagination-item-box-shadow-active;
				}
			}

			// next-prev wrapper
            &.#{$ts-algolia-pagination-item--selector}__previous,
            &.#{$ts-algolia-pagination-item--selector}__next,
            &.#{$ts-algolia-pagination-item--selector}--previousPage,
            &.#{$ts-algolia-pagination-item--selector}--nextPage,
			&.pages-item-next,
			&.pages-item-previous {
				@if $ts-pagination-centered {
					position: relative;
				}

                // @include breakpoint(xlarge) {
                //     bottom: auto;
                //     top: 0;
                // }

				// next-prev has additional arrows
				@if $ts-pagination-item-next-prev-has-arrow {
					&:before {
						content:'';
						width: $ts-pagination-item-next-prev-arrow-width;
						height: $ts-pagination-item-next-prev-arrow-height;
						display: block;
						position: absolute;
						top: 50%;
						transform: translateY(-50%) rotate(180deg);
						left: $ts-pagination-item-next-prev-arrow-hz-indent;
						background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow}.svg");
						background-size: contain;
						background-repeat: no-repeat;
					}
				}

				// next-prev buttons
				.#{$ts-algolia-pagination-link--selector},
				.link,
				.action {

					// has button-view
					@if $ts-pagination-item-next-prev-button {

                        @include mq-specific-property(min-width, $ts-pagination-item-next-prev-button-min-width);
                        @include mq-specific-property(font-size, $ts-pagination-item-next-prev-button-font-size);
                        @include mq-specific-property(padding-top, $ts-pagination-item-next-prev-button-padding-top);
                        @include mq-specific-property(padding-bottom, $ts-pagination-item-next-prev-button-padding-bottom);

						// has button-view only starting from specified breakpoint
						@if $ts-pagination-item-next-prev-button-simple-mobile {
							font-size: 0;
							background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow}.svg");
							background-size: contain;
							background-repeat: no-repeat;
							background-position: 50%;

							@include breakpoint($ts-pagination-item-next-prev-button-simple-mobile-until) {
								@include element-invisible-off;
								min-width: map-get($ts-pagination-item-next-prev-button-min-width,small);
								max-width: 49%;
								height: auto;
								@include button-extended;
								padding-top: map-get($ts-pagination-item-next-prev-button-padding-top,small);
								padding-bottom: map-get($ts-pagination-item-next-prev-button-padding-bottom,small);
								font-size: map-get($ts-pagination-item-next-prev-button-font-size,small);
								text-decoration: none;
								color: $ts-pagination-item-next-prev-button-color !important;
								letter-spacing: $ts-pagination-item-next-prev-button-letter-spacing;
								text-transform: $ts-pagination-item-next-prev-button-text-transform;
								background-image: none;
								background-color: $ts-pagination-item-next-prev-button-bg-color !important;
								cursor: pointer;

								// next-prev - active states
								&:hover {
									background-color: $ts-pagination-item-next-prev-button-bg-color-hover !important;
									background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow-hover}.svg");
								}
								&:active,
								&:focus {
									background-color: $ts-pagination-item-next-prev-button-bg-color-active !important;
									background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow-hover}.svg");
								}
							}
						} @else {
							@include element-invisible-off;

							max-width: 49%;
							height: auto;
							@include button-extended;

							text-decoration: none;
							color: $ts-pagination-item-next-prev-button-color !important;
							letter-spacing: $ts-pagination-item-next-prev-button-letter-spacing;
							text-transform: $ts-pagination-item-next-prev-button-text-transform;
							background-color: $ts-pagination-item-next-prev-button-bg-color !important;
							cursor: pointer;

							// next-prev - active states
							&:hover {
								background-color: $ts-pagination-item-next-prev-button-bg-color-hover !important;
							}
							&:active,
							&:focus {
								background-color: $ts-pagination-item-next-prev-button-bg-color-active !important;
							}
						}

					// has simple page-view with arrow
					} @else {
						display: block;
						width: map-get($ts-pagination-item-width,small);
						height: map-get($ts-pagination-item-height,small);
						line-height: map-get($ts-pagination-item-line-height,small);
						font-size: 0;
						text-align: center;
						border-radius: $ts-pagination-item-border-radius;
						color: $ts-pagination-item-color;
						text-decoration: none;
						border: $ts-pagination-item-border;
						background-color: $ts-pagination-item-bg;
						background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow}.svg");
						background-size: contain;
						background-repeat: no-repeat;
						background-position: 50%;
						cursor: pointer;

						// next-prev - active states
						&:hover,
						&:focus {
							text-decoration: none;
							color: $ts-pagination-item-color-active;
							border-color: $ts-pagination-item-border-color-active;
							background-color: $ts-pagination-item-bg-active;
							box-shadow: $ts-pagination-item-box-shadow-active;
							background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow-hover}.svg");
						}
					}
				}

				// next-prev buttons - disabled state
				&.disabled,
				&.#{$ts-algolia-pagination-item--selector}__disabled,
                &.#{$ts-algolia-pagination-item--selector}--disabled {
					@if $ts-pagination-centered {
                        visibility: hidden;
					}

					.#{$ts-algolia-pagination-link--selector},
					.link,
					.action {
						@if $ts-pagination-item-next-prev-button {
							cursor: default !important;

							// has disabled button-view only starting from specified breakpoint
							@if $ts-pagination-item-next-prev-button-simple-mobile {
								@include breakpoint($ts-pagination-item-next-prev-button-simple-mobile-until) {
									color: $ts-pagination-item-next-prev-button-color-disabled !important;
									background-color: $ts-pagination-item-next-prev-button-bg-color-disabled !important;
									&:hover {
										color: $ts-pagination-item-next-prev-button-color-disabled !important;
										background-color: $ts-pagination-item-next-prev-button-bg-color-disabled !important;
										box-shadow: none;
									}
									&:active,
									&:focus {
										color: $ts-pagination-item-next-prev-button-color-disabled !important;
										background-color: $ts-pagination-item-next-prev-button-bg-color-disabled !important;
										box-shadow: none;
									}
								}
							} @else {
								color: $ts-pagination-item-next-prev-button-color-disabled !important;
								background-color: $ts-pagination-item-next-prev-button-bg-color-disabled !important;
								&:hover {
									color: $ts-pagination-item-next-prev-button-color-disabled !important;
									background-color: $ts-pagination-item-next-prev-button-bg-color-disabled !important;
									box-shadow: none;
								}
								&:active,
								&:focus {
									color: $ts-pagination-item-next-prev-button-color-disabled !important;
									background-color: $ts-pagination-item-next-prev-button-bg-color-disabled !important;
									box-shadow: none;
								}
							}
						} @else {
							opacity: $ts-pagination-item-next-prev-opacity-disabled;
							cursor: default !important;
							&:hover,
							&:focus {
								color: $ts-pagination-item-next-prev-button-color-disabled;
								border-color: $ts-pagination-item-next-prev-button-color-disabled;
								background-color: $ts-pagination-item-next-prev-button-bg-color-disabled;
								box-shadow: none;
							}
						}
					}

					@if $ts-pagination-item-next-prev-has-arrow {
						&:before {
							opacity: $ts-pagination-item-next-prev-opacity-disabled;
						}
					}
				}
			}

			&.#{$ts-algolia-pagination-item--selector}__next,
            &.#{$ts-algolia-pagination-item--selector}--nextPage,
			&.pages-item-next {
                margin-left: auto;

                @include breakpoint(medium down) {
    				order: -1;
                    flex-basis: 50%;
                    margin: 0 0 10px!important;
                }

				@if $ts-pagination-item-next-prev-has-arrow {
					&:before {
						transform: translateY(-50%);
						left: auto;
						right: $ts-pagination-item-next-prev-arrow-hz-indent;
					}
				}
			}

            &.#{$ts-algolia-pagination-item--selector}__previous,
            &.#{$ts-algolia-pagination-item--selector}--previousPage,
			&.pages-item-previous {
                margin-right: auto;

                @include breakpoint(medium down) {
                    order: -2;
                    flex-basis: 50%;
                    margin: 0 0 10px !important;
                }

				.#{$ts-algolia-pagination-link--selector},
				.link,
				.action {
					// has button-view
					@if $ts-pagination-item-next-prev-button {
						// has button-view only starting from specified breakpoint
						@if $ts-pagination-item-next-prev-button-simple-mobile {
							background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow-prev}.svg");

							&:hover,
							&:active,
							&:focus {
								background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow-prev-hover}.svg");
							}

							@include breakpoint($ts-pagination-item-next-prev-button-simple-mobile-until) {
								background-image: none;
							}
						}
					} @else {
						background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow-prev}.svg");

						&:hover,
						&:active,
						&:focus {
							background-image: url("#{$ts-path-to-images}/#{$ts-pagination-item-next-prev-button-arrow-prev-hover}.svg");
						}
					}
				}
			}
		}

		// iPhone4 smaller view
		@media all and (max-width: 374px) {
			.#{$ts-algolia-pagination-item--selector},
			.item {
				&.#{$ts-algolia-pagination-item--selector}__previous,
                &.#{$ts-algolia-pagination-item--selector}__next,
                &.#{$ts-algolia-pagination-item--selector}--previousPage,
                &.#{$ts-algolia-pagination-item--selector}--nextPage,
				&.pages-item-next,
				&.pages-item-previous {
					&:before {
						@if $ts-pagination-item-next-prev-has-arrow {
							display: none;
						}
					}
					.#{$ts-algolia-pagination-link--selector},
					.link,
					.action {
						@if $ts-pagination-item-next-prev-button {
							@if not $ts-pagination-item-next-prev-button-simple-mobile {
								font-size: $ts-pagination-item-next-prev-button-font-size-iphone4;
								min-width: $ts-pagination-item-next-prev-button-min-width-iphone4;
								padding-left: $ts-pagination-item-next-prev-button-hz-paddings-iphone4;
								padding-right: $ts-pagination-item-next-prev-button-hz-paddings-iphone4;
							}
						}
					}
				}
			}
		}// breakpoint
	}
}
