$snowdog-menu-link__color: $body-font-color !default;
$snowdog-menu-link__font-family: $header-font-family !default;

$snowdog-menu-link__font-size: (
    small: 26px,
    xlarge: 20px
) !default;

$snowdog-menu-level0-dropdown__left: 0 !default;
$snowdog-menu-level0-dropdown__right: 0 !default;

$snowdog-menu-level0-dropdown__background-color: $white !default;

$snowdog-menu-level0-dropdown__padding: (
    small: 20px,
    xlarge: (30px 50px)
) !default;

$snowdog-menu-level1-link__font-size: (
    small: 16px,
    xxlarge: 24px
) !default;

$snowdog-menu-level1-link__margin: (
    small: (0 0 12px),
    xxlarge: (0 0 20px)
) !default;

$snowdog-menu-level2-link__margin: (0 0 9px) !default;

.is-not-sticky {
    .header {
        .header-row {
            position: relative;
        }
    }
}

#off-canvas-header {
    display: none;
}

.main-menu {
    outline: none;

    &__list {
        list-style: none;
        margin: 0;
        display: flex;
        padding-left: 13px;

        @include breakpoint(xlarge) {
            padding-left: 5px;
        }
    }

    li.level0 {
        margin-left: 7px;
        margin-right: 7px;
        padding: 0;

        @include breakpoint(xlarge) {
            margin-left: 15px;
            margin-right: 15px;
        }

        &.active {
            > .level0__title:before,
            > .wrapper__title:before {
                content: "";
                position: absolute;
                left: 50%;
                bottom: -20px;
                transform: translateX(-50%);
                z-index: 1;
                margin-left: -12px;
                border-bottom: 12px solid $white;
                border-right: 12px solid transparent;
                border-left: 12px solid transparent;
            }
        }
    }

    .main-menu__link,
    .level0__title,
    li.level0 > .wrapper__title,
    li.level0 > a {
        font-family: $header-font-family;
        color: $snowdog-menu-link__color;
        display: block;
        position: relative;

        @each $q in (map-keys($snowdog-menu-link__font-size)) {
            @include breakpoint($q) {
                font-size: map-get($snowdog-menu-link__font-size, $q);
            }
        }
    }

    .level0__title,
    li.level0 > .wrapper__title {
        padding-right: 20px;
        cursor: default;

        &:after {
            content: "";
            position: absolute;
            width: 20px;
            height: 24px;
            right: 0;
            top: 1px;
            background-image: url("#{$ts-path-to-images}/icon-menu-arrow.svg");
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: 10px auto;

            @include breakpoint(xlarge) {
                height: 30px;
            }
        }

        span {
            display: block;
            color: inherit;
            user-select: none;
            white-space: nowrap;
        }
    }

    ul {
        list-style: none;
        margin: 0;
    }

    .submenu {
        a {
            .is-sticky .header .header-row & {
                color: $body-font-color;
            }
        }
    }

    &__submenu {
        &.level0 {
            li {
                padding: 0;
                line-height: 1.4;
                margin-bottom: 9px;

                &.level2 {
                    > a {
                        margin: $snowdog-menu-level2-link__margin;
                    }
                }
            }

            a {
                display: block;
            }

            > li.level1 {
                flex-basis: 33.33333%;
                font-size: rem-calc(13);
                margin-bottom: 25px;
                padding: 0 10px;

                @include breakpoint(xlarge) {
                    flex-basis: 25%;
                    font-size: rem-calc(15);
                    margin-bottom: 50px;
                    padding: 0;
                }

                > a {
                    display: inline-block;
                    font-family: $header-font-family;
                    line-height: 1.2;

                    @each $q in (map-keys($snowdog-menu-level1-link__font-size)) {
                        @include breakpoint($q) {
                            font-size: map-get($snowdog-menu-level1-link__font-size, $q);
                        }
                    }

                    @each $q in (map-keys($snowdog-menu-level1-link__margin)) {
                        @include breakpoint($q) {
                            margin: map-get($snowdog-menu-level1-link__margin, $q);
                        }
                    }
                }
            }
        }
    }

    &__submenu.level0 {
        position: absolute;
        @if $snowdog-menu-level0-dropdown__left {
            left: $snowdog-menu-level0-dropdown__left;
        }
        @if $snowdog-menu-level0-dropdown__right {
            right: $snowdog-menu-level0-dropdown__right;
        }
        background-color: $snowdog-menu-level0-dropdown__background-color;
        display: none;
        margin: 20px 0 0;
        flex-wrap: wrap;
        box-shadow: 4px 4px 16px rgba($black,0.2);

        @each $q in (map-keys($snowdog-menu-level0-dropdown__padding)) {
            @include breakpoint($q) {
                padding: map-get($snowdog-menu-level0-dropdown__padding, $q);
            }
        }
    }

    a {
        color: $body-font-color;
    }
}
