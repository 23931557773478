// Flexbox Features in Grid
.convert-grid-row-flex {
	@include flex-grid-row;

	// Nesting behavior
	.convert-grid-row-flex {
		@include flex-grid-row(nest, $base: false);
	}

	// Expanded row
	&.expanded {
		@include grid-row-size(expand);

		.convert-grid-row-flex {
			margin-right: auto;
			margin-left: auto;
		}
	}

	&:not(.expanded) .convert-grid-row-flex {
		@include grid-row-size(expand);
	}

	&.collapse {
		> .convert-column-flex {
			@include grid-col-collapse;
		}
	}

	// Undo negative margins
	// From collapsed child
	&.collapse > .convert-column-flex > .convert-grid-row-flex {
		margin-right: 0;
		margin-left: 0;
	}

	&.small-collapse > .convert-column-flex {
		padding-left: 0;
		padding-right: 0;
	}
}

.convert-column-flex {
	@include flex-grid-column;
}

@include -zf-each-breakpoint {
	.convert-column-flex {
		@for $i from 1 through $grid-column-count {
			// Sizing (percentage)
			&.#{$-zf-size}-#{$i} {
				flex: flex-grid-column($i);
				max-width: grid-column($i);
			}

			// Offsets
			$o: $i - 1;

			&.#{$-zf-size}-offset-#{$o} {
				@include grid-column-offset($o);
			}
		}

		// Source ordering
		@for $i from 1 through 6 {
			&.#{$-zf-size}-order-#{$i} {
				@include flex-order($i);
			}
		}

		// Block grid
		@for $i from 1 through $block-grid-max {
			&.#{$-zf-size}-up-#{$i} {
				@include flex-grid-layout($i);
			}
		}

		@if $-zf-size != $-zf-zero-breakpoint {
			// Sizing (expand)
			@include breakpoint($-zf-size) {
				&.#{$-zf-size}-expand {
					flex: flex-grid-column();
				}
			}

			// direction helper classes
			@each $dir, $prop in $-zf-flex-direction {
				&.#{$-zf-size}-flex-dir-#{$dir} {
					@include flex-direction($prop);
				}
			}
			// child helper classes
			&.#{$-zf-size}-flex-child-auto {
				flex: 1 1 auto;
			}

			&.#{$-zf-size}-flex-child-grow {
				flex: 1 0 auto;
			}

			&.#{$-zf-size}-flex-child-shrink {
				flex: 0 1 auto;
			}

			// Auto-stacking/unstacking
			@at-root (without: media) {
				.convert-grid-row-flex.#{$-zf-size}-unstack {
					> .convert-column-flex {
					flex: flex-grid-column(100%);

						@include breakpoint($-zf-size) {
							flex: flex-grid-column();
						}
					}
				}
			}
		}

		// Responsive collapsing
		&.#{$-zf-size}-collapse {
			> .convert-column-flex { @include grid-col-collapse; }
		}

		&.#{$-zf-size}-uncollapse {
			> .convert-column-flex { @include grid-col-gutter($-zf-size); }
		}
	}
}


@mixin convert-foundation-flex-classes {
	// Horizontal alignment using justify-content
	@each $hdir, $prop in map-remove($-zf-flex-justify, left) {
		.align-hz-#{$hdir} {
			@include flex-align($x: $hdir);
		}
	}

	// Vertical alignment using align-items and align-self
	@each $vdir, $prop in $-zf-flex-align {
		.align-vt-#{$vdir} {
			@include flex-align($y: $vdir);
		}

		.align-vt-self-#{$vdir} {
			@include flex-align-self($y: $vdir);
		}
	}

	// Central alignment of content
	.align-center-middle {
		@include flex-align($x: center, $y: middle);
		align-content: center;
	}

	// Temporary class for central vertical alignment
	.convert-grid-row-vc {
		@include flex-align($x: left, $y: middle);
	}

	// Source ordering
	@include -zf-each-breakpoint {
		@for $i from 1 through 6 {
			.#{$-zf-size}-order-#{$i} {
				@include flex-order($i);
			}
		}
	}
}

@include convert-foundation-flex-classes;
