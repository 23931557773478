@mixin convert-button {
	.convert-button {
		&.aligned {
			display: table;

			&--center {
				margin-left: auto;
				margin-right: auto;
			}

			&--right {
				margin-left: auto;
				margin-right: 0;
			}
		}
	}
} // @mixin convert-button