@import '../../mixins';

//	Animations
.mm-page {
    box-sizing: border-box;
    position: relative;
}

.mm-slideout {
    transition: transform $ts-snowdog-menu-transition-duration $ts-snowdog-menu-transition-function;
    @include breakpoint(medium down) {
        z-index: 1;
    }
}

//	Wrapper
.mm-wrapper {
    &_opened {
        overflow-x: hidden;
        position: relative;

        .mm-page {
            min-height: 100vh;
        }
    }
    &_background {
        .mm-page {
            background: inherit;
        }
    }
}

//	Menu
.mm-menu_offcanvas {
    position: fixed;
    right: auto;
    z-index: 2;

    &:not(.mm-menu_opened) {
        display: none;
    }
}

@include mm_offcanvas_size;

@if ($ts-snowdog-menu-include-offcanvas_blocker) {
    .mm-wrapper__blocker {
        background: rgba(3, 2, 1, 0);
        overflow: hidden;
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    .mm-wrapper_blocking {
        overflow: hidden;

        body {
            overflow: hidden;
        }

        .mm-wrapper__blocker {
            display: block;
        }
    }
}

.mm-offcanvas-close {
    width: $ts-snowdog-menu-close-width;
    height: $ts-snowdog-menu-close-height;
    background-image: url("#{$ts-snowdog-menu-path-to-images}/#{$ts-snowdog-menu-close-icon}.svg");
    background-size: $ts-snowdog-menu-close-icon-width auto;
    background-repeat: no-repeat;
    background-position: $ts-snowdog-menu-close-icon-position;
    padding: $ts-snowdog-menu-close-padding;
    position: absolute;
    top: $ts-snowdog-menu-close-top;
    right: $ts-snowdog-menu-close-right;
    z-index: 2;

    span {
        font-size: $ts-snowdog-menu-close-text-size;
        font-weight: $ts-snowdog-menu-close-text-weight;
        font-family: $ts-snowdog-menu-close-text-font-family;
        line-height: $ts-snowdog-menu-close-text-line;
    }

    @if $ts-snowdog-menu-close-text-hide {
        span {
            display: none;
        }
    }
}

.mm-offcanvas-links {
    display: block !important;
    position: static !important;
    transform: translate3d(0%, 0, 0);
    margin-top: $ts-snowdog-menu-panel-bottom-indent;

    .mm-navbar {
        display: none;
    }

    .offcanvas-links {
        padding: $ts-snowdog-menu-links-menu-inner-spacing;
        border-bottom: $ts-snowdog-menu-links-menu-border;

        h3,
        .title {
            font-size: $ts-snowdog-menu-links-menu-title-font-size;
            font-family: $ts-snowdog-menu-links-menu-title-font-family;
            font-weight: $ts-snowdog-menu-links-menu-title-font-weight;
            color: $ts-snowdog-menu-links-menu-title-color;
            margin-bottom: $ts-snowdog-menu-links-menu-title-margin-bottom;
            text-transform: $ts-snowdog-menu-links-menu-title-transform;

            @if $ts-snowdog-menu-links-menu-title-hide {
                display: none;
            }
        }// title

        ul {
            font-size: $ts-snowdog-menu-links-menu-item-font-size;
            font-family: $ts-snowdog-menu-links-menu-item-font-family;
            font-weight: $ts-snowdog-menu-links-menu-item-fon-weight;
            @include lsn;

            li {
                padding: $ts-snowdog-menu-links-menu-item-padding;
                a {
                    color: $ts-snowdog-menu-links-menu-item-color;
                    text-decoration: $ts-snowdog-menu-links-menu-item-underline;
                    text-transform: $ts-snowdog-menu-links-menu-item-transform;

                    &:hover {
                        color: $ts-snowdog-menu-links-menu-item-active-color;
                    }
                }

                strong {
                    font-weight: inherit;
                }

                &.current {
                    color: $ts-snowdog-menu-links-menu-item-active-color;
                }
            }
        }// ul
    }// links

    .top-links {
        display: flex;
        flex-direction: column-reverse;
    }
}


// .menu inheritance bugfix
.mm-menu {
    .menu {
        > li {
            display: block;

            > a {
                padding-left: $ts-snowdog-menu-listitem-indent;
                padding-right: $ts-snowdog-menu-padding;
                line-height: inherit;
            }
        }
        .active > a {
            background: $ts-snowdog-menu-highlighted-background-color;
        }
    }
}
.mm-listitem:after {
    top: auto;
}

@import 'offcanvas.rtl';
