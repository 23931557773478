@mixin convert-similar-products (
		$title: $cv-similar-products__title,
		$margin: $cv-similar-products__margin,
		$item__availability: $cv-similar-products-item-availability__show,
		$item-availability__margin: $cv-similar-products-item-availability__margin,
		$item__width: $cv-similar-products-item__width,
		$item__height: $cv-similar-products-item__height,
		$item__margin: $cv-similar-products-item__margin,
		$item__padding: $cv-similar-products-item__padding,
		$item__border: $cv-similar-products-item__border,
		$item__border-color: $cv-similar-products-item__border-color,
		$item__border-color--current: $cv-similar-products-item__border-color--current,
		$item__border-radius: $cv-similar-products-item__border-radius,
		$item__bgcolor: $cv-similar-products-item__bgcolor,
		$item__box-shadow: $cv-similar-products-item__box-shadow,
		$item__box-shadow--hover: $cv-similar-products-item__box-shadow--hover,
		$item-image__height: $cv-similar-products-item-image__height,
		$item-label__show: $cv-similar-products-item-label__show,
		$item-label__color: $cv-similar-products-item-label__color,
		$item-label__font-size: $cv-similar-products-item-label__font-size,
		$item-label__font-weight: $cv-similar-products-item-label__font-weight,
		$item-label__line-height: $cv-similar-products-item-label__line-height,
		$item-label__margin: $cv-similar-products-item-label__margin
	) {
	@if not ($item-image__height) {
		$item-image__height: $item__width;

		@if ($item__border) {
			$item-image__height: ($item__width - 2 * $item__border);
		} @else {
			$item-image__height: $item__width;
		}
	}
	.product-info-main {
		.similar.product-info-form-row {
			clear: both;
			margin: $margin;

			.product-options-wrapper {
				display: flex;
				flex-wrap: wrap;
			}

			.swatch-attribute-label {
				@if $title {
					flex-basis: 100%;

				} @else {
					display: none;
				}
			} // .swatch-attribute-label

			.product {
				width: $item__width;
				height: $item__height;
				margin: $item__margin;
				text-align: center;

				@if ($item__box-shadow) {
					box-shadow: $item__box-shadow;
					transition: box-shadow .3s ease;
				}
				background-color: $item__bgcolor;

				@if ($item__border) {
					border: $item__border solid $item__border-color;
					border-radius: $item__border-radius;
				}

				position: relative;

				&:hover {
					@if ($item__box-shadow--hover) {
						box-shadow: $item__box-shadow--hover;
					}
				}

				&--current {
					@if ($item__border) and ($item__border-color != $item__border-color--current) {
						border-color: $item__border-color--current;
					}
				}

				&__info {
					text-decoration: none;
					display: flex;
					flex-direction: column;
					height: 100%;
					@if ($item__padding) {
						padding: $item__padding;
					}

					.product-image-container {
						display: flex;
						justify-content: center;
						align-items: center;
						flex: 1 1 $item-image__height;
					}
				}

				&__variation {
					color: $item-label__color;
					font-weight: $item-label__font-weight;
					font-size: $item-label__font-size;
					line-height: $item-label__line-height;
					margin: $item-label__margin;
					min-height: round(2.4 * $item-label__line-height * $item-label__font-size);
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					@if not ($item-label__show) {
						display: none;
					}
				}

				.product-label,
				.convert-label-container {
					display: none !important;
				}

				.product-image-container {
					width: auto !important;
				}

				.product-image-wrapper {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 !important;
				}
			} // .product

			.product-availability {
				position: absolute;
				left: 0;
				right: 0;
				margin: $item-availability__margin;

				.stock {
					margin: 0;
				}

				@if not ($item__availability) {
					display: none;
				}
			} // .product-availability
		} // .similar
	} // .product-info-main
} // @mixin convert-similar-products