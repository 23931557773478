div.off-canvas {
	background-image: url("#{$ts-path-to-images}/bg__offcanvas.png");
	background-position: 100% 100%;
	background-repeat: no-repeat;
	height: calc(100% - 2 * #{$ts-offcanvas-spacing-top});

	.offcanvas-title {
		@include element-invisible;
	}

	.close-menu {
		position: absolute;
		right: 1.5rem;
		top: 0.75rem;

		.close-button {
			color: $white;
			position: static;
		}

		.txt {
			@include element-invisible;
		}
	}
}

