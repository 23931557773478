.page {
	&.messages {
		max-width: $global-width;
		position: relative;
		margin: 0 auto;
		float: none;
	}
}

.mm-menu {
    div.mm-panel {
        background-image: url("#{$ts-path-to-images}/bg__offcanvas.png");
        background-position: 100% 100%;
        background-repeat: no-repeat;

        .mm-navbar__title {
            align-items: flex-end;
            justify-content: left;
            color: rgba($white, .5);
            font-size: rem-calc(13);
            padding: 0 0 10px 28px;
        }
        .mm-navbar__btn:not(.mm-hidden) + .mm-navbar__title {
            margin-left: -20px;
        }

        a {
            color: $white;
        }

        .mm-listitem.active > .mm-listitem__text,
        .mm-listitem_opened > .mm-listitem__text {
            color: $white;
        }
    }

    .extra-links {
        .mm-listitem__btn,
        .mm-listitem__text {
            color: rgba($white, .8);
        }
    }
}


.mm-offcanvas-links {
	.offcanvas-links ul li {
		color: $ts-snowdog-menu-dimmed-text-color;
	}
}

// Search
.minisearch {
	input.input-text {
		border-radius: 26px;
		transition: box-shadow .3s ease;
		&:hover,
		&:active,
		&:focus {

		}
	}
	button.search {
		&.disabled,
		&[disabled] {
			background-color: transparent;
		}
	}
	.actions {
		right: 8px;
	}
}// search

#algolia-searchbox {
    input.algolia-search-input {
        &:hover,
        &:active,
        &:focus,
        &:focus:not([value=""]),
        &.focus {
            border-width: 0;
        }
    }
}

// Mobile Search bar
.mobile-search-wrapper {
	transition: none;

	.mobile-search-bar {
		box-shadow: none;
		padding: 3px 23px 14px !important;
		background-color: $primary-color;

		#algolia-searchbox {
			.clear-cross {
				background-image: url("#{$ts-path-to-images}/icon-deselect-white.svg");
				background-size: 1rem auto;
			}
		}
		#algolia-searchbox .algolia-search-input,
		.minisearch .input-text {
			border-radius: $ts-mobile-search-field-height;
			color: $white;
		}
	}

	&.in-scroll {
		z-index: $ts-headbar-zindex + 2;
		.mobile-search-bar {
			padding: 7px 0 0 !important;
			border-bottom: 0;
		}
	}
} // mobile-search-wrapper


//HEADER
.page-header {
	.is-sticky &,
	.in-scroll-main & {
		z-index: $ts-headbar-zindex + 2;
	}

	.header {

        // Search
        .minisearch {
        	.actions {
        		right: 8px;
        	}
        }// search

        .sticky-mm {
        	box-shadow: none;

        	&.in-scroll {
        		z-index: $ts-headbar-zindex + 2;

        		&:after {
        			display: none;
        		}
        		.tab-bar {
        			padding-top: 0;
        			box-shadow: $ts-sticky-scroll-headbar-box-shadow;
        		}
        		.middle-side {
					height: map-get($ts-headbar-height, small);
					line-height: map-get($ts-headbar-height, small);
        			opacity: 0;
        		}
        		.right-side,
        		.left-side {
					height: map-get($ts-headbar-height, small);
        			.minicart-wrapper,
        			.nav-toggle {
						height: map-get($ts-headbar-height, small);
						line-height: map-get($ts-headbar-height, small);
        			}
        		}
        	}
        }// sticky-mm

        // Mobile
        .tab-bar {
        	display: flex;
        	justify-content: space-between;

        	&:before,
        	&:after {
        		display: none;
        	}

        	.middle-side {
        		display: flex;
        		align-items: center;
        		line-height: normal;
        		padding: 0;

        		.logo {
        			height: auto;
                    // margin-top: -15px;
                }
            }

            .right-side {
            	width: auto;
            	padding-left: 0;
            	margin-left: auto;

            	.minicart-wrapper {
            		opacity: 1;

            		.action.showcart {
            			transform: none;
            		}
            	}

				.miniquote-wrapper {
					height: map-get($ts-headbar-height, small);
					line-height: map-get($ts-headbar-height, small);
				}
            }

            .left-side {
            	width: auto;
            	padding-right: 0;
            	margin-right: auto;

            	.nav-toggle {
					min-width: 44px;
					background-size: 20px auto;
            	}
            }
        }// tab-bar

        .minicart-wrapper {
        	margin: 0;
        }

        .header-row {
        	.sticky-header-row {
				body:not(.checkout-index-index) & {
					display: flex;
				}
        	}

            // Logo
            .logo {
            	width: auto;

            	&:before {
            		display: none;
            	}

            	img {
            		width: $ts-headbar-logo-width;

            		.is-sticky & {
            			width: 80px;
            		}
            	}
            }// logo

            // Navigation
            .top-menu {
            	@include breakpoint(xlarge) {
            		margin-right: 16px;
            	}
            }

            // Top Header Panel
            .nav-search-wrapper {
            	width: auto;
            	flex-grow: 1;
            	display: flex;
            	flex-direction: column;
            	padding-top: 1.3rem;

            	.panel.wrapper {
            		justify-content: flex-end;
            		width: auto;
            		padding: 0;
            	}

            	#search {
            		border-radius: $ts-header-search-input-height;
            		@include placeholder(rgba($ts-header-search-input-color,0.7));
            	}

				.clear-cross {
					background-image: url("#{$ts-path-to-images}/icon-deselect-white.svg");
				}
            }

            .top-panel-wrapper {
            	display: flex;
            	height: 100%;
            	align-items: center;

            	&:before,
            	&:after {
            		display: none;
            	}

            	.top-nav-wrapper {
            		flex-grow: 1;

            		&:before,
            		&:after {
            			display: none;
            		}

            		.nav-sections {
            			width: auto !important;
            			height: auto;
            		}
            	}

            	.top-search-wrapper {
            		padding-right: 0 !important;
            		flex: 1 1 auto;
            		margin-left: auto;
            		max-width: 738px;

            		.block-search {
            			height: auto;
            			display: block;
            			background: transparent;
            			box-shadow: none;
            		}
            	}

            	.header-control-wrapper {
            		display: flex;
            		justify-content: flex-end;
            		align-items: center;
            		margin-left: 20px;
            	}

            	.panel.header {
            		margin: 0;
            	}
            }

            // Sticky
            .is-sticky & {
            	.logo {
            		width: 150px;
            		@include breakpoint(xlarge) {
            			width: $ts-headbar-logo-width;
            		}
            	}

            	.switcher-website {
            		display: none;
            	}

            	.sticky-header-row {
					body:not(.checkout-index-index) & {
						display: flex;
					}

            		.top-menu {
            			order: 1;
            		}

            		.top-panel-wrapper {
            			padding-top: 0;
            			margin-left: 0;
            			.top-search-wrapper {
            				width: 70%;
            				order: 2;
            			}
            		}
            		.header-control-wrapper {
            			order: 3;
            		}
            		.nav-search-wrapper {
            			padding-top: 0;
            			width: 100%;
            			padding-left: 150px + (map-get($grid-column-gutter, medium)/2);
            			order: 2;
            			@include breakpoint(xlarge) {
            				padding-left: $ts-headbar-logo-width + map-get($grid-column-gutter, medium);
            			}

            			.header-control-wrapper {
            				align-items: center;
            			}

            			.links li {
            				padding-top: 0;
            				a {
            					font-size: 0;
            					&:before {
            						margin-bottom: 0;
            					}
            				}
            			}
            			.block-search {
            				position: static;
            				padding: 0;
            			}
            		}
                }// .sticky-header-row
            }// sticky

        }// .header-row

        .links {
        	display: flex;
        	margin: -2px 13px 0 0;
        	@include breakpoint(xxlarge) {
        		margin-right: 26px;
        	}

        	li {
        		margin-left: 13px;
        	}
        	li.link {
        		text-align: center;
        		padding: 0;
        		a {
        			display: block;
        			width: 52px;
        			height: 52px;
        			transform: translateY(0);
        			margin:0;
        			font-size: 0;
        			padding: 0;
        			background-size: 36px auto;
        			background-repeat: no-repeat;
        			background-position: 50%;
        			&:before {
        				display: none;
        			}
        		}
        		&.customer-section {
        			order: 2;
        			a {
        				padding: 0;
        				background-image: url("#{$ts-path-to-images}/icon-user.svg");
        				&:after {
        					display: none;
        				}
        			}
        		}
        		&.wishlist {
        			@include element-invisible;
        			order: 1;
        			display: inline-block !important;
        			a {
        				background-size: 40px auto;
        				background-image: url("#{$ts-path-to-images}/icon-wishlist.svg");

        				&:hover,
        				&:active,
        				&:focus {
        					background-image: url("#{$ts-path-to-images}/icon-wishlist-active.svg");
        				}
        			}
        		}
        		&.authorization-link {
        			order: 4;
        			a {
        				background-image: url("#{$ts-path-to-images}/icon-user.svg");
        			}
        			@include breakpoint(large) {
        				&:not(.loggedout) {
        					display: none;
        				}
        			}
        		}
        	}
        }// .links

		.miniquote-wrapper {
			margin-left: 10px;
			margin-top: 0;
			position: relative;

			@include breakpoint(xlarge) {
				margin-left: 15px;
			}

			a {
				font-family: $medium-font-family;
				font-size: rem-calc(14);
				color: $primary-color;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}

			.label {
				font-family: $medium-font-family;
				font-size: rem-calc(14);
				color: $primary-color;
				margin-bottom: 4px;
			}

			.action {
				&.showquote{
					margin: 0;
					position: relative;
					vertical-align: middle;

					&:before {
						content: '' !important;
						font-size: 0;
						display: block;
						width: 44px;
						height: 44px;
						background-image: url("#{$ts-path-to-images}/icon-check-quote.svg");
						background-position: center;
						background-repeat: no-repeat;
						background-size: 31px 34px;
					}

					.counter.qty {
						position: absolute;
						bottom: auto;
						top: 5px;
						right: 3px;
						min-width: rem-calc(18);
						height: rem-calc(18);
						line-height: rem-calc(18);
						font-size: rem-calc(12);
						padding: 0;
						border-radius: 50%;
						background-color: $success-color;
					}
				}

				&.close {
					top: 5px;
					right: 8px;

					&:before {
						color: #000;
						font-size: 30px;
						line-height: 30px;
					}
				}
			}

			&.active {
				.block-miniquote {
					margin-top: -11px;

					@include breakpoint(large) {
						margin-top: 0;
					}
				}
			}

			.block-miniquote {
				width: 280px;
				padding: 60px 15px 30px;
				right: 0;
				text-align: left;
				line-height: 1;
				border: none;
				box-shadow: 3px 3px 10px rgba($black, .2);

				@include breakpoint(medium) {
					width: 390px;
					padding: 60px 25px 50px;
				}

				&:after {
					display: none;
				}
				&:before {
					left: auto;
					right: 10px;
					border: solid 12px;
					border-color: transparent transparent #fff transparent;
					top: -24px;
				}

				.items-total {
					display: none;
				}

				.block-content {
					> .actions {
						> .primary {
							margin: 0;

							.action.primary {
								font-size: rem-calc(17);
								padding: 8px 17px;

								@include breakpoint(medium) {
									font-size: rem-calc(19);
									padding: 12px 17px;
								}
							}
						}
					}
				}

				.actions.primary {
					text-align: center;
                    margin-top: 26px;
				}
			}

			.miniquote-items-wrapper {
				border: none;
				margin: 0 -25px;
				padding: 0 25px;
				overflow-x: inherit;
				overflow-y: auto;
				max-height: 500px;
			}

			.miniquote-items {
				.product-item-details {
					padding-left: 65px;
					padding-right: 35px;
					position: relative;

					@include breakpoint(medium) {
						padding-left: 100px;
					}

					.price {
						font-family: $bold-font-family;
						font-size: rem-calc(16);
						font-weight: normal;

						@include breakpoint(medium) {
							font-size: rem-calc(18);
						}
					}

					.options {
						display: none;
					}
				}
				.product {
					.product-top {
						overflow: hidden;
						margin-bottom: 20px;

						.product-item-photo,
						.product-image-container {
							float: left;
							width: 50px !important;
							text-align: center;

							@include breakpoint(medium) {
								width: 80px !important;
							}
						}
					}

					.product.actions {
						float: none;
						margin: 0;
						position: absolute;
						top: -3px;
						right: -4px;
					}
				}
				.product-item-name {
					display: inline-block;
					margin: 0 0 3px;

					a {
						font-size: rem-calc(13);
						line-height: 1;
						color: $primary-color;

						@include breakpoint(medium) {
							font-size: rem-calc(15);
						}
					}
				}
				.action {
					&.edit {
						display: none;
					}
					&.delete:before {
						content: '';
						width: 30px;
						height: 30px;
						background-image: url("#{$ts-path-to-images}/icon-trash.svg");
						background-repeat: no-repeat;
						background-position: center;
						background-size: auto 44px;
					}
				}
				.update-quote-item {
					@include button-style($primary-color, lighten($primary-color, 10%), $white);
					font-family: $medium-font-family;
					font-size: rem-calc(14);
					padding: 9px 30px;
					border-radius: 50px;
					float: right;
					cursor: pointer;

					@include breakpoint(medium) {
						font-size: rem-calc(15);
						padding: 20px 40px;
					}
				}
			}

			.miniquote-widgets {
				margin: 0;
			}

			#miniquote-movetocart {
				background: transparent;
				width: auto;
				color: $primary-color;
				font-family: $medium-font-family;
				font-size: rem-calc(16);
				border: none;
				border-bottom: 1px solid map-get($foundation-palette, alert);
				border-radius: 0;
				padding: 0 0 5px;

				&:hover {
					border: none;
					border-bottom-color: transparent;
				}

				@include breakpoint(medium) {
					font-size: rem-calc(19);
				}
			}

			.details-qty {
				overflow: hidden;

				.label {
					display: block;
					margin-bottom: 7px;
				}
				.qty-field {
					margin-bottom: 0;

					@include breakpoint(small down) {
						input[type="number"] {
							font-size: 15px;
							height: 32px;
							width: 100px;
						}
						.qty-field__control {
							width: 30px;
							height: 30px;

							span {
								font-size: 24px;
								line-height: 24px;
							}
						}
					}
				}
			}

			.product-link {
				text-align: center;
				margin-top: 20px;
			}
		}

		.section-item-title {
			display: none;
		}

    }// header

}// page-header

.nav-sections-items {
	> div:last-child {
		display: none;
	}
}

.action.showcart {
	background-size: auto 38px;

	.counter {
		bottom: auto;
		top: 3px;
		right: 0;
		padding-left: 0;
		padding-right: 0;
	}
}

.add-to-cart-popup {
	.add-popup-showcart {
		.action {
            &.showcart,
            &.showquote {
                top: auto;
                left: auto;
                transform: none;
            }
            &.showquote {
                display: inline-block;
                width: 44px;
                height: 44px;
                position: relative;
                background-image: url("#{$ts-path-to-images}/icon-check-quote.svg");
                background-size: 31px 34px;
                background-position: 50%;
                background-repeat: no-repeat;

                > .text {
                    @include at;
                }

                .counter.qty {
                    display: inline-block;
                    position: absolute;
                    bottom: auto;
                    top: 5px;
                    right: 3px;
                    min-width: rem-calc(18);
                    height: rem-calc(18);
                    line-height: rem-calc(18);
                    font-size: rem-calc(12);
                    padding: 0;
                    border-radius: 50%;
                    background-color: $success-color;
                    color: $white;
                    text-align: center;
                }
            }
        }
	}
}


// Top menu
//  ---------------------------------------------
.top-menu {
	position: relative;

	.top-menu-button {

		@extend .no-outline;
		border: 0;

		span {
			border-bottom: 1px solid $highlight;
		}

		&.is-active {
			background-color: $button-background;
			box-shadow: inset 2px 2px 4px rgba($black, .5);
		}
	}
	.top-menu-panel {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		margin-top: 6px;
		z-index: 1;
		@include breakpoint(xxlarge) {
			left: -55px;
		}


		.nav-sections {
			width: auto;
			min-width: 190px;
			height: auto;
			padding: 0;
			float: none;
			position: relative;
			@include breakpoint(xxlarge) {
				min-width: 290px;
			}

			&:before {
				content: '';
				display: block;
				position: absolute;
				bottom: 100%;
				left: 50%;
				transform: translateX(-50%);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 16px 16px 16px;
				border-color: transparent transparent $white transparent;
			}

			.navigation {
				margin: 0;
				padding: 0;

				> .ui-menu {
					position: relative;
				}

				.ui-menu {
					border-radius: 8px;

					.ui-menu-item {
						&:first-child {
							> a {
								border-radius: 8px 8px 0 0;
							}
						}
						&:last-child {
							> a {
								border-radius: 0 0 8px 8px;
							}
						}
						> a {
							display: block;
							font-size: $ts-header-menu-dropdown-link-font-size !important;
							font-family: $medium-font-family !important;
							font-weight: $ts-header-menu-dropdown-link-font-weight !important;
							border-bottom: 1px solid $ts-main-border-color;
							padding: $ts-header-menu-dropdown-link-padding !important;
							color: $primary-color !important;
							line-height: normal;
							background-image: url("#{$ts-path-to-images}/icon-arrow-next-gray.svg") !important;
							background-size: 7px auto !important;
							background-position: calc(100% - 22px) 50% !important;
							background-repeat: no-repeat !important;

							&.ui-state-focus,
							&.ui-state-active,
							&.has-active,
							&:hover,
							&:active,
							&:focus {
								color: $white !important;
								background-color: $ts-header-menu-dropdown-link-color;
								background-image: url("#{$ts-path-to-images}/icon-arrow-next-white.svg") !important;
							}

							span {
								display: block;
							}
						}
                        // &.active,
                        // &.has-active {
                        //     > a {
                        //         color: $white !important;
                        //         background-color: $ts-header-menu-dropdown-link-color;
                        //         background-image: url("#{$ts-path-to-images}/icon-arrow-next-white.svg") !important;
                        //     }
                        // }
                    }
                }


                > ul,
                > .ui-menu {
                	display: block;
                	background-color: $white;

                	> li,
                	> .ui-menu-item {
                		display: block;
                		line-height: 1;

                		&.parent {
                			> a:after {
                				display: none;
                			}
                		}

                		&:nth-child(2) {
                			> a {
                				border-radius: 8px 8px 0 0;
                			}
                		}
                		&.menu-type-complex-vertical {
                			.submenu {
                				min-width: 190px;
                				width: auto;
                				height: auto;
                				min-height: 100% !important;
                				top: 0 !important;
                				bottom: auto;
                				left: 100% !important;
                				margin: 0;
                				padding: 0;
                				border: 0 !important;
                				background-color: #eaf0f0;
                				&:before,
                				&:after {
                					display: none;
                				}
                				@include breakpoint(xxlarge) {
                					min-width: 290px;
                				}

                				.ui-menu {
                					line-height: 1;
                					background-color: #eaf0f0;

                					.ui-menu-item {
                						> a {
                							height: auto;
                							line-height: normal;
                							background-color: transparent;
                							&:after,
                							&:before {
                								display: none !important;
                							}

                							&.ui-state-focus,
                							&.ui-state-active,
                							&.has-active,
                							&:hover,
                							&:active,
                							&:focus {
                								color: $white !important;
                								background-color: $ts-header-menu-dropdown-link-color;
                								background-image: url("#{$ts-path-to-images}/icon-arrow-next-white.svg") !important;
                							}

                							span:not(.ui-menu-icon) {
                								display: block;
                								white-space: nowrap;
                								line-height: inherit;
                								padding: 0;
                							}
                						}
                                        // &.active,
                                        // &.has-active {
                                        //     > a {
                                        //         background-color: $ts-header-menu-dropdown-link-color;
                                        //     }
                                        // }
                                        &:last-child {
                                        	> a {
                                        		border-radius: 0;
                                        	}
                                        }
                                    }// ui-menu-item

                                }// ui-menu

                                .i-close-submenu {
                                	display: none;
                                }
                            }
                            &:hover,
                            &:active,
                            &:focus {
                            	> .submenu {
                            		display: block !important;
                            		top: 0 !important;
                            	}
                            }
                        }// menu-type-complex-vertical

                    }// ui-menu-item
                }// ui-menu
            }// navigation

        }// nav-sections
    }// top-menu-panel
}// top-menu

.topmenu-fade-layer {
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba($black,.3);
	z-index: $ts-headbar-zindex - 1;
}// topmenu-fade-layer

#off-canvas-header {
	display: none;
}

// FOOTER
footer.page-footer {
	@include clearfix;
	overflow: visible;
	background-repeat: no-repeat;

	@include breakpoint(medium down) {
		.footer-content .footer-list {
			border-bottom: 1px solid rgba($white,0.15);

			.footer-mobile-accordion {
				float: none !important;
				width: auto;
				margin-left: -1*(map-get($ts-global-space-indent, small));
				margin-right: -1*(map-get($ts-global-space-indent, small));
				padding: 0;
				border-bottom: 0 none;
				border-top: 1px solid rgba($white,0.15);
				margin-bottom: 0;

				h3 {
					font-size: 20px;
					padding: 18px 18px 18px 74px;
					margin-bottom: 0;
					position: relative;

					&:before {
						display: block;
						content: '';
						width: 12px;
						height: 12px;
						position: absolute;
						top: 26px;
						left: 44px;
						background-image: url("#{$ts-path-to-images}/icon-plus--active.svg");
						background-repeat: no-repeat;
						background-size: 100% auto;
						background-position: center;
					}
				}

				ul.links {
					display: none;
					padding: 18px 18px 18px 74px;
				}

				&.is-active {
					h3 {
						background-color: rgba($white, 0.15);
					}
				}
            }// footer-mobile-accordion
        }
    }

    .footer-content {
    	@if $ts-footer-bg-has-image {
    		transition: background-image .3s ease, background-position .3s ease;

    		@if ( not $ts-footer-bg-custom-set ) and ( $ts-footer-bg-type != none ) {
    			background: $ts-footer-bg-color url("#{$ts-path-to-images}/#{map-get($ts-footer-bg-src,small)}.#{map-get($ts-footer-bg-type,small)}") map-get($ts-footer-bg-position, small) no-repeat;
    			background-size: map-get($ts-footer-bg-size,small);

    			@include breakpoint(xlarge) {
    				position: relative;
    				background: none;
    				min-height: 270px;

    				&:before {
    					width: 304px;
    					height: 152px;
    					content: "";
    					position: absolute;
    					left: -152px;
    					background: url("#{$ts-path-to-images}/footer-bubble.png") 0 0 no-repeat;
    				}
    			}
    		}
    	}
        .footer-block {
            .footer.links {
                .phone-link {
                    background: url("#{$ts-path-to-images}/icon-phone-red.svg") 0 50% no-repeat;
                    padding: 0 0 0 31px;
                    background-size: 18px auto;
                }
                .email-link {
                    background: url("#{$ts-path-to-images}/icon-email.svg") 0 50% no-repeat;
                    padding: 0 0 0 31px;
                    background-size: 20px auto;
                }
            }
        }
    }

    .footer-bottom {
    	margin-top: 135px;

    	.footer-list {
    		@include breakpoint(large) {
    			display: flex;
    		}
    	}

    	.footer-block.social {
    		padding-top: 0;
    		padding-bottom: 0;
    		margin-bottom: 30px;

    		@include breakpoint(xlarge) {
    			flex-basis: 33%;
    		}
    	}

    	.footer-social {
    		text-align: center;
    	}

    	.footer-copyright-cookies {
    		text-align: center;

    		@include breakpoint(large) {
    			order: -1;
    			padding-top: 0 !important;
    			text-align: left !important;
    		}

    		@include breakpoint(xlarge) {
    			text-align: left;
    			flex-basis: 33%;
    		}
    	}
    }
} // footer

footer .footer__top {
	background-color: $box-color;
	color: $black;
	text-align: center;
	padding: 40px 20px;

	@include breakpoint(xlarge) {
		padding: 78px 20px 71px;
	}

	.newsletter {
		color: inherit;
		text-align: center;

		h3 {
			color: $primary-color;
			font-size: 32px;

			@include breakpoint(xlarge) {
				font-size: 36px;
			}
		}

		.description {
			color: $black;

			@include breakpoint(xlarge) {
				font-size: 17px;
			}
		}

		.field.newsletter {
			margin-bottom: 20px !important;
		}

		.field.terms {
			input[type="checkbox"] + label,
			input[type="checkbox"] + .label {
				text-align: left;
			}
		}

		.control input {
			border-radius: map-get($ts-footer-subscribe-input-height, small);
			box-shadow: 2px 2px 4px rgba($black, 0.2);
		}

		div.mage-error, div.field-error {
			margin-top: 0.5rem;
			margin-bottom: 0;
		}

		button.action {
			outline: none;
			width: auto !important;
			margin-left: auto !important;
			margin-right: auto !important;
			height: 60px !important;
			min-width: 178px;
		}
	}
}

.main-menu {
	margin-left: -20px;
	margin-right: 0;
	outline: none;

	@include breakpoint(xlarge) {
		margin-right: -20px;
	}


	&__list {
		list-style: none;
		margin: 0;
		display: flex;
	}

	li.level0 {
		margin-left: 5px;
		margin-right: 5px;

		@include breakpoint(xlarge) {
			margin-left: 20px;
			margin-right: 20px;
		}

		&.active {
			> .level0__title:before,
			> .wrapper__title:before {
				content: "";
				position: absolute;
				left: 50%;
				border-bottom-color: $box-color;
			}
		}
	}

    .main-menu__link,
    .level0__title,
    li.level0 > .wrapper__title,
    li.level0 > a {
		font-size: 16px;
		font-family: $bold-font-family;
		color: $white;
		display: block;
		padding-right: 20px;
		position: relative;
		cursor: default;

		@include breakpoint(xlarge) {
			font-size: 20px;
			padding-right: 30px;
		}

		&:after {
			content: "";
			position: absolute;
			width: 20px;
			height: 30px;
			right: 0;
			top: 1px;
			background-image: url("#{$ts-path-to-images}/icon-menu-arrow.svg");
			background-position: 50%;
			background-repeat: no-repeat;
			background-size: 10px auto;

			@include breakpoint(xlarge) {
				width: 30px;
			}
		}

		span {
			display: block;
			border-bottom: 1px solid $highlight;
			color: inherit;
			user-select: none;
            white-space: nowrap;
		}
	}

	ul {
		list-style: none;
		margin: 0;
	}

    .submenu {
        a {
            .is-sticky .header .header-row & {
                color: $primary-color;
            }
        }
    }

	&__submenu {
		&.level0 {
			li {
				padding: 0;
				line-height: 1.4;
				margin-bottom: 9px;

				&.level2 {
					> a {
						margin-bottom: 9px;
					}
				}
			}

			a {
				display: block;
			}

			> li.level1 {
				flex-basis: 33.33333%;
				font-size: rem-calc(15);
				margin-bottom: 30px;
				padding: 0 10px;

				@include breakpoint(xlarge) {
					flex-basis: 25%;
					margin-bottom: 50px;
					padding: 0;
				}

				> a {
					display: inline-block;
					font-family: $bold-font-family;
					font-size: rem-calc(24);
					line-height: 1.2;
					margin-bottom: rem-calc(20);
					border-bottom: 1px solid $highlight;
				}
			}
		}
	}

	&__submenu.level0 {
		position: absolute;
		left: 0;
		right: 0;
		background-color: $box-color;
		display: none;
		margin: 20px 0 0;
		flex-wrap: wrap;
		box-shadow: 4px 4px 16px rgba($black,0.2);
		padding: 20px;

		@include breakpoint(xlarge) {
			padding: 30px 50px;
		}
	}

	a {
		color: $primary-color;
	}
}

.similar-products-wrapper {
	position: relative;

	&.active {

		.similar-products-dropdown {
			display: block;
		}

		.similar-products-trigger {
			border-radius: 30px 30px 0 0;
			border-bottom: none;
		}
    }//&.active

    .similar-products-trigger {
    	border: 1px solid $ts-main-border-color;
    	border-radius: 30px;
    	cursor: pointer;
    	outline: none;
    	min-height: 61px;
    	padding-right: 60px;

    	&:after {
    		content: '';
    		display: block;
    		position: absolute;
    		top: 50%;
    		right: 24px;
    		width: 18px;
    		height: 11px;
    		background: url("#{$ts-path-to-images}/icon-arrow-down--dark.svg") 0 0 no-repeat;
    		background-size: 100%;
    		margin-top: -4px;
    	}
    }//.similar-products-trigger

    .similar-products-dropdown{
    	display: none;
    	position: absolute;
    	top: 100%;
    	background: $input-background;
    	border: 1px solid $ts-main-border-color;
    	border-top: none;
    	border-radius: 0 0 30px 30px;
    	width: 100%;
    	z-index: 10;
    }

    .similar-products-item {
    	padding: 7px;

    	.product__info {
    		font-size: rem-calc(17);
    		padding: 0!important;
    		flex-direction: row!important;
    		align-items: center;

    		.product-image-container {
    			flex-grow: 0!important;
    			margin: 0 6px 0 0;

    			.product-image-wrapper {
    				padding-bottom: 0!important;

    				.product-image-photo {
                        width: auto;
    					max-height: 46px;
    				}
    			}
    		}
        }//.product__info
    }//.similar-products-item
} //.similar-products-wrapper

.page-title-wrapper {
	color: $primary-color;

	.page-title-manufacturer {
		@include grid-col;
		font-family: $body-font-family;
		font-size: rem-calc(20);
		line-height: rem-calc(24);
		margin-bottom: 10px;
	}
}

.category-view {
	@include breakpoint(medium) {
		display: flex;
	}

	#toolbar-amount {
		display: none;
	}

	.page-title-wrapper {
		display: flex;
		align-items: center;
		margin-left: 0;

		.page-title {
            margin-bottom: 0;

			@include breakpoint(medium down) {
				span {
					margin-bottom: 0;
					padding-bottom: 0;

					&:before {
						display: none;
					}
				}
			}
			@include breakpoint(small down) {
				text-align: center;
				margin-bottom: 0;

				span {
					padding-bottom: rem-calc(22);

					&:before {
						display: block;
						border-bottom: 1px solid rgba($black,0.15);
					}
				}
			}
		}
	}

	.toolbar.toolbar-products {
		display: flex;
		align-items: center;
		margin-right: 0;
	}

	.category-header-mobile {
		margin-bottom: rem-calc(22);

		.filter-options-content {
			ul,
			ol {
				li {
					border-bottom: 1px solid rgba($black,0.15);

					&:last-child {
						border-bottom: 1px solid rgba($black,0.15);
					}
				}
			}
		}
	}
}

.block.filter,
.filter-content,
.nav-toolbar-sorter-mobile {
    .action {
        &.clear {
            @include button-style($primary-color, $primary-color);
        }
    }
}

.block.filter,
.filter-content {
	dl.filter-options {
		dt.filter-options-title {
			.tooltip {
				display: none;
			}
		}
		dd.filter-options-content {
			&.has-labels {
				ol,
				ul {
					border-top: none;

					.item {
						border-bottom: none;

						a {
							font-size: rem-calc(15);
							position: relative;
							padding: rem-calc(1 0 3 25);

							&:before {
								content: '';
								display: block;
								width: 14px;
								height: 14px;
								border-radius: 4px;
								box-shadow: 0 0 0 1px #979797;
								position: absolute;
								left: 1px;
								top: 50%;
								transform: translateY(-50%);
							}
							&:after {
								content: '';
								display: block;
								width: 14px;
								height: 14px;
								position: absolute;
								left: 1px;
								top: 50%;
								transform: translateY(-50%);
								background: url("#{$ts-path-to-images}/icon-valid-black.svg") no-repeat center;
								background-size: 11px auto;
								opacity: 0;
							}

							&:hover,
							&:active,
							&:focus {
								&:before {
									box-shadow: 0 0 0 1px $black;
								}
								&:after {
									opacity: 1;
								}
							}
							&.amshopby-link-selected {
								&:before {
									box-shadow: 0 0 0 1px $black;
								}
								&:after {
									opacity: 1;
								}
								.label {
									padding-left: 0;
									background: none;
									font-weight: normal;
								}
							}
						}
					}

					&.am-filter-items-category_ids {
						border-top: 1px solid rgba($black,0.15);

						.item {
							border-bottom: 1px solid rgba($black,0.15);

							a {
								font-size: rem-calc(15);
								padding: $ts-sidebar-main-filter-anchor-padding;
								background: url("#{$ts-path-to-images}/#{$ts-sidebar-mobile-category-image}.#{$ts-sidebar-mobile-category-image-type}") $ts-sidebar-mobile-category-image-position no-repeat;
								background-size: $ts-sidebar-mobile-category-image-size;

								@include breakpoint(large) {
									font-size: rem-calc(17);
								}
								@include breakpoint(xlarge) {
									font-size: $ts-sidebar-main-filter-font-size;
								}

								&:before,
								&:after {
									display: none;
								}

								&.amshopby-link-selected {
									background: url("#{$ts-path-to-images}/icon-valid-black.svg") $ts-sidebar-mobile-category-image-position no-repeat;
									background-size: 15px auto;

									.label {
										padding-left: 0;
										background: none;
										font-weight: bold;
									}
								}
							}
						}
					}
				}
			}
			&.has-ui-slider {
				@include breakpoint(large) {
					padding-bottom: rem-calc(100);
				}
			}
		}
	}
}

.mobile-filter-btn {
	width: 49.5%;
	font-size: rem-calc(15);
	padding-top: rem-calc(16);
	padding-bottom: rem-calc(16);
	margin-right: 1%;
	@include button-style($primary-color, lighten($primary-color, 10%), $white);

	&.open-filter-modal {
		width: 49.5%;
	}
}

.toolbar-sorter {
	width: auto;

	&.sorter {
		@include breakpoint(medium) {
			display: block !important;
		}
	}


	.sorter-label {
		display: none;
	}

	select.sorter-options,
	.sorter-options select {
		border-radius: $ts-category-sorting-toolbar-height;
		padding-left: 1.3125rem;
		padding-right: 3.5rem;
		background-position: calc(100% - 28px) 50%;
	}
}

.filter-options-content {
    &.has-images-labels {
        .am-swatch-options,
        .swatch-attribute-options {
            border-top: none;

            .item {
                border: none;
            }
        }

        .am_shopby_filter_items_attr_color {
            .am-swatch-options,
            .swatch-attribute-options {
                display: flex;
                flex-wrap: wrap;

                .item {
                    margin: 5px 8px 5px 0;

                    &.checked {
                        .am_shopby_swatch_label,
                        .am-shopby-swatch-label {
                            background-color: $primary-color;
                            box-shadow: 1px 1px 2px rgba($black,0.5) inset;

                            .label {
                                color: $white;
                            }
                        }
                    }

                    .am_shopby_swatch_label,
                    .am-shopby-swatch-label {
                        padding: rem-calc(9 20 9 9);
                        box-shadow: inset 0 0 0 1px $ts-color-swatch-border-color;
                        border-radius: 18px;
                        line-height: 18px;
                        font-size: 0;
                        background: $white;

                        .swatch-option.color {
                            box-shadow: 0 0 0 1px $ts-color-swatch-border-color inset;
                        }

                        .label {
                            font-size: 13px;
                            line-height: inherit;
                            margin-left: 6px;
                        }
                    }
                }
            }
        }
    }

    &.has-text-swatch {
        .am_shopby_filter_items_attr_size {
            .am-swatch-options,
            .swatch-attribute-options {
                .item {
                    &.checked {
                        .swatch-option {
                            background-color: $primary-color;
                            box-shadow: 1px 1px 2px rgba($black,0.5) inset;
                            color: $white;
                        }
                    }

                    .swatch-option {
                        &:hover,
                        &:active {
                            color: $ts-size-swatch-color-text;
                            background-color: $ts-size-swatch-bg-color;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

.category-description {
	font-size: rem-calc(16);

	div {
		padding: 0 !important;
	}
}

.algolia-instant-selector-results {
	.page-main {
		.category-header {
			.category-view {
				display: flex;
				align-items: center;
				margin-bottom: 40px;

				.page-title-wrapper {
					flex-grow: 1;
					display: flex;
					align-items: flex-end;
                    margin-bottom: 0;

					&:before,
					&:after {
						display: none;
					}
				}

				.page-title {
					width: auto;
					float: none;
					margin-bottom: 0;
				}

				.toolbar-amount {
					font-size: rem-calc(17);
					line-height: 20px;
					margin-bottom: 3px;

					@include breakpoint(medium) {
						margin-bottom: 6px;
					}
					@include breakpoint(xlarge) {
						margin-bottom: 14px;
					}
				}
			}
		}
	}
}

#refine-toggle {
	margin-bottom: 20px;
}

.qty-field {
	position: relative;
	margin-bottom: 1rem;
	display: inline-flex;

    div.mage-error {
        width: 100%;
        font-size: 14px;
        line-height: 1;
        text-align: left;
        margin-bottom: 0;
        position: absolute;
        top: 100%;
        left: 0;
    }

	&__wrap {
		.label {
			@include element-invisible;
		}
	}

	input[type="number"] {
		text-align: center;
		font-size: 19px;
		margin: 0;
		padding: 0;
		height: 56px;
		border-radius: 30px;
		width: 160px;
		background-color: $input-background;

		&.mage-error {
			background-image: none;
		}

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

        .product-print__dropdown & {
            font-size: 15px;
            height: 32px;
            width: 100px;
        }

        + div.mage-error {
            position: absolute;
            top: 100%;
            margin: 0;
            text-align: left;
            font-size: 12px;

            @include breakpoint(large down) {
                top: 50%;
                left: 100%;
                width: 200px;
                margin-left: 10px;
                transform: translateY(-50%);
            }
        }
	}

	&__control {
		width: 54px;
		height: 54px;
		padding: 0;
		border: 0 none;
		border-radius: 30px;
		overflow: hidden;
		position: absolute;
		top: 1px;
		box-shadow: 2px 2px 3px rgba($black,0.15);
		outline: none;

        .product-print__dropdown & {
            width: 30px;
            height: 30px;
        }

		&--decrease {
			background-color: $white;
			color: $success-color;
			left: -3px;
		}

		&--increase {
			background-color: $success-color;
			color: $white;
			right: -3px;
		}

		span {
			display: block;
			font-size: 36px;
			line-height: 36px;
			font-family: $regular-font-family;
			text-align: center;
			position: relative;
			top: -1px;

            .product-print__dropdown & {
                font-size: 24px;
                line-height: 24px;
            }
		}
	}
}

.catalog-product-view {
	.usp-section {
		padding: rem-calc(30 0);

		&:before {
			display: none;
		}

		ul {
			font-size: 15px;
			display: flex;
			justify-content: space-between;

			&:before,
			&:after {
				display: none;
			}

			li {
				width: auto;
				padding-left: 0;

				a,
				span {
					&:before {
						top: 0;
						width: 22px;
						height: 18px;
						background-size: 22px auto;
					}
				}
			}
		}
	}

	.product-info-wrapper {
		.product-info-sidebar {
			@include breakpoint(large) {
				padding-left: 0;
				padding-right: 0;
			}
			.product-description-wrapper {
				font-size: rem-calc(19);
				line-height: 1.6;
				margin-bottom: 2rem;
			}
		}

		.product-info-mobile-price-social {
			@include breakpoint(xlarge) {
				display: none;
			}
		}

		.box-tocart {
			width: 100% !important;
            float: none;

			.fieldset {
				@include breakpoint(xlarge) {
					display: flex;

					.field.qty {
						margin-right: rem-calc(12);
					}
				}
			}

			.actions {
				@include breakpoint(xlarge) {
					flex-grow: 1;
					display: flex;
				}

                button.action {
                    font-family: $bold-font-family;
					margin-bottom: 1rem;

					@include breakpoint(xlarge) {
						&:first-child:not(:last-child) {
							margin-right: rem-calc(12);
						}
					}
				}
			}
			.product-addtoquote-button {
				line-height: 1;
				height: auto;
				border: none;
				@include button-style($primary-color, lighten($primary-color, 10%), $white);
			}
		}
	}

	.product-stock__container {
		margin-bottom: 40px;

		@include breakpoint(large) {
			display: flex;
		}
	}

	.product-delivery ~ .product-info-stock-sku {
		display: none;
	}

	.product-delivery ~ .pricing__tier {
		@include breakpoint(large) {
			border-left: 1px solid rgba($black,0.15);
		}
	}
}

.pricing__tier {
	flex-grow: 1;
	padding-left: 12px;

	table {
		width: auto;
		margin-bottom: 0;

		tbody tr {
			border: 0 none;
			border-bottom: 0 none;
		}

		th, td {
			font-size: 1rem;
			padding: 0.25em 1.5em;
			border: 0 none;
		}
	}
}

.product-delivery {
	font-size: rem-calc(15);
	padding-right: 2rem;

	&__item {
		position: relative;
		padding-left: 20px;
		margin-bottom: 0.5em;

		&:before {
			position: absolute;
			content: "";
			background-image: url("#{$ts-path-to-images}/icon-valid.svg");
			background-repeat: no-repeat;
			background-size: 12px auto;
			top: 50%;
			left: 0;
			width: 12px;
			height: 10px;
            transform: translateY(-50%);
		}
	}
}

.customer-account-forgotpassword {
	.form.password.forget,
	.form.password.reset {
		@include breakpoint(medium) {
			width: 100%;
		}

		@include breakpoint(xlarge) {
			width: 75%;
		}

		@include breakpoint(xxlarge) {
			width: 60%;
		}

		@include breakpoint(xxxlarge) {
			width: 50%;
		}
	}

	form.forget {
		.field.captcha {
			.control.captcha {
				@include breakpoint(large) {
					display: flex;
					justify-content: space-between;

					> .input-text {
						width: 32%;
					}
				}
			}

			.nested .captcha-image {
				@include breakpoint(large) {
					display: flex;
					gap: 1rem;
				}
			}
		}
	}
}

.product-info__accordion {
	.accordion-item {
		padding: 0;

		&:first-child > :first-child {
			border-radius: 0;
		}

		.product-attr-list {
			list-style: none;
			margin-left: 0;
		}

		&.is-active {
			.accordion-title {
				&:before {
					background-image: url("#{$ts-path-to-images}/icon-minus--red.svg");
				}
			}
		}
	}

	.accordion-title {
		position: relative;
		border-left: 0 none;
		border-right: 0 none;
		text-decoration: none;
		font-size: 17px;
		font-family: $bold-font-family;
		color: $primary-color;
		padding: 22px 20px 20px 40px;

		&:before {
			display: block;
			content: '';
			width: 12px;
			height: 12px;
			position: absolute;
			top: 24px;
			left: 7px;
			background-image: url("#{$ts-path-to-images}/icon-plus--active.svg");
			background-repeat: no-repeat;
			background-size: 100% auto;
			background-position: center;
		}

		:last-child:not(.is-active) > & {
			border-radius: 0;
		}
	}
}

.product-info-main {
    .similar.product-info-form-row {
        margin: 0 0 20px;
    }
}

.product-print__container {
    display: flex;
    flex-direction: column;

	.fieldset {
		.actions {
			display: flex;
			gap: 20px;
			flex-direction: column-reverse;

			@include breakpoint(xxlarge) {
				flex-direction: row;
			}

			.quick-quote-form {
				width: auto;

				button.action {
					width: 100%;
					padding: $ts-product-addtocart-btn-padding;
					@include button-style($primary-color, lighten($primary-color, 10%), $white);
				}
			}
		}
	}

	.product-print__button {
		@include button;
        width: 100%;
		margin-bottom: 0;
		position: relative;
		padding: $ts-product-addtocart-btn-padding;
		transition: background-color 0.3s ease-in, border-radius 0.5s ease-in;

		@include breakpoint(large down) {
			padding-left: 1.3rem;
			padding-right: 1.3rem;
		}
        @include breakpoint(xxlarge) {
            width: 50%;
        }

		&.active {
			background-color: $primary-color !important;
			border-radius: 9px 9px 0 0;
			z-index: 202;
		}
	}

	.mage-dropdown-dialog {
		display: none;
	}

	.ui-dialog {
        position: relative;
        outline: none;
        z-index: 201;
    }

    .ui-dialog-titlebar {
    	position: absolute;
    	right: 25px;
    	top: 20px;

    	@include breakpoint(xlarge) {
    		top: 27px;
			right: 28px;
		}
    }

    .ui-dialog-titlebar-close {
    	outline: none;
        cursor: pointer;
        font-size: 0;

    	.ui-icon-closethick {
    		width: 30px;
    		height: 30px;
    		position: relative;
			display: block;

    		&:before {
    			position: absolute;
    			content: "\00d7";
    			left: 0;
    			right: 0;
    			top: 0;
    			bottom: 0;
    			line-height: 22px;
    			font-size: 45px;
    		}
    	}

    	.ui-button-text {
    		@include element-invisible;
    	}
    }

	.ui-widget-overlay {
        background: rgba($black,.3);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 200;
    }
}

.product-info-stock-sku.mobile {
	.stock.available {
		display: none;
	}
}

.product-add-form {
	display: none;

	&.saleable {
		display: block;
	}
}

.product-print__dropdown {
    .product-add-form {
		&.saleable {
			background-color: $white;
			box-shadow: 5px 5px 20px rgba($black,0.2);
			padding: 20px 25px 30px;

			@include breakpoint(xlarge) {
				padding: 30px 50px 50px;
			}
		}


	}

	.table {
		.table-caption {
			text-align: left;
			font-size: 22px;
			padding-bottom: 0;
		}

		th,
		td {
			border: 0 none;
		}

		thead .item,
		thead .qty {
			visibility: hidden;
		}

		thead th {
			color: rgba($black, 0.5);
			padding-bottom: 0;
		}

		tbody td,
		tbody th {
			padding: 0.2rem 0.625rem 0.2rem 0;

            &.col.qty {
                padding-right: 20px;
            }
		}

		th label {
            font-family: $bold-font-family;
			font-size: 19px;
			line-height: 1.3;
		}

		.qty-field {
			margin-bottom: 0;
		}
	}

	.product-options-wrapper .fieldset {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.table-wrapper {
			flex-basis: 100%;
		}

		.field {
			flex-basis: 100%;

            .label {
                font-family: $medium-font-family;
                font-size: rem-calc(18);
                line-height: rem-calc(22);
                margin-bottom: rem-calc(17);
				color: $black;
				text-align: center;

                &[for="select_1"] {
                    font-size: rem-calc(22);
                    line-height: rem-calc(27);
                    margin-bottom: rem-calc(22);

                    + .control {
                        select {
                            margin-bottom: rem-calc(57);
                        }
                    }
                }

				&.type_print_variant,
				&.type_field {
					text-align: center;
				}
            }

			&:first-of-type {
				.label {
					font-size: rem-calc(22);
					text-align: left;
				}
			}

			.control {
				.question-block {
					font-size: rem-calc(15);
					text-align: center;

					.question-label {
						display: block;
						text-decoration: underline;
						cursor: pointer;
						color: $primary-color;
						margin-bottom: rem-calc(6);

						&:hover,
						&:focus {
							text-decoration: none;
						}
					}
					.ui-dialog {
						text-align: left;

						.note {
							margin-bottom: rem-calc(6);
						}
					}
				}
			}
		}

        script {
            + .field {
                .label {
                    font-size: rem-calc(22);
                    text-align: left;
                }
            }
        }

		.field.file {
			flex-basis: 100% !important;
			margin-top: rem-calc(30);
			margin-bottom: rem-calc(42);

            .label {
                text-align: left;
            }
            .note {
				margin-bottom: rem-calc(6);
            }
            .file-uploader-wait-message {
                display: inline-block;
                margin-top: rem-calc(10);
                color: map-get($foundation-palette, alert);
			}
			.file-requirements-container {
				font-size: rem-calc(15);
				margin-bottom: rem-calc(16);

				@include breakpoint(xxlarge) {
					margin-top: -37px;
					text-align: right;
				}

				.text-box {
					@include breakpoint(xxlarge) {
						display: inline-block;
						width: 48%;
					}
				}

				span {
					text-decoration: underline;
					cursor: pointer;
					color: $primary-color;
					margin-bottom: rem-calc(6);
					display: block;

					&:hover,
					&:focus {
						text-decoration: none;
					}
				}
			}

			.file-uploader-summary {
				margin-top: 20px;

				.preview-image {
					text-decoration: none;
				}
				.action-remove {
					display: inline-block;
					vertical-align: middle;
					width: $ts-delete-icon-width;
					height: $ts-delete-icon-height;
					opacity: .8;
					background: url("#{$ts-path-to-images}/#{$ts-delete-icon}.#{$ts-delete-icon-type}") no-repeat;
					background-size: contain;
					cursor: pointer;
					span {
						@include at;
					}

					&:hover,
					&:active {
						opacity: 1;
					}
				}
			}
		}

		.field ~ .field {
			@include breakpoint(xlarge) {
				flex-basis: 47%;
			}
		}

		.file-uploader-button {
			@include button-extended;
			@include button-expand;
 			@include button-style($primary-color, lighten($primary-color, 10%), $white);
 			color: $button-color;
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
		}

	}

	.price-final_price {
		justify-content: center;
	}

	.product-info-wrapper & {
		.price-container,
		.special-price {
			font-size: rem-calc(20);

			@include breakpoint(xxlarge) {
				font-size: rem-calc(22);
			}
		}

	}

	.box-tocart {
		float: none;
		padding-left: 0;
		padding-right: 0;

		.actions {
			justify-content: center;

			button.action {
				font-size: rem-calc(17);
			}
		}

		button.tocart {
			@include breakpoint(large down) {
				padding-left: 1.3rem !important;
				padding-right: 1.3rem !important;
			}
		}

		.product-addtoquote-button {
			display: none;
		}
	}
}

select {
	background-color: $select-background;
}

.product-items,
.#{$ts-algolia-list--selector},
.#{$ts-algolia-infinite-list--selector} {
    margin: 0 !important;

    .product-item,
    .#{$ts-algolia-item--selector},
    .#{$ts-algolia-infinite-item--selector} {
		box-shadow: 6px 6px 15px transparent;

		.no-touchevents & {
			&:hover,
			&:focus {
				box-shadow: 6px 6px 15px rgba($black,0.15);
			}
		}

        .product-item-details {
            .product-item-inner,
            .product-item-actions {
                @include breakpoint(medium down) {
                    @include element-invisible-off;
                }

                button.action {
                    @include breakpoint(small down) {
                        font-size: rem-calc(16);
                    }
                }
            }
        }

        .product-item-name {
            margin-bottom: 7px;

            mark {
                background-color: transparent;
                color: $primary-color;
            }
        }

		.product-item-photo-container {
			position: relative;

			.similar-group {
				display: none;

				.similar-group__title {
					display: none;
				}
				.similar-group__items {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: $input-background;
					font-size: 0;
					padding: 7%;
					text-align: left;
					z-index: 1;

					.similar-group__item,
					.similar-group__more-items {
						display: inline-block;
						width: 33.33333%;
						height: 33.33333%;
						text-align: center;
						vertical-align: top;
						padding: 3%;
						position: relative;
						font-size: rem-calc(28);
						color: #4c4b4a;

						.product-image-container {
							top: 50%;
							transform: translateY(-50%);
						}
						.similar-group__inside-box {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}
				.product-image-container {
					display: inline-block;
					padding: 0;
					height: auto;

					.product-image-wrapper {
						position: relative!important;
					}
				}
			}
		}

		&:hover,
		&:focus {
			.product-item-photo-container {
				.similar-group {
					display: block;
				}
			}
		}

		.action {
			&.tocart {
				display: none;

				&.toquote {
					display: block;

					&:hover,
					&:focus {
						border: 1px solid transparent;
					}
				}
			}
		}

		.no-touchevents & {
			&:hover,
			&:focus {
				.price-box + .stock.unavailable {
					z-index: 2;
				}
			}
		}

		.product-price-stock {
			.price-box {
				display: flex;
				flex-direction: column;
				gap: 4px;
				font-size: 14px;
				color: $primary-color;

				@include breakpoint(xlarge) {
					font-size: 15px;
				}
			}
		}
    }
}

%product-layer-overlay {
	&:before {
		mix-blend-mode: multiply;
	}
}

.fotorama__wrap--slide .fotorama__stage__frame {
	background-color: $ts-product-photo-overlay-color;

	.fotorama__img {
		mix-blend-mode: multiply;
	}
}

.category-header {
	margin-bottom: 1rem;

	@include breakpoint(xlarge) {
		margin-bottom: 2rem;
	}
}

.algolia-instant-results-wrapper {
	clear: both;
}

.checkout-index-index .legal-info {
    font-weight: bold;
}

.customer-address-index .actions-toolbar {
    margin-bottom: 20px;
}

// Cart2Quote_Quotation styles override
.checkout-cart-index,
.quotation-quote-index,
.dibs_easy_checkout-index-index {
    table {
        &.cart {
            background: $ts-checkout-sidebar-bg;

            tbody {
                tr.item-info:not(:first-child) {
                    border-top: 16px solid $body-background;
                }
            }

            th,td {
                font-size: rem-calc(15);
				padding: 5px 10px;

				@include breakpoint(xxlarge) {
					padding: 5px $ts-cart-table-cell-indent;
				}

				&:first-child {
					width: 130px;
					vertical-align: top;

					@include breakpoint(xlarge down) {
						width: 110px;
                        padding-left: 15px;
					}
				}
            }

            tr {
				@include breakpoint(xlarge down) {
					display: block;
					position: relative;
					@include clearfix;
				}

                &.item-info {
					position: relative;

					@include breakpoint(xlarge down) {
						padding-top: 15px;
						padding-bottom: 15px;
					}

                    th,td {
                        padding-top: 35px;
                        padding-bottom: 30px;

						@include breakpoint(xlarge down) {
							padding-top: 0;
							padding-bottom: 0;
                            vertical-align: top;
                            display: block;
                            float: left;
						}

						&:first-child {
							width: 130px;
							padding-left: 2.5rem;
							vertical-align: top;

							@include breakpoint(xlarge down) {
                                width: 110px;
								padding-left: 15px;
							}
						}
                    }

					.col {
						&.price,
						&.subtotal {
							text-align: right;
						}
					}
                }
                &.print-price-method-label {
                    td {
                        font-family: $medium-font-family;
                        font-size: 18px;
                        line-height: 1.2;
                        padding-top: 11px;
                    }
                }
                &.print-price-calculations {
                    @include breakpoint(xlarge down) {
                        display: flex;
                        align-items: flex-end;
                    }

                    .print-code-flex {
                        display: flex;
                        justify-content: space-between;

                        @include breakpoint(xlarge down) {
                            flex-direction: column;
                            align-items: flex-start;
                        }

						.print-code-name {
							margin-right: 20px;

                            @include breakpoint(xlarge down) {
                                width: 0;
                                white-space: nowrap;
                                margin-bottom: 5px;
                                margin-right: 0;
                            }
						}
                    }
                    .print-price {
                        text-align: right;

						&.subtotal {
                            @include breakpoint(xlarge down) {
                                flex: 1;
                                padding-right: 30px;
                            }
							@include breakpoint(xxlarge) {
								padding-right: $ts-cart-table-cell-indent;
							}
						}
                    }
                }
                &.print-price-options {
                    .item-options {
                        margin: 0 0 10px;

                        .item-option:not(:last-child) {
                            margin: 0 0 10px;
                        }

                        dt,
						dd,
						.item-label,
						.item-name {
                            line-height: 1.3;
                            padding-top: 6px;
                            padding-bottom: 6px;
                        }
						.item-name {
							a {
								margin-right: 5px;
							}
						}
						.item-size {
							display: inline-block;
						}
						.item-print-image {
							display: inline-block;
							margin-top: 11px;

                            @include breakpoint(xlarge down) {
                                position: absolute;
                                left: 22px;
                                top: -37px;
                            }

							img {
								max-height: 80px;

                                @include breakpoint(xlarge down) {
                                    max-width: 70px;
                                    max-height: none;
                                }
							}
						}
                    }
                }
                &.print-price-total {
                    display: flex;
                    border: none;
                    padding: 0;
                    margin: 0;

                    @include breakpoint(xxlarge) {
                        display: table-row;
                    }

                    td {
                        padding-top: 38px;
                        padding-bottom: 52px;

						@include breakpoint(xlarge down) {
							padding-top: 20px;
							padding-bottom: 30px;
						}
                    }
                    td.total-cell {
                        font-family: $medium-font-family;
                        font-size: rem-calc(18);
                        position: relative;

                        @include breakpoint(xlarge down) {
                            width: calc(100% - 110px);
                            padding-right: 30px;
                        }
						@include breakpoint(xxlarge) {
							padding-right: $ts-cart-table-cell-indent;
						}

                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 18px;
                            left: $ts-cart-table-cell-indent;
                            right: $ts-cart-table-cell-indent;
                            border-top: 1px solid #d3d2d1;

							@include breakpoint(xlarge down) {
								top: 8px;
								left: 10px;
								right: 30px;
							}
                        }

                        .amount {
                            float: right;
                        }
                    }
                }
            }

            .product-item-name {
                line-height: 1.2;
                margin: 4px 0 12px;

                a {
                    color: $primary-color;

                    &:hover {
                        color: $primary-color;
                    }
                }
            }

			.product-item-sku,
			.product-item-supplier {
				color: rgba($black,0.7);
			}

            .cart-item-stock-status {
                display: none;
            }

            .field.qty {
				display: flex;
				align-items: center;
				gap: 10px;

                .input-text {
                    border: none;
                    border-radius: 0;
                    height: 30px;
                    font-size: rem-calc(15);
                    text-align: left;
					background-color: $input-background;

					&:focus,
					&[readonly] {
						background-color: $input-background;
					}

                    @include breakpoint(xxlarge) {
                        height: 50px;
						text-align: right;
                    }
                }

                &:after {
                    font-size: rem-calc(22);
                    padding-left: 5px;
                    color: $black;
                    line-height: 30px;

                    @include breakpoint(xlarge) {
                        padding-left: 5px;
                    }
                }

                .control {
                    label {
                        line-height: 30px;
                    }
                }
            }

            .print-qty {
				display: flex;
				align-items: center;
                text-align: right;

                &:after {
                    content: '×';
                    display: inline-block;
                    font-size: rem-calc(22);
                    line-height: 20px;
                    padding-left: 13px;
                    padding-right: 1px;
                    vertical-align: bottom;
                }
            }

            .tier-qty {
                .field-tooltip {
                    display: none;
                }
            }

            .print-price.subtotal {
                font-family: $bold-font-family;
                text-align: right;
            }

			.col.item-image {
				@include breakpoint(xlarge down) {
					margin-bottom: 15px;
				}
				.product-image-container {
					max-width: rem-calc(70);

					@include breakpoint(xxlarge) {
						max-width: rem-calc(110);
					}
				}
			}
            .col.item-info,
			.col.item {
				@include breakpoint(xlarge down) {
					float: none;
					clear: right;
					margin-left: 110px;
					margin-bottom: 15px;
					width: auto;
					padding: 0 20px 0 0;
				}
            }
            .col.qty {
                @include breakpoint(xlarge down) {
                    margin-left: 110px;
                    padding-left: 0;
                }
                @media screen and (max-width: 400px) {
                    margin-left: 20px;
                }
            }
			.print-info {
				@include breakpoint(xlarge down) {
                    float: none;
                    clear: right;
                    margin-left: 110px;
                    width: auto;
                    padding-right: 20px;
				}
			}
            .col.subtotal {
                .price-including-tax,
                .price-excluding-tax,
                .wishlist-price .price-box {
                    font-family: $bold-font-family;
                    line-height: initial;
                }
            }

            .price-including-tax,
            .price-excluding-tax,
            .wishlist-price .price-box {
                line-height: 30px;
            }

            .item-options {
                display: flex;
                flex-wrap: wrap;
                color: $black;
                margin: 27px 0 28px;

                .item-bubble {
                    display: flex;
                    align-items: center;
                    margin: 0 6px 10px 0;
                    padding: 6px 20px;
                    border-radius: 30px;
                    background: white;
                    box-shadow: 2px 2px 6px rgba(0,0,0,.15);
                    font-size: 17px;
                    color: $primary-color;

                    @include breakpoint(xlarge down) {
                        padding: 6px 12px;
                    }

                    .name {
                        font-family: $bold-font-family;
                        margin-right: 10px;

                        @include breakpoint(xlarge down) {
                            margin-right: 8px;
                        }
                    }

                    input[type="number"] {
                        margin: 0;
                        height: auto;
                        text-align: center;
                        width: auto;
                        max-width: 50px;
                        padding: 10px 6px;
                        font-size: 15px;

                        @include breakpoint(xlarge down) {
                            padding: 6px;
                        }
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }

                    /* Firefox */
                    input[type=number] {
                      -moz-appearance: textfield;
                    }
                }
            }
            .control.comment {
                a {
                    color: $primary-color;
                    margin-bottom: 10px;

                    &:hover,
                    &:focus {
                        color: $primary-color;
                    }
                }
                .quotation-product-comment {
                    width: 100%;
                    max-width: 100%;
                    min-height: 172px;
                    border-radius: 0;
                    text-align: left;
                    padding: 10px;
                    font-size: 15px;
                }
				.quotation-product-comment-counter {
					display: none;
					margin-top: 5px;

					&.invalid {
						color: #d41367;
					}
				}
				&.active {
					.quotation-product-comment-counter {
						display: block;
					}
				}
            }
        }
    }
    .opc#checkoutSteps {
        .methods-shipping {
            padding-left: 23px;
            padding-right: 23px;
            @include breakpoint(xlarge) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    .cart-form {
        @include breakpoint(large) {
            @include grid-col-size(6.75);
        }
        @include breakpoint(xlarge) {
            @include grid-col-size(7.9);
        }
    }
    .cart-summary {
        padding: 0 20px;
        background: none;

        @include breakpoint(large) {
            @include grid-col-size(5.25);
            float: right;
        }
        @include breakpoint(xlarge) {
            @include grid-col-size(4.1);
        }

        .block {
            .title {
                strong {
                    font-size: 16px;
                    border-bottom: none;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        table {
            .totals {
                &.grand {
                    border-top: 1px solid #d3d2d1;
                }
            }
        }

        .cart-totals {
			margin-left: 0;
			margin-right: 0;

            th {
                white-space: nowrap;

                .label {
                    white-space: nowrap;

                    @include breakpoint(xxlarge down) {
                        white-space: normal;
                        min-width: 225px;
                    }
                }
            }
        }

        .customers-search {
            .input-text {
                position: relative;
                z-index: 1;
            }
            .customers-dropdown {
                margin-top: calc(-1 * (.58333rem + 23px));
                border: 1px solid #9da3af;
                overflow: hidden;
                padding-top: 21px;
                border-bottom-left-radius: 25px;
                border-bottom-right-radius: 25px;
                box-shadow: 0 0 10px rgba(0,0,0,.15);

                .search-result {
                    border-bottom: 1px solid #9da3af;

                    &:last-child {
                        border-bottom: none;
                    }

                    .customer {
                        font-size: rem-calc(16);
                        padding: 6px 20px;
                        cursor: pointer;

                        &:hover {
                            background-color: $input-background;
                        }

                        .label {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .form.form-shipping-address {
            .control._with-tooltip {
                .field-tooltip.toggle {
                    display: none;
                }
            }
        }
    }

    .action.primary.checkout.movetoquote.movetoquote-button,
    .action.primary.checkout.movetocart.movetocart-button {
        font-size: ($ts-checkout-main-button-font-size*.75);
        line-height: 1;
        padding-top: $ts-checkout-main-button-top-indent;
        padding-bottom: $ts-checkout-main-button-bottom-indent;

        @include breakpoint(xxlarge) {
            font-size: $ts-checkout-main-button-font-size;
        }

        &:hover,
        &:focus {
            border: 1px solid transparent;
        }
    }

	.action.primary.checkout.movetoquote.movetoquote-button {
		display: inline;
		width: auto;
		background: none;
		padding: 0;
		font-family: $medium-font-family;
		font-size: rem-calc(14);
		color: $primary-color;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

    .action {
        &.action-edit,
		&.action-towishlist {
            display: none;
        }
        &.action-delete {
            display: inline-block;
        }
    }
}

.checkout-methods-items {
	.cart.main.actions {
		margin-top: 20px;
		text-align: center;
	}
}

.checkout-cart-index,
.dibs_easy_checkout-index-index {
	table {
		&.cart {
			.col.qty {
				@include breakpoint(xlarge down) {
					margin-left: 0;
					padding-left: 20px;
                    padding-right: 0;
				}
			}
			.col {
				&.price,
				&.subtotal {
					@include breakpoint(xlarge down) {
						line-height: 30px;
						padding: 0 10px;
					}
				}
			}
			.col.actions {
				@include breakpoint(xlarge) {
					padding-right: 15px;
				}
                @include breakpoint(xxlarge) {
                    padding-right: 40px;
                }
			}
		}
	}
}

.quotation-quote-index {
	.quote-title-container {
		@include grid-row;

		.page-title-wrapper {
			@include grid-col;
			display: flex;
			align-items: flex-end;
			padding: 0;

			@include breakpoint(large) {
				@include grid-col-size(7.9);
			}
            @include breakpoint(small down) {
                flex-wrap: wrap;
            }

			.page-title {
				padding: 0;

                @include breakpoint(large down) {
                    font-size: rem-calc(30);
                }
                @include breakpoint(small down) {
                    margin-bottom: 10px;
                }
			}
		}
		.quote-totalsum {
			display: flex;
			font-size: 22px;
			color: $primary-color;

            @include breakpoint(xlarge) {
                margin-bottom: 10px;
            }
			@include breakpoint(xxlarge) {
				font-size: 36px;
                margin-bottom: 5px;
			}

			.total {
				margin-right: 10px;
				white-space: nowrap;

				@include breakpoint(xlarge) {
					margin-right: 15px;
				}
			}
		}
	}

    table {
        &.cart {

            tbody {
                border-bottom: 2px solid $body-background!important;
            }

            tr {
                position: relative;

                &.item-info {
                    border-width: 2px!important;

                    th,td {
                        padding-left: 10px;
                        padding-right: 10px;
						vertical-align: top;

						@include breakpoint(xlarge down) {
							padding-top: 0;
							padding-bottom: 0;

                            &:first-child {
                                float: left;
                            }
						}
                    }

                    .product-item-details {
                        position: relative;
                    }

                    .tier-prices-tooltip {
                        display: none;
                        padding: 12px 20px;
                        position: absolute;
                        top: 100%;
                        margin-top: 10px;
                        background: $ts-checkout-sidebar-bg;
                        box-shadow: 4px 4px 15px rgba(0,0,0,.2);
                        z-index: 1;

                        strong {
                            display: block;
                            margin-bottom: 6px;
                        }

                        .quote-pricing-tier {
                            margin: 0;

                            tbody {
                                border: none !important;
                            }
                            tr {
                                display: table-row;
                                th,
                                td {
                                    display: table-cell;
                                    float: none !important;
                                    padding: 2px 0 !important;
                                    width: auto !important;
                                    font-size: 14px;
                                }
                                th {
                                    font-weight: bold;
                                    padding-right: 20px !important;
                                }
                            }
                        }
                    }

                    &:hover {
                        .tier-prices-tooltip {
                            @include breakpoint(large) {
                                display: block;
                            }
                        }
                    }
                }

                .field.qty {
                    padding: 0;
					margin-bottom: 8px;

					@include breakpoint(large down) {
						margin-bottom: 10px;
					}

                    &:after {
                        display: none;
                        content: '';
                    }

					&.with-supplier-cost {
						margin-bottom: 38px;

						@include breakpoint(large down) {
							margin-bottom: 42px;
						}
					}

					.control {
						&.qty-field {
							margin-bottom: 0;

							input[type="number"] {
								text-align: center;
								font-size: 19px;
								margin: 0;
								padding: 0;
								height: 56px;
								border: 1px solid #cecece;
								border-radius: $input-radius;
								background: $input-background;
								width: 160px;
							}

							@include breakpoint(xlarge down) {
								input[type="number"] {
									font-size: 15px;
									height: 32px;
									width: 100px;
								}
								.qty-field__control {
									width: 30px;
									height: 30px;

									span {
										font-size: 24px;
										line-height: 24px;
									}
								}
							}
						}

						&.qty {
							height: 56px;
							display: flex;
							align-items: center;

                            @include breakpoint(xlarge) {
                                justify-content: flex-end;
                            }
							@include breakpoint(xlarge down) {
								height: 32px;
							}

                            .qty-wrapper {
                                display: flex;
                                justify-content: flex-end;

                                @include breakpoint(large down) {
                                    justify-content: space-between;
                                }

                                .qty-x {
                                    margin-left: 5px;
                                }
                            }
						}
					}
                    .actions-toolbar {
                        display: none;
                    }
                }

                .col.item-image {
                    text-align: center;

                    .all-images-link {
                        font-family: $medium-font-family;
                        display: block;
						max-width: 110px;
						white-space: normal;
                        margin: 10px 0 0;

						@include breakpoint(xlarge down) {
							font-size: rem-calc(13);
						}

						&:hover,
						&:focus {
							color: $black;
						}
                    }
                }

				.product-item-sku {
					@include breakpoint(xlarge) {
						margin-bottom: 3px;
					}
				}

				.col.qty {
					position: relative;

					@include breakpoint(xlarge down) {
						padding-bottom: 0;
					}

					.min-qty-notification {
						position: absolute;
						bottom: -5px;
						font-size: 13px;
						white-space: nowrap;
						color: map-get($foundation-palette, alert);

						@include breakpoint(xlarge) {
							bottom: 0;
						}
						@include breakpoint(xxlarge) {
							top: 10px;
							bottom: auto;
							font-size: 15px;
						}
					}
				}

                .col.price {
					position: relative;
					max-width: 130px;

                    .quote-item-price-component {
						margin-bottom: 10px;

                        > .price-wrapper {
                            border: 1px solid #cecece;
                            border-radius: $input-radius;
                            background: $input-background;
                        }
                    }
                    .item-price {
                        text-align: center;
                        height: 54px;
						padding-left: 10px;
						padding-right: 10px;
                        @include remove-number-appearance;

						@include breakpoint(xlarge down) {
							font-size: 15px;
							height: 32px;
						}
                    }
					.product-item-cost {
						position: relative;
						min-height: 20px;
						margin-bottom: 10px;

						.inner {
							white-space: nowrap;
							position: absolute;
							right: 0;
							color: rgba($black,0.7);
						}
					}
                    .price-including-tax,
                    .price-excluding-tax,
                    .wishlist-price .price-box {
                        line-height: 52px;
                        width: 100%;
                        text-align: center;

                        @include breakpoint(xlarge down) {
							line-height: 30px;
							margin-top: 0;
						}

                        @include breakpoint(xxlarge) {
                            font-size: rem-calc(18);
                        }
                    }
                }

                .col.subtotal {
                    text-align: right;
                    .price-including-tax,
                    .price-excluding-tax,
                    .wishlist-price .price-box {
                        font-family: $bold-font-family;
						line-height: 42px;

                        @include breakpoint(large) {
                            font-size: rem-calc(18);
                        }
                    }
                }

                .col.actions {
					padding: 0;

					@include breakpoint(large down) {
						position: absolute;
						top: 0;
						right: 0;
					}

                    .action-delete {
                        width: 40px;
                        height: 40px;
						background: none;
						text-align: center;
                        @include breakpoint(xlarge) {
                            width: 30px;
                            margin-top: -15px;
                        }
                        @include breakpoint(xxlarge) {
                            width: 40px;
                            margin-top: 0;
                        }

						&:before {
							content: '\e905' !important;
							display: inline-block;
							font-family: 'cart2quote-iconset' !important;
							color: #000;
							font-size: 24px;
							line-height: 40px;
							margin: 0;
							overflow: hidden;
							text-align: center;
							vertical-align: top;
						}
                    }
                }

                .action.update {
                    width: 100%;
                    font-size: rem-calc(15);
                    @include button-style($primary-color, lighten($primary-color, 10%), $white);
                }
            }
        }
    }

    .page-container {
        .cart-container {
            .form.form-cart {
                margin-bottom: 30px;
                order: 1;
                width: auto;
                margin-left: -1*(map-get($ts-global-space-indent, small));
                margin-right: -1*(map-get($ts-global-space-indent, small));

                @include breakpoint(large) {
                    @include grid-col;
                    @include grid-col-size(7.9);
                    margin-bottom: 0;
                    margin-left: 0;
                    margin-right: 0;
					max-width: 100% !important;
                }

                .cart.main.actions {
                    display: none;
                }
            }
            .quote-details-wrapper {
				margin-left: -1*(map-get($ts-global-space-indent, small));
				margin-right: -1*(map-get($ts-global-space-indent, small));
				width: auto;
				order: 2;
				padding: 0;

				@include breakpoint(large) {
					@include grid-col;
					@include grid-col-size(4.1);
				}

                .authentication-wrapper {
                    display: none;
                }

                .step-title {
                    border-top: none!important;
                    border-bottom: none!important;
                    cursor: default;
                    strong {
                        font-size: rem-calc(18);
                        text-decoration: none;
                        color: $primary-color;
                        font-family: $regular-font-family;
                        border: none!important;
                        padding: 0!important;
                        @include breakpoint(xlarge) {
                            font-size: rem-calc(28);
                        }
                    }

                    &:after {
                        content: '';
                        display: none;
                    }
                }

                .shipping-address-item {
                    border: none;
                    width: 100%!important;

                    label {

                        &:before {
                            top: 5px;
                        }
                        &:after {
                            top: 8px;
                        }
                    }
                    .col-row {

                        .shipping-address {
                            width: 100%;
                            font-size: rem-calc(15);
                            line-height: 1.5;
                            padding-left: 25px;

                            @include breakpoint(xlarge) {
                                font-size: rem-calc(17);
                                line-height: 1.76;
                                padding-left: 35px;
                            }

                            strong {
                                font-weight: 400;
                                font-size: rem-calc(15);
                                line-height: 1.76;

                                @include breakpoint(xlarge) {
                                    font-size: rem-calc(17);
                                }
                            }
                        }
                    }
                }

				.quote-details-container {
					width: auto;
					padding: 0;

                    .loading-mask {
                        min-height: 80px;
                        position: relative;
                    }
				}

                .upload-file-button {
                    @include button;
                    @include button-style($primary-color, lighten($primary-color, 10%), $white);
                    font-size: rem-calc(15);
                    font-family: $bold-font-family;
                    height: 46px;
                    padding-top: 1rem;
                    margin: 0;
                }
                .upload-ext {
                    font-size: rem-calc(13);
                    font-family: $regular-font-family;
                    opacity: .5;
                }
                input[type="text"],
                .input-text,
                textarea,
                select {
                    font-size: rem-calc(15);
                    background-color: $white;
                    border: $input-border;
                }

                input[type="text"],
                .input-text,
                select {
                    height: 46px;
                    padding: 3px 14px;
                }
                select {
                    padding-right: 40px;
                    background-position: calc(100% - 15px) 50%;
                }

                input[type="file"] {
                    overflow: hidden;
                    @include at;
                }

                .file-upload-container {
					width: 100%;
                    background: none;
                    padding: 0;
                    margin: 0;
					border-bottom: 1px solid rgba(60, 71, 97, .2);

                    .step-title {
                        color: $primary-color;
                        font-size: rem-calc(17);
                        border-top: 1px solid rgba(60, 71, 97, .2) !important;
                        font-family: $medium-font-family;
                        padding: 21px 36px 21px 46px;
                        margin: 0;
                        cursor: pointer;
                        position: relative;
                        @include breakpoint(large) {
                            padding: 21px 26px;
                        }
                        @include breakpoint(xlarge) {
                            padding: 21px 36px;
                        }

                        &:before {
                            display: block;
                            content: '';
                            width: 12px;
                            height: 12px;
                            position: absolute;
                            top: 24px;
                            left: 24px;
                            background-image: url("#{$ts-path-to-images}/icon-plus--active.svg");
                            background-repeat: no-repeat;
                            background-size: 100% auto;
                            background-position: center;
                            @include breakpoint(large) {
                                left: 4px;
                            }
                        }
                    }
                    [data-role="content"] {
                        margin-top: 0;
                        padding-left: 23px;
                        padding-right: 23px;
                        @include breakpoint(xlarge) {
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                    &.active {
                        .step-title {
                            &:before {
                                background-image: url("#{$ts-path-to-images}/icon-minus--red.svg")
                            }
                        }
                    }
                    ul:not(.uploaded-files) {
                        margin-left: 0;
                    }
                    .uploaded-files {
                        margin: 0;
                        border: none;
                    }
                    .add-file-button {
                        font-size: rem-calc(15);
                        font-family: $medium-font-family;
                        text-decoration: underline;
                        color: $primary-color;
                        cursor: pointer;
                    }
                    .action.upload {
                        @include button;
                        @include button-hollow-style;
                        @include button-hollow;
                        font-size: rem-calc(19);
                        font-family: $medium-font-family;
                        margin: 24px 0 30px;
                    }
                    .upload-remove-file {
                        text-decoration: none;

                        .icon-c2q_dustbin {
                            font-size: 24px;
                            vertical-align: middle;
                            margin: -10px 0 0;
                            display: inline-block;
                        }
                    }
                }

                .movetocart.movetocart-button {
                    background: none;
                    margin: 4px 0 0;
                    padding: 0;
                    color: $primary-color;
                    font-size: rem-calc(19);
                    font-family: $medium-font-family;

                    span {
                        position: relative;

                        &:after {
                            position: absolute;
                            content: '';
                            display: block;
                            height: 1px;
                            background-color: $highlight;
                            left: 0;
                            width: 100%;
                            bottom: -4px;
                        }
                    }
                }
                #co-print-quote-form {
                    .action.checkout {
                        @include button;
                        @include button-hollow-style;
                        @include button-hollow;
                        font-size: rem-calc(19);
                        font-family: $medium-font-family;
                    }
                }
                .opc-wrapper {

                    .opc#checkoutSteps {
                        @include lsn;

                        .checkout-shipping-address,
						.checkout-quote-details,
                        #billing {
                            margin: 48px 0 0;
                            padding-left: 23px;
                            padding-right: 23px;
                            @include breakpoint(large) {
                                padding-left: 0;
                                padding-right: 0;
                            }
                        }
                        .additional-fields {
                            border-bottom: 1px solid rgba(60, 71, 97, .2) !important;

                            .step-title {
                                color: $primary-color;
                                font-size: rem-calc(17);
                                font-family: $medium-font-family;
                                border-top: 1px solid rgba(60, 71, 97, .2) !important;
                                padding: 21px 36px 21px 46px;
                                cursor: pointer;
                                margin: 0;
                                position: relative;
                                @include breakpoint(xlarge) {
                                    padding: 21px 36px;
                                }

                                &:before {
                                    display: block;
                                    content: '';
                                    width: 12px;
                                    height: 12px;
                                    position: absolute;
                                    top: 24px;
                                    left: 24px;
                                    background-image: url("#{$ts-path-to-images}/icon-plus--active.svg");
                                    background-repeat: no-repeat;
                                    background-size: 100% auto;
                                    background-position: center;
                                    @include breakpoint(xlarge) {
                                        left: 4px;
                                    }
                                }
                            }
                            [data-role="content"] {
                                margin-top: 20px;
                                padding-left: 23px;
                                padding-right: 23px;
                                @include breakpoint(xlarge) {
                                    padding-left: 0;
                                    padding-right: 0;
                                }
                            }
                            &.active {
                                .step-title {
                                    &:before {
                                        background-image: url("#{$ts-path-to-images}/icon-minus--red.svg")
                                    }
                                }
                            }
                        }
                    }
                    #checkout-step-billing {
                        .billing-same-as-shipping {
                            @include checkbox();
                            padding: 13px 0;
                        }
                    }
                    #checkout-step-shipping {

                        .action-show-popup {
                            margin-top: 20px;
                            font-size: rem-calc(15);
                            text-decoration: underline;
                            color: $primary-color;
                            font-family: $medium-font-family;
                            cursor: pointer;
                        }
                    }
                }

                .admin__control-textarea {
                    min-height: 130px;
                    border-radius: 5px;
                    margin: 0;
                }
                .cart-summary {
                    padding: 0;
                    margin-bottom: 10px;
                    // keep loader only over summary
                    position: relative;
                    // remove sticky cart summary behavior
                    position: unset !important;

                    ol {
                        margin-left: 0;
                    }
                    .form.form-login {
                        padding: 0;
                        box-shadow: none;

                        .field {
                            padding: 0;
                            width: 100%;
                        }
                        .actions-toolbar {
                            @include grid-row-nest();
                            padding-top: 10px;
                        }
                    }
                    .field-tooltip {
                        .label {
                            display: none;
                        }
                    }
                    .checkout-methods-items {
                        padding: 0;
                        margin-top: 30px;
                    }

                    #co-add-to-quote-form {
                        margin-top: 43px;
                        padding-left: 23px;
                        padding-right: 23px;
                        @include breakpoint(xlarge) {
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                }
                .fieldset {
                    .field {
                         .label {
                            font-weight: 400;
                            font-family: $medium-font-family;
                        }
                    }
                }
                .table-checkout-shipping-method {
                    table-layout: auto;

                    td {
                        text-align: left;
                        padding-left: 0;
                        padding-right: 5px;
                    }
                    input[type="radio"] {
                        margin-top: 5px;
                    }
                }
                #fileUpload {
                    .upload-header,
                    .upload-row {
                        p,
                        div {
                            font-family: $medium-font-family;
                            font-size: rem-calc(15);
                            color: $primary-color;
                            font-weight: normal!important;
                        }
                        .upload-row-title {
                            padding-right: 20px;
                        }
                        .upload-row-title,
                        .upload-first-cell {
                            width: 43%;
                        }
                        .upload-second-cell,
                        .upload-row-file {
                            width: 57%;
                        }
                    }
                }//#fileUpload
            }
        }
    }

    div._required label > span::after {
        content: '*'
    }
}


aside.modal-popup {
	@include breakpoint(medium down) {
		&.modal-slide {
			left: 0;

			.modal-content {
				padding-bottom: 40px;
			}
		}
	}
	&.popup-authentication {
		.action-close {
			top: 0;
			right: 0;
		}

		.modal-inner-wrap {
			background-color: $white;
			opacity: 1;
			min-height: auto;
			width: 90%;
			margin: 20px auto;

			@include breakpoint(medium down) {
				background-color: $white !important;
				min-height: auto !important;
			}
			@include breakpoint(medium) {
				margin: 80px auto;
			}
			@include breakpoint(large) {
				width: 75%;
			}

			.login-container {
				.block-title {
					font-size: 22px;

					@include breakpoint(xxlarge) {
						font-size: 28px;
					}
				}
			}

			.form-login {
				padding: 20px;

				@include breakpoint(xxlarge) {
					padding: 30px;
				}

				.field {
					@include grid-col;

					@include breakpoint(xxlarge) {
						@include grid-col-size(6);
					}

					&.additional {
						@include grid-col-size(12);
						margin-bottom: 20px;
					}
				}
			}

			.block-customer-login,
			.block-new-customer,
			.block-new-company {
				@include grid-col;

				@include breakpoint(xlarge) {
					@include grid-col-size(6);
				}
			}
		}
	}
	&.popup-quote-image {
		.modal-inner-wrap {
			max-width: 800px;
		}
		.modal-header {
			padding-bottom: 30px;

			.modal-title {
				border-bottom: none;
			}
		}

		.quote-images {
			.quote-image-label {
				display: inline-block;
				margin: 0 15px 15px 0;

				input[type="radio"] {
					display: none;

					&:checked ~ .quote-image {
						border-color: $primary-color;
					}
				}
			}
			.quote-image {
				border: 2px solid transparent;
				cursor: pointer;
			}
		}
		.modal-footer {
			text-align: center;

			button {
				margin: 0 10px;
			}
		}
	}

	&.nets-popup-wrapper {
		#nets-popup-iframe {
			min-height: 800px;
			width: 100%;

			@include breakpoint(small down) {
				min-height: 900px;
			}
		}
		.action-close {
			top: 0;
			right: 0;
		}
		.modal-inner-wrap {
			max-width: 770px;
			opacity: 1;
			margin: 0;
		}
		.modal-header {
			padding-bottom: 30px;
		}
		.modal-title {
			border-bottom: none;
		}
		.modal-footer {
			display: none;
		}
	}

	&.filters {
		.action-close {
			width: 35px;
			height: 35px;
			font-size: 0;
			top: 27px;
			right: 20px;
			padding: 0;
			background-image: url("#{$ts-path-to-images}/icon-close--white-bold.svg");
			background-size: 20px auto;
			background-repeat: no-repeat;
			background-position: 50%;
			&:before {
				display: none;
			}

			+ .close-filter-modal {
				@include element-invisible;
			}
		}

		.modal-inner-wrap {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			min-height: 100%;
			margin: 0;
			box-shadow: none;
			background-color: $primary-color;
		}

		.modal-header {
			padding: rem-calc(50 35 18);
			text-align: left;
		}
		.modal-footer {
			padding-left: rem-calc(35);
			padding-right: rem-calc(35);
		}
		.modal-header,
		.modal-footer {
			button:not(.action-close) {
				@include button-style($button-background, $button-background-hover);
			}
		}

		.modal-title,
		.sorter-title {
			font-size: 24px;
			font-weight: normal;
			font-family: $bold-font-family;
			color: $white;
		}
		.modal-sub-title {
			@include element-invisible;
		}



		.nav-toolbar-sorter-mobile,
		.nav-layered-mobile {
			margin-left: -($ts-modal-popup-padding / 2 );
			margin-right: -($ts-modal-popup-padding / 2 );

			.action.clear {
				@include button-style($button-background, $button-background-hover);
			}

			.filter-options {
				border-bottom: 1px solid rgba($white, .3);

				.tooltip {
					display: none;
				}

				.filter-content {
					.filter-options-title {
						outline: none;
						padding: rem-calc(10 50 10 35);
						font-size: rem-calc(19);
						line-height: 1.6;
						border-top: 1px solid rgba($white, .3);
						color: $white;
						position: relative;

						&:first-child {
							display: block;
						}

						&:after {
							content: '';
							display: block;
							width: 10px;
							height: 18px;
							position: absolute;
							top: 50%;
							right: 30px;
							transform: translateY(-50%) rotate(90deg);
							background: url("#{$ts-path-to-images}/icon-arrow-next-light.svg") no-repeat center;
							background-size: 10px auto;
						}
					}
					.filter-options-content {
						padding-left: rem-calc(35);
						padding-right: rem-calc(35);
						padding-bottom: rem-calc(25);

						&.has-ui-slider {
							padding-bottom: rem-calc(40);

							.ui-slider {
								border-color: $white;

								&:after {
									background: $white;
								}
								.ui-slider-handle {
									background: $success-color;
								}
							}
							.min-value-container,
							.max-value-container {
								color: $white;
							}
						}
						&.has-labels {
							ol,
							ul {
								border-top: none;

								.item {
									border-bottom: none;

									a {
										font-size: rem-calc(15);
										color: $white;
										position: relative;
										padding: 4px 0 4px 34px;
										background: none;

										&:before {
											content: '';
											display: block;
											width: 21px;
											height: 21px;
											border-radius: 4px;
											background-color: $white;
											position: absolute;
											left: 0;
											top: 50%;
											transform: translateY(-50%);
											box-shadow: none;
										}
										&:after {
											content: '';
											display: block;
											width: 21px;
											height: 21px;
											position: absolute;
											left: 0;
											top: 50%;
											transform: translateY(-50%);
											background: url("#{$ts-path-to-images}/icon-valid-white.svg") no-repeat center;
											background-size: 15px auto;
											opacity: 0;
										}

										&:hover,
										&:focus,
										&:active {
											&:before {
												background-color: #d52469;
											}
											&:after {
												opacity: 1;
											}
										}
										&.amshopby-link-selected {
											&:before {
												background-color: #d52469;
											}
											&:after {
												opacity: 1;
											}

											.label {
												padding-left: 0;
												background: none;
												font-weight: normal;
											}
										}

										.label {
											color: $white;
										}
									}
								}
							}
						}
					}
				}
			}

			.filter-options-content {
				&.has-images-labels {
					.am_shopby_filter_items_attr_color {
						.am-swatch-options,
						.swatch-attribute-options {
							.item {
								&.checked {
									.am_shopby_swatch_label,
									.am-shopby-swatch-label {
										background-color: $success-color;
									}
								}
							}
						}
					}
				}

				&.has-text-swatch {
					.swatch-option {
						&:hover,
						&:active,
						&.selected,
						&.checked {
							background-color: $success-color;
						}
					}
				}
			}

			.filter-actions {
				padding-left: rem-calc(35);
				padding-right: rem-calc(35);
			}

			.am-filter-items-attr_price {
				max-width: none;
			}
		}
		.nav-toolbar-sorter-mobile {
			ol,
			ul {
				border-top: 1px solid rgba($white, .3);

				&.items {
					.item {
						border-bottom: 1px solid rgba($white, .3);

						&:last-child {
							border-bottom: 1px solid rgba($white, .3);
						}
						&.active {
							a {
								background: none;

								&:after {
									left: auto;
									right: rem-calc(30);
								}
							}
						}

						a {
							background-image: url("#{$ts-path-to-images}/icon-arrow-next-light.svg");
							background-position: calc(100% - 30px) 50%;
							background-size: 10px auto;
						}
					}
				}
			}
		}
	}

    &.confirm {
        .modal-inner-wrap {
            @include breakpoint(medium) {
                width: 55%;
            }
            @include breakpoint(xxxlarge) {
                width: ($ts-modal-popup-width / 2);
            }
        }
    }

    &.new-customer-address {
        .modal-inner-wrap {
            max-width: 800px;

            .form-shipping-address {
                .fieldset {
                    @include grid-row;
                    @include grid-row-nest;

                    > .field {
                        @include grid-col;

                        @include breakpoint(medium) {
                            @include grid-col-size(6);
                        }

                        label.label {
                            font-family: $medium-font-family;
                        }

                        input[type="text"],
                        .input-text,
                        select {
                            height: 46px;
                            padding: 3px 14px;
                        }

                        .field-tooltip.toggle {
                            display: none;
                        }

                        &.choice {
                            @include grid-col-size(12);
                            margin: 20px 0 0;
                        }
                    }

                    legend {
                        display: none;
                    }
                }
            }

            .modal-footer {
                text-align: center;

                button.action {
                    margin: 0 7px;
                }
            }
        }
    }
}

.b-page-checkout {
	.header {
		.header-row {
			.logo {
				img {
					margin-top: rem-calc(-35);
				}
			}
		}
	}
}

.authentication-wrapper {
	position: relative;
	margin-bottom: rem-calc(30);
	top: 0;
}

input.input-text {
	background-color: $white;
}

.checkout-steps {
	.checkout-box {
		input.input-text {
			height: 46px;
			padding: 3px 14px;
			font-size: rem-calc(14);
			background-color: $white;
		}
		textarea,
		select {
			font-size: rem-calc(14);
			background-color: $white;
		}
	}
}

.header-controls {
	flex-grow: 1;
}

.checkout-onepage-success {
	.block.newsletter {
		display: none;
	}
}

// IHB-468 My account "Mine Tilbuder" page styles fixes
.account {
	.page-subtitle-wrapper {
		.page-subtitle {
			margin-bottom: 10px;
		}
		span.label {
			vertical-align: baseline;
		}

		.actions.quote {
			margin-bottom: 20px;
		}
	}
	.pager {
        .toolbar-amount {
            @include grid-col-size(12);
        }
        .pages {
            margin-top: 0;

            .pages-items {
                .item {
                    @include mq-specific-property(margin, $ts-pagination-item-outside-indent);
                }
            }
        }
		.limiter {
            @include grid-col-size(12);

			.limiter-options {
				height: 40px;
			}
		}
	}
	.actions.quote {
		.action.secondary.cart2quote-reject-btn {
			width: auto;
			margin-bottom: 10px;
		}
	}
    .order-products-toolbar {
        .convert-paging {
            @include grid-col;
            margin-bottom: 20px;
        }
    }
    .form-add-user {
        .field-over {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
.order-details-items {
	.order-title {
		display: none;
	}
	.table-order-items {
		.c2q-price {
			font-size: inherit;
		}
		.price-including,
		.price-excluding {
			span {
				display: inline;
				font-size: inherit;
				padding: 0;
			}
		}

		tfoot {
			border-top: 1px solid #ccc;
			font-size: 16px;

			tr {
				th,
				td {
					font-size: inherit;
				}
				&.grand_total {
					th,
					td {
						padding-top: 10px;
						font-size: 18px;
						font-weight: bold;
					}
				}
			}
		}
	}
	.items-qty {
		line-height: 1.4;
	}
	.item-options {
		font-size: 13px;
		margin-top: 20px;

		dt,
		dd {
			float: none;
		}
		dt {
			font-weight: bold;
			margin-bottom: 5px;
		}
		dd {
			margin-bottom: 20px;
		}
	}
	.print-price-cart-totals {
		table {
			tr {
				th,
				td {
					padding-top: 10px;
					padding-bottom: 10px;

					&:first-child {
						padding-left: 0;
					}
					&:last-child {
						padding-right: 0;
					}
				}
			}
		}
	}
	.item-print-image {
		display: inline-block;
		margin-top: 10px;

		img {
			max-height: 80px;
		}
	}
}

.form-quote {
	.reason_for_rejection {
		textarea {
			border-radius: 0;
		}
	}
	.return_to_quotation_view_btn {
		height: auto;
	}
}

.checkout-success {
	font-size: 16px;

	@include breakpoint(medium) {
		font-size: 20px;
	}
}

.dibs-easy-checkout-wrapper {
	div.dibs-easy-checkout-review {
		margin-bottom: 40px;

		.table-wrapper.order-items {
			@include grid-row;
            @include grid-row-nest;

			.dibs-easy-checkout-items {
				@include grid-col;

				@include breakpoint(large) {
					@include grid-col-size(6);
				}
				@include breakpoint(xlarge) {
					@include grid-col-size(7.9);
				}
			}
			.dibs-easy-checkout-totals {
				@include grid-col;

				@include breakpoint(large) {
					@include grid-col-size(6);
					float: right;
				}
				@include breakpoint(xlarge) {
					@include grid-col-size(4.1);
				}

				#dibs-easy-checkout_cart_totals {

				}
			}
		}

		.dibs-easy-checkout-shipping,
		.checkout-container,
		.dibs-easy-checkout-extra {
			max-width: 440px;
			margin: 0 auto 20px;

			@include breakpoint(large) {
				margin: 0 auto 40px;
			}
		}

		table.dibs-easy-checkout-review {
			@include breakpoint(medium down) {
				margin-bottom: 20px;
			}

			tr {
				@include breakpoint(large down) {
					display: block;
					position: relative;
					@include clearfix;
				}

				&:first-child {
					th,
					td {
						border-top: none;
					}
				}

				&.item-info {
					position: relative;

					@include breakpoint(large down) {
						padding-top: 15px;
						padding-bottom: 15px;
					}

					th,td {
						padding-top: 40px;
						padding-bottom: 35px;

						@include breakpoint(large down) {
							padding-top: 0;
							padding-bottom: 0;
						}

						&:first-child {
							width: 130px;
							padding-left: 2.5rem;
							vertical-align: top;

							@include breakpoint(xlarge down) {
								padding-left: 20px;
							}
							@include breakpoint(large down) {
								width: 90px;
							}
						}
						&.qty {
							.input-text {
								border: none;
								border-radius: 0;
								height: 30px;
								font-size: rem-calc(15);
								text-align: left;
								background-color: $input-background;

								&:focus,
								&[readonly] {
									background-color: $input-background;
								}

								@include breakpoint(xlarge) {
									height: 50px;
									text-align: right;
								}
							}
							.input-number-decrement,
							.input-number-increment {
								display: none;
							}
						}
						&.subtotal {
							.price-including-tax {
								font-family: $bold-font-family;
							}
						}
						&.actions {
							@include breakpoint(xlarge) {
								padding-right: 40px;
							}
						}
					}
				}
			}
			.product-item-sku,
			.product-item-supplier {
				color: rgba($black,0.7);
			}
			.remove-product {
				display: inline-block;
				vertical-align: middle;
				width: 24px;
				height: 26px;
				opacity: .8;
				background: url("#{$ts-path-to-images}/icon-delete.svg") no-repeat;
				background-size: contain;
				cursor: pointer;

				&:active,
				&:hover {
					opacity: 1;
				}

				span {
					display: none;
				}
			}
		}

		input[type="text"],
		.input-text,
		textarea,
		select {
			font-size: rem-calc(15);
			background-color: $white;
			border: $input-border;
		}

		input[type="text"],
		.input-text,
		select {
			height: 46px;
			padding: 3px 14px;
		}

		button.action {
			width: 100%;
		}
	}
	.dibs-easy-checkout-cart {
		.dibs-easy-checkout-title {
			display: none;
		}
	}
	.dibs-easy-checkout-review {
		thead {
			display: none;
		}
	}
	.dibs-easy-checkout-shipping {
		.dibs-easy-checkout-title {
			.shipping-price {
				display: none;
			}
		}
		.dibs-easy-checkout-section-shipping {
			margin-bottom: 20px;
		}
		.radio {
			input[type=radio] {
				&+.label,
				&+label {
					display: flex;
					justify-content: space-between;
				}
			}
		}
		button.action {
			font-size: rem-calc(15);
			@include button-style($primary-color, lighten($primary-color, 10%), $white);
		}
	}
	.dibs-easy-checkout-extra {
		.dibs-easy-checkout-extra__right,
		.discount {
			margin-bottom: 20px;
		}

		.dibs-easy-checkout-extra__right {
			button.action {
				font-size: rem-calc(15);
				@include button-style($primary-color, lighten($primary-color, 10%), $white);
			}
		}

		.grand-total {
			margin-bottom: 20px;

			h4 {
				display: flex;

				.total-title {
					order: 0;
					margin-right: auto;
				}
				.total-currency {
					order: 1;
				}
				.total-amount {
					order: 2;
				}
			}
		}
	}
}
.dibs-checkout-wrapper {
	max-width: 820px;
	margin: 0 auto;
}

body.product-custom-request-form {
    .algolia-instant-selector-results {
        .page-title-wrapper {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    .page-main {
        background: none !important;
        width: auto !important;
        padding: 0 !important;
        margin-bottom: 2rem !important;

        &:before {
            display: none;
        }

        h1 {
            background: none;
            border-bottom: none;
            margin: 0;
            padding: 0 map-get($grid-column-gutter, small)/2;

            @include breakpoint(medium) {
                padding: 0 map-get($grid-column-gutter, medium)/2;
            }
        }
    }
    .modal-popup {
        h1 {
            margin: 0;
            background: none;
            padding: 0 0 5px;
        }
    }
	.product-info-wrapper {
		.product-info-main {
			display: block;
			width: 100%;

			@include breakpoint(xlarge) {
				width: 50%;
			}

			.page-title-wrapper.product {
				display: block;
				margin: -30px -30px 0;
				padding: 0;

				@include breakpoint(large) {
					margin: -60px -60px 0;
				}

				.page-title {
                    background: #f9f9f9;
                    border-bottom: 1px solid #e3e3e3;
					width: 100%;
					font-size: rem-calc(26);
					padding: 30px;
					margin: 0;
					text-align: center;

					@include breakpoint(large) {
						font-size: rem-calc(34);
						padding: 30px 60px;
					}
				}
			}
			.product-info-sidebar {
				padding-bottom: 0;
				margin-bottom: 0;

				.product-description-wrapper {
					display: none;
				}
			}
			.product-add-form {
				display: block;

				.product-options-wrapper {
					.field {
						label.label,
						legend.legend {
							width: auto;
							float: none;
							padding: 0 !important;
							font-family: $medium-font-family;
							font-size: rem-calc(15);
							font-weight: 400;
							line-height: 2.4;
							color: $primary-color;
						}
						.control {
							width: auto;
							float: none;
							margin-top: 0;
						}
					}
				}

				input,
				select {
					height: 46px;
					font-size: rem-calc(15);
					background-color: $white;
					border: $input-border;
					border-radius: 50px;
					padding: 3px 14px;

					&:focus {
						border: 1px solid $primary-color;
						box-shadow: none;
					}
				}
				select {
					padding-right: 50px;
				}
				.file-requirements-container {
					font-size: rem-calc(15);
					margin-bottom: rem-calc(16);

					span {
						text-decoration: underline;
						cursor: pointer;
						color: $primary-color;
						margin-bottom: rem-calc(6);
						display: block;

						&:hover,
						&:focus {
							text-decoration: none;
						}
					}
				}
				.file-uploader-button {
					@include button-extended;
					@include button-expand;
					@include button-style($primary-color, lighten($primary-color, 10%), $white);
					color: $button-color;
					padding-top: 1.25rem;
					padding-bottom: 1.25rem;
				}
				.file-uploader-summary {
					margin-top: 20px;

					.preview-image {
						text-decoration: none;
					}
					.action-remove {
						display: inline-block;
						vertical-align: middle;
						width: $ts-delete-icon-width;
						height: $ts-delete-icon-height;
						opacity: .8;
						background: url("#{$ts-path-to-images}/#{$ts-delete-icon}.#{$ts-delete-icon-type}") no-repeat;
						background-size: contain;
						cursor: pointer;
						span {
							@include at;
						}

						&:hover,
						&:active {
							opacity: 1;
						}
					}
				}

				.product-options-bottom {
					@include breakpoint(medium) {
						margin: 0 -62px;
					}
					@include breakpoint(large) {
						margin: 0 -60px;
					}
					.box-tocart {
						padding: 0;
					}
					.actions {
						text-align: center;

						#product-addtoquote-button {
							@include button-extended;
							width: auto;
							margin: 0 auto;
						}
					}
				}
			}
		}
	}
}

@if ($ts-addtocart-popup-view == 'default') {
    .reveal-overlay {
        .add-popup-message {
            &:after {
                border-width: 0 14px 16px;

                @if ($ts-header-cart-dropdown-showcart-type == 'image-only') {
                    right: 8px;
                }

                body.is-sticky & {
                    right: 8px;
                }
            }
        }
    }
}

.manage-company-accounts {
    .block-dashboard-company {
        .block-title {
            display: none;
        }
        .box-actions {
            margin-bottom: 20px;

            .action {
                &:after {
                    display: none;
                }
                &:hover {
                    color: $white;
                }
            }
        }
        .jstree {
            li {
                a {
                    height: 36px;
                    line-height: 30px;
                    padding-top: 3px;
                    padding-bottom: 3px;
                }
            }
            .jstree-clicked {
                background-color: map-get($foundation-palette, success);
            }
        }
    }
}
.company-users-index {
    .data-grid-filters-wrap {
        margin-bottom: 20px;
    }
}
.quotes-grid-toolbar {
    .limiter {
        margin-bottom: 20px;

        .control {
            display: inline-block;
            width: 150px;

            select {
                margin-bottom: 0;
            }
        }
        .limiter-text {
            display: inline-block;
            font-size: inherit;
        }
    }
}
.customer-address-form {
    .form-address-edit {
        .field {
            &.fax,
            &.region {
                display: none;
            }
        }
    }
}

.magnifier-preview {
    img {
        object-fit: contain;
    }
}

.mm-slideout {
    .category-all {
        display: none;
    }
}

.sales-order-view {
    table {
        tr {
            &.print-price-calculations {
                td, th {
                    border-top: unset;
                    border-bottom: unset;
                }
            }
            &:first-child {
                td,
                th {
                    border-top: $table-border;
                    border-bottom-style: dashed;
                }
            }
            td,
            th {
                border-top-style: dashed;
                border-bottom-style: dashed;
            }
        }
    }
}


.form-edit-account,
.form-address-edit {
    .fieldset {
        &.password {
            clear: both;
        }

        .control {
            font-size: 17px;
        }
    }
}


.#{$ts-algolia-pagination--selector}-list,
.convert-paging .pages-items {
    display: block;
    font-size: 0;

    .#{$ts-algolia-pagination-item--selector},
    .item {
        display: inline-block;

        &.#{$ts-algolia-pagination-item--selector}__previous,
        &.#{$ts-algolia-pagination-item--selector}__next,
        &.#{$ts-algolia-pagination-item--selector}--previousPage,
        &.#{$ts-algolia-pagination-item--selector}--nextPage,
        &.pages-item-next,
        &.pages-item-previous {
            @if not $ts-pagination-centered {
                position: absolute;
                bottom: 0;
                left: 0;

                @include breakpoint(xlarge) {
                    bottom: auto;
                    top: 0;
                }
            }

            .#{$ts-algolia-pagination-link--selector},
            .link,
            .action {
                @include breakpoint(large down) {
                    font-size: map-get($ts-pagination-item-next-prev-button-font-size, small);
                    padding-top: map-get($ts-pagination-item-next-prev-button-padding-top,small);
                    padding-bottom: map-get($ts-pagination-item-next-prev-button-padding-bottom,small);
                }
            }
        }

        &.#{$ts-algolia-pagination-item--selector}__next,
        &.#{$ts-algolia-pagination-item--selector}--nextPage,
        &.pages-item-next {
            @if not $ts-pagination-centered {
                left: auto;
                right: 0;
            }
            @include breakpoint(medium down) {
                margin: map-get($ts-pagination-item-outside-indent, small) !important;
            }
        }

        &.#{$ts-algolia-pagination-item--selector}__previous,
        &.#{$ts-algolia-pagination-item--selector}--previousPage,
        &.pages-item-previous {
            @include breakpoint(medium down) {
                margin: map-get($ts-pagination-item-outside-indent, small) !important;
            }
        }
    }
}

.quote-action-toolbar {
	text-align: right;
	a.action.back {
		&:before {
			display: none;
		}
	}
	a.action {
		@include button;
		font-family: $bold-font-family;

		&:hover,
		&:focus {
			span {
				text-decoration: none;
			}
		}
	}
}

.quotation-quote-index #trigger-file-requirements-modal-popup {
    text-decoration: underline;
    cursor: pointer;
    color: $primary-color;
    margin-bottom: 0.5rem;
    display: block;
}

.quotation-quote-index .file-upload-container ul.uploaded-files {
    padding: 10px 0 20px 0;
}

body.product-custom-request-form .product-add-form .product-options-wrapper #custom-request-form-custom-options {
    .field:first-of-type {
        margin-top: 0;
    }

    ul {
        margin-left: 0;
        list-style: none;

        span.add-file-button {
            text-decoration: underline;
            cursor: pointer;
            color: $primary-color;
            font-size: 0.9375rem;
            font-family: "silka-medium-webfont", sans-serif;
        }
    }

    .hidden-file-upload {
        display: none;
    }
}

.checkout-steps {
    .field {
        &.field-country_id:has(div > select > option:only-child) {
            display: none !important;
        }
    }
}

// amasty brands
.cms-page-view {
    .brand-list {
        display: flex;
        flex-wrap: wrap;
    }

    .brand-content .brand-item {
        padding: 4%;
    }

    .brand-title-container {
        text-align: center;
        margin-top: 0.625rem;
    }
}
.amshopby-option-link {
    margin-left: 20px;
    margin-top: 5px;

    .brand-title {
        font-size: 1.25rem;
        margin-bottom: 1.25rem;
    }

    > a {
        float: left;
        margin-top: -14px;
    }
}

.product-items .product-item .product-price-stock:has(.amshopby-option-link) {
    display: flex;
    flex-direction: column;
    margin-right: 80px;
}

.brand-image {
    margin-bottom: 2rem;

    > img {
        display: block;
        margin: auto;
    }
}
