@if ($ts-snowdog-menu-include-vertical) {
    .mm-listitem_vertical > .mm-panel {
        display: none;
        width: 100%;
        padding: $ts-snowdog-menu-padding 0 $ts-snowdog-menu-padding $ts-snowdog-menu-padding;

        transform: none !important;

        &:before,
        &:after {
            content: none;
            display: none;
        }
    }

    .mm-listitem_opened > .mm-panel {
        display: block;
    }

    .mm-listitem_vertical {
        > .mm-listitem__btn {
            height: $ts-snowdog-menu-listitem-size;
            bottom: auto;
        }

        .mm-listitem:last-child:after {
            border-color: transparent;
        }
    }

    .mm-listitem_opened {
        > .mm-listitem__btn:after {
            transform: rotate(225deg);
            right: $ts-snowdog-menu-listitem-indent - 1;
        }
    }
}
