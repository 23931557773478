$cv-similar-products__title                       : true !default;
$cv-similar-products__margin                      : (0 0 3rem) !default;
$cv-similar-products-item-availability__show      : true !default;
$cv-similar-products-item-availability__margin    : (8px 0 0) !default;
$cv-similar-products-item__width                  : 70px !default;
$cv-similar-products-item__height                 : 80px !default;
$cv-similar-products-item__margin                 : (0 .25rem .5rem 0) !default;
$cv-similar-products-item__border                 : 2px !default;
$cv-similar-products-item__border-color           : transparent !default;
$cv-similar-products-item__border-color--current  : rgba($primary-color,0.9) !default;
$cv-similar-products-item__border-radius          : $global-radius !default;
$cv-similar-products-item__padding                : (0 0 10px) !default; // set to false to remove the property
$cv-similar-products-item__bgcolor                : $white !default;
$cv-similar-products-item__box-shadow             : (0 0 10px transparent) !default;
$cv-similar-products-item__box-shadow--hover      : (0 0 13px rgba($black, .3)) !default;
$cv-similar-products-item-image__height           : 75px !default;
$cv-similar-products-item-label__show             : true !default;
$cv-similar-products-item-label__color            : rgba($body-font-color,0.7) !default;
$cv-similar-products-item-label__font-size        : 13px !default;
$cv-similar-products-item-label__font-weight      : normal !default;
$cv-similar-products-item-label__line-height      : 1.153846154 !default;
$cv-similar-products-item-label__margin           : (8px 8px 0) !default;