$ts-algolia--version: 2 !default;

@mixin ais-active-state {
    font-weight: bolder;
    &:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        right: $ts-mobile-filter-image-position;
        transform: translateY(-50%);
        @if ( $ts-mobile-filter-image != false ) {
            width: $ts-mobile-filter-image-width;
            height: $ts-mobile-filter-image-height;
            background: url("#{$ts-path-to-images}/#{$ts-mobile-filter-image}.#{$ts-mobile-filter-image-type}") no-repeat;
            background-size: 100% auto;
        }
    }
}// ais-active-state

@mixin ais-list-controls($class_name: '', $class_part: '') {
    font-size: $ts-sidebar-main-filter-font-size;
    border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

    .ais-#{$class_name}--item {
        position: relative;
        border-bottom: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

        &:last-child {
            border-bottom: 0;
        }

        .ais-#{$class_name}--label,
        .ais-#{$class_name}--link {
            display: block;
            text-transform: $ts-sidebar-main-filter-text-transform;
            font-family: $ts-sidebar-main-filter-font-family;
            font-size: $ts-sidebar-main-filter-font-size;
            font-weight: $ts-sidebar-main-filter-font-weight;
            color: $ts-sidebar-main-filter-anchor-color;
            line-height: 1.7;
            padding: $ts-sidebar-main-filter-anchor-padding;
            cursor: pointer;
            position: relative;
            // text-decoration: $ts-sidebar-main-filter-anchor-underline;

            &:hover {
                color: $ts-sidebar-main-filter-anchor-color-hover;
                background-color: $ts-sidebar-main-filter-anchor-bg-hover;
                // text-decoration: $ts-sidebar-main-filter-anchor-underline-hover;
            }
        }// .ais-refinement-list--label

        &.ais-#{$class_name}--item__active {
            > div {
                > .ais-#{$class_part}-link-wrapper {
                    > .ais-#{$class_name}--link {
                        @include ais-active-state;
                    }
                }
                > .ais-#{$class_name}--label,
                > .ais-#{$class_name}--link {
                    @include ais-active-state;
                } // .ais-refinement-list--label
            }
        } // ais-refinement-list--item__active

        .ais-#{$class_name}--count {
            @if $ts-sidebar-main-filter-count-hide {
                @include at;
            } @else {
                color: $ts-sidebar-main-filter-count-color;
                font-size: $ts-sidebar-main-filter-count-font-size;

                &:before {
                    content: '(';
                }

                &:after {
                    content: ')';
                }
            }
        }// .ais-refinement-list--count

        .ais-hierarchical-menu--count {
            position: relative;
            margin-left: .25rem;
        }

        input[type="checkbox"],
        input[type="radio"] {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
        } // input

    } // .ais-#{$class_name}--item
} // ais-list-controls

@mixin ais-ListControls ($class_name: '', $class_part: '') {
    font-size: $ts-sidebar-main-filter-font-size;
    border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

    .ais-#{$class_name}-item {
        position: relative;
        border-bottom: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

        &:last-child {
            border-bottom: 0;
        }

        .ais-#{$class_name}-label,
        .ais-#{$class_name}-link {
            display: block;
            text-transform: $ts-sidebar-main-filter-text-transform;
            font-family: $ts-sidebar-main-filter-font-family;
            font-size: $ts-sidebar-main-filter-font-size;
            font-weight: $ts-sidebar-main-filter-font-weight;
            color: $ts-sidebar-main-filter-anchor-color;
            line-height: 1.7;
            padding: $ts-sidebar-main-filter-anchor-padding;
            cursor: pointer;
            position: relative;
            // text-decoration: $ts-sidebar-main-filter-anchor-underline;

            &:hover {
                color: $ts-sidebar-main-filter-anchor-color-hover;
                background-color: $ts-sidebar-main-filter-anchor-bg-hover;
                // text-decoration: $ts-sidebar-main-filter-anchor-underline-hover;
            }
        }

        &.ais-#{$class_name}-item--selected {
            > div {
                > .ais-#{$class_name}-label,
                > .ais-#{$class_name}-link {
                    @include ais-active-state;
                }
            }
        }

        .ais-#{$class_name}-count {
            @if $ts-sidebar-main-filter-count-hide {
                @include at;
            } @else {
                color: $ts-sidebar-main-filter-count-color;
                font-size: $ts-sidebar-main-filter-count-font-size;

                &:before {
                    content: '(';
                }

                &:after {
                    content: ')';
                }
            }
        }

        .ais-HierarchicalMenu-count {
            position: relative;
            margin-left: .25rem;
        }

        input[type="checkbox"],
        input[type="radio"] {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
        }// input

    }// .ais-#{$class_name}--item
}// ais-list-controls

@if $ts-algolia--version == 2 {
    .ais-root {
        position: relative;

         a {
            text-decoration: none;
        }

        &.slider {
            margin: 0;
            height: auto;
            cursor: default;
            background: transparent;
        }// &.slider

        &.ais-current-refined-values,
        &.ais-refinement-list,
        &.ais-range-slider,
        &.ais-hierarchical-menu,
        &.ais-menu-select,
        &.ais-range-input,
        &.ais-price-ranges,
        &.ais-toggle {
            .ais-header,
            .ais-body {
                padding: $ts-sidebar-main-padding;
                background: $ts-sidebar-main-background-color;
            }

            .ais-header {
                display: block;
                @include header-font-family($ts-sidebar-main-filter-title-font-family);
                @include clearfix;
                font-size: $ts-sidebar-main-filter-title-font-size;
                color: $ts-sidebar-main-filter-title-font-color;
                font-weight: $ts-sidebar-main-filter-title-font-weight;
                margin-bottom: 0;
                line-height: 1.2;
                padding: $ts-sidebar-main-padding;
                background: $ts-sidebar-main-background-color;
                padding-bottom: $ts-sidebar-main-filter-title-indent;
                padding-left: $ts-sidebar-main-filter-title-indent-left;
                @if ( $ts-sidebar-main-filter-title-text-transform != none ) {
                    text-transform: $ts-sidebar-main-filter-title-text-transform;
                }
            }// .ais-header

            .ais-body {
                padding-top: 0;
                margin-bottom: $ts-sidebar-main-filter-content-indent;
            }// .ais-body
        }// ais-items

        .searchbox {
            margin: 1rem 0;

            &.sbx-sffv-disabled {
                display: none;
            }

            .sbx-sffv__wrapper {
                position: relative;
            }
            .sbx-sffv__submit,
            .sbx-sffv__reset {
                position: absolute;
                top: 1px;
                bottom: 1px;
                right: auto;
                width: ($ts-sidebar-search-input-indent - $ts-sidebar-search-input-icon-right-indent);
                right: ($ts-sidebar-search-input-icon-right-indent/2);
                padding: $ts-sidebar-search-input-icon-padding-indent;
                @extend .action.search;
                background-position: 50%;
                background-color: $ts-sidebar-search-input-background-color;
                background-size: 80% auto;
                height: auto;
                z-index: 2;

                svg {
                    display: none;
                }
            }
            .sbx-sffv__reset {
                @extend .clear-cross;
                background-size: 60% auto;
                z-index: 1;
            }
            .sbx-sffv__input {
                height: $ts-sidebar-search-input-height;
                padding-right: $ts-sidebar-search-input-indent;
                padding-left: $ts-sidebar-search-input-left-indent;
                margin-bottom: 0;
                font-family: $ts-sidebar-search-font-family;
                font-size: $ts-sidebar-search-font-size;
                font-weight: $ts-sidebar-search-font-weight;
                color: $ts-sidebar-search-input-color;
                text-transform: $ts-sidebar-search-input-text-transform;
                background-color: $ts-sidebar-search-input-background-color;
                background-position: $ts-sidebar-search-input-icon-bg-position;
                transition: all .3s ease;
                @include placeholder($ts-sidebar-search-input-color);

                &.sbx-sffv__input-disabled {
                    opacity: .5;
                    border: $ts-sidebar-search-input-border !important;
                    + .sbx-sffv__submit {
                        opacity: .5;
                        + .sbx-sffv__reset {
                            display: none;
                        }
                    }
                }

                @if $ts-sidebar-search-input-focus-one-line-border {
                    border-bottom-color: $ts-sidebar-search-input-border-bottom-color;
                } @else {
                    border: $ts-sidebar-search-input-border;
                }
                &:hover,
                &:active,
                &:focus,
                &:focus:not([value=""]) {
                    @if $ts-sidebar-search-input-focus-one-line-border {
                        border-bottom-color: $ts-sidebar-search-input-focus-border-bottom-color;
                    } @else {
                        border-color: $ts-sidebar-search-input-focus-border-color;
                    }
                }
                // &:focus:not([value=""]),
                // &.focus {
                //     + .sbx-sffv__submit {
                //         z-index: 0;
                //     }
                // }

                &::-ms-clear {
                    display: none;
                }
            }// .algolia-search-input
        }// .searchbox

        // Price Ranges filter view
        &.ais-price-ranges {
            .ais-price-ranges--form {
                padding-top: $ts-algolia-from-to-btn-font-size*.75;
                font-size: 0;
                label {
                    display: inline-block;
                    vertical-align: middle;
                    width: 40%;
                    position: relative;

                    @include breakpoint(xxlarge) {
                        width: 25%;
                    }

                    input {
                        margin-bottom: 0;
                        width: 100%;
                        height: $ts-algolia-from-to-input-height;
                        text-align: $ts-algolia-from-to-input-text-align;
                        font-size: $ts-algolia-from-to-input-font-size;
                        font-family: $ts-algolia-from-to-input-font-family;
                        font-weight: $ts-algolia-from-to-input-font-weight;
                        color: $ts-algolia-from-to-input-color;
                        padding: $ts-algolia-from-to-input-padding;
                        @include placeholder($ts-algolia-from-to-input-placeholder-color);

                        &::-webkit-inner-spin-button,
                        &::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                        }
                    }

                    .ais-price-ranges--currency {
                        position: absolute;
                        bottom: 100%;
                        left: 0;
                        line-height: 1;
                        font-size: $ts-algolia-from-to-btn-font-size*.75;
                    }
                }
                .ais-price-ranges--separator {
                    width: 20%;
                    display: inline-block;
                    vertical-align: middle;
                    font-size: $ts-algolia-from-to-btn-font-size*.75;
                    padding: 0 $ts-algolia-from-to-items-indent;
                    text-align: center;

                    @if $ts-algolia-from-to-replace-separator {
                        font-size: 0;
                        &:before {
                            content: '#{$ts-algolia-from-to-replace-separator-content}';
                            font-size: $ts-algolia-from-to-btn-font-size*.75;
                        }
                    }

                    @include breakpoint(xxlarge) {
                        width: 10%;
                    }
                }
                .ais-price-ranges--submit,
                .ais-price-ranges--button {
                    @include button;
                    width: 100%;
                    height: $ts-algolia-from-to-input-height;
                    font-size: $ts-algolia-from-to-btn-font-size;
                    font-weight: $ts-algolia-from-to-btn-font-weight;
                    font-family: $ts-algolia-from-to-btn-font-family;
                    color: $ts-algolia-from-to-btn-color;
                    margin-top: $ts-algolia-from-to-items-indent;
                    padding: $ts-algolia-from-to-btn-padding;
                    background-color: $ts-algolia-from-to-btn-bg-color;

                    &:hover,
                    &:active {
                        background-color: $ts-algolia-from-to-btn-bg-color-hover;
                    }

                    &[disabled] {
                        cursor: default;
                        opacity: .5;
                    }

                    @include breakpoint(xxlarge) {
                        width: calc(40% - #{$ts-algolia-from-to-items-indent});
                        margin-left: $ts-algolia-from-to-items-indent;
                        margin-top: 0;
                    }
                }
            }
        }// .ais-price-ranges

    } // .ais-root

    .ais-current-refined-values--clear-all {
        font-family: $ts-sidebar-main-filter-clear-font-family;
        font-size: $ts-sidebar-main-filter-clear-font-size;
        font-weight: $ts-sidebar-main-filter-clear-font-weight;
        color: $ts-sidebar-main-filter-clear-font-color;
        border-bottom: $ts-sidebar-main-filter-clear-border;
        line-height: 1;
        margin: $ts-sidebar-main-padding;
        margin-bottom: 0;
        position: absolute;
        top: .5rem;
        right: 0;
        text-decoration: none;

        &:hover,
        &:focus {
            border-bottom: 0;
            padding-bottom: 1px;
        }
    }// .clear-filter

    .ais-refinement-list--list {
        @include ais-list-controls('refinement-list');

        @if $ts-label-has-checkbox {
            .ais-refinement-list:not(.images-labels):not(.swatches) & {
                .ais-refinement-list--item {
                    > div {
                        position: relative;
                        &:before {
                            display: block;
                            content: '';
                            width: $ts-checkbox-label-swatch-width;
                            height: $ts-checkbox-label-swatch-height;
                            position: absolute;
                            top: $ts-checkbox-label-swatch-top;
                            left: $ts-checkbox-label-swatch-left;
                            background-color: $ts-checkbox-swatch-bg-color;
                            box-shadow: $ts-checkbox-swatch-border;
                        }

                        @if $ts-label-has-checkbox-simple-bg {
                            &:after {
                                display: block;
                                content: '';
                                position: absolute;
                                top: $ts-checkbox-label-swatch-top-active;
                                left: $ts-checkbox-label-swatch-left-active;
                                width: $ts-checkbox-label-swatch-width-active;
                                height: $ts-checkbox-label-swatch-height-active;
                                background: $ts-checkbox-label-swatch-bg-active;
                                opacity: 0;
                            }
                        } @else {
                            &:after {
                                display: block;
                                content: '';
                                position: absolute;
                                top: $ts-checkbox-label-swatch-top-active;
                                left: $ts-checkbox-label-swatch-left-active;
                                width: $ts-checkbox-label-swatch-width-active;
                                height: $ts-checkbox-label-swatch-height-active;
                                background-color: $ts-checkbox-label-swatch-bg-active;
                                background-image: url("#{$ts-path-to-images}/#{$ts-checkbox-label-swatch-bg-image-active}.#{$ts-checkbox-label-swatch-bg-image-active-type}");
                                background-size: $ts-checkbox-label-swatch-bg-image-active-size;
                                background-repeat: no-repeat;
                                background-position: 50%;
                                opacity: 0;
                            }
                        }
                        .ais-refinement-list--label {
                            color: $ts-sidebar-main-filter-anchor-color;
                            padding-left: $ts-checkbox-label-swatch-left-indent;
                        }
                    }
                    &:hover,
                    &:focus,
                    &.ais-refinement-list--item__active {
                        > div {
                            &:after {
                                opacity: 1;
                            }
                            .ais-refinement-list--label {
                                font-weight: inherit;
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    } //.ais-refinement-list--list

    .ais-current-refined-values--list {
        @include ais-list-controls('current-refined-values');
        .ais-current-refined-values--item {
            a {
                display: block;
            }
            &:hover,
            &:focus {
                cursor: pointer;

                .current-refinement-wrapper {
                    text-decoration: line-through;
                }
            }
        }// .ais-current-refined-values--item
    } // .ais-current-refined-values--list

    .ais-price-ranges--list {
        @include ais-list-controls('price-ranges');
        .ais-price-ranges--item {
            &.ais-price-ranges--item__active {
                .ais-price-ranges--link {
                    @include ais-active-state;
                }
            }// active
        }
    } // .ais-price-ranges--list

    .ais-hierarchical-menu--list {
        @include ais-list-controls('hierarchical-menu','hierearchical');
        .ais-hierarchical-menu--item {
            &.ais-hierarchical-menu--item__active {
                > div > .ais-hierearchical-link-wrapper {
                    > .ais-hierarchical-menu--link {
                        padding-left: 23px;
                        font-weight: $ts-mobile-filter-active-font-weight;
                        background: none;

                        &:after {
                            display: block;
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: $ts-mobile-filter-image-position;
                            transform: translateY(-50%);
                            @if ( $ts-mobile-filter-image != false ) {
                                width: $ts-mobile-filter-image-width;
                                height: $ts-mobile-filter-image-height;
                                background: url("#{$ts-path-to-images}/#{$ts-mobile-filter-image}.#{$ts-mobile-filter-image-type}") no-repeat;
                                background-size: 100% auto;
                            }
                        }
                    }// .ais-refinement-list--label
                }
            }
        }//.ais-hierarchical-menu--item

        @if $ts-sidebar-category-has-checkbox {
            .is-widget-container-categories & {
                .ais-hierarchical-menu--item {
                    .ais-hierearchical-link-wrapper {
                        position: relative;
                        &:before {
                            display: block;
                            content: '';
                            width: $ts-checkbox-label-swatch-width;
                            height: $ts-checkbox-label-swatch-height;
                            position: absolute;
                            top: $ts-checkbox-label-swatch-top;
                            left: $ts-checkbox-label-swatch-left;
                            background-color: $ts-checkbox-swatch-bg-color;
                            box-shadow: $ts-checkbox-swatch-border;
                        }

                        @if $ts-label-has-checkbox-simple-bg {
                            &:after {
                                display: block;
                                content: '';
                                position: absolute;
                                top: $ts-checkbox-label-swatch-top-active;
                                left: $ts-checkbox-label-swatch-left-active;
                                width: $ts-checkbox-label-swatch-width-active;
                                height: $ts-checkbox-label-swatch-height-active;
                                background: $ts-checkbox-label-swatch-bg-active;
                                opacity: 0;
                            }
                        } @else {
                            &:after {
                                display: block;
                                content: '';
                                position: absolute;
                                top: $ts-checkbox-label-swatch-top-active;
                                left: $ts-checkbox-label-swatch-left-active;
                                width: $ts-checkbox-label-swatch-width-active;
                                height: $ts-checkbox-label-swatch-height-active;
                                background-color: $ts-checkbox-label-swatch-bg-active;
                                background-image: url("#{$ts-path-to-images}/#{$ts-checkbox-label-swatch-bg-image-active}.#{$ts-checkbox-label-swatch-bg-image-active-type}");
                                background-size: $ts-checkbox-label-swatch-bg-image-active-size;
                                background-repeat: no-repeat;
                                background-position: 50%;
                                opacity: 0;
                            }
                        }
                        .ais-hierarchical-menu--link {
                            color: $ts-sidebar-main-filter-anchor-color;
                            padding-left: $ts-checkbox-label-swatch-left-indent;
                        }
                    }
                    &:hover,
                    &:focus,
                    &.ais-hierarchical-menu--item__active {
                        .ais-hierearchical-link-wrapper {
                            &:after {
                                opacity: 1;
                            }
                            .ais-hierarchical-menu--link {
                                font-weight: inherit;
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    } // .ais-hierarchical-menu--list

    .ais-toggle--list {
        @include ais-list-controls('toggle');
        .ais-toggle--item {
            > div {
                position: relative;
                &:before {
                    display: block;
                    content: '';
                    width: $ts-checkbox-label-swatch-width;
                    height: $ts-checkbox-label-swatch-height;
                    position: absolute;
                    top: $ts-checkbox-label-swatch-top;
                    left: $ts-checkbox-label-swatch-left;
                    background-color: $ts-checkbox-swatch-bg-color;
                    box-shadow: $ts-checkbox-swatch-border;
                }

                @if $ts-label-has-checkbox-simple-bg {
                    &:after {
                        display: block;
                        content: '';
                        position: absolute;
                        top: $ts-checkbox-label-swatch-top-active;
                        left: $ts-checkbox-label-swatch-left-active;
                        width: $ts-checkbox-label-swatch-width-active;
                        height: $ts-checkbox-label-swatch-height-active;
                        background: $ts-checkbox-label-swatch-bg-active;
                        opacity: 0;
                    }
                } @else {
                    &:after {
                        display: block;
                        content: '';
                        position: absolute;
                        top: $ts-checkbox-label-swatch-top-active;
                        left: $ts-checkbox-label-swatch-left-active;
                        width: $ts-checkbox-label-swatch-width-active;
                        height: $ts-checkbox-label-swatch-height-active;
                        background-color: $ts-checkbox-label-swatch-bg-active;
                        background-image: url("#{$ts-path-to-images}/#{$ts-checkbox-label-swatch-bg-image-active}.#{$ts-checkbox-label-swatch-bg-image-active-type}");
                        background-size: $ts-checkbox-label-swatch-bg-image-active-size;
                        background-repeat: no-repeat;
                        background-position: 50%;
                        opacity: 0;
                    }
                }
                .ais-toggle--label {
                    color: $ts-sidebar-main-filter-anchor-color;
                    padding-left: $ts-checkbox-label-swatch-left-indent;
                }
            }
            &:hover,
            &:focus,
            &.ais-toggle--item__active {
                > div {
                    &:after {
                        opacity: 1;
                    }
                    .ais-toggle--label {
                        font-weight: inherit;
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }


    @for $i from 1 through 10 {
        .ais-hierarchical-menu--list__lvl#{$i} {
            .ais-hierarchical-menu--item {
                padding-left: $ts-sidebar-main-filter-subcategory-left-indent;
            }
        }
    } // for
} @elseif $ts-algolia--version > 2 {
    .ais-Panel {
        position: relative;
        background: $ts-sidebar-main-background-color;
        margin-bottom: $ts-sidebar-main-filter-content-indent;

        a {
            text-decoration: none;
        }

        &-header {
            @include header-font-family($ts-sidebar-main-filter-title-font-family);
            font-size: $ts-sidebar-main-filter-title-font-size;
            color: $ts-sidebar-main-filter-title-font-color;
            font-weight: $ts-sidebar-main-filter-title-font-weight;
            margin-bottom: 0;
            line-height: 1.2;
            padding: $ts-sidebar-main-padding;
            padding-bottom: $ts-sidebar-main-filter-title-indent;
            padding-left: $ts-sidebar-main-filter-title-indent-left;
            @if ( $ts-sidebar-main-filter-title-text-transform != none ) {
                text-transform: $ts-sidebar-main-filter-title-text-transform;
            }
        } // .ais-Panel-header

        &-body {
            padding: $ts-sidebar-main-padding;
            padding-top: 1px;

            ul,
            ol {
                @include lsn;
            }
        } // .ais-Panel-body
    }

    #instant-search-facets-container {
        position: relative;
    }

    #clear-refinements {
        position: absolute;
        top: 14px;
        right: 34px;
    }

    .ais-ClearRefinements {
        // margin-bottom: 1rem;

        &-button {
            background-color: transparent !important;
            color: $primary-color !important;
            text-decoration: underline;
            padding: 0 !important;
            font-size: 12px !important;
        }
    }

    .ais-CurrentRefinements {
        @if $ts-algolia-CurrentRefinements {
            @include ais-ListControls('CurrentRefinements');
            margin-bottom: $ts-sidebar-main-filter-content-indent;

            @if $ts-algolia-CurrentRefinements__background-color {
                background-color: $ts-algolia-CurrentRefinements__background-color;
            }

            &-list {
                @include lsn;

                &:not(:empty) {
                    @include mq-specific-property(padding, $ts-sidebar-main-filter-padding);
                }
            }

            &-item {
                padding: 0.5rem 0 !important;

                & + .ais-CurrentRefinements-item {
                    border-top: 1px solid $ts-sidebar-main-filter-border-color !important;
                }
            }

            &-label {
                padding: 0 !important;
                margin-right: 10px;
                font-weight: bold !important;
                padding-left: 18px !important;

                &:before {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    content: '';
                    width: .75rem;
                    height: .75rem;
                    opacity: .5;
                    background: url("#{$ts-path-to-images}/#{$ts-mobile-filter-image}.#{$ts-mobile-filter-image-type}") no-repeat 0 50%;
                    background-size: 100% auto;
                }
            }

            &-category {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-grow: 1;
                flex-basis: 100%;
                padding-left: 18px;
            }

            &-delete {
                padding: 4px;
                color: $primary-color;
                cursor: pointer;

                &:hover {
                    color: $alert-color;
                }
            }

            &-categoryLabel {
                white-space: nowrap;
            }
        }
    }

    .ais-HierarchicalMenu-list {
        @include ais-ListControls('HierarchicalMenu','hierarchical');
        .ais-HierarchicalMenu-item {
            &.ais-HierarchicalMenu-item--selected {
                .ais-HierarchicalMenu-link--selected {
                    padding-left: 23px;
                    font-weight: $ts-mobile-filter-active-font-weight;
                    background: none;

                    &:after {
                            display: block;
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: $ts-mobile-filter-image-position;
                            transform: translateY(-50%);
                            @if ( $ts-mobile-filter-image != false ) {
                                width: $ts-mobile-filter-image-width;
                                height: $ts-mobile-filter-image-height;
                                background: url("#{$ts-path-to-images}/#{$ts-mobile-filter-image}.#{$ts-mobile-filter-image-type}") no-repeat;
                                background-size: 100% auto;
                        }
                    }
                }
            }
        } //.ais-hierarchical-menu--item

        .ais-HierarchicalMenu-link--selected {
            .cross-circle {
                display: inline-block;
                width: 0.8em;
                height: 0.8em;
                background: url("data:image/svg+xml;utf8,<svg width='34' height='34' viewBox='0 0 34 34' xmlns='http://www.w3.org/2000/svg'><title>testvg</title><g fill='%23000' fill-rule='evenodd'><path d='M17.163 0C7.95 0 .41 7.578.353 16.893c-.03 4.542 1.693 8.82 4.847 12.053 3.156 3.23 7.367 5.026 11.857 5.054h.11c9.21 0 16.75-7.578 16.81-16.893C34.035 7.735 26.54.06 17.163 0zm.015 30.842v1.08l-.09-1.08c-3.656-.023-7.085-1.485-9.654-4.115-2.57-2.63-3.97-6.116-3.948-9.814C3.533 9.33 9.673 3.158 17.262 3.158c7.548.048 13.65 6.297 13.605 13.93-.05 7.585-6.19 13.754-13.69 13.754z'/><path d='M22.362 10.23l-5.186 5.245-5.186-5.244c-.417-.42-1.092-.42-1.51 0-.416.422-.416 1.105 0 1.526L15.668 17l-5.186 5.244c-.416.42-.416 1.104 0 1.525.21.21.483.316.755.316.273 0 .546-.106.755-.317l5.186-5.245 5.186 5.244c.208.21.482.316.754.316.273 0 .546-.106.755-.317.417-.422.417-1.105 0-1.526L18.685 17l5.187-5.244c.417-.42.417-1.104 0-1.525-.416-.42-1.09-.42-1.508 0z'/></g></svg>") no-repeat center center / contain;
                opacity: 0;
                vertical-align: middle;
                margin-left: 1rem;
            }

            &:hover {
                .cross-circle {
                    opacity: 1;
                }
            }
        }

        .ais-HierarchicalMenu-list--child {
            .ais-HierarchicalMenu-item {
                padding-left: $ts-sidebar-main-filter-subcategory-left-indent;
            }
        }

        @if $ts-sidebar-category-has-checkbox {
            .is-widget-container-categories & {
                .ais-HierarchicalMenu-item {
                    .ais-hierearchical-link-wrapper {
                        position: relative;
                        &:before {
                            display: block;
                            content: '';
                            width: $ts-checkbox-label-swatch-width;
                            height: $ts-checkbox-label-swatch-height;
                            position: absolute;
                            top: $ts-checkbox-label-swatch-top;
                            left: $ts-checkbox-label-swatch-left;
                            background-color: $ts-checkbox-swatch-bg-color;
                            box-shadow: $ts-checkbox-swatch-border;
                        }

                        @if $ts-label-has-checkbox-simple-bg {
                            &:after {
                                display: block;
                                content: '';
                                position: absolute;
                                top: $ts-checkbox-label-swatch-top-active;
                                left: $ts-checkbox-label-swatch-left-active;
                                width: $ts-checkbox-label-swatch-width-active;
                                height: $ts-checkbox-label-swatch-height-active;
                                background: $ts-checkbox-label-swatch-bg-active;
                                opacity: 0;
                            }
                        } @else {
                            &:after {
                                display: block;
                                content: '';
                                position: absolute;
                                top: $ts-checkbox-label-swatch-top-active;
                                left: $ts-checkbox-label-swatch-left-active;
                                width: $ts-checkbox-label-swatch-width-active;
                                height: $ts-checkbox-label-swatch-height-active;
                                background-color: $ts-checkbox-label-swatch-bg-active;
                                background-image: url("#{$ts-path-to-images}/#{$ts-checkbox-label-swatch-bg-image-active}.#{$ts-checkbox-label-swatch-bg-image-active-type}");
                                background-size: $ts-checkbox-label-swatch-bg-image-active-size;
                                background-repeat: no-repeat;
                                background-position: 50%;
                                opacity: 0;
                            }
                        }
                        .ais-HierarchicalMenu-link {
                            color: $ts-sidebar-main-filter-anchor-color;
                            padding-left: $ts-checkbox-label-swatch-left-indent;
                        }
                    }
                    &:hover,
                    &:focus,
                    &.ais-hierarchical-menu--item__active {
                        .ais-hierearchical-link-wrapper {
                            &:after {
                                opacity: 1;
                            }
                            .ais-HierarchicalMenu-link {
                                font-weight: inherit;
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    } // .ais-hierarchical-menu--list

    .ais-RefinementList-list {
        @include ais-ListControls('RefinementList');
    }

} // $ts-algolia--version




// Algolia Slider
.rheostat {
    position: relative;
    font-size: $ts-sidebar-main-price-slider-font-size;
    font-weight: $ts-sidebar-main-price-slider-font-weight;
    color: $ts-sidebar-main-price-slider-color;
    margin: $ts-sidebar-main-price-slider-outer-margin;

    &:after {
        display: block;
        content:'';
        position: absolute;
        width: $ts-sidebar-main-price-slider-point-width - 3px;
        left: 100%;
        top: 0;
        height: $ts-sidebar-main-price-slider-line-width;
        background: $ts-sidebar-main-price-slider-line-color;
    }

    .rheostat-background {
        width: 100%;
        text-align: left;
        position: relative;
        margin: $ts-sidebar-main-price-slider-margin;
        border-top: $ts-sidebar-main-price-slider-line-width $ts-sidebar-main-price-slider-line-type $ts-sidebar-main-price-slider-line-color;
        z-index: 1;
    }

    @if $ts-algolia--version > 2 {
        .rheostat-progress {
            position: absolute;
            top: 0;
            border-top: $ts-sidebar-main-price-slider-line-width $ts-sidebar-main-price-slider-line-type $primary-color;
        }
    }

    .ais-range-slider--marker,
    .rheostat-marker {
        margin-left: 0 !important;
        position: absolute;
        padding-top: 0.1875rem;
        text-align: center;

        .ais-range-slider--value,
        .rheostat-value {
            display: none;
        }
    }

    // Left marker
    .rheostat-progress + .rheostat-marker.rheostat-marker-large {
        left: 0 !important;
    }

    // Right marker
    .ais-range-slider--marker.ais-range-slider--marker-large,
    .rheostat-marker.rheostat-marker-large {
        &:last-child {
            left: auto !important;
            right: -1 * ($ts-sidebar-main-price-slider-point-width / 2);

            .ais-range-slider--value,
            .rheostat-value {
                display: block;
            }
        }
    }

    // Right marker
    .rheostat-marker.rheostat-marker-large {
        &:last-child {
            left: auto !important;
            right: 0;
        }
    }


    .rheostat-progress {
        + .ais-range-slider--marker.ais-range-slider--marker-large,
        + .rheostat-marker.rheostat-marker-large {
            .ais-range-slider--value,
            .rheostat-value {
                display: block;
            }
        }
    }

    .ais-range-slider--handle,
    .rheostat-handle {
        position: absolute;
        z-index: 2;
        width: $ts-sidebar-main-price-slider-point-width;
        height: $ts-sidebar-main-price-slider-point-height;
        border-radius: 50%;
        background: $ts-sidebar-main-price-slider-point-bg;
        border-radius: 100%;
        border: $ts-sidebar-main-price-slider-point-border;
        top: -($ts-sidebar-main-price-slider-point-height/2);
        margin-left: -1px;
        margin-top: 0;
        cursor: pointer;
        -ms-touch-action: none;
        touch-action: none;

        &.rheostat-handle-upper {
            margin-left: -1 * $ts-sidebar-main-price-slider-point-width;
        }

        &:focus,
        &:active,
        &:hover {
            outline: none;

            .ais-range-slider--tooltip,
            .rheostat-tooltip {
                opacity: 1;
            }
        }

        .ais-range-slider--tooltip,
        .rheostat-tooltip {
            opacity: 0;
            min-width: rem-calc(30);
            position: absolute;
            color: $white;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: rem-calc(5);
            padding: rem-calc(2 5);
            font-size: rem-calc(11);
            line-height: 1.2;
            text-align: center;
            white-space: nowrap;
            background: $secondary-color;
            transition: opacity .3s ease;

            &:after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 6px 0 6px;
                border-color: $secondary-color transparent transparent transparent;
                position: absolute;
                left: 50%;
                top:100%;
                transform: translateX(-50%);
            }
        }
    }
}// .rheostat


.pages-grid {
    .#{$ts-algolia-list--selector} {
        margin-bottom: 1rem;

        @include breakpoint(large) {
            margin-bottom: 1.5rem;
        }

        .extra-title {
            font-size: rem-calc(22);
            font-weight: 400;
        }
    }

    .#{$ts-algolia-list--selector}--body {
        @include grid-row;
        @include grid-row-nest;
    }

    .#{$ts-algolia-item--selector},
    .#{$ts-algolia-infinite-item--selector} {
        @include grid-col;
        @include grid-col-end;
        margin-bottom: 1rem;

        @include breakpoint(large) {
            @include grid-col-size(6);
            margin-bottom: 1.5rem;
        }

        &:nth-child(odd) {
            clear: left;
        }
    }

    .ais-hits--more-items {
        display: inline-block;
        color: $anchor-color;
        cursor: pointer;

        .text {
            text-decoration: underline;
        }

        .count {
            color: $body-font-color;
        }

        &:hover {
            .text {
                text-decoration: none;
            }
        }
    }

    .algoliasearch-page-hit {
        display: block;
        @include header-font-family($header-font-family);
        font-weight: 700;
        font-size: rem-calc(18);
        margin-bottom: .25rem;

        em {
            font-style: normal;
        }
    }
}// .pages-grid

// Loader
.column.main {
    @if ($ts-loadmore-btn-view == 'circle') {
        .ais-infinite-hits--showmore {
            color: $anchor-color;
            font-size: rem-calc(16);
            @include header-font-family($ts-loadmore-btn-font-family);
            margin: 1rem auto;
            position: relative;
            line-height: 1;
            text-align: center;
            text-transform: uppercase;
            width: $ts-loadmore-btn-width;
            height: $ts-loadmore-btn-width;
            clear: both;

            button {
                display: table-cell;
                text-decoration: none;
                height: $ts-loadmore-btn-height;
                line-height: 1.1;
                vertical-align: middle;
                width: $ts-loadmore-btn-width;
            }

            &:before {
                border: $ts-loadmore-btn-border-size $ts-loadmore-btn-border-style $anchor-color;
                border-radius: 100%;
                content: "";
                height: rem-calc(120);
                left: 0;
                position: absolute;
                width: $ts-loadmore-btn-width;
            }

            &:after {
                $ts-loadmore-btn-spinner-origin-x : ($ts-loadmore-btn-width - $ts-loadmore-btn-spinner-size)/2;

                display: block;
                content: "";
                position: absolute;
                margin: 0 auto;
                width: $ts-loadmore-btn-spinner-size;
                height: $ts-loadmore-btn-spinner-size;
                background-color: white;
                -webkit-animation: spin-loading-more 1s linear infinite;
                animation: spin-loading-more 1s linear infinite;
                -webkit-transform-origin: -$ts-loadmore-btn-spinner-origin-x $ts-loadmore-btn-spinner-size/2;
                -ms-transform-origin: -$ts-loadmore-btn-spinner-origin-x $ts-loadmore-btn-spinner-size/2;
                transform-origin: -$ts-loadmore-btn-spinner-origin-x $ts-loadmore-btn-spinner-size/2;
                border-radius: 50%;
                top:  $ts-loadmore-btn-spinner-origin-x;
                left: $ts-loadmore-btn-width - $ts-loadmore-btn-spinner-size/2;
            }

            &:hover {
                color: rgba(black,0.9);
                cursor: pointer;

                &:before {
                    border-color: rgba(black,0.9);
                }
            }
        }// .ais-infinite-hits--showmore
    } @else {
        .ais-infinite-hits--showmore {

            button {
                @include button-extended;
                @include button-style($primary-color);
                @include button-hollow;
                @include button-hollow-style-extend($primary-color, $primary-color);
                display: block;
                width: 100%;
                max-width: rem-calc(250);
                margin-left: auto;
                margin-right: auto;
                clear: both;
                color: $body-font-color;
                cursor: pointer;

                &:hover,
                &:focus {
                    color: $white;
                    border-color: $primary-color;
                    box-shadow: inset 0 0 0 1px $primary-color;
                    background: $primary-color;
                }

                &[disabled] {
                    display: none;
                    opacity: .25;
                    cursor: default;

                    &:hover,
                    &:focus {
                        color: $body-font-color;
                        border: 1px solid $primary-color;
                        box-shadow: inset 0 0 0 1px $primary-color;
                        background: transparent;
                    }
                }
            }


        }// .ais-infinite-hits--showmore
    }
}// algolia show-more spinner


.algolia-current-refinements {
    .ais-root.ais-hierarchical-menu {
        @if not $ts-algolia-mobile-category-filter-title-show {
            .ais-header {
                @include at;
            }
        }

        .ais-body {
            margin-bottom: 0;
            padding-bottom: $ts-algolia-mobile-category-filter-bottom-indent;

            .ais-hierarchical-menu--link {
                font-size: $ts-sidebar-mobile-category-font-size;
                padding: $ts-sidebar-mobile-category-padding;
                color: $ts-sidebar-mobile-category-color;
                @if ( $ts-sidebar-mobile-category-image != false ) {
                    background: url("#{$ts-path-to-images}/#{$ts-sidebar-mobile-category-image}.#{$ts-sidebar-mobile-category-image-type}") $ts-sidebar-mobile-category-image-position no-repeat;
                    background-size: $ts-sidebar-mobile-category-image-size;
                }

                &:hover {
                    color:$anchor-color;
                }
            }
        }// .ais-body
    }
}// .algolia-current-refinements


// Mobile algolia filters
.algolia-instant-selector-results {
    .page-main {
        .sidebar.algolia-left-container {
            .algolia-current-refinements {
                margin-top: 0;
            }
        }
        .category-header {
            position: static;
            width: auto;
            clear: both;

            .category-view {
                display: block;
            }
            .category-header-mobile {
                display: none !important;
            }
            .page-title-wrapper,
            .top-controls-wrapper {
                @include breakpoint(medium down) {
                    @include element-invisible-off;
                }
            }
        }
    }

    .page-title-wrapper {
        .page-title {
            @include breakpoint(small) {
                font-size: rem-calc(map-deep-get($header-styles, small, h1, font-size));
            }
            @include breakpoint(medium) {
                font-size: rem-calc(map-deep-get($header-styles, medium, h1, font-size));
            }
            @include breakpoint(xlarge) {
                font-size: rem-calc(map-deep-get($header-styles, xlarge, h1, font-size));
            }
        }
    }
}// .algolia-instant-selector-results

.toolbar-sorter.sorter {
    @include show-for(large);
}//.toolbar-sorter.sorter

.algolia-mobile-filter-buttons {
    display: flex;
    justify-content: space-between;

    @include breakpoint(large) {
        display: none;
    }
    .button {
        flex-basis: 48%;

        @if ($ts-algolia-mobile-filters-btn-view == 'primary') {
            background-color: $button-background;
            &:hover {
                color: $button-color;
                background-color: $button-background-hover;
            }
            &:active,
            &:focus {
                color: $button-color;
                background-color: $button-background-active;
            }

        } @elseif ($ts-algolia-mobile-filters-btn-view == 'secondary') {
            color: $button-color;
            background-color: $button-background-secondary;
            &:hover {
                background-color: $button-background-secondary-hover;
            }
            &:active,
            &:focus {
                background-color: $button-background-secondary-active;
            }

        } @elseif ($ts-algolia-mobile-filters-btn-view == 'hollow') {
            @include button-hollow;
            @include button-hollow-style-extend(rgba($black,.5), $white);
            color: $button-color-alt !important;
            box-shadow: none !important;
            &:hover {
                background-color: $box-color;
            }
            &:active,
            &:focus {
                background-color: darken($box-color, 4.71);
            }
        }
        font-size: $ts-algolia-mobile-filters-btn-font-size;

        &:before {
            width: $ts-algolia-mobile-sort-btn-icn-width;
            height: $ts-algolia-mobile-sort-btn-icn-height;
            display: inline-block;
            vertical-align: middle;
            content: '';
            margin-right: $ts-algolia-mobile-filter-btn-icn-right-indent;
            background-image: url("#{$ts-path-to-images}/#{$ts-algolia-mobile-sort-btn-icn}.svg");
            background-size: 100% auto;
            background-repeat: no-repeat;
        }

        &.refine-button {
            &:before {
                width: $ts-algolia-mobile-filter-btn-icn-width;
                height: $ts-algolia-mobile-filter-btn-icn-height;
                background-image: url("#{$ts-path-to-images}/#{$ts-algolia-mobile-filter-btn-icn}.svg");
            }
        }
    }
}// .algolia-mobile-filter-buttons

.toolbar-sorter-modal {
    .sorter-title,
    .sorter-action {
        @include at;
    }
    .instant-search-sorter-options {
        @include lsn;
        border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

        .sorter-options {
            position: relative;
            padding: $ts-sidebar-mobile-category-padding;
            padding-left: $ts-mobile-sorting-left-indent;
            color: $ts-sidebar-mobile-category-color;
            font-size: $ts-sidebar-mobile-category-font-size;
            font-weight: $ts-sidebar-mobile-category-font-weight;
            font-family: $ts-sidebar-mobile-category-font-family;
            text-transform: $ts-sidebar-main-filter-text-transform;
            border-bottom: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

            @if ( $ts-sidebar-mobile-category-image != false ) {
                background: url("#{$ts-path-to-images}/#{$ts-sidebar-mobile-category-image}.#{$ts-sidebar-mobile-category-image-type}") $ts-sidebar-mobile-category-image-position no-repeat;
                background-size: $ts-sidebar-mobile-category-image-size;
            }

            &:last-child {
                border-bottom: 0;
            }
            &.selected {
                font-weight: $ts-sidebar-mobile-category-active-state;
                background-image: none;

                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    @if ( $ts-mobile-filter-image != false ) {
                        width: $ts-mobile-filter-image-width;
                        height: $ts-mobile-filter-image-height;
                        background: url("#{$ts-path-to-images}/#{$ts-mobile-filter-image}.#{$ts-mobile-filter-image-type}") no-repeat;
                        background-size: 100% auto;
                    }
                }
            }
        }
    }
} // .toolbar-sorter-modal
