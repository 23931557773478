// Pimcore Blocks
// 1. Teaser Block
$ts-pimcore-teaser-bg                                        : $box-color !default;
$ts-pimcore-teaser-padding                                   : ( small     : 1.4rem 2rem 2.8rem,
																 medium    : 1.5rem 1.5rem 1.75rem,
																 medium-4  : 1.2rem 1.5rem 2rem,
																 medium-12 : 2.25rem 2rem 1rem 2rem,
																 xlarge     : 1.8rem 1.4rem 1.8rem 2.2rem,
																 xlarge-12  : 2.5rem,
																 xxlarge    : 3rem) !default;
$ts-pimcore-teaser-button-position-top                       : ( medium-12 : 2.25rem,
																 xlarge     : 1.8rem,
																 xlarge-12  : 2.5rem,
																 xxlarge    : 3rem) !default;
$ts-pimcore-teaser-button-position-right                     : ( medium-12 : 2rem,
																 xlarge     : 1.4rem,
																 xlarge-12  : 2.5rem,
																 xxlarge    : 3.25rem) !default;
$ts-pimcore-teaser-title-font-family                         : $header-font-family !default;
$ts-pimcore-teaser-title-font-size                           : ( small     : rem-calc(28),
																 medium    : rem-calc(22)) !default;
$ts-pimcore-teaser-title-font-weight                         : 300 !default;
$ts-pimcore-teaser-title-color                               : $body-font-color !default;
$ts-pimcore-teaser-subtitle-font-family                      : $body-font-family !default;
$ts-pimcore-teaser-subtitle-font-size                        : ( small     : rem-calc(13),
																 medium    : rem-calc(11)) !default;
$ts-pimcore-teaser-subtitle-font-weight                      : 400 !default;
$ts-pimcore-teaser-subtitle-color                            : rgba($body-font-color, .7) !default;

$ts-pimcore-teaser-image-height                              : ( medium    : rem-calc(100),
																 medium-12 : rem-calc(215)) !default;

// 2. Content with Image Block
$ts-pimcore-content-title-font-family                        : $header-font-family !default;
$ts-pimcore-content-title-font-size                          : ( small    : rem-calc(22),
																  medium   : rem-calc(28)) !default;
$ts-pimcore-content-title-font-weight                        : 300 !default;

// 2. Google Block
$ts-pimcore-map-height                                       : rem-calc(285) !default;

// 3. Video Block (with custom button)
$ts-pimcore-video-button-width                               : 40px !default;
$ts-pimcore-video-button-height                              : $ts-pimcore-video-button-width !default;
$ts-pimcore-video-button-icon-type                           : svg !default; // none or value
$ts-pimcore-video-button-icon                                : "icon-video-play" !default;
$ts-pimcore-video-button-icon-size                           : $ts-pimcore-video-button-width auto !default;
$ts-pimcore-video-button-icon-bcolor                         : none !default;
$ts-pimcore-video-button-icon-bcolor--hover                  : none !default;
$ts-pimcore-video-button-icon-radius                         : 100% !default;
$ts-pimcore-video-button-icon-shadow                         : (3px 2px 25px rgba($black,0.35)) !default;
$ts-pimcore-video-button-icon-shadow--hover                  : $ts-pimcore-video-button-icon-shadow !default;
$ts-pimcore-video-button-icon-margin                         : (0 auto 10px) !default;
$ts-pimcore-video-button-label-color                         : rgba(white,0.8) !default;
$ts-pimcore-video-button-label-color--hover                  : white !default;
$ts-pimcore-video-button-label-font-size                     : 12px !default;
$ts-pimcore-video-button-label-text-transform                : none !default;
$ts-pimcore-video-button-label-text-align                    : center !default;
$ts-pimcore-video-content-color                              : white !default;
$ts-pimcore-video-content-text-align                         : $ts-pimcore-video-button-label-text-align !default;

// 4. Text Block
$ts-pimcore-wysiwyg-font-size-small                          : 11px !default;
$ts-pimcore-wysiwyg-font-size-normal                         : 13px !default;
$ts-pimcore-wysiwyg-font-size-large                          : 15px !default;
$ts-pimcore-wysiwyg-font-size-xlarge                         : 17px !default;
$ts-pimcore-wysiwyg-font-size-xxlarge                        : 19px !default;


// 5. Accordion
$ts-pimcore-accordion-title__font-weight					 : bold !default;
$ts-pimcore-accordion__border								 : default !default; // default, lines
$ts-pimcore-accordion__toggler								 : custom !default; // default, custom
$ts-pimcore-accordion-title__background--active				 : $white !default;

// 6. Banner
$convert-banner-overlay-alpha								 : 0.6 !default;

// 7. Image
$ts-pimcore-image-marker__width                              : rem-calc(24) !default;
$ts-pimcore-image-marker__height                             : $ts-pimcore-image-marker__width !default;
$ts-pimcore-image-marker__icon                               : url("#{$ts-path-to-images}/icon-marker.svg") !default; // value or false
$ts-pimcore-image-marker__icon-size                          : (100% 100%) !default;
$ts-pimcore-image-marker__border-radius                      : 100% !default; // value or false
$ts-pimcore-image-marker__box-shadow                         : (2px 2px 7px rgba(0,0,0,.1)) !default; // value or false

$ts-pimcore-image-hotspot__background-color--hover           : rgba(255,255,255,.15) !default; // value or false
