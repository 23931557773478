.convert-banner {
	position: relative;

	@include breakpoint(medium down) {
		margin-bottom: .5rem;
	}

	&:not[class^="spacing-"],
	&:not[class*=" spacing-"] {
		margin-bottom: 0 !important;
	}

	.convert-banner-image {
		position: relative;
		img {
			display: block;
			width: 100%;
			height: auto;
			margin-bottom: 0;
		}
		.convert-banner-overlay {
			background-color: rgba($black, $convert-banner-overlay-alpha);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}

	.convert-banner-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		text-align: center;
		width: 80%;

		.convert-wysiwyg {
			margin-left: auto;
			margin-right: auto;

			@include breakpoint(large) {
				max-width: 53ch;
			}
		}

		&.banner-text-left {
			left: map-get($grid-column-gutter, small);
			right: map-get($grid-column-gutter, small);
			transform: translateY(-50%);
			width: auto;
			text-align: left;

			@include breakpoint(xlarge) {
				left: map-get($grid-column-gutter, medium);
				right: map-get($grid-column-gutter, medium);
			}

			.convert-wysiwyg {
				margin-left: 0;

				> * {
					text-align: left;
				}
			}
		}

		&.banner-text-right {
			left: map-get($grid-column-gutter, small);
			right: map-get($grid-column-gutter, small);
			transform: translateY(-50%);
			width: auto;
			text-align: right;

			@include breakpoint(xlarge) {
				left: map-get($grid-column-gutter, medium);
				right: map-get($grid-column-gutter, medium);
			}

			.convert-wysiwyg {
				margin-right: 0;

				> * {
					text-align: right;
				}
			}
		}

		> *,
		.convert-wysiwyg * {
			text-align: center;
		}
		.convert-wysiwyg p {
			line-height: 1.3;
		}

		*:last-child {
			margin-bottom: 0 !important;
		}

		.button {
			font-size: 15px !important;
			padding: 0.62em 2.3em 0.87em !important;
			margin-top: 1rem;
		}
	}

	&.cs-black {
		.convert-banner-image {
			.convert-banner-overlay {
				background-color: rgba($white, $convert-banner-overlay-alpha);
			}
		}

		.convert-wysiwyg * {
			color: rgba($black, 0.8);
		}

		&.has-content-overlay {
			.convert-banner-content {
				background-color: rgba($white, $convert-banner-overlay-alpha);
			}
		}
	}

	&.cs-white {
		.convert-wysiwyg * {
			color: rgba($white, 0.8);
		}

		.button {
			border-color: rgba($white, 0.5);
			background-color: transparent;
			color: white;

			&:hover {
				background-color: rgba($white, 0.15);
			}
		}

		&.has-content-overlay {
			.convert-banner-content {
				background-color: rgba($black, $convert-banner-overlay-alpha);
			}
		}
	}

	&.hover-jump {

		&:hover {
			.convert-wysiwyg {
				transform: translate(0,-.75rem) !important;
			}
		}
	}

	&.hover-brightness img {
		transition: all 450ms cubic-bezier(0,.275,.125,1);
	}

	&.hover-brightness {
		&:hover {
			img {
				filter: brightness(75%);
			}
		}
	}

	&.has-content-overlay {
		.convert-banner-content {
			padding: map-get($grid-column-gutter, small);
		}
	}

	&.content--hover {
		.convert-banner-content {
			opacity: 0;
			transition: all 450ms cubic-bezier(0,.275,.125,1);
		}

		&:hover {
			.convert-banner-content {
				opacity: 1;
			}
		}
	}
}