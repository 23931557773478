$mm_module: '.mm-listitem';

.mm-listview {
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;

    .divider {
        margin-bottom: 27px;

        &:after {
            display: none;
        }
    }
}

#{$mm_module} {
    color: $ts-snowdog-menu-text-color;
    border-color: $ts-snowdog-menu-border-color;

    list-style: none;
    display: block;
    padding: 0;
    margin: 0;

    position: relative;
    display: flex;
    flex-wrap: wrap;

    &:after {
        content: '';
        border-color: inherit;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        background: none;
        border-radius: 0;
    }

    a,
    a:hover {
        text-decoration: none;
    }

    &.extra-links {
        &:after {
            display: none;
        }
    }
}

%mm_listitem_content {
    display: block;
    padding: (($ts-snowdog-menu-listitem-size - $ts-snowdog-menu-line-height) / 2);
    padding-left: 0;
    padding-right: 0;
    font-size: $ts-snowdog-menu-listitem-font-size;
    font-weight: $ts-snowdog-menu-listitem-weight;
    font-style: $ts-snowdog-menu-listitem-style;
    font-family: $ts-snowdog-menu-listitem-font-family;
    line-height: $ts-snowdog-menu-listitem-line-height;
}

#{$mm_module}__text {
    @extend %mm_listitem_content;
    @include mm_ellipsis;

    padding-left: $ts-snowdog-menu-listitem-indent;
    padding-right: $ts-snowdog-menu-padding;
    flex-grow: 1;
    // flex-basis: 10%;
}

#{$mm_module}__btn {
    @extend %mm_listitem_content;

    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // padding-right: $ts-snowdog-menu-subopen-width;
}

#{$mm_module}__text,
#{$mm_module}__btn {
    .extra-links & {
        font-size: $ts-snowdog-menu-listitem-secondary-font-size;
        color: rgba($black, .8);
    }
}

#{$mm_module} {
    &:active,
    &.active:active,
    &_selected {
        > #{$mm_module}__text,
        > #{$mm_module}__text:visited {
            color: $ts-snowdog-menu-emphasized-text-color;
            background: $ts-snowdog-menu-emphasized-background-color;
        }
        > #{$mm_module}__btn {
            &:after,
            &:before {
                border-color: $ts-snowdog-menu-emphasized-arrow-color;
            }
        }
    }

    &.active,
    &_opened {
        > #{$mm_module}__text {
            color: $ts-snowdog-menu-highlighted-text-color;
            background: $ts-snowdog-menu-highlighted-background-color;
        }
        > #{$mm_module}__btn {
            &:after,
            &:before {
                border-color: $ts-snowdog-menu-highlighted-arrow-color;
            }
        }
    }
}
