.mm-menu {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    position: absolute;
    top: $ts-snowdog-menu-offset-top;
    right: $ts-snowdog-menu-offset-right;
    bottom: $ts-snowdog-menu-offset-bottom;
    left: $ts-snowdog-menu-offset-left;
    z-index: 0;

    background: $ts-snowdog-menu-background-color;
    border-color: $ts-snowdog-menu-border-color;
    color: $ts-snowdog-menu-text-color;
    line-height: $ts-snowdog-menu-line-height;

    -webkit-tap-highlight-color: $ts-snowdog-menu-common-emphasized-background-color;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    a,
    a:link,
    a:active,
    a:visited,
    a:hover {
        text-decoration: none;
        color: inherit;
    }
}
