$mm_module: '.mm-panel';

#{$mm_module} {
    @if ($ts-snowdog-menu-ie11-fallbacks) {
        &:after {
            height: $ts-snowdog-menu-listitem-size;
        }
    }

    z-index: 0;
    box-sizing: border-box;
    width: 100%;

    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;

    color: $ts-snowdog-menu-text-color;
    border-color: $ts-snowdog-menu-border-color;
    background: $ts-snowdog-menu-background-color;

    transform: translate3d(100%, 0, 0);
    transition: transform $ts-snowdog-menu-transition-duration $ts-snowdog-menu-transition-function;

    //	Fixes css from other styles hiding submenus
    &:not(.mm-hidden) {
        display: block;
    }

    //	Because padding-bottom is ignored when a DIV is scrollable
    &:after {
        content: '';
        display: block;
        height: $ts-snowdog-menu-panel-bottom-indent;
    }

    &_opened {
        z-index: 1;
        transform: translate3d(0%, 0, 0);
    }

    &_opened-parent {
        transform: translate3d(-$ts-snowdog-menu-subpanel-offset, 0, 0);
    }

    &_highest {
        z-index: 2;
    }

    &_noanimation {
        transition: none !important;

        &#{$mm_module}_opened-parent {
            transform: translate3d(0, 0, 0);
        }
    }

    .mm-panels > & {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

#{$mm_module}__content {
    padding: $ts-snowdog-menu-panel-padding $ts-snowdog-menu-panel-padding 0;
}

.mm-panels {
    position: relative;
    height: 100%;
    flex-grow: 1;
    overflow: hidden;

    background: $ts-snowdog-menu-background-color;
    border-color: $ts-snowdog-menu-border-color;
    color: $ts-snowdog-menu-text-color;
}
