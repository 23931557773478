$mm_module: '.mm-btn';

#{$mm_module} {
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    box-sizing: border-box;
    width: $ts-snowdog-menu-btn-size;
    padding: 0;

    &:before,
    &:after {
        border: $ts-snowdog-menu-arrow-thick solid $ts-snowdog-menu-dimmed-text-color;
    }
    &_prev {
        &:before,
        &:after {
            border: $ts-snowdog-menu-arrow-thick solid $ts-snowdog-menu-arrow-prev-color;
        }
    }
}

%mm_btn_arrow {
    content: '';
    border-bottom: none;
    border-right: none;
    box-sizing: content-box;
    display: block;
    width: $ts-snowdog-menu-arrow-width;
    height: $ts-snowdog-menu-arrow-height;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: $ts-snowdog-menu-arrow-opacity;
}

#{$mm_module} {
    &_prev {
        width: $ts-snowdog-menu-prev-width;
    }
    &_prev:before {
        @extend %mm_btn_arrow;
        @include mm_btn_arrow_prev;
        width: $ts-snowdog-menu-arrow-prev-width;
        height: $ts-snowdog-menu-arrow-prev-height;
        opacity: $ts-snowdog-menu-arrow-prev-opacity;
        top: $ts-snowdog-menu-prev-top-shift;
    }

    &_next:after {
        @extend %mm_btn_arrow;
        @include mm_btn_arrow_next;
    }

    &_close {
        &:before,
        &:after {
            content: '';
            box-sizing: content-box;
            display: block;
            width: 5px;
            height: 5px;
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;

            transform: rotate(-45deg);
        }
        &:before {
            border-right: none;
            border-bottom: none;
            right: $ts-snowdog-menu-listitem-indent - 2;
        }
        &:after {
            border-left: none;
            border-top: none;
            right: $ts-snowdog-menu-listitem-indent + 5;
        }
    }
}
