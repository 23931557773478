//
//  For Magento Debug tool
//  _____________________________________________
.ui-tabs-nav,
.qdb-panel ul {
	@include lsn;
}

//
//  Body
//  _____________________________________________
body {
	font-size: $body-font-size;
	font-family: $body-font-family;
	font-weight: $global-weight-normal;
    line-height: $global-lineheight;
    color: $body-font-color;
	background-color: $body-background;

	&.is-reveal-open {
		overflow: auto;
	}
}

html.is-reveal-open,
html.is-reveal-open body {
    overflow: auto;
    position: static;
    min-height: auto;
    user-select: auto;   
}

//  Home Banners
//  _____________________________________________
.home-banners {
	margin-bottom: 1rem;
	@include breakpoint(large) {
		margin-bottom: 2rem;
	}
}// .home-banners


//  Skip Link for Usability
//  _____________________________________________
.skipLink {
    display: block;
    position: fixed;
    top: -100%;
    left: 0;
    right: 0;
    padding: .35rem .25rem .25rem .25rem;
    text-align: center;
    color: $white;
    background-color: $primary-color;
    border: none;
    transition: all .3s ease;
    z-index: ($ts-headbar-zindex + 1);

    &:active,
    &:focus {
    	top: 0;
    	outline: none;
    	color: $white;
    }
}// .skipLink

// Breadcrumbs
.breadcrumbs {
	.items {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			&:after {
				display: none;
			}
			&:not(:last-child)::after {
				width: auto;
				height: auto;
				content: $breadcrumbs-item-slash-view;
				display: inline-block;
				vertical-align: baseline;
				position: relative;
				top: auto;
				left: auto;
				font-size: $breadcrumbs-item-font-size;
				color: $breadcrumbs-item-color;
				background-color: transparent;
			}

			@if not $breadcrumbs-item-slash {
				$slash: '';

				&:not(:last-child)::after {
					content: $slash;
					width: 5px;
					height: 8px;
					margin: 0 $breadcrumbs-item-margin;
					background: url("#{$ts-path-to-images}/icon-breadcrumb.#{$ts-breadcrumb-icon-type}") no-repeat;
					background-size: 100% auto;
					background-color: transparent;
				}
			}
		}
	}
}
