//  IDE Main | Foundation for Sites Settings
//  -----------------------------

// 1. Global
// ---------
$body-font-size : rem-calc(16);
$box-color: #F1EFED;

$global-radius: 4px;

$foundation-palette: (
	primary: #1A2746,
	secondary: #181818,
	success: #30A95E,
	warning: #ffae00,
	alert: #D41367,
);

$primary-color: #1A2746;
$white: white;
$black: #181818;
$highlight: #D52469;
$button-alt-color: #002F6C;

$body-background: $white;
$body-font-color: $black;

@include add-foundation-colors;

// 4. Base Typography
// ------------------

$font-family-alt: $header-font-family;

$regular-font-family: ('silka-regular-webfont', sans-serif);
$medium-font-family: ('silka-medium-webfont', sans-serif);
$bold-font-family: ('silka-semibold-webfont', sans-serif);
$italic-font-family: ('silka-regularitalic-webfont', italic);

$body-font-family: $regular-font-family;
$header-font-family: $regular-font-family;

$header-styles: (
  small: (
    'h1': ('font-size': 28, 'line-height': 34),
    'h2': ('font-size': 28, 'line-height': 32),
    'h3': ('font-size': 26, 'line-height': 38),
    'h4': ('font-size': 24, 'line-height': 32),
    'h5': ('font-size': 15, 'line-height': 18),
    'h6': ('font-size': 13, 'line-height': 16),
  ),
  medium: (
	'h1': ('font-size': 42, 'line-height': 50),
	'h2': ('font-size': 34, 'line-height': 40),
	'h3': ('font-size': 27, 'line-height': 39),
	'h4': ('font-size': 25, 'line-height': 33),
	'h5': ('font-size': 17, 'line-height': 21),
	'h6': ('font-size': 15, 'line-height': 18),
  ),
  xlarge: (
    'h1': ('font-size': 46, 'line-height': 67),
    'h2': ('font-size': 40, 'line-height': 48),
    'h3': ('font-size': 28, 'line-height': 40),
    'h4': ('font-size': 26, 'line-height': 34),
    'h5': ('font-size': 19, 'line-height': 23),
    'h6': ('font-size': 17, 'line-height': 20),
  ),
);

$header-font-weight: 400;
$header-text-transform: none;
$paragraph-lineheight: 1.5;
$anchor-color: $body-font-color;


// 7. Accordion
// ------------
$accordion-background: $body-background;
$accordion-plusminus: false;
$accordion-item-color: $body-font-color;
$accordion-title-font-size: rem-calc(18);
$accordion-item-padding: 21px 25px 21px 60px;
$accordion-content-padding: 20px 40px 20px 58px;

// 9. Badge
// --------
$badge-palette: (
	primary: $success-color,
	secondary: $black
);
$badge-palette: map-remove($badge-palette, success, warning, alert);

// 10. Breadcrumbs
// ---------------
$breadcrumbs-item-font-size: rem-calc(13);
$breadcrumbs-item-uppercase: false;

// 11. Button
// ----------

$button-font-family: $bold-font-family;
$button-gradient: false;
$button-weight: 400;
$button-background: $success-color;
$button-background-hover: rgba($success-color,0.75);
$button-background-active: $button-background-hover;
$button-background-secondary: $primary-color;
$button-radius: 50px;

$button-sizes: (
	tiny: 0.6rem,
	small: 0.75rem,
	default: 1.1875rem,
	xlarge: 1.1875rem,
);

$button-palette: (
    primary: $success-color,
    secondary: $primary-color
);

$button-palette: map-remove($button-palette, (
    success,
    alert,
    warning
));


// 19. Forms
// ---------

$input-font-family: $regular-font-family;
$input-font-size: rem-calc(17);
$input-background: #f8f7f6;
$input-select-height: ($input-font-size + $form-spacing + rem-calc(15));
$input-border: 1px solid #9DA3AF;
$input-padding: rem-calc(10) rem-calc(27);
$input-radius: 50px;
$select-radius: 50px;
$select-triangle-right-space: 3rem;
$select-triangle-icon-position: calc(100% - 22px) 50%;
$select-triangle-icon-size: 16px;
$form-label-color: $primary-color;
$form-label-font-size: rem-calc(15);

// 24. Off-canvas
// --------------

$offcanvas-background: $primary-color;


// 31. Table
// ---------

