//  IDE Main | Theme Settings
//  -----------------------------

// 00.3 Borders
$ts-main-border-color                                        : rgba($black,0.15);

// 00.5 Pro Block
$ts-pro-block-title-font-size                                : rem-calc(20);
$ts-pro-block-title-text-transform                           : none;
$ts-pro-block-title-responsive                               : ( medium :  ( font-size : 1.3 * $ts-pro-block-title-font-size),
                                                                xxlarge : ( font-size : 1.6 * $ts-pro-block-title-font-size),
                                                                xxxlarge : ( font-size : 1.8 * $ts-pro-block-title-font-size));
$ts-pro-block-title-side-lines-show                          : false;

// 00.9 Product Photo settings
$ts-product-photo-overlay-color                              : rgba(#463717, 0.04);


// 00.11 Lists
$ts-custom-ordered-lists                                     : false;
$ts-custom-unordered-lists                                   : false;

//00.12 Titles
$ts-common-titles-border-bottom                              : false;
$ts-common-titles-margin-bottom                              : (small: 1.75rem, large: 1.75rem);
$ts-common-titles-padding-bottom                             : (small: 0,  large: 0);

// 00.13 Algolia setup
$ts-algolia--version                                         : 3;
$ts-algolia-list--selector                                   : "ais-Hits";
$ts-algolia-infinite-list--selector                          : "ais-InfiniteHits";
$ts-algolia-item--selector                                   : $ts-algolia-list--selector + "-item";
$ts-algolia-infinite-item--selector                          : $ts-algolia-infinite-list--selector + "-item";
$ts-algolia-pagination--selector                             : "ais-Pagination";
$ts-algolia-pagination-item--selector                        : $ts-algolia-pagination--selector + "-item";
$ts-algolia-pagination-link--selector                        : $ts-algolia-pagination--selector + "-link";
$ts-algolia-pagination-page--selector                        : $ts-algolia-pagination--selector + "-page";

// 01. Footer settings
$ts-footer-view                                              : full-width;
$ts-footer-bg-has-image                                      : true;
$ts-footer-bg-src                                            : ( small : 'bg__footer--s', xlarge : 'bg__footer');
$ts-footer-bg-type                                           : ( small: png, xlarge : png);
$ts-footer-bg-size                                           : ( small: auto, xlarge : auto);
$ts-footer-bg-position                                       : ( small: 100% 0, xlarge : 100% 100%);
$ts-footer-bg-color                                          : $primary-color;
$ts-footer-font-color                                        : $white;
$ts-footer-font-size                                         : 17px;
$ts-footer-font-line-height                                  : 1.1875;

$ts-footer-bottom-border                                     : false;
$ts-footer-border                                            : false;

$ts-footer-indents-vt                                        : ( small : 6, medium: .75, xxlarge : 1.9);

$ts-footer-header-font-size                                  : 22px;
$ts-footer-header-font-weight                                : 400;
$ts-footer-header-text-transform                             : none;

// 01.1 Footer Navigation settings
$ts-footer-nav-anchor-color                                  : $ts-footer-font-color;
$ts-footer-nav-anchor-color-hover                            : $ts-footer-nav-anchor-color;
$ts-footer-nav-font-weight                                   : 400;

// 01.2 Footer Cookies settings
$ts-footer-cookies-color                                     : rgba($ts-footer-font-color, .4);
$ts-footer-cookies-anchor-color                              : rgba($ts-footer-font-color, .4);

// 01.3 Footer Subscribe settings
$ts-footer-suscribe-text-align                               : center;
$ts-footer-input-place                                       : block;
$ts-footer-subscribe-max-width                               : rem-calc(361);
$ts-footer-subscribe-input-bg                                : $white;
$ts-footer-subscribe-input-color                             : $body-font-color;
$ts-footer-subscribe-input-placeholder-color                 : rgba($body-font-color,0.7);
$ts-footer-subscribe-input-border-color                      : transparent;
$ts-footer-subscribe-input-border-bottom-color               : $ts-footer-subscribe-input-border-color;
$ts-footer-subscribe-input-font-size                         : 15px;
$ts-footer-subscribe-input-spacing                           : .75rem 20px;
$ts-footer-subscribe-input-font-weight                       : 400;
$ts-footer-subscribe-input-focus-color                       : $ts-footer-font-color;
$ts-footer-subscribe-center                                  : true;
$ts-footer-button-border                                     : none;
$ts-footer-subscribe-input-height                            : ( small : rem-calc(50), xlarge : rem-calc(50));

$ts-footer-desc-bottom-indent                                : 39px;
$ts-footer-desc-font-size                                    : 15px;

$ts-terms-label-color                                        : $black;
$ts-terms-link-color                                         : $ts-terms-label-color;
$ts-terms-link-decoration                                    : underline;
$ts-terms-link-decoration-hover                              : none;

$ts-footer-button-view                                       : text;
$ts-footer-button-bg-view                                    : solid;
$ts-footer-button-bg                                         : $success-color;
$ts-footer-button-bg-hover                                   : mix($ts-footer-button-bg,$black,80%);
$ts-footer-button-color                                      : $white;
$ts-footer-button-color-hover                                : $white;
$ts-footer-button-font-size                                  : 19px;
$ts-footer-button-font-weight                                : 700;

// 01.4 Footer Contact settings
$ts-footer-contact-tel-color                                 : $ts-footer-font-color;
$ts-footer-contact-tel-icon                                  : 'icon-phone';
$ts-footer-contact-email-color                               : $ts-footer-font-color;
$ts-footer-contact-email-font-weight                         : 400;

// 01.5 Footer Social settings
$ts-footer-social-anchor-color                               : $primary-color;
$ts-footer-social-anchor-color-hover                         : $black;
$ts-footer-social-icon-size                                  : 47px;
$ts-footer-social-right-indent                               : 6px;
$ts-footer-social-icon-opacity                               : 1;
$ts-footer-social-icon-opacity-hover                         : 0.75;
$ts-footer-social-icon-networks                              : (facebook, instagram, youtube, linkedin);

// 01.7 Footer Copyright settings
$ts-footer-copyright-color                                   : rgba($white, .5);
$ts-footer-copyright-font-size                               : 12px;

$ts-usp-item-icon-indent                                     : 1rem;

// 02. Header settings
$ts-headbar-top-border                                       : false;
$ts-headbar-bg-color                                         : $primary-color;
$ts-headbar-text-color                                       : $white;

$ts-headbar-height                                           : ( small : rem-calc(72),
                                                                 large : rem-calc(164),
                                                                 xlarge : rem-calc(164),
                                                                 xxlarge : rem-calc(164),
                                                                 xxxlarge : rem-calc(164) );

$ts-headbar-logo-width                                       : 123px;
$ts-headbar-logo-mobile-height                               : 46px;

// 02.0 Sticky Header
$ts-sticky-headbar-height                                    : rem-calc(76);
$ts-sticky-headbar-height-spacing-top                        : 0;
$ts-sticky-headbar-height-spacing-bottom                     : 0;
$ts-sticky-headbar-height-spacing                            : 0;

// 02.1 Top links
$ts-headbar-anchor-color                                     : $ts-headbar-text-color;
$ts-headbar-anchor-font-weight                               : 400;
$ts-top-links-anchor-font-size                               : 15px;
$ts-top-links-bottom-indent                                  : ( large : .9rem,
                                                                 xlarge : .9rem,
                                                                 xxlarge : -1.6rem,
                                                                 xxxlarge : .75rem );

// 02.2 Header Search
$ts-header-search-mobile-parent                              : 'mobile-search-wrapper';
$ts-header-search-mobile-sticky                              : true;
$ts-header-search-mobile-sticky-top                          : 6px;
$ts-header-search-mobile-sticky-left                         : 70px;
$ts-header-search-mobile-sticky-right                        : 130px;

$ts-header-search-font-size                                  : 17px;
$ts-header-search-input-background-color                     : rgba($white,0.15);
$ts-header-search-input-border-bottom-color                  : none;
$ts-header-search-input-focus-one-line-border                : false;
$ts-header-search-input-text-transform                       : none;
$ts-header-search-input-height                               : 56px;
$ts-header-search-input-color                                : $white;
$ts-header-search-input-focus-border                         : transparent;
$ts-header-search-input-left-indent                          : 24px;

// 02.3 Header Cart
// 02.3.1 Header Cart Button
$ts-header-cart-dropdown-showcart-type                       : image-only;
$ts-header-cart-dropdown-showcart-text-transform             : none;
$ts-header-cart-dropdown-showcart-text-color                 : $button-color;
$ts-header-cart-dropdown-showcart-text-weight				 : $button-weight;
$ts-header-cart-dropdown-showcart-text-size					 : map-get($button-sizes, default);
$ts-header-cart-dropdown-showcart-icon                       : 'icon-cart';
$ts-header-cart-dropdown-showcart-icon-active                : $ts-header-cart-dropdown-showcart-icon;
$ts-header-cart-dropdown-showcart-icon-hover                 : $ts-header-cart-dropdown-showcart-icon;
$ts-header-cart-dropdown-showcart-icon-width                 : rem-calc(44);
$ts-header-cart-dropdown-showcart-icon-height                : $ts-header-cart-dropdown-showcart-icon-width;

$ts-header-cart-dropdown-showcart-bg-color                   : transparent;
$ts-header-cart-dropdown-showcart-bg-color-hover             : transparent;

$ts-header-cart-button-count-size                            : rem-calc(18);
$ts-header-cart-button-count-font-size                       : rem-calc(12);
$ts-header-cart-button-count-font-weight                     : $bold-font-family;
$ts-header-cart-button-count-box-shadow                      : none;

$ts-header-cart-dropdown-showcart-icon-spacing               : 0;

$ts-header-cart-button-count-font-weight                     : normal;
$ts-header-cart-button-count-bg-color                        : $success-color;
$ts-header-cart-button-count-box-shadow                      : none;
$ts-header-cart-dropdown-showcart-icon-image-sticky-bottom   : auto;

// 02.4 Top Bar Navigation
// 02.4.1 Top Bar Nav Home link
$ts-headbar-main-menu-anchor-color                           : $ts-headbar-text-color;
$ts-primary-nav-height                                       : rem-calc(54);
$ts-headbar-main-menu-font-size                              : ( medium : rem-calc(19), xlarge : rem-calc(19), xxlarge : rem-calc(19) );
$ts-headbar-main-menu-font-weight                            : normal;
$ts-headbar-main-menu-text-transform                         : none;
$ts-headbar-main-menu-font-family                            : $bold-font-family;

// 02.4.2 Dropdown
$ts-header-menu-dropdown-padding                             : 0;
$ts-header-menu-dropdown-box-shadow                          : none;
$ts-header-menu-dropdown-link-font-size                      : 15px;
$ts-header-menu-dropdown-link-font-weight                    : normal;
$ts-header-menu-dropdown-link-padding                        : 12px 45px 12px 21px;
$ts-header-menu-dropdown-link-color                          : $primary-color;
$ts-header-menu-dropdown-link-color-hover                    : $primary-color;

// 02.6.2 Snowdog Menu Colors
$ts-snowdog-menu-background-color                            : $primary-color;
$ts-snowdog-menu-border-color                                : rgba($white,0.3);
$ts-snowdog-menu-dimmed-text-color                           : rgba($white,0.5);

// 02.6.7 Snowdog Menu Next-Prev Arrows
$ts-snowdog-menu-arrow-prev-color                            : rgba($white,0.5);

// 02.6.9 Snowdog Menu Close Button
$ts-snowdog-menu-close-icon                                  : "icon-close--white";

// 03. Offcanvas
$ts-foundation-offcanvas                                     : false;

// 03.3 Mobile toggle button
$ts-mobile-toggle-button-width                               : rem-calc(20);

// 03.1.2 Offcanvas Main Categories Menu
$ts-offcanvas-main-menu-item-link-color                      : $white;
$ts-offcanvas-main-menu-item-link-active-bg                  : transparent;
$ts-offcanvas-main-menu-item-link-active-color               : $white;

// 03.1.3 Offcanvas Submenu of Main Categories Menu
$ts-offcanvas-main-submenu-item-link-color                   : $white;
$ts-offcanvas-main-submenu-item-link-active-color            : transparent;

// 03.1.4 Offcanvas Secondary Menu
$ts-offcanvas-close-color                                    : $white;
$ts-offcanvas-close-font-size                                : 32px;
$ts-offcanvas-close-indent-left                              : 0;
$ts-offcanvas-close-indent-bottom                            : 0;


// 03.2 Mobile search
// 03.2.1 Mobile search bar
$ts-mobile-search-bar-box-shadow                             : none;
$ts-mobile-search-bar-bg-color                               : $ts-headbar-bg-color;

// 03.2.2 Mobile search bar field
$ts-mobile-search-field-bg                                   : rgba($white,0.15);
$ts-mobile-search-field-placeholder-color                    : rgba($white, .5);
$ts-mobile-search-field-border-style                         : none;
$ts-mobile-search-field-color                                : $white;

// 04.2 Magento Modal Extend (Custom, Popup, Slide)
$ts-modal-slide-action-close-padding                         : 0;

// 05. Category Page
// 05.0 Category Title
$ts-category-main-title-align                                : left;
$ts-category-main-title-border                               : none;

// 05.1 Category Sidebar
$ts-sidebar-main-filter-font-size                            : rem-calc(18);
$ts-sidebar-main-filter-anchor-padding                       : (.5rem 1.8rem .5rem 0);
$ts-sidebar-main-filter-border-color                         : rgba($black,0.15);
$ts-sidebar-main-filter-title-font-size                      : rem-calc(26);

// 05.2 Category Mobile Filter
$ts-sidebar-mobile-category-font-size                        : rem-calc(19);
$ts-sidebar-mobile-category-padding                          : rem-calc(10 50 10 8);
$ts-sidebar-mobile-category-image-size                       : 7px auto;
$ts-sidebar-mobile-category-color                            : $white;

$ts-mobile-sorting-left-indent                               : rem-calc(35);
$ts-mobile-filter-image                                      : "icon-check-white";

// 05.3 Category Sorting Toolbar
$ts-category-sorting-toolbar-height                          : rem-calc(52);
$ts-category-sorting-toolbar-font-size                       : rem-calc(15);
$ts-category-sorting-toolbar-select-spacing                  : 0.9rem;
$ts-category-sorting-toolbar-select-width                    : 280px;
$ts-category-sorting-toolbar-select-bg                       : $white;

// 05.4 Category Swatches
// IMAGE SWATCH
$ts-color-swatch-radius                                      : 100%;
$ts-color-swatch-border-color                                : $ts-main-border-color;
$ts-color-swatch-active-image-type                           : none;
$ts-color-swatch-outer-indent                                : 0 4px 3px 0;

// IMAGE+LABEL SWATCH
$ts-color-label-swatch-height                                : 18px;
$ts-color-label-swatch-width                                 : $ts-color-label-swatch-height;
$ts-color-label-swatch-indent                                : 0 0 0 4px !default;

// TEXT SWATCH
$ts-size-swatch-text-inner-indent                           : 0 4px;

// 05.6 Category Price slider
$ts-sidebar-main-price-slider-outer-margin                   : 10px 0 36px;
$ts-sidebar-main-price-slider-point-width                    : 17px;
$ts-sidebar-main-price-slider-point-height                   : 17px;
$ts-sidebar-main-price-slider-point-bg                       : $primary-color;
$ts-sidebar-main-price-slider-line-color                     : rgba($black,.15);
$ts-sidebar-main-price-slider-font-size                      : rem-calc(15);


// 05.7 Pagination
$ts-pagination-item-width                                    : ( small   : 40px,
                                                                 xlarge  : 45px,
                                                                 xxlarge : 55px );
$ts-pagination-item-height                                   : ( small   : 40px,
                                                                 xlarge  : 45px,
                                                                 xxlarge : 55px );
$ts-pagination-item-line-height                              : ( small   : 40px,
                                                                 xlarge  : 45px,
                                                                 xxlarge : 55px );
$ts-pagination-item-font-size                                : 20px;
$ts-pagination-item-border                                   : none;
$ts-pagination-item-bg                                       : $box-color;
$ts-pagination-item-color-active                             : $white;
$ts-pagination-item-bg-active                                : $primary-color;
$ts-pagination-item-box-shadow-active                        : none;

$ts-pagination-item-next-prev-button-font-size               : ( small   : 14px,
                                                                 xlarge  : 16px,
                                                                 xxlarge : 18px);
$ts-pagination-item-next-prev-button-letter-spacing          : 0;

$ts-pagination-item-next-prev-arrow-width                    : 6px;
$ts-pagination-item-next-prev-arrow-height                   : 12px;
$ts-pagination-item-next-prev-arrow-hz-indent                : 18px;
$ts-pagination-item-next-prev-button-arrow                   :'icon-arrow-next-white';
$ts-pagination-item-next-prev-button-arrow-prev              :'icon-arrow-prev-white';
$ts-pagination-item-next-prev-button-arrow-hover             :'icon-arrow-next-white';
$ts-pagination-item-next-prev-button-arrow-prev-hover        :'icon-arrow-prev-white';
$ts-pagination-item-next-prev-button-bg-color                : $primary-color;
$ts-pagination-item-next-prev-button-bg-color-hover          : mix($white, $ts-pagination-item-next-prev-button-bg-color, 15%);
$ts-pagination-item-next-prev-button-bg-color-active         : mix($white, $ts-pagination-item-next-prev-button-bg-color, 25%);

// 06. Product List Item
$ts-product-item-inner-indent                                : ( small : rem-calc(2) rem-calc(2) rem-calc(2),
                                                                large : rem-calc(2) rem-calc(2) rem-calc(2),
                                                                xlarge : rem-calc(2) rem-calc(2) rem-calc(2),
                                                                xxlarge : rem-calc(2) rem-calc(2) rem-calc(2));
$ts-product-item-nameprice-align                             : block;
$ts-product-item-photo-bottom-indent                         : rem-calc(20);

$ts-product-item-product-name-font-size                      : ( small : rem-calc(15), large : rem-calc(18), xlarge : rem-calc(20), xxlarge : rem-calc(22));
$ts-product-item-product-name-line-height                    : ( small : 1.23, large : 1.23, xlarge : 1.23, xxlarge : 1.23);
$ts-product-item-product-name-color                          : map-get($foundation-palette, primary);
$ts-product-item-product-name-text-transform                 : none;

$ts-product-item-product-price-font-size                     : ( small : rem-calc(15), xlarge : rem-calc(17), xxlarge : rem-calc(17));
$ts-product-item-product-price-font-weight                   : 400;
$ts-product-item-product-price-font-color                    : map-get($foundation-palette, primary);

$ts-product-item-product-price-old-font-size                 : rem-calc(12);

$ts-product-item-collapse-grid                               : true;

$ts-product-item-hide-btn                                    : false;

// 06.2 Product Price Tier
$ts-product-price-tier__default-styling                      : false;

// 07. Product Detail

// 07.2 Product Detail Top Panel
$ts-product-detail-title-font-size                           : ( small : rem-calc(32), large : rem-calc(42), xxlarge : rem-calc(50));
$ts-product-detail-title-font-weight                         : 400;
$ts-product-detail-title-font-family                         : $regular-font-family;
$ts-product-detail-title-text-align                          : left;
$ts-product-detail-title-text-transform                      : none;
$ts-product-detail-title-bottom-indent                       : 0;

$ts-product-detail-price-font-size                           : rem-calc(28);
$ts-product-detail-price-font-weight                         : 400;
$ts-product-detail-price-font-weight-mobile                  : 400;
$ts-product-detail-price-font-family                         : $bold-font-family;
$ts-product-detail-price-line-height                         : rem-calc(27);
$ts-product-detail-price-line-height-mobile                  : rem-calc(27);

// 07.3 Product Detail Info Right Sidebar
$ts-product-info-sidebar-indent                              : (small: 1.5rem 0, large: 0 2rem, xlarge: 0 2.1rem .5rem, xxlarge: 0 2.1rem .5rem);
$ts-product-info-sidebar-bottom-indent                       : (small: .5rem, large: .5rem, xlarge: .5rem, xxlarge: .5rem);

// 07.6 Product Detail AddtoCart Button
$ts-product-addtocart-btn-font-size                          : map-get($button-sizes, default);
$ts-product-addtocart-btn-font-weight						 : $button-weight;
$ts-product-addtocart-btn-font-family                        : $body-font-family;
$ts-product-addtocart-text-transform                         : none;
$ts-product-addtocart-btn-padding                            : (1.1rem 1rem);

// 08. Customer Section
$ts-underlined-title-font-size                               : rem-calc(28);
$ts-customer-sidebar-font-weight-active                      : 700;
$ts-customer-sidebar-item-active-color                       : $success-color;

$ts-product-addtocart-qty__show                              : true;

// 11. Checkout Cart
$ts-checkout-sidebar-bg                                      : #f8f7f6;
$ts-checkout-sidebar-shadow                                  : none;
$ts-delete-icon-width                                        : 24px;
$ts-delete-icon-height                                       : 26px;

$ts-cart-table-subtotal__show                                : true;
$ts-cart-table-product-title-font-size                       : ( small    : rem-calc(17),
                                                                large   : rem-calc(20));
$ts-cart-table-product-title-font-family                     : $medium-font-family;
$ts-cart-table-product-title-font-weight                     : 400;
$ts-cart-table-product-title-text-transform                  : none;
$ts-cart-table-product-price-font-size                       : rem-calc(15);
$ts-cart-table-product-price-font-weight                     : 400;
$ts-cart-table-cell-indent                                   : rem-calc(13);

$ts-checkout-main-button-has-arrow                           : false;
$ts-checkout-main-button-font-size                           : rem-calc(19);
$ts-checkout-main-button-top-indent                          : rem-calc(19);
$ts-checkout-main-button-bottom-indent                       : rem-calc(20);

// 11.1 Checkout
$ts-checkout-headbar-height                                  : ( small : rem-calc(72), normal : rem-calc(131), tall : rem-calc(131) );
$ts-checkout-headbar-total-color                             : $body-background;
$ts-checkout-headbar-link-color                              : $body-background;
$ts-checkout-headbar-info-color                              : $body-background;

$ts-checkout-headbar-contact-tel-color                       : $body-background;
$ts-checkout-headbar-contact-tel-icon                        : 'icon-phone-white';

$ts-checkout-headbar-goback-button-icon                      : "icon-arrow-next-white";

$ts-checkout-shipping-list-spacing-in                        : rem-calc(20);
$ts-checkout-box-bg-color                                    : $ts-checkout-sidebar-bg;
$ts-checkout-box-shadow                                      : none;
$ts-checkout-box-spacing                                     : ( small : 1.5rem, xlarge : 1.5rem );
$ts-checkout-address-country__show                           : true !default;
