$mm_module: '.mm-navbar';

#{$mm_module} {
    @if ($ts-snowdog-menu-ie11-fallbacks) {
        > * {
            min-height: $ts-snowdog-menu-navbar-size;
        }
    }

    display: flex;
    min-height: $ts-snowdog-menu-navbar-size;
    border-bottom: 1px solid $ts-snowdog-menu-border-color;
    background: $ts-snowdog-menu-background-color;
    color: $ts-snowdog-menu-dimmed-text-color;
    text-align: center;

    opacity: 1;
    transition: opacity $ts-snowdog-menu-transition-duration $ts-snowdog-menu-transition-function;

    @supports (position: sticky) {
        &_sticky {
            position: sticky;
            top: 0;
            z-index: 1;
        }
    }

    > * {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }
}

#{$mm_module}__btn {
    flex-grow: 0;
}

#{$mm_module}__title {

    flex: 1 1 50%;
    display: flex;
    padding-left: $ts-snowdog-menu-panel-padding;
    padding-right: $ts-snowdog-menu-panel-padding;
    overflow: hidden;
    font-size: $ts-snowdog-menu-panel-title-size;
    font-weight: $ts-snowdog-menu-panel-title-weight;
    font-style: $ts-snowdog-menu-panel-title-style;
    font-family: $ts-snowdog-menu-panel-title-font-family;
    color: $ts-snowdog-menu-panel-title-color;
    line-height: $ts-snowdog-menu-panel-title-line-height;

    // Center the content.
    &:not(:last-child) {
        padding-right: 0;
    }

    > span {
        @include mm_ellipsis;
        display: block;
        position: relative;
        top: $ts-snowdog-menu-panel-title-top-shift;
    }

    #{$mm_module}__btn:not(.mm-hidden) + & {
        padding-left: 0;

        &:last-child {
            padding-right: $ts-snowdog-menu-prev-width;
        }
    }
    // /Center
}
