@mixin convert-image {
	.convert-image {
		&.image--full-width img {
			display: block;
			width: 100%;
		}

		&.aligned {
			display: table;

			&--center {
				margin-left: auto;
				margin-right: auto;
			}

			&--right {
				margin-left: auto;
				margin-right: 0;
			}
		}

		&-metadata {
			position: relative;

			img {
				width: 100%;
			}
		}

		&-marker {
			position: absolute;
			width: $ts-pimcore-image-marker__width;
			height: $ts-pimcore-image-marker__height;
			@if $ts-pimcore-image-marker__icon {
			background-image: $ts-pimcore-image-marker__icon;
			background-repeat: no-repeat;
			background-size: $ts-pimcore-image-marker__icon-size;
			}
			@if $ts-pimcore-image-marker__border-radius {
			border-radius: $ts-pimcore-image-marker__border-radius;
			}
			@if $ts-pimcore-image-marker__box-shadow {
			box-shadow: $ts-pimcore-image-marker__box-shadow;
			}
		}

		&-hotspot {
			position: absolute;

			a:hover {
				background: $ts-pimcore-image-hotspot__background-color--hover;
			}
		}

		&-marker,
		&-hotspot {
			a {
				width: 100%;
				height: 100%;
				border-bottom: none;
				cursor: pointer;
			}
		}
	}
} // convert-image