// Pimcore Video v1.0
@mixin convert-background {
	.convert-grid-row.has-bg,
	.convert-grid-row-flex.has-bg {
		overflow: hidden;
		position: relative;

		.convert-column,
		.convert-column-flex {
			position: relative;
		}
	}
} // @mixin convert-background

@mixin convert-video {
	.convert-video {
		position: relative;

		.convert-video-poster {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-size: cover;
			background-position: center;
		}

		.vjs-playing ~ .convert-video-poster,
		.vjs-playing ~ .convert-video-content {
			visibility: hidden;
		}

		.convert-video-content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			color: $ts-pimcore-video-content-color;
			text-align: $ts-pimcore-video-content-text-align;

			@include breakpoint (medium down) {
				width: 80%;
			}
		}

		.convert-video-button {
			display: block;
			width: $ts-pimcore-video-button-width;
			height: $ts-pimcore-video-button-height;
			margin: $ts-pimcore-video-button-icon-margin;
			cursor: pointer;

			@include breakpoint (medium down) {
				width: $ts-pimcore-video-button-width * 0.75;
				height: $ts-pimcore-video-button-height * 0.75;
				margin-bottom: nth($ts-pimcore-video-button-icon-margin,3) * 0.6;
			}

			@if ($ts-pimcore-video-button-icon-bcolor != none) {
				background-color: $ts-pimcore-video-button-icon-bcolor;
			}
			@if ($ts-pimcore-video-button-icon-type != none) {
				background-image: url("#{$ts-path-to-images}/#{$ts-pimcore-video-button-icon}.#{$ts-pimcore-video-button-icon-type}");
				background-repeat: no-repeat;
				background-position: 0 0;
				background-size: $ts-pimcore-video-button-icon-size;

				@include breakpoint (medium down) {
					background-size: $ts-pimcore-video-button-width * 0.75 auto;
				}
			}
			@if ($ts-pimcore-video-button-icon-radius != 0) {
				border-radius: $ts-pimcore-video-button-icon-radius;
			}
			@if ($ts-pimcore-video-button-icon-shadow != none) {
				box-shadow: $ts-pimcore-video-button-icon-shadow;
			}

			&:hover {
				opacity: 0.75;
				@if ($ts-pimcore-video-button-icon-bcolor--hover != none) {
					background-color: $ts-pimcore-video-button-icon-bcolor--hover;
				}
				@if ($ts-pimcore-video-button-icon-shadow--hover != $ts-pimcore-video-button-icon-shadow) {
					box-shadow: $ts-pimcore-video-button-icon-shadow--hover;
				}
			}
		}

		.convert-video-button-label {
			color: $ts-pimcore-video-button-label-color;
			font-size: $ts-pimcore-video-button-label-font-size;
			text-align: $ts-pimcore-video-button-label-text-align;
			@if (text-transform: $ts-pimcore-video-button-label-text-transform != none) {
				text-transform: $ts-pimcore-video-button-label-text-transform;
			}

			&:hover {
				color: $ts-pimcore-video-button-label-color--hover;
				cursor: pointer;
			}
		}

		.convert-wysiwyg {
			margin-top: 10px;
		}
	}
} // @mixin convert-video

@mixin convert-video--background {
	.convert-videobg {
		position: absolute;

		.convert-video-overlay {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		}

		&.bg-overlay-light {
			.convert-video-overlay {
				background-color: rgba(white,0.5);
			}
		}

		&.bg-overlay-dark {
			.convert-video-overlay {
				background-color: rgba(black,0.5);
			}
		}
	}

	.convert-video-asset {
		left: 0;
		right: 0;

		@supports (object-fit:cover) {
			.convert-video-ctr {
				padding-bottom: 0 !important;
			}
		}

		&.bg-y-top {
			top: 0;
		}

		&.bg-y-bottom {
			bottom: 0;
		}

		&.bg-y-center {
			top: 50%;
			transform: translateY(-50%);
		}

		video {
			display: block;
			position: absolute;

			@supports (object-fit:cover) {
				position: static;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.convert-video-vimeo,
	.convert-video-youtube {
		left: 0;
		right: 0;

		&.bg-y-top {
			top: 0;
		}

		&.bg-y-bottom {
			bottom: 0;
		}

		&.bg-y-center {
			top: 50%;
			transform: translateY(-50%);
		}

		iframe {
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
		}
	}
} // @mixin convert-video--background

@mixin convert-image--background {
	.has-img-bg .convert-img-bg {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-repeat: no-repeat;

		&.bg-x-left {
			background-position-x: 0;
		}

		&.bg-x-right {
			background-position-x: 100%;
		}

		&.bg-y-top {
			background-position-y: 0;
		}

		&.bg-y-bottom {
			background-position-y: 100%;
		}

		&.bg-size-cover {
			background-size: cover;
		}

		.convert-image-overlay {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		}

		&.bg-overlay-light {
			.convert-image-overlay {
				background-color: rgba(white,0.5);
			}
		}

		&.bg-overlay-dark {
			.convert-image-overlay {
				background-color: rgba(black,0.5);
			}
		}
	}
} // @mixin convert-image--background