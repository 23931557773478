.product-items,
.ais-infinite-hits {
	.product-item,
    .ais-hits--item,
    .ais-infinite-hits--item {
        .product.product-item-actions {
            display: inherit;
        }
	}// .product-item
}// .product-items

@media screen and (max-width: 63.9375em) {
    .product-items {
        .ais-hits--item {
            .product-item-details .product-item-inner {
                position: inherit !important;
                width: inherit;
                height: inherit;
                overflow: inherit;
                clip: inherit;
            }
        }

        .product-item .product-item-details {
            .product-item-actions,
            .product-item-inner {
                position: inherit !important;
                width: inherit;
                height: inherit;
                overflow: inherit;
                clip: inherit;
            }
        }
    }

    .no-touchevents .product-items {
        .ais-hits--item,
        .product-item {
            .product-item-actions button.action {
                opacity: 1;
                z-index: 1;
            }
        }
    }
}
