@import '../../mixins';

.mm-hidden {
    display: none !important;
}

.mm-wrapper {
    overflow-x: hidden;
    position: relative;
}

@import 'oncanvas.menu', 'oncanvas.menu.rtl', 'oncanvas.panels',
    'oncanvas.panels.rtl', 'oncanvas.vertical', 'oncanvas.buttons',
    'oncanvas.buttons.rtl', 'oncanvas.navbar', 'oncanvas.navbar.rtl',
    'oncanvas.listviews', 'oncanvas.listviews.rtl';
