@media print {
  a[href]:after {
    content: none !important;
  }
}

@mixin at {
	@include element-invisible;
}

.at {
	@include at;
}

.relative {
	position: relative;
}

.ib {
	display: inline-block;
	vertical-align: middle;
}

.no-display {
	display: none;
}

.loader-holder {
	position:absolute;
	width:100%;
	height:40px;
}

@mixin header-font-family($font) {
	@if $font == $header-font-family {
		font-family: $font;
		@if $header-letter-spacing != 0 {
			letter-spacing: $header-letter-spacing;
		}
	} @else {
		font-family: $font;
		letter-spacing: normal;
	}
}

// remove Outline of Element
.no-outline {
	outline: none;
	&:hover,
	&:active,
	&:focus {
		outline: none;
	}
}

// clean Magento Label with .label
@mixin no-label {
	padding: 0;
	vertical-align: middle;
	color: $body-font-color;
	font-size: inherit;
	line-height:inherit;
	white-space: normal;
	background: none;
}

// Add Colon where you need
@mixin label-colon {
	&:after {
		content: ':';
	}
}

// clean Foundation Fieldset
@mixin no-fieldset {
	padding: 0;
	border:0;
	margin: 0;

	.legend {
		width: 100%;
		margin: 0;
		padding: 0;
		background: none;
	}
}

// loading overlay-mask
@mixin loading-mask {
	bottom: 0;
	left: 0;
	margin: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 100;
}

// add border-line wherever you need
@mixin border-line($position: 'bottom', $coord: 0, $border-color: $ts-main-border-color) {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		@include get-gutter(.5,'left');
		@include get-gutter(.5,'right');
		visibility: visible;
		opacity: 1;
		display: block;
		border:0;
		border-#{$position}: $ts-main-border-width $ts-main-border-style $border-color;
		@if $position == 'top' {
			bottom: auto;
			top: $coord;

			@include breakpoint(medium) {
				top: $coord;
			}
		}
		@else if $position == 'left' {
			top: 0;
			left: $coord;
			right: auto;

			@include breakpoint(medium) {
				left: $coord;
			}
		}
		@else if $position == 'right' {
			top: 0;
			right: $coord;
			left: auto;

			@include breakpoint(medium) {
				right: $coord;
			}
		}
	}
}

//
//  Title with bottom line
//  _____________________________________________
@mixin underlined-title {
	display: block;
	font-size: $ts-underlined-title-font-size;
	font-weight: $ts-underlined-title-font-weight;
	line-height: $ts-underlined-title-line-height;
	@include header-font-family($ts-underlined-title-font-family);
	@include get-gutter(.5,'padding-bottom');
	@include get-gutter(.5,'margin-bottom');
	@include border-line(bottom, 0, $ts-main-dark-border-color);

	strong {
		font-weight: inherit;
	}

	.action {
		display: inline-block;
		vertical-align: baseline;
		font-size: ($ts-underlined-title-font-size * .6);
		margin-left: .5rem;
		font-weight: 400;

		&:hover {
			text-decoration: underline;
		}
	}

	&:before {
		left: 0;
		right: 0;
	}
}


//
//  Title with centered text
//  _____________________________________________
.extra-title {
	font-size: $ts-pro-block-title-font-size;
	@include header-font-family($ts-pro-block-title-font-family);
	font-weight: $ts-pro-block-title-font-weight;
	font-style: $ts-pro-block-title-font-style;
	margin: $ts-pro-block-title-margin;
	text-align: $ts-pro-block-title-text-align;
	text-transform: $ts-pro-block-title-text-transform;
	color: $ts-pro-block-title-font-color;

	strong,
	b {
		font-weight: $ts-pro-block-title-font-weight;
	}

	@if $ts-pro-block-title-side-lines-show {
		overflow: hidden;

		&:before,
		&:after {
			content: "";
			background: $ts-pro-block-title-side-lines-color;
			height: $ts-pro-block-title-side-lines-width;
			display: inline-block;
			position: relative;
			vertical-align: middle;
			width: 50%;
			margin-bottom: $ts-pro-block-title-side-lines-margin-bottom;
		}

		&:before {
			right: $ts-pro-block-title-side-lines-gap;
			margin-left: -50%;
		}

		&:after {
			left: $ts-pro-block-title-side-lines-gap;
			margin-right: -50%;
		}
	} // @if
}

//
//  Custom Checkbox
//  _____________________________________________
@mixin checkbox($top: -1px) {
	line-height: 1.2;
	margin-bottom: .75rem;
	position: relative;

	input[type="checkbox"] {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;

		+ label,
		+ .label {
			cursor: pointer;
			font-weight: normal;
			position: relative;
			margin: 0;
			line-height: 1.2;
			min-height: 1rem;
			padding-left: 1.4rem;

			&:before {
				display: block;
				content: '';
				height: 1rem;
				width: 1rem;
				line-height: 1rem;
				text-align: center;
				position: absolute;
				top: $top;
				left: 0;
				border:$input-border;
				color: $body-font-color;
				background: $white;
			}
		}
		&:checked + label:before,
		&:checked + .label:before {
			content: '✓';
		}
		&:disabled + label {
			opacity: .4;
		}
		&:disabled + label:before,
		&:disabled + .label:before {
			background: $light-gray;
		}
	}
}

.checkbox {
	@include checkbox;
}

//
//  Custom Radio
//  _______________________________
@mixin radio($top: -1px) {
	line-height: 1.2;
	margin-bottom: .75rem;
	position: relative;

	input[type="radio"] {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;

		+ label,
		+ .label {
			cursor: pointer;
			font-weight: normal;
			position: relative;
			margin: 0;
			line-height: 1.2;
			min-height: rem-calc(16);
			padding-left: 1.4rem;

			&:before {
				display: block;
				content: '';
				height: rem-calc(16);
				width: rem-calc(16);
				line-height: rem-calc(16);
				text-align: center;
				position: absolute;
				top: $top;
				left: 0;
				border-radius: 50%;
				border:$input-border;
				color: $body-font-color;
				background: $white;
				z-index: 0;
			}
			&:after {
				display: none;
				content: '';
				position: absolute;
				top: rem-calc(2);
				left: rem-calc(3);
				height: rem-calc(10);
				width: rem-calc(10);
				border-radius: 50%;
				background: $primary-color;
				// z-index: 1;
			}
		}
		&:checked + label:after,
		&:checked + .label:after {
			display: block;
		}
		&:disabled + label:before,
		&:disabled + .label:before {
			opacity: .3;
			background: $light-gray;
		}
	}
}

.radio {
	@include radio;
}


// Arrow helper mixin
@mixin _position($position, $args) {
  @each $o in top right bottom left {
    $i: index($args, $o);
    @if $i
    and $i + 1 <= length($args)
    and type-of( nth($args, $i + 1) ) == number {
      #{$o}: nth($args, $i + 1);
    }
  }
  position: $position;
}
@mixin absolute($args) {
  @include _position(absolute, $args);
}
@mixin triangle($direction, $position, $color: currentColor, $size: 1em, $center-h: false, $center-v: false) {
  @if not index(top right bottom left, $direction) {
    @warn "Direction must be one of top, right, bottom or left.";
  }

  @else {
    @include absolute($position); // Position
    width: 0;
    height: 0;
    content: '';
    z-index: 21;

    border-#{$direction}: ($size*1.5) solid $color;
    $perpendicular-borders: $size solid transparent;

    @if $direction == top or $direction == bottom {
      border-left:   $perpendicular-borders;
      border-right:  $perpendicular-borders;
    }

    @else if $direction == right or $direction == left {
      border-bottom: $perpendicular-borders;
      border-top:    $perpendicular-borders;
    }

    @if $center-h {
    	transform: translateX(-50%);
    }
    @if $center-v {
    	transform: translateY(-50%);
    }
  }
}


// add value which depends on $grid-column-gutter
@mixin get-gutter($multiplier:1,$prop:'padding') {
	$gutter: $grid-column-gutter;
	@if type-of($gutter) == 'map' {
		@each $breakpoint, $value in $gutter {
			$value: rem-calc($value) * $multiplier;
			@include breakpoint($breakpoint) {
				#{$prop}: $value;
			}
		}
	}
	@else if type-of($gutter) == 'number' and strip-unit($gutter) > 0 {
		$value: rem-calc($value) * $multiplier;
		#{$prop}: $value;
	}
}

// add box-shadow value which depends on $grid-column-gutter
@mixin get-gutter-shadow($multiplier:1,$prop:'box-shadow', $color: '#fff', $custom-shadow: 'none') {
	$gutter: $grid-column-gutter;
	@if type-of($gutter) == 'map' {
		@each $breakpoint, $value in $gutter {
			$value: rem-calc($value) * $multiplier;
			@include breakpoint($breakpoint) {
				@if ($custom-shadow != 'none') {
					#{$prop}: 0 0 0 $value $color, #{$custom-shadow};
				} @else {
					#{$prop}: 0 0 0 $value $color;
				}
			}
		}
	}
	@else if type-of($gutter) == 'number' and strip-unit($gutter) > 0 {
		$value: rem-calc($value) * $multiplier;
		@if ($custom-shadow != 'none') {
			#{$prop}: 0 0 0 $value $color, #{$custom-shadow};
		} @else {
			#{$prop}: 0 0 0 $value $color;
		}
	}
}
/// Convert angle
/// @author Chris Eppstein
/// @param {Number} $value - Value to convert
/// @param {String} $unit - Unit to convert to
/// @return {Number} Converted angle
@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value
             / nth($conversion-factors, index($convertable-units, unit($value)))
             * nth($conversion-factors, index($convertable-units, $unit));
  }

  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }

  $conversion-map: (
    to top          : bottom,
    to top right    : bottom left,
    to right top    : left bottom,
    to right        : left,
    to bottom right : top left,
    to right bottom : left top,
    to bottom       : top,
    to bottom left  : top right,
    to left bottom  : right top,
    to left         : right,
    to left top     : right bottom,
    to top left     : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - convert-angle($value, 'deg');
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }

  // background-color: nth(nth($color-stops, 1), 1);
  background-image: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background-image: linear-gradient($direction, $color-stops);
}

// Background Gradient: Radial
@mixin bg-radial($start-colour, $start-position, $start2-colour, $start2-position, $end-colour, $end-position, $end2-colour, $end2-position) {
	background: -moz-radial-gradient(center, ellipse cover, $start-colour $start-position, $start2-colour $start2-position, $end-colour $end-position, $end2-colour $end2-position);
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, Colour-stop($start-position,$start-colour), Colour-stop($end-position,$end-colour));
	background: -webkit-radial-gradient(center, ellipse cover, $start-colour $start-position, $start2-colour $start2-position, $end-colour $end-position, $end2-colour $end2-position);
	background: -o-radial-gradient(center, ellipse cover, $start-colour $start-position, $start2-colour $start2-position, $end-colour $end-position, $end2-colour $end2-position);
	background: -ms-radial-gradient(center, ellipse cover, $start-colour $start-position, $start2-colour $start2-position, $end-colour $end-position, $end2-colour $end2-position);
	background: radial-gradient(ellipse at center, $start-colour $start-position, $start2-colour $start2-position, $end-colour $end-position, $end2-colour $end2-position);
}

@mixin hide-text() {
	overflow: hidden;
	text-indent: 200%;
	white-space: nowrap;
}

@mixin lsn {
	margin: 0;
	list-style: none;

	li {
		padding: 0;
		&:after {
			display: none;
		}
	}
}

//
//  Product Image Overlay
//  ---------------------------------------------
%product-layer-overlay {
	&:before {
		display: block;
		content: '';
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: $ts-product-photo-overlay-opacity;
		background: $ts-product-photo-overlay-color;
	}
}


// Placeholder
@mixin placeholder($color) {
	&::-webkit-input-placeholder {
		color: $color;
	}

	&:-moz-placeholder {
		color: $color;
	}

	&::-moz-placeholder {
		color: $color;
	}

	&:-ms-input-placeholder {
		color: $color;
	}
}

@mixin loading-overlay($pos: absolute, $zindex: 555) {
	display:none;
	height: 100%;
	width: 100%;
	position: $pos;
	left: 0;
	top: 0;
	background-color: $ts-loading-background-color;
	background-image: $ts-loader-icon-background-image;
	background-position: 50%;
	background-repeat: no-repeat;
	z-index: $zindex;

	@if $ts-loading-fallback {
		.ie11 &,
		.no-smil & {
			background-image: $ts-loader-icon-background-image-fallback;
		}
	}

	.loading-mssg {
		display: block;
		position: absolute;
		top: 10rem;
		left: 50%;
		transform: translateX(-50%);
		color: $ts-loader-text-color;
		@include header-font-family($ts-loader-text-font-family);
		font-weight: $ts-loader-text-font-weight;
		font-size: $ts-loader-text-font-size;
		font-style: $ts-loader-text-font-style;

		.loading-img {
			margin-right: .5rem;
		}

		@include breakpoint(medium) {
			font-size: ($ts-loader-text-font-size*1.5);
		}

		@include breakpoint(xlarge) {
			font-size: ($ts-loader-text-font-size*1.8);
			top: 15rem;
		}
	}
}// .loading-overlay

.loading-overlay {
	@include loading-overlay;
	background-image: none;

	@if $ts-loading-fallback {
		.ie11 &,
		.no-smil & {
			background-image: none;

			.loading-mssg {
				.loading-img {
					display: none;
				}
				&:before {
					display: block;
					content: '';
					margin: 0 auto;
					width: $ts-loader-icon-width;
					height: $ts-loader-icon-height;
					background-image: $ts-loader-icon-background-image-fallback;
					background-position: 50%;
					background-repeat: no-repeat;
					background-size: 100% auto;
				}
			}
		}
	}
}

// keyframes mixin
@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}
	@-moz-keyframes #{$name} {
		@content;
	}
	@-ms-keyframes #{$name} {
		@content;
	}
	@keyframes #{$name} {
		@content;
	}
}

@-webkit-keyframes spin-loading-more {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin-loading-more {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.admin__data-grid-loading-mask {
	@include loading-overlay;
	.spinner {
		display: none;
	}
}

// Top Header Line
@mixin top-header-line($height) {
	&:before {
		display: block;
		visibility: visible;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: $height;
		background: $ts-headbar-top-border-bg;
	}
}

@mixin remove-number-appearance {
	-moz-appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

//
//  Foundation Visible Classed 5 to 6
//  _____________________________________________
// Responsive visibility classes
@mixin foundation-visibility-classes-extend {
	// Basic hiding classes
	.hide {
	  display: none !important;
	}

	.invisible {
	  visibility: hidden;
	}

	@each $size in $breakpoint-classes {
	  @if $size != $-zf-zero-breakpoint {
	  	.hide-for-#{$size},
	    .hide-for-#{$size}-up {
	      @include hide-for($size);
	    }

	    .show-for-#{$size},
	    .show-for-#{$size}-up {
	      @include show-for($size);
	    }
	  }

	  .hide-for-#{$size}-only {
	    @include hide-for-only($size);
	  }

	  .show-for-#{$size}-only {
	    @include show-for-only($size);
	  }
	}
}
@include foundation-visibility-classes-extend;

@mixin mq-specific-property ($property, $values, $factor: 1) {
    @if type-of($values) == map {
        @each $query, $value in $values {
            @include breakpoint($query) {
                @if type-of($value) == map or type-of($value) == list {
                    #{$property}: $value;
                } @else {
                    #{$property}: $factor * $value;
                }
            }
        } // @each
    } @else {
        #{$property}: $values;
    }
}
