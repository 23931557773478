@if ($ts-snowdog-menu-include-rtl) {
    [dir='rtl'] .mm-panel {
        &:not(.mm-panel_opened) {
            transform: translate3d(-100%, 0, 0);
        }

        &.mm-panel_opened-parent {
            transform: translate3d(30%, 0, 0);
        }
    }
}
