@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;700&family=Roboto:wght@400;700&display=swap');

@import "fonts";

@import "../../../vendor/convert/theme-frontend-two/styles/functions/colors";

@import "../../../vendor/convert/theme-frontend-two/styles/settings.scss";
@import "settings";

@import "../../../vendor/convert/theme-frontend-two/styles/app.scss";

@import "theme-settings";
@import "../../../vendor/convert/theme-frontend-two/styles/theme-settings.scss";

@import "../../../vendor/convert/theme-frontend-two/styles/imports.scss";
@import "imports";

@include foundation-accordion;

// @import "module-settings";

// Included modules with separated styles
@import "../Convert_Cookies/styles/module";
@import "../Convert_Links/styles/module";
@import "../Convert_Social/styles/module";
@import "../Convert_ShippingBar/styles/module";
@import "../Convert_Usp/styles/module";
@import "../Convert_Labels/styles/module";
@import "../Convert_SnowdogMenu/styles/module";
@import "../Convert_CustomOptionFile/styles/module";

@import "../Bss_AjaxCart/styles/module";
@import "../Strategery_Infinitescroll/styles/module";

@import "../Convert_SimilarProducts/styles/module";

@import "../Convert_PimcoreCms/styles/module";


@import "components/global/button";
@import "components/header/header-mobile";
@import "components/header/header-off-canvas";
@import "components/product/_product-list";
@import "algolia";
@import "ide-main";

// @import "components/product/simple";
// @import "components/product/configurable";
