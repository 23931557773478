@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;700&family=Roboto:wght@400;700&display=swap");
@font-face {
  font-family: 'silka-regular-webfont';
  src: url("../fonts/silka-regular-webfont.eot");
  src: url("../fonts/silka-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/silka-regular-webfont.woff2") format("woff2"), url("../fonts/silka-regular-webfont.woff") format("woff"), url("../fonts/silka-regular-webfont.ttf") format("truetype"), url("../fonts/silka-regular-webfont.svg#silka-regular-webfont") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'silka-regularitalic-webfont';
  src: url("../fonts/silka-regularitalic-webfont.eot");
  src: url("../fonts/silka-regularitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/silka-regularitalic-webfont.woff2") format("woff2"), url("../fonts/silka-regularitalic-webfont.woff") format("woff"), url("../fonts/silka-regularitalic-webfont.ttf") format("truetype"), url("../fonts/silka-regularitalic-webfont.svg#silka-regularitalic-webfont") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'silka-medium-webfont';
  src: url("../fonts/silka-medium-webfont.eot");
  src: url("../fonts/silka-medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/silka-medium-webfont.woff2") format("woff2"), url("../fonts/silka-medium-webfont.woff") format("woff"), url("../fonts/silka-medium-webfont.ttf") format("truetype"), url("../fonts/silka-medium-webfont.svg#silka-medium-webfont") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'silka-semibold-webfont';
  src: url("../fonts/silka-semibold-webfont.eot");
  src: url("../fonts/silka-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/silka-semibold-webfont.woff2") format("woff2"), url("../fonts/silka-semibold-webfont.woff") format("woff"), url("../fonts/silka-semibold-webfont.ttf") format("truetype"), url("../fonts/silka-semibold-webfont.svg#silka-semibold-webfont") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' '; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -1.0625rem;
    margin-left: -1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .row .row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .row .row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .row .row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .row .row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 1.0625rem;
  padding-left: 1.0625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 48em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 64em) {
  .xlarge-1 {
    width: 8.33333%; }
  .xlarge-push-1 {
    position: relative;
    left: 8.33333%; }
  .xlarge-pull-1 {
    position: relative;
    left: -8.33333%; }
  .xlarge-offset-0 {
    margin-left: 0%; }
  .xlarge-2 {
    width: 16.66667%; }
  .xlarge-push-2 {
    position: relative;
    left: 16.66667%; }
  .xlarge-pull-2 {
    position: relative;
    left: -16.66667%; }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .xlarge-3 {
    width: 25%; }
  .xlarge-push-3 {
    position: relative;
    left: 25%; }
  .xlarge-pull-3 {
    position: relative;
    left: -25%; }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .xlarge-4 {
    width: 33.33333%; }
  .xlarge-push-4 {
    position: relative;
    left: 33.33333%; }
  .xlarge-pull-4 {
    position: relative;
    left: -33.33333%; }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .xlarge-5 {
    width: 41.66667%; }
  .xlarge-push-5 {
    position: relative;
    left: 41.66667%; }
  .xlarge-pull-5 {
    position: relative;
    left: -41.66667%; }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .xlarge-6 {
    width: 50%; }
  .xlarge-push-6 {
    position: relative;
    left: 50%; }
  .xlarge-pull-6 {
    position: relative;
    left: -50%; }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .xlarge-7 {
    width: 58.33333%; }
  .xlarge-push-7 {
    position: relative;
    left: 58.33333%; }
  .xlarge-pull-7 {
    position: relative;
    left: -58.33333%; }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .xlarge-8 {
    width: 66.66667%; }
  .xlarge-push-8 {
    position: relative;
    left: 66.66667%; }
  .xlarge-pull-8 {
    position: relative;
    left: -66.66667%; }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .xlarge-9 {
    width: 75%; }
  .xlarge-push-9 {
    position: relative;
    left: 75%; }
  .xlarge-pull-9 {
    position: relative;
    left: -75%; }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .xlarge-10 {
    width: 83.33333%; }
  .xlarge-push-10 {
    position: relative;
    left: 83.33333%; }
  .xlarge-pull-10 {
    position: relative;
    left: -83.33333%; }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .xlarge-11 {
    width: 91.66667%; }
  .xlarge-push-11 {
    position: relative;
    left: 91.66667%; }
  .xlarge-pull-11 {
    position: relative;
    left: -91.66667%; }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .xlarge-12 {
    width: 100%; }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xlarge-up-1 > .column:nth-of-type(1n), .xlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-1 > .column:nth-of-type(1n+1), .xlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xlarge-up-1 > .column:last-child, .xlarge-up-1 > .columns:last-child {
      float: left; }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xlarge-up-2 > .column:nth-of-type(1n), .xlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-2 > .column:nth-of-type(2n+1), .xlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xlarge-up-2 > .column:last-child, .xlarge-up-2 > .columns:last-child {
      float: left; }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xlarge-up-3 > .column:nth-of-type(1n), .xlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-3 > .column:nth-of-type(3n+1), .xlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xlarge-up-3 > .column:last-child, .xlarge-up-3 > .columns:last-child {
      float: left; }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xlarge-up-4 > .column:nth-of-type(1n), .xlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-4 > .column:nth-of-type(4n+1), .xlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xlarge-up-4 > .column:last-child, .xlarge-up-4 > .columns:last-child {
      float: left; }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xlarge-up-5 > .column:nth-of-type(1n), .xlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-5 > .column:nth-of-type(5n+1), .xlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xlarge-up-5 > .column:last-child, .xlarge-up-5 > .columns:last-child {
      float: left; }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xlarge-up-6 > .column:nth-of-type(1n), .xlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-6 > .column:nth-of-type(6n+1), .xlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xlarge-up-6 > .column:last-child, .xlarge-up-6 > .columns:last-child {
      float: left; }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xlarge-up-7 > .column:nth-of-type(1n), .xlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-7 > .column:nth-of-type(7n+1), .xlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xlarge-up-7 > .column:last-child, .xlarge-up-7 > .columns:last-child {
      float: left; }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xlarge-up-8 > .column:nth-of-type(1n), .xlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-8 > .column:nth-of-type(8n+1), .xlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xlarge-up-8 > .column:last-child, .xlarge-up-8 > .columns:last-child {
      float: left; }
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem; }
  .xlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xlarge-centered, .xlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xlarge-uncentered,
  .xlarge-push-0,
  .xlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 75em) {
  .xxlarge-1 {
    width: 8.33333%; }
  .xxlarge-push-1 {
    position: relative;
    left: 8.33333%; }
  .xxlarge-pull-1 {
    position: relative;
    left: -8.33333%; }
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .xxlarge-2 {
    width: 16.66667%; }
  .xxlarge-push-2 {
    position: relative;
    left: 16.66667%; }
  .xxlarge-pull-2 {
    position: relative;
    left: -16.66667%; }
  .xxlarge-offset-1 {
    margin-left: 8.33333%; }
  .xxlarge-3 {
    width: 25%; }
  .xxlarge-push-3 {
    position: relative;
    left: 25%; }
  .xxlarge-pull-3 {
    position: relative;
    left: -25%; }
  .xxlarge-offset-2 {
    margin-left: 16.66667%; }
  .xxlarge-4 {
    width: 33.33333%; }
  .xxlarge-push-4 {
    position: relative;
    left: 33.33333%; }
  .xxlarge-pull-4 {
    position: relative;
    left: -33.33333%; }
  .xxlarge-offset-3 {
    margin-left: 25%; }
  .xxlarge-5 {
    width: 41.66667%; }
  .xxlarge-push-5 {
    position: relative;
    left: 41.66667%; }
  .xxlarge-pull-5 {
    position: relative;
    left: -41.66667%; }
  .xxlarge-offset-4 {
    margin-left: 33.33333%; }
  .xxlarge-6 {
    width: 50%; }
  .xxlarge-push-6 {
    position: relative;
    left: 50%; }
  .xxlarge-pull-6 {
    position: relative;
    left: -50%; }
  .xxlarge-offset-5 {
    margin-left: 41.66667%; }
  .xxlarge-7 {
    width: 58.33333%; }
  .xxlarge-push-7 {
    position: relative;
    left: 58.33333%; }
  .xxlarge-pull-7 {
    position: relative;
    left: -58.33333%; }
  .xxlarge-offset-6 {
    margin-left: 50%; }
  .xxlarge-8 {
    width: 66.66667%; }
  .xxlarge-push-8 {
    position: relative;
    left: 66.66667%; }
  .xxlarge-pull-8 {
    position: relative;
    left: -66.66667%; }
  .xxlarge-offset-7 {
    margin-left: 58.33333%; }
  .xxlarge-9 {
    width: 75%; }
  .xxlarge-push-9 {
    position: relative;
    left: 75%; }
  .xxlarge-pull-9 {
    position: relative;
    left: -75%; }
  .xxlarge-offset-8 {
    margin-left: 66.66667%; }
  .xxlarge-10 {
    width: 83.33333%; }
  .xxlarge-push-10 {
    position: relative;
    left: 83.33333%; }
  .xxlarge-pull-10 {
    position: relative;
    left: -83.33333%; }
  .xxlarge-offset-9 {
    margin-left: 75%; }
  .xxlarge-11 {
    width: 91.66667%; }
  .xxlarge-push-11 {
    position: relative;
    left: 91.66667%; }
  .xxlarge-pull-11 {
    position: relative;
    left: -91.66667%; }
  .xxlarge-offset-10 {
    margin-left: 83.33333%; }
  .xxlarge-12 {
    width: 100%; }
  .xxlarge-offset-11 {
    margin-left: 91.66667%; }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xxlarge-up-1 > .column:nth-of-type(1n), .xxlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-1 > .column:nth-of-type(1n+1), .xxlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xxlarge-up-1 > .column:last-child, .xxlarge-up-1 > .columns:last-child {
      float: left; }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xxlarge-up-2 > .column:nth-of-type(1n), .xxlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-2 > .column:nth-of-type(2n+1), .xxlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xxlarge-up-2 > .column:last-child, .xxlarge-up-2 > .columns:last-child {
      float: left; }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xxlarge-up-3 > .column:nth-of-type(1n), .xxlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-3 > .column:nth-of-type(3n+1), .xxlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xxlarge-up-3 > .column:last-child, .xxlarge-up-3 > .columns:last-child {
      float: left; }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xxlarge-up-4 > .column:nth-of-type(1n), .xxlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-4 > .column:nth-of-type(4n+1), .xxlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xxlarge-up-4 > .column:last-child, .xxlarge-up-4 > .columns:last-child {
      float: left; }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xxlarge-up-5 > .column:nth-of-type(1n), .xxlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-5 > .column:nth-of-type(5n+1), .xxlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xxlarge-up-5 > .column:last-child, .xxlarge-up-5 > .columns:last-child {
      float: left; }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xxlarge-up-6 > .column:nth-of-type(1n), .xxlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-6 > .column:nth-of-type(6n+1), .xxlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xxlarge-up-6 > .column:last-child, .xxlarge-up-6 > .columns:last-child {
      float: left; }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xxlarge-up-7 > .column:nth-of-type(1n), .xxlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-7 > .column:nth-of-type(7n+1), .xxlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xxlarge-up-7 > .column:last-child, .xxlarge-up-7 > .columns:last-child {
      float: left; }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xxlarge-up-8 > .column:nth-of-type(1n), .xxlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-8 > .column:nth-of-type(8n+1), .xxlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xxlarge-up-8 > .column:last-child, .xxlarge-up-8 > .columns:last-child {
      float: left; }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xxlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xxlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem; }
  .xxlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xxlarge-centered, .xxlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xxlarge-uncentered,
  .xxlarge-push-0,
  .xxlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 90.625em) {
  .xxxlarge-1 {
    width: 8.33333%; }
  .xxxlarge-push-1 {
    position: relative;
    left: 8.33333%; }
  .xxxlarge-pull-1 {
    position: relative;
    left: -8.33333%; }
  .xxxlarge-offset-0 {
    margin-left: 0%; }
  .xxxlarge-2 {
    width: 16.66667%; }
  .xxxlarge-push-2 {
    position: relative;
    left: 16.66667%; }
  .xxxlarge-pull-2 {
    position: relative;
    left: -16.66667%; }
  .xxxlarge-offset-1 {
    margin-left: 8.33333%; }
  .xxxlarge-3 {
    width: 25%; }
  .xxxlarge-push-3 {
    position: relative;
    left: 25%; }
  .xxxlarge-pull-3 {
    position: relative;
    left: -25%; }
  .xxxlarge-offset-2 {
    margin-left: 16.66667%; }
  .xxxlarge-4 {
    width: 33.33333%; }
  .xxxlarge-push-4 {
    position: relative;
    left: 33.33333%; }
  .xxxlarge-pull-4 {
    position: relative;
    left: -33.33333%; }
  .xxxlarge-offset-3 {
    margin-left: 25%; }
  .xxxlarge-5 {
    width: 41.66667%; }
  .xxxlarge-push-5 {
    position: relative;
    left: 41.66667%; }
  .xxxlarge-pull-5 {
    position: relative;
    left: -41.66667%; }
  .xxxlarge-offset-4 {
    margin-left: 33.33333%; }
  .xxxlarge-6 {
    width: 50%; }
  .xxxlarge-push-6 {
    position: relative;
    left: 50%; }
  .xxxlarge-pull-6 {
    position: relative;
    left: -50%; }
  .xxxlarge-offset-5 {
    margin-left: 41.66667%; }
  .xxxlarge-7 {
    width: 58.33333%; }
  .xxxlarge-push-7 {
    position: relative;
    left: 58.33333%; }
  .xxxlarge-pull-7 {
    position: relative;
    left: -58.33333%; }
  .xxxlarge-offset-6 {
    margin-left: 50%; }
  .xxxlarge-8 {
    width: 66.66667%; }
  .xxxlarge-push-8 {
    position: relative;
    left: 66.66667%; }
  .xxxlarge-pull-8 {
    position: relative;
    left: -66.66667%; }
  .xxxlarge-offset-7 {
    margin-left: 58.33333%; }
  .xxxlarge-9 {
    width: 75%; }
  .xxxlarge-push-9 {
    position: relative;
    left: 75%; }
  .xxxlarge-pull-9 {
    position: relative;
    left: -75%; }
  .xxxlarge-offset-8 {
    margin-left: 66.66667%; }
  .xxxlarge-10 {
    width: 83.33333%; }
  .xxxlarge-push-10 {
    position: relative;
    left: 83.33333%; }
  .xxxlarge-pull-10 {
    position: relative;
    left: -83.33333%; }
  .xxxlarge-offset-9 {
    margin-left: 75%; }
  .xxxlarge-11 {
    width: 91.66667%; }
  .xxxlarge-push-11 {
    position: relative;
    left: 91.66667%; }
  .xxxlarge-pull-11 {
    position: relative;
    left: -91.66667%; }
  .xxxlarge-offset-10 {
    margin-left: 83.33333%; }
  .xxxlarge-12 {
    width: 100%; }
  .xxxlarge-offset-11 {
    margin-left: 91.66667%; }
  .xxxlarge-up-1 > .column, .xxxlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xxxlarge-up-1 > .column:nth-of-type(1n), .xxxlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxxlarge-up-1 > .column:nth-of-type(1n+1), .xxxlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xxxlarge-up-1 > .column:last-child, .xxxlarge-up-1 > .columns:last-child {
      float: left; }
  .xxxlarge-up-2 > .column, .xxxlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xxxlarge-up-2 > .column:nth-of-type(1n), .xxxlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxxlarge-up-2 > .column:nth-of-type(2n+1), .xxxlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xxxlarge-up-2 > .column:last-child, .xxxlarge-up-2 > .columns:last-child {
      float: left; }
  .xxxlarge-up-3 > .column, .xxxlarge-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xxxlarge-up-3 > .column:nth-of-type(1n), .xxxlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxxlarge-up-3 > .column:nth-of-type(3n+1), .xxxlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xxxlarge-up-3 > .column:last-child, .xxxlarge-up-3 > .columns:last-child {
      float: left; }
  .xxxlarge-up-4 > .column, .xxxlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xxxlarge-up-4 > .column:nth-of-type(1n), .xxxlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxxlarge-up-4 > .column:nth-of-type(4n+1), .xxxlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xxxlarge-up-4 > .column:last-child, .xxxlarge-up-4 > .columns:last-child {
      float: left; }
  .xxxlarge-up-5 > .column, .xxxlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xxxlarge-up-5 > .column:nth-of-type(1n), .xxxlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxxlarge-up-5 > .column:nth-of-type(5n+1), .xxxlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xxxlarge-up-5 > .column:last-child, .xxxlarge-up-5 > .columns:last-child {
      float: left; }
  .xxxlarge-up-6 > .column, .xxxlarge-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xxxlarge-up-6 > .column:nth-of-type(1n), .xxxlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxxlarge-up-6 > .column:nth-of-type(6n+1), .xxxlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xxxlarge-up-6 > .column:last-child, .xxxlarge-up-6 > .columns:last-child {
      float: left; }
  .xxxlarge-up-7 > .column, .xxxlarge-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xxxlarge-up-7 > .column:nth-of-type(1n), .xxxlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxxlarge-up-7 > .column:nth-of-type(7n+1), .xxxlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xxxlarge-up-7 > .column:last-child, .xxxlarge-up-7 > .columns:last-child {
      float: left; }
  .xxxlarge-up-8 > .column, .xxxlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xxxlarge-up-8 > .column:nth-of-type(1n), .xxxlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxxlarge-up-8 > .column:nth-of-type(8n+1), .xxxlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xxxlarge-up-8 > .column:last-child, .xxxlarge-up-8 > .columns:last-child {
      float: left; }
  .xxxlarge-collapse > .column, .xxxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xxxlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xxxlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xxxlarge-uncollapse > .column, .xxxlarge-uncollapse > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem; }
  .xxxlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xxxlarge-centered, .xxxlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xxxlarge-uncentered,
  .xxxlarge-push-0,
  .xxxlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 2.125rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 2.5rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.5;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 85%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "silka-regular-webfont", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: rgba(0, 0, 0, 0.7); }

h1 {
  font-size: 1.75rem;
  line-height: 1.21429;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.75rem;
  line-height: 1.14286;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.625rem;
  line-height: 1.46154;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.5rem;
  line-height: 1.33333;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 0.9375rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 0.8125rem;
  line-height: 1.23077;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 2.625rem;
    line-height: 1.19048; }
  h2 {
    font-size: 2.125rem;
    line-height: 1.17647; }
  h3 {
    font-size: 1.6875rem;
    line-height: 1.44444; }
  h4 {
    font-size: 1.5625rem;
    line-height: 1.32; }
  h5 {
    font-size: 1.0625rem;
    line-height: 1.23529; }
  h6 {
    font-size: 0.9375rem;
    line-height: 1.2; } }

@media screen and (min-width: 64em) {
  h1 {
    font-size: 2.875rem;
    line-height: 1.45652; }
  h2 {
    font-size: 2.5rem;
    line-height: 1.2; }
  h3 {
    font-size: 1.75rem;
    line-height: 1.42857; }
  h4 {
    font-size: 1.625rem;
    line-height: 1.30769; }
  h5 {
    font-size: 1.1875rem;
    line-height: 1.21053; }
  h6 {
    font-size: 1.0625rem;
    line-height: 1.17647; } }

a {
  line-height: inherit;
  color: #181818;
  text-decoration: underline;
  cursor: pointer; }
  a:hover, a:focus {
    color: #4979a0;
    text-decoration: none; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 90.625rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #ccc;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 0; }
  dl dt {
    margin-bottom: 0;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.5;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #0a0a0a;
  color: #181818;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 48em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

@media screen and (min-width: 64em) {
  .xlarge-text-left {
    text-align: left; }
  .xlarge-text-right {
    text-align: right; }
  .xlarge-text-center {
    text-align: center; }
  .xlarge-text-justify {
    text-align: justify; } }

@media screen and (min-width: 75em) {
  .xxlarge-text-left {
    text-align: left; }
  .xxlarge-text-right {
    text-align: right; }
  .xxlarge-text-center {
    text-align: center; }
  .xxlarge-text-justify {
    text-align: justify; } }

@media screen and (min-width: 90.625em) {
  .xxxlarge-text-left {
    text-align: left; }
  .xxxlarge-text-right {
    text-align: right; }
  .xxxlarge-text-center {
    text-align: center; }
  .xxxlarge-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #888;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.82em 1em 0.87em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 50px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 1.1875rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #30A95E;
  color: #fff; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: rgba(48, 169, 94, 0.75);
    color: #fff; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.xlarge {
    font-size: 1.1875rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #30A95E;
    color: #000; }
    .button.primary:hover, .button.primary:focus {
      background-color: #26874b;
      color: #000; }
  .button.secondary {
    background-color: #1A2746;
    color: #fff; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #151f38;
      color: #fff; }
  .button.hollow {
    border: 1px solid #1A2746;
    color: #1A2746; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #0d1423;
      color: #0d1423; }
    .button.hollow.primary {
      border: 1px solid #30A95E;
      color: #30A95E; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #18552f;
        color: #18552f; }
    .button.hollow.secondary {
      border: 1px solid #1A2746;
      color: #1A2746; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #0d1423;
        color: #0d1423; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #1A2746;
      color: #fff; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #30A95E;
        color: #000; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #1A2746;
        color: #fff; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: white transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #30A95E; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #30A95E; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #1A2746; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 3.625rem;
  margin: 0 0 1.75rem;
  padding: 0.875rem;
  border: 1px solid #9DA3AF;
  border-radius: 50px;
  background-color: #f8f7f6;
  box-shadow: none;
  font-family: "silka-regular-webfont", sans-serif;
  font-size: 1.0625rem;
  font-weight: normal;
  color: #000;
  transition: border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #888;
    background-color: #fff;
    box-shadow: none;
    transition: border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: rgba(0, 0, 0, 0.5); }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #eee;
  cursor: default; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1.75rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.875rem;
  margin-right: 1.75rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.875rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 2.4;
  color: #1A2746; }
  label.middle {
    margin: 0 0 1.75rem;
    padding: 0.9375rem 0; }

.help-text {
  margin-top: -0.875rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #000; }

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1.75rem; }
  .input-group > :first-child {
    border-radius: 50px 0 0 50px; }
  .input-group > :last-child > * {
    border-radius: 0 50px 50px 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #ccc;
  background: #eee;
  color: #000;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 3.6875rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 3.6875rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.0625rem; }

.input-group .input-group-button {
  display: table-cell; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.875rem; }

.fieldset {
  margin: 0;
  padding: 0;
  border: none; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0;
    background: white; }

select {
  height: 3.625rem;
  margin: 0 0 1.75rem;
  padding: 0.875rem;
  appearance: none;
  border: 1px solid #9DA3AF;
  border-radius: 50px;
  background-color: #fff;
  font-family: "silka-regular-webfont", sans-serif;
  font-size: 1.0625rem;
  line-height: normal;
  color: #000;
  transition: border-color 0.25s ease-in-out; }
  select:focus {
    outline: none;
    border: 1px solid #888;
    background-color: #fff;
    box-shadow: none;
    transition: border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #eee;
    cursor: default; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #faedeb; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.875rem;
  margin-bottom: 1.75rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #e24283;
  color: #fff; }
  .badge.primary {
    background: #30A95E;
    color: #000; }
  .badge.secondary {
    background: #181818;
    color: #fff; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' '; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.8125rem;
    color: rgba(0, 0, 0, 0.5);
    cursor: default; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      top: 1px;
      margin: 0 0.4rem;
      opacity: 1;
      content: "/";
      color: rgba(0, 0, 0, 0.5); }
  .breadcrumbs a {
    color: rgba(0, 0, 0, 0.5); }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: rgba(0, 0, 0, 0.3);
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  font-size: 0; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' '; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 1.1875rem; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.xlarge .button {
    font-size: 1.1875rem; }
  .button-group.expanded {
    margin-right: -1px; }
    .button-group.expanded::before, .button-group.expanded::after {
      display: none; }
    .button-group.expanded .button:first-child:last-child {
      width: 100%; }
    .button-group.expanded .button:first-child:nth-last-child(2), .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button {
      display: inline-block;
      width: calc(50% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(2):last-child, .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(3), .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button {
      display: inline-block;
      width: calc(33.33333% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(3):last-child, .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(4), .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button {
      display: inline-block;
      width: calc(25% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(4):last-child, .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(5), .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button {
      display: inline-block;
      width: calc(20% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(5):last-child, .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(6), .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button {
      display: inline-block;
      width: calc(16.66667% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(6):last-child, .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button:last-child {
        margin-right: -6px; }
  .button-group.primary .button {
    background-color: #1A2746;
    color: #fff; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #151f38;
      color: #fff; }
  .button-group.secondary .button {
    background-color: #181818;
    color: #fff; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #131313;
      color: #fff; }
  .button-group.success .button {
    background-color: #30A95E;
    color: #000; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #26874b;
      color: #000; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #000; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #000; }
  .button-group.alert .button {
    background-color: #D41367;
    color: #fff; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #aa0f52;
      color: #fff; }
  .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
    width: 100%; }
    .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
      margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      width: auto;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 48em) {
    .button-group.stacked-for-medium .button {
      width: auto;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.close-button {
  position: absolute;
  color: #000;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #e24283; }
  .close-button.small {
    right: 1.4375rem;
    top: 1.5625rem;
    font-size: 3rem;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1.4375rem;
    top: 1.5625rem;
    font-size: 3rem;
    line-height: 1; }

.menu {
  margin: 0 0 0 -0.5rem;
  list-style-type: none; }
  .menu > li {
    display: table-cell;
    vertical-align: middle; }
    [data-whatinput='mouse'] .menu > li {
      outline: 0; }
  .menu > li > a {
    display: block;
    padding: 0.7rem 1rem;
    line-height: 1; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    vertical-align: middle; }
    .menu > li > a img + span,
    .menu > li > a i + span,
    .menu > li > a svg + span {
      vertical-align: middle; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    margin-right: 0.25rem;
    display: inline-block; }
  .menu > li, .menu.horizontal > li {
    display: table-cell; }
  .menu.expanded {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .menu.expanded > li:first-child:last-child {
      width: 100%; }
  .menu.vertical > li {
    display: block; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal > li {
      display: table-cell; }
    .menu.medium-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.medium-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.medium-vertical > li {
      display: block; } }
  @media print, screen and (min-width: 48em) {
    .menu.large-horizontal > li {
      display: table-cell; }
    .menu.large-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.large-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.large-vertical > li {
      display: block; } }
  @media screen and (min-width: 64em) {
    .menu.xlarge-horizontal > li {
      display: table-cell; }
    .menu.xlarge-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.xlarge-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.xlarge-vertical > li {
      display: block; } }
  @media screen and (min-width: 75em) {
    .menu.xxlarge-horizontal > li {
      display: table-cell; }
    .menu.xxlarge-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.xxlarge-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.xxlarge-vertical > li {
      display: block; } }
  @media screen and (min-width: 90.625em) {
    .menu.xxxlarge-horizontal > li {
      display: table-cell; }
    .menu.xxxlarge-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.xxxlarge-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.xxxlarge-vertical > li {
      display: block; } }
  .menu.simple li {
    display: inline-block;
    vertical-align: top;
    line-height: 1; }
  .menu.simple a {
    padding: 0; }
  .menu.simple li {
    margin-left: 0;
    margin-right: 1rem; }
  .menu.simple.align-right li {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.align-right::before, .menu.align-right::after {
    display: table;
    content: ' '; }
  .menu.align-right::after {
    clear: both; }
  .menu.align-right > li {
    float: right; }
  .menu.icon-top > li > a {
    text-align: center; }
    .menu.icon-top > li > a img,
    .menu.icon-top > li > a i,
    .menu.icon-top > li > a svg {
      display: block;
      margin: 0 auto 0.25rem; }
  .menu.icon-top.vertical a > span {
    margin: auto; }
  .menu.nested {
    margin-left: 1rem; }
  .menu .active > a {
    background: #f7f7f7;
    color: #000000; }
  .menu.menu-bordered li {
    border: 1px solid #e6e6e6; }
    .menu.menu-bordered li:not(:first-child) {
      border-top: 0; }
  .menu.menu-hover li:hover {
    background-color: #e6e6e6; }

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit; }

.menu-centered {
  text-align: center; }
  .menu-centered > .menu {
    display: inline-block;
    vertical-align: top; }

.no-js [data-responsive-menu] ul {
  display: none; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  display: block;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem; }
  .dropdown-pane.is-open {
    visibility: visible; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  right: 5px;
  margin-top: -3px; }

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media print, screen and (min-width: 48em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media screen and (min-width: 64em) {
  .dropdown.menu.xlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.xlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.xlarge-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.xlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.xlarge-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.xlarge-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.xlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media screen and (min-width: 75em) {
  .dropdown.menu.xxlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xxlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xxlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.xxlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.xxlarge-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xxlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.xxlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.xxlarge-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.xxlarge-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.xxlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media screen and (min-width: 90.625em) {
  .dropdown.menu.xxxlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xxxlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xxxlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.xxxlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.xxxlarge-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xxxlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.xxxlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.xxxlarge-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.xxxlarge-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.xxxlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #1779ba;
  color: #fefefe; }
  .label.primary {
    background: #1779ba;
    color: #fefefe; }
  .label.secondary {
    background: #767676;
    color: #fefefe; }
  .label.success {
    background: #3adb76;
    color: #0a0a0a; }
  .label.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .label.alert {
    background: #cc4b37;
    color: #fefefe; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.25s ease, visibility 0.25s ease;
  background: rgba(255, 255, 255, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 1;
  transition: transform 0.25s ease;
  backface-visibility: hidden;
  background: #1A2746; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-overlap {
    z-index: 10; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 1;
  transition: transform 0.25s ease;
  backface-visibility: hidden;
  background: #1A2746; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 10; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  overflow-y: auto; }
  .position-left.is-open ~ .off-canvas-content {
    transform: translateX(100%); }
  .position-left.is-transition-push::after {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-left.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-right {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  overflow-y: auto; }
  .position-right.is-open ~ .off-canvas-content {
    transform: translateX(-100%); }
  .position-right.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-right.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(-250px);
  overflow-x: auto; }
  .position-top.is-open ~ .off-canvas-content {
    transform: translateY(250px); }
  .position-top.is-transition-push::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-top.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(250px);
  overflow-x: auto; }
  .position-bottom.is-open ~ .off-canvas-content {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-bottom.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.off-canvas-content {
  transition: transform 0.25s ease;
  backface-visibility: hidden; }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 100%; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 100%; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 100%; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 100%; } }

@media print, screen and (min-width: 48em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 100%; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 100%; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 100%; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 100%; } }

@media screen and (min-width: 64em) {
  .position-left.reveal-for-xlarge {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-xlarge ~ .off-canvas-content {
      margin-left: 100%; }
  .position-right.reveal-for-xlarge {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-xlarge ~ .off-canvas-content {
      margin-right: 100%; }
  .position-top.reveal-for-xlarge {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-xlarge ~ .off-canvas-content {
      margin-top: 100%; }
  .position-bottom.reveal-for-xlarge {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-xlarge ~ .off-canvas-content {
      margin-bottom: 100%; } }

@media screen and (min-width: 75em) {
  .position-left.reveal-for-xxlarge {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-xxlarge ~ .off-canvas-content {
      margin-left: 100%; }
  .position-right.reveal-for-xxlarge {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-xxlarge ~ .off-canvas-content {
      margin-right: 100%; }
  .position-top.reveal-for-xxlarge {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-xxlarge ~ .off-canvas-content {
      margin-top: 100%; }
  .position-bottom.reveal-for-xxlarge {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-xxlarge ~ .off-canvas-content {
      margin-bottom: 100%; } }

@media screen and (min-width: 90.625em) {
  .position-left.reveal-for-xxxlarge {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-xxxlarge ~ .off-canvas-content {
      margin-left: 100%; }
  .position-right.reveal-for-xxxlarge {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-xxxlarge ~ .off-canvas-content {
      margin-right: 100%; }
  .position-top.reveal-for-xxxlarge {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-xxxlarge ~ .off-canvas-content {
      margin-top: 100%; }
  .position-bottom.reveal-for-xxxlarge {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-xxxlarge ~ .off-canvas-content {
      margin-bottom: 100%; } }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' '; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 4px;
    color: #000; }
    .pagination a:hover,
    .pagination button:hover {
      background: #eee; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #e24283;
    color: #e24283;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #ccc;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #000; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 0.375rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #fff; }
  .progress.primary .progress-meter {
    background-color: #1A2746; }
  .progress.secondary .progress-meter {
    background-color: #181818; }
  .progress.success .progress-meter {
    background-color: #30A95E; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #D41367; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #e24283; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: white;
  white-space: nowrap; }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal .reveal {
      right: auto;
      left: auto;
      margin: 0 auto; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 75rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: white;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fefefe;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #1779ba; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 4px; }
  thead,
  tbody,
  tfoot {
    border: 1px solid #cccccc;
    background-color: #fff; }
  caption {
    padding: 1.25rem 0.625rem 1.25rem 0;
    font-weight: bold; }
  thead {
    background: #fff;
    color: #000000; }
  tfoot {
    background: #fff;
    color: #0a0a0a; }
  thead tr,
  tfoot tr {
    background: transparent; }
  thead th,
  thead td,
  tfoot th,
  tfoot td {
    padding: 1.25rem 0.625rem 1.25rem 0;
    font-weight: bold;
    text-align: left; }
  tbody th,
  tbody td {
    padding: 1.25rem 0.625rem 1.25rem 0; }
  table.unstriped tbody {
    background-color: #fff; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #cccccc;
      background-color: #fff; }

@media screen and (max-width: 47.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f3f3f3; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f2f2f2; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #888;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #000;
  font-size: 85%;
  color: #fff; }
  .tooltip::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #000;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #000 transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #000;
    top: 50%;
    bottom: auto;
    left: 100%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #000 transparent transparent;
    top: 50%;
    right: 100%;
    bottom: auto;
    left: auto;
    transform: translateY(-50%); }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only, .hidden-xs {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 47.9375em) {
  .hide-for-medium-only, .hidden-sm {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 48em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 48em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 47.9375em), screen and (min-width: 64em) {
  .show-for-large-only {
    display: none !important; } }

@media screen and (min-width: 64em) {
  .hide-for-xlarge {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-xlarge {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-xlarge-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-xlarge-only {
    display: none !important; } }

@media screen and (min-width: 75em) {
  .hide-for-xxlarge {
    display: none !important; } }

@media screen and (max-width: 74.9375em) {
  .show-for-xxlarge {
    display: none !important; } }

@media screen and (min-width: 75em) and (max-width: 90.5625em) {
  .hide-for-xxlarge-only {
    display: none !important; } }

@media screen and (max-width: 74.9375em), screen and (min-width: 90.625em) {
  .show-for-xxlarge-only {
    display: none !important; } }

@media screen and (min-width: 90.625em) {
  .hide-for-xxxlarge {
    display: none !important; } }

@media screen and (max-width: 90.5625em) {
  .show-for-xxxlarge {
    display: none !important; } }

@media screen and (min-width: 90.625em) {
  .hide-for-xxxlarge-only {
    display: none !important; } }

@media screen and (max-width: 90.5625em) {
  .show-for-xxxlarge-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left, .page-layout-2columns-right .sidebar-main.algolia-left-container {
  float: left !important; }

.float-right, .page-layout-2columns-left .column.main, .page-layout-2columns-left .main.columns, .page-layout-2columns-right .sidebar-main, .page-layout-2columns-right .sidebar-additional, .page-layout-0column .column.algolia-right-container, .page-layout-0column .algolia-right-container.columns,
.page-layout-1column .column.algolia-right-container, .page-layout-1column .algolia-right-container.columns, .page-layout-2columns-right .algolia-right-container {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

@media print {
  a[href]:after {
    content: none !important; } }

.at {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.relative {
  position: relative; }

.ib {
  display: inline-block;
  vertical-align: middle; }

.no-display {
  display: none; }

.loader-holder {
  position: absolute;
  width: 100%;
  height: 40px; }

.no-outline, .modal-custom .action-close,
.modal-popup .action-close,
.modal-slide .action-close, .product-info-wrapper .product-info-sidebar .fieldset, .filter-options-content .am-show-more,
.am-show-more, .checkout-steps .form-order-comment > .field > .label,
.checkout-steps .new-address-form > .field > .label, .authentication-wrapper .authentication-dropdown, .shipping-address .action, .opc-payment-additional .payment-option-title, .page-product-giftcard .product-info-sidebar .giftcard-amount, .bundle-options-container .product-options-wrapper .fieldset, .bundle-options-container .block-bundle-summary .bundle-info .fieldset, .filter-options[role="tablist"] .filter-options-title, .top-menu .top-menu-button {
  outline: none; }
  .no-outline:hover, .modal-custom .action-close:hover,
  .modal-popup .action-close:hover,
  .modal-slide .action-close:hover, .product-info-wrapper .product-info-sidebar .fieldset:hover,
  .am-show-more:hover, .checkout-steps .form-order-comment > .field > .label:hover,
  .checkout-steps .new-address-form > .field > .label:hover, .authentication-wrapper .authentication-dropdown:hover, .shipping-address .action:hover, .opc-payment-additional .payment-option-title:hover, .page-product-giftcard .product-info-sidebar .giftcard-amount:hover, .bundle-options-container .product-options-wrapper .fieldset:hover, .bundle-options-container .block-bundle-summary .bundle-info .fieldset:hover, .filter-options[role="tablist"] .filter-options-title:hover, .top-menu .top-menu-button:hover, .no-outline:active, .modal-custom .action-close:active,
  .modal-popup .action-close:active,
  .modal-slide .action-close:active, .product-info-wrapper .product-info-sidebar .fieldset:active,
  .am-show-more:active, .checkout-steps .form-order-comment > .field > .label:active,
  .checkout-steps .new-address-form > .field > .label:active, .authentication-wrapper .authentication-dropdown:active, .shipping-address .action:active, .opc-payment-additional .payment-option-title:active, .page-product-giftcard .product-info-sidebar .giftcard-amount:active, .bundle-options-container .product-options-wrapper .fieldset:active, .bundle-options-container .block-bundle-summary .bundle-info .fieldset:active, .filter-options[role="tablist"] .filter-options-title:active, .top-menu .top-menu-button:active, .no-outline:focus, .modal-custom .action-close:focus,
  .modal-popup .action-close:focus,
  .modal-slide .action-close:focus, .product-info-wrapper .product-info-sidebar .fieldset:focus,
  .am-show-more:focus, .checkout-steps .form-order-comment > .field > .label:focus,
  .checkout-steps .new-address-form > .field > .label:focus, .authentication-wrapper .authentication-dropdown:focus, .shipping-address .action:focus, .opc-payment-additional .payment-option-title:focus, .page-product-giftcard .product-info-sidebar .giftcard-amount:focus, .bundle-options-container .product-options-wrapper .fieldset:focus, .bundle-options-container .block-bundle-summary .bundle-info .fieldset:focus, .filter-options[role="tablist"] .filter-options-title:focus, .top-menu .top-menu-button:focus {
    outline: none; }

.extra-title, .block.grid .block-title strong, .block.related .block-title strong, .block.crosssell .block-title strong, .block.upsell .block-title strong, .block.block-new-products-names .block-title strong {
  font-size: 1.25rem;
  font-family: "silka-regular-webfont", sans-serif;
  letter-spacing: normal;
  font-weight: 300;
  font-style: normal;
  margin: 0 0 1.5rem;
  text-align: center;
  text-transform: none;
  color: inherit; }
  .extra-title strong, .block.grid .block-title strong strong, .block.related .block-title strong strong, .block.crosssell .block-title strong strong, .block.upsell .block-title strong strong, .block.block-new-products-names .block-title strong strong,
  .extra-title b,
  .block.grid .block-title strong b,
  .block.related .block-title strong b,
  .block.crosssell .block-title strong b,
  .block.upsell .block-title strong b,
  .block.block-new-products-names .block-title strong b {
    font-weight: 300; }

.checkbox {
  line-height: 1.2;
  margin-bottom: .75rem;
  position: relative; }
  .checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0; }
    .checkbox input[type="checkbox"] + label,
    .checkbox input[type="checkbox"] + .label {
      cursor: pointer;
      font-weight: normal;
      position: relative;
      margin: 0;
      line-height: 1.2;
      min-height: 1rem;
      padding-left: 1.4rem; }
      .checkbox input[type="checkbox"] + label:before,
      .checkbox input[type="checkbox"] + .label:before {
        display: block;
        content: '';
        height: 1rem;
        width: 1rem;
        line-height: 1rem;
        text-align: center;
        position: absolute;
        top: -1px;
        left: 0;
        border: 1px solid #9DA3AF;
        color: #181818;
        background: white; }
    .checkbox input[type="checkbox"]:checked + label:before,
    .checkbox input[type="checkbox"]:checked + .label:before {
      content: '✓'; }
    .checkbox input[type="checkbox"]:disabled + label {
      opacity: .4; }
    .checkbox input[type="checkbox"]:disabled + label:before,
    .checkbox input[type="checkbox"]:disabled + .label:before {
      background: #eee; }

.radio {
  line-height: 1.2;
  margin-bottom: .75rem;
  position: relative; }
  .radio input[type="radio"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0; }
    .radio input[type="radio"] + label,
    .radio input[type="radio"] + .label {
      cursor: pointer;
      font-weight: normal;
      position: relative;
      margin: 0;
      line-height: 1.2;
      min-height: 1rem;
      padding-left: 1.4rem; }
      .radio input[type="radio"] + label:before,
      .radio input[type="radio"] + .label:before {
        display: block;
        content: '';
        height: 1rem;
        width: 1rem;
        line-height: 1rem;
        text-align: center;
        position: absolute;
        top: -1px;
        left: 0;
        border-radius: 50%;
        border: 1px solid #9DA3AF;
        color: #181818;
        background: white;
        z-index: 0; }
      .radio input[type="radio"] + label:after,
      .radio input[type="radio"] + .label:after {
        display: none;
        content: '';
        position: absolute;
        top: 0.125rem;
        left: 0.1875rem;
        height: 0.625rem;
        width: 0.625rem;
        border-radius: 50%;
        background: #1A2746; }
    .radio input[type="radio"]:checked + label:after,
    .radio input[type="radio"]:checked + .label:after {
      display: block; }
    .radio input[type="radio"]:disabled + label:before,
    .radio input[type="radio"]:disabled + .label:before {
      opacity: .3;
      background: #eee; }

.product-items .product-item .product-image-container:before,
.product-items .ais-Hits-item .product-image-container:before,
.product-items .ais-InfiniteHits-item .product-image-container:before,
.ais-Hits .product-item .product-image-container:before,
.ais-Hits .ais-Hits-item .product-image-container:before,
.ais-Hits .ais-InfiniteHits-item .product-image-container:before,
.ais-InfiniteHits .product-item .product-image-container:before,
.ais-InfiniteHits .ais-Hits-item .product-image-container:before,
.ais-InfiniteHits .ais-InfiniteHits-item .product-image-container:before, table.cart .product-image-container:before, .add-to-cart-popup .add-popup-product-image:before {
  display: block;
  content: '';
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  background: rgba(70, 55, 23, 0.04); }

.loading-overlay {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.3);
  background-image: url("../images/ripple.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  z-index: 555;
  background-image: none; }
  .ie11 .loading-overlay,
  .no-smil .loading-overlay {
    background-image: url("../images/ripple.gif"); }
  .loading-overlay .loading-mssg {
    display: block;
    position: absolute;
    top: 10rem;
    left: 50%;
    transform: translateX(-50%);
    color: #1A2746;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-weight: 400;
    font-size: 100%;
    font-style: normal; }
    .loading-overlay .loading-mssg .loading-img {
      margin-right: .5rem; }
    @media print, screen and (min-width: 40em) {
      .loading-overlay .loading-mssg {
        font-size: 150%; } }
    @media screen and (min-width: 64em) {
      .loading-overlay .loading-mssg {
        font-size: 180%;
        top: 15rem; } }
  .ie11 .loading-overlay,
  .no-smil .loading-overlay {
    background-image: none; }
    .ie11 .loading-overlay .loading-mssg .loading-img,
    .no-smil .loading-overlay .loading-mssg .loading-img {
      display: none; }
    .ie11 .loading-overlay .loading-mssg:before,
    .no-smil .loading-overlay .loading-mssg:before {
      display: block;
      content: '';
      margin: 0 auto;
      width: 160px;
      height: 160px;
      background-image: url("../images/ripple.gif");
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 100% auto; }

@-webkit-keyframes spin-loading-more {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spin-loading-more {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.admin__data-grid-loading-mask {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.3);
  background-image: url("../images/ripple.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  z-index: 555; }
  .ie11 .admin__data-grid-loading-mask,
  .no-smil .admin__data-grid-loading-mask {
    background-image: url("../images/ripple.gif"); }
  .admin__data-grid-loading-mask .loading-mssg {
    display: block;
    position: absolute;
    top: 10rem;
    left: 50%;
    transform: translateX(-50%);
    color: #1A2746;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-weight: 400;
    font-size: 100%;
    font-style: normal; }
    .admin__data-grid-loading-mask .loading-mssg .loading-img {
      margin-right: .5rem; }
    @media print, screen and (min-width: 40em) {
      .admin__data-grid-loading-mask .loading-mssg {
        font-size: 150%; } }
    @media screen and (min-width: 64em) {
      .admin__data-grid-loading-mask .loading-mssg {
        font-size: 180%;
        top: 15rem; } }
  .admin__data-grid-loading-mask .spinner {
    display: none; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only, .hidden-xs {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium,
  .hide-for-medium-up {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium,
  .show-for-medium-up {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 47.9375em) {
  .hide-for-medium-only, .hidden-sm {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 48em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 48em) {
  .hide-for-large,
  .hide-for-large-up {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  .show-for-large,
  .show-for-large-up {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 47.9375em), screen and (min-width: 64em) {
  .show-for-large-only {
    display: none !important; } }

@media screen and (min-width: 64em) {
  .hide-for-xlarge,
  .hide-for-xlarge-up {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-xlarge,
  .show-for-xlarge-up {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-xlarge-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-xlarge-only {
    display: none !important; } }

@media screen and (min-width: 75em) {
  .hide-for-xxlarge,
  .hide-for-xxlarge-up {
    display: none !important; } }

@media screen and (max-width: 74.9375em) {
  .show-for-xxlarge,
  .show-for-xxlarge-up {
    display: none !important; } }

@media screen and (min-width: 75em) and (max-width: 90.5625em) {
  .hide-for-xxlarge-only {
    display: none !important; } }

@media screen and (max-width: 74.9375em), screen and (min-width: 90.625em) {
  .show-for-xxlarge-only {
    display: none !important; } }

@media screen and (min-width: 90.625em) {
  .hide-for-xxxlarge,
  .hide-for-xxxlarge-up {
    display: none !important; } }

@media screen and (max-width: 90.5625em) {
  .show-for-xxxlarge,
  .show-for-xxxlarge-up {
    display: none !important; } }

@media screen and (min-width: 90.625em) {
  .hide-for-xxxlarge-only {
    display: none !important; } }

@media screen and (max-width: 90.5625em) {
  .show-for-xxxlarge-only {
    display: none !important; } }

.ui-tabs-nav,
.qdb-panel ul {
  margin: 0;
  list-style: none; }
  .ui-tabs-nav li,
  .qdb-panel ul li {
    padding: 0; }
    .ui-tabs-nav li:after,
    .qdb-panel ul li:after {
      display: none; }

body {
  font-size: 1rem;
  font-family: "silka-regular-webfont", sans-serif;
  font-weight: 400;
  line-height: 1.4;
  color: #181818;
  background-color: white; }
  body.is-reveal-open {
    overflow: auto; }

html.is-reveal-open,
html.is-reveal-open body {
  overflow: auto;
  position: static;
  min-height: auto;
  user-select: auto; }

.home-banners {
  margin-bottom: 1rem; }
  @media print, screen and (min-width: 48em) {
    .home-banners {
      margin-bottom: 2rem; } }

.skipLink {
  display: block;
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  padding: .35rem .25rem .25rem .25rem;
  text-align: center;
  color: white;
  background-color: #1A2746;
  border: none;
  transition: all .3s ease;
  z-index: 900; }
  .skipLink:active, .skipLink:focus {
    top: 0;
    outline: none;
    color: white; }

.breadcrumbs .items {
  list-style: none;
  margin: 0;
  padding: 0; }
  .breadcrumbs .items li:after {
    display: none; }
  .breadcrumbs .items li:not(:last-child)::after {
    width: auto;
    height: auto;
    content: "/";
    display: inline-block;
    vertical-align: baseline;
    position: relative;
    top: auto;
    left: auto;
    font-size: 0.8125rem;
    color: rgba(0, 0, 0, 0.5);
    background-color: transparent; }

h1, .as-h1,
h2, .as-h2,
h3, .as-h3,
h4, .as-h4,
h5, .as-h5,
h6, .as-h6 {
  letter-spacing: normal;
  text-transform: none; }
  h1.bold, .as-h1.bold,
  h2.bold, .as-h2.bold,
  h3.bold, .as-h3.bold,
  h4.bold, .as-h4.bold,
  h5.bold, .as-h5.bold,
  h6.bold, .as-h6.bold {
    font-weight: bold; }
  h1 a, .as-h1 a,
  h2 a, .as-h2 a,
  h3 a, .as-h3 a,
  h4 a, .as-h4 a,
  h5 a, .as-h5 a,
  h6 a, .as-h6 a {
    color: inherit;
    text-decoration: none;
    cursor: pointer; }
  h1 strong,
  h1 b, .as-h1 strong,
  .as-h1 b,
  h2 strong,
  h2 b, .as-h2 strong,
  .as-h2 b,
  h3 strong,
  h3 b, .as-h3 strong,
  .as-h3 b,
  h4 strong,
  h4 b, .as-h4 strong,
  .as-h4 b,
  h5 strong,
  h5 b, .as-h5 strong,
  .as-h5 b,
  h6 strong,
  h6 b, .as-h6 strong,
  .as-h6 b {
    font-weight: inherit; }

h1,
.as-h1 {
  margin-bottom: 1.59375rem; }
  @media print, screen and (min-width: 40em) {
    h1,
    .as-h1 {
      margin-bottom: 1.875rem; } }

h2,
.as-h2 {
  margin-bottom: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    h2,
    .as-h2 {
      margin-bottom: 1.25rem; } }

h3,
.as-h3 {
  margin-bottom: 0.53125rem; }
  @media print, screen and (min-width: 40em) {
    h3,
    .as-h3 {
      margin-bottom: 0.625rem; } }

h4,
.as-h4 {
  margin-bottom: 0.31875rem; }
  @media print, screen and (min-width: 40em) {
    h4,
    .as-h4 {
      margin-bottom: 0.375rem; } }

small {
  color: rgba(0, 0, 0, 0.7); }

p {
  font-weight: 400; }

.switch {
  color: #000000;
  font-weight: 400; }

strong,
b {
  font-weight: bold; }

.txt-nowrap {
  white-space: nowrap; }

.item-options {
  overflow: hidden;
  color: rgba(24, 24, 24, 0.8); }
  .item-options dt {
    float: left;
    clear: left;
    font-weight: normal;
    padding-right: .5rem; }
    .item-options dt:after {
      content: ':'; }
  .item-options dd {
    float: left; }

.mva_label {
  color: rgba(24, 24, 24, 0.5);
  font-size: 0.70312rem;
  font-weight: 400;
  letter-spacing: normal; }

select,
[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'] {
  height: 3.75rem;
  margin-bottom: 0.58333rem;
  background-repeat: no-repeat;
  background-size: 12px auto;
  background-position: calc(100% - 17px) 50%;
  padding: 0.625rem 1.6875rem; }
  select.mage-error, select.invalid,
  [type='text'].mage-error,
  [type='text'].invalid, [type='password'].mage-error, [type='password'].invalid, [type='date'].mage-error, [type='date'].invalid, [type='datetime'].mage-error, [type='datetime'].invalid, [type='datetime-local'].mage-error, [type='datetime-local'].invalid, [type='month'].mage-error, [type='month'].invalid, [type='week'].mage-error, [type='week'].invalid, [type='email'].mage-error, [type='email'].invalid, [type='number'].mage-error, [type='number'].invalid, [type='search'].mage-error, [type='search'].invalid, [type='tel'].mage-error, [type='tel'].invalid, [type='time'].mage-error, [type='time'].invalid, [type='url'].mage-error, [type='url'].invalid, [type='color'].mage-error, [type='color'].invalid {
    padding-right: 2.5rem;
    background-image: url("../images/icon-invalid.svg");
    background-color: #fce1ed; }
  select.mage-success, select.valid,
  [type='text'].mage-success,
  [type='text'].valid, [type='password'].mage-success, [type='password'].valid, [type='date'].mage-success, [type='date'].valid, [type='datetime'].mage-success, [type='datetime'].valid, [type='datetime-local'].mage-success, [type='datetime-local'].valid, [type='month'].mage-success, [type='month'].valid, [type='week'].mage-success, [type='week'].valid, [type='email'].mage-success, [type='email'].valid, [type='number'].mage-success, [type='number'].valid, [type='search'].mage-success, [type='search'].valid, [type='tel'].mage-success, [type='tel'].valid, [type='time'].mage-success, [type='time'].valid, [type='url'].mage-success, [type='url'].valid, [type='color'].mage-success, [type='color'].valid {
    padding-right: 2.5rem;
    background-image: url("../images/icon-valid.svg");
    background-size: 14px auto;
    background-position: calc(100% - 15px) 50%; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 0.58333rem; }

textarea {
  resize: none; }

select {
  padding-right: 3rem;
  background: #f8f7f6 url("../images/icon-select.svg") calc(100% - 22px) 50% no-repeat;
  background-size: 16px auto; }

.input-text.mage-error {
  border-color: #D41367;
  margin-bottom: 0; }
  .input-text.mage-error + .mage-error,
  .input-text.mage-error + .field-error {
    margin-top: 0.58333rem; }

div.mage-error,
div.field-error {
  display: block;
  margin-bottom: .5rem;
  color: #D41367; }

label.label, .checkout-steps .checkout-box legend.label, .checkout-steps .form-order-comment legend.label,
.checkout-steps .new-address-form legend.label, .shipping-address .action, .modal-add-adress .street legend.label,
span.label {
  padding: 0;
  vertical-align: middle;
  color: #181818;
  font-size: inherit;
  line-height: inherit;
  white-space: normal;
  background: none; }

label.label, .checkout-steps .checkout-box legend.label, .checkout-steps .form-order-comment legend.label,
.checkout-steps .new-address-form legend.label, .shipping-address .action, .modal-add-adress .street legend.label {
  display: block;
  margin: 0;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 2.4;
  color: #1A2746; }
  label.label.middle, .checkout-steps .checkout-box legend.middle.label, .checkout-steps .form-order-comment legend.middle.label,
  .checkout-steps .new-address-form legend.middle.label, .shipping-address .middle.action, .modal-add-adress .street legend.middle.label {
    margin: 0 0 1.75rem;
    padding: 0.9375rem 0; }

.fieldset {
  padding: 0;
  border: 0;
  margin: 0; }
  .fieldset .legend {
    width: 100%;
    margin: 0;
    padding: 0;
    background: none; }

ul {
  margin-bottom: 1rem; }
  ul > li {
    padding: .3em 0 .2em 0.2em; }

ol {
  margin-bottom: 1rem; }
  ol > li {
    padding: .3em 0 .4em 0.2em; }

table {
  border-collapse: collapse;
  border: 0;
  margin-bottom: 2.5rem; }
  table tr td,
  table tr th {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    text-align: left; }
  table tr td {
    font-size: 0.815rem; }
  table thead th {
    border-bottom: 0;
    padding: 1.125rem 0.625rem 0.625rem 0;
    font-size: 1rem;
    font-weight: inherit;
    font-family: "Montserrat", sans-serif;
    letter-spacing: normal; }
  table thead,
  table tfoot,
  table tbody {
    border: 0; }
  table tfoot tr:last-child td,
  table tfoot tr:last-child th,
  table tbody tr:last-child td,
  table tbody tr:last-child th {
    border-bottom: 0; }

@media screen and (max-width: 47.9375em) {
  .table-wrapper {
    overflow-x: auto;
    overflow-y: hidden; } }

.page-main {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem; }
  .page-main::before, .page-main::after {
    display: table;
    content: ' '; }
  .page-main::after {
    clear: both; }
  .cms-home .algolia-instant-selector-results .page-main {
    margin-top: 0; }
  .cms-home .page-main {
    margin-top: 1rem; }
  @media screen and (min-width: 64em) {
    .page-main {
      margin-bottom: 5rem; }
      .cms-home .algolia-instant-selector-results .page-main {
        margin-top: 0; }
      .cms-home .page-main {
        margin-top: 2rem; } }
  .page-main.page-main-details {
    margin: 0; }

.column.main, .main.columns {
  min-height: 300px;
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem;
  position: relative; }
  @media print, screen and (min-width: 40em) {
    .column.main, .main.columns {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .column.main:last-child:not(:first-child), .main.columns:last-child:not(:first-child) {
    float: right; }
  .page-main-details .column.main, .page-main-details .main.columns {
    min-height: inherit;
    padding: 0; }
  .page-layout-0column .column.main, .page-layout-0column .main.columns {
    padding-left: 0rem;
    padding-right: 0rem; }
    @media print, screen and (min-width: 40em) {
      .page-layout-0column .column.main, .page-layout-0column .main.columns {
        padding-left: 0rem; } }
    @media print, screen and (min-width: 40em) {
      .page-layout-0column .column.main, .page-layout-0column .main.columns {
        padding-right: 0rem; } }
  .page-layout-1column .column.main, .page-layout-1column .main.columns {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .page-layout-3columns .column.main, .page-layout-3columns .main.columns {
      width: 33.33333%; } }
  @media screen and (min-width: 64em) {
    .page-layout-3columns .column.main, .page-layout-3columns .main.columns {
      width: 50%; } }
  @media print, screen and (min-width: 40em) {
    .page-layout-2columns-left .column.main, .page-layout-2columns-left .main.columns {
      width: 66.66667%; } }
  @media screen and (min-width: 64em) {
    .page-layout-2columns-left .column.main, .page-layout-2columns-left .main.columns {
      width: 75%; } }
  @media print, screen and (min-width: 40em) {
    .page-layout-2columns-right .column.main, .page-layout-2columns-right .main.columns {
      width: 66.66667%; } }
  @media screen and (min-width: 64em) {
    .page-layout-2columns-right .column.main, .page-layout-2columns-right .main.columns {
      width: 75%; } }
  .column.main > .category-cms, .main.columns > .category-cms {
    padding-left: 0;
    padding-right: 0; }

.page-container:not(.algolia-instant-selector-results) > .page-main {
  margin-top: 1.6rem; }
  .cms-index-index .page-container:not(.algolia-instant-selector-results) > .page-main {
    margin-top: 0; }
  @media print, screen and (min-width: 48em) {
    .page-container:not(.algolia-instant-selector-results) > .page-main {
      margin-top: 3.2rem; } }

.page-container:not(.algolia-instant-selector-results) > .top-controls-wrapper + .page-main,
.page-container:not(.algolia-instant-selector-results) > .category-header + .page-main,
.page-container:not(.algolia-instant-selector-results) > .category-cms + .page-main {
  margin-top: 0; }

.page-layout-0column .page-container:not(.algolia-instant-selector-results) {
  padding-left: 0rem;
  padding-right: 0rem; }
  @media print, screen and (min-width: 40em) {
    .page-layout-0column .page-container:not(.algolia-instant-selector-results) {
      padding-left: 0rem; } }
  @media print, screen and (min-width: 40em) {
    .page-layout-0column .page-container:not(.algolia-instant-selector-results) {
      padding-right: 0rem; } }

.page-container,
.algolia-instant-selector-results,
.footer-content,
.footer-bottom,
.page-header,
.header .tab-bar,
.category-header.has-bg,
.header.is-stuck .sticky-header-row,
.magellan-bar.is-stuck .magellan-row,
.header .header-row.is-stuck .sticky-header-row,
.is-stuck .center-header-top-panel,
.category-cms,
.page-layout-0column .top-controls-wrapper,
.page-layout-0column .usp-section,
.usp-section ul,
.full-page-content .column.main > .convert-grid-row:not(.expanded),
.full-page-content .main.columns > .convert-grid-row:not(.expanded),
body.preview > .convert-grid-row {
  padding-left: 6px;
  padding-right: 6px; }
  @media print, screen and (min-width: 48em) {
    .page-container,
    .algolia-instant-selector-results,
    .footer-content,
    .footer-bottom,
    .page-header,
    .header .tab-bar,
    .category-header.has-bg,
    .header.is-stuck .sticky-header-row,
    .magellan-bar.is-stuck .magellan-row,
    .header .header-row.is-stuck .sticky-header-row,
    .is-stuck .center-header-top-panel,
    .category-cms,
    .page-layout-0column .top-controls-wrapper,
    .page-layout-0column .usp-section,
    .usp-section ul,
    .full-page-content .column.main > .convert-grid-row:not(.expanded),
    .full-page-content .main.columns > .convert-grid-row:not(.expanded),
    body.preview > .convert-grid-row {
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (min-width: 90.625em) {
    .page-container,
    .algolia-instant-selector-results,
    .footer-content,
    .footer-bottom,
    .page-header,
    .header .tab-bar,
    .category-header.has-bg,
    .header.is-stuck .sticky-header-row,
    .magellan-bar.is-stuck .magellan-row,
    .header .header-row.is-stuck .sticky-header-row,
    .is-stuck .center-header-top-panel,
    .category-cms,
    .page-layout-0column .top-controls-wrapper,
    .page-layout-0column .usp-section,
    .usp-section ul,
    .full-page-content .column.main > .convert-grid-row:not(.expanded),
    .full-page-content .main.columns > .convert-grid-row:not(.expanded),
    body.preview > .convert-grid-row {
      padding-left: 0;
      padding-right: 0; } }

.full-page-content::before, .full-page-content::after {
  display: table;
  content: ' '; }

.full-page-content::after {
  clear: both; }

.full-page-content > .columns:not(.convert-column) {
  margin-right: auto;
  margin-left: auto; }
  .full-page-content > .columns:not(.convert-column) > .column, .full-page-content > .columns:not(.convert-column) > .columns {
    padding-left: 0rem;
    padding-right: 0rem; }
    @media print, screen and (min-width: 40em) {
      .full-page-content > .columns:not(.convert-column) > .column, .full-page-content > .columns:not(.convert-column) > .columns {
        padding-left: 0rem; } }
    @media print, screen and (min-width: 40em) {
      .full-page-content > .columns:not(.convert-column) > .column, .full-page-content > .columns:not(.convert-column) > .columns {
        padding-right: 0rem; } }

.full-page-content .page-container {
  padding-left: 0rem;
  padding-right: 0rem; }
  @media print, screen and (min-width: 40em) {
    .full-page-content .page-container {
      padding-left: 0rem; } }
  @media print, screen and (min-width: 40em) {
    .full-page-content .page-container {
      padding-right: 0rem; } }

.columns:not(.convert-column) {
  padding-left: 0rem;
  padding-right: 0rem; }
  @media print, screen and (min-width: 40em) {
    .columns:not(.convert-column) {
      padding-left: 0rem; } }
  @media print, screen and (min-width: 40em) {
    .columns:not(.convert-column) {
      padding-right: 0rem; } }
  @media screen and (max-width: 47.9375em) {
    body.account .columns:not(.convert-column) {
      display: flex;
      flex-wrap: wrap; }
      body.account .columns:not(.convert-column) .column.main, body.account .columns:not(.convert-column) .main.columns {
        flex-basis: 100%;
        flex-grow: 1;
        order: 2; }
      body.account .columns:not(.convert-column) .sidebar-main {
        flex-grow: 1;
        flex-basis: 100%;
        order: 1; }
      body.account .columns:not(.convert-column) .sidebar-additional {
        flex-grow: 1;
        flex-basis: 100%;
        order: 3; }
    body.checkout-cart-index .columns:not(.convert-column) .cart-container {
      display: flex;
      flex-wrap: wrap; }
    body.checkout-cart-index .columns:not(.convert-column) .cart-form {
      flex-grow: 1;
      flex-basis: 100%;
      order: 1; }
    body.checkout-cart-index .columns:not(.convert-column) .cart-summary {
      flex-grow: 1;
      flex-basis: 100%;
      order: 3; } }
  @media print, screen and (min-width: 40em) {
    .columns:not(.convert-column) {
      display: block; } }
  .page-layout-1column .columns:not(.convert-column),
  .page-layout-2columns-right .columns:not(.convert-column),
  .page-layout-2columns-left main > .columns:not(.convert-column) {
    float: none !important; }
    .page-layout-1column .columns:not(.convert-column)::before, .page-layout-1column .columns:not(.convert-column)::after,
    .page-layout-2columns-right .columns:not(.convert-column)::before,
    .page-layout-2columns-right .columns:not(.convert-column)::after,
    .page-layout-2columns-left main > .columns:not(.convert-column)::before,
    .page-layout-2columns-left main > .columns:not(.convert-column)::after {
      display: table;
      content: ' '; }
    .page-layout-1column .columns:not(.convert-column)::after,
    .page-layout-2columns-right .columns:not(.convert-column)::after,
    .page-layout-2columns-left main > .columns:not(.convert-column)::after {
      clear: both; }

.small-25 {
  width: 20%; }

@media print, screen and (min-width: 40em) {
  .medium-25 {
    width: 20%; } }

@media print, screen and (min-width: 48em) {
  .large-25 {
    width: 20%; } }

@media screen and (min-width: 64em) {
  .xlarge-25 {
    width: 20%; } }

@media screen and (min-width: 75em) {
  .xxlarge-25 {
    width: 20%; } }

@media screen and (min-width: 90.625em) {
  .xxxlarge-25 {
    width: 20%; } }

.page-title-wrapper {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto; }
  .page-title-wrapper::before, .page-title-wrapper::after {
    display: table;
    content: ' '; }
  .page-title-wrapper::after {
    clear: both; }
  .column.main .page-title-wrapper, .main.columns .page-title-wrapper {
    margin-right: -1.0625rem;
    margin-left: -1.0625rem;
    max-width: initial;
    width: auto; }
    @media print, screen and (min-width: 40em) {
      .column.main .page-title-wrapper, .main.columns .page-title-wrapper {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .column.main .page-title-wrapper, .main.columns .page-title-wrapper {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .column.main .page-title-wrapper, .main.columns .page-title-wrapper {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .column.main .page-title-wrapper, .main.columns .page-title-wrapper {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .column.main .page-title-wrapper, .main.columns .page-title-wrapper {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
  .page-title-wrapper .page-title {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    text-align: left; }
    @media print, screen and (min-width: 40em) {
      .page-title-wrapper .page-title {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .page-title-wrapper .page-title:last-child:not(:first-child) {
      float: right; }
  .page-title-wrapper .price-box,
  .page-title-wrapper .order-status,
  .page-title-wrapper .order-date,
  .page-title-wrapper .actions-toolbar {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .page-title-wrapper .price-box,
      .page-title-wrapper .order-status,
      .page-title-wrapper .order-date,
      .page-title-wrapper .actions-toolbar {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .page-title-wrapper .price-box:last-child:not(:first-child),
    .page-title-wrapper .order-status:last-child:not(:first-child),
    .page-title-wrapper .order-date:last-child:not(:first-child),
    .page-title-wrapper .actions-toolbar:last-child:not(:first-child) {
      float: right; }
  @media screen and (min-width: 64em) {
    .page-title-wrapper.product .page-title {
      width: 66.66667%; } }
  @media screen and (min-width: 64em) {
    .page-title-wrapper.product .price-revert-wrapper {
      width: 33.33333%;
      text-align: right; } }
  .page-title-wrapper.toolbar-amount .page-title {
    padding-right: 0;
    padding-left: 0; }
  .page-layout-0column .page-title-wrapper,
  .page-layout-1column .page-title-wrapper,
  .cms-page-view .page-title-wrapper {
    padding-bottom: 0;
    margin-top: 1.5rem;
    margin-bottom: 1.75rem; }
    .page-layout-0column .page-title-wrapper .page-title,
    .page-layout-1column .page-title-wrapper .page-title,
    .cms-page-view .page-title-wrapper .page-title {
      margin-bottom: 0; }
      .page-layout-0column .page-title-wrapper .page-title span,
      .page-layout-1column .page-title-wrapper .page-title span,
      .cms-page-view .page-title-wrapper .page-title span {
        margin: 0;
        padding: 0; }
        .page-layout-0column .page-title-wrapper .page-title span:before,
        .page-layout-1column .page-title-wrapper .page-title span:before,
        .cms-page-view .page-title-wrapper .page-title span:before {
          display: none; }
    @media print, screen and (min-width: 48em) {
      .page-layout-0column .page-title-wrapper,
      .page-layout-1column .page-title-wrapper,
      .cms-page-view .page-title-wrapper {
        padding-bottom: 0.25rem;
        margin-top: 0;
        margin-bottom: 1.75rem; }
        .page-layout-0column .page-title-wrapper:not(.product),
        .page-layout-1column .page-title-wrapper:not(.product),
        .cms-page-view .page-title-wrapper:not(.product) {
          padding-bottom: 0; } }

.sidebar-main {
  margin-top: 0;
  margin-bottom: 1rem; }

.sidebar-main,
.sidebar-additional {
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    .sidebar-main,
    .sidebar-additional {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .sidebar-main:last-child:not(:first-child),
  .sidebar-additional:last-child:not(:first-child) {
    float: right; }
  @media print, screen and (min-width: 40em) {
    .page-layout-3columns .sidebar-main, .page-layout-3columns
    .sidebar-additional {
      width: 33.33333%; } }
  @media screen and (min-width: 64em) {
    .page-layout-3columns .sidebar-main, .page-layout-3columns
    .sidebar-additional {
      width: 25%; } }
  @media print, screen and (min-width: 40em) {
    .sidebar-main,
    .sidebar-additional {
      width: 33.33333%; } }
  @media screen and (min-width: 64em) {
    .sidebar-main,
    .sidebar-additional {
      width: 25%; } }

.sidebar-additional {
  display: none !important; }

.button {
  background-color: #181818;
  color: #fff;
  font-family: "silka-semibold-webfont", sans-serif;
  font-weight: 400; }
  .button:hover, .button:focus {
    background-color: black;
    color: #fff; }
  .button.primary {
    background-color: #1A2746;
    color: #fff; }
    .button.primary:hover, .button.primary:focus {
      background-color: rgba(48, 169, 94, 0.75);
      color: #fff; }
    .button.primary.hover, .button.primary.active {
      background-color: rgba(48, 169, 94, 0.75) !important; }
  .button.secondary {
    background-color: #181818;
    color: #fff; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: black;
      color: #fff; }
    .button.secondary.hover, .button.secondary.active {
      background-color: black !important; }
  .button.active {
    color: rgba(255, 255, 255, 0.8) !important; }
  .button.light, .button.hollow {
    border: 1px solid #d2d2d2;
    color: #181818;
    box-shadow: inset 0 0 0 1px #d2d2d2;
    transition: background-color 0.25s ease-out, box-shadow 0.25s ease-out, color 0.25s ease-out; }
    .button.light, .button.light:hover, .button.light:focus, .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.light:hover, .button.light:focus, .button.hollow:hover, .button.hollow:focus {
      border-color: dimgray;
      color: #0c0c0c;
      box-shadow: inset 0 0 0 1px dimgray; }
    .button.light.arrow, .button.hollow.arrow {
      background-image: url("../images/icon-arrow-next-gray.svg"); }
  .button.hollow.primary, .button.primary-light {
    border: 1px solid #7891cd;
    color: #1A2746;
    box-shadow: inset 0 0 0 1px #7891cd;
    transition: background-color 0.25s ease-out, box-shadow 0.25s ease-out, color 0.25s ease-out; }
    .button.hollow.primary, .button.hollow.primary:hover, .button.hollow.primary:focus, .button.primary-light, .button.primary-light:hover, .button.primary-light:focus {
      background-color: transparent; }
    .button.hollow.primary:hover, .button.hollow.primary:focus, .button.primary-light:hover, .button.primary-light:focus {
      border-color: #2c4277;
      color: #0d1423;
      box-shadow: inset 0 0 0 1px #2c4277; }
    .button.hollow.primary.arrow, .button.primary-light.arrow {
      background-image: url("../images/icon-arrow-next-light-blue.svg"); }
  .button.expand {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.arrow {
    text-align: left;
    padding-right: 2em;
    background-color: transparent;
    background-image: url("../images/icon-arrow-next-white.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 13px) 50%;
    background-size: 8px auto; }

button.action {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.82em 1em 0.87em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 50px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 1.1875rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #30A95E;
  color: #fff;
  font-family: "silka-semibold-webfont", sans-serif;
  letter-spacing: normal;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 0; }
  [data-whatinput='mouse'] button.action {
    outline: 0; }
  button.action:hover, button.action:focus {
    background-color: rgba(48, 169, 94, 0.75);
    color: #fff; }
  button.action:active {
    color: rgba(255, 255, 255, 0.8) !important; }

a.button {
  text-decoration: none; }

a.action.create, a.action.add {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.82em 1em 0.87em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 50px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 1.1875rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #30A95E;
  color: #fff;
  font-family: "silka-semibold-webfont", sans-serif;
  letter-spacing: normal;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 0;
  text-decoration: none; }
  [data-whatinput='mouse'] a.action.create, [data-whatinput='mouse'] a.action.add {
    outline: 0; }
  a.action.create:hover, a.action.create:focus, a.action.add:hover, a.action.add:focus {
    background-color: rgba(48, 169, 94, 0.75);
    color: #fff; }
  a.action.create:active, a.action.add:active {
    color: rgba(255, 255, 255, 0.8) !important; }

.action.back {
  display: inline-block;
  margin-top: 1rem;
  text-decoration: none;
  background: none; }
  .action.back:before {
    display: inline-block;
    vertical-align: middle;
    content: '';
    width: 0.5rem;
    height: 0.75rem;
    background-image: url("../images/icon-arrow-next-light-blue.svg");
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 0.3125rem;
    transform: rotate(180deg); }
  .action.back span {
    display: inline-block;
    vertical-align: middle; }
  .action.back:hover span {
    text-decoration: underline; }
  .action.back:hover:before {
    text-decoration: none; }

.button-link {
  display: inline-block;
  vertical-align: middle;
  font-family: "silka-regular-webfont", sans-serif;
  font-size: 1rem;
  color: #181818;
  padding: 0;
  line-height: 1.2;
  border: 0;
  background-color: transparent;
  border-bottom: 1px solid rgba(24, 24, 24, 0.15);
  cursor: pointer; }
  .button-link:disabled {
    background-color: transparent;
    opacity: .5; }

.page.messages {
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    .page.messages {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .page.messages:last-child:not(:first-child) {
    float: right; }
  .page.messages .message-close {
    display: none; }
  .page.messages .message {
    margin-bottom: 1rem; }

.message {
  display: block;
  font-size: 0.90312rem;
  font-weight: 400;
  line-height: 1.2;
  font-family: "silka-regular-webfont", sans-serif;
  letter-spacing: normal;
  clear: both;
  padding: 1.05rem 1.2rem 1.25rem 3rem;
  color: white;
  border: 1px solid #1A2746;
  background-color: #1A2746;
  background-repeat: no-repeat;
  background-position: 1.25rem 1.4rem;
  background-image: url("../images/icon-info.svg");
  background-size: 14px 14px; }
  @media print, screen and (min-width: 48em) {
    .message {
      font-size: 1.0625rem; } }
  .message.success {
    color: #181818;
    border-color: #30A95E;
    background-size: 19px 14px;
    background-color: white;
    background-image: url("../images/icon-success.svg"); }
  .message.error, .checkout-steps .checkout-payment-method .message {
    color: #181818;
    border-color: #D41367;
    background-size: 14px 14px;
    background-color: white;
    background-image: url("../images/icon-error.svg"); }
  .message.warning {
    color: white;
    border-color: #D41367;
    background-size: 14px 14px;
    background-color: #D41367;
    background-image: url("../images/icon-error-white.svg"); }
  .message.success a, .message.error a, .checkout-steps .checkout-payment-method .message a {
    color: #181818; }
    .message.success a:hover, .message.success a:focus, .message.success a:active, .message.error a:hover, .checkout-steps .checkout-payment-method .message a:hover, .message.error a:focus, .checkout-steps .checkout-payment-method .message a:focus, .message.error a:active, .checkout-steps .checkout-payment-method .message a:active {
      color: #181818; }
  .message a {
    color: white; }
    .message a:hover, .message a:focus, .message a:active {
      color: white; }

.loading-mask {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.3);
  background-image: url("../images/ripple.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  z-index: 555;
  display: block; }
  .ie11 .loading-mask,
  .no-smil .loading-mask {
    background-image: url("../images/ripple.gif"); }
  .loading-mask .loading-mssg {
    display: block;
    position: absolute;
    top: 10rem;
    left: 50%;
    transform: translateX(-50%);
    color: #1A2746;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-weight: 400;
    font-size: 100%;
    font-style: normal; }
    .loading-mask .loading-mssg .loading-img {
      margin-right: .5rem; }
    @media print, screen and (min-width: 40em) {
      .loading-mask .loading-mssg {
        font-size: 150%; } }
    @media screen and (min-width: 64em) {
      .loading-mask .loading-mssg {
        font-size: 180%;
        top: 15rem; } }
  .loading-mask .loader,
  .loading-mask img,
  .loading-mask p,
  .loading-mask span {
    display: none; }

body > .loading-mask {
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.3);
  background-image: url("../images/ripple.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  z-index: 9999; }
  .ie11 body > .loading-mask,
  .no-smil body > .loading-mask {
    background-image: url("../images/ripple.gif"); }
  body > .loading-mask .loading-mssg {
    display: block;
    position: absolute;
    top: 10rem;
    left: 50%;
    transform: translateX(-50%);
    color: #1A2746;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-weight: 400;
    font-size: 100%;
    font-style: normal; }
    body > .loading-mask .loading-mssg .loading-img {
      margin-right: .5rem; }
    @media print, screen and (min-width: 40em) {
      body > .loading-mask .loading-mssg {
        font-size: 150%; } }
    @media screen and (min-width: 64em) {
      body > .loading-mask .loading-mssg {
        font-size: 180%;
        top: 15rem; } }
  body > .loading-mask .loader {
    display: none; }

.load.indicator {
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.3);
  background-image: url("../images/ripple.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  z-index: 9999; }
  .ie11 .load.indicator,
  .no-smil .load.indicator {
    background-image: url("../images/ripple.gif"); }
  .load.indicator .loading-mssg {
    display: block;
    position: absolute;
    top: 10rem;
    left: 50%;
    transform: translateX(-50%);
    color: #1A2746;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-weight: 400;
    font-size: 100%;
    font-style: normal; }
    .load.indicator .loading-mssg .loading-img {
      margin-right: .5rem; }
    @media print, screen and (min-width: 40em) {
      .load.indicator .loading-mssg {
        font-size: 150%; } }
    @media screen and (min-width: 64em) {
      .load.indicator .loading-mssg {
        font-size: 180%;
        top: 15rem; } }
  .load.indicator span,
  .load.indicator div {
    display: none; }

._block-content-loading,
[data-bind="blockLoader: isLoading"] {
  position: relative; }

.stock {
  white-space: nowrap;
  color: rgba(24, 24, 24, 0.9);
  font-size: 0.8125rem;
  font-weight: inherit;
  text-transform: none;
  font-family: "silka-regular-webfont", sans-serif;
  letter-spacing: normal;
  margin: 0.25rem 0; }
  .stock span {
    display: inline-block;
    padding: 0 0 0 20px;
    background-size: 13px auto; }
  .stock.available span {
    color: rgba(24, 24, 24, 0.9);
    background: url("../images/icon-valid-black.svg") 0 50% no-repeat;
    background-size: 14px auto; }
  .stock.unavailable span {
    color: #989898;
    background: url("../images/icon-unavailable.svg") 0 50% no-repeat;
    background-size: 14px auto; }
  .stock.backorder span {
    white-space: normal;
    text-align: left;
    min-height: 17px;
    color: rgba(24, 24, 24, 0.9);
    padding-left: 22px;
    background: url("../images/icon-stock-delivery.png") 0 50% no-repeat;
    background-size: 17px 17px; }

.action.search, #algolia-searchbox #algolia-glass,
#algolia-searchbox .ais-search-box--magnifier-wrapper {
  display: inline-block;
  vertical-align: middle;
  width: 1.375rem;
  height: 56px;
  background: url("../images/icon-search.svg") no-repeat;
  background-position: 0 50%;
  background-size: 1.375rem auto;
  opacity: 1;
  transition: opacity .5s ease; }
  .action.search:hover, #algolia-searchbox #algolia-glass:hover,
  #algolia-searchbox .ais-search-box--magnifier-wrapper:hover, .action.search:active, #algolia-searchbox #algolia-glass:active,
  #algolia-searchbox .ais-search-box--magnifier-wrapper:active {
    opacity: 1; }
  .action.search > span, #algolia-searchbox #algolia-glass > span, #algolia-searchbox .ais-search-box--magnifier-wrapper > span {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }
  .action.search + .action.showcart, #algolia-searchbox #algolia-glass + .action.showcart, #algolia-searchbox .ais-search-box--magnifier-wrapper + .action.showcart {
    margin-left: .75rem; }

.icn-map {
  display: inline-block;
  vertical-align: middle;
  width: 1.125rem;
  height: 1.6875rem;
  background: url("../images/icn-map.svg") no-repeat;
  background-size: cover; }

.mobile-account-icon {
  display: inline-block;
  vertical-align: middle;
  width: 1.375rem;
  height: 1.125rem;
  background: url("../images/icon-user.svg") no-repeat 50%;
  font-size: 0;
  background-size: 100% auto; }

.icon-q {
  display: inline-block;
  vertical-align: middle;
  width: 1.0625rem;
  height: 1.0625rem;
  font-size: 0.6875rem;
  line-height: 0.875rem;
  font-weight: 700;
  border: 1px solid rgba(24, 24, 24, 0.25);
  font-style: normal;
  font-family: "silka-semibold-webfont", sans-serif;
  letter-spacing: normal;
  text-align: center;
  border-radius: 50%;
  background: white; }

.clear-cross, .mobile-search-bar #algolia-searchbox .clear-cross, #algolia-searchbox .ais-search-box--reset-wrapper {
  display: inline-block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  background: url("../images/icon-deselect.svg") no-repeat 50%;
  background-size: 100% auto;
  cursor: pointer; }

.action.showcart {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
  transition: background-color .3s ease;
  width: 2.75rem;
  height: 2.75rem;
  background: url("../images/icon-cart.svg") 0 50% no-repeat;
  background-size: 100% auto; }
  .action.showcart .is-stuck,
  .tab-bar .action.showcart {
    margin-top: 0; }
  .action.showcart:hover, .action.showcart:active, .action.showcart.has-item {
    background-image: url("../images/icon-cart.svg"); }
  .action.showcart.has-item {
    background-image: url("../images/icon-cart.svg"); }
  .action.showcart > .text {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }
  .action.showcart .counter {
    bottom: -15%;
    right: -15%; }
  .action.showcart .counter {
    position: absolute;
    min-width: 1.125rem;
    height: 1.125rem;
    line-height: 1.125rem;
    display: inline-block;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    color: white;
    background-color: #30A95E;
    box-shadow: none;
    border-radius: 50%;
    font-size: 0.75rem;
    font-family: inherit;
    text-align: center;
    font-weight: normal; }
    body.is-sticky .action.showcart .counter {
      bottom: auto; }
    .action.showcart .counter .counter-label {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }
    .action.showcart .counter.empty {
      display: none; }

.action.action-delete, .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-delete {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 26px;
  opacity: .8;
  background: url("../images/icon-delete.svg") no-repeat;
  background-size: contain;
  cursor: pointer; }
  .action.action-delete span, .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-delete span {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }
  .action.action-delete:hover, .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-delete:hover, .action.action-delete:active, .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-delete:active {
    opacity: 1; }

.action.towishlist,
.action.wishlist-remove-product {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 1.5625rem;
  height: 1.4375rem;
  background: url("../images/icon-wishlist.svg") no-repeat;
  background-size: 100% auto;
  cursor: pointer !important; }
  .action.towishlist:hover, .action.towishlist:active, .action.towishlist.has-item,
  .action.wishlist-remove-product:hover,
  .action.wishlist-remove-product:active,
  .action.wishlist-remove-product.has-item {
    background-image: url("../images/icon-wishlist-active.svg"); }
    .action.towishlist:hover span, .action.towishlist:active span, .action.towishlist.has-item span,
    .action.wishlist-remove-product:hover span,
    .action.wishlist-remove-product:active span,
    .action.wishlist-remove-product.has-item span {
      opacity: 1; }
  .action.towishlist.active,
  .action.wishlist-remove-product.active {
    background-image: url("../images/icon-wishlist-active.svg"); }
  .action.towishlist > span,
  .action.wishlist-remove-product > span {
    opacity: 0;
    position: absolute;
    color: white;
    top: 100%;
    right: 0;
    margin-top: 0.3125rem;
    padding: 0.3125rem 0.625rem;
    font-size: 0.6875rem;
    font-family: inherit;
    font-weight: inherit;
    line-height: 1.2;
    white-space: nowrap;
    background: #181818;
    transition: opacity .3s ease; }
    @media print, screen and (min-width: 48em) {
      .action.towishlist > span,
      .action.wishlist-remove-product > span {
        right: auto;
        left: 50%;
        transform: translateX(-50%); } }
    .action.towishlist > span:after,
    .action.wishlist-remove-product > span:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent #181818 transparent;
      position: absolute;
      bottom: 100%;
      right: 0.78125rem;
      transform: translateX(50%); }
      @media print, screen and (min-width: 48em) {
        .action.towishlist > span:after,
        .action.wishlist-remove-product > span:after {
          right: auto;
          left: 50%;
          transform: translateX(-50%); } }

.action.wishlist-remove-product {
  background-image: url("../images/icon-wishlist-remove.svg"); }
  .action.wishlist-remove-product:hover, .action.wishlist-remove-product:active, .action.wishlist-remove-product.has-item {
    background-image: url("../images/icon-wishlist-remove.svg"); }

.pages-label {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.ais-Pagination-list,
.convert-paging .pages-items {
  margin: 0;
  list-style: none;
  display: flex;
  position: relative;
  text-align: center;
  padding: 0 0 55px;
  margin: 2px 0 11px; }
  .ais-Pagination-list li,
  .convert-paging .pages-items li {
    padding: 0; }
    .ais-Pagination-list li:after,
    .convert-paging .pages-items li:after {
      display: none; }
  @media screen and (min-width: 64em) {
    .ais-Pagination-list,
    .convert-paging .pages-items {
      padding: 0 185px; } }
  @media screen and (min-width: 75em) {
    .ais-Pagination-list,
    .convert-paging .pages-items {
      padding: 0 220px; } }
  @media screen and (min-width: 64em) {
    .ais-Pagination-list,
    .convert-paging .pages-items {
      margin: 57px 0; } }
  @media screen and (min-width: 75em) {
    .ais-Pagination-list,
    .convert-paging .pages-items {
      margin: 57px 0; } }
  @media screen and (max-width: 47.9375em) {
    .ais-Pagination-list,
    .convert-paging .pages-items {
      flex-wrap: wrap; } }
  @media print, screen and (min-width: 48em) {
    .ais-Pagination-list,
    .convert-paging .pages-items {
      justify-content: center;
      align-items: center; } }
  .ais-Pagination-list .ais-Pagination-item,
  .ais-Pagination-list .item,
  .convert-paging .pages-items .ais-Pagination-item,
  .convert-paging .pages-items .item {
    user-select: none;
    margin: 0 5px 5px;
    vertical-align: top; }
    @media screen and (min-width: 64em) {
      .ais-Pagination-list .ais-Pagination-item,
      .ais-Pagination-list .item,
      .convert-paging .pages-items .ais-Pagination-item,
      .convert-paging .pages-items .item {
        margin: 0 5px 5px; } }
    @media screen and (min-width: 75em) {
      .ais-Pagination-list .ais-Pagination-item,
      .ais-Pagination-list .item,
      .convert-paging .pages-items .ais-Pagination-item,
      .convert-paging .pages-items .item {
        margin: 0 5px 5px; } }
    .ais-Pagination-list .ais-Pagination-item .label,
    .ais-Pagination-list .item .label,
    .convert-paging .pages-items .ais-Pagination-item .label,
    .convert-paging .pages-items .item .label {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }
    .ais-Pagination-list .ais-Pagination-item .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item .link,
    .ais-Pagination-list .ais-Pagination-item .page,
    .ais-Pagination-list .item .ais-Pagination-link,
    .ais-Pagination-list .item .link,
    .ais-Pagination-list .item .page,
    .convert-paging .pages-items .ais-Pagination-item .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item .link,
    .convert-paging .pages-items .ais-Pagination-item .page,
    .convert-paging .pages-items .item .ais-Pagination-link,
    .convert-paging .pages-items .item .link,
    .convert-paging .pages-items .item .page {
      display: block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      font-family: "silka-regular-webfont", sans-serif;
      font-weight: 600;
      text-align: center;
      border-radius: 50%;
      color: #181818;
      text-decoration: none;
      border: none;
      background-color: #F1EFED;
      cursor: pointer; }
      @media screen and (min-width: 64em) {
        .ais-Pagination-list .ais-Pagination-item .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item .link,
        .ais-Pagination-list .ais-Pagination-item .page,
        .ais-Pagination-list .item .ais-Pagination-link,
        .ais-Pagination-list .item .link,
        .ais-Pagination-list .item .page,
        .convert-paging .pages-items .ais-Pagination-item .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item .link,
        .convert-paging .pages-items .ais-Pagination-item .page,
        .convert-paging .pages-items .item .ais-Pagination-link,
        .convert-paging .pages-items .item .link,
        .convert-paging .pages-items .item .page {
          width: 45px; } }
      @media screen and (min-width: 75em) {
        .ais-Pagination-list .ais-Pagination-item .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item .link,
        .ais-Pagination-list .ais-Pagination-item .page,
        .ais-Pagination-list .item .ais-Pagination-link,
        .ais-Pagination-list .item .link,
        .ais-Pagination-list .item .page,
        .convert-paging .pages-items .ais-Pagination-item .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item .link,
        .convert-paging .pages-items .ais-Pagination-item .page,
        .convert-paging .pages-items .item .ais-Pagination-link,
        .convert-paging .pages-items .item .link,
        .convert-paging .pages-items .item .page {
          width: 55px; } }
      @media screen and (min-width: 64em) {
        .ais-Pagination-list .ais-Pagination-item .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item .link,
        .ais-Pagination-list .ais-Pagination-item .page,
        .ais-Pagination-list .item .ais-Pagination-link,
        .ais-Pagination-list .item .link,
        .ais-Pagination-list .item .page,
        .convert-paging .pages-items .ais-Pagination-item .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item .link,
        .convert-paging .pages-items .ais-Pagination-item .page,
        .convert-paging .pages-items .item .ais-Pagination-link,
        .convert-paging .pages-items .item .link,
        .convert-paging .pages-items .item .page {
          height: 45px; } }
      @media screen and (min-width: 75em) {
        .ais-Pagination-list .ais-Pagination-item .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item .link,
        .ais-Pagination-list .ais-Pagination-item .page,
        .ais-Pagination-list .item .ais-Pagination-link,
        .ais-Pagination-list .item .link,
        .ais-Pagination-list .item .page,
        .convert-paging .pages-items .ais-Pagination-item .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item .link,
        .convert-paging .pages-items .ais-Pagination-item .page,
        .convert-paging .pages-items .item .ais-Pagination-link,
        .convert-paging .pages-items .item .link,
        .convert-paging .pages-items .item .page {
          height: 55px; } }
      @media screen and (min-width: 64em) {
        .ais-Pagination-list .ais-Pagination-item .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item .link,
        .ais-Pagination-list .ais-Pagination-item .page,
        .ais-Pagination-list .item .ais-Pagination-link,
        .ais-Pagination-list .item .link,
        .ais-Pagination-list .item .page,
        .convert-paging .pages-items .ais-Pagination-item .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item .link,
        .convert-paging .pages-items .ais-Pagination-item .page,
        .convert-paging .pages-items .item .ais-Pagination-link,
        .convert-paging .pages-items .item .link,
        .convert-paging .pages-items .item .page {
          line-height: 45px; } }
      @media screen and (min-width: 75em) {
        .ais-Pagination-list .ais-Pagination-item .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item .link,
        .ais-Pagination-list .ais-Pagination-item .page,
        .ais-Pagination-list .item .ais-Pagination-link,
        .ais-Pagination-list .item .link,
        .ais-Pagination-list .item .page,
        .convert-paging .pages-items .ais-Pagination-item .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item .link,
        .convert-paging .pages-items .ais-Pagination-item .page,
        .convert-paging .pages-items .item .ais-Pagination-link,
        .convert-paging .pages-items .item .link,
        .convert-paging .pages-items .item .page {
          line-height: 55px; } }
      .ais-Pagination-list .ais-Pagination-item .ais-Pagination-link:hover, .ais-Pagination-list .ais-Pagination-item .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item .link:hover,
      .ais-Pagination-list .ais-Pagination-item .link:focus,
      .ais-Pagination-list .ais-Pagination-item .page:hover,
      .ais-Pagination-list .ais-Pagination-item .page:focus,
      .ais-Pagination-list .item .ais-Pagination-link:hover,
      .ais-Pagination-list .item .ais-Pagination-link:focus,
      .ais-Pagination-list .item .link:hover,
      .ais-Pagination-list .item .link:focus,
      .ais-Pagination-list .item .page:hover,
      .ais-Pagination-list .item .page:focus,
      .convert-paging .pages-items .ais-Pagination-item .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item .link:hover,
      .convert-paging .pages-items .ais-Pagination-item .link:focus,
      .convert-paging .pages-items .ais-Pagination-item .page:hover,
      .convert-paging .pages-items .ais-Pagination-item .page:focus,
      .convert-paging .pages-items .item .ais-Pagination-link:hover,
      .convert-paging .pages-items .item .ais-Pagination-link:focus,
      .convert-paging .pages-items .item .link:hover,
      .convert-paging .pages-items .item .link:focus,
      .convert-paging .pages-items .item .page:hover,
      .convert-paging .pages-items .item .page:focus {
        text-decoration: none;
        color: white;
        border-color: #454343;
        background-color: #1A2746;
        box-shadow: none; }
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__active .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__active .page, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--selected .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--selected .page, .ais-Pagination-list .ais-Pagination-item.current .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.current .page,
    .ais-Pagination-list .item.ais-Pagination-item__active .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item__active .page,
    .ais-Pagination-list .item.ais-Pagination-item--selected .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item--selected .page,
    .ais-Pagination-list .item.current .ais-Pagination-link,
    .ais-Pagination-list .item.current .page,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__active .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__active .page,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--selected .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--selected .page,
    .convert-paging .pages-items .ais-Pagination-item.current .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.current .page,
    .convert-paging .pages-items .item.ais-Pagination-item__active .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item__active .page,
    .convert-paging .pages-items .item.ais-Pagination-item--selected .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item--selected .page,
    .convert-paging .pages-items .item.current .ais-Pagination-link,
    .convert-paging .pages-items .item.current .page {
      text-decoration: none;
      color: white;
      border-color: #454343;
      background-color: #1A2746;
      box-shadow: none; }
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage:before, .ais-Pagination-list .ais-Pagination-item.pages-item-next:before, .ais-Pagination-list .ais-Pagination-item.pages-item-previous:before,
    .ais-Pagination-list .item.ais-Pagination-item__previous:before,
    .ais-Pagination-list .item.ais-Pagination-item__next:before,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage:before,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage:before,
    .ais-Pagination-list .item.pages-item-next:before,
    .ais-Pagination-list .item.pages-item-previous:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage:before,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next:before,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous:before,
    .convert-paging .pages-items .item.ais-Pagination-item__previous:before,
    .convert-paging .pages-items .item.ais-Pagination-item__next:before,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage:before,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage:before,
    .convert-paging .pages-items .item.pages-item-next:before,
    .convert-paging .pages-items .item.pages-item-previous:before {
      content: '';
      width: 6px;
      height: 12px;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      left: 18px;
      background-image: url("../images/icon-arrow-next-white.svg");
      background-size: contain;
      background-repeat: no-repeat; }
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-next .link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-next .action, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action,
    .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item__previous .link,
    .ais-Pagination-list .item.ais-Pagination-item__previous .action,
    .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item__next .link,
    .ais-Pagination-list .item.ais-Pagination-item__next .action,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage .link,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage .action,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage .link,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage .action,
    .ais-Pagination-list .item.pages-item-next .ais-Pagination-link,
    .ais-Pagination-list .item.pages-item-next .link,
    .ais-Pagination-list .item.pages-item-next .action,
    .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link,
    .ais-Pagination-list .item.pages-item-previous .link,
    .ais-Pagination-list .item.pages-item-previous .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action,
    .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item__previous .link,
    .convert-paging .pages-items .item.ais-Pagination-item__previous .action,
    .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item__next .link,
    .convert-paging .pages-items .item.ais-Pagination-item__next .action,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action,
    .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link,
    .convert-paging .pages-items .item.pages-item-next .link,
    .convert-paging .pages-items .item.pages-item-next .action,
    .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link,
    .convert-paging .pages-items .item.pages-item-previous .link,
    .convert-paging .pages-items .item.pages-item-previous .action {
      min-width: 160px;
      font-size: 14px;
      padding-top: 1.1em;
      padding-bottom: 1em;
      position: static !important;
      width: auto;
      height: auto;
      overflow: visible;
      clip: auto;
      max-width: 49%;
      height: auto;
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 1rem 0;
      padding: 0.82em 1em 0.87em;
      -webkit-appearance: none;
      border: 1px solid transparent;
      border-radius: 50px;
      transition: background-color 0.25s ease-out, color 0.25s ease-out;
      font-size: 1.1875rem;
      line-height: 1;
      text-align: center;
      cursor: pointer;
      background-color: #30A95E;
      color: #fff;
      font-family: "silka-semibold-webfont", sans-serif;
      letter-spacing: normal;
      font-weight: 400;
      text-transform: none;
      margin-bottom: 0;
      text-decoration: none;
      color: #fff !important;
      letter-spacing: 0;
      text-transform: none;
      background-color: #1A2746 !important;
      cursor: pointer; }
      @media screen and (min-width: 64em) {
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .action, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__next .link,
        .ais-Pagination-list .item.ais-Pagination-item__next .action,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .action,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .action,
        .ais-Pagination-list .item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-next .link,
        .ais-Pagination-list .item.pages-item-next .action,
        .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-previous .link,
        .ais-Pagination-list .item.pages-item-previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-next .link,
        .convert-paging .pages-items .item.pages-item-next .action,
        .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-previous .link,
        .convert-paging .pages-items .item.pages-item-previous .action {
          min-width: 180px; } }
      @media screen and (min-width: 75em) {
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .action, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__next .link,
        .ais-Pagination-list .item.ais-Pagination-item__next .action,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .action,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .action,
        .ais-Pagination-list .item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-next .link,
        .ais-Pagination-list .item.pages-item-next .action,
        .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-previous .link,
        .ais-Pagination-list .item.pages-item-previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-next .link,
        .convert-paging .pages-items .item.pages-item-next .action,
        .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-previous .link,
        .convert-paging .pages-items .item.pages-item-previous .action {
          min-width: 215px; } }
      @media screen and (min-width: 64em) {
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .action, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__next .link,
        .ais-Pagination-list .item.ais-Pagination-item__next .action,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .action,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .action,
        .ais-Pagination-list .item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-next .link,
        .ais-Pagination-list .item.pages-item-next .action,
        .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-previous .link,
        .ais-Pagination-list .item.pages-item-previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-next .link,
        .convert-paging .pages-items .item.pages-item-next .action,
        .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-previous .link,
        .convert-paging .pages-items .item.pages-item-previous .action {
          font-size: 16px; } }
      @media screen and (min-width: 75em) {
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .action, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__next .link,
        .ais-Pagination-list .item.ais-Pagination-item__next .action,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .action,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .action,
        .ais-Pagination-list .item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-next .link,
        .ais-Pagination-list .item.pages-item-next .action,
        .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-previous .link,
        .ais-Pagination-list .item.pages-item-previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-next .link,
        .convert-paging .pages-items .item.pages-item-next .action,
        .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-previous .link,
        .convert-paging .pages-items .item.pages-item-previous .action {
          font-size: 18px; } }
      @media screen and (min-width: 64em) {
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .action, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__next .link,
        .ais-Pagination-list .item.ais-Pagination-item__next .action,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .action,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .action,
        .ais-Pagination-list .item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-next .link,
        .ais-Pagination-list .item.pages-item-next .action,
        .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-previous .link,
        .ais-Pagination-list .item.pages-item-previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-next .link,
        .convert-paging .pages-items .item.pages-item-next .action,
        .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-previous .link,
        .convert-paging .pages-items .item.pages-item-previous .action {
          padding-top: 1.1em; } }
      @media screen and (min-width: 75em) {
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .action, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__next .link,
        .ais-Pagination-list .item.ais-Pagination-item__next .action,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .action,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .action,
        .ais-Pagination-list .item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-next .link,
        .ais-Pagination-list .item.pages-item-next .action,
        .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-previous .link,
        .ais-Pagination-list .item.pages-item-previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-next .link,
        .convert-paging .pages-items .item.pages-item-next .action,
        .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-previous .link,
        .convert-paging .pages-items .item.pages-item-previous .action {
          padding-top: 1.1em; } }
      @media screen and (min-width: 64em) {
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .action, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__next .link,
        .ais-Pagination-list .item.ais-Pagination-item__next .action,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .action,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .action,
        .ais-Pagination-list .item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-next .link,
        .ais-Pagination-list .item.pages-item-next .action,
        .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-previous .link,
        .ais-Pagination-list .item.pages-item-previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-next .link,
        .convert-paging .pages-items .item.pages-item-next .action,
        .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-previous .link,
        .convert-paging .pages-items .item.pages-item-previous .action {
          padding-bottom: 1em; } }
      @media screen and (min-width: 75em) {
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .action, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__next .link,
        .ais-Pagination-list .item.ais-Pagination-item__next .action,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .action,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .action,
        .ais-Pagination-list .item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-next .link,
        .ais-Pagination-list .item.pages-item-next .action,
        .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-previous .link,
        .ais-Pagination-list .item.pages-item-previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-next .link,
        .convert-paging .pages-items .item.pages-item-next .action,
        .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-previous .link,
        .convert-paging .pages-items .item.pages-item-previous .action {
          padding-bottom: 1em; } }
      [data-whatinput='mouse'] .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link, [data-whatinput='mouse']
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link, [data-whatinput='mouse']
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action, [data-whatinput='mouse'] .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link, [data-whatinput='mouse']
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link, [data-whatinput='mouse']
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action, [data-whatinput='mouse'] .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link, [data-whatinput='mouse']
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link, [data-whatinput='mouse']
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action, [data-whatinput='mouse'] .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link, [data-whatinput='mouse']
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link, [data-whatinput='mouse']
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action, [data-whatinput='mouse'] .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link, [data-whatinput='mouse']
      .ais-Pagination-list .ais-Pagination-item.pages-item-next .link, [data-whatinput='mouse']
      .ais-Pagination-list .ais-Pagination-item.pages-item-next .action, [data-whatinput='mouse'] .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link, [data-whatinput='mouse']
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link, [data-whatinput='mouse']
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action, [data-whatinput='mouse']
      .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link, [data-whatinput='mouse']
      .ais-Pagination-list .item.ais-Pagination-item__previous .link, [data-whatinput='mouse']
      .ais-Pagination-list .item.ais-Pagination-item__previous .action, [data-whatinput='mouse']
      .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link, [data-whatinput='mouse']
      .ais-Pagination-list .item.ais-Pagination-item__next .link, [data-whatinput='mouse']
      .ais-Pagination-list .item.ais-Pagination-item__next .action, [data-whatinput='mouse']
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link, [data-whatinput='mouse']
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .link, [data-whatinput='mouse']
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .action, [data-whatinput='mouse']
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link, [data-whatinput='mouse']
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .link, [data-whatinput='mouse']
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .action, [data-whatinput='mouse']
      .ais-Pagination-list .item.pages-item-next .ais-Pagination-link, [data-whatinput='mouse']
      .ais-Pagination-list .item.pages-item-next .link, [data-whatinput='mouse']
      .ais-Pagination-list .item.pages-item-next .action, [data-whatinput='mouse']
      .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link, [data-whatinput='mouse']
      .ais-Pagination-list .item.pages-item-previous .link, [data-whatinput='mouse']
      .ais-Pagination-list .item.pages-item-previous .action, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link, [data-whatinput='mouse']
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action, [data-whatinput='mouse']
      .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link, [data-whatinput='mouse']
      .convert-paging .pages-items .item.ais-Pagination-item__previous .link, [data-whatinput='mouse']
      .convert-paging .pages-items .item.ais-Pagination-item__previous .action, [data-whatinput='mouse']
      .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link, [data-whatinput='mouse']
      .convert-paging .pages-items .item.ais-Pagination-item__next .link, [data-whatinput='mouse']
      .convert-paging .pages-items .item.ais-Pagination-item__next .action, [data-whatinput='mouse']
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link, [data-whatinput='mouse']
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link, [data-whatinput='mouse']
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action, [data-whatinput='mouse']
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link, [data-whatinput='mouse']
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link, [data-whatinput='mouse']
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action, [data-whatinput='mouse']
      .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link, [data-whatinput='mouse']
      .convert-paging .pages-items .item.pages-item-next .link, [data-whatinput='mouse']
      .convert-paging .pages-items .item.pages-item-next .action, [data-whatinput='mouse']
      .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link, [data-whatinput='mouse']
      .convert-paging .pages-items .item.pages-item-previous .link, [data-whatinput='mouse']
      .convert-paging .pages-items .item.pages-item-previous .action {
        outline: 0; }
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action:focus, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link:hover, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next .link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next .link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next .action:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next .action:focus, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link:hover, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__previous .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__previous .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__previous .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item__previous .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__next .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__next .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__next .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item__next .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .action:focus,
      .ais-Pagination-list .item.pages-item-next .ais-Pagination-link:hover,
      .ais-Pagination-list .item.pages-item-next .ais-Pagination-link:focus,
      .ais-Pagination-list .item.pages-item-next .link:hover,
      .ais-Pagination-list .item.pages-item-next .link:focus,
      .ais-Pagination-list .item.pages-item-next .action:hover,
      .ais-Pagination-list .item.pages-item-next .action:focus,
      .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link:hover,
      .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link:focus,
      .ais-Pagination-list .item.pages-item-previous .link:hover,
      .ais-Pagination-list .item.pages-item-previous .link:focus,
      .ais-Pagination-list .item.pages-item-previous .action:hover,
      .ais-Pagination-list .item.pages-item-previous .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__next .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__next .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__next .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__next .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action:focus,
      .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.pages-item-next .link:hover,
      .convert-paging .pages-items .item.pages-item-next .link:focus,
      .convert-paging .pages-items .item.pages-item-next .action:hover,
      .convert-paging .pages-items .item.pages-item-next .action:focus,
      .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.pages-item-previous .link:hover,
      .convert-paging .pages-items .item.pages-item-previous .link:focus,
      .convert-paging .pages-items .item.pages-item-previous .action:hover,
      .convert-paging .pages-items .item.pages-item-previous .action:focus {
        background-color: rgba(48, 169, 94, 0.75);
        color: #fff; }
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action:active, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next .link:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next .action:active, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action:active,
      .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item__previous .link:active,
      .ais-Pagination-list .item.ais-Pagination-item__previous .action:active,
      .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item__next .link:active,
      .ais-Pagination-list .item.ais-Pagination-item__next .action:active,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .link:active,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .action:active,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .link:active,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .action:active,
      .ais-Pagination-list .item.pages-item-next .ais-Pagination-link:active,
      .ais-Pagination-list .item.pages-item-next .link:active,
      .ais-Pagination-list .item.pages-item-next .action:active,
      .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link:active,
      .ais-Pagination-list .item.pages-item-previous .link:active,
      .ais-Pagination-list .item.pages-item-previous .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__next .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__next .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action:active,
      .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link:active,
      .convert-paging .pages-items .item.pages-item-next .link:active,
      .convert-paging .pages-items .item.pages-item-next .action:active,
      .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link:active,
      .convert-paging .pages-items .item.pages-item-previous .link:active,
      .convert-paging .pages-items .item.pages-item-previous .action:active {
        color: rgba(255, 255, 255, 0.8) !important; }
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action:hover, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next .link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next .action:hover, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__previous .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__previous .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__next .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__next .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .action:hover,
      .ais-Pagination-list .item.pages-item-next .ais-Pagination-link:hover,
      .ais-Pagination-list .item.pages-item-next .link:hover,
      .ais-Pagination-list .item.pages-item-next .action:hover,
      .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link:hover,
      .ais-Pagination-list .item.pages-item-previous .link:hover,
      .ais-Pagination-list .item.pages-item-previous .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__next .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__next .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action:hover,
      .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.pages-item-next .link:hover,
      .convert-paging .pages-items .item.pages-item-next .action:hover,
      .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.pages-item-previous .link:hover,
      .convert-paging .pages-items .item.pages-item-previous .action:hover {
        background-color: #3c4762 !important; }
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action:focus, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next .link:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next .link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next .action:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next .action:focus, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__previous .link:active,
      .ais-Pagination-list .item.ais-Pagination-item__previous .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__previous .action:active,
      .ais-Pagination-list .item.ais-Pagination-item__previous .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__next .link:active,
      .ais-Pagination-list .item.ais-Pagination-item__next .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__next .action:active,
      .ais-Pagination-list .item.ais-Pagination-item__next .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .link:active,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .action:active,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .link:active,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .action:active,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage .action:focus,
      .ais-Pagination-list .item.pages-item-next .ais-Pagination-link:active,
      .ais-Pagination-list .item.pages-item-next .ais-Pagination-link:focus,
      .ais-Pagination-list .item.pages-item-next .link:active,
      .ais-Pagination-list .item.pages-item-next .link:focus,
      .ais-Pagination-list .item.pages-item-next .action:active,
      .ais-Pagination-list .item.pages-item-next .action:focus,
      .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link:active,
      .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link:focus,
      .ais-Pagination-list .item.pages-item-previous .link:active,
      .ais-Pagination-list .item.pages-item-previous .link:focus,
      .ais-Pagination-list .item.pages-item-previous .action:active,
      .ais-Pagination-list .item.pages-item-previous .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item__previous .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__next .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__next .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__next .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item__next .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action:focus,
      .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link:active,
      .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.pages-item-next .link:active,
      .convert-paging .pages-items .item.pages-item-next .link:focus,
      .convert-paging .pages-items .item.pages-item-next .action:active,
      .convert-paging .pages-items .item.pages-item-next .action:focus,
      .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link:active,
      .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.pages-item-previous .link:active,
      .convert-paging .pages-items .item.pages-item-previous .link:focus,
      .convert-paging .pages-items .item.pages-item-previous .action:active,
      .convert-paging .pages-items .item.pages-item-previous .action:focus {
        background-color: #535d74 !important; }
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.disabled .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.disabled .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.disabled .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.disabled .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .action, .ais-Pagination-list .ais-Pagination-item.pages-item-next.disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-next.disabled .link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-next.disabled .action, .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .action, .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .action, .ais-Pagination-list .ais-Pagination-item.pages-item-previous.disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-previous.disabled .link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-previous.disabled .action, .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .action, .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .action,
    .ais-Pagination-list .item.ais-Pagination-item__previous.disabled .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item__previous.disabled .link,
    .ais-Pagination-list .item.ais-Pagination-item__previous.disabled .action,
    .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .link,
    .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .action,
    .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .link,
    .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .action,
    .ais-Pagination-list .item.ais-Pagination-item__next.disabled .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item__next.disabled .link,
    .ais-Pagination-list .item.ais-Pagination-item__next.disabled .action,
    .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item__disabled .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item__disabled .link,
    .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item__disabled .action,
    .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item--disabled .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item--disabled .link,
    .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item--disabled .action,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage.disabled .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage.disabled .link,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage.disabled .action,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .link,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .action,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .link,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .action,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage.disabled .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage.disabled .link,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage.disabled .action,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .link,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .action,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .link,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .action,
    .ais-Pagination-list .item.pages-item-next.disabled .ais-Pagination-link,
    .ais-Pagination-list .item.pages-item-next.disabled .link,
    .ais-Pagination-list .item.pages-item-next.disabled .action,
    .ais-Pagination-list .item.pages-item-next.ais-Pagination-item__disabled .ais-Pagination-link,
    .ais-Pagination-list .item.pages-item-next.ais-Pagination-item__disabled .link,
    .ais-Pagination-list .item.pages-item-next.ais-Pagination-item__disabled .action,
    .ais-Pagination-list .item.pages-item-next.ais-Pagination-item--disabled .ais-Pagination-link,
    .ais-Pagination-list .item.pages-item-next.ais-Pagination-item--disabled .link,
    .ais-Pagination-list .item.pages-item-next.ais-Pagination-item--disabled .action,
    .ais-Pagination-list .item.pages-item-previous.disabled .ais-Pagination-link,
    .ais-Pagination-list .item.pages-item-previous.disabled .link,
    .ais-Pagination-list .item.pages-item-previous.disabled .action,
    .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item__disabled .ais-Pagination-link,
    .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item__disabled .link,
    .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item__disabled .action,
    .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item--disabled .ais-Pagination-link,
    .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item--disabled .link,
    .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item--disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next.disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next.disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next.disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .action,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .action,
    .convert-paging .pages-items .item.ais-Pagination-item__previous.disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item__previous.disabled .link,
    .convert-paging .pages-items .item.ais-Pagination-item__previous.disabled .action,
    .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .link,
    .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .action,
    .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .link,
    .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .action,
    .convert-paging .pages-items .item.ais-Pagination-item__next.disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item__next.disabled .link,
    .convert-paging .pages-items .item.ais-Pagination-item__next.disabled .action,
    .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item__disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item__disabled .link,
    .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item__disabled .action,
    .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item--disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item--disabled .link,
    .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item--disabled .action,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage.disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage.disabled .link,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage.disabled .action,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .link,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .action,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .link,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .action,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage.disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage.disabled .link,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage.disabled .action,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .link,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .action,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .link,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .action,
    .convert-paging .pages-items .item.pages-item-next.disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.pages-item-next.disabled .link,
    .convert-paging .pages-items .item.pages-item-next.disabled .action,
    .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item__disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item__disabled .link,
    .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item__disabled .action,
    .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item--disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item--disabled .link,
    .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item--disabled .action,
    .convert-paging .pages-items .item.pages-item-previous.disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.pages-item-previous.disabled .link,
    .convert-paging .pages-items .item.pages-item-previous.disabled .action,
    .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item__disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item__disabled .link,
    .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item__disabled .action,
    .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item--disabled .ais-Pagination-link,
    .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item--disabled .link,
    .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item--disabled .action {
      cursor: default !important;
      color: white !important;
      background-color: #F1EFED !important; }
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.pages-item-next.disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.pages-item-previous.disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .action:hover, .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .link:hover,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item__previous.disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__previous.disabled .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__previous.disabled .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item__next.disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__next.disabled .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__next.disabled .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item__disabled .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item__disabled .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item--disabled .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item--disabled .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.disabled .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.disabled .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.disabled .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.disabled .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .action:hover,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .link:hover,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .action:hover,
      .ais-Pagination-list .item.pages-item-next.disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.pages-item-next.disabled .link:hover,
      .ais-Pagination-list .item.pages-item-next.disabled .action:hover,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item__disabled .link:hover,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item__disabled .action:hover,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item--disabled .link:hover,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item--disabled .action:hover,
      .ais-Pagination-list .item.pages-item-previous.disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.pages-item-previous.disabled .link:hover,
      .ais-Pagination-list .item.pages-item-previous.disabled .action:hover,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item__disabled .link:hover,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item__disabled .action:hover,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item--disabled .link:hover,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item--disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .action:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .link:hover,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.disabled .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.disabled .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__next.disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__next.disabled .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__next.disabled .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item__disabled .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item__disabled .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item--disabled .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item--disabled .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.disabled .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.disabled .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.disabled .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.disabled .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .action:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .link:hover,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .action:hover,
      .convert-paging .pages-items .item.pages-item-next.disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.pages-item-next.disabled .link:hover,
      .convert-paging .pages-items .item.pages-item-next.disabled .action:hover,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item__disabled .link:hover,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item__disabled .action:hover,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item--disabled .link:hover,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item--disabled .action:hover,
      .convert-paging .pages-items .item.pages-item-previous.disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.pages-item-previous.disabled .link:hover,
      .convert-paging .pages-items .item.pages-item-previous.disabled .action:hover,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item__disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item__disabled .link:hover,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item__disabled .action:hover,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item--disabled .ais-Pagination-link:hover,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item--disabled .link:hover,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item--disabled .action:hover {
        color: white !important;
        background-color: #F1EFED !important;
        box-shadow: none; }
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.pages-item-next.disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.pages-item-next.disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.pages-item-previous.disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.pages-item-previous.disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .action:focus, .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .ais-Pagination-link:active, .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .link:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .link:focus,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .action:active,
      .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item__previous.disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item__previous.disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__previous.disabled .link:active,
      .ais-Pagination-list .item.ais-Pagination-item__previous.disabled .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__previous.disabled .action:active,
      .ais-Pagination-list .item.ais-Pagination-item__previous.disabled .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .link:active,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .action:active,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .link:active,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .action:active,
      .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item__next.disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item__next.disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__next.disabled .link:active,
      .ais-Pagination-list .item.ais-Pagination-item__next.disabled .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__next.disabled .action:active,
      .ais-Pagination-list .item.ais-Pagination-item__next.disabled .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item__disabled .link:active,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item__disabled .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item__disabled .action:active,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item__disabled .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item--disabled .link:active,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item--disabled .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item--disabled .action:active,
      .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item--disabled .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.disabled .link:active,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.disabled .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.disabled .action:active,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.disabled .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .link:active,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .action:active,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .link:active,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .action:active,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.disabled .link:active,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.disabled .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.disabled .action:active,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.disabled .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .link:active,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .action:active,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .action:focus,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .link:active,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .link:focus,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .action:active,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .action:focus,
      .ais-Pagination-list .item.pages-item-next.disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.pages-item-next.disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.pages-item-next.disabled .link:active,
      .ais-Pagination-list .item.pages-item-next.disabled .link:focus,
      .ais-Pagination-list .item.pages-item-next.disabled .action:active,
      .ais-Pagination-list .item.pages-item-next.disabled .action:focus,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item__disabled .link:active,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item__disabled .link:focus,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item__disabled .action:active,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item__disabled .action:focus,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item--disabled .link:active,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item--disabled .link:focus,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item--disabled .action:active,
      .ais-Pagination-list .item.pages-item-next.ais-Pagination-item--disabled .action:focus,
      .ais-Pagination-list .item.pages-item-previous.disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.pages-item-previous.disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.pages-item-previous.disabled .link:active,
      .ais-Pagination-list .item.pages-item-previous.disabled .link:focus,
      .ais-Pagination-list .item.pages-item-previous.disabled .action:active,
      .ais-Pagination-list .item.pages-item-previous.disabled .action:focus,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item__disabled .link:active,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item__disabled .link:focus,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item__disabled .action:active,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item__disabled .action:focus,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item--disabled .link:active,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item--disabled .link:focus,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item--disabled .action:active,
      .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item--disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled .action:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .link:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .link:focus,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .action:active,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.disabled .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.disabled .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.disabled .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.disabled .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item__disabled .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item--disabled .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__next.disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__next.disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__next.disabled .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__next.disabled .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__next.disabled .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item__next.disabled .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item__disabled .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item__disabled .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item__disabled .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item__disabled .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item--disabled .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item--disabled .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item--disabled .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item--disabled .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.disabled .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.disabled .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.disabled .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.disabled .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.disabled .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.disabled .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.disabled .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.disabled .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled .action:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .link:active,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .link:focus,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .action:active,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled .action:focus,
      .convert-paging .pages-items .item.pages-item-next.disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.pages-item-next.disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.pages-item-next.disabled .link:active,
      .convert-paging .pages-items .item.pages-item-next.disabled .link:focus,
      .convert-paging .pages-items .item.pages-item-next.disabled .action:active,
      .convert-paging .pages-items .item.pages-item-next.disabled .action:focus,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item__disabled .link:active,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item__disabled .link:focus,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item__disabled .action:active,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item__disabled .action:focus,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item--disabled .link:active,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item--disabled .link:focus,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item--disabled .action:active,
      .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item--disabled .action:focus,
      .convert-paging .pages-items .item.pages-item-previous.disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.pages-item-previous.disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.pages-item-previous.disabled .link:active,
      .convert-paging .pages-items .item.pages-item-previous.disabled .link:focus,
      .convert-paging .pages-items .item.pages-item-previous.disabled .action:active,
      .convert-paging .pages-items .item.pages-item-previous.disabled .action:focus,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item__disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item__disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item__disabled .link:active,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item__disabled .link:focus,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item__disabled .action:active,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item__disabled .action:focus,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item--disabled .ais-Pagination-link:active,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item--disabled .ais-Pagination-link:focus,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item--disabled .link:active,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item--disabled .link:focus,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item--disabled .action:active,
      .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item--disabled .action:focus {
        color: white !important;
        background-color: #F1EFED !important;
        box-shadow: none; }
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.disabled:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.disabled:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.disabled:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.disabled:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled:before, .ais-Pagination-list .ais-Pagination-item.pages-item-next.disabled:before, .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled:before, .ais-Pagination-list .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled:before, .ais-Pagination-list .ais-Pagination-item.pages-item-previous.disabled:before, .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled:before, .ais-Pagination-list .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled:before,
    .ais-Pagination-list .item.ais-Pagination-item__previous.disabled:before,
    .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item__disabled:before,
    .ais-Pagination-list .item.ais-Pagination-item__previous.ais-Pagination-item--disabled:before,
    .ais-Pagination-list .item.ais-Pagination-item__next.disabled:before,
    .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item__disabled:before,
    .ais-Pagination-list .item.ais-Pagination-item__next.ais-Pagination-item--disabled:before,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage.disabled:before,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled:before,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled:before,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage.disabled:before,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled:before,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled:before,
    .ais-Pagination-list .item.pages-item-next.disabled:before,
    .ais-Pagination-list .item.pages-item-next.ais-Pagination-item__disabled:before,
    .ais-Pagination-list .item.pages-item-next.ais-Pagination-item--disabled:before,
    .ais-Pagination-list .item.pages-item-previous.disabled:before,
    .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item__disabled:before,
    .ais-Pagination-list .item.pages-item-previous.ais-Pagination-item--disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item__disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous.ais-Pagination-item--disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item__disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next.ais-Pagination-item--disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next.disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item__disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next.ais-Pagination-item--disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item__disabled:before,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous.ais-Pagination-item--disabled:before,
    .convert-paging .pages-items .item.ais-Pagination-item__previous.disabled:before,
    .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item__disabled:before,
    .convert-paging .pages-items .item.ais-Pagination-item__previous.ais-Pagination-item--disabled:before,
    .convert-paging .pages-items .item.ais-Pagination-item__next.disabled:before,
    .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item__disabled:before,
    .convert-paging .pages-items .item.ais-Pagination-item__next.ais-Pagination-item--disabled:before,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage.disabled:before,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item__disabled:before,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled:before,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage.disabled:before,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item__disabled:before,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled:before,
    .convert-paging .pages-items .item.pages-item-next.disabled:before,
    .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item__disabled:before,
    .convert-paging .pages-items .item.pages-item-next.ais-Pagination-item--disabled:before,
    .convert-paging .pages-items .item.pages-item-previous.disabled:before,
    .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item__disabled:before,
    .convert-paging .pages-items .item.pages-item-previous.ais-Pagination-item--disabled:before {
      opacity: 0.25; }
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage, .ais-Pagination-list .ais-Pagination-item.pages-item-next,
    .ais-Pagination-list .item.ais-Pagination-item__next,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage,
    .ais-Pagination-list .item.pages-item-next,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next,
    .convert-paging .pages-items .item.ais-Pagination-item__next,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage,
    .convert-paging .pages-items .item.pages-item-next {
      margin-left: auto; }
      @media screen and (max-width: 47.9375em) {
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage, .ais-Pagination-list .ais-Pagination-item.pages-item-next,
        .ais-Pagination-list .item.ais-Pagination-item__next,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage,
        .ais-Pagination-list .item.pages-item-next,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next,
        .convert-paging .pages-items .item.ais-Pagination-item__next,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage,
        .convert-paging .pages-items .item.pages-item-next {
          order: -1;
          flex-basis: 50%;
          margin: 0 0 10px !important; } }
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage:before, .ais-Pagination-list .ais-Pagination-item.pages-item-next:before,
      .ais-Pagination-list .item.ais-Pagination-item__next:before,
      .ais-Pagination-list .item.ais-Pagination-item--nextPage:before,
      .ais-Pagination-list .item.pages-item-next:before,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next:before,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage:before,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-next:before,
      .convert-paging .pages-items .item.ais-Pagination-item__next:before,
      .convert-paging .pages-items .item.ais-Pagination-item--nextPage:before,
      .convert-paging .pages-items .item.pages-item-next:before {
        transform: translateY(-50%);
        left: auto;
        right: 18px; }
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage, .ais-Pagination-list .ais-Pagination-item.pages-item-previous,
    .ais-Pagination-list .item.ais-Pagination-item__previous,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage,
    .ais-Pagination-list .item.pages-item-previous,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous,
    .convert-paging .pages-items .item.ais-Pagination-item__previous,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage,
    .convert-paging .pages-items .item.pages-item-previous {
      margin-right: auto; }
      @media screen and (max-width: 47.9375em) {
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage, .ais-Pagination-list .ais-Pagination-item.pages-item-previous,
        .ais-Pagination-list .item.ais-Pagination-item__previous,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage,
        .ais-Pagination-list .item.pages-item-previous,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous,
        .convert-paging .pages-items .item.ais-Pagination-item__previous,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage,
        .convert-paging .pages-items .item.pages-item-previous {
          order: -2;
          flex-basis: 50%;
          margin: 0 0 10px !important; } }
  @media all and (max-width: 374px) {
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage:before, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage:before, .ais-Pagination-list .ais-Pagination-item.pages-item-next:before, .ais-Pagination-list .ais-Pagination-item.pages-item-previous:before,
    .ais-Pagination-list .item.ais-Pagination-item__previous:before,
    .ais-Pagination-list .item.ais-Pagination-item__next:before,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage:before,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage:before,
    .ais-Pagination-list .item.pages-item-next:before,
    .ais-Pagination-list .item.pages-item-previous:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage:before,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage:before,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next:before,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous:before,
    .convert-paging .pages-items .item.ais-Pagination-item__previous:before,
    .convert-paging .pages-items .item.ais-Pagination-item__next:before,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage:before,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage:before,
    .convert-paging .pages-items .item.pages-item-next:before,
    .convert-paging .pages-items .item.pages-item-previous:before {
      display: none; }
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link,
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-next .link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-next .action, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link,
    .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action,
    .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item__previous .link,
    .ais-Pagination-list .item.ais-Pagination-item__previous .action,
    .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item__next .link,
    .ais-Pagination-list .item.ais-Pagination-item__next .action,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage .link,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage .action,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage .link,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage .action,
    .ais-Pagination-list .item.pages-item-next .ais-Pagination-link,
    .ais-Pagination-list .item.pages-item-next .link,
    .ais-Pagination-list .item.pages-item-next .action,
    .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link,
    .ais-Pagination-list .item.pages-item-previous .link,
    .ais-Pagination-list .item.pages-item-previous .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action,
    .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item__previous .link,
    .convert-paging .pages-items .item.ais-Pagination-item__previous .action,
    .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item__next .link,
    .convert-paging .pages-items .item.ais-Pagination-item__next .action,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action,
    .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link,
    .convert-paging .pages-items .item.pages-item-next .link,
    .convert-paging .pages-items .item.pages-item-next .action,
    .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link,
    .convert-paging .pages-items .item.pages-item-previous .link,
    .convert-paging .pages-items .item.pages-item-previous .action {
      font-size: 11px;
      min-width: 130px;
      padding-left: 1em;
      padding-right: 1em; } }

.reveal-overlay {
  bottom: auto;
  min-height: 100%;
  overflow: visible;
  background: rgba(24, 24, 24, 0.2) radial-gradient(ellipse at center, rgba(24, 24, 24, 0) 0%, rgba(24, 24, 24, 0.6) 100%); }

.modals-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  min-height: 100%;
  overflow: visible;
  background: rgba(24, 24, 24, 0.2) radial-gradient(ellipse at center, rgba(24, 24, 24, 0) 0%, rgba(24, 24, 24, 0.6) 100%);
  z-index: 899; }

body._has-modal {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed; }

.modals-overlay {
  z-index: 899; }

.modal-popup,
.modal-slide {
  bottom: 0;
  left: 0;
  min-width: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden; }
  .modal-popup._show,
  .modal-slide._show {
    visibility: visible; }
    .modal-popup._show .modal-inner-wrap,
    .modal-slide._show .modal-inner-wrap {
      opacity: 1; }
  .modal-popup .modal-inner-wrap,
  .modal-slide .modal-inner-wrap {
    background-color: white;
    box-shadow: 0 0 12px 2px rgba(24, 24, 24, 0.35);
    opacity: 1;
    pointer-events: auto; }

.modal-slide {
  left: 236px;
  z-index: 900; }
  .modal-slide._show .modal-inner-wrap {
    transform: translateX(0);
    opacity: 1; }
  .modal-slide .modal-inner-wrap {
    height: 100%;
    overflow-y: auto;
    position: static;
    opacity: 0;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    width: auto; }
  .modal-slide._inner-scroll .modal-inner-wrap {
    overflow-y: visible;
    display: flex;
    flex-direction: column; }
  .modal-slide._inner-scroll .modal-footer,
  .modal-slide._inner-scroll .modal-header {
    flex-grow: 0;
    flex-shrink: 0; }
  .modal-slide._inner-scroll .modal-content {
    overflow-y: auto; }
  .modal-slide._inner-scroll .modal-footer {
    margin-top: auto; }
  .modal-slide .modal-content,
  .modal-slide .modal-footer,
  .modal-slide .modal-header {
    padding: 0 41px 20.5px; }
    @media print, screen and (min-width: 40em) {
      .modal-slide .modal-content,
      .modal-slide .modal-footer,
      .modal-slide .modal-header {
        padding: 0 41px 41px; } }
  .modal-slide .modal-header {
    padding-bottom: 16.5px;
    padding-top: 16.5px; }
    @media print, screen and (min-width: 40em) {
      .modal-slide .modal-header {
        padding-bottom: 33px;
        padding-top: 33px; } }

.modal-popup {
  left: 0;
  overflow-y: auto; }
  ._has-modal .modal-popup {
    z-index: 900; }
  .modal-popup._show {
    z-index: 900; }
    .modal-popup._show .modal-inner-wrap {
      opacity: 1; }
  .modal-popup .modal-inner-wrap {
    margin: 20px auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    opacity: 0;
    transition-duration: 0.5s;
    transition-timing-function: ease; }
    @media print, screen and (min-width: 40em) {
      .modal-popup .modal-inner-wrap {
        width: 75%;
        margin: 80px auto; } }
  @media screen and (min-width: 64em) {
    .modal-popup.modal-xs .modal-inner-wrap {
      width: 42.85714%; } }
  @media screen and (min-width: 75em) {
    .modal-popup.modal-xs .modal-inner-wrap {
      width: 37.5%; } }
  @media screen and (min-width: 90.625em) {
    .modal-popup.modal-xs .modal-inner-wrap {
      width: 25%; } }
  @media screen and (min-width: 64em) {
    .modal-popup.modal-md .modal-inner-wrap {
      width: 60%; } }
  @media screen and (min-width: 75em) {
    .modal-popup.modal-md .modal-inner-wrap {
      width: 50%; } }
  @media screen and (min-width: 90.625em) {
    .modal-popup.modal-md .modal-inner-wrap {
      width: 39.47368%; } }
  @media screen and (min-width: 64em) {
    .modal-popup.modal-xmd .modal-inner-wrap {
      width: 65%; } }
  @media print, screen and (min-width: 48em) {
    .modal-popup.modal-add-adress .modal-inner-wrap {
      width: 37.5rem; } }
  .modal-popup._inner-scroll {
    overflow-y: visible; }
    .ie10 .modal-popup._inner-scroll,
    .ie9 .modal-popup._inner-scroll {
      overflow-y: auto; }
    .modal-popup._inner-scroll .modal-inner-wrap {
      max-height: 90%; }
      .ie10 .modal-popup._inner-scroll .modal-inner-wrap,
      .ie9 .modal-popup._inner-scroll .modal-inner-wrap {
        max-height: none; }
    .modal-popup._inner-scroll .modal-content {
      overflow-y: auto; }
  .modal-popup .modal-content,
  .modal-popup .modal-footer,
  .modal-popup .modal-header {
    padding-left: 24px;
    padding-right: 24px; }
    @media print, screen and (min-width: 40em) {
      .modal-popup .modal-content,
      .modal-popup .modal-footer,
      .modal-popup .modal-header {
        padding-left: 48px;
        padding-right: 48px; } }
  .modal-popup .modal-footer,
  .modal-popup .modal-header {
    flex-grow: 0;
    flex-shrink: 0; }
  .modal-popup .modal-header {
    padding-bottom: 19.2px;
    padding-top: 32px; }
    @media print, screen and (min-width: 40em) {
      .modal-popup .modal-header {
        padding-top: 48px; } }
  .modal-popup .modal-footer {
    margin-top: auto;
    padding-bottom: 24px;
    padding-top: 24px; }
    @media print, screen and (min-width: 40em) {
      .modal-popup .modal-footer {
        padding-bottom: 48px;
        padding-top: 48px; } }
  .modal-popup .modal-footer-actions {
    text-align: right; }

@media screen and (max-width: 47.9375em) {
  .modal-popup.modal-slide {
    left: 236px;
    z-index: 900; }
    .modal-popup.modal-slide._show .modal-inner-wrap {
      transform: translateX(0);
      opacity: 1; }
    .modal-popup.modal-slide .modal-inner-wrap {
      height: 100%;
      overflow-y: auto;
      position: static;
      opacity: 0;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      width: auto; }
    .modal-popup.modal-slide .modal-inner-wrap {
      margin: 0;
      max-height: none; } }

.modal-custom .action-close,
.modal-popup .action-close,
.modal-slide .action-close {
  position: absolute;
  color: #000;
  cursor: pointer;
  font-size: 1.5rem;
  top: 1.5625rem;
  right: 1.4375rem;
  font-weight: 300;
  color: #181818;
  padding: 0; }
  [data-whatinput='mouse'] .modal-custom .action-close, [data-whatinput='mouse']
  .modal-popup .action-close, [data-whatinput='mouse']
  .modal-slide .action-close {
    outline: 0; }
  .modal-custom .action-close:hover, .modal-custom .action-close:focus,
  .modal-popup .action-close:hover,
  .modal-popup .action-close:focus,
  .modal-slide .action-close:hover,
  .modal-slide .action-close:focus {
    color: #e24283; }
  @media screen and (min-width: 64em) {
    .modal-custom .action-close,
    .modal-popup .action-close,
    .modal-slide .action-close {
      font-size: 3rem; } }
  .modal-custom .action-close span,
  .modal-popup .action-close span,
  .modal-slide .action-close span {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }
  .modal-custom .action-close:before,
  .modal-popup .action-close:before,
  .modal-slide .action-close:before {
    content: '×'; }
  .modal-custom .action-close:hover:before, .modal-custom .action-close:active:before, .modal-custom .action-close:focus:before,
  .modal-popup .action-close:hover:before,
  .modal-popup .action-close:active:before,
  .modal-popup .action-close:focus:before,
  .modal-slide .action-close:hover:before,
  .modal-slide .action-close:active:before,
  .modal-slide .action-close:focus:before {
    color: #1A2746; }

.modal-custom .action-close {
  margin: 10px; }

.modal-popup .modal-title {
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  font-size: 26px;
  margin-bottom: 0;
  min-height: 1em;
  word-wrap: break-word; }

.modal-popup .action-close {
  padding: 5px; }

.modal-popup.filters .modal-footer button:not(.action-close),
.modal-popup.filters .modal-header button:not(.action-close) {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.82em 1em 0.87em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 50px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 1.1875rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #30A95E;
  color: #fff;
  font-family: "silka-semibold-webfont", sans-serif;
  letter-spacing: normal;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 0;
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  background-color: #181818;
  color: #fff;
  margin-bottom: 0; }
  [data-whatinput='mouse'] .modal-popup.filters .modal-footer button:not(.action-close), [data-whatinput='mouse']
  .modal-popup.filters .modal-header button:not(.action-close) {
    outline: 0; }
  .modal-popup.filters .modal-footer button:not(.action-close):hover, .modal-popup.filters .modal-footer button:not(.action-close):focus,
  .modal-popup.filters .modal-header button:not(.action-close):hover,
  .modal-popup.filters .modal-header button:not(.action-close):focus {
    background-color: rgba(48, 169, 94, 0.75);
    color: #fff; }
  .modal-popup.filters .modal-footer button:not(.action-close):active,
  .modal-popup.filters .modal-header button:not(.action-close):active {
    color: rgba(255, 255, 255, 0.8) !important; }
  .modal-popup.filters .modal-footer button:not(.action-close):hover, .modal-popup.filters .modal-footer button:not(.action-close):focus,
  .modal-popup.filters .modal-header button:not(.action-close):hover,
  .modal-popup.filters .modal-header button:not(.action-close):focus {
    background-color: black;
    color: #fff; }

.modal-popup.filters .modal-header {
  padding-bottom: 1rem; }
  .modal-popup.filters .modal-header button:not(.action-close) {
    margin-top: 1rem; }

.modal-popup.filters .modal-title,
.modal-popup.filters .sorter-title {
  border-bottom: 0;
  font-size: 0.875rem;
  color: #181818;
  font-weight: 400;
  text-transform: none;
  font-family: inherit;
  letter-spacing: 0;
  margin-bottom: 0;
  padding-bottom: 0; }

.modal-popup.filters .modal-sub-title {
  display: block;
  font-size: 1.5rem;
  color: #181818;
  font-weight: 300;
  text-transform: none;
  font-family: "silka-regular-webfont", sans-serif;
  letter-spacing: normal; }

.modal-popup.confirm {
  text-align: center; }
  @media print, screen and (min-width: 40em) {
    .modal-popup.confirm .modal-inner-wrap {
      width: 37.5%; } }
  .modal-popup.confirm .modal-footer button {
    min-width: 5rem;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    padding: 0.82em 1em 0.87em;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 50px;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 1.1875rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: #30A95E;
    color: #fff;
    font-family: "silka-semibold-webfont", sans-serif;
    letter-spacing: normal;
    font-weight: 400;
    text-transform: none;
    margin-bottom: 0;
    margin-bottom: 0;
    font-family: "silka-semibold-webfont", sans-serif;
    font-weight: 400;
    background-color: #1A2746;
    color: #fff; }
    [data-whatinput='mouse'] .modal-popup.confirm .modal-footer button {
      outline: 0; }
    .modal-popup.confirm .modal-footer button:hover, .modal-popup.confirm .modal-footer button:focus {
      background-color: rgba(48, 169, 94, 0.75);
      color: #fff; }
    .modal-popup.confirm .modal-footer button:active {
      color: rgba(255, 255, 255, 0.8) !important; }
    .modal-popup.confirm .modal-footer button:hover, .modal-popup.confirm .modal-footer button:focus {
      background-color: rgba(48, 169, 94, 0.75);
      color: #fff; }
    .modal-popup.confirm .modal-footer button.action-secondary {
      background-color: #181818;
      color: #fff; }
      .modal-popup.confirm .modal-footer button.action-secondary:hover, .modal-popup.confirm .modal-footer button.action-secondary:focus {
        background-color: black;
        color: #fff; }

.modal-slide .action-close {
  padding: 0; }

.modal-slide .page-main-actions {
  margin-bottom: 13px;
  margin-top: 33px; }

.modal-checkout-enter {
  text-align: center; }
  .modal-checkout-enter .action-close {
    display: none; }
  .modal-checkout-enter .modal-content-row {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    margin-right: -1.0625rem;
    margin-left: -1.0625rem; }
    .modal-checkout-enter .modal-content-row::before, .modal-checkout-enter .modal-content-row::after {
      display: table;
      content: ' '; }
    .modal-checkout-enter .modal-content-row::after {
      clear: both; }
    @media print, screen and (min-width: 40em) {
      .modal-checkout-enter .modal-content-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .modal-checkout-enter .modal-content-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .modal-checkout-enter .modal-content-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .modal-checkout-enter .modal-content-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .modal-checkout-enter .modal-content-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
  .modal-checkout-enter .modal-content {
    padding-bottom: 2rem; }
    .modal-checkout-enter .modal-content .modal-content-column {
      padding-top: 2rem;
      margin-top: 2rem;
      position: relative; }
      .modal-checkout-enter .modal-content .modal-content-column:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 1.0625rem;
        right: 1.0625rem;
        visibility: visible;
        opacity: 1;
        display: block;
        border: 0;
        border-top: 1px solid rgba(24, 24, 24, 0.15);
        bottom: auto;
        top: 0; }
        @media print, screen and (min-width: 40em) {
          .modal-checkout-enter .modal-content .modal-content-column:before {
            left: 1.25rem; } }
        @media print, screen and (min-width: 40em) {
          .modal-checkout-enter .modal-content .modal-content-column:before {
            right: 1.25rem; } }
        @media print, screen and (min-width: 40em) {
          .modal-checkout-enter .modal-content .modal-content-column:before {
            top: 0; } }
      .modal-checkout-enter .modal-content .modal-content-column:first-child {
        padding-top: 0;
        margin-top: 0; }
        .modal-checkout-enter .modal-content .modal-content-column:first-child:before {
          display: none; }
  .modal-checkout-enter .modal-title {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 1.25rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 300; }
  .modal-checkout-enter p {
    margin-bottom: 1.5rem;
    color: rgba(24, 24, 24, 0.8); }
  .modal-checkout-enter .label {
    text-align: left; }
    .modal-checkout-enter .label:after {
      content: ':'; }
  .modal-checkout-enter .action {
    min-width: 12.5rem;
    margin-bottom: .5rem; }
  @media screen and (min-width: 90.625em) {
    .modal-checkout-enter .block-customer-login {
      max-width: 85%;
      margin-left: auto;
      margin-right: auto; } }
  .modal-checkout-enter .block-customer-login .fieldset {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto; }
    .modal-checkout-enter .block-customer-login .fieldset::before, .modal-checkout-enter .block-customer-login .fieldset::after {
      display: table;
      content: ' '; }
    .modal-checkout-enter .block-customer-login .fieldset::after {
      clear: both; }
    @media print, screen and (min-width: 48em) {
      .modal-checkout-enter .block-customer-login .fieldset {
        margin-right: -1.0625rem;
        margin-left: -1.0625rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .modal-checkout-enter .block-customer-login .fieldset {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 48em) {
    .modal-checkout-enter .block-customer-login .fieldset {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 64em) {
    .modal-checkout-enter .block-customer-login .fieldset {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 75em) {
    .modal-checkout-enter .block-customer-login .fieldset {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 90.625em) {
    .modal-checkout-enter .block-customer-login .fieldset {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
    .modal-checkout-enter .block-customer-login .fieldset .label {
      color: rgba(24, 24, 24, 0.8); }
    .modal-checkout-enter .block-customer-login .fieldset .field {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem; }
      @media print, screen and (min-width: 40em) {
        .modal-checkout-enter .block-customer-login .fieldset .field {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .modal-checkout-enter .block-customer-login .fieldset .field:last-child:not(:first-child) {
        float: right; }
      @media screen and (min-width: 75em) {
        .modal-checkout-enter .block-customer-login .fieldset .field {
          width: 50%; } }
  .modal-checkout-enter .block-customer-login .actions-toolbar {
    margin-top: 1rem; }
  .modal-checkout-enter .button-group-modal {
    position: relative;
    z-index: 1; }
  @media print, screen and (min-width: 48em) {
    .modal-checkout-enter .modal-content .modal-content-column {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem;
      width: 50%;
      position: relative;
      padding-top: 0;
      margin-top: 0;
      position: relative; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .modal-checkout-enter .modal-content .modal-content-column {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  @media print, screen and (min-width: 48em) {
      .modal-checkout-enter .modal-content .modal-content-column:last-child:not(:first-child) {
        float: right; }
      .modal-checkout-enter .modal-content .modal-content-column:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 1.0625rem;
        right: 1.0625rem;
        visibility: visible;
        opacity: 1;
        display: block;
        border: 0;
        border-left: 1px solid rgba(24, 24, 24, 0.15);
        top: 0;
        left: 0;
        right: auto; } }
    @media print, screen and (min-width: 48em) and (min-width: 40em) {
      .modal-checkout-enter .modal-content .modal-content-column:before {
        left: 1.25rem; } }
    @media print, screen and (min-width: 48em) and (min-width: 40em) {
      .modal-checkout-enter .modal-content .modal-content-column:before {
        right: 1.25rem; } }
    @media print, screen and (min-width: 48em) and (min-width: 40em) {
      .modal-checkout-enter .modal-content .modal-content-column:before {
        left: 0; } }
  @media print, screen and (min-width: 48em) {
      .modal-checkout-enter .modal-content .modal-content-column:before {
        top: -1rem;
        bottom: -1rem; } }
  @media screen and (min-width: 75em) {
    .modal-checkout-enter .modal-header {
      padding-bottom: 2.25rem; }
    .modal-checkout-enter .modal-content {
      padding-bottom: 3.5rem; }
      .modal-checkout-enter .modal-content .button-group-modal {
        margin-right: 0;
        margin-bottom: 0;
        max-width: 25.9375rem;
        margin-bottom: 1rem;
        font-size: 0;
        margin-right: -1px;
        margin: 0 auto; }
        .modal-checkout-enter .modal-content .button-group-modal::before, .modal-checkout-enter .modal-content .button-group-modal::after {
          display: table;
          content: ' '; }
        .modal-checkout-enter .modal-content .button-group-modal::after {
          clear: both; }
        .modal-checkout-enter .modal-content .button-group-modal .button {
          margin: 0;
          margin-right: 1px;
          margin-bottom: 1px;
          font-size: 1.1875rem; }
          .modal-checkout-enter .modal-content .button-group-modal .button:last-child {
            margin-right: 0; }
        .modal-checkout-enter .modal-content .button-group-modal::before, .modal-checkout-enter .modal-content .button-group-modal::after {
          display: none; }
        .modal-checkout-enter .modal-content .button-group-modal .button:first-child:last-child {
          width: 100%; }
        .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(2), .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button {
          display: inline-block;
          width: calc(50% - 1px);
          margin-right: 1px; }
          .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(2):last-child, .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child {
            margin-right: -6px; }
        .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(3), .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button {
          display: inline-block;
          width: calc(33.33333% - 1px);
          margin-right: 1px; }
          .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(3):last-child, .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button:last-child {
            margin-right: -6px; }
        .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(4), .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button {
          display: inline-block;
          width: calc(25% - 1px);
          margin-right: 1px; }
          .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(4):last-child, .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button:last-child {
            margin-right: -6px; }
        .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(5), .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button {
          display: inline-block;
          width: calc(20% - 1px);
          margin-right: 1px; }
          .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(5):last-child, .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button:last-child {
            margin-right: -6px; }
        .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(6), .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button {
          display: inline-block;
          width: calc(16.66667% - 1px);
          margin-right: 1px; }
          .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(6):last-child, .modal-checkout-enter .modal-content .button-group-modal .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button:last-child {
            margin-right: -6px; }
        .modal-checkout-enter .modal-content .button-group-modal .action {
          min-width: inherit;
          margin-right: .5rem; }
          .modal-checkout-enter .modal-content .button-group-modal .action:last-child {
            margin-right: 0; } }

@media screen and (max-width: 47.9375em) {
  body._has-modal-custom {
    height: 100vh;
    overflow: hidden;
    width: 100vw; }
  .custom-slide {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    left: 236px;
    z-index: 900; }
    .custom-slide._show {
      visibility: visible; }
      .custom-slide._show .modal-inner-wrap {
        opacity: 1; }
    .custom-slide .modal-inner-wrap {
      background-color: white;
      box-shadow: 0 0 12px 2px rgba(24, 24, 24, 0.35);
      opacity: 1;
      pointer-events: auto; }
    .custom-slide._show .modal-inner-wrap {
      transform: translateX(0);
      opacity: 1; }
    .custom-slide .modal-inner-wrap {
      height: 100%;
      overflow-y: auto;
      position: static;
      opacity: 0;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      width: auto; }
    .custom-slide._show {
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      overflow-y: auto; }
    .custom-slide .modal-inner-wrap {
      background-color: #eee;
      box-sizing: border-box;
      height: auto;
      min-height: 100%; }
  .modal-popup .modal-title {
    font-size: 24px; }
  .modal-popup.modal-slide .modal-inner-wrap[class] {
    background-color: #eee; }
  .modal-popup.modal-slide._inner-scroll._show {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto; }
  .modal-popup.modal-slide._inner-scroll .modal-inner-wrap {
    height: auto;
    min-height: 100%; } }

@media print, screen and (min-width: 40em) {
  .modal-popup.modal-slide .modal-footer {
    border-top: 1px solid #eee;
    text-align: right; } }

.page-header {
  background-color: #1A2746;
  position: relative;
  z-index: 899; }

.action.nav-toggle {
  display: none; }

@media print, screen and (min-width: 48em) {
  .is-not-sticky .header {
    height: auto !important; } }

.header .header-row .logo {
  text-align: left; }
  .header .header-row .logo img {
    display: inline-block;
    height: auto;
    max-height: 100%;
    width: auto;
    margin-top: 1.75rem; }
    @media screen and (min-width: 75em) and (max-width: 90.5625em) {
      .header .header-row .logo img {
        margin-top: 0; } }
    @media screen and (min-width: 90.625em) {
      .header .header-row .logo img {
        max-width: 123px; } }

.header .header-row .field {
  margin-bottom: 0; }

.header a {
  text-decoration: none;
  color: #f7f7f7; }

.header .sticky-search {
  display: none !important; }

.header .sticky {
  width: 100%;
  position: relative; }

.header .header-row.is-stuck {
  max-width: 100% !important;
  height: 4.75rem;
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  margin: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3), 0 0 15px rgba(0, 0, 0, 0.15);
  background: #1A2746; }
  .header .header-row.is-stuck .nav-sections {
    height: 4.75rem; }
    .header .header-row.is-stuck .nav-sections .navigation {
      height: 100%;
      margin-top: 0; }
  .ie11 .header .header-row.is-stuck .logo img {
    width: 100%;
    max-height: inherit; }

.header .block-search .block-title,
.header .block-search .action.advanced,
.header .block-search .block-content .search .label,
.header .block-search .minisearch .search > span {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  color: white; }

.header .top-search-wrapper {
  margin-bottom: 0;
  text-align: right; }
  @media screen and (min-width: 75em) {
    .header .top-search-wrapper {
      display: table;
      width: 100%; } }
  .header .top-search-wrapper .block-search {
    height: 56px; }
    @media screen and (min-width: 75em) {
      .header .top-search-wrapper .block-search {
        width: 100%; } }
  @media screen and (min-width: 75em) {
    .header .top-search-wrapper .block-search,
    .header .top-search-wrapper .minicart-wrapper {
      margin: 0;
      white-space: nowrap;
      display: table-cell;
      vertical-align: middle; } }
  .header .top-search-wrapper .minicart-wrapper {
    margin-top: 1rem;
    margin-right: .5rem; }
    @media screen and (min-width: 75em) {
      .header .top-search-wrapper .minicart-wrapper {
        width: 1%;
        height: 100%;
        padding: 0;
        margin-top: 0;
        margin-right: 0; } }

.header .minisearch .control .input-text, #algolia-searchbox input.algolia-search-input {
  height: 56px;
  padding-right: 3rem;
  padding-left: 24px;
  margin-bottom: 0;
  font-family: "silka-regular-webfont", sans-serif;
  font-size: 17px;
  font-weight: 300;
  color: white;
  text-transform: none;
  border-color: rgba(255, 255, 255, 0.15);
  border-bottom-color: none;
  background-color: rgba(255, 255, 255, 0.15);
  background-position: calc(100% - 17px) 50%;
  transition: all .3s ease;
  border: transparent; }
  .header .minisearch .control .input-text::-webkit-input-placeholder, #algolia-searchbox input.algolia-search-input::-webkit-input-placeholder {
    color: white; }
  .header .minisearch .control .input-text:-moz-placeholder, #algolia-searchbox input.algolia-search-input:-moz-placeholder {
    color: white; }
  .header .minisearch .control .input-text::-moz-placeholder, #algolia-searchbox input.algolia-search-input::-moz-placeholder {
    color: white; }
  .header .minisearch .control .input-text:-ms-input-placeholder, #algolia-searchbox input.algolia-search-input:-ms-input-placeholder {
    color: white; }
  .mobile-search-bar .header .minisearch .control .input-text, .header .minisearch .control .mobile-search-bar .input-text, .mobile-search-bar #algolia-searchbox input.algolia-search-input, #algolia-searchbox .mobile-search-bar input.algolia-search-input {
    padding-left: 1rem; }
  @media screen and (max-width: 63.9375em) {
    .header .minisearch .control .input-text, #algolia-searchbox input.algolia-search-input {
      padding-left: 1rem; } }
  .header .minisearch .control .input-text:hover, #algolia-searchbox input.algolia-search-input:hover, .header .minisearch .control .input-text:active, #algolia-searchbox input.algolia-search-input:active, .header .minisearch .control .input-text:focus, #algolia-searchbox input.algolia-search-input:focus, .header .minisearch .control .input-text:focus:not([value=""]), #algolia-searchbox input.algolia-search-input:focus:not([value=""]), .header .minisearch .control .focus.input-text, #algolia-searchbox input.focus.algolia-search-input {
    border-color: #1A2746;
    border-bottom-color: #1A2746;
    background-color: rgba(255, 255, 255, 0.15) !important; }
  .header .minisearch .control .input-text::-ms-clear, #algolia-searchbox input.algolia-search-input::-ms-clear {
    display: none; }

.mobile-search-bar #algolia-searchbox .algolia-search-input, .mobile-search-bar .minisearch .input-text {
  height: 2.9375rem;
  color: white;
  font-size: 1rem;
  padding: 0.5rem 3rem 0.5rem 1rem;
  border-width: 1px;
  border-style: none;
  border-color: #919191;
  background-color: rgba(255, 255, 255, 0.15);
  text-transform: none;
  width: 100%; }
  .mobile-search-bar #algolia-searchbox .algolia-search-input::-webkit-input-placeholder, .mobile-search-bar .minisearch .input-text::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5); }
  .mobile-search-bar #algolia-searchbox .algolia-search-input:-moz-placeholder, .mobile-search-bar .minisearch .input-text:-moz-placeholder {
    color: rgba(255, 255, 255, 0.5); }
  .mobile-search-bar #algolia-searchbox .algolia-search-input::-moz-placeholder, .mobile-search-bar .minisearch .input-text::-moz-placeholder {
    color: rgba(255, 255, 255, 0.5); }
  .mobile-search-bar #algolia-searchbox .algolia-search-input:-ms-input-placeholder, .mobile-search-bar .minisearch .input-text:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5); }
  .mobile-search-bar #algolia-searchbox .algolia-search-input:hover, .mobile-search-bar .minisearch .input-text:hover, .mobile-search-bar #algolia-searchbox .algolia-search-input:active, .mobile-search-bar .minisearch .input-text:active, .mobile-search-bar #algolia-searchbox .algolia-search-input:focus, .mobile-search-bar .minisearch .input-text:focus, .mobile-search-bar #algolia-searchbox .algolia-search-input:focus:not([value=""]), .mobile-search-bar .minisearch .input-text:focus:not([value=""]), .mobile-search-bar #algolia-searchbox .focus.algolia-search-input, .mobile-search-bar .minisearch .focus.input-text {
    border-color: #444444;
    background-color: rgba(255, 255, 255, 0.15) !important; }

.mobile-search-bar #algolia-searchbox #algolia-glass, .mobile-search-bar .minisearch .actions {
  height: 2.9375rem;
  position: absolute;
  top: 0;
  right: 0;
  left: auto; }

.mobile-search-bar #algolia-searchbox #algolia-glass, .mobile-search-bar .minisearch .action.search, #algolia-searchbox .mobile-search-bar .minisearch #algolia-glass,
.mobile-search-bar .minisearch #algolia-searchbox .ais-search-box--magnifier-wrapper, #algolia-searchbox .mobile-search-bar .minisearch .ais-search-box--magnifier-wrapper {
  height: 2.9375rem;
  width: 1.6875rem;
  min-width: 3.375rem;
  line-height: 2.9375rem;
  padding: 0.3125rem 0.625rem;
  background-image: url("../images/icon-search.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 1.6875rem auto; }

.mobile-search-wrapper {
  transition: all .3s ease; }
  @media print, screen and (min-width: 48em) {
    .mobile-search-wrapper {
      display: none !important; } }
  body:not(.page-layout-0column) .mobile-search-wrapper .mobile-search-bar {
    padding-left: 23px;
    padding-right: 23px; }
    @media print, screen and (min-width: 48em) {
      body:not(.page-layout-0column) .mobile-search-wrapper .mobile-search-bar {
        padding-left: 40px;
        padding-right: 40px; } }
  .mobile-search-wrapper.in-scroll {
    position: fixed;
    top: 6px;
    left: 70px;
    right: 130px;
    z-index: 899; }
    .mobile-search-wrapper.in-scroll .mobile-search-bar {
      padding: 0 !important; }

.mobile-search-bar {
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 23px;
  padding-right: 23px;
  background-color: #1A2746; }
  .mobile-search-bar #algolia-searchbox .clear-cross, .mobile-search-bar #algolia-searchbox .ais-search-box--reset-wrapper {
    height: 2.9375rem;
    width: 1.6875rem;
    min-width: 3.375rem;
    line-height: 2.9375rem;
    padding: 0.3125rem 0.625rem;
    background-size: 1.6875rem auto;
    top: -1px;
    position: absolute;
    top: 0;
    right: 0;
    left: auto; }
  .mobile-search-bar .minisearch {
    position: relative; }
    .mobile-search-bar .minisearch .label,
    .mobile-search-bar .minisearch .nested {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }
  .mobile-search-bar .le-search-side.autocomplete {
    left: 0;
    right: 0;
    z-index: 103; }

.header .minisearch {
  position: relative; }
  .header .minisearch .actions {
    position: absolute;
    top: 0;
    right: 0.25rem;
    line-height: 1; }
    .header .minisearch .actions button.action {
      width: 2.5rem;
      height: 56px;
      padding: 0.3125rem;
      background-position: 50%; }
      .header .minisearch .actions button.action:hover, .header .minisearch .actions button.action:focus {
        background-color: transparent; }

.search-autocomplete {
  text-align: left;
  margin-top: -1px;
  z-index: 4; }
  .search-autocomplete ul {
    margin: 0;
    list-style: none;
    border: 1px solid rgba(24, 24, 24, 0.2);
    box-shadow: 2px 3px 3px rgba(24, 24, 24, 0.2);
    background: white; }
    .search-autocomplete ul li {
      padding: 0; }
      .search-autocomplete ul li:after {
        display: none; }
    .search-autocomplete ul:empty {
      display: none; }
    .search-autocomplete ul li {
      padding: .25rem 1rem;
      border-bottom: 1px solid rgba(24, 24, 24, 0.2); }
      .search-autocomplete ul li.selected {
        color: white;
        background: #181818; }
      .search-autocomplete ul li:last-child {
        border-bottom: 0; }

#search_mini_form label {
  color: white; }

#algolia-searchbox {
  position: relative; }
  #algolia-searchbox label,
  #algolia-searchbox .clear-cross,
  .mobile-search-bar #algolia-searchbox .clear-cross,
  #algolia-searchbox .ais-search-box--reset-wrapper {
    display: none; }
  #algolia-searchbox #algolia-glass,
  #algolia-searchbox .ais-search-box--magnifier-wrapper {
    position: absolute;
    top: 0;
    right: 0.25rem;
    width: 2.5rem;
    height: 56px;
    padding: 0.3125rem;
    background-position: 50%; }
    #algolia-searchbox #algolia-glass:hover, #algolia-searchbox #algolia-glass:focus,
    #algolia-searchbox .ais-search-box--magnifier-wrapper:hover,
    #algolia-searchbox .ais-search-box--magnifier-wrapper:focus {
      background-color: transparent; }
    #algolia-searchbox #algolia-glass svg,
    #algolia-searchbox .ais-search-box--magnifier-wrapper svg {
      display: none; }
  #algolia-searchbox .ais-search-box--magnifier-wrapper {
    top: -1px; }
  #algolia-searchbox span.clear-query-autocomplete,
  #algolia-searchbox .ais-search-box--reset-wrapper {
    height: 100%;
    top: 0;
    right: 0;
    width: 3rem;
    background-size: 1rem auto;
    z-index: 1; }
    #algolia-searchbox span.clear-query-autocomplete svg,
    #algolia-searchbox .ais-search-box--reset-wrapper svg {
      display: none; }
  #algolia-searchbox .ais-search-box--reset-wrapper {
    top: -1px; }

.header .minicart-wrapper {
  border: 0;
  display: inline-block;
  line-height: 1;
  position: relative;
  background: none; }
  @media screen and (max-width: 74.9375em) {
    .header .minicart-wrapper {
      margin-top: .5rem; } }
  .header .minicart-wrapper:after {
    display: block;
    content: '';
    width: 2.75rem;
    height: 2.75rem; }
  .header .minicart-wrapper .action.showcart {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }
    @media screen and (max-width: 47.9375em) {
      .header .minicart-wrapper .action.showcart {
        position: relative;
        left: auto;
        top: auto;
        transform: scale(0.85) translateY(0);
        margin: 0; } }
    @media screen and (min-width: 75em) {
      .header .minicart-wrapper .action.showcart {
        left: 0;
        right: auto;
        margin: 0; } }
  .header .minicart-wrapper .mage-dropdown-dialog {
    display: block;
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 0.625rem;
    width: 25rem;
    padding: 1.0625rem;
    z-index: 20;
    background-color: white;
    box-shadow: 0 0 25px rgba(24, 24, 24, 0.15); }
    @media print, screen and (min-width: 40em) {
      .header .minicart-wrapper .mage-dropdown-dialog {
        padding: 1.25rem; } }
    .header .minicart-wrapper .mage-dropdown-dialog .block-title,
    .header .minicart-wrapper .mage-dropdown-dialog .subtitle:not(.empty) {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }
    .header .minicart-wrapper .mage-dropdown-dialog .subtitle.empty {
      font-size: 1rem;
      color: #1A2746;
      font-weight: 300; }
    .header .minicart-wrapper .mage-dropdown-dialog .action.close {
      display: inline-block;
      position: absolute;
      right: 0.625rem;
      top: 0.4375rem;
      font-size: 1rem;
      color: #181818; }
      .header .minicart-wrapper .mage-dropdown-dialog .action.close span {
        position: absolute !important;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0); }

.header .block-minicart .subtotal {
  text-align: left;
  padding-top: 1rem;
  margin-bottom: 1rem; }
  .header .block-minicart .subtotal .label {
    padding: 0;
    vertical-align: middle;
    color: #181818;
    font-size: inherit;
    line-height: inherit;
    white-space: normal;
    background: none; }
    .header .block-minicart .subtotal .label span:after {
      content: ':'; }
  .header .block-minicart .subtotal .amount.price-container {
    display: inline-block;
    vertical-align: middle;
    font-weight: 700; }

.header .block-minicart button.checkout {
  margin-bottom: 0;
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  font-size: 1.1875rem; }

.content.minicart-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 1rem;
  z-index: 1; }
  .content.minicart-items .minicart-items-wrapper {
    width: 23.75rem;
    text-align: left;
    padding: 1.5rem;
    box-shadow: 0 0 25px rgba(24, 24, 24, 0.15);
    position: relative;
    background-color: white; }
    .content.minicart-items .minicart-items-wrapper:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 1rem 1rem 1rem;
      border-color: transparent transparent white transparent;
      position: absolute;
      bottom: 100%;
      left: 4rem; }
  .content.minicart-items .minicart-items {
    margin: 0;
    list-style: none; }
    .content.minicart-items .minicart-items li {
      padding: 0; }
      .content.minicart-items .minicart-items li:after {
        display: none; }
    .content.minicart-items .minicart-items .product-item {
      padding: .5rem 0;
      border-bottom: 1px solid rgba(24, 24, 24, 0.2); }
      .content.minicart-items .minicart-items .product-item:first-child {
        padding-top: 0; }
      .content.minicart-items .minicart-items .product-item:last-child {
        padding-bottom: 0;
        border-bottom: 0; }
    .content.minicart-items .minicart-items .product-item-details {
      line-height: 1.1;
      font-size: 0; }
      .content.minicart-items .minicart-items .product-item-details::before, .content.minicart-items .minicart-items .product-item-details::after {
        display: table;
        content: ' '; }
      .content.minicart-items .minicart-items .product-item-details::after {
        clear: both; }
    .content.minicart-items .minicart-items .col {
      display: inline-block;
      vertical-align: middle;
      font-size: 0.8125rem; }
    .content.minicart-items .minicart-items .product-image {
      width: 15%;
      text-align: center; }
    .content.minicart-items .minicart-items .product-title {
      width: 55%;
      padding: 0 1rem;
      text-transform: none;
      font-family: "silka-regular-webfont", sans-serif;
      letter-spacing: normal; }
      .content.minicart-items .minicart-items .product-title .product-item-name {
        font-weight: 500; }
      .content.minicart-items .minicart-items .product-title .item-options {
        font-size: 90%; }
      .content.minicart-items .minicart-items .product-title .label {
        padding: 0;
        vertical-align: middle;
        color: #181818;
        font-size: inherit;
        line-height: inherit;
        white-space: normal;
        background: none;
        margin-right: 2px; }
    .content.minicart-items .minicart-items .product-qty {
      width: 10%;
      text-align: center;
      padding: 0 .25rem;
      color: rgba(24, 24, 24, 0.5);
      font-family: "silka-regular-webfont", sans-serif;
      letter-spacing: normal; }
    .content.minicart-items .minicart-items .product-subtotal {
      width: 20%;
      padding: 0 .25rem;
      font-weight: 500;
      font-family: "silka-regular-webfont", sans-serif;
      letter-spacing: normal; }

.page-header {
  height: 4.5rem; }
  @media print, screen and (min-width: 48em) {
    .page-header {
      height: 10.25rem; } }
  @media screen and (min-width: 64em) {
    .page-header {
      height: 10.25rem; } }
  @media screen and (min-width: 75em) {
    .page-header {
      height: 10.25rem; } }
  @media screen and (min-width: 90.625em) {
    .page-header {
      height: 10.25rem; } }

.header {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto; }
  .header::before, .header::after {
    display: table;
    content: ' '; }
  .header::after {
    clear: both; }
  .header .header-row::before, .header .header-row::after {
    display: table;
    content: ' '; }
  .header .header-row::after {
    clear: both; }
  @media screen and (min-width: 90.625em) {
    .header .header-row .top-nav-search-sticky-row {
      max-width: 90.625rem;
      margin-right: auto;
      margin-left: auto;
      margin-right: -1.0625rem;
      margin-left: -1.0625rem; }
      .header .header-row .top-nav-search-sticky-row::before, .header .header-row .top-nav-search-sticky-row::after {
        display: table;
        content: ' '; }
      .header .header-row .top-nav-search-sticky-row::after {
        clear: both; } }
  @media screen and (min-width: 90.625em) and (min-width: 40em) {
    .header .header-row .top-nav-search-sticky-row {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) and (min-width: 48em) {
    .header .header-row .top-nav-search-sticky-row {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) and (min-width: 64em) {
    .header .header-row .top-nav-search-sticky-row {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) and (min-width: 75em) {
    .header .header-row .top-nav-search-sticky-row {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) and (min-width: 90.625em) {
    .header .header-row .top-nav-search-sticky-row {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .is-sticky .header .header-row .nav-sections a,
  .is-sticky .header .header-row a.action.showcart {
    color: white; }
  .is-sticky .header .header-row .navigation {
    padding-right: 0; }
    @media print, screen and (min-width: 40em) {
      .is-sticky .header .header-row .navigation > .ui-menu > li > a {
        padding-left: 0.625rem;
        padding-right: 0.625rem; } }
    @media screen and (min-width: 64em) {
      .is-sticky .header .header-row .navigation > .ui-menu > li > a {
        padding-left: 1rem;
        padding-right: 1rem; } }
    @media screen and (min-width: 75em) {
      .is-sticky .header .header-row .navigation > .ui-menu > li > a {
        padding-left: 1.25rem;
        padding-right: 1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .is-sticky .header .header-row .navigation > .ui-menu > li > a {
        padding-left: 1.25rem;
        padding-right: 1.25rem; } }
    @media print, screen and (min-width: 40em) {
      .is-sticky .header .header-row .navigation > .ui-menu > li > a {
        padding-top: 0.8125rem;
        padding-bottom: 0.8125rem; } }
    @media screen and (min-width: 64em) {
      .is-sticky .header .header-row .navigation > .ui-menu > li > a {
        padding-top: 0.8125rem;
        padding-bottom: 0.8125rem; } }
    @media screen and (min-width: 75em) {
      .is-sticky .header .header-row .navigation > .ui-menu > li > a {
        padding-top: 0.875rem;
        padding-bottom: 0.875rem; } }
    @media screen and (min-width: 90.625em) {
      .is-sticky .header .header-row .navigation > .ui-menu > li > a {
        padding-top: 0.875rem;
        padding-bottom: 0.875rem; } }
    @media print, screen and (min-width: 48em) {
      .is-sticky .header .header-row .navigation {
        margin-top: .05rem; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .is-sticky .header .header-row .navigation {
        margin-left: 0; } }
    @media screen and (min-width: 75em) {
      .is-sticky .header .header-row .navigation {
        margin-left: 0; } }
  @media screen and (min-width: 90.625em) {
    .is-sticky .header .header-row .sticky-header-row {
      max-width: 90.625rem;
      margin-right: auto;
      margin-left: auto;
      position: relative; }
      .is-sticky .header .header-row .sticky-header-row::before, .is-sticky .header .header-row .sticky-header-row::after {
        display: table;
        content: ' '; }
      .is-sticky .header .header-row .sticky-header-row::after {
        clear: both; } }
  .is-sticky .header .header-row .logo:before {
    display: none; }
  .is-sticky .header .header-row .logo img {
    max-width: 100%;
    margin-top: 0;
    width: auto; }
  @media print, screen and (min-width: 48em) {
    .is-sticky .header .header-row .logo {
      width: 90px;
      padding: 0 10px 0 0;
      position: absolute;
      top: 0;
      height: 4.75rem;
      line-height: 4.75rem;
      left: 40px; } }
  @media screen and (min-width: 48em) and (min-width: 90.625em) {
    .is-sticky .header .header-row .logo {
      left: 20px;
      top: 50%;
      transform: translateY(-50%); } }
  @media print, screen and (min-width: 48em) {
      .is-sticky .header .header-row .logo img {
        max-height: 100%; } }
  .is-sticky .header .header-row .nav-search-wrapper {
    height: 4.75rem;
    padding-top: 0; }
    .is-sticky .header .header-row .nav-search-wrapper .panel.wrapper {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }
    @media print, screen and (min-width: 48em) {
      .is-sticky .header .header-row .nav-search-wrapper {
        width: 100%;
        padding-left: 110px; }
        .ie11 .is-sticky .header .header-row .nav-search-wrapper {
          padding-left: 130px; } }
  @media screen and (min-width: 75em) {
    .is-sticky .header .header-row .top-nav-search-sticky-row {
      margin-right: -1.0625rem;
      margin-left: 0; } }
  @media screen and (min-width: 75em) and (min-width: 40em) {
    .is-sticky .header .header-row .top-nav-search-sticky-row {
      margin-right: -1.25rem; } }
  .is-sticky .header .header-row .top-nav-search-sticky-row .nav-sections {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    width: 75%; }
    @media print, screen and (min-width: 40em) {
      .is-sticky .header .header-row .top-nav-search-sticky-row .nav-sections {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .is-sticky .header .header-row .top-nav-search-sticky-row .nav-sections:last-child:not(:first-child) {
      float: right; }
    @media screen and (min-width: 769px) {
      .is-sticky .header .header-row .top-nav-search-sticky-row .nav-sections {
        width: 66.66667%; } }
    @media print, screen and (min-width: 40em) {
      .is-sticky .header .header-row .top-nav-search-sticky-row .nav-sections {
        padding-right: 0;
        padding-left: 0; } }
    @media screen and (min-width: 64em) {
      .is-sticky .header .header-row .top-nav-search-sticky-row .nav-sections {
        width: 70.83333%; } }
    @media screen and (min-width: 75em) {
      .is-sticky .header .header-row .top-nav-search-sticky-row .nav-sections {
        width: 66.66667%;
        padding-right: 0rem;
        padding-left: 0rem; } }
  @media screen and (min-width: 75em) and (min-width: 40em) {
    .is-sticky .header .header-row .top-nav-search-sticky-row .nav-sections {
      padding-right: 0rem; } }
  @media screen and (min-width: 75em) and (min-width: 40em) {
    .is-sticky .header .header-row .top-nav-search-sticky-row .nav-sections {
      padding-left: 0rem; } }
    @media screen and (min-width: 90.625em) {
      .is-sticky .header .header-row .top-nav-search-sticky-row .nav-sections {
        width: 66.66667%; } }
  .is-sticky .header .header-row .top-nav-search-sticky-row .top-search-grid-wrapper {
    width: 25%;
    margin-top: 0; }
    @media screen and (min-width: 769px) {
      .is-sticky .header .header-row .top-nav-search-sticky-row .top-search-grid-wrapper .top-search-wrapper {
        margin-bottom: 0;
        display: table;
        width: 100%; }
        .is-sticky .header .header-row .top-nav-search-sticky-row .top-search-grid-wrapper .top-search-wrapper .block-search,
        .is-sticky .header .header-row .top-nav-search-sticky-row .top-search-grid-wrapper .top-search-wrapper .minicart-wrapper {
          margin: 0;
          white-space: nowrap;
          display: table-cell;
          vertical-align: middle; }
        .is-sticky .header .header-row .top-nav-search-sticky-row .top-search-grid-wrapper .top-search-wrapper .minicart-wrapper {
          width: 1%;
          height: 100%;
          padding: 0;
          margin-top: 0;
          padding-left: 0; } }
    @media screen and (min-width: 64em) {
      .is-sticky .header .header-row .top-nav-search-sticky-row .top-search-grid-wrapper {
        width: 29.16667%; }
        .is-sticky .header .header-row .top-nav-search-sticky-row .top-search-grid-wrapper .top-search-wrapper .minicart-wrapper {
          padding-right: 0; } }
    @media screen and (min-width: 75em) {
      .is-sticky .header .header-row .top-nav-search-sticky-row .top-search-grid-wrapper {
        width: 33.33333%;
        padding-right: 1.0625rem; } }
  @media screen and (min-width: 75em) and (min-width: 40em) {
    .is-sticky .header .header-row .top-nav-search-sticky-row .top-search-grid-wrapper {
      padding-right: 1.25rem; } }
  @media screen and (max-width: 767px) {
    .is-sticky .header .header-row .top-search-grid-wrapper {
      text-align: right; }
    .is-sticky .header .header-row .top-search-wrapper {
      display: inline-block;
      line-height: 4.75rem; }
      .is-sticky .header .header-row .top-search-wrapper .sticky-search {
        display: inline-block !important;
        position: relative; }
        .is-sticky .header .header-row .top-search-wrapper .sticky-search.active .action {
          opacity: .5; }
      .is-sticky .header .header-row .top-search-wrapper .block-search {
        display: none;
        position: absolute;
        width: 100%;
        height: auto;
        top: 100%;
        right: 0;
        padding: 0.625rem 0.875rem 0.6875rem;
        background-color: #f0f0f0;
        box-shadow: none; }
        .is-sticky .header .header-row .top-search-wrapper .block-search.is-opened {
          display: block; }
        .is-sticky .header .header-row .top-search-wrapper .block-search #algolia-searchbox .algolia-search-input {
          height: 2.9375rem;
          color: #000;
          font-size: 1rem;
          border-color: #919191;
          background-color: rgba(255, 255, 255, 0.15); }
          .is-sticky .header .header-row .top-search-wrapper .block-search #algolia-searchbox .algolia-search-input::-webkit-input-placeholder {
            color: rgba(255, 255, 255, 0.5); }
          .is-sticky .header .header-row .top-search-wrapper .block-search #algolia-searchbox .algolia-search-input:-moz-placeholder {
            color: rgba(255, 255, 255, 0.5); }
          .is-sticky .header .header-row .top-search-wrapper .block-search #algolia-searchbox .algolia-search-input::-moz-placeholder {
            color: rgba(255, 255, 255, 0.5); }
          .is-sticky .header .header-row .top-search-wrapper .block-search #algolia-searchbox .algolia-search-input:-ms-input-placeholder {
            color: rgba(255, 255, 255, 0.5); }
          .is-sticky .header .header-row .top-search-wrapper .block-search #algolia-searchbox .algolia-search-input:hover, .is-sticky .header .header-row .top-search-wrapper .block-search #algolia-searchbox .algolia-search-input:active, .is-sticky .header .header-row .top-search-wrapper .block-search #algolia-searchbox .algolia-search-input:focus, .is-sticky .header .header-row .top-search-wrapper .block-search #algolia-searchbox .algolia-search-input:focus:not([value=""]), .is-sticky .header .header-row .top-search-wrapper .block-search #algolia-searchbox .algolia-search-input.focus {
            border-color: #444444;
            background-color: rgba(255, 255, 255, 0.15) !important; }
        .is-sticky .header .header-row .top-search-wrapper .block-search #algolia-searchbox #algolia-glass {
          height: 2.9375rem;
          background: url("../images/icon-search.svg") 50% 50% no-repeat;
          background-size: 1.625rem auto; }
        .is-sticky .header .header-row .top-search-wrapper .block-search .minisearch .input-text {
          height: 2.9375rem;
          color: #000;
          font-size: 1rem;
          border: 1px none #919191;
          margin: 0;
          padding: 0.5rem 3rem 0.5rem 1rem;
          background-color: rgba(255, 255, 255, 0.15); }
          .is-sticky .header .header-row .top-search-wrapper .block-search .minisearch .input-text::-webkit-input-placeholder {
            color: rgba(255, 255, 255, 0.5); }
          .is-sticky .header .header-row .top-search-wrapper .block-search .minisearch .input-text:-moz-placeholder {
            color: rgba(255, 255, 255, 0.5); }
          .is-sticky .header .header-row .top-search-wrapper .block-search .minisearch .input-text::-moz-placeholder {
            color: rgba(255, 255, 255, 0.5); }
          .is-sticky .header .header-row .top-search-wrapper .block-search .minisearch .input-text:-ms-input-placeholder {
            color: rgba(255, 255, 255, 0.5); }
          .is-sticky .header .header-row .top-search-wrapper .block-search .minisearch .input-text:hover, .is-sticky .header .header-row .top-search-wrapper .block-search .minisearch .input-text:active, .is-sticky .header .header-row .top-search-wrapper .block-search .minisearch .input-text:focus, .is-sticky .header .header-row .top-search-wrapper .block-search .minisearch .input-text:focus:not([value=""]), .is-sticky .header .header-row .top-search-wrapper .block-search .minisearch .input-text.focus {
            border-color: #444444;
            background-color: rgba(255, 255, 255, 0.15) !important; }
        .is-sticky .header .header-row .top-search-wrapper .block-search .minisearch .action.search, .is-sticky .header .header-row .top-search-wrapper .block-search .minisearch #algolia-searchbox #algolia-glass, #algolia-searchbox .is-sticky .header .header-row .top-search-wrapper .block-search .minisearch #algolia-glass,
        .is-sticky .header .header-row .top-search-wrapper .block-search .minisearch #algolia-searchbox .ais-search-box--magnifier-wrapper, #algolia-searchbox .is-sticky .header .header-row .top-search-wrapper .block-search .minisearch .ais-search-box--magnifier-wrapper {
          height: 2.9375rem;
          min-width: 3.375rem;
          line-height: 2.9375rem;
          padding: 0.3125rem 0.625rem;
          background: url("../images/icon-search.svg") 50% 50% no-repeat; }
      .is-sticky .header .header-row .top-search-wrapper .minicart-wrapper {
        display: inline-block;
        vertical-align: middle;
        width: auto;
        text-align: right;
        padding-right: 0;
        padding-left: 0;
        margin-left: 1.25rem;
        margin-top: 0; }
        .is-sticky .header .header-row .top-search-wrapper .minicart-wrapper .action.showcart {
          margin-left: 0; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .is-sticky .header .header-row .top-search-wrapper .minicart-wrapper .action.showcart {
      margin-left: .75rem; } }
  @media screen and (max-width: 63.9375em) {
    .is-sticky .header .header-row .nav-extra {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); } }
  .header .logo {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .header .logo {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .header .logo:last-child:not(:first-child) {
      float: right; }
    @media screen and (max-width: 47.9375em) {
      .header .logo {
        display: none; } }
    @media print, screen and (min-width: 48em) {
      .header .logo {
        height: auto;
        line-height: 10.25rem;
        text-align: inherit;
        width: 12.5%;
        position: relative; }
        .header .logo:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 1.0625rem;
          right: 1.0625rem;
          visibility: visible;
          opacity: 1;
          display: block;
          border: 0;
          border-right: 1px solid rgba(24, 24, 24, 0.15);
          top: 0;
          right: 0;
          left: auto; } }
    @media print, screen and (min-width: 48em) and (min-width: 40em) {
      .header .logo:before {
        left: 1.25rem; } }
    @media print, screen and (min-width: 48em) and (min-width: 40em) {
      .header .logo:before {
        right: 1.25rem; } }
    @media print, screen and (min-width: 48em) and (min-width: 40em) {
      .header .logo:before {
        right: 0; } }
    @media print, screen and (min-width: 48em) {
        .header .logo:before {
          right: 0;
          top: 15%;
          bottom: 15%; } }
    @media screen and (min-width: 64em) {
      .header .logo {
        text-align: left;
        width: 20.83333%;
        line-height: 10.25rem; }
        .header .logo:before {
          top: auto;
          bottom: 1.875rem;
          height: 2.9375rem; } }
    @media screen and (min-width: 75em) {
      .header .logo {
        width: 16.66667%;
        line-height: 10.25rem; } }
    @media screen and (min-width: 90.625em) {
      .header .logo {
        width: 16.66667%;
        line-height: 10.25rem; } }
  .header .nav-search-wrapper {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    float: right;
    padding-top: 1.45rem;
    height: 10.25rem; }
    @media print, screen and (min-width: 40em) {
      .header .nav-search-wrapper {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .header .nav-search-wrapper:last-child:not(:first-child) {
      float: right; }
    @media screen and (max-width: 47.9375em) {
      .header .nav-search-wrapper {
        display: none !important; } }
    @media print, screen and (min-width: 48em) {
      .header .nav-search-wrapper {
        width: 87.5%; } }
    @media screen and (min-width: 64em) {
      .header .nav-search-wrapper {
        height: 10.25rem;
        width: 79.16667%;
        padding-top: 1.45rem; } }
    @media screen and (min-width: 75em) {
      .header .nav-search-wrapper {
        height: 10.25rem;
        width: 83.33333%;
        padding-top: 1rem; } }
    @media screen and (min-width: 90.625em) {
      .header .nav-search-wrapper {
        height: 10.25rem;
        padding-top: 0.5rem; } }
    .header .nav-search-wrapper .panel.wrapper {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem;
      width: 79.16667%;
      height: 56px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      line-height: 1;
      margin-bottom: 0.9rem; }
      @media print, screen and (min-width: 40em) {
        .header .nav-search-wrapper .panel.wrapper {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .header .nav-search-wrapper .panel.wrapper:last-child:not(:first-child) {
        float: right; }
      @media screen and (min-width: 90.625em) {
        .header .nav-search-wrapper .panel.wrapper {
          justify-content: flex-end; }
          .header .nav-search-wrapper .panel.wrapper .top-links {
            padding-left: 1.0625rem;
            padding-right: 1.0625rem; } }
    @media screen and (min-width: 90.625em) and (min-width: 40em) {
      .header .nav-search-wrapper .panel.wrapper .top-links {
        padding-left: 1.25rem; } }
    @media screen and (min-width: 90.625em) and (min-width: 40em) {
      .header .nav-search-wrapper .panel.wrapper .top-links {
        padding-right: 1.25rem; } }
      @media print, screen and (min-width: 48em) {
        .header .nav-search-wrapper .panel.wrapper {
          padding-left: 0.53125rem;
          padding-right: 0.53125rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .header .nav-search-wrapper .panel.wrapper {
      padding-left: 0.625rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .header .nav-search-wrapper .panel.wrapper {
      padding-right: 0.625rem; } }
      @media screen and (min-width: 64em) {
        .header .nav-search-wrapper .panel.wrapper {
          padding-left: 1.0625rem;
          width: 62.5%;
          margin-bottom: 0.9rem; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .header .nav-search-wrapper .panel.wrapper {
      padding-left: 1.25rem; } }
      @media screen and (min-width: 75em) {
        .header .nav-search-wrapper .panel.wrapper {
          margin-bottom: -1.6rem; } }
      @media screen and (min-width: 90.625em) {
        .header .nav-search-wrapper .panel.wrapper {
          max-width: 90.625rem;
          margin-right: auto;
          margin-left: auto;
          margin-right: -1.0625rem;
          margin-left: -1.0625rem;
          width: auto;
          float: none;
          padding-right: 0;
          margin-bottom: 0.75rem; }
          .header .nav-search-wrapper .panel.wrapper::before, .header .nav-search-wrapper .panel.wrapper::after {
            display: table;
            content: ' '; }
          .header .nav-search-wrapper .panel.wrapper::after {
            clear: both; } }
  @media screen and (min-width: 90.625em) and (min-width: 40em) {
    .header .nav-search-wrapper .panel.wrapper {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) and (min-width: 48em) {
    .header .nav-search-wrapper .panel.wrapper {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) and (min-width: 64em) {
    .header .nav-search-wrapper .panel.wrapper {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) and (min-width: 75em) {
    .header .nav-search-wrapper .panel.wrapper {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) and (min-width: 90.625em) {
    .header .nav-search-wrapper .panel.wrapper {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
      .header .nav-search-wrapper .panel.wrapper .panel.header {
        display: inline-block;
        vertical-align: middle;
        margin-left: 0;
        margin-right: 0; }
        @media screen and (min-width: 90.625em) {
          .header .nav-search-wrapper .panel.wrapper .panel.header {
            width: 100%;
            float: left;
            padding-right: 1.0625rem;
            padding-left: 1.0625rem;
            width: 41.66667%;
            text-align: right; } }
  @media screen and (min-width: 90.625em) and (min-width: 40em) {
    .header .nav-search-wrapper .panel.wrapper .panel.header {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
        @media screen and (min-width: 90.625em) {
            .header .nav-search-wrapper .panel.wrapper .panel.header:last-child:not(:first-child) {
              float: right; } }
      .header .nav-search-wrapper .panel.wrapper .top-links {
        display: inline-block;
        vertical-align: middle; }
        @media screen and (min-width: 75em) {
          .header .nav-search-wrapper .panel.wrapper .top-links {
            text-align: right; } }
  .header .top-nav-wrapper {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    clear: both; }
    .header .top-nav-wrapper::before, .header .top-nav-wrapper::after {
      display: table;
      content: ' '; }
    .header .top-nav-wrapper::after {
      clear: both; }
  .header .nav-sections {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .header .nav-sections {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .header .nav-sections:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 40em) {
      .header .nav-sections {
        padding-right: 0;
        padding-left: 0; } }
    @media screen and (min-width: 90.625em) {
      .header .nav-sections {
        padding-left: 1.0625rem;
        padding-right: 1.0625rem;
        width: 64.58333%; } }
  @media screen and (min-width: 90.625em) and (min-width: 40em) {
    .header .nav-sections {
      padding-left: 1.25rem; } }
  @media screen and (min-width: 90.625em) and (min-width: 40em) {
    .header .nav-sections {
      padding-right: 1.25rem; } }
  .header .top-search-grid-wrapper {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .header .top-search-grid-wrapper {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .header .top-search-grid-wrapper:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 48em) {
      .header .top-search-grid-wrapper {
        width: 35.41667%;
        padding-right: 0;
        padding-left: 0; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .header .top-search-grid-wrapper {
        margin-top: -7rem; } }
    @media screen and (min-width: 64em) and (max-width: 74.9375em) {
      .header .top-search-grid-wrapper {
        margin-top: -6.75rem; } }
    @media screen and (min-width: 75em) and (max-width: 90.5625em) {
      .header .top-search-grid-wrapper {
        margin-top: -4.2rem; } }
    @media screen and (min-width: 64em) {
      .header .top-search-grid-wrapper {
        width: 35.41667%; } }
    @media screen and (min-width: 75em) {
      .header .top-search-grid-wrapper {
        padding-left: 40px;
        padding-right: 0;
        width: 41.66667%; } }
    @media screen and (min-width: 90.625em) {
      .header .top-search-grid-wrapper {
        padding-right: 0;
        width: 35.41667%;
        margin-top: 0; } }
  .header .navigation {
    padding-right: 4.125rem; }
    @media screen and (min-width: 75em) {
      .header .navigation {
        padding-right: 0; } }
    @media screen and (min-width: 90.625em) {
      .header .navigation {
        margin-top: .2rem; } }
    .header .navigation > ul,
    .header .navigation > .ui-menu {
      margin-left: 0; }
      @media screen and (min-width: 64em) {
        .header .navigation > ul,
        .header .navigation > .ui-menu {
          margin-left: 0; } }
      @media screen and (min-width: 75em) {
        .header .navigation > ul,
        .header .navigation > .ui-menu {
          margin-left: 0; } }
      @media screen and (min-width: 90.625em) {
        .header .navigation > ul,
        .header .navigation > .ui-menu {
          margin-left: 0; } }

.header .sticky-mm {
  position: fixed;
  width: 100%;
  z-index: 102;
  left: 0;
  top: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3), 0 0 15px rgba(0, 0, 0, 0.15); }
  .header .sticky-mm.in-scroll {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3), 0 0 15px rgba(0, 0, 0, 0.15); }
  .header .sticky-mm.has-up-animation {
    transition: top .5s ease-in-out; }
  .header .sticky-mm.has-up-animation.nav-up {
    top: -200px; }

.header .tab-bar {
  background-color: #1A2746; }
  .header .tab-bar::before, .header .tab-bar::after {
    display: table;
    content: ' '; }
  .header .tab-bar::after {
    clear: both; }
  .header .tab-bar .nav-toggle {
    display: inline-block;
    vertical-align: top;
    position: relative;
    height: 4.5rem;
    min-width: 1.25rem;
    margin-right: 11.33333px;
    background: url("../images/icon-toggle.svg") 50% 50% no-repeat;
    background-size: 100% auto; }
    .header .tab-bar .nav-toggle span {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }
    @media print, screen and (min-width: 48em) {
      .header .tab-bar .nav-toggle {
        display: none; } }
  .header .tab-bar .mobile-search-icon {
    display: inline-block;
    vertical-align: top;
    height: 4.5rem;
    min-width: 1.6875rem;
    background: url("../images/icon-search.svg") 50% 50% no-repeat;
    background-size: 100% auto; }
    .header .tab-bar .mobile-search-icon.is-open {
      opacity: 0.5; }
    .header .tab-bar .mobile-search-icon span {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }
  .header .tab-bar .mobile-account-icon {
    height: 4.5rem; }
  .header .tab-bar .middle-side {
    display: block;
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    width: 33.33333%;
    padding-left: 0.53125rem;
    padding-right: 0.53125rem;
    height: 4.5rem;
    line-height: 3.375rem;
    text-align: center;
    padding-top: 0.5625rem;
    padding-bottom: 0.5625rem;
    padding-top: 0.81818rem; }
    @media print, screen and (min-width: 40em) {
      .header .tab-bar .middle-side {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .header .tab-bar .middle-side:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 40em) {
      .header .tab-bar .middle-side {
        padding-left: 0.625rem; } }
    @media print, screen and (min-width: 40em) {
      .header .tab-bar .middle-side {
        padding-right: 0.625rem; } }
    .header .tab-bar .middle-side .logo {
      height: 100%;
      display: inline-block;
      float: none;
      padding-left: 0;
      padding-right: 0; }
      .header .tab-bar .middle-side .logo img {
        width: 123px;
        max-width: 100%; }
      .header .tab-bar .middle-side .logo img {
        height: 46px;
        max-height: inherit;
        width: auto; }
  .header .tab-bar .left-side {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    width: 33.33333%; }
    @media print, screen and (min-width: 40em) {
      .header .tab-bar .left-side {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .header .tab-bar .left-side:last-child:not(:first-child) {
      float: right; }
  .header .tab-bar .right-side {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    width: 33.33333%;
    text-align: right; }
    @media print, screen and (min-width: 40em) {
      .header .tab-bar .right-side {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .header .tab-bar .right-side:last-child:not(:first-child) {
      float: right; }
    .header .tab-bar .right-side .mobile-search-icon {
      margin-right: .5rem;
      opacity: 0.7; }
      .header .tab-bar .right-side .mobile-search-icon.is-open {
        opacity: 0.5; }
    .header .tab-bar .right-side .minicart-wrapper {
      text-align: right;
      padding: 0;
      height: 4.5rem;
      line-height: 4.5rem;
      margin: 0;
      opacity: 0.7; }
      @media screen and (max-width: 47.9375em) {
        .header .tab-bar .right-side .minicart-wrapper:after {
          display: none; } }

.page-container {
  margin-top: 0;
  transition: margin-top .3s ease; }
  .mobile-search-bar-open .page-container {
    margin-top: 5.5rem; }

footer.page-footer {
  overflow: hidden;
  position: relative;
  font-size: 15.3px;
  transition: background-image .3s ease, background-position .3s ease;
  background: #1A2746 url("../images/bg__footer--s.png") 100% 0 no-repeat;
  background-size: auto; }
  @media print, screen and (min-width: 40em) {
    footer.page-footer {
      font-size: 17px; } }
  @media screen and (min-width: 64em) {
    footer.page-footer {
      background: #1A2746 url("../images/bg__footer.png") 100% 100% no-repeat;
      background-size: auto; } }
  footer.page-footer .footer-content {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    padding-top: 12.75rem; }
    footer.page-footer .footer-content::before, footer.page-footer .footer-content::after {
      display: table;
      content: ' '; }
    footer.page-footer .footer-content::after {
      clear: both; }
    @media print, screen and (min-width: 40em) {
      footer.page-footer .footer-content {
        padding-top: 15rem; } }
    @media print, screen and (min-width: 40em) {
      footer.page-footer .footer-content {
        padding-top: 1.59375rem; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    footer.page-footer .footer-content {
      padding-top: 1.875rem; } }
    @media screen and (min-width: 75em) {
      footer.page-footer .footer-content {
        padding-top: 4.0375rem; } }
  @media screen and (min-width: 75em) and (min-width: 40em) {
    footer.page-footer .footer-content {
      padding-top: 4.75rem; } }
    footer.page-footer .footer-content .footer-list {
      max-width: 90.625rem;
      margin-right: auto;
      margin-left: auto;
      position: relative; }
      footer.page-footer .footer-content .footer-list::before, footer.page-footer .footer-content .footer-list::after {
        display: table;
        content: ' '; }
      footer.page-footer .footer-content .footer-list::after {
        clear: both; }
      footer.page-footer .footer-content .footer-list .footer-block {
        width: 100%;
        float: left;
        padding-right: 1.0625rem;
        padding-left: 1.0625rem;
        position: relative;
        padding-top: 2.65625rem;
        padding-bottom: 2.65625rem;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.15); }
        @media print, screen and (min-width: 40em) {
          footer.page-footer .footer-content .footer-list .footer-block {
            padding-right: 1.25rem;
            padding-left: 1.25rem; } }
        footer.page-footer .footer-content .footer-list .footer-block:last-child:not(:first-child) {
          float: right; }
        @media print, screen and (min-width: 40em) {
          footer.page-footer .footer-content .footer-list .footer-block {
            padding-top: 3.125rem; } }
        @media print, screen and (min-width: 40em) {
          footer.page-footer .footer-content .footer-list .footer-block {
            padding-bottom: 3.125rem; } }
        footer.page-footer .footer-content .footer-list .footer-block:last-child {
          border-bottom: 0; }
        @media print, screen and (min-width: 40em) {
          footer.page-footer .footer-content .footer-list .footer-block {
            width: 25%;
            padding-top: 2.125rem;
            padding-bottom: 2.125rem;
            border-bottom: 0; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    footer.page-footer .footer-content .footer-list .footer-block {
      padding-top: 2.5rem; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    footer.page-footer .footer-content .footer-list .footer-block {
      padding-bottom: 2.5rem; } }
        @media print, screen and (min-width: 40em) {
            footer.page-footer .footer-content .footer-list .footer-block:nth-child(3) {
              clear: right; }
            footer.page-footer .footer-content .footer-list .footer-block:nth-child(4) {
              clear: left; }
            footer.page-footer .footer-content .footer-list .footer-block:nth-child(4), footer.page-footer .footer-content .footer-list .footer-block:nth-child(5) {
              width: 50%; } }
        @media screen and (min-width: 64em) {
          footer.page-footer .footer-content .footer-list .footer-block {
            width: 25%;
            padding-bottom: 3.1875rem; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    footer.page-footer .footer-content .footer-list .footer-block {
      padding-bottom: 3.75rem; } }
        @media screen and (min-width: 64em) {
            footer.page-footer .footer-content .footer-list .footer-block:nth-child(4) h3,
            footer.page-footer .footer-content .footer-list .footer-block:nth-child(4) .newsletter .title, footer.page-footer .footer-content .footer-list .footer-block:nth-child(5) h3,
            footer.page-footer .footer-content .footer-list .footer-block:nth-child(5) .newsletter .title {
              margin-bottom: 0.4rem; }
            footer.page-footer .footer-content .footer-list .footer-block:nth-child(4) p,
            footer.page-footer .footer-content .footer-list .footer-block:nth-child(4) .description, footer.page-footer .footer-content .footer-list .footer-block:nth-child(5) p,
            footer.page-footer .footer-content .footer-list .footer-block:nth-child(5) .description {
              margin-bottom: 0.65rem; } }
        @media screen and (min-width: 75em) {
          footer.page-footer .footer-content .footer-list .footer-block {
            padding-top: 2.3375rem; } }
  @media screen and (min-width: 75em) and (min-width: 40em) {
    footer.page-footer .footer-content .footer-list .footer-block {
      padding-top: 2.75rem; } }
      footer.page-footer .footer-content .footer-list .footer-block:last-child:last-child {
        float: left; }
      @media print, screen and (min-width: 40em) {
        footer.page-footer .footer-content .footer-list .footer-block {
          width: 50%; }
          footer.page-footer .footer-content .footer-list .footer-block:nth-child(4) {
            clear: none; }
          footer.page-footer .footer-content .footer-list .footer-block:nth-child(3) {
            clear: both; }
          footer.page-footer .footer-content .footer-list .footer-block:nth-child(5) {
            clear: left; } }
      @media print, screen and (min-width: 48em) {
        footer.page-footer .footer-content .footer-list .footer-block {
          width: 25%; }
          footer.page-footer .footer-content .footer-list .footer-block:nth-child(3), footer.page-footer .footer-content .footer-list .footer-block:nth-child(4) {
            clear: none; }
          footer.page-footer .footer-content .footer-list .footer-block:nth-child(4), footer.page-footer .footer-content .footer-list .footer-block:nth-child(5) {
            width: 25%; } }
      @media screen and (min-width: 75em) {
        footer.page-footer .footer-content .footer-list .footer-block {
          padding-top: 2.3375rem;
          padding-bottom: 2.3375rem; } }
  @media screen and (min-width: 75em) and (min-width: 40em) {
    footer.page-footer .footer-content .footer-list .footer-block {
      padding-top: 2.75rem; } }
  @media screen and (min-width: 75em) and (min-width: 40em) {
    footer.page-footer .footer-content .footer-list .footer-block {
      padding-bottom: 2.75rem; } }
      @media screen and (max-width: 63.9375em) {
        footer.page-footer .footer-content .footer-list .footer-block.social {
          text-align: left; } }
  footer.page-footer .footer-bottom {
    background-color: transparent; }
    footer.page-footer .footer-bottom .footer-wrap {
      max-width: 90.625rem;
      margin-right: auto;
      margin-left: auto; }
      footer.page-footer .footer-bottom .footer-wrap::before, footer.page-footer .footer-bottom .footer-wrap::after {
        display: table;
        content: ' '; }
      footer.page-footer .footer-bottom .footer-wrap::after {
        clear: both; }
    footer.page-footer .footer-bottom .footer-list {
      max-width: 90.625rem;
      margin-right: auto;
      margin-left: auto; }
      footer.page-footer .footer-bottom .footer-list::before, footer.page-footer .footer-bottom .footer-list::after {
        display: table;
        content: ' '; }
      footer.page-footer .footer-bottom .footer-list::after {
        clear: both; }
      @media print, screen and (min-width: 40em) {
        footer.page-footer .footer-bottom .footer-list {
          padding-left: 0;
          padding-right: 0; } }
      footer.page-footer .footer-bottom .footer-list .footer-block {
        width: 100%;
        float: left;
        padding-right: 1.0625rem;
        padding-left: 1.0625rem;
        width: 100%;
        padding-top: 1.59375rem;
        padding-bottom: 1.59375rem;
        position: relative;
        margin-bottom: 0;
        z-index: 1; }
        @media print, screen and (min-width: 40em) {
          footer.page-footer .footer-bottom .footer-list .footer-block {
            padding-right: 1.25rem;
            padding-left: 1.25rem; } }
        footer.page-footer .footer-bottom .footer-list .footer-block:last-child:not(:first-child) {
          float: right; }
        @media print, screen and (min-width: 40em) {
          footer.page-footer .footer-bottom .footer-list .footer-block {
            padding-top: 1.875rem; } }
        @media print, screen and (min-width: 40em) {
          footer.page-footer .footer-bottom .footer-list .footer-block {
            padding-bottom: 1.875rem; } }
        footer.page-footer .footer-bottom .footer-list .footer-block + .footer-block {
          padding-top: 0; }
        footer.page-footer .footer-bottom .footer-list .footer-block p {
          margin-bottom: 0; }
        @media print, screen and (min-width: 40em) {
          footer.page-footer .footer-bottom .footer-list .footer-block {
            width: 50%;
            padding-bottom: 1.7rem;
            padding-top: 1.7rem; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    footer.page-footer .footer-bottom .footer-list .footer-block {
      padding-bottom: 2rem; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    footer.page-footer .footer-bottom .footer-list .footer-block {
      padding-top: 2rem; } }
        @media print, screen and (min-width: 40em) {
            footer.page-footer .footer-bottom .footer-list .footer-block + .footer-block {
              padding-top: 1.7rem;
              text-align: right; } }
    @media print, screen and (min-width: 40em) and (min-width: 40em) {
      footer.page-footer .footer-bottom .footer-list .footer-block + .footer-block {
        padding-top: 2rem; } }
        @media screen and (min-width: 75em) {
          footer.page-footer .footer-bottom .footer-list .footer-block {
            padding-bottom: 2.125rem;
            padding-top: 6.90625rem; } }
  @media screen and (min-width: 75em) and (min-width: 40em) {
    footer.page-footer .footer-bottom .footer-list .footer-block {
      padding-bottom: 2.5rem; } }
  @media screen and (min-width: 75em) and (min-width: 40em) {
    footer.page-footer .footer-bottom .footer-list .footer-block {
      padding-top: 8.125rem; } }
        @media screen and (min-width: 75em) {
            footer.page-footer .footer-bottom .footer-list .footer-block + .footer-block {
              padding-top: 6.90625rem; } }
    @media screen and (min-width: 75em) and (min-width: 40em) {
      footer.page-footer .footer-bottom .footer-list .footer-block + .footer-block {
        padding-top: 8.125rem; } }
  footer.page-footer .footer-block.social {
    text-align: left; }
  footer.page-footer ul {
    margin: 0;
    list-style: none; }
    footer.page-footer ul li {
      padding: 0; }
      footer.page-footer ul li:after {
        display: none; }
  footer.page-footer p,
  footer.page-footer ul {
    font-family: inherit;
    color: white; }
  footer.page-footer p {
    font-size: 15.3px; }
    @media print, screen and (min-width: 40em) {
      footer.page-footer p {
        font-size: 17px; } }
  footer.page-footer a {
    color: white;
    text-decoration: none; }
  footer.page-footer h3,
  footer.page-footer h3.convert-block:not(:empty),
  footer.page-footer .newsletter .title {
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-size: 17.6px;
    color: white;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 0.5rem;
    text-transform: none; }
    @media print, screen and (min-width: 40em) {
      footer.page-footer h3,
      footer.page-footer h3.convert-block:not(:empty),
      footer.page-footer .newsletter .title {
        font-size: 22px; } }
    footer.page-footer h3 strong,
    footer.page-footer h3.convert-block:not(:empty) strong,
    footer.page-footer .newsletter .title strong {
      font-weight: inherit; }
    @media screen and (min-width: 64em) {
      footer.page-footer h3,
      footer.page-footer h3.convert-block:not(:empty),
      footer.page-footer .newsletter .title {
        margin-bottom: 0.85rem; } }
  footer.page-footer .convert-block:not(:empty) {
    margin-bottom: 0; }
  footer.page-footer ul.links li,
  footer.page-footer .pimcore_area_document-menu ul li,
  footer.page-footer .footer-block .convert-wysiwyg ul li {
    padding: 0.1rem 0 0.1rem; }
  footer.page-footer ul.links a,
  footer.page-footer .pimcore_area_document-menu ul a,
  footer.page-footer .footer-block .convert-wysiwyg ul a {
    display: block;
    text-transform: none;
    font-family: inherit;
    color: white;
    font-weight: 400;
    padding: 0; }
    footer.page-footer ul.links a:hover,
    footer.page-footer .pimcore_area_document-menu ul a:hover,
    footer.page-footer .footer-block .convert-wysiwyg ul a:hover {
      color: white;
      text-decoration: underline; }
  footer.page-footer .copyright {
    display: block;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    font-family: inherit; }
    footer.page-footer .copyright p {
      margin-bottom: 0; }
  footer.page-footer .newsletter {
    text-align: center; }
    footer.page-footer .newsletter input,
    footer.page-footer .newsletter button,
    footer.page-footer .newsletter .action {
      margin-bottom: 0; }
    footer.page-footer .newsletter .label {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }
    footer.page-footer .newsletter .description {
      line-height: 1.5;
      margin-bottom: 39px;
      font-size: 15px;
      font-weight: inherit;
      font-family: inherit;
      line-height: inherit; }
    footer.page-footer .newsletter .form.subscribe {
      margin-bottom: .5rem;
      max-width: 22.5625rem;
      margin-left: auto;
      margin-right: auto; }
      footer.page-footer .newsletter .form.subscribe .field.newsletter {
        position: relative;
        margin-bottom: 1rem; }
        footer.page-footer .newsletter .form.subscribe .field.newsletter input {
          height: 3.125rem;
          padding: 0.75rem 20px;
          font-size: 15px;
          font-family: "silka-regular-webfont", sans-serif;
          font-weight: 400;
          color: #181818;
          border-color: transparent;
          background-color: white;
          text-align: left;
          text-transform: none;
          transition: all .3s ease;
          border: 0;
          border-bottom: 1px solid transparent; }
          footer.page-footer .newsletter .form.subscribe .field.newsletter input::-webkit-input-placeholder {
            color: rgba(24, 24, 24, 0.7); }
          footer.page-footer .newsletter .form.subscribe .field.newsletter input:-moz-placeholder {
            color: rgba(24, 24, 24, 0.7); }
          footer.page-footer .newsletter .form.subscribe .field.newsletter input::-moz-placeholder {
            color: rgba(24, 24, 24, 0.7); }
          footer.page-footer .newsletter .form.subscribe .field.newsletter input:-ms-input-placeholder {
            color: rgba(24, 24, 24, 0.7); }
          footer.page-footer .newsletter .form.subscribe .field.newsletter input:active, footer.page-footer .newsletter .form.subscribe .field.newsletter input:focus {
            border-bottom-color: rgba(0, 0, 0, 0); }
          @media screen and (min-width: 64em) {
            footer.page-footer .newsletter .form.subscribe .field.newsletter input {
              height: 3.125rem; } }
      footer.page-footer .newsletter .form.subscribe .field.terms {
        line-height: 1.2;
        margin-bottom: .75rem;
        position: relative; }
        footer.page-footer .newsletter .form.subscribe .field.terms input[type="checkbox"] {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          margin: 0; }
          footer.page-footer .newsletter .form.subscribe .field.terms input[type="checkbox"] + label,
          footer.page-footer .newsletter .form.subscribe .field.terms input[type="checkbox"] + .label {
            cursor: pointer;
            font-weight: normal;
            position: relative;
            margin: 0;
            line-height: 1.2;
            min-height: 1rem;
            padding-left: 1.4rem; }
            footer.page-footer .newsletter .form.subscribe .field.terms input[type="checkbox"] + label:before,
            footer.page-footer .newsletter .form.subscribe .field.terms input[type="checkbox"] + .label:before {
              display: block;
              content: '';
              height: 1rem;
              width: 1rem;
              line-height: 1rem;
              text-align: center;
              position: absolute;
              top: 2px;
              left: 0;
              border: 1px solid #9DA3AF;
              color: #181818;
              background: white; }
          footer.page-footer .newsletter .form.subscribe .field.terms input[type="checkbox"]:checked + label:before,
          footer.page-footer .newsletter .form.subscribe .field.terms input[type="checkbox"]:checked + .label:before {
            content: '✓'; }
          footer.page-footer .newsletter .form.subscribe .field.terms input[type="checkbox"]:disabled + label {
            opacity: .4; }
          footer.page-footer .newsletter .form.subscribe .field.terms input[type="checkbox"]:disabled + label:before,
          footer.page-footer .newsletter .form.subscribe .field.terms input[type="checkbox"]:disabled + .label:before {
            background: #eee; }
        footer.page-footer .newsletter .form.subscribe .field.terms .label {
          position: static !important;
          width: auto;
          height: auto;
          overflow: visible;
          clip: auto;
          color: #181818;
          font-size: 15px;
          font-weight: 400;
          line-height: inherit;
          cursor: default; }
          footer.page-footer .newsletter .form.subscribe .field.terms .label a {
            color: #181818;
            text-decoration: underline; }
            footer.page-footer .newsletter .form.subscribe .field.terms .label a:hover {
              text-decoration: none; }
      footer.page-footer .newsletter .form.subscribe .actions .action {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        height: 3.125rem;
        background-color: #30A95E;
        color: #fff;
        color: white;
        font-size: 19px;
        font-weight: 700;
        font-style: inherit;
        text-transform: none;
        background-image: none; }
        @media screen and (min-width: 64em) {
          footer.page-footer .newsletter .form.subscribe .actions .action {
            height: 3.125rem; } }
        footer.page-footer .newsletter .form.subscribe .actions .action:hover, footer.page-footer .newsletter .form.subscribe .actions .action:focus {
          background-color: #2b8c50;
          color: #fff; }
        footer.page-footer .newsletter .form.subscribe .actions .action:hover, footer.page-footer .newsletter .form.subscribe .actions .action:active, footer.page-footer .newsletter .form.subscribe .actions .action:focus {
          color: white; }
    footer.page-footer .newsletter .response {
      color: white;
      font-size: 0.75rem;
      border: 1px solid white;
      max-width: 22.5625rem;
      padding: 1rem 1rem 1.25rem 2.9rem;
      background: url("../images/icon-valid-white.svg") no-repeat 1rem 1.5rem;
      background-size: 17px auto;
      background-color: transparent; }
      footer.page-footer .newsletter .response .ttl {
        display: none;
        font-family: "silka-regular-webfont", sans-serif;
        letter-spacing: normal;
        font-size: 22px;
        color: white;
        font-weight: 400;
        margin-bottom: 0.16667rem; }
      footer.page-footer .newsletter .response .message-text {
        margin-bottom: 0 !important; }
      footer.page-footer .newsletter .response.success .error-ttl {
        display: none; }
      footer.page-footer .newsletter .response.success .success-ttl {
        display: block; }
      footer.page-footer .newsletter .response.error {
        border-color: #D41367;
        background-image: url("../images/icon-invalid.svg");
        background-color: white; }
        footer.page-footer .newsletter .response.error .ttl,
        footer.page-footer .newsletter .response.error .message-text {
          color: #D41367; }
        footer.page-footer .newsletter .response.error .error-ttl {
          display: block; }
        footer.page-footer .newsletter .response.error .success-ttl {
          display: none; }
    footer.page-footer .newsletter div.mage-error {
      white-space: normal;
      font-size: .75rem; }
  footer.page-footer .footer-payment {
    text-align: center; }
    @media print, screen and (min-width: 40em) {
      footer.page-footer .footer-payment {
        text-align: right; } }
    footer.page-footer .footer-payment p {
      margin-bottom: 0.625rem; }
  footer.page-footer .footer-contacts li {
    padding: 0.1rem 0 0.1rem; }

.footer.links .phone {
  display: block;
  font-size: 1.0125rem;
  font-family: "silka-regular-webfont", sans-serif;
  letter-spacing: normal;
  color: white;
  line-height: 1;
  font-weight: 700;
  margin-top: .25rem;
  margin-bottom: .25rem;
  padding: 0 0 3.5px 1.375rem;
  background: url("../images/icon-phone.svg") 0 50% no-repeat;
  background-size: 14px auto; }
  @media print, screen and (min-width: 40em) {
    .footer.links .phone {
      font-size: 1.125rem; } }

.footer.links .email {
  color: white;
  font-weight: 400;
  text-decoration: underline; }
  .footer.links .email:hover {
    text-decoration: none; }

.ais-Hits-list,
.ais-InfiniteHits-list {
  margin: 0;
  list-style: none; }
  .ais-Hits-list li,
  .ais-InfiniteHits-list li {
    padding: 0; }
    .ais-Hits-list li:after,
    .ais-InfiniteHits-list li:after {
      display: none; }

.product-items,
.ais-Hits,
.ais-InfiniteHits {
  margin: 0;
  list-style: none;
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-left: -0.53125rem;
  margin-right: -0.53125rem; }
  .product-items li,
  .ais-Hits li,
  .ais-InfiniteHits li {
    padding: 0; }
    .product-items li:after,
    .ais-Hits li:after,
    .ais-InfiniteHits li:after {
      display: none; }
  .product-items::before, .product-items::after,
  .ais-Hits::before,
  .ais-Hits::after,
  .ais-InfiniteHits::before,
  .ais-InfiniteHits::after {
    display: table;
    content: ' '; }
  .product-items::after,
  .ais-Hits::after,
  .ais-InfiniteHits::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .product-items,
    .ais-Hits,
    .ais-InfiniteHits {
      margin-left: -0.625rem; } }
  @media print, screen and (min-width: 40em) {
    .product-items,
    .ais-Hits,
    .ais-InfiniteHits {
      margin-right: -0.625rem; } }
  @media print, screen and (min-width: 48em) {
    .product-items.widget-product-grid, .product-items.bestseller-products-grid, .product-items.widget-new-grid, .product-items.items,
    .ais-Hits.widget-product-grid,
    .ais-Hits.bestseller-products-grid,
    .ais-Hits.widget-new-grid,
    .ais-Hits.items,
    .ais-InfiniteHits.widget-product-grid,
    .ais-InfiniteHits.bestseller-products-grid,
    .ais-InfiniteHits.widget-new-grid,
    .ais-InfiniteHits.items {
      margin-left: -0.53125rem;
      margin-right: -0.53125rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .product-items.widget-product-grid, .product-items.bestseller-products-grid, .product-items.widget-new-grid, .product-items.items,
    .ais-Hits.widget-product-grid,
    .ais-Hits.bestseller-products-grid,
    .ais-Hits.widget-new-grid,
    .ais-Hits.items,
    .ais-InfiniteHits.widget-product-grid,
    .ais-InfiniteHits.bestseller-products-grid,
    .ais-InfiniteHits.widget-new-grid,
    .ais-InfiniteHits.items {
      margin-left: -0.625rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .product-items.widget-product-grid, .product-items.bestseller-products-grid, .product-items.widget-new-grid, .product-items.items,
    .ais-Hits.widget-product-grid,
    .ais-Hits.bestseller-products-grid,
    .ais-Hits.widget-new-grid,
    .ais-Hits.items,
    .ais-InfiniteHits.widget-product-grid,
    .ais-InfiniteHits.bestseller-products-grid,
    .ais-InfiniteHits.widget-new-grid,
    .ais-InfiniteHits.items {
      margin-right: -0.625rem; } }
  @media screen and (min-width: 64em) {
    .product-items.widget-product-grid, .product-items.bestseller-products-grid, .product-items.widget-new-grid, .product-items.items,
    .ais-Hits.widget-product-grid,
    .ais-Hits.bestseller-products-grid,
    .ais-Hits.widget-new-grid,
    .ais-Hits.items,
    .ais-InfiniteHits.widget-product-grid,
    .ais-InfiniteHits.bestseller-products-grid,
    .ais-InfiniteHits.widget-new-grid,
    .ais-InfiniteHits.items {
      margin-left: -0.53125rem;
      margin-right: -0.53125rem; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .product-items.widget-product-grid, .product-items.bestseller-products-grid, .product-items.widget-new-grid, .product-items.items,
    .ais-Hits.widget-product-grid,
    .ais-Hits.bestseller-products-grid,
    .ais-Hits.widget-new-grid,
    .ais-Hits.items,
    .ais-InfiniteHits.widget-product-grid,
    .ais-InfiniteHits.bestseller-products-grid,
    .ais-InfiniteHits.widget-new-grid,
    .ais-InfiniteHits.items {
      margin-left: -0.625rem; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .product-items.widget-product-grid, .product-items.bestseller-products-grid, .product-items.widget-new-grid, .product-items.items,
    .ais-Hits.widget-product-grid,
    .ais-Hits.bestseller-products-grid,
    .ais-Hits.widget-new-grid,
    .ais-Hits.items,
    .ais-InfiniteHits.widget-product-grid,
    .ais-InfiniteHits.bestseller-products-grid,
    .ais-InfiniteHits.widget-new-grid,
    .ais-InfiniteHits.items {
      margin-right: -0.625rem; } }
  .algolia-right-container .product-items.widget-product-grid, .algolia-right-container .product-items.bestseller-products-grid, .algolia-right-container .product-items.widget-new-grid, .algolia-right-container .product-items.items, .algolia-right-container
  .ais-Hits.widget-product-grid, .algolia-right-container
  .ais-Hits.bestseller-products-grid, .algolia-right-container
  .ais-Hits.widget-new-grid, .algolia-right-container
  .ais-Hits.items, .algolia-right-container
  .ais-InfiniteHits.widget-product-grid, .algolia-right-container
  .ais-InfiniteHits.bestseller-products-grid, .algolia-right-container
  .ais-InfiniteHits.widget-new-grid, .algolia-right-container
  .ais-InfiniteHits.items {
    margin-left: -0.53125rem;
    margin-right: -0.53125rem; }
    @media print, screen and (min-width: 40em) {
      .algolia-right-container .product-items.widget-product-grid, .algolia-right-container .product-items.bestseller-products-grid, .algolia-right-container .product-items.widget-new-grid, .algolia-right-container .product-items.items, .algolia-right-container
      .ais-Hits.widget-product-grid, .algolia-right-container
      .ais-Hits.bestseller-products-grid, .algolia-right-container
      .ais-Hits.widget-new-grid, .algolia-right-container
      .ais-Hits.items, .algolia-right-container
      .ais-InfiniteHits.widget-product-grid, .algolia-right-container
      .ais-InfiniteHits.bestseller-products-grid, .algolia-right-container
      .ais-InfiniteHits.widget-new-grid, .algolia-right-container
      .ais-InfiniteHits.items {
        margin-left: -0.625rem; } }
    @media print, screen and (min-width: 40em) {
      .algolia-right-container .product-items.widget-product-grid, .algolia-right-container .product-items.bestseller-products-grid, .algolia-right-container .product-items.widget-new-grid, .algolia-right-container .product-items.items, .algolia-right-container
      .ais-Hits.widget-product-grid, .algolia-right-container
      .ais-Hits.bestseller-products-grid, .algolia-right-container
      .ais-Hits.widget-new-grid, .algolia-right-container
      .ais-Hits.items, .algolia-right-container
      .ais-InfiniteHits.widget-product-grid, .algolia-right-container
      .ais-InfiniteHits.bestseller-products-grid, .algolia-right-container
      .ais-InfiniteHits.widget-new-grid, .algolia-right-container
      .ais-InfiniteHits.items {
        margin-right: -0.625rem; } }
    @media screen and (min-width: 64em) {
      .algolia-right-container .product-items.widget-product-grid, .algolia-right-container .product-items.bestseller-products-grid, .algolia-right-container .product-items.widget-new-grid, .algolia-right-container .product-items.items, .algolia-right-container
      .ais-Hits.widget-product-grid, .algolia-right-container
      .ais-Hits.bestseller-products-grid, .algolia-right-container
      .ais-Hits.widget-new-grid, .algolia-right-container
      .ais-Hits.items, .algolia-right-container
      .ais-InfiniteHits.widget-product-grid, .algolia-right-container
      .ais-InfiniteHits.bestseller-products-grid, .algolia-right-container
      .ais-InfiniteHits.widget-new-grid, .algolia-right-container
      .ais-InfiniteHits.items {
        margin-left: -0.53125rem;
        margin-right: -0.53125rem; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .algolia-right-container .product-items.widget-product-grid, .algolia-right-container .product-items.bestseller-products-grid, .algolia-right-container .product-items.widget-new-grid, .algolia-right-container .product-items.items, .algolia-right-container
    .ais-Hits.widget-product-grid, .algolia-right-container
    .ais-Hits.bestseller-products-grid, .algolia-right-container
    .ais-Hits.widget-new-grid, .algolia-right-container
    .ais-Hits.items, .algolia-right-container
    .ais-InfiniteHits.widget-product-grid, .algolia-right-container
    .ais-InfiniteHits.bestseller-products-grid, .algolia-right-container
    .ais-InfiniteHits.widget-new-grid, .algolia-right-container
    .ais-InfiniteHits.items {
      margin-left: -0.625rem; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .algolia-right-container .product-items.widget-product-grid, .algolia-right-container .product-items.bestseller-products-grid, .algolia-right-container .product-items.widget-new-grid, .algolia-right-container .product-items.items, .algolia-right-container
    .ais-Hits.widget-product-grid, .algolia-right-container
    .ais-Hits.bestseller-products-grid, .algolia-right-container
    .ais-Hits.widget-new-grid, .algolia-right-container
    .ais-Hits.items, .algolia-right-container
    .ais-InfiniteHits.widget-product-grid, .algolia-right-container
    .ais-InfiniteHits.bestseller-products-grid, .algolia-right-container
    .ais-InfiniteHits.widget-new-grid, .algolia-right-container
    .ais-InfiniteHits.items {
      margin-right: -0.625rem; } }
  .page-layout-2columns-left .product-items.widget-product-grid, .page-layout-2columns-left .product-items.bestseller-products-grid, .page-layout-2columns-left .product-items.widget-new-grid, .page-layout-2columns-left .product-items.items, .page-layout-2columns-left
  .ais-Hits.widget-product-grid, .page-layout-2columns-left
  .ais-Hits.bestseller-products-grid, .page-layout-2columns-left
  .ais-Hits.widget-new-grid, .page-layout-2columns-left
  .ais-Hits.items, .page-layout-2columns-left
  .ais-InfiniteHits.widget-product-grid, .page-layout-2columns-left
  .ais-InfiniteHits.bestseller-products-grid, .page-layout-2columns-left
  .ais-InfiniteHits.widget-new-grid, .page-layout-2columns-left
  .ais-InfiniteHits.items {
    margin-left: -0.53125rem;
    margin-right: -0.53125rem; }
    @media print, screen and (min-width: 40em) {
      .page-layout-2columns-left .product-items.widget-product-grid, .page-layout-2columns-left .product-items.bestseller-products-grid, .page-layout-2columns-left .product-items.widget-new-grid, .page-layout-2columns-left .product-items.items, .page-layout-2columns-left
      .ais-Hits.widget-product-grid, .page-layout-2columns-left
      .ais-Hits.bestseller-products-grid, .page-layout-2columns-left
      .ais-Hits.widget-new-grid, .page-layout-2columns-left
      .ais-Hits.items, .page-layout-2columns-left
      .ais-InfiniteHits.widget-product-grid, .page-layout-2columns-left
      .ais-InfiniteHits.bestseller-products-grid, .page-layout-2columns-left
      .ais-InfiniteHits.widget-new-grid, .page-layout-2columns-left
      .ais-InfiniteHits.items {
        margin-left: -0.625rem; } }
    @media print, screen and (min-width: 40em) {
      .page-layout-2columns-left .product-items.widget-product-grid, .page-layout-2columns-left .product-items.bestseller-products-grid, .page-layout-2columns-left .product-items.widget-new-grid, .page-layout-2columns-left .product-items.items, .page-layout-2columns-left
      .ais-Hits.widget-product-grid, .page-layout-2columns-left
      .ais-Hits.bestseller-products-grid, .page-layout-2columns-left
      .ais-Hits.widget-new-grid, .page-layout-2columns-left
      .ais-Hits.items, .page-layout-2columns-left
      .ais-InfiniteHits.widget-product-grid, .page-layout-2columns-left
      .ais-InfiniteHits.bestseller-products-grid, .page-layout-2columns-left
      .ais-InfiniteHits.widget-new-grid, .page-layout-2columns-left
      .ais-InfiniteHits.items {
        margin-right: -0.625rem; } }
  @media screen and (min-width: 64em) {
    .product-items,
    .ais-Hits,
    .ais-InfiniteHits {
      margin-right: -1.0625rem;
      margin-left: -1.0625rem; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .product-items,
    .ais-Hits,
    .ais-InfiniteHits {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) and (min-width: 48em) {
    .product-items,
    .ais-Hits,
    .ais-InfiniteHits {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) and (min-width: 64em) {
    .product-items,
    .ais-Hits,
    .ais-InfiniteHits {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) and (min-width: 75em) {
    .product-items,
    .ais-Hits,
    .ais-InfiniteHits {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) and (min-width: 90.625em) {
    .product-items,
    .ais-Hits,
    .ais-InfiniteHits {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .block-wishlist .product-items,
  .block-reorder .product-items,
  .block-compare .product-items, .block-wishlist
  .ais-Hits,
  .block-reorder
  .ais-Hits,
  .block-compare
  .ais-Hits, .block-wishlist
  .ais-InfiniteHits,
  .block-reorder
  .ais-InfiniteHits,
  .block-compare
  .ais-InfiniteHits {
    margin-right: -1.0625rem;
    margin-left: -1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .block-wishlist .product-items,
      .block-reorder .product-items,
      .block-compare .product-items, .block-wishlist
      .ais-Hits,
      .block-reorder
      .ais-Hits,
      .block-compare
      .ais-Hits, .block-wishlist
      .ais-InfiniteHits,
      .block-reorder
      .ais-InfiniteHits,
      .block-compare
      .ais-InfiniteHits {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .block-wishlist .product-items,
      .block-reorder .product-items,
      .block-compare .product-items, .block-wishlist
      .ais-Hits,
      .block-reorder
      .ais-Hits,
      .block-compare
      .ais-Hits, .block-wishlist
      .ais-InfiniteHits,
      .block-reorder
      .ais-InfiniteHits,
      .block-compare
      .ais-InfiniteHits {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .block-wishlist .product-items,
      .block-reorder .product-items,
      .block-compare .product-items, .block-wishlist
      .ais-Hits,
      .block-reorder
      .ais-Hits,
      .block-compare
      .ais-Hits, .block-wishlist
      .ais-InfiniteHits,
      .block-reorder
      .ais-InfiniteHits,
      .block-compare
      .ais-InfiniteHits {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .block-wishlist .product-items,
      .block-reorder .product-items,
      .block-compare .product-items, .block-wishlist
      .ais-Hits,
      .block-reorder
      .ais-Hits,
      .block-compare
      .ais-Hits, .block-wishlist
      .ais-InfiniteHits,
      .block-reorder
      .ais-InfiniteHits,
      .block-compare
      .ais-InfiniteHits {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .block-wishlist .product-items,
      .block-reorder .product-items,
      .block-compare .product-items, .block-wishlist
      .ais-Hits,
      .block-reorder
      .ais-Hits,
      .block-compare
      .ais-Hits, .block-wishlist
      .ais-InfiniteHits,
      .block-reorder
      .ais-InfiniteHits,
      .block-compare
      .ais-InfiniteHits {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
  .product-items .product-item,
  .product-items .ais-Hits-item,
  .product-items .ais-InfiniteHits-item,
  .ais-Hits .product-item,
  .ais-Hits .ais-Hits-item,
  .ais-Hits .ais-InfiniteHits-item,
  .ais-InfiniteHits .product-item,
  .ais-InfiniteHits .ais-Hits-item,
  .ais-InfiniteHits .ais-InfiniteHits-item {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    width: 50%;
    position: relative;
    text-align: center;
    padding-top: 1.0625rem;
    padding-bottom: 1.0625rem;
    transition: box-shadow .3s ease;
    z-index: 1;
    box-shadow: 0 0 0 0.53125rem transparent;
    padding: 0.53125rem; }
    @media print, screen and (min-width: 40em) {
      .product-items .product-item,
      .product-items .ais-Hits-item,
      .product-items .ais-InfiniteHits-item,
      .ais-Hits .product-item,
      .ais-Hits .ais-Hits-item,
      .ais-Hits .ais-InfiniteHits-item,
      .ais-InfiniteHits .product-item,
      .ais-InfiniteHits .ais-Hits-item,
      .ais-InfiniteHits .ais-InfiniteHits-item {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .product-items .product-item:last-child:not(:first-child),
    .product-items .ais-Hits-item:last-child:not(:first-child),
    .product-items .ais-InfiniteHits-item:last-child:not(:first-child),
    .ais-Hits .product-item:last-child:not(:first-child),
    .ais-Hits .ais-Hits-item:last-child:not(:first-child),
    .ais-Hits .ais-InfiniteHits-item:last-child:not(:first-child),
    .ais-InfiniteHits .product-item:last-child:not(:first-child),
    .ais-InfiniteHits .ais-Hits-item:last-child:not(:first-child),
    .ais-InfiniteHits .ais-InfiniteHits-item:last-child:not(:first-child) {
      float: right; }
    .product-items .product-item:last-child:last-child,
    .product-items .ais-Hits-item:last-child:last-child,
    .product-items .ais-InfiniteHits-item:last-child:last-child,
    .ais-Hits .product-item:last-child:last-child,
    .ais-Hits .ais-Hits-item:last-child:last-child,
    .ais-Hits .ais-InfiniteHits-item:last-child:last-child,
    .ais-InfiniteHits .product-item:last-child:last-child,
    .ais-InfiniteHits .ais-Hits-item:last-child:last-child,
    .ais-InfiniteHits .ais-InfiniteHits-item:last-child:last-child {
      float: left; }
    @media print, screen and (min-width: 40em) {
      .product-items .product-item,
      .product-items .ais-Hits-item,
      .product-items .ais-InfiniteHits-item,
      .ais-Hits .product-item,
      .ais-Hits .ais-Hits-item,
      .ais-Hits .ais-InfiniteHits-item,
      .ais-InfiniteHits .product-item,
      .ais-InfiniteHits .ais-Hits-item,
      .ais-InfiniteHits .ais-InfiniteHits-item {
        padding-top: 1.25rem; } }
    @media print, screen and (min-width: 40em) {
      .product-items .product-item,
      .product-items .ais-Hits-item,
      .product-items .ais-InfiniteHits-item,
      .ais-Hits .product-item,
      .ais-Hits .ais-Hits-item,
      .ais-Hits .ais-InfiniteHits-item,
      .ais-InfiniteHits .product-item,
      .ais-InfiniteHits .ais-Hits-item,
      .ais-InfiniteHits .ais-InfiniteHits-item {
        padding-bottom: 1.25rem; } }
    .product-items .product-item:nth-child(2n+1),
    .product-items .ais-Hits-item:nth-child(2n+1),
    .product-items .ais-InfiniteHits-item:nth-child(2n+1),
    .ais-Hits .product-item:nth-child(2n+1),
    .ais-Hits .ais-Hits-item:nth-child(2n+1),
    .ais-Hits .ais-InfiniteHits-item:nth-child(2n+1),
    .ais-InfiniteHits .product-item:nth-child(2n+1),
    .ais-InfiniteHits .ais-Hits-item:nth-child(2n+1),
    .ais-InfiniteHits .ais-InfiniteHits-item:nth-child(2n+1) {
      clear: both; }
      .product-items .product-item:nth-child(2n+1):before,
      .product-items .ais-Hits-item:nth-child(2n+1):before,
      .product-items .ais-InfiniteHits-item:nth-child(2n+1):before,
      .ais-Hits .product-item:nth-child(2n+1):before,
      .ais-Hits .ais-Hits-item:nth-child(2n+1):before,
      .ais-Hits .ais-InfiniteHits-item:nth-child(2n+1):before,
      .ais-InfiniteHits .product-item:nth-child(2n+1):before,
      .ais-InfiniteHits .ais-Hits-item:nth-child(2n+1):before,
      .ais-InfiniteHits .ais-InfiniteHits-item:nth-child(2n+1):before {
        border-right: 0; }
    .product-items .product-item .product-item-info,
    .product-items .ais-Hits-item .product-item-info,
    .product-items .ais-InfiniteHits-item .product-item-info,
    .ais-Hits .product-item .product-item-info,
    .ais-Hits .ais-Hits-item .product-item-info,
    .ais-Hits .ais-InfiniteHits-item .product-item-info,
    .ais-InfiniteHits .product-item .product-item-info,
    .ais-InfiniteHits .ais-Hits-item .product-item-info,
    .ais-InfiniteHits .ais-InfiniteHits-item .product-item-info {
      min-height: 100%;
      position: relative;
      background-color: white;
      z-index: 1 !important;
      padding: 0.125rem 0.125rem 0.125rem; }
      @media print, screen and (min-width: 48em) {
        .product-items .product-item .product-item-info,
        .product-items .ais-Hits-item .product-item-info,
        .product-items .ais-InfiniteHits-item .product-item-info,
        .ais-Hits .product-item .product-item-info,
        .ais-Hits .ais-Hits-item .product-item-info,
        .ais-Hits .ais-InfiniteHits-item .product-item-info,
        .ais-InfiniteHits .product-item .product-item-info,
        .ais-InfiniteHits .ais-Hits-item .product-item-info,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-item-info {
          padding: 0.125rem 0.125rem 0.125rem; } }
      @media screen and (min-width: 64em) {
        .product-items .product-item .product-item-info,
        .product-items .ais-Hits-item .product-item-info,
        .product-items .ais-InfiniteHits-item .product-item-info,
        .ais-Hits .product-item .product-item-info,
        .ais-Hits .ais-Hits-item .product-item-info,
        .ais-Hits .ais-InfiniteHits-item .product-item-info,
        .ais-InfiniteHits .product-item .product-item-info,
        .ais-InfiniteHits .ais-Hits-item .product-item-info,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-item-info {
          padding: 0.125rem 0.125rem 0.125rem; } }
      @media screen and (min-width: 75em) {
        .product-items .product-item .product-item-info,
        .product-items .ais-Hits-item .product-item-info,
        .product-items .ais-InfiniteHits-item .product-item-info,
        .ais-Hits .product-item .product-item-info,
        .ais-Hits .ais-Hits-item .product-item-info,
        .ais-Hits .ais-InfiniteHits-item .product-item-info,
        .ais-InfiniteHits .product-item .product-item-info,
        .ais-InfiniteHits .ais-Hits-item .product-item-info,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-item-info {
          padding: 0.125rem 0.125rem 0.125rem; } }
    .product-items .product-item .amasty-label-container,
    .product-items .ais-Hits-item .amasty-label-container,
    .product-items .ais-InfiniteHits-item .amasty-label-container,
    .ais-Hits .product-item .amasty-label-container,
    .ais-Hits .ais-Hits-item .amasty-label-container,
    .ais-Hits .ais-InfiniteHits-item .amasty-label-container,
    .ais-InfiniteHits .product-item .amasty-label-container,
    .ais-InfiniteHits .ais-Hits-item .amasty-label-container,
    .ais-InfiniteHits .ais-InfiniteHits-item .amasty-label-container {
      z-index: 2 !important; }
    .product-items .product-item .product-item-actions a,
    .product-items .ais-Hits-item .product-item-actions a,
    .product-items .ais-InfiniteHits-item .product-item-actions a,
    .ais-Hits .product-item .product-item-actions a,
    .ais-Hits .ais-Hits-item .product-item-actions a,
    .ais-Hits .ais-InfiniteHits-item .product-item-actions a,
    .ais-InfiniteHits .product-item .product-item-actions a,
    .ais-InfiniteHits .ais-Hits-item .product-item-actions a,
    .ais-InfiniteHits .ais-InfiniteHits-item .product-item-actions a {
      text-decoration: none; }
    .product-items .product-item .product-item-actions button.action,
    .product-items .ais-Hits-item .product-item-actions button.action,
    .product-items .ais-InfiniteHits-item .product-item-actions button.action,
    .ais-Hits .product-item .product-item-actions button.action,
    .ais-Hits .ais-Hits-item .product-item-actions button.action,
    .ais-Hits .ais-InfiniteHits-item .product-item-actions button.action,
    .ais-InfiniteHits .product-item .product-item-actions button.action,
    .ais-InfiniteHits .ais-Hits-item .product-item-actions button.action,
    .ais-InfiniteHits .ais-InfiniteHits-item .product-item-actions button.action {
      margin-bottom: 0; }
      .no-touchevents .product-items .product-item .product-item-actions button.action, .no-touchevents
      .product-items .ais-Hits-item .product-item-actions button.action, .no-touchevents
      .product-items .ais-InfiniteHits-item .product-item-actions button.action, .no-touchevents
      .ais-Hits .product-item .product-item-actions button.action, .no-touchevents
      .ais-Hits .ais-Hits-item .product-item-actions button.action, .no-touchevents
      .ais-Hits .ais-InfiniteHits-item .product-item-actions button.action, .no-touchevents
      .ais-InfiniteHits .product-item .product-item-actions button.action, .no-touchevents
      .ais-InfiniteHits .ais-Hits-item .product-item-actions button.action, .no-touchevents
      .ais-InfiniteHits .ais-InfiniteHits-item .product-item-actions button.action {
        opacity: 0;
        position: relative;
        z-index: -1;
        transition: opacity .3s ease; }
      .no-touchevents .product-items .product-item .product-item-actions button.action:focus, .no-touchevents .product-items .product-item .product-item-actions button.action:active, .no-touchevents
      .product-items .ais-Hits-item .product-item-actions button.action:focus, .no-touchevents
      .product-items .ais-Hits-item .product-item-actions button.action:active, .no-touchevents
      .product-items .ais-InfiniteHits-item .product-item-actions button.action:focus, .no-touchevents
      .product-items .ais-InfiniteHits-item .product-item-actions button.action:active, .no-touchevents
      .ais-Hits .product-item .product-item-actions button.action:focus, .no-touchevents
      .ais-Hits .product-item .product-item-actions button.action:active, .no-touchevents
      .ais-Hits .ais-Hits-item .product-item-actions button.action:focus, .no-touchevents
      .ais-Hits .ais-Hits-item .product-item-actions button.action:active, .no-touchevents
      .ais-Hits .ais-InfiniteHits-item .product-item-actions button.action:focus, .no-touchevents
      .ais-Hits .ais-InfiniteHits-item .product-item-actions button.action:active, .no-touchevents
      .ais-InfiniteHits .product-item .product-item-actions button.action:focus, .no-touchevents
      .ais-InfiniteHits .product-item .product-item-actions button.action:active, .no-touchevents
      .ais-InfiniteHits .ais-Hits-item .product-item-actions button.action:focus, .no-touchevents
      .ais-InfiniteHits .ais-Hits-item .product-item-actions button.action:active, .no-touchevents
      .ais-InfiniteHits .ais-InfiniteHits-item .product-item-actions button.action:focus, .no-touchevents
      .ais-InfiniteHits .ais-InfiniteHits-item .product-item-actions button.action:active {
        opacity: 1;
        z-index: 1; }
    .product-items .product-item .product-item-actions .actions-secondary,
    .product-items .ais-Hits-item .product-item-actions .actions-secondary,
    .product-items .ais-InfiniteHits-item .product-item-actions .actions-secondary,
    .ais-Hits .product-item .product-item-actions .actions-secondary,
    .ais-Hits .ais-Hits-item .product-item-actions .actions-secondary,
    .ais-Hits .ais-InfiniteHits-item .product-item-actions .actions-secondary,
    .ais-InfiniteHits .product-item .product-item-actions .actions-secondary,
    .ais-InfiniteHits .ais-Hits-item .product-item-actions .actions-secondary,
    .ais-InfiniteHits .ais-InfiniteHits-item .product-item-actions .actions-secondary {
      display: none; }
    .no-touchevents .product-items .product-item:hover, .no-touchevents .product-items .product-item:focus, .no-touchevents
    .product-items .ais-Hits-item:hover, .no-touchevents
    .product-items .ais-Hits-item:focus, .no-touchevents
    .product-items .ais-InfiniteHits-item:hover, .no-touchevents
    .product-items .ais-InfiniteHits-item:focus, .no-touchevents
    .ais-Hits .product-item:hover, .no-touchevents
    .ais-Hits .product-item:focus, .no-touchevents
    .ais-Hits .ais-Hits-item:hover, .no-touchevents
    .ais-Hits .ais-Hits-item:focus, .no-touchevents
    .ais-Hits .ais-InfiniteHits-item:hover, .no-touchevents
    .ais-Hits .ais-InfiniteHits-item:focus, .no-touchevents
    .ais-InfiniteHits .product-item:hover, .no-touchevents
    .ais-InfiniteHits .product-item:focus, .no-touchevents
    .ais-InfiniteHits .ais-Hits-item:hover, .no-touchevents
    .ais-InfiniteHits .ais-Hits-item:focus, .no-touchevents
    .ais-InfiniteHits .ais-InfiniteHits-item:hover, .no-touchevents
    .ais-InfiniteHits .ais-InfiniteHits-item:focus {
      box-shadow: 0 0 30px rgba(24, 24, 24, 0.1);
      background-color: white; }
      .no-touchevents .product-items .product-item:hover + .product-item:before,
      .no-touchevents .product-items .product-item:hover + .ais-Hits-item:before,
      .no-touchevents .product-items .product-item:hover + .ais-InfiniteHits-item:before, .no-touchevents .product-items .product-item:focus + .product-item:before,
      .no-touchevents .product-items .product-item:focus + .ais-Hits-item:before,
      .no-touchevents .product-items .product-item:focus + .ais-InfiniteHits-item:before, .no-touchevents
      .product-items .ais-Hits-item:hover + .product-item:before,
      .no-touchevents
      .product-items .ais-Hits-item:hover + .ais-Hits-item:before,
      .no-touchevents
      .product-items .ais-Hits-item:hover + .ais-InfiniteHits-item:before, .no-touchevents
      .product-items .ais-Hits-item:focus + .product-item:before,
      .no-touchevents
      .product-items .ais-Hits-item:focus + .ais-Hits-item:before,
      .no-touchevents
      .product-items .ais-Hits-item:focus + .ais-InfiniteHits-item:before, .no-touchevents
      .product-items .ais-InfiniteHits-item:hover + .product-item:before,
      .no-touchevents
      .product-items .ais-InfiniteHits-item:hover + .ais-Hits-item:before,
      .no-touchevents
      .product-items .ais-InfiniteHits-item:hover + .ais-InfiniteHits-item:before, .no-touchevents
      .product-items .ais-InfiniteHits-item:focus + .product-item:before,
      .no-touchevents
      .product-items .ais-InfiniteHits-item:focus + .ais-Hits-item:before,
      .no-touchevents
      .product-items .ais-InfiniteHits-item:focus + .ais-InfiniteHits-item:before, .no-touchevents
      .ais-Hits .product-item:hover + .product-item:before,
      .no-touchevents
      .ais-Hits .product-item:hover + .ais-Hits-item:before,
      .no-touchevents
      .ais-Hits .product-item:hover + .ais-InfiniteHits-item:before, .no-touchevents
      .ais-Hits .product-item:focus + .product-item:before,
      .no-touchevents
      .ais-Hits .product-item:focus + .ais-Hits-item:before,
      .no-touchevents
      .ais-Hits .product-item:focus + .ais-InfiniteHits-item:before, .no-touchevents
      .ais-Hits .ais-Hits-item:hover + .product-item:before,
      .no-touchevents
      .ais-Hits .ais-Hits-item:hover + .ais-Hits-item:before,
      .no-touchevents
      .ais-Hits .ais-Hits-item:hover + .ais-InfiniteHits-item:before, .no-touchevents
      .ais-Hits .ais-Hits-item:focus + .product-item:before,
      .no-touchevents
      .ais-Hits .ais-Hits-item:focus + .ais-Hits-item:before,
      .no-touchevents
      .ais-Hits .ais-Hits-item:focus + .ais-InfiniteHits-item:before, .no-touchevents
      .ais-Hits .ais-InfiniteHits-item:hover + .product-item:before,
      .no-touchevents
      .ais-Hits .ais-InfiniteHits-item:hover + .ais-Hits-item:before,
      .no-touchevents
      .ais-Hits .ais-InfiniteHits-item:hover + .ais-InfiniteHits-item:before, .no-touchevents
      .ais-Hits .ais-InfiniteHits-item:focus + .product-item:before,
      .no-touchevents
      .ais-Hits .ais-InfiniteHits-item:focus + .ais-Hits-item:before,
      .no-touchevents
      .ais-Hits .ais-InfiniteHits-item:focus + .ais-InfiniteHits-item:before, .no-touchevents
      .ais-InfiniteHits .product-item:hover + .product-item:before,
      .no-touchevents
      .ais-InfiniteHits .product-item:hover + .ais-Hits-item:before,
      .no-touchevents
      .ais-InfiniteHits .product-item:hover + .ais-InfiniteHits-item:before, .no-touchevents
      .ais-InfiniteHits .product-item:focus + .product-item:before,
      .no-touchevents
      .ais-InfiniteHits .product-item:focus + .ais-Hits-item:before,
      .no-touchevents
      .ais-InfiniteHits .product-item:focus + .ais-InfiniteHits-item:before, .no-touchevents
      .ais-InfiniteHits .ais-Hits-item:hover + .product-item:before,
      .no-touchevents
      .ais-InfiniteHits .ais-Hits-item:hover + .ais-Hits-item:before,
      .no-touchevents
      .ais-InfiniteHits .ais-Hits-item:hover + .ais-InfiniteHits-item:before, .no-touchevents
      .ais-InfiniteHits .ais-Hits-item:focus + .product-item:before,
      .no-touchevents
      .ais-InfiniteHits .ais-Hits-item:focus + .ais-Hits-item:before,
      .no-touchevents
      .ais-InfiniteHits .ais-Hits-item:focus + .ais-InfiniteHits-item:before, .no-touchevents
      .ais-InfiniteHits .ais-InfiniteHits-item:hover + .product-item:before,
      .no-touchevents
      .ais-InfiniteHits .ais-InfiniteHits-item:hover + .ais-Hits-item:before,
      .no-touchevents
      .ais-InfiniteHits .ais-InfiniteHits-item:hover + .ais-InfiniteHits-item:before, .no-touchevents
      .ais-InfiniteHits .ais-InfiniteHits-item:focus + .product-item:before,
      .no-touchevents
      .ais-InfiniteHits .ais-InfiniteHits-item:focus + .ais-Hits-item:before,
      .no-touchevents
      .ais-InfiniteHits .ais-InfiniteHits-item:focus + .ais-InfiniteHits-item:before {
        border-right: 0 none !important; }
      .no-touchevents .product-items .product-item:hover .product-item-actions button.action, .no-touchevents .product-items .product-item:focus .product-item-actions button.action, .no-touchevents
      .product-items .ais-Hits-item:hover .product-item-actions button.action, .no-touchevents
      .product-items .ais-Hits-item:focus .product-item-actions button.action, .no-touchevents
      .product-items .ais-InfiniteHits-item:hover .product-item-actions button.action, .no-touchevents
      .product-items .ais-InfiniteHits-item:focus .product-item-actions button.action, .no-touchevents
      .ais-Hits .product-item:hover .product-item-actions button.action, .no-touchevents
      .ais-Hits .product-item:focus .product-item-actions button.action, .no-touchevents
      .ais-Hits .ais-Hits-item:hover .product-item-actions button.action, .no-touchevents
      .ais-Hits .ais-Hits-item:focus .product-item-actions button.action, .no-touchevents
      .ais-Hits .ais-InfiniteHits-item:hover .product-item-actions button.action, .no-touchevents
      .ais-Hits .ais-InfiniteHits-item:focus .product-item-actions button.action, .no-touchevents
      .ais-InfiniteHits .product-item:hover .product-item-actions button.action, .no-touchevents
      .ais-InfiniteHits .product-item:focus .product-item-actions button.action, .no-touchevents
      .ais-InfiniteHits .ais-Hits-item:hover .product-item-actions button.action, .no-touchevents
      .ais-InfiniteHits .ais-Hits-item:focus .product-item-actions button.action, .no-touchevents
      .ais-InfiniteHits .ais-InfiniteHits-item:hover .product-item-actions button.action, .no-touchevents
      .ais-InfiniteHits .ais-InfiniteHits-item:focus .product-item-actions button.action {
        opacity: 1;
        z-index: 1; }
      .no-touchevents .product-items .product-item:hover .product-price-stock .stock, .no-touchevents .product-items .product-item:focus .product-price-stock .stock, .no-touchevents
      .product-items .ais-Hits-item:hover .product-price-stock .stock, .no-touchevents
      .product-items .ais-Hits-item:focus .product-price-stock .stock, .no-touchevents
      .product-items .ais-InfiniteHits-item:hover .product-price-stock .stock, .no-touchevents
      .product-items .ais-InfiniteHits-item:focus .product-price-stock .stock, .no-touchevents
      .ais-Hits .product-item:hover .product-price-stock .stock, .no-touchevents
      .ais-Hits .product-item:focus .product-price-stock .stock, .no-touchevents
      .ais-Hits .ais-Hits-item:hover .product-price-stock .stock, .no-touchevents
      .ais-Hits .ais-Hits-item:focus .product-price-stock .stock, .no-touchevents
      .ais-Hits .ais-InfiniteHits-item:hover .product-price-stock .stock, .no-touchevents
      .ais-Hits .ais-InfiniteHits-item:focus .product-price-stock .stock, .no-touchevents
      .ais-InfiniteHits .product-item:hover .product-price-stock .stock, .no-touchevents
      .ais-InfiniteHits .product-item:focus .product-price-stock .stock, .no-touchevents
      .ais-InfiniteHits .ais-Hits-item:hover .product-price-stock .stock, .no-touchevents
      .ais-InfiniteHits .ais-Hits-item:focus .product-price-stock .stock, .no-touchevents
      .ais-InfiniteHits .ais-InfiniteHits-item:hover .product-price-stock .stock, .no-touchevents
      .ais-InfiniteHits .ais-InfiniteHits-item:focus .product-price-stock .stock {
        opacity: 1;
        z-index: 1; }
    .product-items .product-item .product-price-stock .stock,
    .product-items .ais-Hits-item .product-price-stock .stock,
    .product-items .ais-InfiniteHits-item .product-price-stock .stock,
    .ais-Hits .product-item .product-price-stock .stock,
    .ais-Hits .ais-Hits-item .product-price-stock .stock,
    .ais-Hits .ais-InfiniteHits-item .product-price-stock .stock,
    .ais-InfiniteHits .product-item .product-price-stock .stock,
    .ais-InfiniteHits .ais-Hits-item .product-price-stock .stock,
    .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .stock {
      display: none; }
    @media print, screen and (min-width: 40em) {
      .product-items .product-item,
      .product-items .ais-Hits-item,
      .product-items .ais-InfiniteHits-item,
      .ais-Hits .product-item,
      .ais-Hits .ais-Hits-item,
      .ais-Hits .ais-InfiniteHits-item,
      .ais-InfiniteHits .product-item,
      .ais-InfiniteHits .ais-Hits-item,
      .ais-InfiniteHits .ais-InfiniteHits-item {
        box-shadow: 0 0 0 0.625rem transparent; } }
    @media print, screen and (min-width: 40em) {
      .product-items .product-item,
      .product-items .ais-Hits-item,
      .product-items .ais-InfiniteHits-item,
      .ais-Hits .product-item,
      .ais-Hits .ais-Hits-item,
      .ais-Hits .ais-InfiniteHits-item,
      .ais-InfiniteHits .product-item,
      .ais-InfiniteHits .ais-Hits-item,
      .ais-InfiniteHits .ais-InfiniteHits-item {
        padding: 0.625rem; } }
    .no-touchevents .product-items .product-item:hover, .no-touchevents .product-items .product-item:focus, .no-touchevents
    .product-items .ais-Hits-item:hover, .no-touchevents
    .product-items .ais-Hits-item:focus, .no-touchevents
    .product-items .ais-InfiniteHits-item:hover, .no-touchevents
    .product-items .ais-InfiniteHits-item:focus, .no-touchevents
    .ais-Hits .product-item:hover, .no-touchevents
    .ais-Hits .product-item:focus, .no-touchevents
    .ais-Hits .ais-Hits-item:hover, .no-touchevents
    .ais-Hits .ais-Hits-item:focus, .no-touchevents
    .ais-Hits .ais-InfiniteHits-item:hover, .no-touchevents
    .ais-Hits .ais-InfiniteHits-item:focus, .no-touchevents
    .ais-InfiniteHits .product-item:hover, .no-touchevents
    .ais-InfiniteHits .product-item:focus, .no-touchevents
    .ais-InfiniteHits .ais-Hits-item:hover, .no-touchevents
    .ais-InfiniteHits .ais-Hits-item:focus, .no-touchevents
    .ais-InfiniteHits .ais-InfiniteHits-item:hover, .no-touchevents
    .ais-InfiniteHits .ais-InfiniteHits-item:focus {
      z-index: 2;
      box-shadow: 0 0 0 0.53125rem white, 0 0 30px rgba(24, 24, 24, 0.1); }
      @media print, screen and (min-width: 40em) {
        .no-touchevents .product-items .product-item:hover, .no-touchevents .product-items .product-item:focus, .no-touchevents
        .product-items .ais-Hits-item:hover, .no-touchevents
        .product-items .ais-Hits-item:focus, .no-touchevents
        .product-items .ais-InfiniteHits-item:hover, .no-touchevents
        .product-items .ais-InfiniteHits-item:focus, .no-touchevents
        .ais-Hits .product-item:hover, .no-touchevents
        .ais-Hits .product-item:focus, .no-touchevents
        .ais-Hits .ais-Hits-item:hover, .no-touchevents
        .ais-Hits .ais-Hits-item:focus, .no-touchevents
        .ais-Hits .ais-InfiniteHits-item:hover, .no-touchevents
        .ais-Hits .ais-InfiniteHits-item:focus, .no-touchevents
        .ais-InfiniteHits .product-item:hover, .no-touchevents
        .ais-InfiniteHits .product-item:focus, .no-touchevents
        .ais-InfiniteHits .ais-Hits-item:hover, .no-touchevents
        .ais-InfiniteHits .ais-Hits-item:focus, .no-touchevents
        .ais-InfiniteHits .ais-InfiniteHits-item:hover, .no-touchevents
        .ais-InfiniteHits .ais-InfiniteHits-item:focus {
          box-shadow: 0 0 0 0.625rem white, 0 0 30px rgba(24, 24, 24, 0.1); } }
    .product-items .product-item .product-image-container,
    .product-items .ais-Hits-item .product-image-container,
    .product-items .ais-InfiniteHits-item .product-image-container,
    .ais-Hits .product-item .product-image-container,
    .ais-Hits .ais-Hits-item .product-image-container,
    .ais-Hits .ais-InfiniteHits-item .product-image-container,
    .ais-InfiniteHits .product-item .product-image-container,
    .ais-InfiniteHits .ais-Hits-item .product-image-container,
    .ais-InfiniteHits .ais-InfiniteHits-item .product-image-container {
      max-width: 100%;
      width: auto !important;
      margin-left: auto;
      margin-right: auto;
      display: block;
      position: relative !important;
      padding: 0 0 100%;
      height: 0;
      position: relative !important; }
      .product-items .product-item .product-image-container .product-image-wrapper,
      .product-items .ais-Hits-item .product-image-container .product-image-wrapper,
      .product-items .ais-InfiniteHits-item .product-image-container .product-image-wrapper,
      .ais-Hits .product-item .product-image-container .product-image-wrapper,
      .ais-Hits .ais-Hits-item .product-image-container .product-image-wrapper,
      .ais-Hits .ais-InfiniteHits-item .product-image-container .product-image-wrapper,
      .ais-InfiniteHits .product-item .product-image-container .product-image-wrapper,
      .ais-InfiniteHits .ais-Hits-item .product-image-container .product-image-wrapper,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-image-container .product-image-wrapper {
        display: block;
        padding-bottom: 0 !important;
        font-size: 0; }
        .product-items .product-item .product-image-container .product-image-wrapper:after,
        .product-items .ais-Hits-item .product-image-container .product-image-wrapper:after,
        .product-items .ais-InfiniteHits-item .product-image-container .product-image-wrapper:after,
        .ais-Hits .product-item .product-image-container .product-image-wrapper:after,
        .ais-Hits .ais-Hits-item .product-image-container .product-image-wrapper:after,
        .ais-Hits .ais-InfiniteHits-item .product-image-container .product-image-wrapper:after,
        .ais-InfiniteHits .product-item .product-image-container .product-image-wrapper:after,
        .ais-InfiniteHits .ais-Hits-item .product-image-container .product-image-wrapper:after,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-image-container .product-image-wrapper:after {
          display: inline-block;
          vertical-align: middle;
          content: '';
          margin-right: -0.03em;
          width: 0;
          height: 100%; }
        .product-items .product-item .product-image-container .product-image-wrapper img,
        .product-items .ais-Hits-item .product-image-container .product-image-wrapper img,
        .product-items .ais-InfiniteHits-item .product-image-container .product-image-wrapper img,
        .ais-Hits .product-item .product-image-container .product-image-wrapper img,
        .ais-Hits .ais-Hits-item .product-image-container .product-image-wrapper img,
        .ais-Hits .ais-InfiniteHits-item .product-image-container .product-image-wrapper img,
        .ais-InfiniteHits .product-item .product-image-container .product-image-wrapper img,
        .ais-InfiniteHits .ais-Hits-item .product-image-container .product-image-wrapper img,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-image-container .product-image-wrapper img {
          max-width: 99%;
          display: inline-block;
          vertical-align: middle; }
      .product-items .product-item .product-image-container .product-image-wrapper,
      .product-items .product-item .product-image-container .product-image-fade-item,
      .product-items .ais-Hits-item .product-image-container .product-image-wrapper,
      .product-items .ais-Hits-item .product-image-container .product-image-fade-item,
      .product-items .ais-InfiniteHits-item .product-image-container .product-image-wrapper,
      .product-items .ais-InfiniteHits-item .product-image-container .product-image-fade-item,
      .ais-Hits .product-item .product-image-container .product-image-wrapper,
      .ais-Hits .product-item .product-image-container .product-image-fade-item,
      .ais-Hits .ais-Hits-item .product-image-container .product-image-wrapper,
      .ais-Hits .ais-Hits-item .product-image-container .product-image-fade-item,
      .ais-Hits .ais-InfiniteHits-item .product-image-container .product-image-wrapper,
      .ais-Hits .ais-InfiniteHits-item .product-image-container .product-image-fade-item,
      .ais-InfiniteHits .product-item .product-image-container .product-image-wrapper,
      .ais-InfiniteHits .product-item .product-image-container .product-image-fade-item,
      .ais-InfiniteHits .ais-Hits-item .product-image-container .product-image-wrapper,
      .ais-InfiniteHits .ais-Hits-item .product-image-container .product-image-fade-item,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-image-container .product-image-wrapper,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-image-container .product-image-fade-item {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto;
        max-height: inherit; }
        .product-items .product-item .product-image-container .product-image-wrapper img,
        .product-items .product-item .product-image-container .product-image-fade-item img,
        .product-items .ais-Hits-item .product-image-container .product-image-wrapper img,
        .product-items .ais-Hits-item .product-image-container .product-image-fade-item img,
        .product-items .ais-InfiniteHits-item .product-image-container .product-image-wrapper img,
        .product-items .ais-InfiniteHits-item .product-image-container .product-image-fade-item img,
        .ais-Hits .product-item .product-image-container .product-image-wrapper img,
        .ais-Hits .product-item .product-image-container .product-image-fade-item img,
        .ais-Hits .ais-Hits-item .product-image-container .product-image-wrapper img,
        .ais-Hits .ais-Hits-item .product-image-container .product-image-fade-item img,
        .ais-Hits .ais-InfiniteHits-item .product-image-container .product-image-wrapper img,
        .ais-Hits .ais-InfiniteHits-item .product-image-container .product-image-fade-item img,
        .ais-InfiniteHits .product-item .product-image-container .product-image-wrapper img,
        .ais-InfiniteHits .product-item .product-image-container .product-image-fade-item img,
        .ais-InfiniteHits .ais-Hits-item .product-image-container .product-image-wrapper img,
        .ais-InfiniteHits .ais-Hits-item .product-image-container .product-image-fade-item img,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-image-container .product-image-wrapper img,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-image-container .product-image-fade-item img {
          max-width: 100%;
          max-height: 100%; }
      .product-items .product-item .product-image-container .amasty-label-container,
      .product-items .ais-Hits-item .product-image-container .amasty-label-container,
      .product-items .ais-InfiniteHits-item .product-image-container .amasty-label-container,
      .ais-Hits .product-item .product-image-container .amasty-label-container,
      .ais-Hits .ais-Hits-item .product-image-container .amasty-label-container,
      .ais-Hits .ais-InfiniteHits-item .product-image-container .amasty-label-container,
      .ais-InfiniteHits .product-item .product-image-container .amasty-label-container,
      .ais-InfiniteHits .ais-Hits-item .product-image-container .amasty-label-container,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-image-container .amasty-label-container {
        opacity: 0; }
        .product-items .product-item .product-image-container .amasty-label-container:last-child,
        .product-items .ais-Hits-item .product-image-container .amasty-label-container:last-child,
        .product-items .ais-InfiniteHits-item .product-image-container .amasty-label-container:last-child,
        .ais-Hits .product-item .product-image-container .amasty-label-container:last-child,
        .ais-Hits .ais-Hits-item .product-image-container .amasty-label-container:last-child,
        .ais-Hits .ais-InfiniteHits-item .product-image-container .amasty-label-container:last-child,
        .ais-InfiniteHits .product-item .product-image-container .amasty-label-container:last-child,
        .ais-InfiniteHits .ais-Hits-item .product-image-container .amasty-label-container:last-child,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-image-container .amasty-label-container:last-child {
          opacity: 1; }
    .product-items .product-item .product-item-photo,
    .product-items .ais-Hits-item .product-item-photo,
    .product-items .ais-InfiniteHits-item .product-item-photo,
    .ais-Hits .product-item .product-item-photo,
    .ais-Hits .ais-Hits-item .product-item-photo,
    .ais-Hits .ais-InfiniteHits-item .product-item-photo,
    .ais-InfiniteHits .product-item .product-item-photo,
    .ais-InfiniteHits .ais-Hits-item .product-item-photo,
    .ais-InfiniteHits .ais-InfiniteHits-item .product-item-photo {
      display: block !important;
      text-align: center;
      text-decoration: none;
      position: static !important; }
      @media print, screen and (min-width: 40em) {
        .product-items .product-item .product-item-photo,
        .product-items .ais-Hits-item .product-item-photo,
        .product-items .ais-InfiniteHits-item .product-item-photo,
        .ais-Hits .product-item .product-item-photo,
        .ais-Hits .ais-Hits-item .product-item-photo,
        .ais-Hits .ais-InfiniteHits-item .product-item-photo,
        .ais-InfiniteHits .product-item .product-item-photo,
        .ais-InfiniteHits .ais-Hits-item .product-item-photo,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-item-photo {
          margin-bottom: 1.25rem; } }
      .product-items .product-item .product-item-photo img,
      .product-items .ais-Hits-item .product-item-photo img,
      .product-items .ais-InfiniteHits-item .product-item-photo img,
      .ais-Hits .product-item .product-item-photo img,
      .ais-Hits .ais-Hits-item .product-item-photo img,
      .ais-Hits .ais-InfiniteHits-item .product-item-photo img,
      .ais-InfiniteHits .product-item .product-item-photo img,
      .ais-InfiniteHits .ais-Hits-item .product-item-photo img,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-item-photo img {
        width: auto;
        max-width: 100%; }
    .product-items .product-item .product-item-details,
    .product-items .ais-Hits-item .product-item-details,
    .product-items .ais-InfiniteHits-item .product-item-details,
    .ais-Hits .product-item .product-item-details,
    .ais-Hits .ais-Hits-item .product-item-details,
    .ais-Hits .ais-InfiniteHits-item .product-item-details,
    .ais-InfiniteHits .product-item .product-item-details,
    .ais-InfiniteHits .ais-Hits-item .product-item-details,
    .ais-InfiniteHits .ais-InfiniteHits-item .product-item-details {
      text-align: left;
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column; }
      @media print, screen and (min-width: 40em) {
        .product-items .product-item .product-item-details,
        .product-items .ais-Hits-item .product-item-details,
        .product-items .ais-InfiniteHits-item .product-item-details,
        .ais-Hits .product-item .product-item-details,
        .ais-Hits .ais-Hits-item .product-item-details,
        .ais-Hits .ais-InfiniteHits-item .product-item-details,
        .ais-InfiniteHits .product-item .product-item-details,
        .ais-InfiniteHits .ais-Hits-item .product-item-details,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-item-details {
          padding-left: 0;
          padding-right: 0; } }
      .product-items .product-item .product-item-details .product-item-manufacturer,
      .product-items .ais-Hits-item .product-item-details .product-item-manufacturer,
      .product-items .ais-InfiniteHits-item .product-item-details .product-item-manufacturer,
      .ais-Hits .product-item .product-item-details .product-item-manufacturer,
      .ais-Hits .ais-Hits-item .product-item-details .product-item-manufacturer,
      .ais-Hits .ais-InfiniteHits-item .product-item-details .product-item-manufacturer,
      .ais-InfiniteHits .product-item .product-item-details .product-item-manufacturer,
      .ais-InfiniteHits .ais-Hits-item .product-item-details .product-item-manufacturer,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-item-details .product-item-manufacturer {
        display: block;
        text-transform: uppercase;
        color: rgba(24, 24, 24, 0.5);
        font-size: 0.6875rem;
        font-weight: 400;
        line-height: 1;
        font-family: "silka-regular-webfont", sans-serif;
        letter-spacing: normal;
        padding: 0 0 0.25rem; }
        .product-items .product-item .product-item-details .product-item-manufacturer:empty,
        .product-items .ais-Hits-item .product-item-details .product-item-manufacturer:empty,
        .product-items .ais-InfiniteHits-item .product-item-details .product-item-manufacturer:empty,
        .ais-Hits .product-item .product-item-details .product-item-manufacturer:empty,
        .ais-Hits .ais-Hits-item .product-item-details .product-item-manufacturer:empty,
        .ais-Hits .ais-InfiniteHits-item .product-item-details .product-item-manufacturer:empty,
        .ais-InfiniteHits .product-item .product-item-details .product-item-manufacturer:empty,
        .ais-InfiniteHits .ais-Hits-item .product-item-details .product-item-manufacturer:empty,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-item-details .product-item-manufacturer:empty {
          display: none; }
      .product-items .product-item .product-item-details .product-item-inner,
      .product-items .product-item .product-item-details .product-item-actions,
      .product-items .ais-Hits-item .product-item-details .product-item-inner,
      .product-items .ais-Hits-item .product-item-details .product-item-actions,
      .product-items .ais-InfiniteHits-item .product-item-details .product-item-inner,
      .product-items .ais-InfiniteHits-item .product-item-details .product-item-actions,
      .ais-Hits .product-item .product-item-details .product-item-inner,
      .ais-Hits .product-item .product-item-details .product-item-actions,
      .ais-Hits .ais-Hits-item .product-item-details .product-item-inner,
      .ais-Hits .ais-Hits-item .product-item-details .product-item-actions,
      .ais-Hits .ais-InfiniteHits-item .product-item-details .product-item-inner,
      .ais-Hits .ais-InfiniteHits-item .product-item-details .product-item-actions,
      .ais-InfiniteHits .product-item .product-item-details .product-item-inner,
      .ais-InfiniteHits .product-item .product-item-details .product-item-actions,
      .ais-InfiniteHits .ais-Hits-item .product-item-details .product-item-inner,
      .ais-InfiniteHits .ais-Hits-item .product-item-details .product-item-actions,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-item-details .product-item-inner,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-item-details .product-item-actions {
        margin-top: auto; }
        @media screen and (max-width: 47.9375em) {
          .product-items .product-item .product-item-details .product-item-inner,
          .product-items .product-item .product-item-details .product-item-actions,
          .product-items .ais-Hits-item .product-item-details .product-item-inner,
          .product-items .ais-Hits-item .product-item-details .product-item-actions,
          .product-items .ais-InfiniteHits-item .product-item-details .product-item-inner,
          .product-items .ais-InfiniteHits-item .product-item-details .product-item-actions,
          .ais-Hits .product-item .product-item-details .product-item-inner,
          .ais-Hits .product-item .product-item-details .product-item-actions,
          .ais-Hits .ais-Hits-item .product-item-details .product-item-inner,
          .ais-Hits .ais-Hits-item .product-item-details .product-item-actions,
          .ais-Hits .ais-InfiniteHits-item .product-item-details .product-item-inner,
          .ais-Hits .ais-InfiniteHits-item .product-item-details .product-item-actions,
          .ais-InfiniteHits .product-item .product-item-details .product-item-inner,
          .ais-InfiniteHits .product-item .product-item-details .product-item-actions,
          .ais-InfiniteHits .ais-Hits-item .product-item-details .product-item-inner,
          .ais-InfiniteHits .ais-Hits-item .product-item-details .product-item-actions,
          .ais-InfiniteHits .ais-InfiniteHits-item .product-item-details .product-item-inner,
          .ais-InfiniteHits .ais-InfiniteHits-item .product-item-details .product-item-actions {
            position: absolute !important;
            width: 1px;
            height: 1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0); } }
      .product-items .product-item .product-item-details em,
      .product-items .ais-Hits-item .product-item-details em,
      .product-items .ais-InfiniteHits-item .product-item-details em,
      .ais-Hits .product-item .product-item-details em,
      .ais-Hits .ais-Hits-item .product-item-details em,
      .ais-Hits .ais-InfiniteHits-item .product-item-details em,
      .ais-InfiniteHits .product-item .product-item-details em,
      .ais-InfiniteHits .ais-Hits-item .product-item-details em,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-item-details em {
        font-style: normal; }
    .product-items .product-item .product-item-name,
    .product-items .ais-Hits-item .product-item-name,
    .product-items .ais-InfiniteHits-item .product-item-name,
    .ais-Hits .product-item .product-item-name,
    .ais-Hits .ais-Hits-item .product-item-name,
    .ais-Hits .ais-InfiniteHits-item .product-item-name,
    .ais-InfiniteHits .product-item .product-item-name,
    .ais-InfiniteHits .ais-Hits-item .product-item-name,
    .ais-InfiniteHits .ais-InfiniteHits-item .product-item-name {
      display: block;
      overflow: hidden;
      font-size: 0.9375rem;
      font-weight: 300;
      line-height: 1.23;
      text-transform: none;
      color: #1A2746;
      margin: 0.3125rem 0;
      font-family: "silka-regular-webfont", sans-serif;
      letter-spacing: normal; }
      @media print, screen and (min-width: 48em) {
        .product-items .product-item .product-item-name,
        .product-items .ais-Hits-item .product-item-name,
        .product-items .ais-InfiniteHits-item .product-item-name,
        .ais-Hits .product-item .product-item-name,
        .ais-Hits .ais-Hits-item .product-item-name,
        .ais-Hits .ais-InfiniteHits-item .product-item-name,
        .ais-InfiniteHits .product-item .product-item-name,
        .ais-InfiniteHits .ais-Hits-item .product-item-name,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-item-name {
          margin: 0; } }
      @media print, screen and (min-width: 48em) {
        .product-items .product-item .product-item-name,
        .product-items .ais-Hits-item .product-item-name,
        .product-items .ais-InfiniteHits-item .product-item-name,
        .ais-Hits .product-item .product-item-name,
        .ais-Hits .ais-Hits-item .product-item-name,
        .ais-Hits .ais-InfiniteHits-item .product-item-name,
        .ais-InfiniteHits .product-item .product-item-name,
        .ais-InfiniteHits .ais-Hits-item .product-item-name,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-item-name {
          font-size: 1.125rem;
          font-weight: 300;
          line-height: 1.23; } }
      @media screen and (min-width: 64em) {
        .product-items .product-item .product-item-name,
        .product-items .ais-Hits-item .product-item-name,
        .product-items .ais-InfiniteHits-item .product-item-name,
        .ais-Hits .product-item .product-item-name,
        .ais-Hits .ais-Hits-item .product-item-name,
        .ais-Hits .ais-InfiniteHits-item .product-item-name,
        .ais-InfiniteHits .product-item .product-item-name,
        .ais-InfiniteHits .ais-Hits-item .product-item-name,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-item-name {
          font-size: 1.25rem;
          font-weight: 300;
          line-height: 1.23; } }
      @media screen and (min-width: 75em) {
        .product-items .product-item .product-item-name,
        .product-items .ais-Hits-item .product-item-name,
        .product-items .ais-InfiniteHits-item .product-item-name,
        .ais-Hits .product-item .product-item-name,
        .ais-Hits .ais-Hits-item .product-item-name,
        .ais-Hits .ais-InfiniteHits-item .product-item-name,
        .ais-InfiniteHits .product-item .product-item-name,
        .ais-InfiniteHits .ais-Hits-item .product-item-name,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-item-name {
          font-size: 1.375rem;
          line-height: 1.23; } }
      .product-items .product-item .product-item-name a,
      .product-items .ais-Hits-item .product-item-name a,
      .product-items .ais-InfiniteHits-item .product-item-name a,
      .ais-Hits .product-item .product-item-name a,
      .ais-Hits .ais-Hits-item .product-item-name a,
      .ais-Hits .ais-InfiniteHits-item .product-item-name a,
      .ais-InfiniteHits .product-item .product-item-name a,
      .ais-InfiniteHits .ais-Hits-item .product-item-name a,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-item-name a {
        text-decoration: none;
        color: #1A2746; }
    .product-items .product-item .product-price-stock,
    .product-items .ais-Hits-item .product-price-stock,
    .product-items .ais-InfiniteHits-item .product-price-stock,
    .ais-Hits .product-item .product-price-stock,
    .ais-Hits .ais-Hits-item .product-price-stock,
    .ais-Hits .ais-InfiniteHits-item .product-price-stock,
    .ais-InfiniteHits .product-item .product-price-stock,
    .ais-InfiniteHits .ais-Hits-item .product-price-stock,
    .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock {
      display: flex;
      align-items: flex-end;
      flex-flow: row wrap;
      margin-bottom: .75rem; }
      .product-items .product-item .product-price-stock::before, .product-items .product-item .product-price-stock::after,
      .product-items .ais-Hits-item .product-price-stock::before,
      .product-items .ais-Hits-item .product-price-stock::after,
      .product-items .ais-InfiniteHits-item .product-price-stock::before,
      .product-items .ais-InfiniteHits-item .product-price-stock::after,
      .ais-Hits .product-item .product-price-stock::before,
      .ais-Hits .product-item .product-price-stock::after,
      .ais-Hits .ais-Hits-item .product-price-stock::before,
      .ais-Hits .ais-Hits-item .product-price-stock::after,
      .ais-Hits .ais-InfiniteHits-item .product-price-stock::before,
      .ais-Hits .ais-InfiniteHits-item .product-price-stock::after,
      .ais-InfiniteHits .product-item .product-price-stock::before,
      .ais-InfiniteHits .product-item .product-price-stock::after,
      .ais-InfiniteHits .ais-Hits-item .product-price-stock::before,
      .ais-InfiniteHits .ais-Hits-item .product-price-stock::after,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock::before,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock::after {
        display: table;
        content: ' '; }
      .product-items .product-item .product-price-stock::after,
      .product-items .ais-Hits-item .product-price-stock::after,
      .product-items .ais-InfiniteHits-item .product-price-stock::after,
      .ais-Hits .product-item .product-price-stock::after,
      .ais-Hits .ais-Hits-item .product-price-stock::after,
      .ais-Hits .ais-InfiniteHits-item .product-price-stock::after,
      .ais-InfiniteHits .product-item .product-price-stock::after,
      .ais-InfiniteHits .ais-Hits-item .product-price-stock::after,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock::after {
        clear: both; }
      .product-items .product-item .product-price-stock .product-attr-list,
      .product-items .ais-Hits-item .product-price-stock .product-attr-list,
      .product-items .ais-InfiniteHits-item .product-price-stock .product-attr-list,
      .ais-Hits .product-item .product-price-stock .product-attr-list,
      .ais-Hits .ais-Hits-item .product-price-stock .product-attr-list,
      .ais-Hits .ais-InfiniteHits-item .product-price-stock .product-attr-list,
      .ais-InfiniteHits .product-item .product-price-stock .product-attr-list,
      .ais-InfiniteHits .ais-Hits-item .product-price-stock .product-attr-list,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .product-attr-list {
        flex-basis: 100%; }
      @media print, screen and (min-width: 48em) {
        .product-items .product-item .product-price-stock,
        .product-items .ais-Hits-item .product-price-stock,
        .product-items .ais-InfiniteHits-item .product-price-stock,
        .ais-Hits .product-item .product-price-stock,
        .ais-Hits .ais-Hits-item .product-price-stock,
        .ais-Hits .ais-InfiniteHits-item .product-price-stock,
        .ais-InfiniteHits .product-item .product-price-stock,
        .ais-InfiniteHits .ais-Hits-item .product-price-stock,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock {
          margin-bottom: 1.25rem; } }
      .product-items .product-item .product-price-stock .price-box,
      .product-items .ais-Hits-item .product-price-stock .price-box,
      .product-items .ais-InfiniteHits-item .product-price-stock .price-box,
      .ais-Hits .product-item .product-price-stock .price-box,
      .ais-Hits .ais-Hits-item .product-price-stock .price-box,
      .ais-Hits .ais-InfiniteHits-item .product-price-stock .price-box,
      .ais-InfiniteHits .product-item .product-price-stock .price-box,
      .ais-InfiniteHits .ais-Hits-item .product-price-stock .price-box,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .price-box {
        margin-bottom: 0;
        position: relative;
        flex-basis: 60%;
        width: 60%;
        flex-basis: 100%;
        width: 100%; }
        .product-items .product-item .product-price-stock .price-box + .stock,
        .product-items .ais-Hits-item .product-price-stock .price-box + .stock,
        .product-items .ais-InfiniteHits-item .product-price-stock .price-box + .stock,
        .ais-Hits .product-item .product-price-stock .price-box + .stock,
        .ais-Hits .ais-Hits-item .product-price-stock .price-box + .stock,
        .ais-Hits .ais-InfiniteHits-item .product-price-stock .price-box + .stock,
        .ais-InfiniteHits .product-item .product-price-stock .price-box + .stock,
        .ais-InfiniteHits .ais-Hits-item .product-price-stock .price-box + .stock,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .price-box + .stock {
          flex-basis: 35%;
          width: 35%; }
          @media screen and (max-width: 47.9375em) {
            .product-items .product-item .product-price-stock .price-box + .stock,
            .product-items .ais-Hits-item .product-price-stock .price-box + .stock,
            .product-items .ais-InfiniteHits-item .product-price-stock .price-box + .stock,
            .ais-Hits .product-item .product-price-stock .price-box + .stock,
            .ais-Hits .ais-Hits-item .product-price-stock .price-box + .stock,
            .ais-Hits .ais-InfiniteHits-item .product-price-stock .price-box + .stock,
            .ais-InfiniteHits .product-item .product-price-stock .price-box + .stock,
            .ais-InfiniteHits .ais-Hits-item .product-price-stock .price-box + .stock,
            .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .price-box + .stock {
              position: absolute !important;
              width: 1px;
              height: 1px;
              overflow: hidden;
              clip: rect(0, 0, 0, 0); } }
        @media print, screen and (min-width: 40em) {
          .product-items .product-item .product-price-stock .price-box,
          .product-items .ais-Hits-item .product-price-stock .price-box,
          .product-items .ais-InfiniteHits-item .product-price-stock .price-box,
          .ais-Hits .product-item .product-price-stock .price-box,
          .ais-Hits .ais-Hits-item .product-price-stock .price-box,
          .ais-Hits .ais-InfiniteHits-item .product-price-stock .price-box,
          .ais-InfiniteHits .product-item .product-price-stock .price-box,
          .ais-InfiniteHits .ais-Hits-item .product-price-stock .price-box,
          .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .price-box {
            float: left; } }
      .product-items .product-item .product-price-stock .stock,
      .product-items .ais-Hits-item .product-price-stock .stock,
      .product-items .ais-InfiniteHits-item .product-price-stock .stock,
      .ais-Hits .product-item .product-price-stock .stock,
      .ais-Hits .ais-Hits-item .product-price-stock .stock,
      .ais-Hits .ais-InfiniteHits-item .product-price-stock .stock,
      .ais-InfiniteHits .product-item .product-price-stock .stock,
      .ais-InfiniteHits .ais-Hits-item .product-price-stock .stock,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .stock {
        color: #30A95E;
        font-family: "silka-regular-webfont", sans-serif;
        letter-spacing: normal;
        font-weight: 300;
        font-size: 1.0625rem;
        margin-top: .5rem;
        line-height: 0.9375rem;
        flex-basis: 100%; }
        .product-items .product-item .product-price-stock .stock.backorder,
        .product-items .ais-Hits-item .product-price-stock .stock.backorder,
        .product-items .ais-InfiniteHits-item .product-price-stock .stock.backorder,
        .ais-Hits .product-item .product-price-stock .stock.backorder,
        .ais-Hits .ais-Hits-item .product-price-stock .stock.backorder,
        .ais-Hits .ais-InfiniteHits-item .product-price-stock .stock.backorder,
        .ais-InfiniteHits .product-item .product-price-stock .stock.backorder,
        .ais-InfiniteHits .ais-Hits-item .product-price-stock .stock.backorder,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .stock.backorder {
          color: rgba(24, 24, 24, 0.8); }
          .product-items .product-item .product-price-stock .stock.backorder span,
          .product-items .ais-Hits-item .product-price-stock .stock.backorder span,
          .product-items .ais-InfiniteHits-item .product-price-stock .stock.backorder span,
          .ais-Hits .product-item .product-price-stock .stock.backorder span,
          .ais-Hits .ais-Hits-item .product-price-stock .stock.backorder span,
          .ais-Hits .ais-InfiniteHits-item .product-price-stock .stock.backorder span,
          .ais-InfiniteHits .product-item .product-price-stock .stock.backorder span,
          .ais-InfiniteHits .ais-Hits-item .product-price-stock .stock.backorder span,
          .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .stock.backorder span {
            padding-left: 1.5rem; }
            @media screen and (min-width: 64em) and (max-width: 74.9375em) {
              .product-items .product-item .product-price-stock .stock.backorder span,
              .product-items .ais-Hits-item .product-price-stock .stock.backorder span,
              .product-items .ais-InfiniteHits-item .product-price-stock .stock.backorder span,
              .ais-Hits .product-item .product-price-stock .stock.backorder span,
              .ais-Hits .ais-Hits-item .product-price-stock .stock.backorder span,
              .ais-Hits .ais-InfiniteHits-item .product-price-stock .stock.backorder span,
              .ais-InfiniteHits .product-item .product-price-stock .stock.backorder span,
              .ais-InfiniteHits .ais-Hits-item .product-price-stock .stock.backorder span,
              .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .stock.backorder span {
                font-size: 0.8125rem;
                padding-top: 1px; } }
            @media screen and (min-width: 75em) and (max-width: 90.5625em) {
              .product-items .product-item .product-price-stock .stock.backorder span,
              .product-items .ais-Hits-item .product-price-stock .stock.backorder span,
              .product-items .ais-InfiniteHits-item .product-price-stock .stock.backorder span,
              .ais-Hits .product-item .product-price-stock .stock.backorder span,
              .ais-Hits .ais-Hits-item .product-price-stock .stock.backorder span,
              .ais-Hits .ais-InfiniteHits-item .product-price-stock .stock.backorder span,
              .ais-InfiniteHits .product-item .product-price-stock .stock.backorder span,
              .ais-InfiniteHits .ais-Hits-item .product-price-stock .stock.backorder span,
              .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .stock.backorder span {
                font-size: 0.9375rem; } }
        @media print, screen and (min-width: 40em) {
          .product-items .product-item .product-price-stock .stock,
          .product-items .ais-Hits-item .product-price-stock .stock,
          .product-items .ais-InfiniteHits-item .product-price-stock .stock,
          .ais-Hits .product-item .product-price-stock .stock,
          .ais-Hits .ais-Hits-item .product-price-stock .stock,
          .ais-Hits .ais-InfiniteHits-item .product-price-stock .stock,
          .ais-InfiniteHits .product-item .product-price-stock .stock,
          .ais-InfiniteHits .ais-Hits-item .product-price-stock .stock,
          .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .stock {
            float: right;
            margin-top: 0;
            text-align: right; } }
        .no-touchevents .product-items .product-item .product-price-stock .stock, .no-touchevents
        .product-items .ais-Hits-item .product-price-stock .stock, .no-touchevents
        .product-items .ais-InfiniteHits-item .product-price-stock .stock, .no-touchevents
        .ais-Hits .product-item .product-price-stock .stock, .no-touchevents
        .ais-Hits .ais-Hits-item .product-price-stock .stock, .no-touchevents
        .ais-Hits .ais-InfiniteHits-item .product-price-stock .stock, .no-touchevents
        .ais-InfiniteHits .product-item .product-price-stock .stock, .no-touchevents
        .ais-InfiniteHits .ais-Hits-item .product-price-stock .stock, .no-touchevents
        .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .stock {
          opacity: 0;
          transition: opacity .3s ease; }
        .product-items .product-item .product-price-stock .stock span,
        .product-items .ais-Hits-item .product-price-stock .stock span,
        .product-items .ais-InfiniteHits-item .product-price-stock .stock span,
        .ais-Hits .product-item .product-price-stock .stock span,
        .ais-Hits .ais-Hits-item .product-price-stock .stock span,
        .ais-Hits .ais-InfiniteHits-item .product-price-stock .stock span,
        .ais-InfiniteHits .product-item .product-price-stock .stock span,
        .ais-InfiniteHits .ais-Hits-item .product-price-stock .stock span,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .stock span {
          display: inline-block;
          vertical-align: middle;
          padding: 0 0 0 1.1rem;
          line-height: 1; }
        @media screen and (min-width: 64em) {
          .product-items .product-item .product-price-stock .stock,
          .product-items .ais-Hits-item .product-price-stock .stock,
          .product-items .ais-InfiniteHits-item .product-price-stock .stock,
          .ais-Hits .product-item .product-price-stock .stock,
          .ais-Hits .ais-Hits-item .product-price-stock .stock,
          .ais-Hits .ais-InfiniteHits-item .product-price-stock .stock,
          .ais-InfiniteHits .product-item .product-price-stock .stock,
          .ais-InfiniteHits .ais-Hits-item .product-price-stock .stock,
          .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .stock {
            line-height: 1.0625rem; } }
        @media screen and (min-width: 75em) {
          .product-items .product-item .product-price-stock .stock,
          .product-items .ais-Hits-item .product-price-stock .stock,
          .product-items .ais-InfiniteHits-item .product-price-stock .stock,
          .ais-Hits .product-item .product-price-stock .stock,
          .ais-Hits .ais-Hits-item .product-price-stock .stock,
          .ais-Hits .ais-InfiniteHits-item .product-price-stock .stock,
          .ais-InfiniteHits .product-item .product-price-stock .stock,
          .ais-InfiniteHits .ais-Hits-item .product-price-stock .stock,
          .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .stock {
            line-height: 1.0625rem; } }
      .product-items .product-item .product-price-stock .mva_label,
      .product-items .ais-Hits-item .product-price-stock .mva_label,
      .product-items .ais-InfiniteHits-item .product-price-stock .mva_label,
      .ais-Hits .product-item .product-price-stock .mva_label,
      .ais-Hits .ais-Hits-item .product-price-stock .mva_label,
      .ais-Hits .ais-InfiniteHits-item .product-price-stock .mva_label,
      .ais-InfiniteHits .product-item .product-price-stock .mva_label,
      .ais-InfiniteHits .ais-Hits-item .product-price-stock .mva_label,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .mva_label {
        color: rgba(24, 24, 24, 0.5);
        font-size: 0.70312rem;
        font-weight: 400;
        letter-spacing: normal; }
      .product-items .product-item .product-price-stock .special-price .mva_label,
      .product-items .ais-Hits-item .product-price-stock .special-price .mva_label,
      .product-items .ais-InfiniteHits-item .product-price-stock .special-price .mva_label,
      .ais-Hits .product-item .product-price-stock .special-price .mva_label,
      .ais-Hits .ais-Hits-item .product-price-stock .special-price .mva_label,
      .ais-Hits .ais-InfiniteHits-item .product-price-stock .special-price .mva_label,
      .ais-InfiniteHits .product-item .product-price-stock .special-price .mva_label,
      .ais-InfiniteHits .ais-Hits-item .product-price-stock .special-price .mva_label,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .special-price .mva_label {
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: -.25rem; }
    .product-items .product-item .price-box,
    .product-items .ais-Hits-item .price-box,
    .product-items .ais-InfiniteHits-item .price-box,
    .ais-Hits .product-item .price-box,
    .ais-Hits .ais-Hits-item .price-box,
    .ais-Hits .ais-InfiniteHits-item .price-box,
    .ais-InfiniteHits .product-item .price-box,
    .ais-InfiniteHits .ais-Hits-item .price-box,
    .ais-InfiniteHits .ais-InfiniteHits-item .price-box {
      line-height: 1;
      padding-bottom: 0;
      margin: 0 0 1.25rem;
      white-space: normal; }
      .product-items .product-item .price-box .price-label,
      .product-items .ais-Hits-item .price-box .price-label,
      .product-items .ais-InfiniteHits-item .price-box .price-label,
      .ais-Hits .product-item .price-box .price-label,
      .ais-Hits .ais-Hits-item .price-box .price-label,
      .ais-Hits .ais-InfiniteHits-item .price-box .price-label,
      .ais-InfiniteHits .product-item .price-box .price-label,
      .ais-InfiniteHits .ais-Hits-item .price-box .price-label,
      .ais-InfiniteHits .ais-InfiniteHits-item .price-box .price-label {
        position: absolute !important;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0); }
      .product-items .product-item .price-box > .price-container,
      .product-items .product-item .price-box .special-price,
      .product-items .ais-Hits-item .price-box > .price-container,
      .product-items .ais-Hits-item .price-box .special-price,
      .product-items .ais-InfiniteHits-item .price-box > .price-container,
      .product-items .ais-InfiniteHits-item .price-box .special-price,
      .ais-Hits .product-item .price-box > .price-container,
      .ais-Hits .product-item .price-box .special-price,
      .ais-Hits .ais-Hits-item .price-box > .price-container,
      .ais-Hits .ais-Hits-item .price-box .special-price,
      .ais-Hits .ais-InfiniteHits-item .price-box > .price-container,
      .ais-Hits .ais-InfiniteHits-item .price-box .special-price,
      .ais-InfiniteHits .product-item .price-box > .price-container,
      .ais-InfiniteHits .product-item .price-box .special-price,
      .ais-InfiniteHits .ais-Hits-item .price-box > .price-container,
      .ais-InfiniteHits .ais-Hits-item .price-box .special-price,
      .ais-InfiniteHits .ais-InfiniteHits-item .price-box > .price-container,
      .ais-InfiniteHits .ais-InfiniteHits-item .price-box .special-price {
        font-size: 0.9375rem;
        font-family: "silka-regular-webfont", sans-serif;
        letter-spacing: normal;
        font-weight: 400;
        color: #1A2746;
        line-height: 1; }
        @media screen and (min-width: 64em) {
          .product-items .product-item .price-box > .price-container,
          .product-items .product-item .price-box .special-price,
          .product-items .ais-Hits-item .price-box > .price-container,
          .product-items .ais-Hits-item .price-box .special-price,
          .product-items .ais-InfiniteHits-item .price-box > .price-container,
          .product-items .ais-InfiniteHits-item .price-box .special-price,
          .ais-Hits .product-item .price-box > .price-container,
          .ais-Hits .product-item .price-box .special-price,
          .ais-Hits .ais-Hits-item .price-box > .price-container,
          .ais-Hits .ais-Hits-item .price-box .special-price,
          .ais-Hits .ais-InfiniteHits-item .price-box > .price-container,
          .ais-Hits .ais-InfiniteHits-item .price-box .special-price,
          .ais-InfiniteHits .product-item .price-box > .price-container,
          .ais-InfiniteHits .product-item .price-box .special-price,
          .ais-InfiniteHits .ais-Hits-item .price-box > .price-container,
          .ais-InfiniteHits .ais-Hits-item .price-box .special-price,
          .ais-InfiniteHits .ais-InfiniteHits-item .price-box > .price-container,
          .ais-InfiniteHits .ais-InfiniteHits-item .price-box .special-price {
            font-size: 1.0625rem; } }
        @media screen and (min-width: 75em) {
          .product-items .product-item .price-box > .price-container,
          .product-items .product-item .price-box .special-price,
          .product-items .ais-Hits-item .price-box > .price-container,
          .product-items .ais-Hits-item .price-box .special-price,
          .product-items .ais-InfiniteHits-item .price-box > .price-container,
          .product-items .ais-InfiniteHits-item .price-box .special-price,
          .ais-Hits .product-item .price-box > .price-container,
          .ais-Hits .product-item .price-box .special-price,
          .ais-Hits .ais-Hits-item .price-box > .price-container,
          .ais-Hits .ais-Hits-item .price-box .special-price,
          .ais-Hits .ais-InfiniteHits-item .price-box > .price-container,
          .ais-Hits .ais-InfiniteHits-item .price-box .special-price,
          .ais-InfiniteHits .product-item .price-box > .price-container,
          .ais-InfiniteHits .product-item .price-box .special-price,
          .ais-InfiniteHits .ais-Hits-item .price-box > .price-container,
          .ais-InfiniteHits .ais-Hits-item .price-box .special-price,
          .ais-InfiniteHits .ais-InfiniteHits-item .price-box > .price-container,
          .ais-InfiniteHits .ais-InfiniteHits-item .price-box .special-price {
            font-size: 1.0625rem; } }
      .product-items .product-item .price-box .special-price,
      .product-items .ais-Hits-item .price-box .special-price,
      .product-items .ais-InfiniteHits-item .price-box .special-price,
      .ais-Hits .product-item .price-box .special-price,
      .ais-Hits .ais-Hits-item .price-box .special-price,
      .ais-Hits .ais-InfiniteHits-item .price-box .special-price,
      .ais-InfiniteHits .product-item .price-box .special-price,
      .ais-InfiniteHits .ais-Hits-item .price-box .special-price,
      .ais-InfiniteHits .ais-InfiniteHits-item .price-box .special-price {
        color: #1A2746;
        line-height: 1;
        display: inline-block;
        vertical-align: sub;
        margin-right: 0.5rem;
        padding: 0;
        background: transparent; }
      .product-items .product-item .price-box .old-price,
      .product-items .product-item .price-box .from-txt,
      .product-items .ais-Hits-item .price-box .old-price,
      .product-items .ais-Hits-item .price-box .from-txt,
      .product-items .ais-InfiniteHits-item .price-box .old-price,
      .product-items .ais-InfiniteHits-item .price-box .from-txt,
      .ais-Hits .product-item .price-box .old-price,
      .ais-Hits .product-item .price-box .from-txt,
      .ais-Hits .ais-Hits-item .price-box .old-price,
      .ais-Hits .ais-Hits-item .price-box .from-txt,
      .ais-Hits .ais-InfiniteHits-item .price-box .old-price,
      .ais-Hits .ais-InfiniteHits-item .price-box .from-txt,
      .ais-InfiniteHits .product-item .price-box .old-price,
      .ais-InfiniteHits .product-item .price-box .from-txt,
      .ais-InfiniteHits .ais-Hits-item .price-box .old-price,
      .ais-InfiniteHits .ais-Hits-item .price-box .from-txt,
      .ais-InfiniteHits .ais-InfiniteHits-item .price-box .old-price,
      .ais-InfiniteHits .ais-InfiniteHits-item .price-box .from-txt {
        text-decoration: line-through;
        color: rgba(24, 24, 24, 0.5);
        margin-left: 0;
        font-weight: inherit;
        font-family: "silka-regular-webfont", sans-serif;
        letter-spacing: normal;
        line-height: 0.9375rem; }
        @media screen and (min-width: 64em) {
          .product-items .product-item .price-box .old-price,
          .product-items .product-item .price-box .from-txt,
          .product-items .ais-Hits-item .price-box .old-price,
          .product-items .ais-Hits-item .price-box .from-txt,
          .product-items .ais-InfiniteHits-item .price-box .old-price,
          .product-items .ais-InfiniteHits-item .price-box .from-txt,
          .ais-Hits .product-item .price-box .old-price,
          .ais-Hits .product-item .price-box .from-txt,
          .ais-Hits .ais-Hits-item .price-box .old-price,
          .ais-Hits .ais-Hits-item .price-box .from-txt,
          .ais-Hits .ais-InfiniteHits-item .price-box .old-price,
          .ais-Hits .ais-InfiniteHits-item .price-box .from-txt,
          .ais-InfiniteHits .product-item .price-box .old-price,
          .ais-InfiniteHits .product-item .price-box .from-txt,
          .ais-InfiniteHits .ais-Hits-item .price-box .old-price,
          .ais-InfiniteHits .ais-Hits-item .price-box .from-txt,
          .ais-InfiniteHits .ais-InfiniteHits-item .price-box .old-price,
          .ais-InfiniteHits .ais-InfiniteHits-item .price-box .from-txt {
            line-height: 1.0625rem; } }
        @media screen and (min-width: 75em) {
          .product-items .product-item .price-box .old-price,
          .product-items .product-item .price-box .from-txt,
          .product-items .ais-Hits-item .price-box .old-price,
          .product-items .ais-Hits-item .price-box .from-txt,
          .product-items .ais-InfiniteHits-item .price-box .old-price,
          .product-items .ais-InfiniteHits-item .price-box .from-txt,
          .ais-Hits .product-item .price-box .old-price,
          .ais-Hits .product-item .price-box .from-txt,
          .ais-Hits .ais-Hits-item .price-box .old-price,
          .ais-Hits .ais-Hits-item .price-box .from-txt,
          .ais-Hits .ais-InfiniteHits-item .price-box .old-price,
          .ais-Hits .ais-InfiniteHits-item .price-box .from-txt,
          .ais-InfiniteHits .product-item .price-box .old-price,
          .ais-InfiniteHits .product-item .price-box .from-txt,
          .ais-InfiniteHits .ais-Hits-item .price-box .old-price,
          .ais-InfiniteHits .ais-Hits-item .price-box .from-txt,
          .ais-InfiniteHits .ais-InfiniteHits-item .price-box .old-price,
          .ais-InfiniteHits .ais-InfiniteHits-item .price-box .from-txt {
            line-height: 1.0625rem; } }
        .product-items .product-item .price-box .old-price .price,
        .product-items .product-item .price-box .from-txt .price,
        .product-items .ais-Hits-item .price-box .old-price .price,
        .product-items .ais-Hits-item .price-box .from-txt .price,
        .product-items .ais-InfiniteHits-item .price-box .old-price .price,
        .product-items .ais-InfiniteHits-item .price-box .from-txt .price,
        .ais-Hits .product-item .price-box .old-price .price,
        .ais-Hits .product-item .price-box .from-txt .price,
        .ais-Hits .ais-Hits-item .price-box .old-price .price,
        .ais-Hits .ais-Hits-item .price-box .from-txt .price,
        .ais-Hits .ais-InfiniteHits-item .price-box .old-price .price,
        .ais-Hits .ais-InfiniteHits-item .price-box .from-txt .price,
        .ais-InfiniteHits .product-item .price-box .old-price .price,
        .ais-InfiniteHits .product-item .price-box .from-txt .price,
        .ais-InfiniteHits .ais-Hits-item .price-box .old-price .price,
        .ais-InfiniteHits .ais-Hits-item .price-box .from-txt .price,
        .ais-InfiniteHits .ais-InfiniteHits-item .price-box .old-price .price,
        .ais-InfiniteHits .ais-InfiniteHits-item .price-box .from-txt .price {
          font-size: 0.6375rem;
          font-family: inherit; }
          @media print, screen and (min-width: 48em) {
            .product-items .product-item .price-box .old-price .price,
            .product-items .product-item .price-box .from-txt .price,
            .product-items .ais-Hits-item .price-box .old-price .price,
            .product-items .ais-Hits-item .price-box .from-txt .price,
            .product-items .ais-InfiniteHits-item .price-box .old-price .price,
            .product-items .ais-InfiniteHits-item .price-box .from-txt .price,
            .ais-Hits .product-item .price-box .old-price .price,
            .ais-Hits .product-item .price-box .from-txt .price,
            .ais-Hits .ais-Hits-item .price-box .old-price .price,
            .ais-Hits .ais-Hits-item .price-box .from-txt .price,
            .ais-Hits .ais-InfiniteHits-item .price-box .old-price .price,
            .ais-Hits .ais-InfiniteHits-item .price-box .from-txt .price,
            .ais-InfiniteHits .product-item .price-box .old-price .price,
            .ais-InfiniteHits .product-item .price-box .from-txt .price,
            .ais-InfiniteHits .ais-Hits-item .price-box .old-price .price,
            .ais-InfiniteHits .ais-Hits-item .price-box .from-txt .price,
            .ais-InfiniteHits .ais-InfiniteHits-item .price-box .old-price .price,
            .ais-InfiniteHits .ais-InfiniteHits-item .price-box .from-txt .price {
              font-size: 0.75rem; } }
      .product-items .product-item .price-box .from-txt,
      .product-items .ais-Hits-item .price-box .from-txt,
      .product-items .ais-InfiniteHits-item .price-box .from-txt,
      .ais-Hits .product-item .price-box .from-txt,
      .ais-Hits .ais-Hits-item .price-box .from-txt,
      .ais-Hits .ais-InfiniteHits-item .price-box .from-txt,
      .ais-InfiniteHits .product-item .price-box .from-txt,
      .ais-InfiniteHits .ais-Hits-item .price-box .from-txt,
      .ais-InfiniteHits .ais-InfiniteHits-item .price-box .from-txt {
        display: none;
        color: #1A2746;
        text-decoration: none;
        font-size: 0.75rem; }
      .product-items .product-item .price-box .price,
      .product-items .ais-Hits-item .price-box .price,
      .product-items .ais-InfiniteHits-item .price-box .price,
      .ais-Hits .product-item .price-box .price,
      .ais-Hits .ais-Hits-item .price-box .price,
      .ais-Hits .ais-InfiniteHits-item .price-box .price,
      .ais-InfiniteHits .product-item .price-box .price,
      .ais-InfiniteHits .ais-Hits-item .price-box .price,
      .ais-InfiniteHits .ais-InfiniteHits-item .price-box .price {
        white-space: nowrap; }
        .product-items .product-item .price-box .price .currency,
        .product-items .ais-Hits-item .price-box .price .currency,
        .product-items .ais-InfiniteHits-item .price-box .price .currency,
        .ais-Hits .product-item .price-box .price .currency,
        .ais-Hits .ais-Hits-item .price-box .price .currency,
        .ais-Hits .ais-InfiniteHits-item .price-box .price .currency,
        .ais-InfiniteHits .product-item .price-box .price .currency,
        .ais-InfiniteHits .ais-Hits-item .price-box .price .currency,
        .ais-InfiniteHits .ais-InfiniteHits-item .price-box .price .currency {
          position: absolute !important;
          width: 1px;
          height: 1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0); }
    .product-items .product-item .product-attr-list,
    .product-items .ais-Hits-item .product-attr-list,
    .product-items .ais-InfiniteHits-item .product-attr-list,
    .ais-Hits .product-item .product-attr-list,
    .ais-Hits .ais-Hits-item .product-attr-list,
    .ais-Hits .ais-InfiniteHits-item .product-attr-list,
    .ais-InfiniteHits .product-item .product-attr-list,
    .ais-InfiniteHits .ais-Hits-item .product-attr-list,
    .ais-InfiniteHits .ais-InfiniteHits-item .product-attr-list {
      display: none;
      margin: 0;
      list-style: none;
      color: rgba(24, 24, 24, 0.7);
      font-size: 0.6875rem;
      line-height: 1.2;
      margin-bottom: 1.4rem; }
      .product-items .product-item .product-attr-list li,
      .product-items .ais-Hits-item .product-attr-list li,
      .product-items .ais-InfiniteHits-item .product-attr-list li,
      .ais-Hits .product-item .product-attr-list li,
      .ais-Hits .ais-Hits-item .product-attr-list li,
      .ais-Hits .ais-InfiniteHits-item .product-attr-list li,
      .ais-InfiniteHits .product-item .product-attr-list li,
      .ais-InfiniteHits .ais-Hits-item .product-attr-list li,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-attr-list li {
        padding: 0; }
        .product-items .product-item .product-attr-list li:after,
        .product-items .ais-Hits-item .product-attr-list li:after,
        .product-items .ais-InfiniteHits-item .product-attr-list li:after,
        .ais-Hits .product-item .product-attr-list li:after,
        .ais-Hits .ais-Hits-item .product-attr-list li:after,
        .ais-Hits .ais-InfiniteHits-item .product-attr-list li:after,
        .ais-InfiniteHits .product-item .product-attr-list li:after,
        .ais-InfiniteHits .ais-Hits-item .product-attr-list li:after,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-attr-list li:after {
          display: none; }
      .product-items .product-item .product-attr-list:empty,
      .product-items .ais-Hits-item .product-attr-list:empty,
      .product-items .ais-InfiniteHits-item .product-attr-list:empty,
      .ais-Hits .product-item .product-attr-list:empty,
      .ais-Hits .ais-Hits-item .product-attr-list:empty,
      .ais-Hits .ais-InfiniteHits-item .product-attr-list:empty,
      .ais-InfiniteHits .product-item .product-attr-list:empty,
      .ais-InfiniteHits .ais-Hits-item .product-attr-list:empty,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-attr-list:empty {
        display: none; }
      @media print, screen and (min-width: 48em) {
        .product-items .product-item .product-attr-list,
        .product-items .ais-Hits-item .product-attr-list,
        .product-items .ais-InfiniteHits-item .product-attr-list,
        .ais-Hits .product-item .product-attr-list,
        .ais-Hits .ais-Hits-item .product-attr-list,
        .ais-Hits .ais-InfiniteHits-item .product-attr-list,
        .ais-InfiniteHits .product-item .product-attr-list,
        .ais-InfiniteHits .ais-Hits-item .product-attr-list,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-attr-list {
          display: block; } }
      @media screen and (min-width: 64em) {
        .product-items .product-item .product-attr-list,
        .product-items .ais-Hits-item .product-attr-list,
        .product-items .ais-InfiniteHits-item .product-attr-list,
        .ais-Hits .product-item .product-attr-list,
        .ais-Hits .ais-Hits-item .product-attr-list,
        .ais-Hits .ais-InfiniteHits-item .product-attr-list,
        .ais-InfiniteHits .product-item .product-attr-list,
        .ais-InfiniteHits .ais-Hits-item .product-attr-list,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-attr-list {
          font-size: 0.8125rem; } }
      .product-items .product-item .product-attr-list > li,
      .product-items .ais-Hits-item .product-attr-list > li,
      .product-items .ais-InfiniteHits-item .product-attr-list > li,
      .ais-Hits .product-item .product-attr-list > li,
      .ais-Hits .ais-Hits-item .product-attr-list > li,
      .ais-Hits .ais-InfiniteHits-item .product-attr-list > li,
      .ais-InfiniteHits .product-item .product-attr-list > li,
      .ais-InfiniteHits .ais-Hits-item .product-attr-list > li,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-attr-list > li {
        position: relative;
        padding: 0 0 .25em 1.3em; }
        .product-items .product-item .product-attr-list > li:after,
        .product-items .ais-Hits-item .product-attr-list > li:after,
        .product-items .ais-InfiniteHits-item .product-attr-list > li:after,
        .ais-Hits .product-item .product-attr-list > li:after,
        .ais-Hits .ais-Hits-item .product-attr-list > li:after,
        .ais-Hits .ais-InfiniteHits-item .product-attr-list > li:after,
        .ais-InfiniteHits .product-item .product-attr-list > li:after,
        .ais-InfiniteHits .ais-Hits-item .product-attr-list > li:after,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-attr-list > li:after {
          display: block;
          top: 0;
          color: #e0e0e0; }
    .product-items .product-item .product-reviews-summary,
    .product-items .ais-Hits-item .product-reviews-summary,
    .product-items .ais-InfiniteHits-item .product-reviews-summary,
    .ais-Hits .product-item .product-reviews-summary,
    .ais-Hits .ais-Hits-item .product-reviews-summary,
    .ais-Hits .ais-InfiniteHits-item .product-reviews-summary,
    .ais-InfiniteHits .product-item .product-reviews-summary,
    .ais-InfiniteHits .ais-Hits-item .product-reviews-summary,
    .ais-InfiniteHits .ais-InfiniteHits-item .product-reviews-summary {
      display: none; }
    .product-items .product-item .comment-box .label span:after,
    .product-items .product-item .box-tocart .label span:after,
    .product-items .ais-Hits-item .comment-box .label span:after,
    .product-items .ais-Hits-item .box-tocart .label span:after,
    .product-items .ais-InfiniteHits-item .comment-box .label span:after,
    .product-items .ais-InfiniteHits-item .box-tocart .label span:after,
    .ais-Hits .product-item .comment-box .label span:after,
    .ais-Hits .product-item .box-tocart .label span:after,
    .ais-Hits .ais-Hits-item .comment-box .label span:after,
    .ais-Hits .ais-Hits-item .box-tocart .label span:after,
    .ais-Hits .ais-InfiniteHits-item .comment-box .label span:after,
    .ais-Hits .ais-InfiniteHits-item .box-tocart .label span:after,
    .ais-InfiniteHits .product-item .comment-box .label span:after,
    .ais-InfiniteHits .product-item .box-tocart .label span:after,
    .ais-InfiniteHits .ais-Hits-item .comment-box .label span:after,
    .ais-InfiniteHits .ais-Hits-item .box-tocart .label span:after,
    .ais-InfiniteHits .ais-InfiniteHits-item .comment-box .label span:after,
    .ais-InfiniteHits .ais-InfiniteHits-item .box-tocart .label span:after {
      content: ':'; }
    .product-items .product-item .field.choice .control,
    .product-items .ais-Hits-item .field.choice .control,
    .product-items .ais-InfiniteHits-item .field.choice .control,
    .ais-Hits .product-item .field.choice .control,
    .ais-Hits .ais-Hits-item .field.choice .control,
    .ais-Hits .ais-InfiniteHits-item .field.choice .control,
    .ais-InfiniteHits .product-item .field.choice .control,
    .ais-InfiniteHits .ais-Hits-item .field.choice .control,
    .ais-InfiniteHits .ais-InfiniteHits-item .field.choice .control {
      display: inline-block;
      vertical-align: middle;
      margin-left: .25rem; }
      .product-items .product-item .field.choice .control input,
      .product-items .ais-Hits-item .field.choice .control input,
      .product-items .ais-InfiniteHits-item .field.choice .control input,
      .ais-Hits .product-item .field.choice .control input,
      .ais-Hits .ais-Hits-item .field.choice .control input,
      .ais-Hits .ais-InfiniteHits-item .field.choice .control input,
      .ais-InfiniteHits .product-item .field.choice .control input,
      .ais-InfiniteHits .ais-Hits-item .field.choice .control input,
      .ais-InfiniteHits .ais-InfiniteHits-item .field.choice .control input {
        margin-bottom: 0; }
    .product-items .product-item .field.choice .label,
    .product-items .ais-Hits-item .field.choice .label,
    .product-items .ais-InfiniteHits-item .field.choice .label,
    .ais-Hits .product-item .field.choice .label,
    .ais-Hits .ais-Hits-item .field.choice .label,
    .ais-Hits .ais-InfiniteHits-item .field.choice .label,
    .ais-InfiniteHits .product-item .field.choice .label,
    .ais-InfiniteHits .ais-Hits-item .field.choice .label,
    .ais-InfiniteHits .ais-InfiniteHits-item .field.choice .label {
      cursor: pointer; }
    .product-items .product-item .box-tocart .field,
    .product-items .ais-Hits-item .box-tocart .field,
    .product-items .ais-InfiniteHits-item .box-tocart .field,
    .ais-Hits .product-item .box-tocart .field,
    .ais-Hits .ais-Hits-item .box-tocart .field,
    .ais-Hits .ais-InfiniteHits-item .box-tocart .field,
    .ais-InfiniteHits .product-item .box-tocart .field,
    .ais-InfiniteHits .ais-Hits-item .box-tocart .field,
    .ais-InfiniteHits .ais-InfiniteHits-item .box-tocart .field {
      width: 1%;
      display: table-cell;
      vertical-align: bottom;
      text-align: left;
      padding-right: .5rem; }
      .product-items .product-item .box-tocart .field .input-text,
      .product-items .ais-Hits-item .box-tocart .field .input-text,
      .product-items .ais-InfiniteHits-item .box-tocart .field .input-text,
      .ais-Hits .product-item .box-tocart .field .input-text,
      .ais-Hits .ais-Hits-item .box-tocart .field .input-text,
      .ais-Hits .ais-InfiniteHits-item .box-tocart .field .input-text,
      .ais-InfiniteHits .product-item .box-tocart .field .input-text,
      .ais-InfiniteHits .ais-Hits-item .box-tocart .field .input-text,
      .ais-InfiniteHits .ais-InfiniteHits-item .box-tocart .field .input-text {
        width: 3.5rem;
        text-align: center; }
    .product-items .product-item .box-tocart .product-item-actions,
    .product-items .ais-Hits-item .box-tocart .product-item-actions,
    .product-items .ais-InfiniteHits-item .box-tocart .product-item-actions,
    .ais-Hits .product-item .box-tocart .product-item-actions,
    .ais-Hits .ais-Hits-item .box-tocart .product-item-actions,
    .ais-Hits .ais-InfiniteHits-item .box-tocart .product-item-actions,
    .ais-InfiniteHits .product-item .box-tocart .product-item-actions,
    .ais-InfiniteHits .ais-Hits-item .box-tocart .product-item-actions,
    .ais-InfiniteHits .ais-InfiniteHits-item .box-tocart .product-item-actions {
      width: 99%;
      display: table-cell;
      vertical-align: bottom; }
      .product-items .product-item .box-tocart .product-item-actions .action,
      .product-items .ais-Hits-item .box-tocart .product-item-actions .action,
      .product-items .ais-InfiniteHits-item .box-tocart .product-item-actions .action,
      .ais-Hits .product-item .box-tocart .product-item-actions .action,
      .ais-Hits .ais-Hits-item .box-tocart .product-item-actions .action,
      .ais-Hits .ais-InfiniteHits-item .box-tocart .product-item-actions .action,
      .ais-InfiniteHits .product-item .box-tocart .product-item-actions .action,
      .ais-InfiniteHits .ais-Hits-item .box-tocart .product-item-actions .action,
      .ais-InfiniteHits .ais-InfiniteHits-item .box-tocart .product-item-actions .action {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-left: 0; }
    .product-items .product-item .tooltip.wrapper,
    .product-items .ais-Hits-item .tooltip.wrapper,
    .product-items .ais-InfiniteHits-item .tooltip.wrapper,
    .ais-Hits .product-item .tooltip.wrapper,
    .ais-Hits .ais-Hits-item .tooltip.wrapper,
    .ais-Hits .ais-InfiniteHits-item .tooltip.wrapper,
    .ais-InfiniteHits .product-item .tooltip.wrapper,
    .ais-InfiniteHits .ais-Hits-item .tooltip.wrapper,
    .ais-InfiniteHits .ais-InfiniteHits-item .tooltip.wrapper {
      margin-bottom: 1rem; }
    .product-items .product-item .tooltip.wrapper, .product-items .product-item .tooltip.toggle,
    .product-items .ais-Hits-item .tooltip.wrapper,
    .product-items .ais-Hits-item .tooltip.toggle,
    .product-items .ais-InfiniteHits-item .tooltip.wrapper,
    .product-items .ais-InfiniteHits-item .tooltip.toggle,
    .ais-Hits .product-item .tooltip.wrapper,
    .ais-Hits .product-item .tooltip.toggle,
    .ais-Hits .ais-Hits-item .tooltip.wrapper,
    .ais-Hits .ais-Hits-item .tooltip.toggle,
    .ais-Hits .ais-InfiniteHits-item .tooltip.wrapper,
    .ais-Hits .ais-InfiniteHits-item .tooltip.toggle,
    .ais-InfiniteHits .product-item .tooltip.wrapper,
    .ais-InfiniteHits .product-item .tooltip.toggle,
    .ais-InfiniteHits .ais-Hits-item .tooltip.wrapper,
    .ais-InfiniteHits .ais-Hits-item .tooltip.toggle,
    .ais-InfiniteHits .ais-InfiniteHits-item .tooltip.wrapper,
    .ais-InfiniteHits .ais-InfiniteHits-item .tooltip.toggle {
      position: relative;
      color: inherit;
      font-size: inherit;
      padding: 0;
      top: auto;
      z-index: inherit;
      max-width: 100% !important;
      background: none; }
      .product-items .product-item .tooltip.wrapper:before, .product-items .product-item .tooltip.toggle:before,
      .product-items .ais-Hits-item .tooltip.wrapper:before,
      .product-items .ais-Hits-item .tooltip.toggle:before,
      .product-items .ais-InfiniteHits-item .tooltip.wrapper:before,
      .product-items .ais-InfiniteHits-item .tooltip.toggle:before,
      .ais-Hits .product-item .tooltip.wrapper:before,
      .ais-Hits .product-item .tooltip.toggle:before,
      .ais-Hits .ais-Hits-item .tooltip.wrapper:before,
      .ais-Hits .ais-Hits-item .tooltip.toggle:before,
      .ais-Hits .ais-InfiniteHits-item .tooltip.wrapper:before,
      .ais-Hits .ais-InfiniteHits-item .tooltip.toggle:before,
      .ais-InfiniteHits .product-item .tooltip.wrapper:before,
      .ais-InfiniteHits .product-item .tooltip.toggle:before,
      .ais-InfiniteHits .ais-Hits-item .tooltip.wrapper:before,
      .ais-InfiniteHits .ais-Hits-item .tooltip.toggle:before,
      .ais-InfiniteHits .ais-InfiniteHits-item .tooltip.wrapper:before,
      .ais-InfiniteHits .ais-InfiniteHits-item .tooltip.toggle:before {
        display: none; }
    .product-items .product-item .tooltip.toggle,
    .product-items .ais-Hits-item .tooltip.toggle,
    .product-items .ais-InfiniteHits-item .tooltip.toggle,
    .ais-Hits .product-item .tooltip.toggle,
    .ais-Hits .ais-Hits-item .tooltip.toggle,
    .ais-Hits .ais-InfiniteHits-item .tooltip.toggle,
    .ais-InfiniteHits .product-item .tooltip.toggle,
    .ais-InfiniteHits .ais-Hits-item .tooltip.toggle,
    .ais-InfiniteHits .ais-InfiniteHits-item .tooltip.toggle {
      display: inline-block;
      text-decoration: underline;
      color: #181818;
      cursor: pointer; }
      .product-items .product-item .tooltip.toggle:hover, .product-items .product-item .tooltip.toggle:active,
      .product-items .ais-Hits-item .tooltip.toggle:hover,
      .product-items .ais-Hits-item .tooltip.toggle:active,
      .product-items .ais-InfiniteHits-item .tooltip.toggle:hover,
      .product-items .ais-InfiniteHits-item .tooltip.toggle:active,
      .ais-Hits .product-item .tooltip.toggle:hover,
      .ais-Hits .product-item .tooltip.toggle:active,
      .ais-Hits .ais-Hits-item .tooltip.toggle:hover,
      .ais-Hits .ais-Hits-item .tooltip.toggle:active,
      .ais-Hits .ais-InfiniteHits-item .tooltip.toggle:hover,
      .ais-Hits .ais-InfiniteHits-item .tooltip.toggle:active,
      .ais-InfiniteHits .product-item .tooltip.toggle:hover,
      .ais-InfiniteHits .product-item .tooltip.toggle:active,
      .ais-InfiniteHits .ais-Hits-item .tooltip.toggle:hover,
      .ais-InfiniteHits .ais-Hits-item .tooltip.toggle:active,
      .ais-InfiniteHits .ais-InfiniteHits-item .tooltip.toggle:hover,
      .ais-InfiniteHits .ais-InfiniteHits-item .tooltip.toggle:active {
        color: #181818; }
        .product-items .product-item .tooltip.toggle:hover + .tooltip.content, .product-items .product-item .tooltip.toggle:active + .tooltip.content,
        .product-items .ais-Hits-item .tooltip.toggle:hover + .tooltip.content,
        .product-items .ais-Hits-item .tooltip.toggle:active + .tooltip.content,
        .product-items .ais-InfiniteHits-item .tooltip.toggle:hover + .tooltip.content,
        .product-items .ais-InfiniteHits-item .tooltip.toggle:active + .tooltip.content,
        .ais-Hits .product-item .tooltip.toggle:hover + .tooltip.content,
        .ais-Hits .product-item .tooltip.toggle:active + .tooltip.content,
        .ais-Hits .ais-Hits-item .tooltip.toggle:hover + .tooltip.content,
        .ais-Hits .ais-Hits-item .tooltip.toggle:active + .tooltip.content,
        .ais-Hits .ais-InfiniteHits-item .tooltip.toggle:hover + .tooltip.content,
        .ais-Hits .ais-InfiniteHits-item .tooltip.toggle:active + .tooltip.content,
        .ais-InfiniteHits .product-item .tooltip.toggle:hover + .tooltip.content,
        .ais-InfiniteHits .product-item .tooltip.toggle:active + .tooltip.content,
        .ais-InfiniteHits .ais-Hits-item .tooltip.toggle:hover + .tooltip.content,
        .ais-InfiniteHits .ais-Hits-item .tooltip.toggle:active + .tooltip.content,
        .ais-InfiniteHits .ais-InfiniteHits-item .tooltip.toggle:hover + .tooltip.content,
        .ais-InfiniteHits .ais-InfiniteHits-item .tooltip.toggle:active + .tooltip.content {
          display: block; }
    .product-items .product-item .tooltip.content,
    .product-items .ais-Hits-item .tooltip.content,
    .product-items .ais-InfiniteHits-item .tooltip.content,
    .ais-Hits .product-item .tooltip.content,
    .ais-Hits .ais-Hits-item .tooltip.content,
    .ais-Hits .ais-InfiniteHits-item .tooltip.content,
    .ais-InfiniteHits .product-item .tooltip.content,
    .ais-InfiniteHits .ais-Hits-item .tooltip.content,
    .ais-InfiniteHits .ais-InfiniteHits-item .tooltip.content {
      display: none;
      min-width: 100%;
      left: 50%;
      text-align: left;
      transform: translateX(-50%); }
      .product-items .product-item .tooltip.content dl,
      .product-items .ais-Hits-item .tooltip.content dl,
      .product-items .ais-InfiniteHits-item .tooltip.content dl,
      .ais-Hits .product-item .tooltip.content dl,
      .ais-Hits .ais-Hits-item .tooltip.content dl,
      .ais-Hits .ais-InfiniteHits-item .tooltip.content dl,
      .ais-InfiniteHits .product-item .tooltip.content dl,
      .ais-InfiniteHits .ais-Hits-item .tooltip.content dl,
      .ais-InfiniteHits .ais-InfiniteHits-item .tooltip.content dl {
        margin: 0; }
      .product-items .product-item .tooltip.content .subtitle,
      .product-items .ais-Hits-item .tooltip.content .subtitle,
      .product-items .ais-InfiniteHits-item .tooltip.content .subtitle,
      .ais-Hits .product-item .tooltip.content .subtitle,
      .ais-Hits .ais-Hits-item .tooltip.content .subtitle,
      .ais-Hits .ais-InfiniteHits-item .tooltip.content .subtitle,
      .ais-InfiniteHits .product-item .tooltip.content .subtitle,
      .ais-InfiniteHits .ais-Hits-item .tooltip.content .subtitle,
      .ais-InfiniteHits .ais-InfiniteHits-item .tooltip.content .subtitle {
        display: block;
        font-size: 120%;
        margin-bottom: .5rem;
        border-bottom: 1px solid white; }
      .product-items .product-item .tooltip.content .label,
      .product-items .ais-Hits-item .tooltip.content .label,
      .product-items .ais-InfiniteHits-item .tooltip.content .label,
      .ais-Hits .product-item .tooltip.content .label,
      .ais-Hits .ais-Hits-item .tooltip.content .label,
      .ais-Hits .ais-InfiniteHits-item .tooltip.content .label,
      .ais-InfiniteHits .product-item .tooltip.content .label,
      .ais-InfiniteHits .ais-Hits-item .tooltip.content .label,
      .ais-InfiniteHits .ais-InfiniteHits-item .tooltip.content .label {
        padding: 0;
        vertical-align: middle;
        color: #181818;
        font-size: inherit;
        line-height: inherit;
        white-space: normal;
        background: none;
        color: white;
        margin-bottom: 0; }
        .product-items .product-item .tooltip.content .label:after,
        .product-items .ais-Hits-item .tooltip.content .label:after,
        .product-items .ais-InfiniteHits-item .tooltip.content .label:after,
        .ais-Hits .product-item .tooltip.content .label:after,
        .ais-Hits .ais-Hits-item .tooltip.content .label:after,
        .ais-Hits .ais-InfiniteHits-item .tooltip.content .label:after,
        .ais-InfiniteHits .product-item .tooltip.content .label:after,
        .ais-InfiniteHits .ais-Hits-item .tooltip.content .label:after,
        .ais-InfiniteHits .ais-InfiniteHits-item .tooltip.content .label:after {
          content: ':'; }
      .product-items .product-item .tooltip.content .values,
      .product-items .ais-Hits-item .tooltip.content .values,
      .product-items .ais-InfiniteHits-item .tooltip.content .values,
      .ais-Hits .product-item .tooltip.content .values,
      .ais-Hits .ais-Hits-item .tooltip.content .values,
      .ais-Hits .ais-InfiniteHits-item .tooltip.content .values,
      .ais-InfiniteHits .product-item .tooltip.content .values,
      .ais-InfiniteHits .ais-Hits-item .tooltip.content .values,
      .ais-InfiniteHits .ais-InfiniteHits-item .tooltip.content .values {
        margin-bottom: .5rem; }
    .product-items .product-item .action.tocart,
    .product-items .ais-Hits-item .action.tocart,
    .product-items .ais-InfiniteHits-item .action.tocart,
    .ais-Hits .product-item .action.tocart,
    .ais-Hits .ais-Hits-item .action.tocart,
    .ais-Hits .ais-InfiniteHits-item .action.tocart,
    .ais-InfiniteHits .product-item .action.tocart,
    .ais-InfiniteHits .ais-Hits-item .action.tocart,
    .ais-InfiniteHits .ais-InfiniteHits-item .action.tocart {
      display: block;
      width: 100%;
      margin-right: 0;
      margin-left: 0; }
    .product-items .product-item .product-item-info,
    .product-items .ais-Hits-item .product-item-info,
    .product-items .ais-InfiniteHits-item .product-item-info,
    .ais-Hits .product-item .product-item-info,
    .ais-Hits .ais-Hits-item .product-item-info,
    .ais-Hits .ais-InfiniteHits-item .product-item-info,
    .ais-InfiniteHits .product-item .product-item-info,
    .ais-InfiniteHits .ais-Hits-item .product-item-info,
    .ais-InfiniteHits .ais-InfiniteHits-item .product-item-info {
      position: relative; }
    .product-items .product-item .price-box + .stock.unavailable,
    .product-items .ais-Hits-item .price-box + .stock.unavailable,
    .product-items .ais-InfiniteHits-item .price-box + .stock.unavailable,
    .ais-Hits .product-item .price-box + .stock.unavailable,
    .ais-Hits .ais-Hits-item .price-box + .stock.unavailable,
    .ais-Hits .ais-InfiniteHits-item .price-box + .stock.unavailable,
    .ais-InfiniteHits .product-item .price-box + .stock.unavailable,
    .ais-InfiniteHits .ais-Hits-item .price-box + .stock.unavailable,
    .ais-InfiniteHits .ais-InfiniteHits-item .price-box + .stock.unavailable {
      display: block;
      width: 90px;
      float: none;
      text-align: center;
      flex-basis: inherit;
      background: #eee;
      color: #30A95E;
      font-family: "silka-regular-webfont", sans-serif;
      font-weight: 300;
      font-size: 0.75rem;
      line-height: 0.9375rem;
      padding: 0.5625rem;
      position: absolute;
      bottom: 20px;
      left: 50%;
      line-height: 1;
      margin-left: -45px; }
      @media print, screen and (min-width: 40em) {
        .product-items .product-item .price-box + .stock.unavailable,
        .product-items .ais-Hits-item .price-box + .stock.unavailable,
        .product-items .ais-InfiniteHits-item .price-box + .stock.unavailable,
        .ais-Hits .product-item .price-box + .stock.unavailable,
        .ais-Hits .ais-Hits-item .price-box + .stock.unavailable,
        .ais-Hits .ais-InfiniteHits-item .price-box + .stock.unavailable,
        .ais-InfiniteHits .product-item .price-box + .stock.unavailable,
        .ais-InfiniteHits .ais-Hits-item .price-box + .stock.unavailable,
        .ais-InfiniteHits .ais-InfiniteHits-item .price-box + .stock.unavailable {
          width: 120px;
          margin-left: -60px;
          font-size: 0.8125rem; } }
      @media screen and (min-width: 64em) {
        .product-items .product-item .price-box + .stock.unavailable,
        .product-items .ais-Hits-item .price-box + .stock.unavailable,
        .product-items .ais-InfiniteHits-item .price-box + .stock.unavailable,
        .ais-Hits .product-item .price-box + .stock.unavailable,
        .ais-Hits .ais-Hits-item .price-box + .stock.unavailable,
        .ais-Hits .ais-InfiniteHits-item .price-box + .stock.unavailable,
        .ais-InfiniteHits .product-item .price-box + .stock.unavailable,
        .ais-InfiniteHits .ais-Hits-item .price-box + .stock.unavailable,
        .ais-InfiniteHits .ais-InfiniteHits-item .price-box + .stock.unavailable {
          width: 170px;
          margin-left: -85px;
          font-size: 1rem; } }
      .no-touchevents .product-items .product-item .price-box + .stock.unavailable, .no-touchevents
      .product-items .ais-Hits-item .price-box + .stock.unavailable, .no-touchevents
      .product-items .ais-InfiniteHits-item .price-box + .stock.unavailable, .no-touchevents
      .ais-Hits .product-item .price-box + .stock.unavailable, .no-touchevents
      .ais-Hits .ais-Hits-item .price-box + .stock.unavailable, .no-touchevents
      .ais-Hits .ais-InfiniteHits-item .price-box + .stock.unavailable, .no-touchevents
      .ais-InfiniteHits .product-item .price-box + .stock.unavailable, .no-touchevents
      .ais-InfiniteHits .ais-Hits-item .price-box + .stock.unavailable, .no-touchevents
      .ais-InfiniteHits .ais-InfiniteHits-item .price-box + .stock.unavailable {
        opacity: 0;
        transition: opacity .3s ease; }
      .product-items .product-item .price-box + .stock.unavailable.available,
      .product-items .ais-Hits-item .price-box + .stock.unavailable.available,
      .product-items .ais-InfiniteHits-item .price-box + .stock.unavailable.available,
      .ais-Hits .product-item .price-box + .stock.unavailable.available,
      .ais-Hits .ais-Hits-item .price-box + .stock.unavailable.available,
      .ais-Hits .ais-InfiniteHits-item .price-box + .stock.unavailable.available,
      .ais-InfiniteHits .product-item .price-box + .stock.unavailable.available,
      .ais-InfiniteHits .ais-Hits-item .price-box + .stock.unavailable.available,
      .ais-InfiniteHits .ais-InfiniteHits-item .price-box + .stock.unavailable.available {
        position: absolute !important;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0); }
    .no-touchevents .product-items .product-item:hover .price-box + .stock.unavailable, .no-touchevents .product-items .product-item:focus .price-box + .stock.unavailable, .no-touchevents
    .product-items .ais-Hits-item:hover .price-box + .stock.unavailable, .no-touchevents
    .product-items .ais-Hits-item:focus .price-box + .stock.unavailable, .no-touchevents
    .product-items .ais-InfiniteHits-item:hover .price-box + .stock.unavailable, .no-touchevents
    .product-items .ais-InfiniteHits-item:focus .price-box + .stock.unavailable, .no-touchevents
    .ais-Hits .product-item:hover .price-box + .stock.unavailable, .no-touchevents
    .ais-Hits .product-item:focus .price-box + .stock.unavailable, .no-touchevents
    .ais-Hits .ais-Hits-item:hover .price-box + .stock.unavailable, .no-touchevents
    .ais-Hits .ais-Hits-item:focus .price-box + .stock.unavailable, .no-touchevents
    .ais-Hits .ais-InfiniteHits-item:hover .price-box + .stock.unavailable, .no-touchevents
    .ais-Hits .ais-InfiniteHits-item:focus .price-box + .stock.unavailable, .no-touchevents
    .ais-InfiniteHits .product-item:hover .price-box + .stock.unavailable, .no-touchevents
    .ais-InfiniteHits .product-item:focus .price-box + .stock.unavailable, .no-touchevents
    .ais-InfiniteHits .ais-Hits-item:hover .price-box + .stock.unavailable, .no-touchevents
    .ais-InfiniteHits .ais-Hits-item:focus .price-box + .stock.unavailable, .no-touchevents
    .ais-InfiniteHits .ais-InfiniteHits-item:hover .price-box + .stock.unavailable, .no-touchevents
    .ais-InfiniteHits .ais-InfiniteHits-item:focus .price-box + .stock.unavailable {
      opacity: 1;
      z-index: 1; }
    @media print, screen and (min-width: 40em) {
      .product-items .product-item,
      .product-items .ais-Hits-item,
      .product-items .ais-InfiniteHits-item,
      .ais-Hits .product-item,
      .ais-Hits .ais-Hits-item,
      .ais-Hits .ais-InfiniteHits-item,
      .ais-InfiniteHits .product-item,
      .ais-InfiniteHits .ais-Hits-item,
      .ais-InfiniteHits .ais-InfiniteHits-item {
        width: 50%;
        margin-bottom: 0; } }
    @media screen and (min-width: 64em) {
      .product-items .product-item,
      .product-items .ais-Hits-item,
      .product-items .ais-InfiniteHits-item,
      .ais-Hits .product-item,
      .ais-Hits .ais-Hits-item,
      .ais-Hits .ais-InfiniteHits-item,
      .ais-InfiniteHits .product-item,
      .ais-InfiniteHits .ais-Hits-item,
      .ais-InfiniteHits .ais-InfiniteHits-item {
        width: 25%; }
        .product-items .product-item:nth-child(2n+1),
        .product-items .ais-Hits-item:nth-child(2n+1),
        .product-items .ais-InfiniteHits-item:nth-child(2n+1),
        .ais-Hits .product-item:nth-child(2n+1),
        .ais-Hits .ais-Hits-item:nth-child(2n+1),
        .ais-Hits .ais-InfiniteHits-item:nth-child(2n+1),
        .ais-InfiniteHits .product-item:nth-child(2n+1),
        .ais-InfiniteHits .ais-Hits-item:nth-child(2n+1),
        .ais-InfiniteHits .ais-InfiniteHits-item:nth-child(2n+1) {
          clear: none; }
        .product-items .product-item:nth-child(4n+1),
        .product-items .ais-Hits-item:nth-child(4n+1),
        .product-items .ais-InfiniteHits-item:nth-child(4n+1),
        .ais-Hits .product-item:nth-child(4n+1),
        .ais-Hits .ais-Hits-item:nth-child(4n+1),
        .ais-Hits .ais-InfiniteHits-item:nth-child(4n+1),
        .ais-InfiniteHits .product-item:nth-child(4n+1),
        .ais-InfiniteHits .ais-Hits-item:nth-child(4n+1),
        .ais-InfiniteHits .ais-InfiniteHits-item:nth-child(4n+1) {
          clear: both; }
        .page-layout-2columns-left .product-items .product-item,
        .algolia-right-container .product-items .product-item, .page-layout-2columns-left
        .product-items .ais-Hits-item,
        .algolia-right-container
        .product-items .ais-Hits-item, .page-layout-2columns-left
        .product-items .ais-InfiniteHits-item,
        .algolia-right-container
        .product-items .ais-InfiniteHits-item, .page-layout-2columns-left
        .ais-Hits .product-item,
        .algolia-right-container
        .ais-Hits .product-item, .page-layout-2columns-left
        .ais-Hits .ais-Hits-item,
        .algolia-right-container
        .ais-Hits .ais-Hits-item, .page-layout-2columns-left
        .ais-Hits .ais-InfiniteHits-item,
        .algolia-right-container
        .ais-Hits .ais-InfiniteHits-item, .page-layout-2columns-left
        .ais-InfiniteHits .product-item,
        .algolia-right-container
        .ais-InfiniteHits .product-item, .page-layout-2columns-left
        .ais-InfiniteHits .ais-Hits-item,
        .algolia-right-container
        .ais-InfiniteHits .ais-Hits-item, .page-layout-2columns-left
        .ais-InfiniteHits .ais-InfiniteHits-item,
        .algolia-right-container
        .ais-InfiniteHits .ais-InfiniteHits-item {
          margin-bottom: 0;
          width: 33.33333%; }
          .page-layout-2columns-left .product-items .product-item:nth-child(4n+1),
          .algolia-right-container .product-items .product-item:nth-child(4n+1), .page-layout-2columns-left
          .product-items .ais-Hits-item:nth-child(4n+1),
          .algolia-right-container
          .product-items .ais-Hits-item:nth-child(4n+1), .page-layout-2columns-left
          .product-items .ais-InfiniteHits-item:nth-child(4n+1),
          .algolia-right-container
          .product-items .ais-InfiniteHits-item:nth-child(4n+1), .page-layout-2columns-left
          .ais-Hits .product-item:nth-child(4n+1),
          .algolia-right-container
          .ais-Hits .product-item:nth-child(4n+1), .page-layout-2columns-left
          .ais-Hits .ais-Hits-item:nth-child(4n+1),
          .algolia-right-container
          .ais-Hits .ais-Hits-item:nth-child(4n+1), .page-layout-2columns-left
          .ais-Hits .ais-InfiniteHits-item:nth-child(4n+1),
          .algolia-right-container
          .ais-Hits .ais-InfiniteHits-item:nth-child(4n+1), .page-layout-2columns-left
          .ais-InfiniteHits .product-item:nth-child(4n+1),
          .algolia-right-container
          .ais-InfiniteHits .product-item:nth-child(4n+1), .page-layout-2columns-left
          .ais-InfiniteHits .ais-Hits-item:nth-child(4n+1),
          .algolia-right-container
          .ais-InfiniteHits .ais-Hits-item:nth-child(4n+1), .page-layout-2columns-left
          .ais-InfiniteHits .ais-InfiniteHits-item:nth-child(4n+1),
          .algolia-right-container
          .ais-InfiniteHits .ais-InfiniteHits-item:nth-child(4n+1) {
            clear: none; }
          .page-layout-2columns-left .product-items .product-item:nth-child(3n+1),
          .algolia-right-container .product-items .product-item:nth-child(3n+1), .page-layout-2columns-left
          .product-items .ais-Hits-item:nth-child(3n+1),
          .algolia-right-container
          .product-items .ais-Hits-item:nth-child(3n+1), .page-layout-2columns-left
          .product-items .ais-InfiniteHits-item:nth-child(3n+1),
          .algolia-right-container
          .product-items .ais-InfiniteHits-item:nth-child(3n+1), .page-layout-2columns-left
          .ais-Hits .product-item:nth-child(3n+1),
          .algolia-right-container
          .ais-Hits .product-item:nth-child(3n+1), .page-layout-2columns-left
          .ais-Hits .ais-Hits-item:nth-child(3n+1),
          .algolia-right-container
          .ais-Hits .ais-Hits-item:nth-child(3n+1), .page-layout-2columns-left
          .ais-Hits .ais-InfiniteHits-item:nth-child(3n+1),
          .algolia-right-container
          .ais-Hits .ais-InfiniteHits-item:nth-child(3n+1), .page-layout-2columns-left
          .ais-InfiniteHits .product-item:nth-child(3n+1),
          .algolia-right-container
          .ais-InfiniteHits .product-item:nth-child(3n+1), .page-layout-2columns-left
          .ais-InfiniteHits .ais-Hits-item:nth-child(3n+1),
          .algolia-right-container
          .ais-InfiniteHits .ais-Hits-item:nth-child(3n+1), .page-layout-2columns-left
          .ais-InfiniteHits .ais-InfiniteHits-item:nth-child(3n+1),
          .algolia-right-container
          .ais-InfiniteHits .ais-InfiniteHits-item:nth-child(3n+1) {
            clear: left; }
        .block-wishlist .product-items .product-item,
        .block-reorder .product-items .product-item,
        .block-compare .product-items .product-item, .block-wishlist
        .product-items .ais-Hits-item,
        .block-reorder
        .product-items .ais-Hits-item,
        .block-compare
        .product-items .ais-Hits-item, .block-wishlist
        .product-items .ais-InfiniteHits-item,
        .block-reorder
        .product-items .ais-InfiniteHits-item,
        .block-compare
        .product-items .ais-InfiniteHits-item, .block-wishlist
        .ais-Hits .product-item,
        .block-reorder
        .ais-Hits .product-item,
        .block-compare
        .ais-Hits .product-item, .block-wishlist
        .ais-Hits .ais-Hits-item,
        .block-reorder
        .ais-Hits .ais-Hits-item,
        .block-compare
        .ais-Hits .ais-Hits-item, .block-wishlist
        .ais-Hits .ais-InfiniteHits-item,
        .block-reorder
        .ais-Hits .ais-InfiniteHits-item,
        .block-compare
        .ais-Hits .ais-InfiniteHits-item, .block-wishlist
        .ais-InfiniteHits .product-item,
        .block-reorder
        .ais-InfiniteHits .product-item,
        .block-compare
        .ais-InfiniteHits .product-item, .block-wishlist
        .ais-InfiniteHits .ais-Hits-item,
        .block-reorder
        .ais-InfiniteHits .ais-Hits-item,
        .block-compare
        .ais-InfiniteHits .ais-Hits-item, .block-wishlist
        .ais-InfiniteHits .ais-InfiniteHits-item,
        .block-reorder
        .ais-InfiniteHits .ais-InfiniteHits-item,
        .block-compare
        .ais-InfiniteHits .ais-InfiniteHits-item {
          width: 50%;
          padding: 0.53125rem;
          margin-bottom: .5rem; } }
    @media screen and (min-width: 64em) and (min-width: 40em) {
      .block-wishlist .product-items .product-item,
      .block-reorder .product-items .product-item,
      .block-compare .product-items .product-item, .block-wishlist
      .product-items .ais-Hits-item,
      .block-reorder
      .product-items .ais-Hits-item,
      .block-compare
      .product-items .ais-Hits-item, .block-wishlist
      .product-items .ais-InfiniteHits-item,
      .block-reorder
      .product-items .ais-InfiniteHits-item,
      .block-compare
      .product-items .ais-InfiniteHits-item, .block-wishlist
      .ais-Hits .product-item,
      .block-reorder
      .ais-Hits .product-item,
      .block-compare
      .ais-Hits .product-item, .block-wishlist
      .ais-Hits .ais-Hits-item,
      .block-reorder
      .ais-Hits .ais-Hits-item,
      .block-compare
      .ais-Hits .ais-Hits-item, .block-wishlist
      .ais-Hits .ais-InfiniteHits-item,
      .block-reorder
      .ais-Hits .ais-InfiniteHits-item,
      .block-compare
      .ais-Hits .ais-InfiniteHits-item, .block-wishlist
      .ais-InfiniteHits .product-item,
      .block-reorder
      .ais-InfiniteHits .product-item,
      .block-compare
      .ais-InfiniteHits .product-item, .block-wishlist
      .ais-InfiniteHits .ais-Hits-item,
      .block-reorder
      .ais-InfiniteHits .ais-Hits-item,
      .block-compare
      .ais-InfiniteHits .ais-Hits-item, .block-wishlist
      .ais-InfiniteHits .ais-InfiniteHits-item,
      .block-reorder
      .ais-InfiniteHits .ais-InfiniteHits-item,
      .block-compare
      .ais-InfiniteHits .ais-InfiniteHits-item {
        padding: 0.625rem; } }
  .product-items .no-results,
  .ais-Hits .no-results,
  .ais-InfiniteHits .no-results {
    padding-left: 0.53125rem;
    padding-right: 0.53125rem; }
    @media print, screen and (min-width: 40em) {
      .product-items .no-results,
      .ais-Hits .no-results,
      .ais-InfiniteHits .no-results {
        padding-left: 0.625rem; } }
    @media print, screen and (min-width: 40em) {
      .product-items .no-results,
      .ais-Hits .no-results,
      .ais-InfiniteHits .no-results {
        padding-right: 0.625rem; } }
    @media screen and (min-width: 64em) {
      .product-items .no-results,
      .ais-Hits .no-results,
      .ais-InfiniteHits .no-results {
        padding-left: 1.0625rem;
        padding-right: 1.0625rem; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .product-items .no-results,
    .ais-Hits .no-results,
    .ais-InfiniteHits .no-results {
      padding-left: 1.25rem; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .product-items .no-results,
    .ais-Hits .no-results,
    .ais-InfiniteHits .no-results {
      padding-right: 1.25rem; } }

.block.grid, .block.related, .block.crosssell, .block.upsell, .block.block-new-products-names {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto; }
  .block.grid + .block,
  .block.grid + .nosto_element, .block.related + .block,
  .block.related + .nosto_element, .block.crosssell + .block,
  .block.crosssell + .nosto_element, .block.upsell + .block,
  .block.upsell + .nosto_element, .block.block-new-products-names + .block,
  .block.block-new-products-names + .nosto_element {
    margin-top: 2rem; }
  .block.grid::before, .block.grid::after, .block.related::before, .block.related::after, .block.crosssell::before, .block.crosssell::after, .block.upsell::before, .block.upsell::after, .block.block-new-products-names::before, .block.block-new-products-names::after {
    display: table;
    content: ' '; }
  .block.grid::after, .block.related::after, .block.crosssell::after, .block.upsell::after, .block.block-new-products-names::after {
    clear: both; }
  .block.grid .block-title strong, .block.related .block-title strong, .block.crosssell .block-title strong, .block.upsell .block-title strong, .block.block-new-products-names .block-title strong {
    display: block; }
    .block.grid .block-title strong span, .block.related .block-title strong span, .block.crosssell .block-title strong span, .block.upsell .block-title strong span, .block.block-new-products-names .block-title strong span {
      display: inline-block;
      vertical-align: middle;
      max-width: 75%; }
    @media print, screen and (min-width: 40em) {
      .block.grid .block-title strong, .block.related .block-title strong, .block.crosssell .block-title strong, .block.upsell .block-title strong, .block.block-new-products-names .block-title strong {
        font-size: 1.625rem; } }
    @media screen and (min-width: 75em) {
      .block.grid .block-title strong, .block.related .block-title strong, .block.crosssell .block-title strong, .block.upsell .block-title strong, .block.block-new-products-names .block-title strong {
        font-size: 2rem; } }
    @media screen and (min-width: 90.625em) {
      .block.grid .block-title strong, .block.related .block-title strong, .block.crosssell .block-title strong, .block.upsell .block-title strong, .block.block-new-products-names .block-title strong {
        font-size: 2.25rem; } }
  .page-layout-0column .block.grid .block-title, .page-layout-0column .block.related .block-title, .page-layout-0column .block.crosssell .block-title, .page-layout-0column .block.upsell .block-title, .page-layout-0column .block.block-new-products-names .block-title {
    padding-left: 1.0625rem;
    padding-right: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .page-layout-0column .block.grid .block-title, .page-layout-0column .block.related .block-title, .page-layout-0column .block.crosssell .block-title, .page-layout-0column .block.upsell .block-title, .page-layout-0column .block.block-new-products-names .block-title {
        padding-left: 1.25rem; } }
    @media print, screen and (min-width: 40em) {
      .page-layout-0column .block.grid .block-title, .page-layout-0column .block.related .block-title, .page-layout-0column .block.crosssell .block-title, .page-layout-0column .block.upsell .block-title, .page-layout-0column .block.block-new-products-names .block-title {
        padding-right: 1.25rem; } }
  .block.grid .block-content > .block-actions,
  .block.grid .block-content .field.choice.related, .block.related .block-content > .block-actions,
  .block.related .block-content .field.choice.related, .block.crosssell .block-content > .block-actions,
  .block.crosssell .block-content .field.choice.related, .block.upsell .block-content > .block-actions,
  .block.upsell .block-content .field.choice.related, .block.block-new-products-names .block-content > .block-actions,
  .block.block-new-products-names .block-content .field.choice.related {
    display: none; }
  .page-layout-0column .block.grid .block-content, .page-layout-0column .block.related .block-content, .page-layout-0column .block.crosssell .block-content, .page-layout-0column .block.upsell .block-content, .page-layout-0column .block.block-new-products-names .block-content {
    padding-left: 1.0625rem;
    padding-right: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .page-layout-0column .block.grid .block-content, .page-layout-0column .block.related .block-content, .page-layout-0column .block.crosssell .block-content, .page-layout-0column .block.upsell .block-content, .page-layout-0column .block.block-new-products-names .block-content {
        padding-left: 1.25rem; } }
    @media print, screen and (min-width: 40em) {
      .page-layout-0column .block.grid .block-content, .page-layout-0column .block.related .block-content, .page-layout-0column .block.crosssell .block-content, .page-layout-0column .block.upsell .block-content, .page-layout-0column .block.block-new-products-names .block-content {
        padding-right: 1.25rem; } }
  .page-layout-0column .block.grid.block-products-list .block-title,
  .page-layout-0column .block.grid.block-products-list .block-content, .page-layout-0column .block.related.block-products-list .block-title,
  .page-layout-0column .block.related.block-products-list .block-content, .page-layout-0column .block.crosssell.block-products-list .block-title,
  .page-layout-0column .block.crosssell.block-products-list .block-content, .page-layout-0column .block.upsell.block-products-list .block-title,
  .page-layout-0column .block.upsell.block-products-list .block-content, .page-layout-0column .block.block-new-products-names.block-products-list .block-title,
  .page-layout-0column .block.block-new-products-names.block-products-list .block-content {
    padding-left: 0rem;
    padding-right: 0rem; }
    @media print, screen and (min-width: 40em) {
      .page-layout-0column .block.grid.block-products-list .block-title,
      .page-layout-0column .block.grid.block-products-list .block-content, .page-layout-0column .block.related.block-products-list .block-title,
      .page-layout-0column .block.related.block-products-list .block-content, .page-layout-0column .block.crosssell.block-products-list .block-title,
      .page-layout-0column .block.crosssell.block-products-list .block-content, .page-layout-0column .block.upsell.block-products-list .block-title,
      .page-layout-0column .block.upsell.block-products-list .block-content, .page-layout-0column .block.block-new-products-names.block-products-list .block-title,
      .page-layout-0column .block.block-new-products-names.block-products-list .block-content {
        padding-left: 0rem; } }
    @media print, screen and (min-width: 40em) {
      .page-layout-0column .block.grid.block-products-list .block-title,
      .page-layout-0column .block.grid.block-products-list .block-content, .page-layout-0column .block.related.block-products-list .block-title,
      .page-layout-0column .block.related.block-products-list .block-content, .page-layout-0column .block.crosssell.block-products-list .block-title,
      .page-layout-0column .block.crosssell.block-products-list .block-content, .page-layout-0column .block.upsell.block-products-list .block-title,
      .page-layout-0column .block.upsell.block-products-list .block-content, .page-layout-0column .block.block-new-products-names.block-products-list .block-title,
      .page-layout-0column .block.block-new-products-names.block-products-list .block-content {
        padding-right: 0rem; } }

.block-products-list,
.block-new-products {
  margin: 2rem 0; }
  @media screen and (min-width: 64em) {
    .block-products-list + .block-products-list,
    .block-new-products + .block-products-list {
      margin-top: 4rem; } }
  .block-products-list .no-rating,
  .block-new-products .no-rating {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }

.product-items .ais-Hits-item .product-price-stock .special-price .mva_label,
.product-items .ais-InfiniteHits-item .product-price-stock .special-price .mva_label {
  margin-top: 0; }

.ais-InfiniteHits--empty {
  margin-top: 2rem; }

.prices-tier {
  margin: 0;
  list-style: none; }
  .prices-tier li {
    padding: 0; }
    .prices-tier li:after {
      display: none; }

.page-title-wrapper.product {
  margin-bottom: 0;
  font-size: 0; }
  .page-title-wrapper.product:before {
    display: none; }
  .page-title-wrapper.product .page-title {
    font-size: 2rem;
    font-weight: 400;
    font-style: normal;
    font-family: "silka-regular-webfont", sans-serif;
    line-height: 1.1;
    color: #181818;
    text-transform: none;
    display: inline-block;
    vertical-align: top;
    float: none;
    text-align: left; }
    .page-title-wrapper.product .page-title span {
      display: inline-block; }
    @media print, screen and (min-width: 48em) {
      .page-title-wrapper.product .page-title {
        font-size: 2.625rem;
        margin-bottom: 2.125rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .page-title-wrapper.product .page-title {
      margin-bottom: 2.5rem; } }
    @media screen and (min-width: 75em) {
      .page-title-wrapper.product .page-title {
        font-size: 3.125rem; } }

.product-title-brand {
  line-height: 1;
  text-align: left;
  font-weight: inherit;
  font-family: inherit;
  font-size: 0.8125rem;
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  color: rgba(24, 24, 24, 0.5);
  text-transform: uppercase;
  margin-bottom: 0.75rem; }
  .product-title-brand::before, .product-title-brand::after {
    display: table;
    content: ' '; }
  .product-title-brand::after {
    clear: both; }
  .product-title-brand a,
  .product-title-brand span {
    display: inline-block;
    color: rgba(24, 24, 24, 0.5);
    text-decoration: none;
    position: relative;
    line-height: 1;
    padding-bottom: .4rem; }
    .product-title-brand a:after,
    .product-title-brand span:after {
      display: block;
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      border-bottom: 1px solid rgba(24, 24, 24, 0.25); }

.product-info-wrapper {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem;
  max-width: initial; }
  .product-info-wrapper::before, .product-info-wrapper::after {
    display: table;
    content: ' '; }
  .product-info-wrapper::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .product-info-wrapper {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .product-info-wrapper {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .product-info-wrapper {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .product-info-wrapper {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .product-info-wrapper {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .product-info-wrapper .product.media {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    min-height: 18.75rem;
    margin-bottom: 2.5rem;
    position: relative; }
    @media print, screen and (min-width: 40em) {
      .product-info-wrapper .product.media {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .product-info-wrapper .product.media:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 40em) {
      .product-info-wrapper .product.media {
        width: 41.66667%;
        min-height: 25rem; } }
    @media print, screen and (min-width: 48em) {
      .product-info-wrapper .product.media {
        width: 50%; } }
    @media screen and (min-width: 75em) {
      .product-info-wrapper .product.media {
        margin-bottom: 4rem; } }
  .product-info-wrapper .product-info-main {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .product-info-wrapper .product-info-main {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .product-info-wrapper .product-info-main:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 40em) {
      .product-info-wrapper .product-info-main {
        width: 58.33333%; } }
    @media print, screen and (min-width: 48em) {
      .product-info-wrapper .product-info-main {
        width: 50%; } }
    .product-info-wrapper .product-info-main .attribute.sku {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }
    .product-info-wrapper .product-info-main .attribute.overview p + ul {
      padding-top: .75rem;
      margin-top: .75rem;
      border-top: 1px solid rgba(24, 24, 24, 0.2); }
      @media screen and (min-width: 64em) {
        .product-info-wrapper .product-info-main .attribute.overview p + ul {
          padding-top: 1.25rem;
          margin-top: 1.25rem; } }
    .product-info-wrapper .product-info-main .attribute.overview + .product-attr-list {
      padding-top: .75rem;
      margin-top: .75rem;
      border-top: 1px solid rgba(24, 24, 24, 0.2); }
      @media screen and (min-width: 64em) {
        .product-info-wrapper .product-info-main .attribute.overview + .product-attr-list {
          padding-top: 1.25rem;
          margin-top: 1.25rem; } }
  .product-info-wrapper .product-info-sidebar {
    position: relative;
    padding: 1.5rem 0;
    padding-left: 23px;
    padding-right: 23px;
    margin-left: -23px;
    margin-right: -23px;
    margin-bottom: 0.5rem;
    background-color: white; }
    @media print, screen and (min-width: 40em) {
      .product-info-wrapper .product-info-sidebar {
        margin-left: 0;
        margin-right: 0; } }
    @media print, screen and (min-width: 40em) {
      .product-info-wrapper .product-info-sidebar {
        padding: 0 2rem;
        margin-bottom: 0.5rem; } }
    @media screen and (min-width: 64em) {
      .product-info-wrapper .product-info-sidebar {
        padding: 0 2.1rem 0.5rem;
        margin-bottom: 0.5rem; } }
    @media screen and (min-width: 75em) {
      .product-info-wrapper .product-info-sidebar {
        padding: 0 2.1rem 0.5rem;
        margin-bottom: 0.5rem; } }
    .product-info-wrapper .product-info-sidebar .fieldset {
      padding: 0;
      border: 0;
      margin: 0;
      -webkit-tap-highlight-color: transparent; }
      .product-info-wrapper .product-info-sidebar .fieldset .legend {
        width: 100%;
        margin: 0;
        padding: 0;
        background: none; }
      [data-whatinput='mouse'] .product-info-wrapper .product-info-sidebar .fieldset {
        outline: 0; }
    .product-info-wrapper .product-info-sidebar .mailto.friend {
      display: none; }
  .product-info-wrapper .swatch-attribute-selected-option,
  .product-info-wrapper input.super-attribute-select {
    display: none; }
  .product-info-wrapper .product-social-links .action.tocompare, .product-info-wrapper .product-social-links .action.mailto {
    display: none; }

.product-info-wrapper .price-revert-wrapper,
.page-title-wrapper.product .price-revert-wrapper {
  display: none;
  margin-bottom: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    .product-info-wrapper .price-revert-wrapper,
    .page-title-wrapper.product .price-revert-wrapper {
      margin-bottom: 1.25rem; } }
  @media screen and (min-width: 64em) {
    .product-info-wrapper .price-revert-wrapper,
    .page-title-wrapper.product .price-revert-wrapper {
      display: inline-block; } }
  .product-info-wrapper .price-revert-wrapper .price-box,
  .page-title-wrapper.product .price-revert-wrapper .price-box {
    display: flex;
    align-items: center;
    flex-direction: row-reverse; }

.product-info-wrapper .price-box > .price-container,
.page-title-wrapper.product .price-box > .price-container {
  font-weight: 400; }
  @media print, screen and (min-width: 48em) {
    .product-info-wrapper .price-box > .price-container,
    .page-title-wrapper.product .price-box > .price-container {
      font-weight: 400; } }
  @media screen and (min-width: 64em) {
    .product-info-wrapper .price-box > .price-container,
    .page-title-wrapper.product .price-box > .price-container {
      margin-top: 0; } }
  @media screen and (min-width: 75em) {
    .product-info-wrapper .price-box > .price-container,
    .page-title-wrapper.product .price-box > .price-container {
      margin-top: 0; } }

.product-info-wrapper .price-box > .price-container,
.product-info-wrapper .price-box .old-price,
.product-info-wrapper .price-box .special-price,
.page-title-wrapper.product .price-box > .price-container,
.page-title-wrapper.product .price-box .old-price,
.page-title-wrapper.product .price-box .special-price {
  line-height: 1.6875rem;
  position: relative; }
  @media screen and (min-width: 64em) {
    .product-info-wrapper .price-box > .price-container,
    .product-info-wrapper .price-box .old-price,
    .product-info-wrapper .price-box .special-price,
    .page-title-wrapper.product .price-box > .price-container,
    .page-title-wrapper.product .price-box .old-price,
    .page-title-wrapper.product .price-box .special-price {
      line-height: 1.6875rem; } }

.product-info-wrapper .price-box .old-price,
.page-title-wrapper.product .price-box .old-price {
  font-size: 0.70312rem;
  font-weight: 400;
  font-family: "silka-regular-webfont", sans-serif;
  letter-spacing: normal;
  color: rgba(24, 24, 24, 0.5);
  line-height: 1.6875rem;
  margin-right: .75rem;
  margin-left: 0;
  margin-top: 0.2625rem;
  text-decoration: line-through; }
  @media screen and (min-width: 64em) {
    .product-info-wrapper .price-box .old-price,
    .page-title-wrapper.product .price-box .old-price {
      line-height: 1.6875rem; } }
  @media screen and (min-width: 75em) {
    .product-info-wrapper .price-box .old-price,
    .page-title-wrapper.product .price-box .old-price {
      font-size: 0.9375rem;
      margin-top: 0.35rem; } }

.product-info-wrapper .price-box > .price-container,
.product-info-wrapper .price-box .special-price,
.page-title-wrapper.product .price-box > .price-container,
.page-title-wrapper.product .price-box .special-price {
  font-size: 1.3125rem; }
  @media screen and (min-width: 75em) {
    .product-info-wrapper .price-box > .price-container,
    .product-info-wrapper .price-box .special-price,
    .page-title-wrapper.product .price-box > .price-container,
    .page-title-wrapper.product .price-box .special-price {
      font-size: 1.75rem; } }
  .product-info-wrapper .price-box > .price-container .mva_label,
  .product-info-wrapper .price-box .special-price .mva_label,
  .page-title-wrapper.product .price-box > .price-container .mva_label,
  .page-title-wrapper.product .price-box .special-price .mva_label {
    color: rgba(24, 24, 24, 0.5);
    font-size: 0.70312rem;
    font-weight: 400;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: -.5rem;
    letter-spacing: normal; }
    @media screen and (min-width: 64em) {
      .product-info-wrapper .price-box > .price-container .mva_label,
      .product-info-wrapper .price-box .special-price .mva_label,
      .page-title-wrapper.product .price-box > .price-container .mva_label,
      .page-title-wrapper.product .price-box .special-price .mva_label {
        right: 0;
        left: auto;
        margin-top: 0; } }

.product-info-wrapper .price-revert-wrapper {
  display: block; }

.price-box {
  white-space: nowrap; }
  .price-box .minimal-price-link {
    display: none; }
  @media print, screen and (min-width: 48em) {
    .price-box {
      display: inline-block;
      vertical-align: top;
      clear: both;
      float: none !important; } }
  .price-box > .price-container,
  .price-box .old-price,
  .price-box .special-price {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.6875rem; }
  .price-box .old-price {
    font-size: 0.60938rem;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    color: rgba(24, 24, 24, 0.7);
    margin-left: .25rem;
    white-space: nowrap;
    position: relative; }
    .price-box .old-price .price-label:after {
      content: ':'; }
  .price-box > .price-container,
  .price-box .special-price {
    font-size: 1.1025rem;
    font-weight: 400;
    font-family: "silka-semibold-webfont", sans-serif;
    letter-spacing: normal;
    color: #181818;
    text-transform: none; }
    .price-box > .price-container .price-label,
    .price-box .special-price .price-label {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }

.b-product-outofstock {
  white-space: normal; }
  .b-product-outofstock .title {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: .75rem; }
    @media screen and (min-width: 64em) {
      .b-product-outofstock .title {
        font-size: 1.5rem; } }
  .b-product-outofstock .note-label {
    line-height: 1.2;
    margin-bottom: 1.25rem; }
    .b-product-outofstock .note-label:after {
      content: ':'; }
    @media screen and (min-width: 64em) {
      .b-product-outofstock .note-label {
        margin-bottom: 1.5rem; } }
  .b-product-outofstock .back-in-stock-header {
    padding-left: 1.5rem;
    background-image: url("../images/icon-invalid.svg");
    background-position: 0 5px;
    background-repeat: no-repeat;
    background-size: 15px auto; }
    @media screen and (min-width: 64em) {
      .b-product-outofstock .back-in-stock-header {
        background-position: 0 7px; } }
  @media screen and (min-width: 64em) {
    .b-product-outofstock .back-in-stock-form {
      padding-left: 1.5rem;
      padding-right: 2.5rem;
      max-width: 90.625rem;
      margin-right: auto;
      margin-left: auto;
      margin-right: -1.0625rem;
      margin-left: -1.0625rem; }
      .b-product-outofstock .back-in-stock-form::before, .b-product-outofstock .back-in-stock-form::after {
        display: table;
        content: ' '; }
      .b-product-outofstock .back-in-stock-form::after {
        clear: both; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .b-product-outofstock .back-in-stock-form {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) and (min-width: 48em) {
    .b-product-outofstock .back-in-stock-form {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) and (min-width: 64em) {
    .b-product-outofstock .back-in-stock-form {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) and (min-width: 75em) {
    .b-product-outofstock .back-in-stock-form {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) and (min-width: 90.625em) {
    .b-product-outofstock .back-in-stock-form {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
      .b-product-outofstock .back-in-stock-form .b-input {
        position: relative;
        width: 100%;
        float: left;
        padding-right: 1.0625rem;
        padding-left: 1.0625rem;
        width: 58.33333%;
        padding-right: 0rem; } }
    @media screen and (min-width: 64em) and (min-width: 40em) {
      .b-product-outofstock .back-in-stock-form .b-input {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
  @media screen and (min-width: 64em) {
        .b-product-outofstock .back-in-stock-form .b-input:last-child:not(:first-child) {
          float: right; } }
    @media screen and (min-width: 64em) and (min-width: 40em) {
      .b-product-outofstock .back-in-stock-form .b-input {
        padding-right: 0rem; } }
  @media screen and (min-width: 64em) {
        .b-product-outofstock .back-in-stock-form .b-input div.mage-error {
          position: absolute;
          top: 100%;
          margin-left: 0;
          margin-right: 0;
          left: 1.0625rem;
          right: 0; } }
      @media screen and (min-width: 64em) and (min-width: 40em) {
        .b-product-outofstock .back-in-stock-form .b-input div.mage-error {
          left: 1.25rem; } }
  @media screen and (min-width: 64em) {
      .b-product-outofstock .back-in-stock-form .b-action {
        width: 100%;
        float: left;
        padding-right: 1.0625rem;
        padding-left: 1.0625rem;
        width: 41.66667%;
        padding-left: 0.53125rem; } }
    @media screen and (min-width: 64em) and (min-width: 40em) {
      .b-product-outofstock .back-in-stock-form .b-action {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
  @media screen and (min-width: 64em) {
        .b-product-outofstock .back-in-stock-form .b-action:last-child:not(:first-child) {
          float: right; } }
    @media screen and (min-width: 64em) and (min-width: 40em) {
      .b-product-outofstock .back-in-stock-form .b-action {
        padding-left: 0.625rem; } }
  @media screen and (min-width: 75em) {
    .b-product-outofstock .back-in-stock-form {
      padding-right: 5.25rem; } }
  .b-product-outofstock .b-add-in-favorite .button {
    margin-bottom: 0;
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    font-size: 0.9375rem; }
  @media screen and (min-width: 64em) {
    .b-product-outofstock .b-add-in-favorite {
      padding-left: 1.5rem;
      padding-right: 2.5rem;
      max-width: 90.625rem;
      margin-right: auto;
      margin-left: auto;
      margin-right: -1.0625rem;
      margin-left: -1.0625rem; }
      .b-product-outofstock .b-add-in-favorite::before, .b-product-outofstock .b-add-in-favorite::after {
        display: table;
        content: ' '; }
      .b-product-outofstock .b-add-in-favorite::after {
        clear: both; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .b-product-outofstock .b-add-in-favorite {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) and (min-width: 48em) {
    .b-product-outofstock .b-add-in-favorite {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) and (min-width: 64em) {
    .b-product-outofstock .b-add-in-favorite {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) and (min-width: 75em) {
    .b-product-outofstock .b-add-in-favorite {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) and (min-width: 90.625em) {
    .b-product-outofstock .b-add-in-favorite {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
      .b-product-outofstock .b-add-in-favorite .over-button {
        width: 100%;
        float: left;
        padding-right: 1.0625rem;
        padding-left: 1.0625rem; } }
    @media screen and (min-width: 64em) and (min-width: 40em) {
      .b-product-outofstock .b-add-in-favorite .over-button {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
  @media screen and (min-width: 64em) {
        .b-product-outofstock .b-add-in-favorite .over-button:last-child:not(:first-child) {
          float: right; }
      .b-product-outofstock .b-add-in-favorite .button {
        padding-left: 2rem;
        padding-right: 2rem; } }
  @media screen and (min-width: 75em) {
    .b-product-outofstock .b-add-in-favorite {
      padding-right: 5.25rem; }
      .b-product-outofstock .b-add-in-favorite .button {
        font-size: 1rem;
        width: auto;
        display: inline-block; } }
  .b-product-outofstock .input-text {
    margin-bottom: .5rem; }
    .b-product-outofstock .input-text + div.mage-error {
      margin-top: -.5rem;
      margin-bottom: .25rem; }
    @media screen and (min-width: 64em) {
      .b-product-outofstock .input-text {
        margin-bottom: 0; }
        .b-product-outofstock .input-text + div.mage-error {
          margin-top: 0; } }
  .b-product-outofstock .action {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    min-height: 3.75rem; }
  .b-product-outofstock + .product-social-links {
    text-align: center;
    margin-top: 1.5rem; }
    .b-product-outofstock + .product-social-links + .product-add-form {
      display: none; }
    @media screen and (max-width: 63.9375em) {
      .b-product-outofstock + .product-social-links .action.towishlist,
      .b-product-outofstock + .product-social-links .action.wishlist-remove-product {
        width: auto;
        text-decoration: none;
        background-size: 1.5625rem 1.4375rem; }
        .b-product-outofstock + .product-social-links .action.towishlist > span,
        .b-product-outofstock + .product-social-links .action.wishlist-remove-product > span {
          position: static;
          opacity: 1;
          margin: 0 0 0 2.375rem;
          padding: 0;
          color: #181818;
          font-size: 0.8125rem;
          background: none; }
          .b-product-outofstock + .product-social-links .action.towishlist > span:after,
          .b-product-outofstock + .product-social-links .action.wishlist-remove-product > span:after {
            display: none; } }
    @media screen and (min-width: 64em) {
      .b-product-outofstock + .product-social-links {
        margin-top: 0;
        position: absolute;
        right: 1.0625rem;
        bottom: 2.21875rem; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .b-product-outofstock + .product-social-links {
      right: 1.25rem; } }
    @media screen and (min-width: 75em) {
      .b-product-outofstock + .product-social-links {
        margin-right: 1.25rem; } }

.product.info.detailed .title {
  display: none;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: .75rem; }

.product.info.detailed .product-data {
  margin-bottom: 2.25rem; }
  .product.info.detailed .product-data h3, .product.info.detailed .product-data h4, .product.info.detailed .product-data h5, .product.info.detailed .product-data h6 {
    font-size: 1rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: .75rem; }
  .product.info.detailed .product-data p {
    margin-bottom: .5rem; }
  .product.info.detailed .product-data .product_info_overview {
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-size: 1.125rem;
    line-height: 1.6;
    padding-bottom: 1.25rem;
    margin-bottom: 1.75rem;
    background-image: linear-gradient(to right, rgba(24, 24, 24, 0.25) 75%, rgba(24, 24, 24, 0) 0%);
    background-position: bottom;
    background-size: 11px 1px;
    background-repeat: repeat-x; }
    .product.info.detailed .product-data .product_info_overview p {
      font-family: "silka-regular-webfont", sans-serif;
      letter-spacing: normal;
      font-size: 1.125rem;
      line-height: 1.6; }
  .product.info.detailed .product-data .product_info_description .content:not(:last-child) {
    padding-bottom: 1.5rem;
    margin-bottom: 1.75rem;
    background-image: linear-gradient(to right, rgba(24, 24, 24, 0.25) 75%, rgba(24, 24, 24, 0) 0%);
    background-position: bottom;
    background-size: 11px 1px;
    background-repeat: repeat-x; }

.product-info-wrapper {
  margin-bottom: 3.75rem; }
  .product-info-wrapper .product.media {
    margin-bottom: 1.5rem; }
    .product-info-wrapper .product.media ._block-content-loading,
    .product-info-wrapper .product.media [data-bind="blockLoader: isLoading"] {
      position: static; }
  .product-info-wrapper .product-social-links {
    line-height: normal;
    text-align: right;
    padding-right: 0rem; }
    @media print, screen and (min-width: 40em) {
      .product-info-wrapper .product-social-links {
        padding-right: 0rem; } }
    @media screen and (max-width: 63.9375em) {
      .product-info-wrapper .product-social-links {
        position: relative;
        padding-top: 1rem;
        text-align: center;
        right: 0;
        clear: both; }
        .product-info-wrapper .product-social-links .action.towishlist,
        .product-info-wrapper .product-social-links .action.wishlist-remove-product {
          width: auto;
          text-decoration: none;
          background-size: 1.5625rem 1.4375rem; }
          .product-info-wrapper .product-social-links .action.towishlist > span,
          .product-info-wrapper .product-social-links .action.wishlist-remove-product > span {
            position: static;
            opacity: 1;
            margin: 0 0 0 2.375rem;
            padding: 0;
            color: #181818;
            font-size: 0.8125rem;
            background: none; }
            .product-info-wrapper .product-social-links .action.towishlist > span:after,
            .product-info-wrapper .product-social-links .action.wishlist-remove-product > span:after {
              display: none; } }
  .product-info-wrapper .product-info-form-wrapper .product-info-form-row {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    margin-right: -1.0625rem;
    margin-left: -1.0625rem;
    position: relative; }
    .product-info-wrapper .product-info-form-wrapper .product-info-form-row::before, .product-info-wrapper .product-info-form-wrapper .product-info-form-row::after {
      display: table;
      content: ' '; }
    .product-info-wrapper .product-info-form-wrapper .product-info-form-row::after {
      clear: both; }
    @media print, screen and (min-width: 40em) {
      .product-info-wrapper .product-info-form-wrapper .product-info-form-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .product-info-wrapper .product-info-form-wrapper .product-info-form-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .product-info-wrapper .product-info-form-wrapper .product-info-form-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .product-info-wrapper .product-info-form-wrapper .product-info-form-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .product-info-wrapper .product-info-form-wrapper .product-info-form-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    .product-info-wrapper .product-info-form-wrapper .product-info-form-row .swatch-attribute-label {
      width: 100%;
      display: block;
      font-size: 1rem;
      font-weight: 300;
      font-family: "silka-regular-webfont", sans-serif;
      font-style: normal;
      text-transform: none;
      margin: .25rem 0 1rem; }
  .product-info-wrapper .product-info-form-wrapper .product-info-stock-sku {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    position: relative;
    margin-bottom: .25rem; }
    @media print, screen and (min-width: 40em) {
      .product-info-wrapper .product-info-form-wrapper .product-info-stock-sku {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .product-info-wrapper .product-info-form-wrapper .product-info-stock-sku:last-child:not(:first-child) {
      float: right; }
    @media screen and (min-width: 64em) {
      .product-info-wrapper .product-info-form-wrapper .product-info-stock-sku .fieldset:before {
        display: none; } }
  .product-info-wrapper .product-info-form-wrapper .price-revert-wrapper {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    width: 50%;
    text-align: right;
    margin: .5rem 0;
    position: absolute;
    bottom: .25rem;
    right: 0; }
    @media print, screen and (min-width: 40em) {
      .product-info-wrapper .product-info-form-wrapper .price-revert-wrapper {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .product-info-wrapper .product-info-form-wrapper .price-revert-wrapper:last-child:not(:first-child) {
      float: right; }
    .product-info-wrapper .product-info-form-wrapper .price-revert-wrapper:last-child:last-child {
      float: left; }
    .product-info-wrapper .product-info-form-wrapper .price-revert-wrapper .price-box {
      display: flex; }
    @media screen and (min-width: 64em) {
      .product-info-wrapper .product-info-form-wrapper .price-revert-wrapper {
        position: relative;
        bottom: auto;
        right: auto;
        margin-top: 0; }
        .product-info-wrapper .product-info-form-wrapper .price-revert-wrapper > .price-revert-wrapper,
        .product-info-wrapper .product-info-form-wrapper .price-revert-wrapper > .price-over-box {
          margin: 0 0 .25rem;
          display: inline-block;
          vertical-align: bottom;
          max-width: 96%; }
        .product-info-wrapper .product-info-form-wrapper .price-revert-wrapper:before {
          content: '';
          display: inline-block;
          vertical-align: bottom;
          height: 100%;
          margin-right: -.3em; } }
  .product-info-wrapper .product-info-form-wrapper .stock {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    width: 50%;
    padding-left: 0rem;
    line-height: 1.75rem;
    margin: .5rem 0;
    font-size: 0.75rem; }
    @media print, screen and (min-width: 40em) {
      .product-info-wrapper .product-info-form-wrapper .stock {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .product-info-wrapper .product-info-form-wrapper .stock:last-child:not(:first-child) {
      float: right; }
    .product-info-wrapper .product-info-form-wrapper .stock:last-child:last-child {
      float: left; }
    @media print, screen and (min-width: 40em) {
      .product-info-wrapper .product-info-form-wrapper .stock {
        padding-left: 0rem; } }
    @media screen and (min-width: 64em) and (max-width: 74.9375em) {
      .product-info-wrapper .product-info-form-wrapper .stock {
        margin: 0; } }
  .is-configurable-product .product-info-wrapper .product-info-form-wrapper {
    margin-top: .5rem; }
    @media screen and (min-width: 64em) {
      .is-configurable-product .product-info-wrapper .product-info-form-wrapper {
        margin-top: 0; } }
    .is-configurable-product .product-info-wrapper .product-info-form-wrapper .product-options-wrapper > .fieldset::before, .is-configurable-product .product-info-wrapper .product-info-form-wrapper .product-options-wrapper > .fieldset::after {
      display: table;
      content: ' '; }
    .is-configurable-product .product-info-wrapper .product-info-form-wrapper .product-options-wrapper > .fieldset::after {
      clear: both; }
    @media screen and (min-width: 64em) {
      .is-configurable-product .product-info-wrapper .product-info-form-wrapper .product-options-wrapper {
        width: 100%;
        float: left;
        padding-right: 1.0625rem;
        padding-left: 1.0625rem; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .is-configurable-product .product-info-wrapper .product-info-form-wrapper .product-options-wrapper {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
    @media screen and (min-width: 64em) {
        .is-configurable-product .product-info-wrapper .product-info-form-wrapper .product-options-wrapper:last-child:not(:first-child) {
          float: right; }
        .is-configurable-product .product-info-wrapper .product-info-form-wrapper .product-options-wrapper .product-social-links .stock {
          display: inline-block; } }
    .is-configurable-product .product-info-wrapper .product-info-form-wrapper .swatch-opt {
      margin-right: 1.0625rem;
      margin-left: 1.0625rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid rgba(24, 24, 24, 0.15); }
      @media print, screen and (min-width: 40em) {
        .is-configurable-product .product-info-wrapper .product-info-form-wrapper .swatch-opt {
          margin-right: 1.25rem; } }
      @media print, screen and (min-width: 40em) {
        .is-configurable-product .product-info-wrapper .product-info-form-wrapper .swatch-opt {
          margin-left: 1.25rem; } }
      .is-configurable-product .product-info-wrapper .product-info-form-wrapper .swatch-opt .swatch-attribute {
        position: relative; }
        @media screen and (min-width: 64em) {
          .is-configurable-product .product-info-wrapper .product-info-form-wrapper .swatch-opt .swatch-attribute .mage-error {
            position: absolute;
            bottom: 0;
            left: 0;
            margin-bottom: 0;
            line-height: 1; } }
      .is-configurable-product .product-info-wrapper .product-info-form-wrapper .swatch-opt .swatch-attribute-label {
        margin: 0 0 .4rem; }
        @media screen and (min-width: 64em) {
          .is-configurable-product .product-info-wrapper .product-info-form-wrapper .swatch-opt .swatch-attribute-label {
            margin: 0 0 1.1rem; } }
      .is-configurable-product .product-info-wrapper .product-info-form-wrapper .swatch-opt .swatch-attribute-options > .swatch-option:not(.disabled):before,
      .is-configurable-product .product-info-wrapper .product-info-form-wrapper .swatch-opt .swatch-attribute-options > .swatch-option:not(.disabled):after {
        display: none; }
      @media screen and (min-width: 64em) {
        .is-configurable-product .product-info-wrapper .product-info-form-wrapper .swatch-opt {
          display: inline-block;
          margin-left: 0rem;
          margin-right: 0rem;
          border-bottom: 0;
          padding-bottom: 0;
          max-width: 50%; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .is-configurable-product .product-info-wrapper .product-info-form-wrapper .swatch-opt {
      margin-left: 0rem; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .is-configurable-product .product-info-wrapper .product-info-form-wrapper .swatch-opt {
      margin-right: 0rem; } }
    .is-configurable-product .product-info-wrapper .product-info-form-wrapper .product-info-stock-sku {
      display: none; }
      .is-configurable-product .product-info-wrapper .product-info-form-wrapper .product-info-stock-sku.mobile {
        display: block; }
      @media screen and (min-width: 64em) {
        .is-configurable-product .product-info-wrapper .product-info-form-wrapper .product-info-stock-sku {
          display: block;
          position: absolute;
          left: 16rem;
          top: .35rem;
          line-height: 1;
          float: none;
          width: auto;
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 0; }
          .is-configurable-product .product-info-wrapper .product-info-form-wrapper .product-info-stock-sku .stock {
            line-height: 1;
            width: auto;
            margin-top: 0;
            margin-bottom: 0;
            padding-right: 0; }
          .is-configurable-product .product-info-wrapper .product-info-form-wrapper .product-info-stock-sku.mobile {
            display: none; } }
    .is-configurable-product .product-info-wrapper .product-info-form-wrapper .open-size-guide-modal {
      position: absolute;
      top: 0;
      font-size: 0.75rem;
      right: 1.0625rem;
      cursor: pointer;
      z-index: 0; }
      @media print, screen and (min-width: 40em) {
        .is-configurable-product .product-info-wrapper .product-info-form-wrapper .open-size-guide-modal {
          right: 1.25rem; } }
      @media screen and (min-width: 64em) {
        .is-configurable-product .product-info-wrapper .product-info-form-wrapper .open-size-guide-modal {
          right: auto;
          left: 9.3rem;
          top: .25rem;
          line-height: 1;
          margin-right: 0; } }
    @media screen and (min-width: 64em) {
      .is-configurable-product .product-info-wrapper .product-info-form-wrapper .price-revert-wrapper {
        position: absolute;
        top: 2.5rem;
        right: 0;
        bottom: auto; } }
    @media screen and (min-width: 75em) {
      .is-configurable-product .product-info-wrapper .product-info-form-wrapper .price-revert-wrapper {
        top: -1rem; } }
    @media screen and (min-width: 64em) {
      .is-configurable-product .product-info-wrapper .product-info-form-wrapper + .product-info-form-bottom {
        margin-top: .5rem; } }
    @media screen and (min-width: 75em) {
      .is-configurable-product .product-info-wrapper .product-info-form-wrapper + .product-info-form-bottom {
        margin-top: 0;
        position: relative; }
        .is-configurable-product .product-info-wrapper .product-info-form-wrapper + .product-info-form-bottom .box-tocart {
          position: absolute;
          bottom: -.5rem;
          right: 0; } }
  .is-simple-product:not(.is-configurable-product) .product-info-wrapper .product-info-form-wrapper .product-info-stock-sku {
    width: 50%; }
    .is-simple-product:not(.is-configurable-product) .product-info-wrapper .product-info-form-wrapper .product-info-stock-sku .stock {
      width: auto; }
    @media screen and (min-width: 75em) {
      .is-simple-product:not(.is-configurable-product) .product-info-wrapper .product-info-form-wrapper .product-info-stock-sku {
        width: 20.83333%;
        margin-bottom: 0; } }
  .is-simple-product:not(.is-configurable-product) .product-info-wrapper .product-info-form-wrapper .price-revert-wrapper {
    bottom: auto;
    top: 0; }
    @media screen and (min-width: 75em) {
      .is-simple-product:not(.is-configurable-product) .product-info-wrapper .product-info-form-wrapper .price-revert-wrapper {
        width: 33.33333%;
        margin-bottom: 0; } }
    @media screen and (min-width: 90.625em) {
      .is-simple-product:not(.is-configurable-product) .product-info-wrapper .product-info-form-wrapper .price-revert-wrapper {
        width: 35.41667%; } }
  .product-info-wrapper .product-info-form-bottom {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    margin-right: -1.0625rem;
    margin-left: -1.0625rem;
    margin-top: 0.3125rem; }
    .product-info-wrapper .product-info-form-bottom::before, .product-info-wrapper .product-info-form-bottom::after {
      display: table;
      content: ' '; }
    .product-info-wrapper .product-info-form-bottom::after {
      clear: both; }
    @media print, screen and (min-width: 40em) {
      .product-info-wrapper .product-info-form-bottom {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .product-info-wrapper .product-info-form-bottom {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .product-info-wrapper .product-info-form-bottom {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .product-info-wrapper .product-info-form-bottom {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .product-info-wrapper .product-info-form-bottom {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    .product-info-wrapper .product-info-form-bottom .product-info-stock-sku {
      position: absolute;
      top: 0;
      right: 1.0625rem; }
      @media print, screen and (min-width: 40em) {
        .product-info-wrapper .product-info-form-bottom .product-info-stock-sku {
          right: 1.25rem; } }
      .product-info-wrapper .product-info-form-bottom .product-info-stock-sku .stock {
        display: none; }
      @media print, screen and (min-width: 40em) {
        .product-info-wrapper .product-info-form-bottom .product-info-stock-sku {
          right: 0rem; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    .product-info-wrapper .product-info-form-bottom .product-info-stock-sku {
      right: 0rem; } }
      @media screen and (min-width: 64em) {
        .product-info-wrapper .product-info-form-bottom .product-info-stock-sku {
          position: static;
          display: block;
          width: 100%;
          float: left;
          padding-right: 1.0625rem;
          padding-left: 1.0625rem;
          width: 50%;
          line-height: 3.75rem; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .product-info-wrapper .product-info-form-bottom .product-info-stock-sku {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
      @media screen and (min-width: 64em) {
          .product-info-wrapper .product-info-form-bottom .product-info-stock-sku:last-child:not(:first-child) {
            float: right; }
          .product-info-wrapper .product-info-form-bottom .product-info-stock-sku:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            height: 100%;
            margin-right: -.3em; }
          .product-info-wrapper .product-info-form-bottom .product-info-stock-sku .stock {
            display: inline-block;
            margin-left: 0; } }
  .product-info-wrapper .box-tocart {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    position: relative; }
    @media print, screen and (min-width: 40em) {
      .product-info-wrapper .box-tocart {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .product-info-wrapper .box-tocart:last-child:not(:first-child) {
      float: right; }
    @media screen and (min-width: 64em) {
      .product-info-wrapper .box-tocart {
        width: 87.5%;
        float: right;
        text-align: right; } }
    @media screen and (min-width: 75em) {
      .product-info-wrapper .box-tocart {
        width: 41.66667%; } }
    @media screen and (min-width: 90.625em) {
      .product-info-wrapper .box-tocart {
        width: 39.58333%; } }
    .product-info-wrapper .box-tocart .actions {
      width: auto; }
      .product-info-wrapper .box-tocart .actions button.action {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        font-size: 1.1875rem;
        font-weight: 400;
        font-family: "silka-regular-webfont", sans-serif;
        padding: 0;
        text-align: center;
        text-transform: none;
        padding: 1.1rem 1rem; }
    @media screen and (min-width: 64em) {
      .product-info-wrapper .box-tocart .product-social-links {
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translateY(-50%); } }

.fotorama--fullscreen {
  background: white;
  bottom: 0 !important;
  float: none !important;
  left: 0 !important;
  margin: 0 !important;
  position: fixed !important;
  right: 0 !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 1000 !important; }
  .fotorama--fullscreen .fotorama__wrap {
    max-width: 100% !important; }
  .fotorama--fullscreen .convert__fotorama__view-larger {
    display: none; }
  .fotorama--fullscreen .fotorama__wrap .fotorama__stage {
    margin-bottom: 0;
    overflow: hidden;
    background: transparent; }
    @media screen and (min-width: 64em) {
      .fotorama--fullscreen .fotorama__wrap .fotorama__stage {
        margin-left: auto; } }
  .fotorama--fullscreen .convert__fotorama__header {
    display: block;
    height: 5rem;
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    padding-top: 1.7rem;
    color: #181818;
    font-size: 0.8125rem;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    max-width: 1450px;
    transform: translateX(-50%);
    padding-left: 1.0625rem;
    padding-right: 1.0625rem;
    z-index: 1; }
    @media print, screen and (min-width: 40em) {
      .fotorama--fullscreen .convert__fotorama__header {
        padding-left: 1.25rem; } }
    @media print, screen and (min-width: 40em) {
      .fotorama--fullscreen .convert__fotorama__header {
        padding-right: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .fotorama--fullscreen .convert__fotorama__header {
        font-size: 1.125rem; } }
    @media screen and (min-width: 75em) {
      .fotorama--fullscreen .convert__fotorama__header {
        font-size: 1.375rem; } }
    .fotorama--fullscreen .convert__fotorama__header + .fotorama__stage {
      padding-top: 5rem;
      padding-bottom: 11.25rem;
      height: 100% !important;
      z-index: 2; }
    .fotorama--fullscreen .convert__fotorama__header .convert__fotorama__counter {
      width: 9%;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      line-height: 1.2;
      padding-left: 1.0625rem;
      padding-right: 1.0625rem; }
      @media print, screen and (min-width: 40em) {
        .fotorama--fullscreen .convert__fotorama__header .convert__fotorama__counter {
          padding-left: 1.25rem; } }
      @media print, screen and (min-width: 40em) {
        .fotorama--fullscreen .convert__fotorama__header .convert__fotorama__counter {
          padding-right: 1.25rem; } }
    .fotorama--fullscreen .convert__fotorama__header .convert__fotorama__caption {
      display: inline-block;
      vertical-align: top;
      width: 45%;
      line-height: 1.2;
      font-weight: 300; }
    .fotorama--fullscreen .convert__fotorama__header:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 45%;
      height: 1px; }
  .fotorama--fullscreen .fotorama__stage,
  .fotorama--fullscreen .fotorama__stage__shaft {
    max-width: 1450px;
    margin-left: auto;
    margin-right: auto; }
  .fotorama--fullscreen .fotorama__fullscreen-icon {
    height: 80px;
    width: auto;
    display: inline-block;
    right: 0;
    top: 0;
    position: absolute;
    font-size: 0.8125rem;
    font-weight: inherit;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    color: rgba(24, 24, 24, 0.7);
    line-height: 80px;
    white-space: nowrap;
    text-align: right;
    padding-right: 80px;
    background: url("../images/icon-close.svg") no-repeat;
    background-position: calc(100% - 25px) 50% !important;
    background-size: 30px auto;
    z-index: 1; }
    @media screen and (min-width: 64em) {
      .fotorama--fullscreen .fotorama__fullscreen-icon {
        font-size: 1rem; } }
    @media screen and (min-width: 75em) {
      .fotorama--fullscreen .fotorama__fullscreen-icon {
        font-size: 1.125rem; } }
    .fotorama--fullscreen .fotorama__fullscreen-icon:after {
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      position: absolute;
      z-index: 1000; }
  .fotorama--fullscreen .fotorama__nav-wrap--horizontal {
    position: absolute;
    bottom: 1rem;
    left: 5rem;
    right: 5rem; }
    @media screen and (min-width: 90.625em) {
      .fotorama--fullscreen .fotorama__nav-wrap--horizontal {
        left: 12rem;
        right: 12rem; } }

.fotorama__fullscreen-icon {
  display: none; }

.convert__fotorama__header {
  display: none; }

.convert__fotorama__view-larger {
  display: none;
  position: absolute;
  color: #181818;
  cursor: pointer;
  left: 2rem;
  top: 2rem; }
  @media print, screen and (min-width: 48em) {
    .convert__fotorama__view-larger {
      display: block; } }
  .convert__fotorama__view-larger span {
    border-bottom: 1px solid rgba(24, 24, 24, 0.2);
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }
  .convert__fotorama__view-larger:before {
    display: inline-block;
    vertical-align: middle;
    margin-right: .35rem;
    content: '';
    width: 25px;
    height: 25px;
    background: url("../images/icon-lens.svg") no-repeat;
    background-size: 100% auto; }
  .convert__fotorama__view-larger:hover span {
    border-bottom: 0; }

.fotorama__fullscreen {
  overflow: hidden;
  position: relative; }
  .fotorama__fullscreen .fotorama__zoom-in,
  .fotorama__fullscreen .fotorama__zoom-out {
    cursor: pointer;
    display: block;
    height: 80px;
    overflow: hidden;
    position: absolute;
    width: 80px; }
  .fotorama__fullscreen .fotorama__zoom-out {
    background-position: 0 -80px !important;
    bottom: 0; }
    .fotorama__fullscreen .fotorama__zoom-out.fotorama__zoom-out--disabled {
      display: none; }
  .fotorama__fullscreen .fotorama__zoom-in {
    background-position: 0 0 !important;
    bottom: 80px; }
    .fotorama__fullscreen .fotorama__zoom-in.fotorama__zoom-in--disabled {
      display: none; }
  .fotorama__fullscreen .fotorama__video-close {
    display: none; }

.fotorama--fullscreen .fotorama__loaded--full .fotorama__img {
  display: none; }

.fotorama--fullscreen .fotorama__loaded--full .fotorama__img--full {
  display: block; }

.fotorama-button-background, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon,
.fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-out,
.fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in {
  background-color: rgba(255, 255, 255, 0.3);
  transition: background-color 0.3s ease-in-out; }
  .fotorama-button-background:hover, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon:hover,
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-out:hover,
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in:hover {
    background-color: rgba(255, 255, 255, 0.5); }
  .fotorama-button-background:active, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon:active,
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-out:active,
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in:active {
    background-color: rgba(0, 0, 0, 0.5); }

.fotorama__zoom-out:focus,
.fotorama__zoom-in:focus,
.fotorama__arr:focus,
.fotorama__stage__shaft:focus,
.fotorama__nav__frame--thumb:focus .fotorama__thumb,
.fotorama__nav__frame--dot:focus .fotorama__dot {
  box-shadow: none; }
  .fotorama__zoom-out:focus:after,
  .fotorama__zoom-in:focus:after,
  .fotorama__arr:focus:after,
  .fotorama__stage__shaft:focus:after,
  .fotorama__nav__frame--thumb:focus .fotorama__thumb:after,
  .fotorama__nav__frame--dot:focus .fotorama__dot:after {
    border-radius: inherit;
    bottom: 3px;
    box-shadow: 3px;
    content: '';
    left: 3px;
    position: absolute;
    right: 3px;
    top: 3px;
    z-index: 1000; }

.fotorama__nav__frame--thumb:focus .fotorama__thumb:after,
.fotorama__nav__frame--thumb:focus .fotorama__dot:after,
.fotorama__nav__frame--dot:focus .fotorama__thumb:after,
.fotorama__nav__frame--dot:focus .fotorama__dot:after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0; }

.fotorama__nav__frame--thumb:focus .fotorama__thumb.fotorama_vertical_ratio:after,
.fotorama__nav__frame--dot:focus .fotorama__thumb.fotorama_vertical_ratio:after {
  left: 2px;
  right: 2px; }

.fotorama__nav__frame--thumb:focus .fotorama__thumb,
.fotorama__nav__frame--dot:focus .fotorama__thumb {
  overflow: inherit; }

.fotorama__nav__frame:nth-child(2):focus .fotorama__thumb:after {
  left: 1px; }

.fotorama__nav__frame:nth-child(2):focus .fotorama__thumb.fotorama_vertical_ratio:after {
  top: 1px; }

.fotorama__nav__frame:last-child:focus .fotorama__thumb:after {
  right: 1px; }

.fotorama__nav__frame:last-child:focus .fotorama__thumb.fotorama_vertical_ratio:after {
  bottom: 1px; }

.fotorama__thumb__arr {
  box-shadow: none; }

.fotorama-stretch, .fotorama-focus-overlay:after, .fotorama__nav__frame:focus .fotorama__thumb:after, .fotorama__stage__shaft, .fotorama__stage__frame, .fotorama__html, .fotorama__video iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }

.fotorama-grab-cursor, .fotorama__wrap .fotorama__grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.fotorama-grabbing-cursor, .fotorama__grabbing * {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.fotorama-gpu, .fotorama--fullscreen, .fotorama__caption, .fotorama__wrap--css3 .fotorama__stage,
.fotorama__wrap--css3 .fotorama__nav,
.fotorama__wrap--css3 .fotorama__stage__frame, .fotorama__wrap--css3 .fotorama__html, .fotorama__stage:before, .fotorama__stage:after,
.fotorama__nav:before,
.fotorama__nav:after, .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
  transform: translateZ(0); }

.fotorama-focus, .fotorama__nav__frame, .fotorama__fullscreen-icon:focus,
.fotorama__zoom-out:focus,
.fotorama__zoom-in:focus {
  outline: 0; }

.action-skip-wrapper {
  display: none; }

.fotorama-focus-overlay:after, .fotorama__nav__frame:focus .fotorama__thumb:after {
  background-color: #1A2746;
  border-radius: inherit;
  content: ''; }

.fotorama-transform-disabled, .fotorama__wrap--video .fotorama__stage__shaft,
.fotorama__wrap--video .fotorama__stage,
.fotorama__wrap--video .fotorama__stage__frame--video, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img,
.fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html {
  transform: none !important; }

.fotorama-transition-for-slide, .fotorama__wrap--css3 .fotorama__stage__shaft,
.fotorama__wrap--css3 .fotorama__nav__shaft,
.fotorama__wrap--css3 .fotorama__thumb-border {
  transition-duration: 0ms;
  transition-property: transform, width;
  transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1); }

.fotorama-no-select, .fotorama__wrap, .fotorama__no-select, .fotorama__arr,
.fotorama__fullscreen-icon,
.fotorama__video-close {
  user-select: none; }

.fotorama-select, .fotorama__select {
  user-select: text; }

.fotorama-empty-bg {
  background: url(data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=); }

.fotorama-auto-margin, .fotorama__nav, .fotorama__nav__frame {
  margin: auto;
  padding: 0; }

.fotorama-inline-block, .fotorama__nav__shaft, .fotorama__nav__frame, .fotorama__caption__wrap {
  -moz-box-orient: vertical;
  display: -moz-inline-box;
  display: inline-block;
  vertical-align: middle; }

.fotorama-content-box, .fotorama__nav__frame {
  box-sizing: content-box; }

.fotorama-hidden, .fotorama--hidden, .fotorama__load {
  left: -99999px;
  position: absolute;
  top: -99999px;
  z-index: -1000; }

.fotorama-visible {
  left: auto;
  opacity: 1;
  position: relative;
  top: auto;
  z-index: auto; }

.fotorama-no-tap, .fotorama__stage__shaft, .fotorama__stage__frame, .fotorama__nav, .fotorama__nav__shaft, .fotorama__nav__frame, .fotorama__arr,
.fotorama__fullscreen-icon,
.fotorama__video-close {
  -webkit-tap-highlight-color: rgba(24, 24, 24, 0); }

.transitionDuration {
  transition-duration: 333ms; }

.transitionDurationZero {
  transition-duration: 0ms; }

.fotorama-sprite, .fotorama__fullscreen .fotorama__zoom-out, .fotorama__fullscreen .fotorama__zoom-in, .fotorama__video-play,
.fotorama__video-close {
  background-image: url("../images/gallery.png");
  background-repeat: no-repeat; }

@media print {
  .fotorama-print-background, .fotorama-sprite, .fotorama__fullscreen .fotorama__zoom-out, .fotorama__fullscreen .fotorama__zoom-in, .fotorama__video-play,
  .fotorama__video-close, .fotorama__thumb-border {
    background: none !important; } }

.fotorama__zoom-in,
.fotorama__zoom-out {
  display: none; }

.fotorama {
  min-width: 1px;
  overflow: hidden; }

.fotorama__wrap {
  height: 100%;
  -webkit-text-size-adjust: 100%;
  direction: ltr;
  position: relative;
  z-index: 0; }

.fotorama__wrap--rtl .fotorama__stage__frame {
  direction: rtl; }

.fotorama__stage,
.fotorama__nav {
  max-width: 100%;
  overflow: hidden;
  position: relative; }

.fotorama--fullscreen .product-label,
.fotorama--fullscreen .convert-label-container {
  display: none; }

.fotorama__wrap--pan-y {
  -ms-touch-action: pan-y; }

.fotorama__stage__shaft {
  position: relative; }

.fotorama__wrap .fotorama__pointer {
  cursor: pointer; }

.fotorama__wrap--slide .fotorama__stage__frame {
  opacity: 1 !important; }

.fotorama__stage__frame {
  overflow: hidden; }
  .fotorama__stage__frame.fotorama__active {
    z-index: 8; }
  .fotorama__stage__frame .product-video {
    height: 100%; }

.fotorama__wrap--fade .fotorama__stage__frame {
  display: none; }

.fotorama__wrap--fade .fotorama__stage__frame.fotorama__active,
.fotorama__wrap--fade .fotorama__fade-front,
.fotorama__wrap--fade .fotorama__fade-rear {
  display: block;
  left: 0;
  top: 0; }

.fotorama__wrap--fade .product-video {
  margin-left: 80px;
  margin-right: 80px; }

.fotorama__wrap--fade .fotorama__fade-front {
  z-index: 8; }

.fotorama__wrap--fade .fotorama__fade-rear {
  z-index: 7; }
  .fotorama__wrap--fade .fotorama__fade-rear.fotorama__active {
    z-index: 9; }

.fotorama__wrap--fade .fotorama__stage .fotorama__shadow {
  display: none; }

.fotorama__img {
  border: none !important;
  max-width: inherit;
  opacity: 0; }
  .fotorama__loaded .fotorama__img,
  .fotorama__error .fotorama__img {
    opacity: 1; }

.fotorama__img--full {
  display: none; }

.fotorama__nav {
  display: none;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  z-index: 5; }

.fotorama__nav__shaft {
  left: 0;
  position: relative;
  text-align: left;
  top: 0; }

.fotorama__nav__frame {
  position: relative; }

.fotorama__nav--dots {
  display: block;
  text-align: center; }
  .fotorama__nav--dots .fotorama__nav__frame {
    height: 30px;
    width: 18px; }
  .fotorama__nav--dots .fotorama__nav__frame--thumb,
  .fotorama__nav--dots .fotorama__thumb-border {
    display: none; }

.fotorama__nav--thumbs {
  display: block; }
  .fotorama__nav--thumbs .fotorama__nav__frame {
    padding-left: 0 !important; }
    .fotorama__nav--thumbs .fotorama__nav__frame:last-child {
      padding-right: 0 !important; }
  .fotorama__nav--thumbs .fotorama__nav__frame--dot {
    display: none; }

.fotorama__active .fotorama__dot {
  background-color: #1A2746;
  border-color: #1A2746; }

.fotorama__nav__frame.fotorama__active .fotorama__nav__frame {
  border-width: 3px;
  height: 0;
  width: 0; }
  .fotorama__nav__frame.fotorama__active .fotorama__nav__frame:after {
    left: -3px;
    padding: 3px;
    top: -3px; }

.fotorama__dot {
  border-radius: 6px;
  border: 1px solid #ccc;
  display: block;
  height: 4px;
  left: 6px;
  position: relative;
  top: 12px;
  width: 4px; }

.fotorama__thumb {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .fotorama__nav__frame:focus .fotorama__thumb {
    z-index: 2; }

.fotorama__thumb-border {
  backface-visibility: hidden;
  background: rgba(255, 255, 255, 0.5) !important;
  border: 1px solid rgba(24, 24, 24, 0.2) !important;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 9; }

.fotorama__caption {
  bottom: 0;
  color: #181818;
  font-size: 14px;
  left: 0;
  line-height: 1.5;
  position: absolute;
  right: 0;
  z-index: 12; }
  .fotorama__caption a {
    border-bottom: 1px solid;
    border-color: rgba(24, 24, 24, 0.5);
    color: #181818;
    text-decoration: none; }
    .fotorama__caption a:hover {
      border-color: rgba(24, 24, 24, 0.5);
      color: #181818; }
  .fotorama__wrap--rtl .fotorama__caption {
    left: auto;
    right: 0; }
  .fotorama__wrap--video .fotorama__caption,
  .fotorama__wrap--no-captions .fotorama__caption {
    display: none; }

.fotorama__caption__wrap {
  background-color: white;
  padding: 5px 10px; }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fotorama__wrap--css3 .fotorama__html {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: linear; }

.fotorama__wrap--css3 .fotorama__stage__shaft {
  max-width: 100% !important; }

.fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img,
.fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html {
  opacity: 0; }

.fotorama__select {
  cursor: auto; }

.fotorama__video {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 32px;
  z-index: 10; }

@-moz-document url-prefix() {
  .fotorama__active {
    box-shadow: 0 0 0 transparent; } }

.fotorama__arr,
.fotorama__fullscreen-icon,
.fotorama__video-close {
  cursor: pointer;
  position: absolute; }

.fotorama__arr {
  z-index: 900; }

.fotorama__fullscreen-icon,
.fotorama__zoom-out,
.fotorama__zoom-in,
.fotorama__video-close {
  z-index: 1000; }

.fotorama__arr {
  bottom: 80px;
  position: absolute;
  top: 80px;
  width: 80px;
  height: 80px;
  top: 50%;
  bottom: auto;
  margin-top: -40px; }
  .fotorama__arr .fotorama__arr__arr {
    height: 80px;
    width: 80px;
    background-image: url("../images/icon-big-next-arrow.svg");
    background-repeat: no-repeat;
    background-size: 25px auto;
    opacity: 0.9; }
    .ie9 .fotorama__arr .fotorama__arr__arr {
      margin: -40px 0 0 -40px; }
  .fotorama__arr:hover .fotorama__arr__arr, .fotorama__arr:active .fotorama__arr__arr {
    opacity: 1; }

.fotorama__arr--prev {
  left: 0; }
  .fotorama__arr--prev .fotorama__arr__arr {
    background-position: 50% 50%;
    transform: translate3d(-50%, -50%, 0) rotate(180deg) !important; }

.fotorama__arr--next {
  right: 0; }
  .fotorama__arr--next .fotorama__arr__arr {
    background-position: 50% 50%; }

.fotorama__arr--disabled {
  cursor: default;
  opacity: 0.1;
  pointer-events: none; }

.fotorama--fullscreen-icons .fotorama__fullscreen-icon {
  display: none; }

.fotorama__video-play {
  background-position: -80px -160px;
  height: 160px;
  width: 160px; }
  .fotorama__wrap--css2 .fotorama__video-play,
  .fotorama__wrap--video .fotorama__stage .fotorama__video-play {
    display: none; }
  .fotorama__loaded .fotorama__video-play,
  .fotorama__error .fotorama__video-play,
  .fotorama__nav__frame .fotorama__video-play {
    display: block;
    opacity: 1; }
  .fotorama__nav__frame .fotorama__video-play {
    background-position: 0 -240px;
    height: 80px;
    width: 80px; }

.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr {
  opacity: 0; }
  .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus {
    opacity: 1; }

.fotorama__wrap--toggle-arrows:not(.fotorama__wrap--video) .fotorama__video-close {
  display: none; }

.fotorama__wrap--toggle-arrows.fotorama__wrap--video .fotorama__video-close {
  opacity: 1;
  right: 93px;
  top: 97px; }

.fotorama__wrap--toggle-arrows:not(.fotorama__wrap--video) .fotorama__video-close {
  display: none; }

.fotorama__wrap--toggle-arrows.fotorama__wrap--video .fotorama__video-close {
  opacity: 1;
  right: 93px;
  top: 97px; }

.fotorama__wrap--toggle-arrows:not(.fotorama__wrap--video) .fotorama__video-close {
  display: none; }

.fotorama__wrap--toggle-arrows.fotorama__wrap--video .fotorama__video-close {
  opacity: 1;
  right: 93px;
  top: 97px; }

.fotorama__wrap--video .fotorama__arr,
.fotorama__wrap--video .fotorama__fullscreen-icon {
  opacity: 0 !important; }

.fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr,
.fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
  display: none; }
  .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus,
  .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
    display: block; }

.fotorama__wrap--css2.fotorama__wrap--video .fotorama__arr,
.fotorama__wrap--css2.fotorama__wrap--video .fotorama__fullscreen-icon {
  display: none !important; }

.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:not(:focus) {
  transform: translate3d(80px, -80px, 0); }

.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--prev:not(:focus) {
  transform: translate3d(-120px, 0, 0); }

.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--next:not(:focus) {
  transform: translate3d(120px, 0, 0); }

.fotorama__wrap--css3.fotorama__wrap--video .fotorama__fullscreen-icon {
  transform: translate3d(80px, -80px, 0) !important; }

.fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--prev {
  transform: translate3d(-120px, 0, 0) !important; }

.fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--next {
  transform: translate3d(120px, 0, 0) !important; }

.fotorama__wrap--css3 .fotorama__arr:not(:focus),
.fotorama__wrap--css3 .fotorama__fullscreen-icon:not(:focus),
.fotorama__wrap--css3 .fotorama__video-play:not(:focus),
.fotorama__wrap--css3 .fotorama__video-close:not(:focus) {
  transition-duration: 0.3s;
  transition-property: transform, opacity, background-color; }

.fotorama__stage:before, .fotorama__stage:after,
.fotorama__nav:before,
.fotorama__nav:after {
  background-repeat: no-repeat;
  content: '';
  display: block;
  pointer-events: none;
  position: absolute;
  text-decoration: none;
  z-index: 10; }

.fotorama__stage:before,
.fotorama__nav:before {
  left: -10px;
  top: -10px; }

.fotorama__stage:after,
.fotorama__nav:after {
  right: -10px;
  bottom: -10px; }

.fotorama__stage.fotorama__shadows--left:before, .fotorama__stage.fotorama__shadows--right:after,
.fotorama__nav.fotorama__shadows--left:before,
.fotorama__nav.fotorama__shadows--right:after {
  background-size: 1px 100%, 5px 100%;
  bottom: 0;
  height: auto;
  top: 0;
  width: 10px; }

.fotorama__stage.fotorama__shadows--top:before, .fotorama__stage.fotorama__shadows--bottom:after,
.fotorama__nav.fotorama__shadows--top:before,
.fotorama__nav.fotorama__shadows--bottom:after {
  background-size: 100% 1px, 100% 5px;
  height: 10px;
  left: 0;
  right: 0;
  width: auto; }

.fotorama__stage.fotorama__shadows--left:before,
.fotorama__nav.fotorama__shadows--left:before {
  background-image: linear-gradient(transparent, rgba(24, 24, 24, 0.2) 25%, rgba(24, 24, 24, 0.3) 75%, transparent), radial-gradient(farthest-side at 0 50%, rgba(24, 24, 24, 0.4), transparent);
  background-position: 0 0, 0 0;
  left: 0; }

.fotorama__stage.fotorama__shadows--right:after,
.fotorama__nav.fotorama__shadows--right:after {
  background-image: linear-gradient(transparent, rgba(24, 24, 24, 0.2) 25%, rgba(24, 24, 24, 0.3) 75%, transparent), radial-gradient(farthest-side at 100% 50%, rgba(24, 24, 24, 0.4), transparent);
  background-position: 100% 0, 100% 0;
  right: 0; }

.fotorama__stage.fotorama__shadows--top:before,
.fotorama__nav.fotorama__shadows--top:before {
  background-image: linear-gradient(transparent, rgba(24, 24, 24, 0.2) 25%, rgba(24, 24, 24, 0.3) 75%, transparent), radial-gradient(farthest-side at 50% 0, rgba(24, 24, 24, 0.4), transparent);
  background-position: 0 0, 0 0;
  top: 0; }

.fotorama__stage.fotorama__shadows--bottom:after,
.fotorama__nav.fotorama__shadows--bottom:after {
  background-image: linear-gradient(transparent, rgba(24, 24, 24, 0.2) 25%, rgba(24, 24, 24, 0.3) 75%, transparent), radial-gradient(farthest-side at 50% 100%, rgba(24, 24, 24, 0.4), transparent);
  background-position: 0 100%, 0 100%;
  bottom: 0; }

.fotorama--fullscreen .fotorama__stage:before, .fotorama--fullscreen .fotorama__stage:after,
.fotorama--fullscreen .fotorama__nav:before,
.fotorama--fullscreen .fotorama__nav:after,
.fotorama__wrap--no-shadows .fotorama__stage:before,
.fotorama__wrap--no-shadows .fotorama__stage:after,
.fotorama__wrap--no-shadows .fotorama__nav:before,
.fotorama__wrap--no-shadows .fotorama__nav:after,
.fotorama__wrap--fade .fotorama__stage:before,
.fotorama__wrap--fade .fotorama__stage:after {
  display: none; }

.fotorama__wrap--no-shadows .fotorama__stage:before {
  display: block; }

.fotorama-abs-center, .fotorama__arr .fotorama__arr__arr, .fotorama__video-play, .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left .fotorama__thumb--icon,
.fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right .fotorama__thumb--icon {
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
  position: absolute;
  top: 50%; }

.fotorama__nav-wrap .fotorama_vertical_ratio .fotorama__img {
  transform: translateY(-50%);
  height: auto;
  position: absolute;
  top: 50%;
  width: 100%; }

.fotorama__nav-wrap .fotorama_horizontal_ratio .fotorama__img {
  transform: translateX(-50%);
  height: 100%;
  left: 50%;
  position: absolute;
  width: auto; }

.magnifier-thumb-wrapper {
  display: block;
  left: 0;
  position: relative;
  top: 0; }

.magnifier-lens {
  border: solid 1px #eee;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 800; }

.magnify-lens {
  background: rgba(255, 255, 255, 0.5);
  border: solid 1px #eee;
  color: white;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  z-index: 1000; }
  @media screen and (max-width: 47.9375em) {
    .magnify-lens {
      display: none !important; } }

.magnifier-loader-text {
  margin-top: 10px; }

.magnifier-xlarge {
  position: absolute;
  width: 32%;
  z-index: 100; }

.magnifier-preview {
  left: 100% !important;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0 !important;
  z-index: 1;
  box-shadow: 0 0 5px rgba(24, 24, 24, 0.5); }
  .magnifier-preview:not(.hidden) {
    background-color: white; }
  .magnifier-preview img {
    left: 0;
    max-width: inherit;
    position: absolute;
    top: 0; }

.fotorama__stage__frame {
  text-align: center; }
  .fotorama__stage__frame .fotorama__img {
    height: auto;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition-duration: 0.3s;
    transition-property: width, height, top, left;
    vertical-align: middle;
    width: auto; }

.magnify-opaque {
  opacity: .5; }

.magnify-hidden {
  display: none; }

.fotorama__nav-wrap--vertical.fotorama__nav-wrap {
  display: inline-block;
  left: 0;
  position: absolute;
  top: 0; }

.fotorama__nav-wrap--vertical .fotorama__nav__shaft {
  background-color: white;
  width: 100%; }
  .fotorama__nav-wrap--vertical .fotorama__nav__shaft .fotorama__nav__frame--thumb {
    display: block;
    padding-bottom: inherit !important; }

.fotorama--fullscreen .fotorama__stage__frame .fotorama__img {
  display: none; }

.fotorama--fullscreen .fotorama__stage__frame .fotorama__img,
.fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: inherit;
  max-width: inherit;
  position: absolute;
  right: 0;
  top: 0; }

.fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
  cursor: default;
  display: block;
  height: auto;
  left: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  top: 0;
  transition: 0.3s linear;
  vertical-align: middle;
  width: auto; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full.fotorama__img--zoommable {
    cursor: pointer;
    max-height: none;
    max-width: none;
    transition-property: width, height, bottom, right, top, left; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full.fotorama__img--draggable {
    cursor: move;
    transition-property: none; }

.fotorama--fullscreen .fotorama__stage__frame iframe {
  left: 80px; }

.fotorama--fullscreen .fotorama__nav-wrap--horizontal {
  text-align: center; }
  .fotorama--fullscreen .fotorama__nav-wrap--horizontal .fotorama__nav__shaft {
    display: inline-block; }
  .fotorama--fullscreen .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right,
  .fotorama--fullscreen .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    left: auto;
    right: auto;
    height: 100%; }

.fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left,
.fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right {
  bottom: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 30px;
  z-index: 1000; }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left .fotorama__thumb--icon,
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right .fotorama__thumb--icon {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.75) url("../images/icon-arrow-next-gray.svg") no-repeat 100% 50%;
    background-size: 14px auto;
    background-position: 50%; }
    .ie9 .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left .fotorama__thumb--icon, .ie9
    .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right .fotorama__thumb--icon {
      margin: -15px 0 0 -15px; }

.fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left {
  left: 0; }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left .fotorama__thumb--icon {
    transform: translate3d(-50%, -50%, 0) rotate(180deg); }

.fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right {
  right: 0; }

.fotorama__nav-wrap--vertical .fotorama__thumb__arr--left,
.fotorama__nav-wrap--vertical .fotorama__thumb__arr--right {
  cursor: pointer;
  height: 30px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1000; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left .fotorama__thumb--icon,
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right .fotorama__thumb--icon {
    transform: rotate(90deg);
    margin: auto;
    width: 30px; }

.fotorama__nav-wrap--vertical .fotorama__thumb__arr--left {
  top: 0; }

.fotorama__nav-wrap--vertical .fotorama__thumb__arr--right {
  bottom: 0; }

.fotorama__wrap--only-active .fotorama__stage,
.fotorama__wrap--only-active .fotorama__nav {
  max-width: 99999px !important; }

.fotorama__wrap--only-active .fotorama__stage__frame {
  visibility: hidden; }

.fotorama__wrap--only-active .fotorama__stage__frame.fotorama__active {
  visibility: visible; }

@media screen and (max-width: 47.9375em) {
  .fotorama__arr--next,
  .fotorama__arr--prev {
    width: 40px; }
    .fotorama__arr--next .fotorama__arr__arr,
    .fotorama__arr--prev .fotorama__arr__arr {
      width: 100%;
      background: url("../images/icon-arrow-next-gray.svg") no-repeat 100% 50%;
      background-size: 14px auto; }
  .fotorama__arr--prev .fotorama__arr__arr {
    transform: translate3d(-50%, -50%, 0) rotate(180deg); }
  .fotorama__nav-wrap {
    text-align: center; }
    .fotorama__nav-wrap .fotorama__nav {
      height: auto !important;
      width: auto !important; }
    .fotorama__nav-wrap .fotorama__nav__frame {
      height: 7px !important;
      width: 7px !important;
      padding: 0 !important;
      border-radius: 50%;
      margin: 0 3px;
      overflow: hidden; }
      .fotorama__nav-wrap .fotorama__nav__frame .fotorama__thumb {
        background: #e4e4e4; }
      .fotorama__nav-wrap .fotorama__nav__frame img {
        display: none; }
      .fotorama__nav-wrap .fotorama__nav__frame.fotorama__active .fotorama__thumb {
        background: #181818; }
    .fotorama__nav-wrap .fotorama__thumb-border,
    .fotorama__nav-wrap .fotorama__thumb__arr {
      display: none !important; } }

.magnify-fullimage {
  display: none; }

.fotorama__video-close {
  background-position: -80px 0;
  height: 80px;
  opacity: 0;
  right: 0;
  top: 0;
  transform: translate3d(80px, -80px, 0);
  transition: opacity 0.3s ease-in-out;
  width: 80px; }
  @media all and (max-width: 768px) {
    .fotorama__video-close {
      background-position: -100px -20px;
      top: 10px;
      height: 40px;
      width: 40px; } }
  .fotorama__video-close.fotorama-show-control {
    opacity: 1;
    transform: translate3d(0, -10px, 0); }

.gallery-placeholder .loading-mask .loader {
  display: block; }

.fotorama__spinner {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.3);
  background-image: url("../images/ripple.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  z-index: 555; }
  .ie11 .fotorama__spinner,
  .no-smil .fotorama__spinner {
    background-image: url("../images/ripple.gif"); }
  .fotorama__spinner .loading-mssg {
    display: block;
    position: absolute;
    top: 10rem;
    left: 50%;
    transform: translateX(-50%);
    color: #1A2746;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-weight: 400;
    font-size: 100%;
    font-style: normal; }
    .fotorama__spinner .loading-mssg .loading-img {
      margin-right: .5rem; }
    @media print, screen and (min-width: 40em) {
      .fotorama__spinner .loading-mssg {
        font-size: 150%; } }
    @media screen and (min-width: 64em) {
      .fotorama__spinner .loading-mssg {
        font-size: 180%;
        top: 15rem; } }
  .fotorama__spinner.fotorama__spinner--show {
    display: block; }

.fotorama__product-video--loaded .fotorama__img, .fotorama__product-video--loaded .fotorama__img--full {
  display: none !important; }

.fotorama__stage {
  box-shadow: none !important;
  margin-bottom: 1rem;
  overflow: visible;
  background: white; }
  .fotorama__stage .amasty-label-container {
    opacity: 0; }
    .fotorama__stage .amasty-label-container:last-child {
      opacity: 1; }
  @media print, screen and (min-width: 48em) {
    .fotorama__stage {
      margin-bottom: 2rem; } }
  .fotorama__stage .fotorama__arr--shown {
    display: block !important; }
  .fotorama__stage .fotorama__arr--hidden {
    display: none !important; }

.fotorama-item {
  padding-bottom: 2rem;
  background: white; }
  .fotorama-item .fotorama__nav--thumbs {
    text-align: center; }
  .fotorama-item .fotorama__nav-wrap--horizontal {
    padding: 1rem 2rem 0; }
  .fotorama-item.fotorama--fullscreen {
    padding-bottom: 0; }
    .fotorama-item.fotorama--fullscreen .fotorama__nav-wrap--horizontal {
      padding: 0; }

.catalog-product-view .fotorama__caption {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.amlabel-position-wrapper {
  z-index: 2 !important; }
  .fotorama--fullscreen .amlabel-position-wrapper {
    display: none !important; }

.product-info-main .swatch-attribute-options,
.product-info-main .ais-refinement-list--list {
  width: 100%;
  display: block; }
  .product-info-main .swatch-attribute-options .ais-refinement-list--item,
  .product-info-main .ais-refinement-list--list .ais-refinement-list--item {
    display: inline-block;
    vertical-align: top;
    min-width: 3rem;
    width: auto;
    border: 0; }
  .product-info-main .swatch-attribute-options .swatch-option,
  .product-info-main .ais-refinement-list--list .swatch-option {
    display: inline-block;
    vertical-align: middle;
    min-width: 3rem;
    width: auto;
    height: 2.25rem;
    padding: 0 2px;
    text-align: center;
    color: #181818;
    line-height: 2.25rem;
    border-radius: 0;
    font-size: 0.9375rem;
    font-weight: 700;
    font-family: inherit;
    margin: 0;
    white-space: nowrap;
    box-shadow: none;
    background-color: white;
    cursor: pointer;
    border-left: 1px solid rgba(24, 24, 24, 0.15); }
    .product-info-main .swatch-attribute-options .swatch-option:first-child,
    .product-info-main .ais-refinement-list--list .swatch-option:first-child {
      border-left: 0; }
    .product-info-main .swatch-attribute-options .swatch-option:hover, .product-info-main .swatch-attribute-options .swatch-option:active, .product-info-main .swatch-attribute-options .swatch-option.selected,
    .product-info-main .ais-refinement-list--list .swatch-option:hover,
    .product-info-main .ais-refinement-list--list .swatch-option:active,
    .product-info-main .ais-refinement-list--list .swatch-option.selected {
      color: white;
      background-color: #181818;
      border-left-color: #181818;
      box-shadow: none; }
      .product-info-main .swatch-attribute-options .swatch-option:hover:after, .product-info-main .swatch-attribute-options .swatch-option:active:after, .product-info-main .swatch-attribute-options .swatch-option.selected:after,
      .product-info-main .ais-refinement-list--list .swatch-option:hover:after,
      .product-info-main .ais-refinement-list--list .swatch-option:active:after,
      .product-info-main .ais-refinement-list--list .swatch-option.selected:after {
        display: none; }
      .product-info-main .swatch-attribute-options .swatch-option:hover + .swatch-option, .product-info-main .swatch-attribute-options .swatch-option:active + .swatch-option, .product-info-main .swatch-attribute-options .swatch-option.selected + .swatch-option,
      .product-info-main .ais-refinement-list--list .swatch-option:hover + .swatch-option,
      .product-info-main .ais-refinement-list--list .swatch-option:active + .swatch-option,
      .product-info-main .ais-refinement-list--list .swatch-option.selected + .swatch-option {
        border-left-color: #181818; }
        .product-info-main .swatch-attribute-options .swatch-option:hover + .swatch-option:hover, .product-info-main .swatch-attribute-options .swatch-option:active + .swatch-option:hover, .product-info-main .swatch-attribute-options .swatch-option.selected + .swatch-option:hover,
        .product-info-main .ais-refinement-list--list .swatch-option:hover + .swatch-option:hover,
        .product-info-main .ais-refinement-list--list .swatch-option:active + .swatch-option:hover,
        .product-info-main .ais-refinement-list--list .swatch-option.selected + .swatch-option:hover {
          border-left-color: rgba(24, 24, 24, 0.15); }
    .product-info-main .swatch-attribute-options .swatch-option.disabled,
    .product-info-main .ais-refinement-list--list .swatch-option.disabled {
      opacity: 1;
      position: relative;
      color: #bbbbbb; }
      .product-info-main .swatch-attribute-options .swatch-option.disabled:hover, .product-info-main .swatch-attribute-options .swatch-option.disabled:active,
      .product-info-main .ais-refinement-list--list .swatch-option.disabled:hover,
      .product-info-main .ais-refinement-list--list .swatch-option.disabled:active {
        cursor: help;
        color: #bbbbbb;
        background: transparent;
        border-left-color: rgba(24, 24, 24, 0.15); }
        .product-info-main .swatch-attribute-options .swatch-option.disabled:hover + .swatch-option, .product-info-main .swatch-attribute-options .swatch-option.disabled:active + .swatch-option,
        .product-info-main .ais-refinement-list--list .swatch-option.disabled:hover + .swatch-option,
        .product-info-main .ais-refinement-list--list .swatch-option.disabled:active + .swatch-option {
          border-left-color: rgba(24, 24, 24, 0.15); }
          .product-info-main .swatch-attribute-options .swatch-option.disabled:hover + .swatch-option:hover, .product-info-main .swatch-attribute-options .swatch-option.disabled:active + .swatch-option:hover,
          .product-info-main .ais-refinement-list--list .swatch-option.disabled:hover + .swatch-option:hover,
          .product-info-main .ais-refinement-list--list .swatch-option.disabled:active + .swatch-option:hover {
            border-left-color: rgba(24, 24, 24, 0.15); }
        .product-info-main .swatch-attribute-options .swatch-option.disabled:hover:before, .product-info-main .swatch-attribute-options .swatch-option.disabled:hover:after, .product-info-main .swatch-attribute-options .swatch-option.disabled:active:before, .product-info-main .swatch-attribute-options .swatch-option.disabled:active:after,
        .product-info-main .ais-refinement-list--list .swatch-option.disabled:hover:before,
        .product-info-main .ais-refinement-list--list .swatch-option.disabled:hover:after,
        .product-info-main .ais-refinement-list--list .swatch-option.disabled:active:before,
        .product-info-main .ais-refinement-list--list .swatch-option.disabled:active:after {
          opacity: 1; }
      .product-info-main .swatch-attribute-options .swatch-option.disabled:before,
      .product-info-main .ais-refinement-list--list .swatch-option.disabled:before {
        opacity: 0;
        display: block;
        content: attr(option-label);
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 0.625rem;
        font-size: 0.6875rem;
        line-height: 0;
        margin: 0;
        color: white;
        white-space: nowrap;
        background: #181818;
        transition: opacity .3s ease;
        z-index: 1; }
      .product-info-main .swatch-attribute-options .swatch-option.disabled:after,
      .product-info-main .ais-refinement-list--list .swatch-option.disabled:after {
        opacity: 0;
        content: '';
        width: 0;
        height: 0;
        display: block;
        border-style: solid;
        border-width: 0 6px 6px 6px;
        border-color: transparent transparent #181818 transparent;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: opacity .3s ease; }

@media screen and (min-width: 64em) {
  .product-info-wrapper .open-size-guide-modal {
    margin-right: 1.5rem; } }

@media screen and (min-width: 75em) {
  .product-info-wrapper .open-size-guide-modal {
    margin-right: 2.5rem; } }

.modal-popup.size-guide .modal-header {
  padding-top: 2rem; }

@media print, screen and (min-width: 48em) {
  .modal-popup.size-guide .modal-content {
    padding-bottom: 1rem; } }

@media screen and (min-width: 64em) {
  .modal-popup.size-guide .modal-content {
    padding-bottom: 3rem; } }

.product-size-guide-modal {
  text-align: center; }
  .product-size-guide-modal .ttl {
    text-transform: none;
    font-weight: 400;
    margin-bottom: 2rem;
    font-size: 1.75rem; }
    @media print, screen and (min-width: 40em) {
      .product-size-guide-modal .ttl {
        font-size: 2.625rem; } }
    @media screen and (min-width: 64em) {
      .product-size-guide-modal .ttl {
        font-size: 2.875rem; } }
    @media screen and (min-width: 75em) {
      .product-size-guide-modal .ttl {
        margin-bottom: 1.5rem; } }
  .product-size-guide-modal .size-line {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    margin-right: -1.0625rem;
    margin-left: -1.0625rem; }
    .product-size-guide-modal .size-line::before, .product-size-guide-modal .size-line::after {
      display: table;
      content: ' '; }
    .product-size-guide-modal .size-line::after {
      clear: both; }
    @media print, screen and (min-width: 40em) {
      .product-size-guide-modal .size-line {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .product-size-guide-modal .size-line {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .product-size-guide-modal .size-line {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .product-size-guide-modal .size-line {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .product-size-guide-modal .size-line {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    .product-size-guide-modal .size-line .size-block {
      padding-top: .5rem;
      padding-bottom: .5rem; }
      @media print, screen and (min-width: 48em) {
        .product-size-guide-modal .size-line .size-block {
          padding-top: 1.25rem;
          padding-bottom: 1.25rem; } }
      @media screen and (min-width: 64em) {
        .product-size-guide-modal .size-line .size-block {
          width: 100%;
          float: left;
          padding-right: 1.0625rem;
          padding-left: 1.0625rem;
          width: 50%; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .product-size-guide-modal .size-line .size-block {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
      @media screen and (min-width: 64em) {
          .product-size-guide-modal .size-line .size-block:last-child:not(:first-child) {
            float: right; }
          .product-size-guide-modal .size-line .size-block:first-child {
            float: right; } }

.page-with-filter .sidebar:not(.algolia-left-container),
.catalogsearch-result-index .sidebar:not(.algolia-left-container),
.algolia-instant-selector-results .sidebar:not(.algolia-left-container) {
  display: none; }
  @media print, screen and (min-width: 40em) {
    .page-with-filter .sidebar:not(.algolia-left-container),
    .catalogsearch-result-index .sidebar:not(.algolia-left-container),
    .algolia-instant-selector-results .sidebar:not(.algolia-left-container) {
      display: block; } }

.category-header {
  background-size: cover;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  padding-top: 1px; }
  .category-header:not(.has-desc) + .page-main .mobile-controls {
    padding-top: .5rem; }
    .category-header:not(.has-desc) + .page-main .mobile-controls:before {
      display: none; }
  @media screen and (max-width: 47.9375em) {
    .category-header {
      background: none !important; }
      .category-header .category-description,
      .category-header .image-view {
        display: none; } }
  .category-header .category-view {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto; }
    .category-header .category-view::before, .category-header .category-view::after {
      display: table;
      content: ' '; }
    .category-header .category-view::after {
      clear: both; }
    .category-header .category-view.toolbar {
      margin-bottom: 0;
      padding-bottom: 0; }
      .category-header .category-view.toolbar .page-title-wrapper {
        padding-bottom: 0;
        line-height: inherit; }
        .category-header .category-view.toolbar .page-title-wrapper:before {
          display: none; }
      .category-header .category-view.toolbar .page-title {
        margin-bottom: 0.53125rem; }
        @media print, screen and (min-width: 40em) {
          .category-header .category-view.toolbar .page-title {
            margin-bottom: 0.625rem; } }
        @media screen and (min-width: 64em) {
          .category-header .category-view.toolbar .page-title {
            font-size: 56px; } }
        @media screen and (min-width: 75em) {
          .category-header .category-view.toolbar .page-title {
            margin-bottom: 1.0625rem; } }
  @media screen and (min-width: 75em) and (min-width: 40em) {
    .category-header .category-view.toolbar .page-title {
      margin-bottom: 1.25rem; } }
      .category-header .category-view.toolbar .toolbar-sorter {
        margin-top: .25rem;
        margin-bottom: .75rem; }
        @media screen and (min-width: 64em) {
          .category-header .category-view.toolbar .toolbar-sorter {
            margin-top: .75rem; } }
        @media screen and (min-width: 75em) {
          .category-header .category-view.toolbar .toolbar-sorter {
            margin-top: 1.25rem; } }
  .category-header .category-description {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    margin-bottom: 2.65625rem; }
    @media print, screen and (min-width: 40em) {
      .category-header .category-description {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .category-header .category-description:last-child:not(:first-child) {
      float: right; }
    .category-header .category-description:last-child:last-child {
      float: left; }
    @media print, screen and (min-width: 40em) {
      .category-header .category-description {
        margin-bottom: 3.125rem; } }
    .category-header .category-description p {
      line-height: 1.3;
      margin-bottom: 0; }
    @media screen and (min-width: 64em) {
      .category-header .category-description {
        margin-bottom: 1.0625rem; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .category-header .category-description {
      margin-bottom: 1.25rem; } }
    @media screen and (min-width: 64em) {
        .category-header .category-description p {
          line-height: 1.5;
          margin-bottom: 1rem; } }
  .category-header + .category-cms {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    padding-left: 1.0625rem;
    padding-right: 1.0625rem;
    margin-bottom: 1.5rem; }
    .category-header + .category-cms::before, .category-header + .category-cms::after {
      display: table;
      content: ' '; }
    .category-header + .category-cms::after {
      clear: both; }
    @media print, screen and (min-width: 40em) {
      .category-header + .category-cms {
        padding-left: 1.25rem; } }
    @media print, screen and (min-width: 40em) {
      .category-header + .category-cms {
        padding-right: 1.25rem; } }
    @media screen and (max-width: 47.9375em) {
      .category-header + .category-cms {
        display: none; } }

@media screen and (max-width: 47.9375em) {
  .category-view .page-title {
    margin-bottom: 0; }
    .category-view .page-title span {
      display: block;
      font-size: 1.75rem;
      font-weight: 300;
      line-height: 1.2;
      font-family: "silka-regular-webfont", sans-serif;
      letter-spacing: normal;
      padding-bottom: 1.0625rem;
      margin-bottom: 1.0625rem;
      position: relative;
      margin-bottom: 0;
      padding-bottom: .75rem; } }
    @media screen and (max-width: 47.9375em) and (min-width: 40em) {
      .category-view .page-title span {
        padding-bottom: 1.25rem; } }
    @media screen and (max-width: 47.9375em) and (min-width: 40em) {
      .category-view .page-title span {
        margin-bottom: 1.25rem; } }

@media screen and (max-width: 47.9375em) {
      .category-view .page-title span:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 1.0625rem;
        right: 1.0625rem;
        visibility: visible;
        opacity: 1;
        display: block;
        border: 0;
        border-bottom: 1px solid rgba(24, 24, 24, 0.2); } }
      @media screen and (max-width: 47.9375em) and (min-width: 40em) {
        .category-view .page-title span:before {
          left: 1.25rem; } }
      @media screen and (max-width: 47.9375em) and (min-width: 40em) {
        .category-view .page-title span:before {
          right: 1.25rem; } }

@media screen and (max-width: 47.9375em) {
      .category-view .page-title span strong {
        font-weight: inherit; }
      .category-view .page-title span .action {
        display: inline-block;
        vertical-align: baseline;
        font-size: 1.05rem;
        margin-left: .5rem;
        font-weight: 400; }
        .category-view .page-title span .action:hover {
          text-decoration: underline; }
      .category-view .page-title span:before {
        left: 0;
        right: 0; } }

.category-view .category-header-mobile {
  margin-left: 1.0625rem;
  margin-right: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    .category-view .category-header-mobile {
      margin-left: 1.25rem; } }
  @media print, screen and (min-width: 40em) {
    .category-view .category-header-mobile {
      margin-right: 1.25rem; } }
  .category-view .category-header-mobile .filter-options-title {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }
  .category-view .category-header-mobile .filter-options-content {
    margin-bottom: 0; }
    .category-view .category-header-mobile .filter-options-content ul,
    .category-view .category-header-mobile .filter-options-content ol {
      border-top: 0; }
      .category-view .category-header-mobile .filter-options-content ul li,
      .category-view .category-header-mobile .filter-options-content ol li {
        font-size: 1.1875rem;
        font-weight: 400;
        font-family: inherit; }
        .category-view .category-header-mobile .filter-options-content ul li:last-child,
        .category-view .category-header-mobile .filter-options-content ol li:last-child {
          border-bottom: 0; }
      .category-view .category-header-mobile .filter-options-content ul a,
      .category-view .category-header-mobile .filter-options-content ol a {
        font-size: 1.1875rem;
        padding: 0.625rem 3.125rem 0.625rem 0.5rem;
        color: white;
        background: url("../images/icon-arrow-next-gray.svg") calc(100% - 8px) 50% no-repeat;
        background-size: 7px auto; }
        .category-view .category-header-mobile .filter-options-content ul a:hover,
        .category-view .category-header-mobile .filter-options-content ol a:hover {
          color: #181818; }

.category-image {
  margin-bottom: 1.5rem; }

.toolbar.toolbar-products {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: .5rem;
  margin-bottom: 0;
  clear: both; }
  .toolbar.toolbar-products::before, .toolbar.toolbar-products::after {
    display: table;
    content: ' '; }
  .toolbar.toolbar-products::after {
    clear: both; }
  @media print, screen and (min-width: 48em) {
    .toolbar.toolbar-products {
      padding-bottom: 0; } }
  .products-grid + .toolbar.toolbar-products {
    padding-top: 1.75rem;
    margin-bottom: 0; }
  .algolia-right-container .toolbar.toolbar-products {
    margin-left: -1.0625rem;
    margin-right: -1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .algolia-right-container .toolbar.toolbar-products {
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 40em) {
      .algolia-right-container .toolbar.toolbar-products {
        margin-right: -1.25rem; } }
  @media print, screen and (min-width: 40em) {
    .toolbar.toolbar-products {
      padding-bottom: 0; } }
  .toolbar.toolbar-products .modes,
  .toolbar.toolbar-products .limiter,
  .toolbar.toolbar-products .pages {
    display: none; }
  .toolbar.toolbar-products .toolbar-amount,
  .toolbar.toolbar-products .mobile-controls {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    margin-bottom: .5rem; }
    @media print, screen and (min-width: 40em) {
      .toolbar.toolbar-products .toolbar-amount,
      .toolbar.toolbar-products .mobile-controls {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .toolbar.toolbar-products .toolbar-amount:last-child:not(:first-child),
    .toolbar.toolbar-products .mobile-controls:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 40em) {
      .toolbar.toolbar-products .toolbar-amount,
      .toolbar.toolbar-products .mobile-controls {
        width: 50%;
        margin-bottom: 1.25rem;
        line-height: 3.25rem; } }
    @media screen and (min-width: 64em) {
      .toolbar.toolbar-products .toolbar-amount,
      .toolbar.toolbar-products .mobile-controls {
        width: 66.66667%; } }
  .toolbar.toolbar-products .toolbar-amount {
    color: #494949;
    font-size: 0.80625rem;
    font-weight: 400;
    margin-top: .5rem; }
    @media print, screen and (min-width: 40em) {
      .toolbar.toolbar-products .toolbar-amount {
        margin-top: 0;
        margin-bottom: 0; } }
    @media print, screen and (min-width: 48em) {
      .toolbar.toolbar-products .toolbar-amount {
        font-size: 0.9375rem;
        margin-bottom: 1.25rem; } }
    .toolbar.toolbar-products .toolbar-amount a {
      color: #080707;
      text-decoration: underline; }
      .toolbar.toolbar-products .toolbar-amount a:hover, .toolbar.toolbar-products .toolbar-amount a:active {
        text-decoration: none; }
  .toolbar.toolbar-products .mobile-controls {
    line-height: inherit;
    font-size: 0; }
    .toolbar.toolbar-products .mobile-controls .mobile-filter-btn:last-child {
      margin-right: 0; }

.toolbar-sorter {
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem;
  font-size: 0; }
  @media print, screen and (min-width: 40em) {
    .toolbar-sorter {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .toolbar-sorter:last-child:not(:first-child) {
    float: right; }
  @media print, screen and (min-width: 40em) {
    .toolbar-sorter {
      width: 50%; } }
  @media screen and (min-width: 64em) {
    .toolbar-sorter {
      width: 33.33333%; } }
  @media screen and (min-width: 75em) {
    .toolbar-sorter {
      width: 25%; } }
  .toolbar-sorter .sorter-action {
    display: none; }
  .toolbar-sorter .sorter-label {
    display: inline-block;
    text-align: right;
    white-space: nowrap;
    padding-right: 1rem; }
    .toolbar-sorter .sorter-label:after {
      content: ':'; }
    @media print, screen and (min-width: 40em) {
      .toolbar-sorter .sorter-label {
        width: 50%; } }
  .toolbar-sorter select.sorter-options,
  .toolbar-sorter .sorter-options select {
    display: inline-block;
    height: 3.25rem;
    font-size: 0.9375rem;
    font-weight: 400;
    font-family: "silka-regular-webfont", sans-serif;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    margin-bottom: 0;
    background-color: white;
    text-transform: none; }
    @media print, screen and (min-width: 40em) {
      .toolbar-sorter select.sorter-options,
      .toolbar-sorter .sorter-options select {
        width: 280px; } }
  .toolbar-sorter .sorter-options > div {
    display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .toolbar-sorter .sorter-options > div {
        width: 280px; }
        .toolbar-sorter .sorter-options > div select {
          width: 100%; } }

.block.filter .filter-options-title,
.block.filter .filter-options-content {
  padding: 0 0 2.6875rem;
  background: transparent; }

.block.filter .filter-options-title {
  padding-bottom: 0.75rem;
  padding-left: 0; }
  .block.filter .filter-options-title::before, .block.filter .filter-options-title::after {
    display: table;
    content: ' '; }
  .block.filter .filter-options-title::after {
    clear: both; }

.block.filter .filter-options-content {
  padding-top: 0; }
  .block.filter .filter-options-content.has-ui-slider {
    padding-top: .75rem;
    padding-bottom: 3.5rem; }

@media screen and (max-width: 63.9375em) {
  .block.filter .filter-options .filter-content:last-child .filter-options-content {
    padding-bottom: 0; } }

.block.filter ol.items,
.filter-content ol.items,
.nav-toolbar-sorter-mobile ol.items {
  margin: 0;
  list-style: none; }
  .block.filter ol.items li,
  .filter-content ol.items li,
  .nav-toolbar-sorter-mobile ol.items li {
    padding: 0; }
    .block.filter ol.items li:after,
    .filter-content ol.items li:after,
    .nav-toolbar-sorter-mobile ol.items li:after {
      display: none; }

.block.filter.filter-price ol.items,
.block.filter.filter-price ul.items,
.block.filter .has-ui-slider ol.items,
.block.filter .has-ui-slider ul.items,
.filter-content.filter-price ol.items,
.filter-content.filter-price ul.items,
.filter-content .has-ui-slider ol.items,
.filter-content .has-ui-slider ul.items,
.nav-toolbar-sorter-mobile.filter-price ol.items,
.nav-toolbar-sorter-mobile.filter-price ul.items,
.nav-toolbar-sorter-mobile .has-ui-slider ol.items,
.nav-toolbar-sorter-mobile .has-ui-slider ul.items {
  border-top: 0; }

.block.filter a,
.filter-content a,
.nav-toolbar-sorter-mobile a {
  text-decoration: none; }

.block.filter .filter-title,
.block.filter .filter-subtitle,
.filter-content .filter-title,
.filter-content .filter-subtitle,
.nav-toolbar-sorter-mobile .filter-title,
.nav-toolbar-sorter-mobile .filter-subtitle {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.block.filter .filter-options-title,
.block.filter .filter-current-subtitle,
.filter-content .filter-options-title,
.filter-content .filter-current-subtitle,
.nav-toolbar-sorter-mobile .filter-options-title,
.nav-toolbar-sorter-mobile .filter-current-subtitle {
  display: block;
  font-family: "silka-regular-webfont", sans-serif;
  letter-spacing: normal;
  font-size: 1.625rem;
  color: #181818;
  font-weight: 300;
  margin-bottom: 0;
  line-height: 1.2;
  padding-bottom: 0.75rem; }
  .block.filter .filter-options-title .clear-filter,
  .block.filter .filter-current-subtitle .clear-filter,
  .filter-content .filter-options-title .clear-filter,
  .filter-content .filter-current-subtitle .clear-filter,
  .nav-toolbar-sorter-mobile .filter-options-title .clear-filter,
  .nav-toolbar-sorter-mobile .filter-current-subtitle .clear-filter {
    font-family: "silka-regular-webfont", sans-serif;
    font-size: 0.6875rem;
    font-weight: 400;
    color: #181818;
    border-bottom: 1px solid #656565;
    line-height: 1;
    margin-top: 0.65rem;
    float: right; }
    .block.filter .filter-options-title .clear-filter:hover, .block.filter .filter-options-title .clear-filter:active,
    .block.filter .filter-current-subtitle .clear-filter:hover,
    .block.filter .filter-current-subtitle .clear-filter:active,
    .filter-content .filter-options-title .clear-filter:hover,
    .filter-content .filter-options-title .clear-filter:active,
    .filter-content .filter-current-subtitle .clear-filter:hover,
    .filter-content .filter-current-subtitle .clear-filter:active,
    .nav-toolbar-sorter-mobile .filter-options-title .clear-filter:hover,
    .nav-toolbar-sorter-mobile .filter-options-title .clear-filter:active,
    .nav-toolbar-sorter-mobile .filter-current-subtitle .clear-filter:hover,
    .nav-toolbar-sorter-mobile .filter-current-subtitle .clear-filter:active {
      border-bottom: 0;
      padding-bottom: 1px; }

.block.filter .filter-options-content,
.filter-content .filter-options-content,
.nav-toolbar-sorter-mobile .filter-options-content {
  margin-bottom: 0.5rem; }

.block.filter .filter-current,
.filter-content .filter-current,
.nav-toolbar-sorter-mobile .filter-current {
  margin-bottom: 0.125rem; }
  .block.filter .filter-current .filter-label:after,
  .filter-content .filter-current .filter-label:after,
  .nav-toolbar-sorter-mobile .filter-current .filter-label:after {
    content: ':'; }
  .block.filter .filter-current .filter-value,
  .filter-content .filter-current .filter-value,
  .nav-toolbar-sorter-mobile .filter-current .filter-value {
    color: #1A2746; }
  .block.filter .filter-current .item,
  .filter-content .filter-current .item,
  .nav-toolbar-sorter-mobile .filter-current .item {
    padding: 0.5rem 1.8rem 0.5rem 0; }
    .block.filter .filter-current .item a,
    .filter-content .filter-current .item a,
    .nav-toolbar-sorter-mobile .filter-current .item a {
      padding: 0; }

.block.filter .action.remove,
.filter-content .action.remove,
.nav-toolbar-sorter-mobile .action.remove {
  display: inline-block;
  vertical-align: middle;
  color: #D41367; }
  .block.filter .action.remove span,
  .filter-content .action.remove span,
  .nav-toolbar-sorter-mobile .action.remove span {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }

.block.filter .action.clear,
.filter-content .action.clear,
.nav-toolbar-sorter-mobile .action.clear {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.82em 1em 0.87em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 50px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 1.1875rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #30A95E;
  color: #fff;
  font-family: "silka-semibold-webfont", sans-serif;
  letter-spacing: normal;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 0;
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  background-color: #181818;
  color: #fff;
  margin-bottom: 1.15rem; }
  [data-whatinput='mouse'] .block.filter .action.clear, [data-whatinput='mouse']
  .filter-content .action.clear, [data-whatinput='mouse']
  .nav-toolbar-sorter-mobile .action.clear {
    outline: 0; }
  .block.filter .action.clear:hover, .block.filter .action.clear:focus,
  .filter-content .action.clear:hover,
  .filter-content .action.clear:focus,
  .nav-toolbar-sorter-mobile .action.clear:hover,
  .nav-toolbar-sorter-mobile .action.clear:focus {
    background-color: rgba(48, 169, 94, 0.75);
    color: #fff; }
  .block.filter .action.clear:active,
  .filter-content .action.clear:active,
  .nav-toolbar-sorter-mobile .action.clear:active {
    color: rgba(255, 255, 255, 0.8) !important; }
  .block.filter .action.clear:hover, .block.filter .action.clear:focus,
  .filter-content .action.clear:hover,
  .filter-content .action.clear:focus,
  .nav-toolbar-sorter-mobile .action.clear:hover,
  .nav-toolbar-sorter-mobile .action.clear:focus {
    background-color: black;
    color: #fff; }

.block.filter .label,
.filter-content .label,
.nav-toolbar-sorter-mobile .label {
  padding: 0;
  vertical-align: middle;
  color: #181818;
  font-size: inherit;
  line-height: inherit;
  white-space: normal;
  background: none; }

.filter-content .item {
  position: relative; }
  .filter-content .item label {
    margin: 0; }
  .filter-content .item input[type="checkbox"],
  .filter-content .item input[type="radio"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0; }
    .filter-content .item input[type="checkbox"][checked="checked"] + .label,
    .filter-content .item input[type="radio"][checked="checked"] + .label {
      font-weight: bolder; }
      .filter-content .item input[type="checkbox"][checked="checked"] + .label:after,
      .filter-content .item input[type="radio"][checked="checked"] + .label:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 16px;
        height: 13px;
        background: url("../images/icon-check-white.svg") no-repeat;
        background-size: 100% auto; }

.filter-options-content .am-show-more,
.am-show-more {
  display: inline-block;
  padding: 0.1875rem 0.8125rem 0.1875rem 0.1875rem;
  margin: 0.3125rem 0 0;
  font-size: 13px;
  font-weight: 400;
  font-family: "silka-regular-webfont", sans-serif;
  color: #181818;
  text-decoration: underline;
  cursor: pointer;
  position: relative; }
  .filter-options-content .am-show-more:after,
  .am-show-more:after {
    display: block;
    content: '';
    width: 9px;
    height: 6px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("../images/icon-down-bold.svg");
    background-repeat: no-repeat;
    background-size: 100% auto; }
  .filter-options-content .am-show-more:hover, .filter-options-content .am-show-more:focus, .filter-options-content .am-show-more:active,
  .am-show-more:hover,
  .am-show-more:focus,
  .am-show-more:active {
    text-decoration: none; }
  .filter-options-content .am-show-more[data-is-hide="false"]:after,
  .am-show-more[data-is-hide="false"]:after {
    transform: translateY(-50%) rotate(180deg); }

.amshopby-search-box {
  /* clears the 'X' from Internet Explorer */ }
  .amshopby-search-box input[type="text"]::-ms-clear, .amshopby-search-box input[type="text"]::-ms-reveal,
  .amshopby-search-box input[type="search"]::-ms-clear,
  .amshopby-search-box input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0; }

.nav-toolbar-sorter-mobile .sorter-title,
.nav-toolbar-sorter-mobile .sorter-action {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.nav-toolbar-sorter-mobile ol,
.nav-toolbar-sorter-mobile ul {
  margin: 0;
  list-style: none;
  border-top: 1px solid rgba(24, 24, 24, 0.15); }
  .nav-toolbar-sorter-mobile ol li,
  .nav-toolbar-sorter-mobile ul li {
    padding: 0; }
    .nav-toolbar-sorter-mobile ol li:after,
    .nav-toolbar-sorter-mobile ul li:after {
      display: none; }
  .nav-toolbar-sorter-mobile ol .item,
  .nav-toolbar-sorter-mobile ul .item {
    border-bottom: 1px solid rgba(24, 24, 24, 0.15); }
    .nav-toolbar-sorter-mobile ol .item:last-child,
    .nav-toolbar-sorter-mobile ul .item:last-child {
      border-bottom: 0; }
    .nav-toolbar-sorter-mobile ol .item a,
    .nav-toolbar-sorter-mobile ul .item a {
      display: block;
      position: relative;
      padding: 0.625rem 3.125rem 0.625rem 0.5rem;
      padding-left: 2.1875rem;
      color: white;
      font-size: 1.1875rem;
      font-weight: 400;
      font-family: inherit;
      text-transform: none;
      background: url("../images/icon-arrow-next-gray.svg") calc(100% - 8px) 50% no-repeat;
      background-size: 7px auto; }
    .nav-toolbar-sorter-mobile ol .item.active,
    .nav-toolbar-sorter-mobile ul .item.active {
      font-weight: 700; }
      .nav-toolbar-sorter-mobile ol .item.active a:after,
      .nav-toolbar-sorter-mobile ul .item.active a:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 16px;
        height: 13px;
        background: url("../images/icon-check-white.svg") no-repeat;
        background-size: 100% auto; }

.mobile-filter-btn {
  width: 43%;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.82em 1em 0.87em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 50px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 1.1875rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #30A95E;
  color: #fff;
  font-family: "silka-semibold-webfont", sans-serif;
  letter-spacing: normal;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 0;
  background-color: #181818;
  color: #fff;
  margin-bottom: 0;
  margin-right: 2%; }
  [data-whatinput='mouse'] .mobile-filter-btn {
    outline: 0; }
  .mobile-filter-btn:hover, .mobile-filter-btn:focus {
    background-color: rgba(48, 169, 94, 0.75);
    color: #fff; }
  .mobile-filter-btn:active {
    color: rgba(255, 255, 255, 0.8) !important; }
  .mobile-filter-btn:hover, .mobile-filter-btn:focus {
    background-color: black;
    color: #fff; }
  .mobile-filter-btn.disabled {
    opacity: .15;
    cursor: not-allowed; }
  .mobile-filter-btn.open-filter-modal {
    width: 55%; }

.no-search-results {
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem;
  font-size: 1rem; }
  @media print, screen and (min-width: 40em) {
    .no-search-results {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .no-search-results:last-child:not(:first-child) {
    float: right; }

.products-grid .no-search-results {
  padding-left: 0;
  padding-right: 0; }

.algolia-instant-selector {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto; }
  .algolia-instant-selector::before, .algolia-instant-selector::after {
    display: table;
    content: ' '; }
  .algolia-instant-selector::after {
    clear: both; }

.algolia-instant-selector-results {
  display: none; }
  @media screen and (max-width: 47.9375em) {
    .algolia-instant-selector-results {
      margin-top: 1rem; }
      .algolia-instant-selector-results .category-header .top-controls-wrapper,
      .algolia-instant-selector-results .category-header .page-title-wrapper,
      .algolia-instant-selector-results .category-header .sorter-label {
        position: absolute !important;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0); }
      .algolia-instant-selector-results .page-main {
        position: relative; }
        .algolia-instant-selector-results .page-main .category-header {
          width: 48%;
          position: absolute;
          top: 0;
          right: 0; }
          .algolia-instant-selector-results .page-main .category-header .sorter {
            margin: 0; }
            .algolia-instant-selector-results .page-main .category-header .sorter select {
              height: 3.75rem; }
          .algolia-instant-selector-results .page-main .category-header .category-view {
            display: none; }
        .algolia-instant-selector-results .page-main .refine-toggle {
          width: 48%;
          position: absolute;
          top: 0;
          height: 3.75rem;
          left: 1.0625rem; } }
      @media screen and (max-width: 47.9375em) and (min-width: 40em) {
        .algolia-instant-selector-results .page-main .refine-toggle {
          left: 1.25rem; } }
  @media screen and (max-width: 47.9375em) {
        .algolia-instant-selector-results .page-main .sidebar.algolia-left-container {
          min-height: 3rem;
          margin-bottom: 0; }
          .algolia-instant-selector-results .page-main .sidebar.algolia-left-container .button.expanded {
            margin-bottom: 0; }
          .algolia-instant-selector-results .page-main .sidebar.algolia-left-container .algolia-current-refinements {
            margin-top: 1rem; }
        .algolia-instant-selector-results .page-main .instant-search-facets-container {
          margin-top: 4rem; } }

.algolia-instant-results-wrapper {
  margin-top: 0;
  transition: margin-top .3s ease; }
  .algolia-instant-results-wrapper::before, .algolia-instant-results-wrapper::after {
    display: table;
    content: ' '; }
  .algolia-instant-results-wrapper::after {
    clear: both; }
  .mobile-search-bar-open .algolia-instant-results-wrapper {
    margin-top: 5.5rem; }
    .mobile-search-bar-open .algolia-instant-results-wrapper .page-container {
      margin-top: 0 !important; }

.algolia-right-container {
  margin-top: 1rem; }
  @media print, screen and (min-width: 48em) {
    .algolia-right-container {
      margin-top: -11px; } }

.page-layout-0column .column.algolia-right-container, .page-layout-0column .algolia-right-container.columns,
.page-layout-1column .column.algolia-right-container,
.page-layout-1column .algolia-right-container.columns {
  padding-left: 1.0625rem;
  padding-right: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    .page-layout-0column .column.algolia-right-container, .page-layout-0column .algolia-right-container.columns,
    .page-layout-1column .column.algolia-right-container,
    .page-layout-1column .algolia-right-container.columns {
      padding-left: 1.25rem; } }
  @media print, screen and (min-width: 40em) {
    .page-layout-0column .column.algolia-right-container, .page-layout-0column .algolia-right-container.columns,
    .page-layout-1column .column.algolia-right-container,
    .page-layout-1column .algolia-right-container.columns {
      padding-right: 1.25rem; } }
  @media print, screen and (min-width: 40em) {
    .page-layout-0column .column.algolia-right-container, .page-layout-0column .algolia-right-container.columns,
    .page-layout-1column .column.algolia-right-container,
    .page-layout-1column .algolia-right-container.columns {
      width: 66.66667%; } }
  @media screen and (min-width: 64em) {
    .page-layout-0column .column.algolia-right-container, .page-layout-0column .algolia-right-container.columns,
    .page-layout-1column .column.algolia-right-container,
    .page-layout-1column .algolia-right-container.columns {
      width: 75%; } }

.algolia-instant-replaced-content.columns {
  padding-left: 0;
  padding-right: 0; }

.account .sidebar-main {
  margin-top: 0; }

.account.page-layout-2columns-left .main.column, .account.page-layout-2columns-left .main.columns {
  padding-left: 1.0625rem;
  padding-right: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    .account.page-layout-2columns-left .main.column, .account.page-layout-2columns-left .main.columns {
      padding-left: 1.25rem; } }
  @media print, screen and (min-width: 40em) {
    .account.page-layout-2columns-left .main.column, .account.page-layout-2columns-left .main.columns {
      padding-right: 1.25rem; } }

.account .main .block-title,
.account .main .page-subtitle {
  font-size: 1.40625rem;
  font-family: "silka-regular-webfont", sans-serif;
  letter-spacing: normal;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 1rem; }
  .account .main .block-title strong,
  .account .main .page-subtitle strong {
    font-weight: inherit; }
  .account .main .block-title .action,
  .account .main .page-subtitle .action {
    font-weight: 400;
    font-family: "silka-regular-webfont", sans-serif;
    font-size: 0.8125rem;
    margin-left: 1rem; }
  @media print, screen and (min-width: 48em) {
    .account .main .block-title,
    .account .main .page-subtitle {
      font-size: 1.875rem;
      margin-bottom: 2.25rem; } }

.account .main .legend + br {
  display: none; }

.account .page-title {
  padding-bottom: .75rem; }
  @media screen and (min-width: 75em) {
    .account .page-title {
      margin-bottom: 3.75rem; } }

.account .page-subtitle-wrapper {
  margin-bottom: 1rem; }
  @media print, screen and (min-width: 48em) {
    .account .page-subtitle-wrapper {
      margin-bottom: 2.25rem; } }
  .account .page-subtitle-wrapper .page-subtitle,
  .account .page-subtitle-wrapper .view-all-orders,
  .account .page-subtitle-wrapper .order-status {
    display: inline-block;
    vertical-align: baseline; }
  .account .page-subtitle-wrapper .page-subtitle {
    margin-bottom: 0; }
  .account .page-subtitle-wrapper .view-all-orders {
    margin-left: .5rem; }
  .account .page-subtitle-wrapper .order-status {
    vertical-align: super;
    margin-left: 1.25rem; }
    .account .page-subtitle-wrapper .order-status .label {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }
    .account .page-subtitle-wrapper .order-status .value {
      display: inline-block;
      line-height: 1;
      border: 1px solid rgba(24, 24, 24, 0.2);
      padding: .3rem 1rem;
      background: white; }

.account .box-title, .account .main .legend, .account .block-order-details-view .block-title, .block-order-details-view .account .block-title {
  position: relative;
  display: block;
  font-size: 1.03125rem;
  font-weight: 300;
  padding-bottom: .25rem;
  margin-bottom: 1rem;
  overflow: hidden;
  font-family: "silka-regular-webfont", sans-serif;
  letter-spacing: normal;
  position: relative; }
  .account .box-title:before, .account .main .legend:before, .account .block-order-details-view .block-title:before, .block-order-details-view .account .block-title:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 1.0625rem;
    right: 1.0625rem;
    visibility: visible;
    opacity: 1;
    display: block;
    border: 0;
    border-bottom: 1px solid rgba(24, 24, 24, 0.2); }
    @media print, screen and (min-width: 40em) {
      .account .box-title:before, .account .main .legend:before, .account .block-order-details-view .block-title:before, .block-order-details-view .account .block-title:before {
        left: 1.25rem; } }
    @media print, screen and (min-width: 40em) {
      .account .box-title:before, .account .main .legend:before, .account .block-order-details-view .block-title:before, .block-order-details-view .account .block-title:before {
        right: 1.25rem; } }
  .account .box-title:before, .account .main .legend:before, .account .block-order-details-view .block-title:before, .block-order-details-view .account .block-title:before {
    left: 0rem;
    right: 0rem; }
    @media print, screen and (min-width: 40em) {
      .account .box-title:before, .account .main .legend:before, .account .block-order-details-view .block-title:before, .block-order-details-view .account .block-title:before {
        left: 0rem; } }
    @media print, screen and (min-width: 40em) {
      .account .box-title:before, .account .main .legend:before, .account .block-order-details-view .block-title:before, .block-order-details-view .account .block-title:before {
        right: 0rem; } }
  @media print, screen and (min-width: 48em) {
    .account .box-title, .account .main .legend, .account .block-order-details-view .block-title, .block-order-details-view .account .block-title {
      font-size: 1.375rem;
      line-height: 1; } }
  @media screen and (min-width: 64em) {
    .account .box-title, .account .main .legend, .account .block-order-details-view .block-title, .block-order-details-view .account .block-title {
      line-height: 1.4; } }
  .account .box-title .text, .account .main .legend .text, .account .block-order-details-view .block-title .text, .block-order-details-view .account .block-title .text {
    margin-right: .5rem; }
    @media screen and (min-width: 64em) {
      .account .box-title .text, .account .main .legend .text, .account .block-order-details-view .block-title .text, .block-order-details-view .account .block-title .text {
        float: left;
        width: 60%;
        margin-right: 0; } }
  .account .box-title .action-box, .account .main .legend .action-box, .account .block-order-details-view .block-title .action-box, .block-order-details-view .account .block-title .action-box {
    white-space: nowrap; }
    @media screen and (min-width: 64em) {
      .account .box-title .action-box, .account .main .legend .action-box, .account .block-order-details-view .block-title .action-box, .block-order-details-view .account .block-title .action-box {
        float: left;
        width: 40%;
        text-align: right; } }
  .account .box-title .action, .account .main .legend .action, .account .block-order-details-view .block-title .action, .block-order-details-view .account .block-title .action {
    font-weight: 400;
    font-family: "silka-regular-webfont", sans-serif;
    font-size: 0.8125rem; }

.account .field {
  margin-bottom: 1.15rem; }

.account caption {
  display: none; }

.account label.label span:after, .account .checkout-steps .checkout-box legend.label span:after, .checkout-steps .checkout-box .account legend.label span:after, .account .checkout-steps .form-order-comment legend.label span:after, .checkout-steps .form-order-comment .account legend.label span:after, .account .checkout-steps .new-address-form legend.label span:after, .checkout-steps .new-address-form .account legend.label span:after, .account .shipping-address .action span:after, .shipping-address .account .action span:after, .account .modal-add-adress .street legend.label span:after, .modal-add-adress .street .account legend.label span:after {
  content: ':'; }

.account .actions-toolbar {
  margin-top: 1rem; }
  @media screen and (max-width: 47.9375em) {
    .account .actions-toolbar .action {
      display: block;
      width: 100%;
      margin-right: 0;
      margin-left: 0; } }
  .account .actions-toolbar .action.primary {
    min-width: 12.5rem; }
  .account .actions-toolbar .action.back {
    display: none; }

.account address {
  font-style: normal;
  line-height: 1.92; }

.account .field-over {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem; }
  .account .field-over::before, .account .field-over::after {
    display: table;
    content: ' '; }
  .account .field-over::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .account .field-over {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .account .field-over {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .account .field-over {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .account .field-over {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .account .field-over {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }

.account .field-row .field-over {
  margin-left: 0rem;
  margin-right: 0rem; }
  @media print, screen and (min-width: 40em) {
    .account .field-row .field-over {
      margin-left: 0rem; } }
  @media print, screen and (min-width: 40em) {
    .account .field-row .field-over {
      margin-right: 0rem; } }

.field.choice {
  line-height: 1.2;
  margin-bottom: .75rem;
  position: relative; }
  .field.choice input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0; }
    .field.choice input[type="checkbox"] + label,
    .field.choice input[type="checkbox"] + .label {
      cursor: pointer;
      font-weight: normal;
      position: relative;
      margin: 0;
      line-height: 1.2;
      min-height: 1rem;
      padding-left: 1.4rem; }
      .field.choice input[type="checkbox"] + label:before,
      .field.choice input[type="checkbox"] + .label:before {
        display: block;
        content: '';
        height: 1rem;
        width: 1rem;
        line-height: 1rem;
        text-align: center;
        position: absolute;
        top: -1px;
        left: 0;
        border: 1px solid #9DA3AF;
        color: #181818;
        background: white; }
    .field.choice input[type="checkbox"]:checked + label:before,
    .field.choice input[type="checkbox"]:checked + .label:before {
      content: '✓'; }
    .field.choice input[type="checkbox"]:disabled + label {
      opacity: .4; }
    .field.choice input[type="checkbox"]:disabled + label:before,
    .field.choice input[type="checkbox"]:disabled + .label:before {
      background: #eee; }
  .field.choice label.label span:after, .field.choice .checkout-steps .checkout-box legend.label span:after, .checkout-steps .checkout-box .field.choice legend.label span:after, .field.choice .checkout-steps .form-order-comment legend.label span:after, .checkout-steps .form-order-comment .field.choice legend.label span:after, .field.choice .checkout-steps .new-address-form legend.label span:after, .checkout-steps .new-address-form .field.choice legend.label span:after, .field.choice .shipping-address .action span:after, .shipping-address .field.choice .action span:after, .field.choice .modal-add-adress .street legend.label span:after, .modal-add-adress .street .field.choice legend.label span:after {
    display: none; }
  .field.choice.persistent .remember-me-link {
    margin-left: 4px;
    font-size: .85em;
    color: #181818; }

.box-actions .action,
.product-item-actions .actions-secondary .action,
.col.actions .action,
.order-actions-toolbar .action,
.addresses .item.actions .action,
.order-details-items .actions-toolbar .action,
.order-details-items .order-title .action {
  display: block;
  position: relative;
  margin: .25rem 0; }
  @media print, screen and (min-width: 40em) {
    .box-actions .action,
    .product-item-actions .actions-secondary .action,
    .col.actions .action,
    .order-actions-toolbar .action,
    .addresses .item.actions .action,
    .order-details-items .actions-toolbar .action,
    .order-details-items .order-title .action {
      display: inline;
      margin: 0; }
      .box-actions .action:after,
      .product-item-actions .actions-secondary .action:after,
      .col.actions .action:after,
      .order-actions-toolbar .action:after,
      .addresses .item.actions .action:after,
      .order-details-items .actions-toolbar .action:after,
      .order-details-items .order-title .action:after {
        display: inline-block;
        content: '|';
        color: #ccc;
        margin: 0 .5rem; }
      .box-actions .action:hover,
      .product-item-actions .actions-secondary .action:hover,
      .col.actions .action:hover,
      .order-actions-toolbar .action:hover,
      .addresses .item.actions .action:hover,
      .order-details-items .actions-toolbar .action:hover,
      .order-details-items .order-title .action:hover {
        color: #181818; }
      .box-actions .action:last-child:after,
      .product-item-actions .actions-secondary .action:last-child:after,
      .col.actions .action:last-child:after,
      .order-actions-toolbar .action:last-child:after,
      .addresses .item.actions .action:last-child:after,
      .order-details-items .actions-toolbar .action:last-child:after,
      .order-details-items .order-title .action:last-child:after {
        display: none; } }

div.account-nav,
.block-collapsible-nav {
  padding-top: 1.59375rem;
  padding-bottom: 1.59375rem;
  background-color: #F1EFED;
  box-shadow: 0 0 15px rgba(24, 24, 24, 0.1);
  margin-bottom: 2rem; }
  @media print, screen and (min-width: 40em) {
    div.account-nav,
    .block-collapsible-nav {
      padding-top: 1.875rem; } }
  @media print, screen and (min-width: 40em) {
    div.account-nav,
    .block-collapsible-nav {
      padding-bottom: 1.875rem; } }
  div.account-nav .title,
  .block-collapsible-nav .title {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }
  div.account-nav .nav,
  .block-collapsible-nav .nav {
    margin: 0;
    list-style: none;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.2; }
    div.account-nav .nav li,
    .block-collapsible-nav .nav li {
      padding: 0; }
      div.account-nav .nav li:after,
      .block-collapsible-nav .nav li:after {
        display: none; }
    div.account-nav .nav .item,
    .block-collapsible-nav .nav .item {
      position: relative;
      position: relative; }
      div.account-nav .nav .item:before,
      .block-collapsible-nav .nav .item:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 1.0625rem;
        right: 1.0625rem;
        visibility: visible;
        opacity: 1;
        display: block;
        border: 0;
        border-top: 1px solid #bababa;
        bottom: auto;
        top: 0; }
        @media print, screen and (min-width: 40em) {
          div.account-nav .nav .item:before,
          .block-collapsible-nav .nav .item:before {
            left: 1.25rem; } }
        @media print, screen and (min-width: 40em) {
          div.account-nav .nav .item:before,
          .block-collapsible-nav .nav .item:before {
            right: 1.25rem; } }
        @media print, screen and (min-width: 40em) {
          div.account-nav .nav .item:before,
          .block-collapsible-nav .nav .item:before {
            top: 0; } }
      div.account-nav .nav .item:first-child:before,
      .block-collapsible-nav .nav .item:first-child:before {
        display: none; }
      div.account-nav .nav .item:before,
      .block-collapsible-nav .nav .item:before {
        left: 1.5625rem;
        right: 1.5625rem; }
      div.account-nav .nav .item a,
      .block-collapsible-nav .nav .item a {
        display: block;
        padding: 0.625rem 1.5625rem;
        color: #181818;
        text-decoration: none; }
      div.account-nav .nav .item:hover a, div.account-nav .nav .item:focus a,
      .block-collapsible-nav .nav .item:hover a,
      .block-collapsible-nav .nav .item:focus a {
        color: #30A95E;
        background: #cfcfcf;
        text-decoration: none; }
      div.account-nav .nav .item:hover:before, div.account-nav .nav .item:focus:before,
      .block-collapsible-nav .nav .item:hover:before,
      .block-collapsible-nav .nav .item:focus:before {
        opacity: 0; }
      div.account-nav .nav .item:hover + .item:before, div.account-nav .nav .item:focus + .item:before,
      .block-collapsible-nav .nav .item:hover + .item:before,
      .block-collapsible-nav .nav .item:focus + .item:before {
        opacity: 0; }
      div.account-nav .nav .item.current:before,
      .block-collapsible-nav .nav .item.current:before {
        opacity: 0; }
      div.account-nav .nav .item.current + .item:before,
      .block-collapsible-nav .nav .item.current + .item:before {
        opacity: 0; }
      div.account-nav .nav .item.current strong,
      .block-collapsible-nav .nav .item.current strong {
        color: #30A95E;
        font-weight: 700;
        display: block;
        padding: 0.625rem 1.5625rem;
        background: #cfcfcf;
        text-decoration: none; }
      div.account-nav .nav .item.current a,
      .block-collapsible-nav .nav .item.current a {
        color: #30A95E;
        font-weight: 700;
        background: #cfcfcf;
        text-decoration: none; }
        div.account-nav .nav .item.current a strong,
        .block-collapsible-nav .nav .item.current a strong {
          padding: 0;
          background: none; }

.account .block-dashboard-info .block-title, .account
.block-dashboard-addresses .block-title {
  margin-bottom: 1rem; }

.block-dashboard-info .block-content,
.block-dashboard-addresses .block-content {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem; }
  .block-dashboard-info .block-content::before, .block-dashboard-info .block-content::after,
  .block-dashboard-addresses .block-content::before,
  .block-dashboard-addresses .block-content::after {
    display: table;
    content: ' '; }
  .block-dashboard-info .block-content::after,
  .block-dashboard-addresses .block-content::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .block-dashboard-info .block-content,
    .block-dashboard-addresses .block-content {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .block-dashboard-info .block-content,
    .block-dashboard-addresses .block-content {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .block-dashboard-info .block-content,
    .block-dashboard-addresses .block-content {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .block-dashboard-info .block-content,
    .block-dashboard-addresses .block-content {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .block-dashboard-info .block-content,
    .block-dashboard-addresses .block-content {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .block-dashboard-info .block-content .box,
  .block-dashboard-addresses .block-content .box {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    margin-bottom: 2rem; }
    @media print, screen and (min-width: 40em) {
      .block-dashboard-info .block-content .box,
      .block-dashboard-addresses .block-content .box {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .block-dashboard-info .block-content .box:last-child:not(:first-child),
    .block-dashboard-addresses .block-content .box:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 40em) {
      .block-dashboard-info .block-content .box,
      .block-dashboard-addresses .block-content .box {
        width: 50%; } }

.form-edit-account,
.form-address-edit {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem;
  max-width: initial; }
  .form-edit-account::before, .form-edit-account::after,
  .form-address-edit::before,
  .form-address-edit::after {
    display: table;
    content: ' '; }
  .form-edit-account::after,
  .form-address-edit::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .form-edit-account,
    .form-address-edit {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .form-edit-account,
    .form-address-edit {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .form-edit-account,
    .form-address-edit {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .form-edit-account,
    .form-address-edit {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .form-edit-account,
    .form-address-edit {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .form-edit-account .fieldset,
  .form-address-edit .fieldset {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    margin-bottom: 2rem; }
    @media print, screen and (min-width: 40em) {
      .form-edit-account .fieldset,
      .form-address-edit .fieldset {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .form-edit-account .fieldset:last-child:not(:first-child),
    .form-address-edit .fieldset:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 48em) {
      .form-edit-account .fieldset,
      .form-address-edit .fieldset {
        width: 50%;
        margin-top: -.25rem; } }
    .form-edit-account .fieldset:last-child,
    .form-address-edit .fieldset:last-child {
      margin-bottom: 0; }
    .form-edit-account .fieldset .field-column,
    .form-address-edit .fieldset .field-column {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem; }
      @media print, screen and (min-width: 40em) {
        .form-edit-account .fieldset .field-column,
        .form-address-edit .fieldset .field-column {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .form-edit-account .fieldset .field-column:last-child:not(:first-child),
      .form-address-edit .fieldset .field-column:last-child:not(:first-child) {
        float: right; }
      @media screen and (min-width: 64em) {
        .form-edit-account .fieldset .field-column,
        .form-address-edit .fieldset .field-column {
          width: 83.33333%; } }
  .form-edit-account .field-name-firstname,
  .form-edit-account .field-name-lastname,
  .form-address-edit .field-name-firstname,
  .form-address-edit .field-name-lastname {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .form-edit-account .field-name-firstname,
      .form-edit-account .field-name-lastname,
      .form-address-edit .field-name-firstname,
      .form-address-edit .field-name-lastname {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .form-edit-account .field-name-firstname:last-child:not(:first-child),
    .form-edit-account .field-name-lastname:last-child:not(:first-child),
    .form-address-edit .field-name-firstname:last-child:not(:first-child),
    .form-address-edit .field-name-lastname:last-child:not(:first-child) {
      float: right; }
    @media screen and (min-width: 64em) {
      .form-edit-account .field-name-firstname,
      .form-edit-account .field-name-lastname,
      .form-address-edit .field-name-firstname,
      .form-address-edit .field-name-lastname {
        width: 41.66667%; }
        .form-edit-account .field-name-firstname:last-child:last-child,
        .form-edit-account .field-name-lastname:last-child:last-child,
        .form-address-edit .field-name-firstname:last-child:last-child,
        .form-address-edit .field-name-lastname:last-child:last-child {
          float: left; } }
  .form-edit-account .field.zip,
  .form-address-edit .field.zip {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .form-edit-account .field.zip,
      .form-address-edit .field.zip {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .form-edit-account .field.zip:last-child:not(:first-child),
    .form-address-edit .field.zip:last-child:not(:first-child) {
      float: right; }
    @media screen and (min-width: 64em) {
      .form-edit-account .field.zip,
      .form-address-edit .field.zip {
        width: 33.33333%; } }
  .form-edit-account .field.city,
  .form-address-edit .field.city {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .form-edit-account .field.city,
      .form-address-edit .field.city {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .form-edit-account .field.city:last-child:not(:first-child),
    .form-address-edit .field.city:last-child:not(:first-child) {
      float: right; }
    @media screen and (min-width: 64em) {
      .form-edit-account .field.city,
      .form-address-edit .field.city {
        width: 66.66667%; } }
  @media screen and (min-width: 64em) {
    .form-edit-account .field.choice,
    .form-address-edit .field.choice {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem;
      padding-left: 0.53125rem;
      clear: both;
      margin-bottom: .75rem; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .form-edit-account .field.choice,
    .form-address-edit .field.choice {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  @media screen and (min-width: 64em) {
      .form-edit-account .field.choice:last-child:not(:first-child),
      .form-address-edit .field.choice:last-child:not(:first-child) {
        float: right; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .form-edit-account .field.choice,
    .form-address-edit .field.choice {
      padding-left: 0.625rem; } }
  .form-edit-account .field-name-firstname input,
  .form-edit-account .field-name-lastname input,
  .form-edit-account .field.city input,
  .form-edit-account .field.zip input,
  .form-address-edit .field-name-firstname input,
  .form-address-edit .field-name-lastname input,
  .form-address-edit .field.city input,
  .form-address-edit .field.zip input {
    margin-bottom: 0; }
  .form-edit-account .field-name-firstname .mage-error,
  .form-edit-account .field-name-lastname .mage-error,
  .form-edit-account .field.city .mage-error,
  .form-edit-account .field.zip .mage-error,
  .form-address-edit .field-name-firstname .mage-error,
  .form-address-edit .field-name-lastname .mage-error,
  .form-address-edit .field.city .mage-error,
  .form-address-edit .field.zip .mage-error {
    margin-top: 0.58333rem; }
  .form-edit-account .actions-toolbar,
  .form-address-edit .actions-toolbar {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .form-edit-account .actions-toolbar,
      .form-address-edit .actions-toolbar {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .form-edit-account .actions-toolbar:last-child:not(:first-child),
    .form-address-edit .actions-toolbar:last-child:not(:first-child) {
      float: right; }
  .form-edit-account .message.info,
  .form-address-edit .message.info {
    margin-bottom: 1.25rem; }

.form-newsletter-manage {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem;
  max-width: initial; }
  .form-newsletter-manage::before, .form-newsletter-manage::after {
    display: table;
    content: ' '; }
  .form-newsletter-manage::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .form-newsletter-manage {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .form-newsletter-manage {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .form-newsletter-manage {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .form-newsletter-manage {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .form-newsletter-manage {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .form-newsletter-manage .fieldset {
    margin-bottom: 2rem; }
  .form-newsletter-manage .fieldset,
  .form-newsletter-manage .actions-toolbar {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    clear: left; }
    @media print, screen and (min-width: 40em) {
      .form-newsletter-manage .fieldset,
      .form-newsletter-manage .actions-toolbar {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .form-newsletter-manage .fieldset:last-child:not(:first-child),
    .form-newsletter-manage .actions-toolbar:last-child:not(:first-child) {
      float: right; }
    .form-newsletter-manage .fieldset:last-child:last-child,
    .form-newsletter-manage .actions-toolbar:last-child:last-child {
      float: left; }
    @media print, screen and (min-width: 48em) {
      .form-newsletter-manage .fieldset,
      .form-newsletter-manage .actions-toolbar {
        width: 50%; } }

.form-wishlist-items .actions-toolbar .action.update {
  display: none; }

.form-wishlist-items .actions-toolbar .action {
  margin-bottom: .75rem;
  margin-right: 1.5rem; }
  @media screen and (max-width: 47.9375em) {
    .form-wishlist-items .actions-toolbar .action {
      margin-right: 0; } }

.form-wishlist-items .block-title {
  display: none; }

.form-wishlist-items table.cart .product-label,
.form-wishlist-items table.cart .convert-label-container {
  display: none !important; }

.form-giftcard-redeem .actions-toolbar .action.check {
  display: none; }

.storecredit {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem;
  max-width: initial; }
  .storecredit::before, .storecredit::after {
    display: table;
    content: ' '; }
  .storecredit::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .storecredit {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .storecredit {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .storecredit {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .storecredit {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .storecredit {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .storecredit .block {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    margin-bottom: 2rem; }
    @media print, screen and (min-width: 40em) {
      .storecredit .block {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .storecredit .block:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 40em) {
      .storecredit .block {
        width: 50%; } }
    .storecredit .block:last-child {
      margin-bottom: 0; }

.form-giftcard-redeem .fieldset {
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    .form-giftcard-redeem .fieldset {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .form-giftcard-redeem .fieldset:last-child:not(:first-child) {
    float: right; }
  @media print, screen and (min-width: 40em) {
    .form-giftcard-redeem .fieldset {
      width: 50%; } }

.form-giftcard-redeem .actions-toolbar {
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    .form-giftcard-redeem .actions-toolbar {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .form-giftcard-redeem .actions-toolbar:last-child:not(:first-child) {
    float: right; }
  .form-giftcard-redeem .actions-toolbar .primary,
  .form-giftcard-redeem .actions-toolbar .secondary {
    display: inline-block;
    vertical-align: top;
    margin-right: .5rem; }

.form-giftregistry-edit .fieldset {
  margin-bottom: 2rem; }
  .form-giftregistry-edit .fieldset:last-child {
    margin-bottom: 0; }

.table-wrapper.order-items + .actions-toolbar {
  display: none; }

@media print, screen and (min-width: 40em) {
  .table-order-items td.actions {
    width: 1%;
    white-space: nowrap; } }

.table-order-items .product-label,
.table-order-items .convert-label-container {
  display: none !important; }

.account .pager {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem;
  display: flex;
  align-items: flex-end;
  flex-flow: row wrap; }
  .account .pager::before, .account .pager::after {
    display: table;
    content: ' '; }
  .account .pager::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .account .pager {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .account .pager {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .account .pager {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .account .pager {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .account .pager {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .account .pager .toolbar-amount {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    text-align: center; }
    @media print, screen and (min-width: 40em) {
      .account .pager .toolbar-amount {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .account .pager .toolbar-amount:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 40em) {
      .account .pager .toolbar-amount {
        width: 50%;
        text-align: left; } }
  .account .pager .limiter {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    text-align: center; }
    @media print, screen and (min-width: 40em) {
      .account .pager .limiter {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .account .pager .limiter:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 40em) {
      .account .pager .limiter {
        width: 50%;
        text-align: right; } }
    .account .pager .limiter .limiter-options {
      display: inline-block;
      width: auto;
      margin-right: .25rem;
      margin-left: .25rem; }
  .account .pager .pages {
    order: 3;
    width: 100%;
    text-align: center;
    margin-top: 1rem; }
    .account .pager .pages .pages-label {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }
    .account .pager .pages .pages-items {
      margin: 0;
      list-style: none; }
      .account .pager .pages .pages-items li {
        padding: 0; }
        .account .pager .pages .pages-items li:after {
          display: none; }
      .account .pager .pages .pages-items .item {
        display: inline-block;
        vertical-align: middle;
        margin: 0 .25rem; }
      .account .pager .pages .pages-items .label {
        position: absolute !important;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0); }
    .account .pager .pages a {
      text-decoration: none; }
      .account .pager .pages a:hover, .account .pager .pages a:focus {
        text-decoration: underline; }

.block-reward-info,
.block-reward-history {
  margin-bottom: 2rem; }

.account .block-addresses-list .block-title {
  margin-bottom: 1rem; }

.block-addresses-list .addresses {
  margin: 0;
  list-style: none;
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem; }
  .block-addresses-list .addresses li {
    padding: 0; }
    .block-addresses-list .addresses li:after {
      display: none; }
  .block-addresses-list .addresses::before, .block-addresses-list .addresses::after {
    display: table;
    content: ' '; }
  .block-addresses-list .addresses::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .block-addresses-list .addresses {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .block-addresses-list .addresses {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .block-addresses-list .addresses {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .block-addresses-list .addresses {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .block-addresses-list .addresses {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .block-addresses-list .addresses > .item {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    margin-bottom: 2rem; }
    @media print, screen and (min-width: 40em) {
      .block-addresses-list .addresses > .item {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .block-addresses-list .addresses > .item:last-child:not(:first-child) {
      float: right; }
    .block-addresses-list .addresses > .item:last-child:last-child {
      float: left; }
    @media print, screen and (min-width: 48em) {
      .block-addresses-list .addresses > .item {
        width: 50%; } }
    @media screen and (min-width: 64em) {
      .block-addresses-list .addresses > .item {
        width: 33.33333%; } }

.account .block-addresses-default .block-title {
  margin-bottom: 1rem; }

.block-addresses-default .block-content {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem; }
  .block-addresses-default .block-content::before, .block-addresses-default .block-content::after {
    display: table;
    content: ' '; }
  .block-addresses-default .block-content::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .block-addresses-default .block-content {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .block-addresses-default .block-content {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .block-addresses-default .block-content {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .block-addresses-default .block-content {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .block-addresses-default .block-content {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .block-addresses-default .block-content .box {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    margin-bottom: 2rem; }
    @media print, screen and (min-width: 40em) {
      .block-addresses-default .block-content .box {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .block-addresses-default .block-content .box:last-child:not(:first-child) {
      float: right; }
    @media screen and (min-width: 64em) {
      .block-addresses-default .block-content .box {
        width: 50%; } }

.order-actions-toolbar {
  margin-bottom: 1rem; }

.sales-order-before-links {
  position: relative;
  z-index: 2; }
  .sales-order-before-links .actions-toolbar {
    margin-top: 0; }
    .sales-order-before-links .actions-toolbar .action {
      display: inline-block;
      vertical-align: middle;
      width: auto; }
  @media screen and (min-width: 64em) {
    .sales-order-before-links {
      float: right;
      padding-top: 0.625rem;
      text-align: right; }
      .sales-order-before-links .order-date,
      .sales-order-before-links .order-actions-toolbar {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0; }
      .sales-order-before-links .order-date {
        margin-right: 1rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .sales-order-shipment .sales-order-before-links {
      width: 100%;
      float: none;
      text-align: left;
      margin-bottom: 1rem;
      padding-top: 0; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .sales-order-shipment .sales-order-before-links {
      width: 42%;
      padding-top: 0;
      margin-top: -1rem; }
      .sales-order-shipment .sales-order-before-links .order-date {
        display: block;
        margin-right: 0; }
      .sales-order-shipment .sales-order-before-links .order-actions-toolbar .action:after {
        margin: 0 .1rem; } }
  .sales-order-before-links .label {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }

.order-links {
  margin: 0;
  list-style: none;
  position: relative;
  z-index: 1; }
  .order-links li {
    padding: 0; }
    .order-links li:after {
      display: none; }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .sales-order-shipment .order-links {
      width: 100%; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .sales-order-shipment .order-links {
      width: 58%; } }
  .order-links li {
    display: inline-block;
    vertical-align: top;
    border: 1px solid rgba(24, 24, 24, 0.2);
    margin-right: -1px;
    margin-bottom: -1px; }
    .order-links li.current {
      border-bottom-color: white; }
    .order-links li a,
    .order-links li strong {
      display: block;
      text-decoration: none;
      padding: 0.625rem; }
      @media screen and (min-width: 64em) {
        .order-links li a,
        .order-links li strong {
          padding: 0.625rem 1.25rem; } }

.block-order-details-comments {
  margin-bottom: 1.5rem; }
  .block-order-details-comments .block-title {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }

.order-details-items {
  width: 100%;
  clear: both;
  margin-bottom: 3rem;
  overflow-x: auto;
  border: 1px solid rgba(24, 24, 24, 0.2); }
  .order-details-items .actions-toolbar {
    margin-left: 2rem;
    margin-top: 1rem; }
  .order-details-items .order-tracking {
    margin: 1rem 2rem 0; }
  .order-details-items .order-title {
    margin: 1rem 2rem; }
    .order-details-items .order-title strong {
      display: block; }
  .order-details-items .product-item-name {
    display: block;
    margin-bottom: .25rem; }
  .order-details-items .item-options {
    font-size: 85%;
    line-height: 1.4; }
  .order-details-items .items-qty {
    margin: 0;
    list-style: none; }
    .order-details-items .items-qty li {
      padding: 0; }
      .order-details-items .items-qty li:after {
        display: none; }
    .order-details-items .items-qty li {
      white-space: nowrap; }
  .order-details-items table {
    margin-bottom: 0; }
    .order-details-items table tr td,
    .order-details-items table tr th {
      vertical-align: top;
      font-size: 0.875rem;
      font-weight: 400; }
      .order-details-items table tr td strong,
      .order-details-items table tr th strong {
        font-weight: inherit; }
      .order-details-items table tr td:first-child,
      .order-details-items table tr th:first-child {
        padding-left: 2rem; }
        @media screen and (max-width: 63.9375em) {
          .order-details-items table tr td:first-child,
          .order-details-items table tr th:first-child {
            padding-left: .75rem; }
            .order-details-items table tr td:first-child .product-image-container,
            .order-details-items table tr th:first-child .product-image-container {
              display: none; } }
      .order-details-items table tr td:last-child,
      .order-details-items table tr th:last-child {
        padding-right: 2rem; }
    .order-details-items table thead {
      border: 0;
      background: none; }
      .order-details-items table thead tr:first-child th {
        border-top: 0; }
      .order-details-items table thead tr th {
        font-size: 1rem;
        font-weight: 700;
        font-family: "silka-regular-webfont", sans-serif;
        letter-spacing: normal; }
    .order-details-items table tfoot {
      border: 0; }
      .order-details-items table tfoot td,
      .order-details-items table tfoot th {
        border: 0;
        padding-top: 0;
        padding-bottom: 0; }
      .order-details-items table tfoot th {
        text-align: right; }
      .order-details-items table tfoot td {
        text-align: left !important; }
      .order-details-items table tfoot tr:first-child th,
      .order-details-items table tfoot tr:first-child td {
        padding-top: 2rem; }
      .order-details-items table tfoot tr:last-child th,
      .order-details-items table tfoot tr:last-child td {
        padding-bottom: 2rem; }
      .order-details-items table tfoot .mark-inner {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        width: 9.375rem; }
        .order-details-items table tfoot .mark-inner:after {
          content: ':'; }
        .order-details-items table tfoot .mark-inner:after {
          margin-left: -3px; }
  .order-details-items .table-order-items .product-image-container {
    display: block; }
  .order-details-items .table-order-items .product-image-wrapper {
    padding-bottom: 0 !important; }

.block-order-details-view .block-title {
  padding-bottom: .75rem !important;
  margin-bottom: 1.5rem !important; }

.block-order-details-view .block-content {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem; }
  .block-order-details-view .block-content::before, .block-order-details-view .block-content::after {
    display: table;
    content: ' '; }
  .block-order-details-view .block-content::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .block-order-details-view .block-content {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .block-order-details-view .block-content {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .block-order-details-view .block-content {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .block-order-details-view .block-content {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .block-order-details-view .block-content {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .block-order-details-view .block-content .box {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    margin-bottom: 2rem; }
    @media print, screen and (min-width: 40em) {
      .block-order-details-view .block-content .box {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .block-order-details-view .block-content .box:last-child:not(:first-child) {
      float: right; }
    .block-order-details-view .block-content .box:last-child:last-child {
      float: left; }
    .block-order-details-view .block-content .box address {
      font-style: normal; }
    .block-order-details-view .block-content .box dt {
      font-weight: normal; }
    .block-order-details-view .block-content .box .box-title, .block-order-details-view .block-content .box .account .main .legend, .account .main .block-order-details-view .block-content .box .legend, .block-order-details-view .block-content .box .block-title {
      display: block;
      margin-bottom: .5rem;
      font-size: 1rem;
      font-weight: 700;
      margin-bottom: .5rem; }
      .block-order-details-view .block-content .box .box-title:before, .block-order-details-view .block-content .box .account .main .legend:before, .account .main .block-order-details-view .block-content .box .legend:before, .block-order-details-view .block-content .box .block-title:before {
        display: none; }
      @media screen and (min-width: 64em) {
        .block-order-details-view .block-content .box .box-title, .block-order-details-view .block-content .box .account .main .legend, .account .main .block-order-details-view .block-content .box .legend, .block-order-details-view .block-content .box .block-title {
          margin-bottom: 1.25rem; } }
    @media print, screen and (min-width: 40em) {
      .block-order-details-view .block-content .box {
        width: 50%; }
        .block-order-details-view .block-content .box:nth-child(odd) {
          clear: left; } }
    @media screen and (min-width: 64em) {
      .block-order-details-view .block-content .box {
        width: 25%; }
        .block-order-details-view .block-content .box:nth-child(odd) {
          clear: none; } }

.block-order-details-view .box-order-billing-method table {
  border-top: 1px solid rgba(24, 24, 24, 0.2); }
  .block-order-details-view .box-order-billing-method table tr th,
  .block-order-details-view .box-order-billing-method table tr td {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    border: 0;
    text-align: left; }
  .block-order-details-view .box-order-billing-method table tr th {
    margin-right: 3px; }
    .block-order-details-view .box-order-billing-method table tr th:after {
      content: ':'; }

.form.contact {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem;
  max-width: initial; }
  .form.contact::before, .form.contact::after {
    display: table;
    content: ' '; }
  .form.contact::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .form.contact {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .form.contact {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .form.contact {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .form.contact {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .form.contact {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .form.contact .legend,
  .form.contact br {
    display: none; }
  .form.contact .no-label {
    margin-bottom: 1rem; }
  .form.contact .fieldset,
  .form.contact .actions-toolbar {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .form.contact .fieldset,
      .form.contact .actions-toolbar {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .form.contact .fieldset:last-child:not(:first-child),
    .form.contact .actions-toolbar:last-child:not(:first-child) {
      float: right; }
  @media print, screen and (min-width: 48em) {
    .form.contact .fieldset {
      width: 66.66667%; } }
  @media screen and (min-width: 64em) {
    .form.contact .fieldset {
      width: 50%; } }

.shipping-tracking-popup .algolia-instant-selector-results,
.shipping-tracking-popup #launcher,
.shipping-tracking-popup .to-top {
  display: none !important; }

.shipping-tracking-popup .subtitle {
  margin-bottom: 1rem;
  font-size: 18px;
  font-family: "silka-regular-webfont", sans-serif;
  font-weight: 400; }

.shipping-tracking-popup .message {
  margin-bottom: 1rem; }

.page-print .algolia-instant-selector-results {
  display: none !important; }

.page-print .copyright,
.page-print .cookies,
.page-print .logo {
  display: block;
  padding-left: 1.0625rem;
  padding-right: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    .page-print .copyright,
    .page-print .cookies,
    .page-print .logo {
      padding-left: 1.25rem; } }
  @media print, screen and (min-width: 40em) {
    .page-print .copyright,
    .page-print .cookies,
    .page-print .logo {
      padding-right: 1.25rem; } }

.page-print .logo {
  margin-bottom: 20px; }

.page-print .order-status {
  margin-top: 10px; }

.page-print .order-details-items {
  overflow-x: hidden; }

.page-print .block-order-details-view {
  padding: 0 2rem;
  overflow: hidden; }

@media print {
  .page-print .product-label,
  .page-print .convert-label-container {
    display: none !important; } }

.customer-account-login .page-title-wrapper {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.login-container {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem;
  max-width: initial; }
  .login-container::before, .login-container::after {
    display: table;
    content: ' '; }
  .login-container::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .login-container {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .login-container {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .login-container {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .login-container {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .login-container {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .login-container .block-customer-login {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    margin-bottom: 2rem; }
    @media print, screen and (min-width: 40em) {
      .login-container .block-customer-login {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .login-container .block-customer-login:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 40em) {
      .login-container .block-customer-login {
        width: 50%; } }
  .login-container .block-new-customer,
  .login-container .block-new-company {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .login-container .block-new-customer,
      .login-container .block-new-company {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .login-container .block-new-customer:last-child:not(:first-child),
    .login-container .block-new-company:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 40em) {
      .login-container .block-new-customer,
      .login-container .block-new-company {
        width: 50%; } }
    .login-container .block-new-customer .action,
    .login-container .block-new-company .action {
      display: block;
      width: 100%;
      margin-right: 0;
      margin-left: 0; }
      @media print, screen and (min-width: 48em) {
        .login-container .block-new-customer .action,
        .login-container .block-new-company .action {
          width: auto;
          display: inline-block; } }
    .login-container .block-new-customer + .block-new-company,
    .login-container .block-new-company + .block-new-company {
      margin-top: 2rem;
      padding-top: 1.5rem;
      position: relative; }
      .login-container .block-new-customer + .block-new-company:before,
      .login-container .block-new-company + .block-new-company:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 1.0625rem;
        right: 1.0625rem;
        visibility: visible;
        opacity: 1;
        display: block;
        border: 0;
        border-top: 1px solid rgba(24, 24, 24, 0.15);
        bottom: auto;
        top: 0; }
        @media print, screen and (min-width: 40em) {
          .login-container .block-new-customer + .block-new-company:before,
          .login-container .block-new-company + .block-new-company:before {
            left: 1.25rem; } }
        @media print, screen and (min-width: 40em) {
          .login-container .block-new-customer + .block-new-company:before,
          .login-container .block-new-company + .block-new-company:before {
            right: 1.25rem; } }
        @media print, screen and (min-width: 40em) {
          .login-container .block-new-customer + .block-new-company:before,
          .login-container .block-new-company + .block-new-company:before {
            top: 0; } }
  .login-container .block-title {
    margin-bottom: 1rem;
    line-height: 1;
    text-align: center;
    font-weight: 300;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-style: normal;
    text-transform: none;
    font-size: 1.75rem; }
    @media print, screen and (min-width: 40em) {
      .login-container .block-title {
        font-size: 2.625rem; } }
    @media screen and (min-width: 64em) {
      .login-container .block-title {
        font-size: 2.875rem; } }
    @media screen and (min-width: 75em) {
      .login-container .block-title {
        margin-bottom: 1.5rem; } }
    .login-container .block-title strong {
      line-height: inherit;
      font-weight: inherit; }
  .login-container .note {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }

.form-login {
  padding: 1.25rem 1.5rem;
  background: #F1EFED;
  box-shadow: 0 0 10px rgba(24, 24, 24, 0.1); }
  @media screen and (min-width: 64em) {
    .form-login {
      padding: 2.25rem 2.5rem; } }
  .form-login .fieldset {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    margin-right: -1.0625rem;
    margin-left: -1.0625rem; }
    .form-login .fieldset::before, .form-login .fieldset::after {
      display: table;
      content: ' '; }
    .form-login .fieldset::after {
      clear: both; }
    @media print, screen and (min-width: 40em) {
      .form-login .fieldset {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .form-login .fieldset {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .form-login .fieldset {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .form-login .fieldset {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .form-login .fieldset {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
  .form-login .field {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    margin-bottom: .25rem; }
    @media print, screen and (min-width: 40em) {
      .form-login .field {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .form-login .field:last-child:not(:first-child) {
      float: right; }
    .form-login .field.note {
      margin-bottom: 0; }
    .form-login .field:not(.choice) .label:after {
      content: ':'; }
    @media screen and (min-width: 64em) {
      .form-login .field {
        width: 50%;
        margin-bottom: .75rem; } }
  .form-login .actions-toolbar {
    clear: both; }
    .form-login .actions-toolbar::before, .form-login .actions-toolbar::after {
      display: table;
      content: ' '; }
    .form-login .actions-toolbar::after {
      clear: both; }
    .form-login .actions-toolbar div.primary {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem; }
      @media print, screen and (min-width: 40em) {
        .form-login .actions-toolbar div.primary {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .form-login .actions-toolbar div.primary:last-child:not(:first-child) {
        float: right; }
      .form-login .actions-toolbar div.primary .action {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-left: 0; }
      @media print, screen and (min-width: 48em) {
        .form-login .actions-toolbar div.primary {
          width: 50%; }
          .form-login .actions-toolbar div.primary .action {
            width: auto;
            display: inline-block; } }
    .form-login .actions-toolbar div.secondary {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem;
      text-align: center;
      margin-top: 1rem; }
      @media print, screen and (min-width: 40em) {
        .form-login .actions-toolbar div.secondary {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .form-login .actions-toolbar div.secondary:last-child:not(:first-child) {
        float: right; }
      .form-login .actions-toolbar div.secondary .action {
        display: inline-block;
        vertical-align: middle;
        line-height: 1.2; }
      @media print, screen and (min-width: 48em) {
        .form-login .actions-toolbar div.secondary {
          width: 50%;
          text-align: right;
          line-height: 3.75rem;
          margin-top: 0; } }

.form-create-account {
  margin-bottom: 3rem; }
  .form-create-account .actions-toolbar {
    text-align: center;
    margin: 2rem 0 2.5rem; }
    .form-create-account .actions-toolbar .submit {
      min-width: 14.375rem; }
    .form-create-account .actions-toolbar .secondary {
      display: none; }
  .form-create-account .check-toolbar {
    margin-top: 1rem;
    margin-bottom: 2.5rem; }
    @media print, screen and (min-width: 48em) {
      .form-create-account .check-toolbar {
        margin-top: 0; } }
    .form-create-account .check-toolbar .choice {
      line-height: 1.2;
      margin-bottom: .75rem;
      position: relative; }
      .form-create-account .check-toolbar .choice input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0; }
        .form-create-account .check-toolbar .choice input[type="checkbox"] + label,
        .form-create-account .check-toolbar .choice input[type="checkbox"] + .label {
          cursor: pointer;
          font-weight: normal;
          position: relative;
          margin: 0;
          line-height: 1.2;
          min-height: 1rem;
          padding-left: 1.4rem; }
          .form-create-account .check-toolbar .choice input[type="checkbox"] + label:before,
          .form-create-account .check-toolbar .choice input[type="checkbox"] + .label:before {
            display: block;
            content: '';
            height: 1rem;
            width: 1rem;
            line-height: 1rem;
            text-align: center;
            position: absolute;
            top: -1px;
            left: 0;
            border: 1px solid #9DA3AF;
            color: #181818;
            background: white; }
        .form-create-account .check-toolbar .choice input[type="checkbox"]:checked + label:before,
        .form-create-account .check-toolbar .choice input[type="checkbox"]:checked + .label:before {
          content: '✓'; }
        .form-create-account .check-toolbar .choice input[type="checkbox"]:disabled + label {
          opacity: .4; }
        .form-create-account .check-toolbar .choice input[type="checkbox"]:disabled + label:before,
        .form-create-account .check-toolbar .choice input[type="checkbox"]:disabled + .label:before {
          background: #eee; }
      .form-create-account .check-toolbar .choice label:after {
        display: none; }
  .form-create-account .fieldset.create .check-toolbar {
    display: none; }
  .form-create-account .field.choice.consent .newsletter-terms, .form-create-account .field.choice.newsletter .newsletter-terms {
    margin-top: .5rem; }
  .form-create-account .field.choice.consent div.mage-error, .form-create-account .field.choice.newsletter div.mage-error {
    margin: .15rem 0 .5rem 1.4rem; }

.customer-account-create .page-title-wrapper,
.company-account-create .page-title-wrapper {
  margin-bottom: 1rem; }
  .customer-account-create .page-title-wrapper .page-title,
  .company-account-create .page-title-wrapper .page-title {
    text-align: center; }
  .customer-account-create .page-title-wrapper:before,
  .company-account-create .page-title-wrapper:before {
    display: none; }
  @media print, screen and (min-width: 48em) {
    .customer-account-create .page-title-wrapper,
    .company-account-create .page-title-wrapper {
      margin-bottom: .65rem; } }

.customer-account-create .page.messages,
.company-account-create .page.messages {
  margin-bottom: 0; }

.b-account-register {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem;
  max-width: initial; }
  .b-account-register::before, .b-account-register::after {
    display: table;
    content: ' '; }
  .b-account-register::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .b-account-register {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .b-account-register {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .b-account-register {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .b-account-register {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .b-account-register {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .b-account-register .account-type-sidebar,
  .b-account-register .account-type-fields,
  .b-account-register .account-register-help {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .b-account-register .account-type-sidebar,
      .b-account-register .account-type-fields,
      .b-account-register .account-register-help {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .b-account-register .account-type-sidebar:last-child:not(:first-child),
    .b-account-register .account-type-fields:last-child:not(:first-child),
    .b-account-register .account-register-help:last-child:not(:first-child) {
      float: right; }
    .b-account-register .account-type-sidebar .title,
    .b-account-register .account-type-fields .title,
    .b-account-register .account-register-help .title {
      font-family: "silka-regular-webfont", sans-serif;
      letter-spacing: normal;
      font-size: 1.375rem;
      font-weight: 500;
      margin-bottom: 1.25rem; }
  .b-account-register .account-type-fields {
    margin-top: 0;
    margin-right: auto;
    margin-left: auto; }
    .b-account-register .account-type-fields, .b-account-register .account-type-fields:last-child:not(:first-child) {
      float: none;
      clear: both; }
    .b-account-register .account-type-fields .label:after {
      display: none; }
  @media print, screen and (min-width: 48em) {
    .b-account-register .account-type-sidebar {
      width: 29.16667%; } }
  @media screen and (min-width: 64em) {
    .b-account-register .account-type-sidebar {
      margin-top: 2rem;
      width: 25%; } }
  .b-account-register .account-type-sidebar .title {
    margin-bottom: .25rem; }
  .b-account-register .account-type-sidebar .link {
    line-height: 1.3; }
  .b-account-register .account-type-sidebar .fieldset {
    position: relative;
    padding: 1.5rem 23px 1.9rem;
    margin-left: -23px;
    margin-right: -23px;
    margin-bottom: 1.75rem;
    background-color: #F1EFED; }
    @media print, screen and (min-width: 40em) {
      .b-account-register .account-type-sidebar .fieldset {
        margin-left: 0;
        margin-right: 0; } }
    @media screen and (min-width: 75em) {
      .b-account-register .account-type-sidebar .fieldset {
        padding: 1.5rem 2rem 2.25rem;
        margin-bottom: 2rem; } }
  .b-account-register .account-type-sidebar .field-list-account-type {
    margin: 1.25rem 0 0;
    position: relative; }
    @media screen and (min-width: 64em) {
      .b-account-register .account-type-sidebar .field-list-account-type {
        margin-left: .25rem; } }
  .b-account-register .account-type-sidebar .field-account-type {
    line-height: 1.2;
    margin-bottom: .75rem;
    position: relative;
    margin-bottom: 1rem; }
    .b-account-register .account-type-sidebar .field-account-type input[type="radio"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0; }
      .b-account-register .account-type-sidebar .field-account-type input[type="radio"] + label,
      .b-account-register .account-type-sidebar .field-account-type input[type="radio"] + .label {
        cursor: pointer;
        font-weight: normal;
        position: relative;
        margin: 0;
        line-height: 1.2;
        min-height: 1rem;
        padding-left: 1.4rem; }
        .b-account-register .account-type-sidebar .field-account-type input[type="radio"] + label:before,
        .b-account-register .account-type-sidebar .field-account-type input[type="radio"] + .label:before {
          display: block;
          content: '';
          height: 1rem;
          width: 1rem;
          line-height: 1rem;
          text-align: center;
          position: absolute;
          top: -1px;
          left: 0;
          border-radius: 50%;
          border: 1px solid #9DA3AF;
          color: #181818;
          background: white;
          z-index: 0; }
        .b-account-register .account-type-sidebar .field-account-type input[type="radio"] + label:after,
        .b-account-register .account-type-sidebar .field-account-type input[type="radio"] + .label:after {
          display: none;
          content: '';
          position: absolute;
          top: 0.125rem;
          left: 0.1875rem;
          height: 0.625rem;
          width: 0.625rem;
          border-radius: 50%;
          background: #1A2746; }
      .b-account-register .account-type-sidebar .field-account-type input[type="radio"]:checked + label:after,
      .b-account-register .account-type-sidebar .field-account-type input[type="radio"]:checked + .label:after {
        display: block; }
      .b-account-register .account-type-sidebar .field-account-type input[type="radio"]:disabled + label:before,
      .b-account-register .account-type-sidebar .field-account-type input[type="radio"]:disabled + .label:before {
        opacity: .3;
        background: #eee; }
    .b-account-register .account-type-sidebar .field-account-type .label span:after {
      display: none; }
    .b-account-register .account-type-sidebar .field-account-type:last-child {
      margin-bottom: 0; }
  @media print, screen and (min-width: 48em) {
    .b-account-register .account-type-fields {
      padding-top: 1.5rem;
      width: 41.66667%; } }
  @media screen and (min-width: 64em) {
    .b-account-register .account-type-fields {
      margin-top: 2rem;
      width: 50%; } }
  .b-account-register .account-type-fields .label:after {
    content: ':'; }
  @media screen and (min-width: 64em) {
    .b-account-register .account-type-fields .field-row {
      margin-bottom: .85rem; } }
  .b-account-register .account-type-fields .fieldset-list .fieldset {
    padding-bottom: 1.25rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid rgba(24, 24, 24, 0.2); }
    .b-account-register .account-type-fields .fieldset-list .fieldset:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: .5rem; }
  @media print, screen and (min-width: 48em) {
    .b-account-register .account-register-help {
      width: 29.16667%; } }
  @media screen and (min-width: 64em) {
    .b-account-register .account-register-help {
      width: 25%; } }
  .b-account-register .account-register-help .customer-form-after {
    text-align: center;
    position: relative;
    margin-left: -23px;
    margin-right: -23px;
    margin-bottom: 1.75rem;
    background-color: #F1EFED; }
    @media print, screen and (min-width: 40em) {
      .b-account-register .account-register-help .customer-form-after {
        margin-left: 0;
        margin-right: 0; } }
    .b-account-register .account-register-help .customer-form-after .help-block {
      padding: 1.5rem 23px 1.9rem; }
      @media print, screen and (min-width: 48em) {
        .b-account-register .account-register-help .customer-form-after .help-block {
          padding: 1.5rem 1.25rem 1.75rem; } }
      @media screen and (min-width: 64em) {
        .b-account-register .account-register-help .customer-form-after .help-block {
          padding: 1.5rem 1.75rem 2.25rem; } }
    .b-account-register .account-register-help .customer-form-after h2,
    .b-account-register .account-register-help .customer-form-after .title {
      font-family: "silka-regular-webfont", sans-serif;
      letter-spacing: normal;
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: .75rem; }
      @media screen and (min-width: 64em) {
        .b-account-register .account-register-help .customer-form-after h2,
        .b-account-register .account-register-help .customer-form-after .title {
          font-size: 1.75rem; } }
    .b-account-register .account-register-help .customer-form-after p {
      margin-bottom: 0;
      line-height: 1.46;
      color: rgba(24, 24, 24, 0.7); }
  .b-account-register .field-row {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    margin-right: -1.0625rem;
    margin-left: -1.0625rem; }
    .b-account-register .field-row::before, .b-account-register .field-row::after {
      display: table;
      content: ' '; }
    .b-account-register .field-row::after {
      clear: both; }
    @media print, screen and (min-width: 40em) {
      .b-account-register .field-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .b-account-register .field-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .b-account-register .field-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .b-account-register .field-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .b-account-register .field-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    .b-account-register .field-row .field {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem; }
      @media print, screen and (min-width: 40em) {
        .b-account-register .field-row .field {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .b-account-register .field-row .field:last-child:not(:first-child) {
        float: right; }
      @media screen and (min-width: 64em) {
        .b-account-register .field-row .field {
          width: 50%; }
          .b-account-register .field-row .field.telephone.short {
            width: 37.5%; }
          .b-account-register .field-row .field.zip {
            width: 33.33333%; }
          .b-account-register .field-row .field.city {
            width: 66.66667%; } }

.customer-dob {
  position: relative; }
  .customer-dob input {
    width: calc(100% - 50px); }
  .customer-dob .v-middle {
    position: absolute;
    width: 50px;
    right: 0;
    top: 0;
    height: 3.75rem;
    border: 1px solid #9DA3AF;
    border-left: 0;
    background: #eee url("../images/icon-calendar.svg") 50% no-repeat; }
    .customer-dob .v-middle span {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }

.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
  top: 50%;
  transform: translateY(-50%); }
  .ui-datepicker .ui-datepicker-next span,
  .ui-datepicker .ui-datepicker-prev span {
    margin: 0;
    top: auto;
    left: auto;
    right: 0;
    border-width: 10px 0 10px 16px;
    border-color: transparent transparent transparent #1A2746; }

.ui-datepicker .ui-datepicker-prev span {
  right: auto;
  left: 0;
  border-width: 10px 16px 10px 0;
  border-color: transparent #1A2746 transparent transparent; }

.ui-datepicker .ui-datepicker-header {
  min-width: 250px; }

.ui-datepicker .ui-datepicker-calendar {
  margin-bottom: 0; }
  .ui-datepicker .ui-datepicker-calendar th {
    padding: .25rem; }
  .ui-datepicker .ui-datepicker-calendar td {
    padding: 0; }
    .ui-datepicker .ui-datepicker-calendar td .ui-state-hover {
      color: white;
      background: #1A2746; }
  .ui-datepicker .ui-datepicker-calendar .ui-state-active {
    color: white;
    background: #1A2746; }

.field-account-type-info {
  display: inline-block;
  vertical-align: middle;
  margin-left: .2rem; }
  @media print, screen and (min-width: 48em) {
    .field-account-type-info {
      position: relative; } }
  .field-account-type-info:hover .field-account-text, .field-account-type-info:active .field-account-text {
    display: block;
    z-index: 1; }
  .field-account-type-info .field-account-text {
    display: none;
    width: 17.5rem;
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: .5rem;
    margin-bottom: .5rem;
    box-shadow: 0 0 3px rgba(24, 24, 24, 0.5);
    font-size: 0.6875rem;
    line-height: 1.1;
    background: white; }
    @media print, screen and (min-width: 48em) {
      .field-account-type-info .field-account-text {
        left: 100%;
        margin-bottom: 0; } }

.field.new.password .control {
  display: flex;
  flex-direction: column; }
  .field.new.password .control .mage-error {
    order: 2;
    margin-bottom: 0.58333rem; }
  .field.new.password .control .input-text {
    order: 0; }

.password-strength-meter {
  background-color: #eee;
  height: 1.72619rem;
  line-height: 1.72619rem;
  padding: 0 0.5rem;
  position: relative;
  font-size: 0.625rem;
  margin-top: -0.35rem;
  margin-bottom: .25rem;
  z-index: 1; }
  .password-strength-meter:before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1; }
  .password-strength-meter-0 .password-strength-meter:before {
    background-color: #eee;
    width: 100%; }
  .password-strength-meter-1 .password-strength-meter:before {
    background-color: #ffafae;
    width: 25%; }
  .password-strength-meter-2 .password-strength-meter:before {
    background-color: #ffd6b3;
    width: 50%; }
  .password-strength-meter-3 .password-strength-meter:before {
    background-color: #c5eeac;
    width: 75%; }
  .password-strength-meter-4 .password-strength-meter:before {
    background-color: #81b562;
    width: 100%; }

.form.password.forget,
.form.password.reset {
  padding: 1.25rem 1.5rem;
  background: #F1EFED; }
  @media print, screen and (min-width: 40em) {
    .form.password.forget,
    .form.password.reset {
      width: 75%; } }
  @media screen and (min-width: 64em) {
    .form.password.forget,
    .form.password.reset {
      width: 50%;
      padding: 2.25rem 2.5rem; } }
  .form.password.forget .field,
  .form.password.reset .field {
    margin-bottom: .25rem; }
    @media screen and (min-width: 64em) {
      .form.password.forget .field,
      .form.password.reset .field {
        margin-bottom: .75rem; } }
  .form.password.forget .label:after,
  .form.password.reset .label:after {
    content: ':'; }
  .form.password.forget .actions-toolbar,
  .form.password.reset .actions-toolbar {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    margin-right: -1.0625rem;
    margin-left: -1.0625rem; }
    .form.password.forget .actions-toolbar::before, .form.password.forget .actions-toolbar::after,
    .form.password.reset .actions-toolbar::before,
    .form.password.reset .actions-toolbar::after {
      display: table;
      content: ' '; }
    .form.password.forget .actions-toolbar::after,
    .form.password.reset .actions-toolbar::after {
      clear: both; }
    @media print, screen and (min-width: 40em) {
      .form.password.forget .actions-toolbar,
      .form.password.reset .actions-toolbar {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .form.password.forget .actions-toolbar,
      .form.password.reset .actions-toolbar {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .form.password.forget .actions-toolbar,
      .form.password.reset .actions-toolbar {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .form.password.forget .actions-toolbar,
      .form.password.reset .actions-toolbar {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .form.password.forget .actions-toolbar,
      .form.password.reset .actions-toolbar {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    .form.password.forget .actions-toolbar div.primary,
    .form.password.reset .actions-toolbar div.primary {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem; }
      @media print, screen and (min-width: 40em) {
        .form.password.forget .actions-toolbar div.primary,
        .form.password.reset .actions-toolbar div.primary {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .form.password.forget .actions-toolbar div.primary:last-child:not(:first-child),
      .form.password.reset .actions-toolbar div.primary:last-child:not(:first-child) {
        float: right; }
      .form.password.forget .actions-toolbar div.primary .action,
      .form.password.reset .actions-toolbar div.primary .action {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-left: 0; }
      @media print, screen and (min-width: 48em) {
        .form.password.forget .actions-toolbar div.primary,
        .form.password.reset .actions-toolbar div.primary {
          width: 50%; }
          .form.password.forget .actions-toolbar div.primary .action,
          .form.password.reset .actions-toolbar div.primary .action {
            width: auto;
            display: inline-block; } }
    .form.password.forget .actions-toolbar .secondary,
    .form.password.reset .actions-toolbar .secondary {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem;
      text-align: center;
      margin-top: 1rem; }
      @media print, screen and (min-width: 40em) {
        .form.password.forget .actions-toolbar .secondary,
        .form.password.reset .actions-toolbar .secondary {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .form.password.forget .actions-toolbar .secondary:last-child:not(:first-child),
      .form.password.reset .actions-toolbar .secondary:last-child:not(:first-child) {
        float: right; }
      .form.password.forget .actions-toolbar .secondary .action,
      .form.password.reset .actions-toolbar .secondary .action {
        display: inline-block;
        vertical-align: middle;
        line-height: 1.2; }
      @media print, screen and (min-width: 48em) {
        .form.password.forget .actions-toolbar .secondary,
        .form.password.reset .actions-toolbar .secondary {
          width: 50%;
          text-align: right;
          line-height: 3.75rem;
          margin-top: 0; } }

.form-orders-search {
  padding: 1.25rem 1.5rem;
  background: #F1EFED;
  margin-bottom: 2rem; }
  @media screen and (min-width: 64em) {
    .form-orders-search {
      width: 75%;
      padding: 2.25rem 2.5rem; } }
  @media screen and (min-width: 75em) {
    .form-orders-search {
      width: 50%; } }
  .form-orders-search legend {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }
  .form-orders-search br {
    display: none; }
  .form-orders-search .fieldset {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    margin-right: -1.0625rem;
    margin-left: -1.0625rem; }
    .form-orders-search .fieldset::before, .form-orders-search .fieldset::after {
      display: table;
      content: ' '; }
    .form-orders-search .fieldset::after {
      clear: both; }
    @media print, screen and (min-width: 40em) {
      .form-orders-search .fieldset {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .form-orders-search .fieldset {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .form-orders-search .fieldset {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .form-orders-search .fieldset {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .form-orders-search .fieldset {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
  .form-orders-search .field {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    margin-bottom: .25rem; }
    @media print, screen and (min-width: 40em) {
      .form-orders-search .field {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .form-orders-search .field:last-child:not(:first-child) {
      float: right; }
    .form-orders-search .field.note {
      margin-bottom: 0; }
    @media print, screen and (min-width: 40em) {
      .form-orders-search .field {
        width: 50%;
        margin-bottom: .75rem; } }
  .form-orders-search .label:after {
    content: ':'; }
  .form-orders-search .action {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
    @media print, screen and (min-width: 48em) {
      .form-orders-search .action {
        width: auto;
        display: inline-block; } }

@media screen and (max-width: 47.9375em) {
  .checkout-cart-index .page-title-wrapper:not(.product) {
    padding-bottom: 0;
    margin-bottom: 0; }
    .checkout-cart-index .page-title-wrapper:not(.product):before {
      display: none; } }

.cart-container {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem;
  max-width: initial; }
  .cart-container::before, .cart-container::after {
    display: table;
    content: ' '; }
  .cart-container::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .cart-container {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .cart-container {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .cart-container {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .cart-container {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .cart-container {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .cart-container .table-caption {
    display: none; }
  .cart-container .page-title-wrapper {
    margin-bottom: .5rem; }
    @media screen and (max-width: 47.9375em) {
      .cart-container .page-title-wrapper {
        padding-bottom: 0; }
        .cart-container .page-title-wrapper .page-title {
          font-size: 1.75rem; }
        .cart-container .page-title-wrapper:before {
          display: none; } }
    .cart-container .page-title-wrapper + .checkout-methods-items {
      margin-top: .5rem; }
      .cart-container .page-title-wrapper + .checkout-methods-items .item {
        margin-bottom: 0; }

.cart-form {
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    .cart-form {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .cart-form:last-child:not(:first-child) {
    float: right; }
  @media print, screen and (min-width: 48em) {
    .cart-form {
      width: 50%; } }
  @media screen and (min-width: 64em) {
    .cart-form {
      width: 65.83333%; } }

.cart-summary {
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem;
  margin-bottom: 2rem; }
  @media print, screen and (min-width: 40em) {
    .cart-summary {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .cart-summary:last-child:not(:first-child) {
    float: right; }
  @media print, screen and (min-width: 48em) {
    .cart-summary {
      width: 50%;
      float: right; } }
  @media screen and (min-width: 64em) {
    .cart-summary {
      width: 34.16667%; } }
  .cart-summary .summary.title {
    display: none;
    font-size: 2rem;
    font-weight: 600;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    padding: 0.65rem 0 0.55rem; }
  .cart-summary .message {
    margin-bottom: .5rem; }
  .cart-summary .methods .item-title {
    display: block;
    font-size: 1.375rem;
    font-weight: 600;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    margin-bottom: .4rem; }
  .cart-summary .field.choice {
    line-height: 1.2;
    margin-bottom: .75rem;
    position: relative;
    margin-bottom: 0; }
    .cart-summary .field.choice input[type="radio"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0; }
      .cart-summary .field.choice input[type="radio"] + label,
      .cart-summary .field.choice input[type="radio"] + .label {
        cursor: pointer;
        font-weight: normal;
        position: relative;
        margin: 0;
        line-height: 1.2;
        min-height: 1rem;
        padding-left: 1.4rem; }
        .cart-summary .field.choice input[type="radio"] + label:before,
        .cart-summary .field.choice input[type="radio"] + .label:before {
          display: block;
          content: '';
          height: 1rem;
          width: 1rem;
          line-height: 1rem;
          text-align: center;
          position: absolute;
          top: -1px;
          left: 0;
          border-radius: 50%;
          border: 1px solid #9DA3AF;
          color: #181818;
          background: white;
          z-index: 0; }
        .cart-summary .field.choice input[type="radio"] + label:after,
        .cart-summary .field.choice input[type="radio"] + .label:after {
          display: none;
          content: '';
          position: absolute;
          top: 0.125rem;
          left: 0.1875rem;
          height: 0.625rem;
          width: 0.625rem;
          border-radius: 50%;
          background: #1A2746; }
      .cart-summary .field.choice input[type="radio"]:checked + label:after,
      .cart-summary .field.choice input[type="radio"]:checked + .label:after {
        display: block; }
      .cart-summary .field.choice input[type="radio"]:disabled + label:before,
      .cart-summary .field.choice input[type="radio"]:disabled + .label:before {
        opacity: .3;
        background: #eee; }
  .cart-summary table {
    margin: 0;
    table-layout: fixed; }
    .cart-summary table tbody,
    .cart-summary table thead,
    .cart-summary table tfoot {
      border: 0;
      background: none; }
    .cart-summary table td,
    .cart-summary table th {
      border: 0;
      padding: 0 0 .5rem;
      line-height: 1.3;
      font-weight: 400;
      text-align: left;
      vertical-align: middle;
      background: none; }
      @media screen and (min-width: 75em) {
        .cart-summary table td,
        .cart-summary table th {
          padding-bottom: .9rem; } }
    .cart-summary table td {
      padding-left: .5rem;
      text-align: right; }
      .cart-summary table td.amount {
        width: 100%; }
    .cart-summary table tr:last-child td,
    .cart-summary table tr:last-child th {
      padding-bottom: 0; }
    .cart-summary table .totals.sub td,
    .cart-summary table .totals.sub th {
      font-size: 0.86063rem;
      text-transform: uppercase;
      font-family: "silka-regular-webfont", sans-serif;
      letter-spacing: normal;
      font-size: 0.79688rem;
      font-weight: 300; }
      @media screen and (min-width: 75em) {
        .cart-summary table .totals.sub td,
        .cart-summary table .totals.sub th {
          font-size: 1.0625rem; } }
    .cart-summary table .totals.grand {
      font-family: "silka-regular-webfont", sans-serif;
      letter-spacing: normal;
      border-top: 1px solid #fefefe;
      text-transform: uppercase; }
      .cart-summary table .totals.grand td,
      .cart-summary table .totals.grand th {
        padding-top: .5rem;
        font-weight: 400;
        font-size: 0.79688rem;
        font-family: "silka-regular-webfont", sans-serif; }
        @media screen and (min-width: 75em) {
          .cart-summary table .totals.grand td,
          .cart-summary table .totals.grand th {
            font-size: 1.25rem; } }
        .cart-summary table .totals.grand td strong,
        .cart-summary table .totals.grand th strong {
          font-weight: inherit; }
      .cart-summary table .totals.grand .amount {
        line-height: 1;
        font-size: 0.9375rem; }
      @media screen and (min-width: 75em) {
        .cart-summary table .totals.grand td,
        .cart-summary table .totals.grand th {
          padding-top: 1.25rem; }
        .cart-summary table .totals.grand .amount {
          font-size: 1.25rem; } }
    .cart-summary table .shipping td,
    .cart-summary table .shipping th,
    .cart-summary table .totals-tax td,
    .cart-summary table .totals-tax th {
      font-size: 0.69062rem;
      font-weight: 400;
      color: #181818; }
      .cart-summary table .shipping td .label,
      .cart-summary table .shipping th .label,
      .cart-summary table .totals-tax td .label,
      .cart-summary table .totals-tax th .label {
        color: #181818; }
      @media screen and (min-width: 75em) {
        .cart-summary table .shipping td,
        .cart-summary table .shipping th,
        .cart-summary table .totals-tax td,
        .cart-summary table .totals-tax th {
          font-size: 0.8125rem; } }
    .cart-summary table .price-label-free {
      display: inline-block;
      vertical-align: middle;
      letter-spacing: normal;
      padding: 0;
      color: #181818;
      background: none; }
    .cart-summary table .shipping .mark .value {
      display: none; }
    .cart-summary table .totals-tax td,
    .cart-summary table .totals-tax th {
      padding-bottom: .75rem; }
      @media screen and (min-width: 75em) {
        .cart-summary table .totals-tax td,
        .cart-summary table .totals-tax th {
          padding-bottom: 1rem; } }
  .cart-summary .block {
    margin-bottom: .5rem; }
    .cart-summary .block .title {
      color: #181818;
      font-size: 0.6875rem;
      cursor: pointer; }
      .cart-summary .block .title strong {
        border-bottom: 1px solid rgba(24, 24, 24, 0.15); }
      .cart-summary .block .title:hover strong, .cart-summary .block .title:active strong {
        border-bottom: 0; }
      @media print, screen and (min-width: 48em) {
        .cart-summary .block .title {
          font-size: 0.8125rem; } }
    .cart-summary .block .content {
      margin-top: 1.25rem;
      padding: 1.25rem 1.75rem 1.75rem;
      line-height: 1.46;
      color: #181818;
      position: relative;
      background: #f8f7f6;
      box-shadow: none; }
      .cart-summary .block .content:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 14px 14px 14px;
        border-color: transparent transparent #f8f7f6 transparent;
        position: absolute;
        bottom: 100%;
        left: 2rem; }
      .cart-summary .block .content .legend {
        display: block;
        font-size: 1.0625rem;
        font-weight: 300;
        font-family: "silka-regular-webfont", sans-serif;
        letter-spacing: normal;
        margin-bottom: .4rem; }
      .cart-summary .block .content .label {
        line-height: 1.46; }
        .cart-summary .block .content .label span:after {
          content: ':'; }
      .cart-summary .block .content br {
        display: none; }
      .cart-summary .block .content p {
        line-height: 1.46;
        margin: 0 0 .625rem; }
      .cart-summary .block .content .input-text {
        margin-bottom: .625rem; }
      .cart-summary .block .content .action {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        background-color: #181818;
        color: #fff; }
        .cart-summary .block .content .action:hover, .cart-summary .block .content .action:focus {
          background-color: black;
          color: #fff; }
      .cart-summary .block .content .actions-toolbar > div {
        margin-bottom: .25rem; }
        .cart-summary .block .content .actions-toolbar > div:last-child {
          margin-bottom: 0; }

.cart-totals {
  padding: 1rem 23px;
  margin-left: -23px;
  margin-right: -23px;
  margin-bottom: 1rem;
  position: relative;
  box-shadow: none;
  background: #f8f7f6; }
  .cart-totals ._block-content-loading,
  .cart-totals [data-bind="blockLoader: isLoading"] {
    position: static; }
  .cart-totals .loading-mask {
    background-color: rgba(255, 255, 255, 0.45); }
  @media print, screen and (min-width: 48em) {
    .cart-totals {
      margin-left: 0;
      margin-right: 0; } }
  @media screen and (min-width: 75em) {
    .cart-totals {
      margin-bottom: 1.5rem;
      padding: 1.75rem 1.75rem 2rem; } }

.checkout-methods-items {
  margin: 0;
  list-style: none;
  margin-top: 1rem; }
  .checkout-methods-items li {
    padding: 0; }
    .checkout-methods-items li:after {
      display: none; }
  @media screen and (min-width: 75em) {
    .checkout-methods-items {
      margin-top: 1.5rem; } }
  .checkout-methods-items .item {
    margin-bottom: .5rem; }
  .checkout-methods-items button.action {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    font-size: 0.89062rem;
    font-weight: 300;
    position: relative;
    padding-right: 1.5em;
    padding-left: 1.5em;
    padding-top: 1.1875rem;
    padding-bottom: 1.25rem;
    text-transform: none; }
    @media screen and (min-width: 75em) {
      .checkout-methods-items button.action {
        font-size: 1.1875rem; } }

table.cart {
  margin-bottom: .5rem; }
  @media print, screen and (min-width: 48em) {
    table.cart {
      margin-bottom: 3rem; } }
  table.cart:not(.has-header) thead {
    display: none; }
  table.cart tbody,
  table.cart thead,
  table.cart tfoot {
    border: 0;
    background: none; }
  table.cart td,
  table.cart th {
    border: 0;
    padding: 10px 5px;
    vertical-align: middle;
    line-height: 1.3;
    font-weight: 400;
    text-align: inherit;
    background: none; }
    @media screen and (min-width: 75em) {
      table.cart td,
      table.cart th {
        padding: 0.8125rem; } }
    table.cart td:last-child,
    table.cart th:last-child {
      padding-right: 2.5rem; }
    table.cart td .stock,
    table.cart th .stock {
      color: rgba(24, 24, 24, 0.8); }
    @media screen and (max-width: 63.9375em) {
      table.cart td,
      table.cart th {
        padding: 0;
        vertical-align: top;
        display: block;
        float: left; }
        table.cart td:last-child,
        table.cart th:last-child {
          padding-right: .25rem; }
        table.cart td .product-item-details,
        table.cart th .product-item-details {
          margin-bottom: .75rem; }
        table.cart td .stock,
        table.cart th .stock {
          font-size: 0.6875rem;
          line-height: 1.5; }
        table.cart td.item-info, table.cart td.item-sku,
        table.cart th.item-info,
        table.cart th.item-sku {
          float: none;
          clear: right;
          margin-left: 3.75rem;
          padding-top: 0.5rem;
          padding-right: 1.875rem; }
          table.cart td.item-info .product-item-name, table.cart td.item-sku .product-item-name,
          table.cart th.item-info .product-item-name,
          table.cart th.item-sku .product-item-name {
            line-height: 1; }
          table.cart td.item-info .item-options, table.cart td.item-sku .item-options,
          table.cart th.item-info .item-options,
          table.cart th.item-sku .item-options {
            font-size: 0.6875rem;
            line-height: 1.5; }
        table.cart td.qty, table.cart td.addtobasket,
        table.cart th.qty,
        table.cart th.addtobasket {
          margin-left: 3.75rem;
          clear: left; }
        table.cart td.addtobasket,
        table.cart th.addtobasket {
          width: calc(100% - 3.75rem);
          padding-top: .5rem;
          padding-bottom: .5rem;
          border-top: 1px solid #fefefe;
          border-bottom: 1px solid #fefefe;
          margin-bottom: 1rem;
          margin-top: .5rem; }
        table.cart td.cart-actions,
        table.cart th.cart-actions {
          position: absolute;
          top: 1.125rem;
          right: 0;
          line-height: 1.875rem; } }
    @media screen and (max-width: 63.9375em) and (min-width: 64em) {
      table.cart td.cart-actions,
      table.cart th.cart-actions {
        float: right;
        position: static;
        line-height: 3.75rem; } }
    @media screen and (min-width: 64em) {
      table.cart td.item-info,
      table.cart th.item-info {
        width: 100%; }
      table.cart td.item-sku,
      table.cart th.item-sku {
        width: auto;
        min-width: 100px; } }
    @media screen and (min-width: 75em) {
      table.cart td.item-sku,
      table.cart th.item-sku {
        min-width: 200px; } }
  table.cart tbody.cart.item {
    border-bottom: 1px solid #fefefe; }
    table.cart tbody.cart.item:last-child {
      border-bottom: 0; }
    @media screen and (max-width: 63.9375em) {
      table.cart tbody.cart.item tr {
        display: block;
        padding-top: .75rem;
        padding-bottom: .75rem;
        position: relative; }
        table.cart tbody.cart.item tr::before, table.cart tbody.cart.item tr::after {
          display: table;
          content: ' '; }
        table.cart tbody.cart.item tr::after {
          clear: both; } }
  table.cart .item-message td,
  table.cart .item-message th {
    padding-top: 0; }
  table.cart .item-message td:last-child {
    padding-right: 0; }
  table.cart .item-image {
    width: 3.125rem;
    margin-bottom: .25rem;
    white-space: nowrap;
    position: relative; }
    table.cart .item-image .checkbox {
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      top: 100%;
      left: 0;
      margin: 6px 0 0 15px; }
    table.cart .item-image .product-item-photo {
      display: inline-block;
      vertical-align: middle; }
    table.cart .item-image .product-image-container {
      max-width: 3.125rem; }
    table.cart .item-image .product-label,
    table.cart .item-image .convert-label-container {
      display: none; }
    @media screen and (min-width: 64em) {
      table.cart .item-image {
        width: 1%;
        margin-bottom: 0; }
        table.cart .item-image .checkbox {
          position: static;
          margin: 0; }
        table.cart .item-image .product-image-container {
          max-width: 3.125rem; }
        table.cart .item-image .product-label,
        table.cart .item-image .convert-label-container {
          display: block; } }
    @media screen and (min-width: 75em) {
      table.cart .item-image .product-image-container {
        max-width: 6.875rem; } }
  table.cart .field.qty {
    white-space: nowrap;
    margin-bottom: 0; }
    table.cart .field.qty:after {
      display: inline-block;
      vertical-align: top;
      content: '×';
      font-size: 1rem;
      line-height: 1.875rem;
      color: rgba(24, 24, 24, 0.5);
      padding-left: .5rem;
      padding-right: .6rem; }
      @media screen and (min-width: 64em) {
        table.cart .field.qty:after {
          padding-left: 1rem;
          padding-right: 0;
          line-height: 3.75rem; }
          .ie11 table.cart .field.qty:after {
            position: relative;
            top: 16px; } }
      @media screen and (min-width: 75em) {
        .ie11 table.cart .field.qty:after {
          top: auto; } }
    table.cart .field.qty .control {
      display: inline-block;
      position: relative; }
      table.cart .field.qty .control div.mage-error {
        position: absolute;
        top: 100%;
        left: 0;
        margin-bottom: 0;
        white-space: normal;
        width: 12.5rem;
        line-height: 1; }
        @media screen and (max-width: 47.9375em) {
          table.cart .field.qty .control div.mage-error {
            margin-top: 0; } }
    table.cart .field.qty .action.update {
      position: absolute;
      top: 0;
      left: 100%;
      height: 3.75rem; }
      @media screen and (max-width: 63.9375em) {
        table.cart .field.qty .action.update {
          height: 1.875rem;
          padding-top: 0;
          padding-bottom: 0;
          line-height: 1.875rem; } }
  table.cart .input-text {
    width: 3.125rem;
    padding-left: .25rem;
    padding-right: .25rem;
    margin: 0;
    text-align: center;
    -moz-appearance: textfield; }
    table.cart .input-text::-webkit-outer-spin-button, table.cart .input-text::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    @media screen and (max-width: 63.9375em) {
      table.cart .input-text {
        width: 2.5rem;
        height: 1.875rem;
        padding-top: 0;
        padding-bottom: 0; } }
    table.cart .input-text.mage-error, table.cart .input-text.mage-success, table.cart .input-text.valid {
      background-image: none; }
  table.cart .action-edit {
    display: none; }
  table.cart .label {
    display: none; }
  table.cart .product-image-container {
    display: block;
    position: relative;
    overflow: hidden; }
  table.cart .product-item-name {
    display: block;
    font-size: 1.0625rem;
    font-weight: 400;
    text-transform: none;
    font-family: "silka-medium-webfont", sans-serif;
    letter-spacing: normal;
    margin: .25rem 0; }
    table.cart .product-item-name a {
      color: #181818;
      text-decoration: none; }
      table.cart .product-item-name a:hover {
        color: #181818; }
    @media print, screen and (min-width: 48em) {
      table.cart .product-item-name {
        font-size: 1.25rem; } }
  table.cart .price-including-tax,
  table.cart .price-excluding-tax,
  table.cart .wishlist-price .price-box {
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 400;
    color: inherit;
    margin-top: -.25rem;
    line-height: 1.875rem;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal; }
    table.cart .price-including-tax .price-as-configured,
    table.cart .price-excluding-tax .price-as-configured,
    table.cart .wishlist-price .price-box .price-as-configured {
      font-weight: inherit;
      margin: 0; }
    @media screen and (min-width: 64em) {
      table.cart .price-including-tax,
      table.cart .price-excluding-tax,
      table.cart .wishlist-price .price-box {
        margin-top: 0;
        line-height: 3.75rem; } }
  table.cart .addtocart-btn {
    display: inline;
    color: #1A2746;
    text-decoration: underline;
    font-weight: 400;
    padding: 0;
    border: 0;
    box-shadow: none;
    background: transparent;
    cursor: pointer;
    white-space: nowrap; }
    table.cart .addtocart-btn:hover, table.cart .addtocart-btn:focus {
      text-decoration: none; }

.cart.main.actions {
  margin-bottom: 1rem; }
  .cart.main.actions .action,
  .cart.main.actions .continue,
  .cart.main.actions .clear {
    display: none; }

.cart.items .item > .item-actions,
.cart.items .item-info .gift-options-cart-item,
.cart-actions .action-towishlist,
.cart-summary .block.giftcard .content,
.block.discount .content,
#block-shipping {
  display: none; }

.header-row.checkout-index-index .algolia-instant-selector-results {
  display: none !important; }

@media screen and (max-width: 47.9375em) {
  .b-page-checkout .column.main, .b-page-checkout .main.columns,
  .b-page-checkout .page-container {
    padding-left: 0;
    padding-right: 0; } }

.b-page-checkout.is-sticky .logo img {
  margin-top: 0; }

.b-page-checkout .header .header-row .logo {
  text-align: center; }
  .b-page-checkout .header .header-row .logo img {
    max-width: 123px; }

.b-page-checkout .header .tab-bar .nav-toggle {
  display: none; }

.b-page-checkout .header .tab-bar .middle-side {
  width: 33.33333%; }

.b-page-checkout .header .tab-bar .left-side,
.b-page-checkout .header .tab-bar .right-side {
  width: 33.33333%;
  line-height: 4.5rem; }

.b-page-checkout .header a {
  color: white; }

.b-page-checkout .header .button {
  color: #fff;
  margin-bottom: 0; }

.b-page-checkout .header .contact-header-checkout {
  color: white; }

.b-page-checkout .page-header {
  height: 4.5rem;
  border-bottom: 1px solid rgba(24, 24, 24, 0.2); }
  @media print, screen and (min-width: 48em) {
    .b-page-checkout .page-header {
      height: auto;
      margin-bottom: 1rem; } }
  .b-page-checkout .page-header .header .header-row.is-stuck .logo {
    position: relative;
    top: auto;
    left: auto;
    transform: none; }

.b-page-checkout .sticky-header-row {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  padding-left: 1.0625rem;
  padding-right: 1.0625rem; }
  .b-page-checkout .sticky-header-row::before, .b-page-checkout .sticky-header-row::after {
    display: table;
    content: ' '; }
  .b-page-checkout .sticky-header-row::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .b-page-checkout .sticky-header-row {
      padding-left: 1.25rem; } }
  @media print, screen and (min-width: 40em) {
    .b-page-checkout .sticky-header-row {
      padding-right: 1.25rem; } }

.b-page-checkout .page-title-wrapper {
  display: none; }

.header-controls {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem;
  max-width: initial;
  width: auto;
  height: 4.5rem; }
  .header-controls::before, .header-controls::after {
    display: table;
    content: ' '; }
  .header-controls::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .header-controls {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .header-controls {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .header-controls {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .header-controls {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .header-controls {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .is-stuck .header-controls.center-header-top-panel {
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .is-stuck .header-controls.center-header-top-panel {
        padding-right: 1.25rem; } }
    @media print, screen and (min-width: 40em) {
      .is-stuck .header-controls.center-header-top-panel {
        padding-left: 1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .header-controls {
      height: 8.1875rem; } }
  @media screen and (min-width: 75em) {
    .header-controls {
      height: 8.1875rem; } }
  .header-row.is-stuck .header-controls {
    height: 4.75rem; }
  .header-controls .header-totals,
  .header-controls .header-logo,
  .header-controls .header-info {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    height: 4.5rem;
    line-height: 4.5rem; }
    @media print, screen and (min-width: 40em) {
      .header-controls .header-totals,
      .header-controls .header-logo,
      .header-controls .header-info {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .header-controls .header-totals:last-child:not(:first-child),
    .header-controls .header-logo:last-child:not(:first-child),
    .header-controls .header-info:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 48em) {
      .header-controls .header-totals,
      .header-controls .header-logo,
      .header-controls .header-info {
        height: 8.1875rem;
        line-height: 8.1875rem; } }
    @media screen and (min-width: 75em) {
      .header-controls .header-totals,
      .header-controls .header-logo,
      .header-controls .header-info {
        height: 8.1875rem;
        line-height: 8.1875rem; } }
    .header-row.is-stuck .header-controls .header-totals, .header-row.is-stuck
    .header-controls .header-logo, .header-row.is-stuck
    .header-controls .header-info {
      height: 4.75rem;
      line-height: 4.75rem; }
      .header-row.is-stuck .header-controls .header-totals .logo, .header-row.is-stuck
      .header-controls .header-logo .logo, .header-row.is-stuck
      .header-controls .header-info .logo {
        padding-left: 0;
        width: auto;
        display: block; }
      @media print, screen and (min-width: 48em) {
        .header-row.is-stuck .header-controls .header-totals .items-in-cart,
        .header-row.is-stuck .header-controls .header-totals .contact-header-checkout, .header-row.is-stuck
        .header-controls .header-logo .items-in-cart,
        .header-row.is-stuck
        .header-controls .header-logo .contact-header-checkout, .header-row.is-stuck
        .header-controls .header-info .items-in-cart,
        .header-row.is-stuck
        .header-controls .header-info .contact-header-checkout {
          margin-top: .25rem; }
        .header-row.is-stuck .header-controls .header-totals .contact-header-worktime, .header-row.is-stuck
        .header-controls .header-logo .contact-header-worktime, .header-row.is-stuck
        .header-controls .header-info .contact-header-worktime {
          margin-top: -.25rem;
          font-size: 0.6875rem; } }
  @media screen and (min-width: 48em) and (min-width: 75em) {
    .header-row.is-stuck .header-controls .header-totals .contact-header-worktime, .header-row.is-stuck
    .header-controls .header-logo .contact-header-worktime, .header-row.is-stuck
    .header-controls .header-info .contact-header-worktime {
      margin-top: -.15rem; } }
  .header-controls .header-totals {
    width: 29.16667%; }
    @media print, screen and (min-width: 48em) {
      .header-controls .header-totals {
        width: 37.5%; } }
  .header-controls .header-logo {
    width: 41.66667%;
    padding-right: 0rem;
    padding-left: 0rem;
    text-align: center; }
    @media print, screen and (min-width: 40em) {
      .header-controls .header-logo {
        padding-right: 0rem; } }
    @media print, screen and (min-width: 40em) {
      .header-controls .header-logo {
        padding-left: 0rem; } }
    @media print, screen and (min-width: 48em) {
      .header-controls .header-logo {
        width: 25%;
        padding-right: 1.0625rem;
        padding-left: 1.0625rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .header-controls .header-logo {
      padding-right: 1.25rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .header-controls .header-logo {
      padding-left: 1.25rem; } }
    .header-controls .header-logo .logo {
      width: auto;
      margin-left: auto;
      margin-right: auto;
      float: none;
      text-align: center;
      padding: 0rem; }
      @media print, screen and (min-width: 40em) {
        .header-controls .header-logo .logo {
          padding: 0rem; } }
      .header-controls .header-logo .logo:before {
        display: none; }
  .header-controls .header-info {
    width: 29.16667%;
    text-align: right; }
    @media print, screen and (min-width: 48em) {
      .header-controls .header-info {
        width: 37.5%; } }

.header-total-controls {
  display: inline-block;
  vertical-align: middle;
  line-height: 1; }
  .header-total-controls .total-line {
    display: none;
    color: white;
    font-size: 1.12rem;
    font-weight: 300;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal; }
    .header-total-controls .total-line .title {
      text-transform: none; }
    @media print, screen and (min-width: 48em) {
      .header-total-controls .total-line {
        display: block;
        font-size: 1.3125rem; }
        .header-total-controls .total-line.total-line-excl {
          font-size: 0.9625rem; } }
    @media screen and (min-width: 75em) {
      .header-total-controls .total-line {
        font-size: 1.75rem; }
        .header-row.is-stuck .header-total-controls .total-line {
          font-size: 1.5rem; }
        .header-total-controls .total-line.total-line-excl {
          font-size: 1.4rem; } }

.checkout-header-info {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  color: white;
  font-size: 0.69062rem; }
  .checkout-header-info .title,
  .checkout-header-info .contact-header-worktime {
    display: none; }
  @media print, screen and (min-width: 48em) {
    .checkout-header-info {
      font-size: 0.8125rem; }
      .checkout-header-info .title,
      .checkout-header-info .contact-header-worktime {
        display: block; } }

.mobile-total-price {
  text-align: center;
  margin-bottom: 1rem; }
  .mobile-total-price .mark:after {
    content: ':'; }
  @media print, screen and (min-width: 48em) {
    .mobile-total-price {
      display: none; } }

.contact-header-checkout {
  display: inline-block;
  width: 99%;
  font-size: 0;
  color: white;
  font-weight: 300;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "silka-regular-webfont", sans-serif;
  letter-spacing: normal; }
  @media print, screen and (min-width: 48em) {
    .contact-header-checkout {
      white-space: nowrap;
      font-size: 1.3125rem;
      margin-top: .75rem; } }
  @media screen and (min-width: 75em) {
    .contact-header-checkout {
      font-size: 1.75rem; }
      .header-row.is-stuck .contact-header-checkout {
        font-size: 1.3125rem; } }
  .contact-header-checkout:before {
    display: inline-block;
    vertical-align: middle;
    content: '';
    width: 23px;
    height: 23px;
    background: url("../images/icon-phone-white.svg") 50% no-repeat;
    background-size: 100% auto; }
    @media screen and (min-width: 75em) {
      .contact-header-checkout:before {
        margin-right: 0.3125rem; }
        .header-row.is-stuck .contact-header-checkout:before {
          width: 13.8px;
          height: 13.8px; } }

.items-in-cart {
  display: inline-block;
  position: relative; }
  @media print, screen and (min-width: 48em) {
    .items-in-cart {
      margin-top: .75rem; }
      .items-in-cart:hover .content.minicart-items, .items-in-cart:focus .content.minicart-items {
        display: block; } }
  .items-in-cart .title {
    cursor: pointer; }
    .items-in-cart .title a:before {
      display: inline-block;
      vertical-align: middle;
      content: '';
      width: 0.5rem;
      height: 0.75rem;
      background-image: url("../images/icon-arrow-next-white.svg");
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 0.4375rem;
      transform: scale(2);
      transform: scale(2) rotate(180deg); }
      @media print, screen and (min-width: 48em) {
        .items-in-cart .title a:before {
          transform: scale(1);
          transform: scale(1) rotate(180deg); } }
    @media print, screen and (min-width: 48em) {
      .items-in-cart .title a span {
        display: inline-block;
        vertical-align: middle;
        text-decoration: underline; } }
    .items-in-cart .title a:hover span {
      text-decoration: none; }
    .items-in-cart .title a:hover:before {
      text-decoration: none; }

.checkout-container {
  position: relative; }
  .checkout-container h1 {
    text-align: center;
    font-weight: 300; }
    @media print, screen and (min-width: 48em) {
      .checkout-container h1 {
        margin-bottom: 2.75rem; } }

.checkout-form-row {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem;
  max-width: initial;
  width: auto; }
  .checkout-form-row::before, .checkout-form-row::after {
    display: table;
    content: ' '; }
  .checkout-form-row::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .checkout-form-row {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .checkout-form-row {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .checkout-form-row {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .checkout-form-row {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .checkout-form-row {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .checkout-form-row .field {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    margin-bottom: 0; }
    @media print, screen and (min-width: 40em) {
      .checkout-form-row .field {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .checkout-form-row .field:last-child:not(:first-child) {
      float: right; }
    .checkout-form-row .field .control {
      margin-bottom: .85rem; }
    @media screen and (min-width: 64em) {
      .checkout-form-row .field.field-city {
        width: 75%; }
      .checkout-form-row .field.field-postcode {
        width: 25%;
        padding-right: 0; }
      .checkout-form-row .field.field-telephone, .checkout-form-row .field.field-customer-password {
        width: 50%; } }
  .checkout-form-row.shipping-postcode-city {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: .85rem; }
    .checkout-form-row.shipping-postcode-city .field {
      margin-bottom: 0; }
    .checkout-form-row.shipping-postcode-city .message,
    .checkout-form-row.shipping-postcode-city .mage-error {
      clear: none;
      margin-bottom: .5rem;
      margin-right: 1.0625rem;
      margin-left: 1.0625rem; }
      @media print, screen and (min-width: 40em) {
        .checkout-form-row.shipping-postcode-city .message,
        .checkout-form-row.shipping-postcode-city .mage-error {
          margin-right: 1.25rem; } }
      @media print, screen and (min-width: 40em) {
        .checkout-form-row.shipping-postcode-city .message,
        .checkout-form-row.shipping-postcode-city .mage-error {
          margin-left: 1.25rem; } }
      @media screen and (min-width: 64em) {
        .checkout-form-row.shipping-postcode-city .message,
        .checkout-form-row.shipping-postcode-city .mage-error {
          order: 3; } }
  .checkout-form-row.shipping-company-number-name .field {
    margin-bottom: 0; }
    @media screen and (min-width: 64em) {
      .checkout-form-row.shipping-company-number-name .field {
        width: 50%; } }

.checkout-steps {
  margin: 0;
  list-style: none;
  position: relative;
  z-index: 1; }
  .checkout-steps li {
    padding: 0; }
    .checkout-steps li:after {
      display: none; }
  @media print, screen and (min-width: 48em) {
    .checkout-steps {
      max-width: 90.625rem;
      margin-right: auto;
      margin-left: auto;
      margin-right: -1.0625rem;
      margin-left: -1.0625rem;
      max-width: initial;
      width: auto; }
      .checkout-steps::before, .checkout-steps::after {
        display: table;
        content: ' '; }
      .checkout-steps::after {
        clear: both; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .checkout-steps {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 48em) {
    .checkout-steps {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 64em) {
    .checkout-steps {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 75em) {
    .checkout-steps {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 90.625em) {
    .checkout-steps {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .checkout-steps .checkout-shipping-address,
  .checkout-steps .checkout-shipping-method,
  .checkout-steps .checkout-payment-method {
    position: relative; }
    @media print, screen and (min-width: 48em) {
      .checkout-steps .checkout-shipping-address,
      .checkout-steps .checkout-shipping-method,
      .checkout-steps .checkout-payment-method {
        width: 100%;
        float: left;
        padding-right: 1.0625rem;
        padding-left: 1.0625rem;
        width: 50%; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .checkout-steps .checkout-shipping-address,
    .checkout-steps .checkout-shipping-method,
    .checkout-steps .checkout-payment-method {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
    @media print, screen and (min-width: 48em) {
        .checkout-steps .checkout-shipping-address:last-child:not(:first-child),
        .checkout-steps .checkout-shipping-method:last-child:not(:first-child),
        .checkout-steps .checkout-payment-method:last-child:not(:first-child) {
          float: right; } }
  .checkout-steps .loading-mask {
    background-color: rgba(255, 255, 255, 0.45); }
  .checkout-steps .checkout-box {
    padding: 1.5rem;
    margin-bottom: 1.25rem;
    position: relative;
    background: #f8f7f6;
    box-shadow: none; }
    @media screen and (max-width: 47.9375em) {
      .checkout-steps .checkout-box {
        padding-left: 1.875rem;
        padding-right: 1.875rem; } }
    @media screen and (min-width: 64em) {
      .checkout-steps .checkout-box {
        padding: 1.5rem; } }
    .checkout-steps .checkout-box .label > span:after {
      content: ':'; }
  .checkout-steps .field-tooltip-content {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }
  .checkout-steps .step-title {
    text-transform: none;
    font-size: 1.5rem;
    position: relative;
    color: #181818;
    font-weight: 300;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal; }
    @media screen and (min-width: 75em) {
      .checkout-steps .step-title {
        font-size: 2rem;
        margin-bottom: 1rem; } }
  .checkout-steps .form-order-comment .input-text {
    margin-top: .5rem;
    margin-bottom: 0;
    min-height: 4.8125rem; }
  .checkout-steps .form-order-comment > .field > .label,
  .checkout-steps .new-address-form > .field > .label {
    display: inline-block;
    line-height: 1;
    text-decoration: underline;
    color: #181818;
    cursor: pointer; }
    .checkout-steps .form-order-comment > .field > .label[aria-expanded="true"],
    .checkout-steps .new-address-form > .field > .label[aria-expanded="true"] {
      text-decoration: none; }
    .checkout-steps .form-order-comment > .field > .label > span:after,
    .checkout-steps .new-address-form > .field > .label > span:after {
      display: none; }
  .checkout-steps .form-order-comment .billing-address-same-as-shipping-block,
  .checkout-steps .new-address-form .billing-address-same-as-shipping-block {
    margin-top: 1rem; }
  .checkout-steps .form-order-comment .field-select-billing .label,
  .checkout-steps .new-address-form .field-select-billing .label {
    margin-top: 1rem;
    line-height: 1.2; }
  .checkout-steps .field {
    margin-bottom: .85rem; }
    .checkout-steps .field.addresses {
      margin-bottom: 1.25rem; }
      .checkout-steps .field.addresses h3 {
        padding-bottom: 0;
        margin-bottom: 0; }
  .checkout-steps fieldset.field {
    margin-bottom: 0; }
  .checkout-steps .has-customer {
    padding-bottom: 2rem;
    margin-bottom: 1.5rem;
    margin-top: -.65rem;
    border-bottom: 1px solid rgba(24, 24, 24, 0.15); }
  .checkout-steps .checkout-shipping-list .item {
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;
    border-bottom: 1px solid rgba(24, 24, 24, 0.15); }
    .checkout-steps .checkout-shipping-list .item label {
      display: block;
      padding-left: 1.75rem; }
    .checkout-steps .checkout-shipping-list .item:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0; }
  .checkout-steps .checkout-shipping-list .col-row {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    margin-right: -1.0625rem;
    margin-left: -1.0625rem;
    max-width: initial;
    width: auto;
    position: relative;
    top: -2px; }
    .checkout-steps .checkout-shipping-list .col-row::before, .checkout-steps .checkout-shipping-list .col-row::after {
      display: table;
      content: ' '; }
    .checkout-steps .checkout-shipping-list .col-row::after {
      clear: both; }
    @media print, screen and (min-width: 40em) {
      .checkout-steps .checkout-shipping-list .col-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .checkout-steps .checkout-shipping-list .col-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .checkout-steps .checkout-shipping-list .col-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .checkout-steps .checkout-shipping-list .col-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .checkout-steps .checkout-shipping-list .col-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    .checkout-steps .checkout-shipping-list .col-row .col {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem; }
      @media print, screen and (min-width: 40em) {
        .checkout-steps .checkout-shipping-list .col-row .col {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .checkout-steps .checkout-shipping-list .col-row .col:last-child:not(:first-child) {
        float: right; }
    .checkout-steps .checkout-shipping-list .col-row .col-title {
      width: 66.66667%; }
      .checkout-steps .checkout-shipping-list .col-row .col-title .title {
        font-size: 1rem;
        font-weight: 400;
        text-transform: none;
        line-height: 1;
        font-family: "silka-regular-webfont", sans-serif;
        letter-spacing: normal; }
    .checkout-steps .checkout-shipping-list .col-row .col-price {
      width: 33.33333%;
      text-align: right;
      line-height: 1;
      font-size: 1.125rem;
      text-transform: none;
      font-weight: 400;
      font-family: "silka-regular-webfont", sans-serif;
      letter-spacing: normal; }
    .checkout-steps .checkout-shipping-list .col-row .desc {
      color: rgba(24, 24, 24, 0.8); }
  .checkout-steps .checkout-payment-method .step-title {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    margin-right: -1.0625rem;
    margin-left: -1.0625rem; }
    .checkout-steps .checkout-payment-method .step-title::before, .checkout-steps .checkout-payment-method .step-title::after {
      display: table;
      content: ' '; }
    .checkout-steps .checkout-payment-method .step-title::after {
      clear: both; }
    @media print, screen and (min-width: 40em) {
      .checkout-steps .checkout-payment-method .step-title {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .checkout-steps .checkout-payment-method .step-title {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .checkout-steps .checkout-payment-method .step-title {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .checkout-steps .checkout-payment-method .step-title {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .checkout-steps .checkout-payment-method .step-title {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    .checkout-steps .checkout-payment-method .step-title .txt {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem;
      white-space: nowrap; }
      @media print, screen and (min-width: 40em) {
        .checkout-steps .checkout-payment-method .step-title .txt {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .checkout-steps .checkout-payment-method .step-title .txt:last-child:not(:first-child) {
        float: right; }
      @media screen and (min-width: 64em) {
        .checkout-steps .checkout-payment-method .step-title .txt {
          width: 33.33333%; } }
    .checkout-steps .checkout-payment-method .step-title .order-total {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem; }
      @media print, screen and (min-width: 40em) {
        .checkout-steps .checkout-payment-method .step-title .order-total {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .checkout-steps .checkout-payment-method .step-title .order-total:last-child:not(:first-child) {
        float: right; }
      @media screen and (min-width: 64em) {
        .checkout-steps .checkout-payment-method .step-title .order-total {
          width: 66.66667%; } }
  .checkout-steps .checkout-payment-method .payment-group .step-title {
    display: none; }
  .checkout-steps .checkout-payment-method .b-place-order button.action {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    font-size: 0.89062rem;
    font-weight: 300;
    position: relative;
    padding-right: 1.5em;
    padding-left: 1.5em;
    padding-top: 1.1875rem;
    padding-bottom: 1.25rem;
    text-transform: none; }
    @media screen and (min-width: 75em) {
      .checkout-steps .checkout-payment-method .b-place-order button.action {
        font-size: 1.1875rem; } }
  .checkout-steps .checkout-payment-method .actions-toolbar {
    display: none; }
  .checkout-steps .checkout-payment-method .order-total {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1;
    text-transform: none;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    margin-top: .75rem; }
    @media screen and (min-width: 64em) {
      .checkout-steps .checkout-payment-method .order-total {
        text-align: right;
        margin-top: 0; } }
    @media screen and (min-width: 75em) {
      .checkout-steps .checkout-payment-method .order-total {
        font-size: 2rem; } }
    .checkout-steps .checkout-payment-method .order-total .order-total-incl-tax .mark:after {
      content: ':'; }
    .checkout-steps .checkout-payment-method .order-total .note {
      color: rgba(24, 24, 24, 0.8);
      font-size: 11px;
      line-height: 1.4;
      letter-spacing: normal; }
    .checkout-steps .checkout-payment-method .order-total .order-total-excl-tax {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.6; }
      .checkout-steps .checkout-payment-method .order-total .order-total-excl-tax + .order-total-tax {
        color: rgba(24, 24, 24, 0.8);
        font-size: 11px;
        line-height: 1.4; }
        .checkout-steps .checkout-payment-method .order-total .order-total-excl-tax + .order-total-tax + .order-total-incl-tax {
          font-size: 22px;
          line-height: 1.4; }
  .checkout-steps .checkout-payment-method .message {
    margin-top: 1rem;
    padding: .75rem .75rem .75rem 2rem;
    font-size: .75rem;
    box-shadow: none;
    background-position: .75rem .85rem; }
  .checkout-steps .payment-methods-list .checkout-agreements-block {
    display: none; }
  .checkout-steps .payment-methods-list .payment-group {
    border-top: 1px solid rgba(24, 24, 24, 0.15);
    padding-top: 1.25rem; }
    .checkout-steps .payment-methods-list .payment-group .payment-method {
      padding-bottom: 0.9375rem;
      margin-bottom: 1.25rem;
      border-bottom: 1px solid rgba(24, 24, 24, 0.15); }
      .checkout-steps .payment-methods-list .payment-group .payment-method > label {
        display: block;
        padding-left: 1.75rem; }
        .checkout-steps .payment-methods-list .payment-group .payment-method > label > span {
          display: block; }
        .checkout-steps .payment-methods-list .payment-group .payment-method > label .desc {
          color: rgba(24, 24, 24, 0.8); }
      .checkout-steps .payment-methods-list .payment-group .payment-method:last-child {
        margin-bottom: 0;
        border-bottom: 0; }
      .checkout-steps .payment-methods-list .payment-group .payment-method .payment-method-content {
        display: none;
        padding-left: 1.4rem;
        color: rgba(24, 24, 24, 0.8); }
      .checkout-steps .payment-methods-list .payment-group .payment-method._active .payment-method-content {
        display: block; }
  .checkout-steps .payment-methods-list .payment-method-title {
    line-height: 1.2;
    margin-bottom: .75rem;
    position: relative;
    margin-bottom: 0; }
    .checkout-steps .payment-methods-list .payment-method-title input[type="radio"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0; }
      .checkout-steps .payment-methods-list .payment-method-title input[type="radio"] + label,
      .checkout-steps .payment-methods-list .payment-method-title input[type="radio"] + .label {
        cursor: pointer;
        font-weight: normal;
        position: relative;
        margin: 0;
        line-height: 1.2;
        min-height: 1rem;
        padding-left: 1.4rem; }
        .checkout-steps .payment-methods-list .payment-method-title input[type="radio"] + label:before,
        .checkout-steps .payment-methods-list .payment-method-title input[type="radio"] + .label:before {
          display: block;
          content: '';
          height: 1rem;
          width: 1rem;
          line-height: 1rem;
          text-align: center;
          position: absolute;
          top: -1px;
          left: 0;
          border-radius: 50%;
          border: 1px solid #9DA3AF;
          color: #181818;
          background: white;
          z-index: 0; }
        .checkout-steps .payment-methods-list .payment-method-title input[type="radio"] + label:after,
        .checkout-steps .payment-methods-list .payment-method-title input[type="radio"] + .label:after {
          display: none;
          content: '';
          position: absolute;
          top: 0.125rem;
          left: 0.1875rem;
          height: 0.625rem;
          width: 0.625rem;
          border-radius: 50%;
          background: #1A2746; }
      .checkout-steps .payment-methods-list .payment-method-title input[type="radio"]:checked + label:after,
      .checkout-steps .payment-methods-list .payment-method-title input[type="radio"]:checked + .label:after {
        display: block; }
      .checkout-steps .payment-methods-list .payment-method-title input[type="radio"]:disabled + label:before,
      .checkout-steps .payment-methods-list .payment-method-title input[type="radio"]:disabled + .label:before {
        opacity: .3;
        background: #eee; }
    .checkout-steps .payment-methods-list .payment-method-title [class*="-logo"] {
      display: none !important; }
    .checkout-steps .payment-methods-list .payment-method-title input[type="radio"] + .label {
      min-height: 1.25rem; }
    .checkout-steps .payment-methods-list .payment-method-title span {
      font-size: 1rem;
      font-weight: 400;
      font-family: "silka-regular-webfont", sans-serif;
      letter-spacing: normal;
      position: relative;
      top: -2px;
      text-transform: none; }
  .checkout-steps .payment-methods-list .payment-method-content [class*="-img"] {
    display: none !important; }
  .checkout-steps .checkout-agreements-block {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-transform: none;
    border-top: 1px solid rgba(24, 24, 24, 0.15); }
    .checkout-steps .checkout-agreements-block .checkout-agreement {
      line-height: 1.2;
      margin-bottom: .75rem;
      position: relative;
      margin-bottom: 0; }
      .checkout-steps .checkout-agreements-block .checkout-agreement input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0; }
        .checkout-steps .checkout-agreements-block .checkout-agreement input[type="checkbox"] + label,
        .checkout-steps .checkout-agreements-block .checkout-agreement input[type="checkbox"] + .label {
          cursor: pointer;
          font-weight: normal;
          position: relative;
          margin: 0;
          line-height: 1.2;
          min-height: 1rem;
          padding-left: 1.4rem; }
          .checkout-steps .checkout-agreements-block .checkout-agreement input[type="checkbox"] + label:before,
          .checkout-steps .checkout-agreements-block .checkout-agreement input[type="checkbox"] + .label:before {
            display: block;
            content: '';
            height: 1rem;
            width: 1rem;
            line-height: 1rem;
            text-align: center;
            position: absolute;
            top: -1px;
            left: 0;
            border: 1px solid #9DA3AF;
            color: #181818;
            background: white; }
        .checkout-steps .checkout-agreements-block .checkout-agreement input[type="checkbox"]:checked + label:before,
        .checkout-steps .checkout-agreements-block .checkout-agreement input[type="checkbox"]:checked + .label:before {
          content: '✓'; }
        .checkout-steps .checkout-agreements-block .checkout-agreement input[type="checkbox"]:disabled + label {
          opacity: .4; }
        .checkout-steps .checkout-agreements-block .checkout-agreement input[type="checkbox"]:disabled + label:before,
        .checkout-steps .checkout-agreements-block .checkout-agreement input[type="checkbox"]:disabled + .label:before {
          background: #eee; }
      .checkout-steps .checkout-agreements-block .checkout-agreement a {
        color: #181818;
        text-decoration: underline; }
        .checkout-steps .checkout-agreements-block .checkout-agreement a:hover, .checkout-steps .checkout-agreements-block .checkout-agreement a:focus {
          text-decoration: none; }

.checkout-step-form-login .actions-toolbar {
  text-align: center;
  margin-top: 1rem;
  clear: both;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    .checkout-step-form-login .actions-toolbar {
      padding-right: 1.25rem; } }
  @media print, screen and (min-width: 40em) {
    .checkout-step-form-login .actions-toolbar {
      padding-left: 1.25rem; } }
  .checkout-step-form-login .actions-toolbar > .primary .action {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: .75rem; }
  .checkout-step-form-login .actions-toolbar .remind {
    display: inline-block;
    vertical-align: middle;
    line-height: 1; }
  @media print, screen and (min-width: 48em) {
    .checkout-step-form-login .actions-toolbar {
      text-align: left;
      max-width: 90.625rem;
      margin-right: auto;
      margin-left: auto;
      margin-right: -1.0625rem;
      margin-left: -1.0625rem; }
      .checkout-step-form-login .actions-toolbar::before, .checkout-step-form-login .actions-toolbar::after {
        display: table;
        content: ' '; }
      .checkout-step-form-login .actions-toolbar::after {
        clear: both; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .checkout-step-form-login .actions-toolbar {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 48em) {
    .checkout-step-form-login .actions-toolbar {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 64em) {
    .checkout-step-form-login .actions-toolbar {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 75em) {
    .checkout-step-form-login .actions-toolbar {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 90.625em) {
    .checkout-step-form-login .actions-toolbar {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
      .checkout-step-form-login .actions-toolbar > .primary,
      .checkout-step-form-login .actions-toolbar > .secondary {
        width: 100%;
        float: left;
        padding-right: 1.0625rem;
        padding-left: 1.0625rem;
        width: 50%; } }
    @media print, screen and (min-width: 48em) and (min-width: 40em) {
      .checkout-step-form-login .actions-toolbar > .primary,
      .checkout-step-form-login .actions-toolbar > .secondary {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
  @media print, screen and (min-width: 48em) {
        .checkout-step-form-login .actions-toolbar > .primary:last-child:not(:first-child),
        .checkout-step-form-login .actions-toolbar > .secondary:last-child:not(:first-child) {
          float: right; }
      .checkout-step-form-login .actions-toolbar > .primary .action {
        margin-bottom: 0; }
      .checkout-step-form-login .actions-toolbar > .secondary {
        line-height: 3.75rem; } }

.checkout-step-form-login .control {
  position: relative; }

.checkout-step-form-login .hidden-fields {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem; }
  .checkout-step-form-login .hidden-fields::before, .checkout-step-form-login .hidden-fields::after {
    display: table;
    content: ' '; }
  .checkout-step-form-login .hidden-fields::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .checkout-step-form-login .hidden-fields {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .checkout-step-form-login .hidden-fields {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .checkout-step-form-login .hidden-fields {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .checkout-step-form-login .hidden-fields {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .checkout-step-form-login .hidden-fields {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .checkout-step-form-login .hidden-fields .field {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .checkout-step-form-login .hidden-fields .field {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .checkout-step-form-login .hidden-fields .field:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 48em) {
      .checkout-step-form-login .hidden-fields .field {
        width: 50%; } }

.checkout-step-form-login .note {
  display: block;
  position: relative;
  text-align: left;
  padding: 1rem;
  line-height: 1.45;
  margin-top: 1rem;
  box-shadow: 0 0 15px rgba(24, 24, 24, 0.25);
  background-color: white;
  z-index: 1; }
  @media print, screen and (min-width: 48em) {
    .checkout-step-form-login .note {
      position: absolute;
      top: 100%;
      right: 0;
      margin-top: 0;
      width: 50%; } }
  .checkout-step-form-login .note:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 1rem 1rem 1rem;
    border-color: transparent transparent white transparent;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%); }
    @media print, screen and (min-width: 48em) {
      .checkout-step-form-login .note:after {
        right: 2.5rem;
        left: auto;
        transform: translateX(0); } }

.authentication-wrapper {
  text-align: center;
  margin-top: -.75rem;
  margin-bottom: 1rem;
  position: relative;
  z-index: 2; }
  @media print, screen and (min-width: 40em) {
    .authentication-wrapper {
      margin-top: 0;
      margin-bottom: 0;
      text-align: left;
      position: absolute;
      left: 0;
      top: 1.625rem; } }
  @media screen and (min-width: 75em) {
    .authentication-wrapper {
      top: 1.875rem; } }
  .authentication-wrapper .authentication-dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    text-align: left;
    padding: 2.5rem;
    margin-top: .75rem;
    box-shadow: 0 0 25px rgba(24, 24, 24, 0.15);
    background-color: white;
    z-index: 1; }
    .authentication-wrapper .authentication-dropdown:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 1rem 1rem 1rem;
      border-color: transparent transparent white transparent;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%); }
    .authentication-wrapper .authentication-dropdown._show {
      display: block; }
    @media print, screen and (min-width: 40em) {
      .authentication-wrapper .authentication-dropdown {
        width: 26.875rem; }
        .authentication-wrapper .authentication-dropdown:after {
          left: 1rem;
          transform: translateX(0); } }
  .authentication-wrapper .block-title {
    font-size: 18px;
    font-weight: 500;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    margin-bottom: 1rem; }
    .authentication-wrapper .block-title strong {
      font-weight: inherit; }
  .authentication-wrapper .actions-toolbar {
    text-align: center;
    margin-top: 1rem; }
    .authentication-wrapper .actions-toolbar > .primary .action {
      display: block;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: .75rem; }
    @media print, screen and (min-width: 48em) {
      .authentication-wrapper .actions-toolbar {
        text-align: left;
        max-width: 90.625rem;
        margin-right: auto;
        margin-left: auto;
        margin-right: -1.0625rem;
        margin-left: -1.0625rem; }
        .authentication-wrapper .actions-toolbar::before, .authentication-wrapper .actions-toolbar::after {
          display: table;
          content: ' '; }
        .authentication-wrapper .actions-toolbar::after {
          clear: both; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .authentication-wrapper .actions-toolbar {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 48em) {
    .authentication-wrapper .actions-toolbar {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 64em) {
    .authentication-wrapper .actions-toolbar {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 75em) {
    .authentication-wrapper .actions-toolbar {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 90.625em) {
    .authentication-wrapper .actions-toolbar {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
        .authentication-wrapper .actions-toolbar > .primary,
        .authentication-wrapper .actions-toolbar > .secondary {
          width: 100%;
          float: left;
          padding-right: 1.0625rem;
          padding-left: 1.0625rem;
          width: 50%; } }
    @media print, screen and (min-width: 48em) and (min-width: 40em) {
      .authentication-wrapper .actions-toolbar > .primary,
      .authentication-wrapper .actions-toolbar > .secondary {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    @media print, screen and (min-width: 48em) {
          .authentication-wrapper .actions-toolbar > .primary:last-child:not(:first-child),
          .authentication-wrapper .actions-toolbar > .secondary:last-child:not(:first-child) {
            float: right; }
        .authentication-wrapper .actions-toolbar > .primary {
          float: right;
          text-align: right; }
          .authentication-wrapper .actions-toolbar > .primary .action {
            display: inline-block;
            width: auto;
            margin-bottom: 0; }
        .authentication-wrapper .actions-toolbar > .secondary {
          line-height: 3.75rem; } }
  .authentication-wrapper .action-close {
    font-size: 1.5rem; }
  .authentication-wrapper .action-auth-toggle {
    display: inline-block;
    line-height: 1;
    vertical-align: top; }

.shipping-address-items .shipping-address-item {
  padding-top: 1.25rem;
  margin-top: 1.25rem;
  border-top: 1px solid rgba(24, 24, 24, 0.15); }
  .shipping-address-items .shipping-address-item:first-child {
    margin-top: 0.625rem; }
  .shipping-address-items .shipping-address-item label {
    display: block;
    padding-left: 1.75rem; }
  .shipping-address-items .shipping-address-item .radio {
    margin-bottom: 0; }
  .shipping-address-items .shipping-address-item .col-row {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    margin-right: -1.0625rem;
    margin-left: -1.0625rem;
    max-width: initial;
    width: auto;
    position: relative;
    top: -2px; }
    .shipping-address-items .shipping-address-item .col-row::before, .shipping-address-items .shipping-address-item .col-row::after {
      display: table;
      content: ' '; }
    .shipping-address-items .shipping-address-item .col-row::after {
      clear: both; }
    @media print, screen and (min-width: 40em) {
      .shipping-address-items .shipping-address-item .col-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .shipping-address-items .shipping-address-item .col-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .shipping-address-items .shipping-address-item .col-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .shipping-address-items .shipping-address-item .col-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .shipping-address-items .shipping-address-item .col-row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    .shipping-address-items .shipping-address-item .col-row .col {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem; }
      @media print, screen and (min-width: 40em) {
        .shipping-address-items .shipping-address-item .col-row .col {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .shipping-address-items .shipping-address-item .col-row .col:last-child:not(:first-child) {
        float: right; }
    .shipping-address-items .shipping-address-item .col-row .shipping-address {
      margin-bottom: 0;
      line-height: 1.4; }
      @media screen and (min-width: 64em) {
        .shipping-address-items .shipping-address-item .col-row .shipping-address {
          width: 50%; } }
      .shipping-address-items .shipping-address-item .col-row .shipping-address strong {
        font-family: "silka-regular-webfont", sans-serif;
        letter-spacing: normal;
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 1; }
  .shipping-address-items .shipping-address-item.selected-item label:after,
  .shipping-address-items .shipping-address-item.selected-item .label:after {
    display: block; }

.shipping-address .action {
  display: inline-block;
  text-decoration: underline;
  color: #181818;
  cursor: pointer;
  line-height: 1.6;
  margin-bottom: .85rem; }

.modal-add-adress label.label span:after, .modal-add-adress .checkout-steps .checkout-box legend.label span:after, .checkout-steps .checkout-box .modal-add-adress legend.label span:after, .modal-add-adress .checkout-steps .form-order-comment legend.label span:after, .checkout-steps .form-order-comment .modal-add-adress legend.label span:after, .modal-add-adress .checkout-steps .new-address-form legend.label span:after, .checkout-steps .new-address-form .modal-add-adress legend.label span:after, .modal-add-adress .shipping-address .action span:after, .shipping-address .modal-add-adress .action span:after, .modal-add-adress .street legend.label span:after {
  content: ':'; }

.account-types-list {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem;
  margin-bottom: 1rem; }
  .account-types-list::before, .account-types-list::after {
    display: table;
    content: ' '; }
  .account-types-list::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .account-types-list {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .account-types-list {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .account-types-list {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .account-types-list {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .account-types-list {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .account-types-list .field-column {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .account-types-list .field-column {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .account-types-list .field-column:last-child:not(:first-child) {
      float: right; }
    @media screen and (min-width: 64em) {
      .account-types-list .field-column {
        width: 50%; } }
  .account-types-list .field-account-type.control {
    line-height: 1.2;
    margin-bottom: .75rem;
    position: relative;
    position: relative; }
    .account-types-list .field-account-type.control input[type="radio"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0; }
      .account-types-list .field-account-type.control input[type="radio"] + label,
      .account-types-list .field-account-type.control input[type="radio"] + .label {
        cursor: pointer;
        font-weight: normal;
        position: relative;
        margin: 0;
        line-height: 1.2;
        min-height: 1rem;
        padding-left: 1.4rem; }
        .account-types-list .field-account-type.control input[type="radio"] + label:before,
        .account-types-list .field-account-type.control input[type="radio"] + .label:before {
          display: block;
          content: '';
          height: 1rem;
          width: 1rem;
          line-height: 1rem;
          text-align: center;
          position: absolute;
          top: -1px;
          left: 0;
          border-radius: 50%;
          border: 1px solid #9DA3AF;
          color: #181818;
          background: white;
          z-index: 0; }
        .account-types-list .field-account-type.control input[type="radio"] + label:after,
        .account-types-list .field-account-type.control input[type="radio"] + .label:after {
          display: none;
          content: '';
          position: absolute;
          top: 0.125rem;
          left: 0.1875rem;
          height: 0.625rem;
          width: 0.625rem;
          border-radius: 50%;
          background: #1A2746; }
      .account-types-list .field-account-type.control input[type="radio"]:checked + label:after,
      .account-types-list .field-account-type.control input[type="radio"]:checked + .label:after {
        display: block; }
      .account-types-list .field-account-type.control input[type="radio"]:disabled + label:before,
      .account-types-list .field-account-type.control input[type="radio"]:disabled + .label:before {
        opacity: .3;
        background: #eee; }
    .account-types-list .field-account-type.control .field-account-type-info {
      position: static; }
    .account-types-list .field-account-type.control .label {
      display: inline-block; }
      .account-types-list .field-account-type.control .label > span:after {
        display: none; }
    .account-types-list .field-account-type.control .icon-q {
      position: absolute;
      left: 100%;
      top: 0; }

.opc-payment-additional {
  margin: 1rem 0 0;
  padding: 1rem 0 0;
  border-top: 1px solid rgba(24, 24, 24, 0.15); }
  .opc-payment-additional .payment-option-title {
    line-height: 1;
    text-decoration: underline;
    color: #181818;
    cursor: pointer; }
    .opc-payment-additional .payment-option-title[aria-expanded="true"] {
      text-decoration: none; }
    .opc-payment-additional .payment-option-title > span:after {
      display: none; }

.service-information-block {
  margin-top: 1rem;
  text-align: center; }

.custom-file-input-wrapper {
  position: relative;
  margin: 0; }
  @media screen and (min-width: 75em) {
    .custom-file-input-wrapper {
      max-width: 75%; } }
  .custom-file-input-wrapper .form-control {
    margin-bottom: 0;
    background: #f8f7f6 !important; }
  .custom-file-input-wrapper .input-group-button {
    padding-left: .5rem; }
    .custom-file-input-wrapper .input-group-button .button {
      position: relative;
      overflow: hidden;
      height: 3.75rem; }
      .custom-file-input-wrapper .input-group-button .button input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        opacity: 0;
        height: auto;
        padding: inherit;
        font-size: initial;
        display: initial;
        width: auto;
        cursor: pointer; }
      .custom-file-input-wrapper .input-group-button .button.primary {
        margin-left: .25rem; }

.payment-method .control.multi-control .html-field {
  line-height: 3.75rem; }

.payment-method .control.single-checkbox-field .option {
  line-height: 1.2;
  margin-bottom: .75rem;
  position: relative; }
  .payment-method .control.single-checkbox-field .option input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0; }
    .payment-method .control.single-checkbox-field .option input[type="checkbox"] + label,
    .payment-method .control.single-checkbox-field .option input[type="checkbox"] + .label {
      cursor: pointer;
      font-weight: normal;
      position: relative;
      margin: 0;
      line-height: 1.2;
      min-height: 1rem;
      padding-left: 1.4rem; }
      .payment-method .control.single-checkbox-field .option input[type="checkbox"] + label:before,
      .payment-method .control.single-checkbox-field .option input[type="checkbox"] + .label:before {
        display: block;
        content: '';
        height: 1rem;
        width: 1rem;
        line-height: 1rem;
        text-align: center;
        position: absolute;
        top: -1px;
        left: 0;
        border: 1px solid #9DA3AF;
        color: #181818;
        background: white; }
    .payment-method .control.single-checkbox-field .option input[type="checkbox"]:checked + label:before,
    .payment-method .control.single-checkbox-field .option input[type="checkbox"]:checked + .label:before {
      content: '✓'; }
    .payment-method .control.single-checkbox-field .option input[type="checkbox"]:disabled + label {
      opacity: .4; }
    .payment-method .control.single-checkbox-field .option input[type="checkbox"]:disabled + label:before,
    .payment-method .control.single-checkbox-field .option input[type="checkbox"]:disabled + .label:before {
      background: #eee; }

.payment-method .control.radio-field .option {
  line-height: 1.2;
  margin-bottom: .75rem;
  position: relative; }
  .payment-method .control.radio-field .option input[type="radio"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0; }
    .payment-method .control.radio-field .option input[type="radio"] + label,
    .payment-method .control.radio-field .option input[type="radio"] + .label {
      cursor: pointer;
      font-weight: normal;
      position: relative;
      margin: 0;
      line-height: 1.2;
      min-height: 1rem;
      padding-left: 1.4rem; }
      .payment-method .control.radio-field .option input[type="radio"] + label:before,
      .payment-method .control.radio-field .option input[type="radio"] + .label:before {
        display: block;
        content: '';
        height: 1rem;
        width: 1rem;
        line-height: 1rem;
        text-align: center;
        position: absolute;
        top: -1px;
        left: 0;
        border-radius: 50%;
        border: 1px solid #9DA3AF;
        color: #181818;
        background: white;
        z-index: 0; }
      .payment-method .control.radio-field .option input[type="radio"] + label:after,
      .payment-method .control.radio-field .option input[type="radio"] + .label:after {
        display: none;
        content: '';
        position: absolute;
        top: 0.125rem;
        left: 0.1875rem;
        height: 0.625rem;
        width: 0.625rem;
        border-radius: 50%;
        background: #1A2746; }
    .payment-method .control.radio-field .option input[type="radio"]:checked + label:after,
    .payment-method .control.radio-field .option input[type="radio"]:checked + .label:after {
      display: block; }
    .payment-method .control.radio-field .option input[type="radio"]:disabled + label:before,
    .payment-method .control.radio-field .option input[type="radio"]:disabled + .label:before {
      opacity: .3;
      background: #eee; }
  .payment-method .control.radio-field .option input[type="radio"] + label {
    font-weight: bold; }
    .payment-method .control.radio-field .option input[type="radio"] + label b {
      font-weight: bold; }

.checkout-footer {
  overflow: hidden;
  padding: .75rem 0 1rem;
  position: relative;
  text-align: center; }
  .checkout-footer p {
    margin-bottom: 0; }
  .checkout-footer:before {
    content: '';
    display: block;
    visibility: visible;
    border-top: 1px solid rgba(24, 24, 24, 0.15);
    position: absolute;
    top: 0;
    left: -50%;
    right: -50%; }

.customer-account-logoutsuccess .page-main,
.checkout-onepage-success .page-main {
  text-align: center;
  padding: 1rem 0; }
  @media screen and (min-width: 64em) {
    .customer-account-logoutsuccess .page-main,
    .checkout-onepage-success .page-main {
      padding: 5rem 0; } }
  @media screen and (min-width: 90.625em) {
    .customer-account-logoutsuccess .page-main,
    .checkout-onepage-success .page-main {
      padding: 6.7rem 0; } }
  .customer-account-logoutsuccess .page-main .page-title-wrapper,
  .checkout-onepage-success .page-main .page-title-wrapper {
    padding-bottom: 0;
    margin-bottom: 1rem; }
    .customer-account-logoutsuccess .page-main .page-title-wrapper:before,
    .checkout-onepage-success .page-main .page-title-wrapper:before {
      display: none; }
  .customer-account-logoutsuccess .page-main .action,
  .checkout-onepage-success .page-main .action {
    text-decoration: none; }
    .customer-account-logoutsuccess .page-main .action.primary,
    .checkout-onepage-success .page-main .action.primary {
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 1rem 0;
      padding: 0.82em 1em 0.87em;
      -webkit-appearance: none;
      border: 1px solid transparent;
      border-radius: 50px;
      transition: background-color 0.25s ease-out, color 0.25s ease-out;
      font-size: 1.1875rem;
      line-height: 1;
      text-align: center;
      cursor: pointer;
      background-color: #30A95E;
      color: #fff;
      font-family: "silka-semibold-webfont", sans-serif;
      letter-spacing: normal;
      font-weight: 400;
      text-transform: none;
      margin-bottom: 0;
      background-color: #1A2746;
      color: #fff; }
      [data-whatinput='mouse'] .customer-account-logoutsuccess .page-main .action.primary, [data-whatinput='mouse']
      .checkout-onepage-success .page-main .action.primary {
        outline: 0; }
      .customer-account-logoutsuccess .page-main .action.primary:hover, .customer-account-logoutsuccess .page-main .action.primary:focus,
      .checkout-onepage-success .page-main .action.primary:hover,
      .checkout-onepage-success .page-main .action.primary:focus {
        background-color: rgba(48, 169, 94, 0.75);
        color: #fff; }
      .customer-account-logoutsuccess .page-main .action.primary:active,
      .checkout-onepage-success .page-main .action.primary:active {
        color: rgba(255, 255, 255, 0.8) !important; }
      .customer-account-logoutsuccess .page-main .action.primary:hover, .customer-account-logoutsuccess .page-main .action.primary:focus,
      .checkout-onepage-success .page-main .action.primary:hover,
      .checkout-onepage-success .page-main .action.primary:focus {
        background-color: rgba(48, 169, 94, 0.75);
        color: #fff; }
    .customer-account-logoutsuccess .page-main .action.secondary,
    .checkout-onepage-success .page-main .action.secondary {
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 1rem 0;
      padding: 0.82em 1em 0.87em;
      -webkit-appearance: none;
      border: 1px solid transparent;
      border-radius: 50px;
      transition: background-color 0.25s ease-out, color 0.25s ease-out;
      font-size: 1.1875rem;
      line-height: 1;
      text-align: center;
      cursor: pointer;
      background-color: #30A95E;
      color: #fff;
      font-family: "silka-semibold-webfont", sans-serif;
      letter-spacing: normal;
      font-weight: 400;
      text-transform: none;
      margin-bottom: 0;
      background-color: #181818;
      color: #fff; }
      [data-whatinput='mouse'] .customer-account-logoutsuccess .page-main .action.secondary, [data-whatinput='mouse']
      .checkout-onepage-success .page-main .action.secondary {
        outline: 0; }
      .customer-account-logoutsuccess .page-main .action.secondary:hover, .customer-account-logoutsuccess .page-main .action.secondary:focus,
      .checkout-onepage-success .page-main .action.secondary:hover,
      .checkout-onepage-success .page-main .action.secondary:focus {
        background-color: rgba(48, 169, 94, 0.75);
        color: #fff; }
      .customer-account-logoutsuccess .page-main .action.secondary:active,
      .checkout-onepage-success .page-main .action.secondary:active {
        color: rgba(255, 255, 255, 0.8) !important; }
      .customer-account-logoutsuccess .page-main .action.secondary:hover, .customer-account-logoutsuccess .page-main .action.secondary:focus,
      .checkout-onepage-success .page-main .action.secondary:hover,
      .checkout-onepage-success .page-main .action.secondary:focus {
        background-color: black;
        color: #fff; }
  .customer-account-logoutsuccess .page-main .checkout-success,
  .customer-account-logoutsuccess .page-main #registration,
  .checkout-onepage-success .page-main .checkout-success,
  .checkout-onepage-success .page-main #registration {
    margin-bottom: 1rem; }
  .customer-account-logoutsuccess .page-main h1,
  .checkout-onepage-success .page-main h1 {
    text-align: center;
    font-size: 1.1875rem;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal; }
    @media screen and (min-width: 64em) {
      .customer-account-logoutsuccess .page-main h1,
      .checkout-onepage-success .page-main h1 {
        font-size: 1.5625rem; } }
    @media screen and (min-width: 90.625em) {
      .customer-account-logoutsuccess .page-main h1,
      .checkout-onepage-success .page-main h1 {
        font-size: 2.5rem; } }
  .customer-account-logoutsuccess .page-main .note-msg,
  .checkout-onepage-success .page-main .note-msg {
    text-align: center;
    font-size: 0.9375rem; }
    @media screen and (min-width: 64em) {
      .customer-account-logoutsuccess .page-main .note-msg,
      .checkout-onepage-success .page-main .note-msg {
        font-size: 1.0625rem; } }

.cart-empty {
  text-align: center;
  padding: 1rem 0; }
  @media screen and (min-width: 64em) {
    .cart-empty {
      padding: 5rem 0; } }
  @media screen and (min-width: 90.625em) {
    .cart-empty {
      padding: 6.7rem 0; } }
  .cart-empty h2 {
    text-align: center;
    font-size: 1.1875rem;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal; }
    @media screen and (min-width: 64em) {
      .cart-empty h2 {
        font-size: 1.5625rem; } }
    @media screen and (min-width: 90.625em) {
      .cart-empty h2 {
        font-size: 2.5rem; } }
  .cart-empty p {
    text-align: center;
    font-size: 0.9375rem; }
    @media screen and (min-width: 64em) {
      .cart-empty p {
        font-size: 1.0625rem; } }

/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.ui-datepicker {
  display: none;
  z-index: 999999 !important; }

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: .2em 0; }

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em;
  cursor: pointer; }

.ui-datepicker .ui-datepicker-next-hover span, .ui-datepicker .ui-datepicker-prev-hover span {
  border-color: transparent #ff8400 transparent transparent; }

.ui-datepicker .ui-datepicker-prev {
  left: 2px; }

.ui-datepicker .ui-datepicker-next {
  right: 2px; }

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -5px;
  font-size: 0px;
  width: 0px;
  height: 0px;
  border: solid;
  border-width: 5px 8px 5px 0;
  border-color: transparent #ff9635 transparent transparent; }

.ui-datepicker .ui-datepicker-next span {
  margin-left: 0px;
  top: 50%;
  margin-top: -5px;
  border-width: 5px 0 5px 8px;
  border-color: transparent transparent transparent #ff9635; }

.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center; }

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0; }

.ui-datepicker select.ui-datepicker-month-year {
  width: 100%; }

.ui-datepicker table {
  width: 100%;
  font-size: .9em; }

.ui-datepicker td span, .ui-datepicker td a {
  display: block;
  padding: 4px;
  text-align: right;
  text-decoration: none;
  color: #000000; }

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right; }

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left; }

.ui-datepicker-rtl {
  direction: rtl; }

.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto; }

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto; }

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto; }

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto; }

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: right; }

.ui-datepicker-rtl .ui-datepicker-group {
  float: right; }

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px; }

.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px; }

.ui-timepicker-div .ui-widget-header {
  margin-bottom: 8px; }

.ui-timepicker-div dl {
  text-align: left; }

.ui-timepicker-div dl dt {
  height: 25px;
  margin-bottom: -22px; }

.ui-timepicker-div dl .ui_tpicker_time_label {
  margin-bottom: -25px; }

.ui-timepicker-div dl dd {
  margin: 0 10px 10px 65px; }

.ui-timepicker-div td {
  font-size: 90%; }

.ui-tpicker-grid-label {
  background: none;
  border: none;
  margin: 0;
  padding: 0; }

.ui-datepicker {
  background: #ffffff;
  padding: 15px;
  border: #ffffff 4px solid;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #efefef;
  -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.35); }

.ui-datepicker .ui-datepicker-header {
  background: transparent;
  border: none;
  padding-bottom: 10px; }

.ui-datepicker .ui-datepicker-title .ui-datepicker-month {
  width: 47%;
  margin-right: 6%; }

.ui-datepicker .ui-datepicker-title .ui-datepicker-year {
  width: 47%; }

.ui-datepicker .ui-datepicker-calendar td {
  border: #cfcfcf 1px solid; }

.ui-datepicker .ui-datepicker-calendar td a {
  padding: 4px;
  display: block; }

.ui-datepicker .ui-datepicker-calendar td .ui-state-hover {
  background: #f9eae7; }

.ui-datepicker .ui-datepicker-calendar {
  background: #ffffff;
  border: #cfcfcf 1px solid;
  border-collapse: collapse; }

.ui-datepicker .ui-datepicker-calendar th {
  background: #efefef;
  border: #cfcfcf 1px solid;
  padding: 4px;
  text-transform: uppercase; }

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-week-col {
  text-align: center;
  border: #cfcfcf 1px solid; }

.ui-datepicker {
  width: auto; }

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today {
  background: #f3d7d2; }

.ui-datepicker .ui-datepicker-calendar .ui-state-active {
  background: #ffdf66; }

.ui-timepicker-div .ui-widget-header {
  margin-bottom: 8px; }

.ui-timepicker-div dl {
  text-align: left; }

.ui-timepicker-div dl dd {
  margin: 0 0 10px 65px; }

.ui-timepicker-div td {
  font-size: 90%; }

.ui-tpicker-grid-label {
  background: none;
  border: none;
  margin: 0;
  padding: 0; }

.ui-slider {
  position: relative;
  text-align: left; }

.ui-slider-horizontal .ui-slider-handle {
  margin-left: -5px; }

.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  cursor: default; }

.ui-slider-horizontal {
  height: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: none;
  background: #dadada; }

.ui-slider-handle {
  height: 10px;
  width: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #f98b25;
  display: block;
  position: absolute; }

.ui-timepicker-div {
  padding: 10px 0 5px 0; }

.ui-datepicker .ui-datepicker-buttonpane {
  overflow: hidden;
  padding-top: 10px;
  border-top: #cfcfcf 1px solid; }

.ui-datepicker .ui-datepicker-calendar .ui-state-disabled {
  background: #f9f9f9; }

.ui-datepicker .ui-datepicker-calendar .ui-state-disabled span {
  color: #959595; }

/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */
.ui-datepicker-cover {
  position: absolute;
  /*must have*/
  z-index: -1;
  /*must have*/
  filter: mask();
  /*must have*/
  top: -4px;
  /*must have*/
  left: -4px;
  /*must have*/
  width: 200px;
  /*must have*/
  height: 200px;
  /*must have*/ }

.table.data.grouped .table-caption {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.table.data.grouped .col.item .product-item-name {
  font-weight: 300;
  font-size: 1rem; }

.table.data.grouped .col.item .price-box {
  margin: 10px 0 0; }
  .table.data.grouped .col.item .price-box > .price-container {
    font-size: 1rem;
    font-weight: 600; }

.table.data.grouped .col.qty {
  padding-right: 0;
  width: 10%; }
  .table.data.grouped .col.qty .control.qty .input-text {
    text-align: center;
    -moz-appearance: textfield; }
    .table.data.grouped .col.qty .control.qty .input-text::-webkit-outer-spin-button, .table.data.grouped .col.qty .control.qty .input-text::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }

.table.data.grouped .price-box {
  display: block; }

.fotorama-video-container:after {
  background: url(../Magento_ProductVideo/images/gallery-sprite.png) bottom right;
  background-size: 108px 145px;
  height: 70px;
  width: 70px;
  bottom: 0;
  content: '';
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 12px;
  cursor: pointer;
  transition: transform linear .2s; }
  @media print, screen and (min-width: 48em) {
    .fotorama-video-container:after {
      height: 100px;
      width: 100px;
      background-size: auto; } }

.fotorama-video-container .magnify-lens {
  display: none !important; }

.fotorama-video-container.video-unplayed:hover img {
  opacity: .6; }

.fotorama-video-container.video-unplayed:hover:after {
  transform: scale(1.25); }

.video-thumb-icon:after {
  background: none;
  bottom: 0;
  content: '';
  height: 40px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 10px;
  width: 49px; }
  @media print, screen and (min-width: 48em) {
    .video-thumb-icon:after {
      background: url(../Magento_ProductVideo/images/gallery-sprite.png) bottom left; } }

.product-video {
  bottom: 0;
  height: 75%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }
  .product-video iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999; }

.fotorama__stage__shaft:focus .fotorama__stage__frame.fotorama__active:after {
  bottom: 0;
  content: '';
  height: 100px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 12px;
  width: 100px; }

.fotorama__product-video--loading:after {
  visibility: hidden; }

.page-product-giftcard .product-info-sidebar .product-add-form .price-box {
  display: flex; }

.page-product-giftcard .product-info-sidebar .giftcard-amount {
  flex: 1 0 100%;
  padding: 0;
  border: 0;
  margin: 0; }
  .page-product-giftcard .product-info-sidebar .giftcard-amount .legend {
    width: 100%;
    margin: 0;
    padding: 0;
    background: none; }
  [data-whatinput='mouse'] .page-product-giftcard .product-info-sidebar .giftcard-amount {
    outline: 0; }
  @media print, screen and (min-width: 48em) {
    .page-product-giftcard .product-info-sidebar .giftcard-amount {
      margin-right: -1.0625rem;
      margin-left: -1.0625rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .page-product-giftcard .product-info-sidebar .giftcard-amount {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 48em) {
    .page-product-giftcard .product-info-sidebar .giftcard-amount {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 64em) {
    .page-product-giftcard .product-info-sidebar .giftcard-amount {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 75em) {
    .page-product-giftcard .product-info-sidebar .giftcard-amount {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 48em) and (min-width: 90.625em) {
    .page-product-giftcard .product-info-sidebar .giftcard-amount {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .page-product-giftcard .product-info-sidebar .giftcard-amount .field.open-amount {
      width: 50%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .page-product-giftcard .product-info-sidebar .giftcard-amount .field.open-amount {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  @media print, screen and (min-width: 48em) {
      .page-product-giftcard .product-info-sidebar .giftcard-amount .field.open-amount:last-child:not(:first-child) {
        float: right; } }
  .page-product-giftcard .product-info-sidebar .giftcard-amount .field.open-amount .control {
    white-space: normal; }
    .page-product-giftcard .product-info-sidebar .giftcard-amount .field.open-amount .control .note {
      color: rgba(24, 24, 24, 0.8);
      font-size: 11px;
      line-height: 1.4;
      letter-spacing: normal;
      margin: -6px 0 0; }
  @media print, screen and (min-width: 48em) {
    .page-product-giftcard .product-info-sidebar .giftcard-amount .field.amount {
      width: 50%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .page-product-giftcard .product-info-sidebar .giftcard-amount .field.amount {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  @media print, screen and (min-width: 48em) {
      .page-product-giftcard .product-info-sidebar .giftcard-amount .field.amount:last-child:not(:first-child) {
        float: right; } }

.page-product-giftcard .product-info-sidebar .giftcard.form {
  margin-top: 1.0625rem;
  margin-bottom: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    .page-product-giftcard .product-info-sidebar .giftcard.form {
      margin-top: 1.25rem; } }
  @media print, screen and (min-width: 40em) {
    .page-product-giftcard .product-info-sidebar .giftcard.form {
      margin-bottom: 1.25rem; } }
  .page-product-giftcard .product-info-sidebar .giftcard.form .fieldset.giftcard {
    min-inline-size: auto; }
    .page-product-giftcard .product-info-sidebar .giftcard.form .fieldset.giftcard .legend {
      font-family: "silka-regular-webfont", sans-serif;
      letter-spacing: normal;
      font-size: 1.75rem;
      font-weight: 400;
      border: 0;
      padding: 0; }
      @media print, screen and (min-width: 40em) {
        .page-product-giftcard .product-info-sidebar .giftcard.form .fieldset.giftcard .legend {
          font-size: 2.125rem; } }
      @media screen and (min-width: 64em) {
        .page-product-giftcard .product-info-sidebar .giftcard.form .fieldset.giftcard .legend {
          font-size: 2.5rem; } }
    .page-product-giftcard .product-info-sidebar .giftcard.form .fieldset.giftcard > br {
      display: none; }

.product-items .product-item .price-box > .price-box,
.product-items .ais-Hits-item .price-box > .price-box,
.product-items .ais-InfiniteHits-item .price-box > .price-box,
.ais-InfiniteHits .product-item .price-box > .price-box,
.ais-InfiniteHits .ais-Hits-item .price-box > .price-box,
.ais-InfiniteHits .ais-InfiniteHits-item .price-box > .price-box {
  font-size: 0.9375rem;
  font-family: "silka-regular-webfont", sans-serif;
  letter-spacing: normal;
  font-weight: 400;
  color: #1A2746;
  line-height: 1; }
  @media screen and (min-width: 64em) {
    .product-items .product-item .price-box > .price-box,
    .product-items .ais-Hits-item .price-box > .price-box,
    .product-items .ais-InfiniteHits-item .price-box > .price-box,
    .ais-InfiniteHits .product-item .price-box > .price-box,
    .ais-InfiniteHits .ais-Hits-item .price-box > .price-box,
    .ais-InfiniteHits .ais-InfiniteHits-item .price-box > .price-box {
      font-size: 1.0625rem; } }
  @media screen and (min-width: 75em) {
    .product-items .product-item .price-box > .price-box,
    .product-items .ais-Hits-item .price-box > .price-box,
    .product-items .ais-InfiniteHits-item .price-box > .price-box,
    .ais-InfiniteHits .product-item .price-box > .price-box,
    .ais-InfiniteHits .ais-Hits-item .price-box > .price-box,
    .ais-InfiniteHits .ais-InfiniteHits-item .price-box > .price-box {
      font-size: 1.0625rem; } }

.product-info-sidebar-configurable .product-info-bundle-row {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem; }
  .product-info-sidebar-configurable .product-info-bundle-row::before, .product-info-sidebar-configurable .product-info-bundle-row::after {
    display: table;
    content: ' '; }
  .product-info-sidebar-configurable .product-info-bundle-row::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .product-info-sidebar-configurable .product-info-bundle-row {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .product-info-sidebar-configurable .product-info-bundle-row {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .product-info-sidebar-configurable .product-info-bundle-row {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .product-info-sidebar-configurable .product-info-bundle-row {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .product-info-sidebar-configurable .product-info-bundle-row {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }

.product-info-sidebar-configurable .product-form-row-bundle {
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem;
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem;
  display: flex;
  align-items: center;
  flex-direction: column; }
  @media print, screen and (min-width: 40em) {
    .product-info-sidebar-configurable .product-form-row-bundle {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .product-info-sidebar-configurable .product-form-row-bundle:last-child:not(:first-child) {
    float: right; }
  @media print, screen and (min-width: 40em) {
    .product-info-sidebar-configurable .product-form-row-bundle {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .product-info-sidebar-configurable .product-form-row-bundle:last-child:not(:first-child) {
    float: right; }
  @media screen and (min-width: 75em) {
    .product-info-sidebar-configurable .product-form-row-bundle {
      width: 66.66667%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem; } }
  @media screen and (min-width: 75em) and (min-width: 40em) {
    .product-info-sidebar-configurable .product-form-row-bundle {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  @media screen and (min-width: 75em) {
      .product-info-sidebar-configurable .product-form-row-bundle:last-child:not(:first-child) {
        float: right; } }
  @media print, screen and (min-width: 48em) {
    .product-info-sidebar-configurable .product-form-row-bundle {
      align-items: flex-end;
      margin-top: 15px; } }
  .product-info-sidebar-configurable .product-form-row-bundle .product-info-stock-sku {
    order: 1;
    align-self: flex-start; }
    @media screen and (min-width: 64em) {
      .product-info-sidebar-configurable .product-form-row-bundle .product-info-stock-sku {
        margin-top: 0.53125rem;
        align-self: auto;
        order: 2; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .product-info-sidebar-configurable .product-form-row-bundle .product-info-stock-sku {
      margin-top: 0.625rem; } }
  .product-info-sidebar-configurable .product-form-row-bundle .product-bundle-addto-container {
    margin-top: 1.0625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    order: 2; }
    @media print, screen and (min-width: 40em) {
      .product-info-sidebar-configurable .product-form-row-bundle .product-bundle-addto-container {
        margin-top: 1.25rem; } }
    @media screen and (min-width: 64em) {
      .product-info-sidebar-configurable .product-form-row-bundle .product-bundle-addto-container {
        flex-direction: row;
        width: auto;
        order: 1;
        margin-top: 0; } }
    .product-info-sidebar-configurable .product-form-row-bundle .product-bundle-addto-container .bundle-actions {
      order: 1; }
      @media screen and (min-width: 64em) {
        .product-info-sidebar-configurable .product-form-row-bundle .product-bundle-addto-container .bundle-actions {
          order: 2; } }
      @media screen and (min-width: 64em) {
        .product-info-sidebar-configurable .product-form-row-bundle .product-bundle-addto-container .bundle-actions .action.primary.customize {
          width: auto; } }
    .product-info-sidebar-configurable .product-form-row-bundle .product-bundle-addto-container .product-social-links {
      margin-right: 1.0625rem;
      order: 2; }
      @media print, screen and (min-width: 40em) {
        .product-info-sidebar-configurable .product-form-row-bundle .product-bundle-addto-container .product-social-links {
          margin-right: 1.25rem; } }
      @media screen and (min-width: 64em) {
        .product-info-sidebar-configurable .product-form-row-bundle .product-bundle-addto-container .product-social-links {
          order: 1; } }

.product-info-sidebar-configurable .product-info-price {
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem;
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    .product-info-sidebar-configurable .product-info-price {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .product-info-sidebar-configurable .product-info-price:last-child:not(:first-child) {
    float: right; }
  @media print, screen and (min-width: 40em) {
    .product-info-sidebar-configurable .product-info-price {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .product-info-sidebar-configurable .product-info-price:last-child:not(:first-child) {
    float: right; }
  @media screen and (min-width: 75em) {
    .product-info-sidebar-configurable .product-info-price {
      width: 33.33333%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem; } }
  @media screen and (min-width: 75em) and (min-width: 40em) {
    .product-info-sidebar-configurable .product-info-price {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  @media screen and (min-width: 75em) {
      .product-info-sidebar-configurable .product-info-price:last-child:not(:first-child) {
        float: right; } }
  .product-info-sidebar-configurable .product-info-price .price-box .price-container {
    line-height: 1.1; }
    .product-info-sidebar-configurable .product-info-price .price-box .price-container .price-label {
      display: block;
      font-weight: 400;
      font-size: 1.125rem;
      color: rgba(24, 24, 24, 0.7); }
    .product-info-sidebar-configurable .product-info-price .price-box .price-container .price-wrapper {
      font-size: 1.875rem;
      font-weight: 700;
      color: #181818; }

.bundle-options-container {
  clear: both;
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem; }
  .bundle-options-container::before, .bundle-options-container::after {
    display: table;
    content: ' '; }
  .bundle-options-container::after {
    clear: both; }
  .bundle-options-container::before, .bundle-options-container::after {
    display: table;
    content: ' '; }
  .bundle-options-container::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .bundle-options-container {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .bundle-options-container {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .bundle-options-container {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .bundle-options-container {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .bundle-options-container {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  .bundle-options-container .bundle-options-wrapper {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .bundle-options-container .bundle-options-wrapper {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .bundle-options-container .bundle-options-wrapper:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 40em) {
      .bundle-options-container .bundle-options-wrapper {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .bundle-options-container .bundle-options-wrapper:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 48em) {
      .bundle-options-container .bundle-options-wrapper {
        width: 50%;
        float: left;
        padding-right: 1.0625rem;
        padding-left: 1.0625rem; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .bundle-options-container .bundle-options-wrapper {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
    @media print, screen and (min-width: 48em) {
        .bundle-options-container .bundle-options-wrapper:last-child:not(:first-child) {
          float: right; } }
  .bundle-options-container .legend.title {
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-size: 1.75rem;
    font-weight: 400;
    border: 0;
    padding: 0; }
    @media print, screen and (min-width: 40em) {
      .bundle-options-container .legend.title {
        font-size: 2.125rem; } }
    @media screen and (min-width: 64em) {
      .bundle-options-container .legend.title {
        font-size: 2.5rem; } }
  .bundle-options-container .product-add-form {
    display: none; }
  @media screen and (min-width: 64em) {
    .bundle-options-container .product-options-wrapper {
      width: 83.33333%; } }
  .bundle-options-container .product-options-wrapper .fieldset {
    padding: 0;
    border: 0;
    margin: 0; }
    .bundle-options-container .product-options-wrapper .fieldset .legend {
      width: 100%;
      margin: 0;
      padding: 0;
      background: none; }
    [data-whatinput='mouse'] .bundle-options-container .product-options-wrapper .fieldset {
      outline: 0; }
    .bundle-options-container .product-options-wrapper .fieldset > .field {
      border-top: 1px #ccc solid;
      margin: 1.15rem 0;
      padding-top: .6rem; }
      .bundle-options-container .product-options-wrapper .fieldset > .field:first-of-type {
        border-top: 0;
        padding-top: 0; }
      .bundle-options-container .product-options-wrapper .fieldset > .field > .label {
        font-size: 0.9375rem; }
      @media print, screen and (min-width: 48em) {
        .bundle-options-container .product-options-wrapper .fieldset > .field .control {
          width: 83.33333%; } }
      .bundle-options-container .product-options-wrapper .fieldset > .field .control .multiselect {
        padding: 0; }
      .bundle-options-container .product-options-wrapper .fieldset > .field .price-notice {
        font-size: 0.875rem;
        font-weight: 600; }
      .bundle-options-container .product-options-wrapper .fieldset > .field.choice input {
        float: left;
        margin-top: 4px; }
      .bundle-options-container .product-options-wrapper .fieldset > .field.choice .label {
        display: block; }
        .bundle-options-container .product-options-wrapper .fieldset > .field.choice .label::before, .bundle-options-container .product-options-wrapper .fieldset > .field.choice .label::after {
          display: table;
          content: ' '; }
        .bundle-options-container .product-options-wrapper .fieldset > .field.choice .label::after {
          clear: both; }
      .bundle-options-container .product-options-wrapper .fieldset > .field.choice .price-excluding-tax {
        display: inline-block; }
  .bundle-options-container .action.back {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    padding: 0.82em 1em 0.87em;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 50px;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 1.1875rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: #30A95E;
    color: #fff;
    font-family: "silka-semibold-webfont", sans-serif;
    letter-spacing: normal;
    font-weight: 400;
    text-transform: none;
    margin-bottom: 0; }
    [data-whatinput='mouse'] .bundle-options-container .action.back {
      outline: 0; }
    .bundle-options-container .action.back:hover, .bundle-options-container .action.back:focus {
      background-color: rgba(48, 169, 94, 0.75);
      color: #fff; }
    .bundle-options-container .action.back:active {
      color: rgba(255, 255, 255, 0.8) !important; }
    .bundle-options-container .action.back:before {
      content: none; }
    .bundle-options-container .action.back:hover span {
      text-decoration: none; }
  .bundle-options-container .block-bundle-summary {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .bundle-options-container .block-bundle-summary {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .bundle-options-container .block-bundle-summary:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 40em) {
      .bundle-options-container .block-bundle-summary {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .bundle-options-container .block-bundle-summary:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 48em) {
      .bundle-options-container .block-bundle-summary {
        width: 50%;
        float: left;
        padding-right: 1.0625rem;
        padding-left: 1.0625rem;
        position: relative; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .bundle-options-container .block-bundle-summary {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
    @media print, screen and (min-width: 48em) {
        .bundle-options-container .block-bundle-summary:last-child:not(:first-child) {
          float: right; } }
    .bundle-options-container .block-bundle-summary > .title {
      font-family: "silka-regular-webfont", sans-serif;
      letter-spacing: normal;
      font-size: 1.75rem;
      font-weight: 400; }
      @media print, screen and (min-width: 40em) {
        .bundle-options-container .block-bundle-summary > .title {
          font-size: 2.125rem; } }
      @media screen and (min-width: 64em) {
        .bundle-options-container .block-bundle-summary > .title {
          font-size: 2.5rem; } }
      .bundle-options-container .block-bundle-summary > .title > strong {
        font-weight: inherit; }
    .bundle-options-container .block-bundle-summary .bundle-info {
      margin-top: 2px;
      margin-bottom: 0.53125rem;
      position: relative;
      padding: 1.5rem 0;
      padding-left: 23px;
      padding-right: 23px;
      margin-left: -23px;
      margin-right: -23px;
      background-color: white; }
      @media print, screen and (min-width: 40em) {
        .bundle-options-container .block-bundle-summary .bundle-info {
          margin-bottom: 0.625rem; } }
      @media print, screen and (min-width: 40em) {
        .bundle-options-container .block-bundle-summary .bundle-info {
          margin-left: 0;
          margin-right: 0;
          padding: 0 2rem;
          margin-bottom: 0.25rem; } }
      @media screen and (min-width: 64em) {
        .bundle-options-container .block-bundle-summary .bundle-info {
          padding: 0 2.1rem 0.5rem;
          margin-bottom: 0.25rem; } }
      @media screen and (min-width: 75em) {
        .bundle-options-container .block-bundle-summary .bundle-info {
          padding: 0 2.1rem 0.5rem;
          margin-bottom: 0.25rem; } }
      .bundle-options-container .block-bundle-summary .bundle-info .fieldset {
        padding: 0;
        border: 0;
        margin: 0;
        -webkit-tap-highlight-color: transparent;
        display: flex; }
        .bundle-options-container .block-bundle-summary .bundle-info .fieldset .legend {
          width: 100%;
          margin: 0;
          padding: 0;
          background: none; }
        [data-whatinput='mouse'] .bundle-options-container .block-bundle-summary .bundle-info .fieldset {
          outline: 0; }
        .bundle-options-container .block-bundle-summary .bundle-info .fieldset .field.qty {
          flex: 1 0 auto;
          max-width: 70px; }
        .bundle-options-container .block-bundle-summary .bundle-info .fieldset .actions {
          margin-left: 1.0625rem;
          margin-right: 1.0625rem;
          flex: 1 0 auto;
          margin-top: 2.25rem; }
          @media print, screen and (min-width: 40em) {
            .bundle-options-container .block-bundle-summary .bundle-info .fieldset .actions {
              margin-left: 1.25rem; } }
          @media print, screen and (min-width: 40em) {
            .bundle-options-container .block-bundle-summary .bundle-info .fieldset .actions {
              margin-right: 1.25rem; } }
    .bundle-options-container .block-bundle-summary > .title {
      padding-bottom: 1rem; }
    .bundle-options-container .block-bundle-summary .bundle-summary {
      margin-bottom: 2.125rem; }
      @media print, screen and (min-width: 40em) {
        .bundle-options-container .block-bundle-summary .bundle-summary {
          margin-bottom: 2.5rem; } }
      @media print, screen and (min-width: 48em) {
        .bundle-options-container .block-bundle-summary .bundle-summary {
          margin-bottom: 0; } }
      .bundle-options-container .block-bundle-summary .bundle-summary.empty {
        display: none; }
      .bundle-options-container .block-bundle-summary .bundle-summary .subtitle {
        margin-bottom: 0.53125rem;
        font-family: "silka-regular-webfont", sans-serif;
        letter-spacing: normal;
        display: block;
        border-bottom: 1px #ccc solid;
        padding-bottom: 5px;
        font-weight: 400;
        font-size: 1.625rem; }
        @media print, screen and (min-width: 40em) {
          .bundle-options-container .block-bundle-summary .bundle-summary .subtitle {
            margin-bottom: 0.625rem; } }
        @media print, screen and (min-width: 40em) {
          .bundle-options-container .block-bundle-summary .bundle-summary .subtitle {
            font-size: 1.6875rem; } }
        @media screen and (min-width: 64em) {
          .bundle-options-container .block-bundle-summary .bundle-summary .subtitle {
            font-size: 1.75rem; } }
        @media print, screen and (min-width: 48em) {
          .bundle-options-container .block-bundle-summary .bundle-summary .subtitle {
            padding-bottom: 0;
            margin-bottom: 0; } }
        .bundle-options-container .block-bundle-summary .bundle-summary .subtitle > strong {
          font-weight: inherit; }
      .bundle-options-container .block-bundle-summary .bundle-summary .bundle.items {
        margin-top: 0.53125rem;
        margin: 0;
        list-style: none; }
        @media print, screen and (min-width: 40em) {
          .bundle-options-container .block-bundle-summary .bundle-summary .bundle.items {
            margin-top: 0.625rem; } }
        .bundle-options-container .block-bundle-summary .bundle-summary .bundle.items li {
          padding: 0; }
          .bundle-options-container .block-bundle-summary .bundle-summary .bundle.items li:after {
            display: none; }
        .bundle-options-container .block-bundle-summary .bundle-summary .bundle.items > li {
          margin: 0.3125rem 0; }
          .bundle-options-container .block-bundle-summary .bundle-summary .bundle.items > li .label {
            background-color: #1A2746; }
    .bundle-options-container .block-bundle-summary .price-box .price-label {
      display: block; }
    .bundle-options-container .block-bundle-summary .box-tocart .action.tocart {
      font-size: 1.1875rem;
      font-weight: 400;
      font-family: "silka-regular-webfont", sans-serif;
      text-transform: none;
      padding: 1.1rem 1rem; }
    .bundle-options-container .block-bundle-summary .product-addto-links {
      text-align: center; }
      @media print, screen and (min-width: 48em) {
        .bundle-options-container .block-bundle-summary .product-addto-links {
          text-align: left; } }
      .bundle-options-container .block-bundle-summary .product-addto-links > .action {
        margin-right: 5%; }
    .bundle-options-container .block-bundle-summary .product-image-container,
    .bundle-options-container .block-bundle-summary .product.name,
    .bundle-options-container .block-bundle-summary .stock {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }
    .bundle-options-container .block-bundle-summary .price-container {
      font-weight: 400;
      font-size: 1.3125rem; }
      @media print, screen and (min-width: 48em) {
        .bundle-options-container .block-bundle-summary .price-container {
          font-weight: 400; } }
      @media screen and (min-width: 64em) {
        .bundle-options-container .block-bundle-summary .price-container {
          margin-top: 0; } }
      @media screen and (min-width: 75em) {
        .bundle-options-container .block-bundle-summary .price-container {
          margin-top: 0;
          font-size: 1.75rem; } }
      .bundle-options-container .block-bundle-summary .price-container .weee .price {
        font-weight: 400; }
        @media print, screen and (min-width: 48em) {
          .bundle-options-container .block-bundle-summary .price-container .weee .price {
            font-weight: 400; } }
  .bundle-options-container p.required {
    color: #D41367; }
  .bundle-options-container .nested .choice {
    line-height: 1.2;
    margin-bottom: .75rem;
    position: relative; }
    .bundle-options-container .nested .choice input[type="radio"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0; }
      .bundle-options-container .nested .choice input[type="radio"] + label,
      .bundle-options-container .nested .choice input[type="radio"] + .label {
        cursor: pointer;
        font-weight: normal;
        position: relative;
        margin: 0;
        line-height: 1.2;
        min-height: 1rem;
        padding-left: 1.4rem; }
        .bundle-options-container .nested .choice input[type="radio"] + label:before,
        .bundle-options-container .nested .choice input[type="radio"] + .label:before {
          display: block;
          content: '';
          height: 1rem;
          width: 1rem;
          line-height: 1rem;
          text-align: center;
          position: absolute;
          top: -1px;
          left: 0;
          border-radius: 50%;
          border: 1px solid #9DA3AF;
          color: #181818;
          background: white;
          z-index: 0; }
        .bundle-options-container .nested .choice input[type="radio"] + label:after,
        .bundle-options-container .nested .choice input[type="radio"] + .label:after {
          display: none;
          content: '';
          position: absolute;
          top: 0.125rem;
          left: 0.1875rem;
          height: 0.625rem;
          width: 0.625rem;
          border-radius: 50%;
          background: #1A2746; }
      .bundle-options-container .nested .choice input[type="radio"]:checked + label:after,
      .bundle-options-container .nested .choice input[type="radio"]:checked + .label:after {
        display: block; }
      .bundle-options-container .nested .choice input[type="radio"]:disabled + label:before,
      .bundle-options-container .nested .choice input[type="radio"]:disabled + .label:before {
        opacity: .3;
        background: #eee; }
  .bundle-options-container .nested .field.qty:last-child {
    margin-bottom: 0; }
  .bundle-options-container .nested .field.qty .label {
    font-weight: 400; }
  .bundle-options-container .nested .field.qty .control {
    width: 15%;
    min-width: 60px; }
    .bundle-options-container .nested .field.qty .control .qty {
      -moz-appearance: textfield; }
      .bundle-options-container .nested .field.qty .control .qty::-webkit-outer-spin-button, .bundle-options-container .nested .field.qty .control .qty::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }
  .bundle-options-container .product-options-bottom {
    clear: left;
    margin: 0;
    padding-top: 0; }

.product-items .product-item .price-box .price-from > .price-container,
.product-items .product-item .price-box .price-to > .price-container,
.product-items .ais-Hits-item .price-box .price-from > .price-container,
.product-items .ais-Hits-item .price-box .price-to > .price-container,
.product-items .ais-InfiniteHits-item .price-box .price-from > .price-container,
.product-items .ais-InfiniteHits-item .price-box .price-to > .price-container,
.ais-InfiniteHits .product-item .price-box .price-from > .price-container,
.ais-InfiniteHits .product-item .price-box .price-to > .price-container,
.ais-InfiniteHits .ais-Hits-item .price-box .price-from > .price-container,
.ais-InfiniteHits .ais-Hits-item .price-box .price-to > .price-container,
.ais-InfiniteHits .ais-InfiniteHits-item .price-box .price-from > .price-container,
.ais-InfiniteHits .ais-InfiniteHits-item .price-box .price-to > .price-container {
  font-size: 0.9375rem;
  font-family: "silka-regular-webfont", sans-serif;
  letter-spacing: normal;
  font-weight: 400;
  color: #1A2746;
  line-height: 1; }
  @media screen and (min-width: 64em) {
    .product-items .product-item .price-box .price-from > .price-container,
    .product-items .product-item .price-box .price-to > .price-container,
    .product-items .ais-Hits-item .price-box .price-from > .price-container,
    .product-items .ais-Hits-item .price-box .price-to > .price-container,
    .product-items .ais-InfiniteHits-item .price-box .price-from > .price-container,
    .product-items .ais-InfiniteHits-item .price-box .price-to > .price-container,
    .ais-InfiniteHits .product-item .price-box .price-from > .price-container,
    .ais-InfiniteHits .product-item .price-box .price-to > .price-container,
    .ais-InfiniteHits .ais-Hits-item .price-box .price-from > .price-container,
    .ais-InfiniteHits .ais-Hits-item .price-box .price-to > .price-container,
    .ais-InfiniteHits .ais-InfiniteHits-item .price-box .price-from > .price-container,
    .ais-InfiniteHits .ais-InfiniteHits-item .price-box .price-to > .price-container {
      font-size: 1.0625rem; } }
  @media screen and (min-width: 75em) {
    .product-items .product-item .price-box .price-from > .price-container,
    .product-items .product-item .price-box .price-to > .price-container,
    .product-items .ais-Hits-item .price-box .price-from > .price-container,
    .product-items .ais-Hits-item .price-box .price-to > .price-container,
    .product-items .ais-InfiniteHits-item .price-box .price-from > .price-container,
    .product-items .ais-InfiniteHits-item .price-box .price-to > .price-container,
    .ais-InfiniteHits .product-item .price-box .price-from > .price-container,
    .ais-InfiniteHits .product-item .price-box .price-to > .price-container,
    .ais-InfiniteHits .ais-Hits-item .price-box .price-from > .price-container,
    .ais-InfiniteHits .ais-Hits-item .price-box .price-to > .price-container,
    .ais-InfiniteHits .ais-InfiniteHits-item .price-box .price-from > .price-container,
    .ais-InfiniteHits .ais-InfiniteHits-item .price-box .price-to > .price-container {
      font-size: 1.0625rem; } }

@font-face {
  font-family: 'magento-icons';
  src: url("../Magento_Company/fonts/Magento-Icons.woff2") format("woff2"), url("../Magento_Company/fonts/Magento-Icons.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: auto; }

.jstree ul > li::after {
  content: ""; }

.jstree > ul ul li {
  background: none;
  border-left: 1px dashed #a6a6a6;
  margin-left: 2.4rem;
  padding: .5rem 0;
  position: relative; }

.jstree > ul ul li.jstree-last {
  background: none;
  border-left: none;
  left: .1rem;
  padding-bottom: 0;
  position: relative; }

.jstree > ul ul li.jstree-last:after {
  border-left: 1px dashed #a6a6a6;
  content: '';
  height: 2.3rem;
  left: -.1rem;
  position: absolute;
  top: 0; }

.jstree > ul ul li.jstree-leaf > .jstree-icon.jstree-ocl {
  background: none;
  display: inline;
  padding-right: 2.5rem;
  width: 2.5rem; }

.jstree > ul ul li.jstree-leaf > .jstree-icon.jstree-ocl:after {
  right: 0;
  top: 1.7rem;
  width: 100%; }

.jstree > ul ul li > .jstree-icon.jstree-ocl {
  position: relative; }

.jstree > ul ul li > .jstree-icon.jstree-ocl:after {
  border-top: 1px dashed #a6a6a6;
  content: '';
  height: .1rem;
  position: absolute;
  right: 1.7rem;
  top: 1.1rem;
  width: 1.3rem; }

.jstree li > .jstree-icon {
  padding-right: 1.5rem; }

.jstree li a {
  height: 2.2rem;
  line-height: 2.2rem;
  padding: .4rem 1rem .4rem .2rem; }

.jstree li a > .jstree-themeicon-custom {
  margin-left: 10px;
  margin-right: .7rem; }

.jstree li a > .jstree-icon.icon-customer:before {
  content: '\e907'; }

.jstree li a > .jstree-icon.icon-customer:before {
  font-size: 1.8rem; }

.jstree li a > .jstree-icon.icon-company:before {
  content: '\e906'; }

.jstree li a > .jstree-icon.icon-company:before {
  font-size: 1.7rem; }

.jstree li a > .field-tooltip {
  color: #000000;
  left: 100%;
  margin-left: 5px;
  top: .4rem; }

.jstree li a .field-tooltip-content {
  white-space: normal; }

.jstree li:not(.jstree-leaf) > .jstree-icon {
  display: inline-block;
  text-decoration: none;
  left: -.1rem;
  position: relative;
  top: 0.6rem;
  z-index: 5; }

.jstree li:not(.jstree-leaf) > .jstree-icon > span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.jstree li:not(.jstree-leaf) > .jstree-icon:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.9rem;
  line-height: 1.1;
  color: #575757;
  content: '\e909';
  font-family: 'magento-icons';
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center; }

.jstree li:not(.jstree-leaf) > .jstree-icon:before {
  cursor: pointer;
  text-align: center; }

.jstree li.jstree-open > .jstree-icon:before {
  content: '\e90a'; }

.jstree li.jstree-open > .jstree-icon:before {
  color: #c2c2c2;
  font-size: 1.6rem;
  left: 1.8rem;
  position: relative; }

.jstree li.jstree-closed > .jstree-icon:before {
  content: '\e909'; }

.jstree li.jstree-closed > .jstree-icon:before {
  color: #c2c2c2;
  font-size: 1.6rem;
  left: 1.8rem;
  position: relative; }

.jstree > ul > li {
  background: none; }

.jstree > ul > li > a.company-admin {
  margin-left: -.2rem;
  padding-bottom: 1.3rem; }

.jstree > ul > li > a.company-admin:before {
  display: none; }

.jstree > ul > li > a.company-admin > .jstree-icon {
  margin-right: 10px;
  position: relative;
  z-index: 5; }

.jstree > ul > li > a.company-admin > .jstree-icon:before {
  content: '\e908'; }

.jstree > ul > li > a.company-admin > .jstree-icon:before {
  font-size: 3rem; }

.jstree > ul > li:not(.root-collapsible) > .jstree-icon.jstree-ocl {
  display: none; }

.jstree .jstree-leaf > .jstree-icon.jstree-ocl {
  display: none; }

.jstree a > .jstree-icon {
  display: inline-block;
  text-decoration: none;
  background: none; }

.jstree a > .jstree-icon > span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.jstree a > .jstree-icon:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2.8rem;
  line-height: 1.1;
  color: #858585;
  font-family: 'magento-icons';
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center; }

.jstree.jstree-draggable a {
  display: inline-block;
  text-decoration: none; }

.jstree.jstree-draggable a > span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.jstree.jstree-draggable a:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2.8rem;
  line-height: 1.1;
  color: #c2c2c2;
  content: '\e90b';
  font-family: 'magento-icons';
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  text-align: center; }

.jstree.jstree-draggable a:before {
  height: 1.8rem;
  margin-left: -.4rem;
  overflow: hidden;
  width: 1.9rem; }

.jstree.jstree-draggable a.jstree-clicked:before {
  color: #ffffff; }

.jstree i {
  font-style: normal; }

.block-dashboard-company .jstree .jstree-clicked {
  background-color: #006bb4;
  color: #ffffff; }

.block-dashboard-company .jstree .jstree-clicked > .jstree-icon:before {
  color: #ffffff; }

.jstree .jstree-anchor .jstree-checkbox {
  background-color: transparent;
  background-image: url(../Magento_Company/images/icon-tree.png);
  background-position: top right;
  background-repeat: no-repeat;
  height: 16px;
  margin: 5px 8px -2px 4px;
  position: relative;
  width: 16px; }

.jstree .jstree-anchor.jstree-clicked > .jstree-checkbox {
  background-position: top center; }

.jstree .jstree-anchor > .jstree-checkbox.jstree-undetermined {
  background-position: top left; }

.jstree-checkbox-selection .jstree-anchor {
  display: inline-block;
  text-decoration: none; }

.jstree-node,
.jstree-children,
.jstree-container-ul {
  display: block;
  list-style-type: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

.jstree-node {
  white-space: nowrap; }

.jstree-anchor {
  color: black;
  display: inline-block;
  margin: 0;
  padding: 0 4px 0 1px;
  vertical-align: top;
  white-space: nowrap; }

#jstree-marker {
  position: absolute;
  top: 0;
  left: 0;
  margin: -5px 0 0 0;
  padding: 0;
  border-right: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0; }

#jstree-dnd {
  line-height: 16px;
  margin: 0;
  padding: 4px; }

#jstree-dnd .jstree-icon,
#jstree-dnd .jstree-copy {
  display: inline-block;
  text-decoration: none;
  margin: 0 2px 0 0;
  padding: 0;
  width: 16px;
  height: 16px; }

#jstree-dnd .jstree-ok {
  background: green; }

#jstree-dnd .jstree-er {
  background: red; }

#jstree-dnd .jstree-copy {
  margin: 0 2px 0 2px; }

footer.page-footer .cookies {
  color: rgba(255, 255, 255, 0.4);
  text-align: right;
  font-size: 0.6875rem;
  font-family: inherit; }
  footer.page-footer .cookies p {
    font-size: 0.6875rem;
    margin: 0; }
  footer.page-footer .cookies a {
    color: rgba(255, 255, 255, 0.4);
    text-decoration: underline; }
    footer.page-footer .cookies a:hover, footer.page-footer .cookies a:active {
      text-decoration: none; }
  footer.page-footer .cookies .cookies-magento a {
    color: rgba(255, 255, 255, 0.4); }

.header .top-links,
.header .links {
  margin: 0;
  list-style: none;
  font-size: 0; }
  .header .top-links li,
  .header .links li {
    padding: 0; }
    .header .top-links li:after,
    .header .links li:after {
      display: none; }
  .header .top-links li,
  .header .links li {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.75rem;
    color: white;
    font-weight: 400; }
    .header .top-links li.customer-section,
    .header .links li.customer-section {
      padding-left: 0.3125rem; }
      @media screen and (min-width: 75em) {
        .header .top-links li.customer-section,
        .header .links li.customer-section {
          padding-left: 0.625rem; } }
      .header .top-links li.customer-section a,
      .header .links li.customer-section a {
        padding-left: 1.25rem; }
        .header .top-links li.customer-section a:before,
        .header .links li.customer-section a:before {
          display: block;
          content: '';
          width: 15px;
          height: 13px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background: url("../images/icon-user-account.svg") 50% 50% no-repeat;
          background-size: 100% auto; }
        .header .top-links li.customer-section a:after,
        .header .links li.customer-section a:after {
          display: block;
          content: '';
          width: 1px;
          position: absolute;
          top: -.25rem;
          bottom: -.25rem;
          right: 0;
          background: rgba(255, 255, 255, 0.2); }
    .header .top-links li a,
    .header .links li a {
      display: block;
      font-size: 15px;
      color: white;
      line-height: 1.6;
      text-transform: none;
      padding: 0 0.27778rem;
      position: relative;
      font-family: "silka-regular-webfont", sans-serif;
      font-weight: inherit;
      text-decoration: none; }
      @media screen and (min-width: 64em) {
        .header .top-links li a,
        .header .links li a {
          padding: 0 0.41667rem; } }
      @media screen and (min-width: 75em) {
        .header .top-links li a,
        .header .links li a {
          padding: 0 0.625rem; } }
      .header .top-links li a:hover,
      .header .links li a:hover {
        text-decoration: underline; }
    @media screen and (min-width: 90.625em) {
      .header .top-links li:last-child a,
      .header .links li:last-child a {
        padding-right: 0; } }

.header .top-links,
.header .header.links {
  margin-left: -0.27778rem; }
  @media screen and (min-width: 64em) {
    .header .top-links,
    .header .header.links {
      margin-left: -0.41667rem; } }
  @media screen and (min-width: 75em) {
    .header .top-links,
    .header .header.links {
      margin-left: -0.625rem; } }

.header .links .greet,
.header .links .counter,
.header .links #invitation-send-link,
.header .links .customer-welcome,
.header .links .compare {
  display: none; }

@media screen and (max-width: 63.9375em) {
  .header .links .wishlist {
    display: none !important; } }

.footer-social li {
  display: inline-block;
  margin-right: 6px;
  font-size: 0; }
  .footer-social li:last-child {
    margin-right: 0; }

.footer-social a {
  display: inline-block;
  color: #1A2746;
  opacity: 1;
  position: relative;
  line-height: 47px; }
  .footer-social a:hover {
    color: #181818;
    opacity: 0.75; }
    .footer-social a:hover:before {
      background-color: transparent; }
  .footer-social a:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 47px;
    height: 47px;
    background-color: transparent;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    transition: background-color .5s ease;
    border-radius: 100%; }

.footer-social .icon-facebook:before {
  background-image: url("../images/icon-facebook.svg"); }

.footer-social .icon-instagram:before {
  background-image: url("../images/icon-instagram.svg"); }

.footer-social .icon-youtube:before {
  background-image: url("../images/icon-youtube.svg"); }

.footer-social .icon-linkedin:before {
  background-image: url("../images/icon-linkedin.svg"); }

.page-with-filter .shipping-bar-container {
  display: none; }
  @media print, screen and (min-width: 40em) {
    .page-with-filter .shipping-bar-container {
      display: block; } }

.breadcrumbs {
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    .breadcrumbs {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .breadcrumbs:last-child:not(:first-child) {
    float: right; }
  .breadcrumbs:last-child:last-child {
    float: left; }
  @media print, screen and (min-width: 40em) {
    .breadcrumbs {
      width: 58.33333%; } }
  .breadcrumbs + .vat-label {
    bottom: auto;
    top: .25rem; }
  .breadcrumbs .items {
    margin: 0 0 1rem 0;
    list-style: none;
    margin: 0;
    list-style: none;
    margin-bottom: 0; }
    .breadcrumbs .items::before, .breadcrumbs .items::after {
      display: table;
      content: ' '; }
    .breadcrumbs .items::after {
      clear: both; }
    .breadcrumbs .items li {
      float: left;
      font-size: 0.8125rem;
      color: rgba(0, 0, 0, 0.5);
      cursor: default; }
      .breadcrumbs .items li:not(:last-child)::after {
        position: relative;
        top: 1px;
        margin: 0 0.4rem;
        opacity: 1;
        content: "/";
        color: rgba(0, 0, 0, 0.5); }
    .breadcrumbs .items a {
      color: rgba(0, 0, 0, 0.5); }
      .breadcrumbs .items a:hover {
        text-decoration: underline; }
    .breadcrumbs .items li {
      padding: 0; }
      .breadcrumbs .items li:after {
        display: none; }
    .breadcrumbs .items .disabled {
      color: rgba(0, 0, 0, 0.3);
      cursor: not-allowed; }
    .breadcrumbs .items li {
      margin-right: 0.4rem;
      font-weight: 400;
      font-family: inherit; }
      .breadcrumbs .items li:not(:last-child)::after {
        width: auto;
        height: auto;
        content: "/";
        display: inline-block;
        vertical-align: baseline;
        position: relative;
        top: auto;
        left: auto;
        font-size: 0.8125rem;
        color: rgba(0, 0, 0, 0.5);
        background-color: transparent; }
      .breadcrumbs .items li strong {
        font-weight: inherit; }
      .breadcrumbs .items li a {
        text-decoration: none; }
        .breadcrumbs .items li a:hover {
          text-decoration: underline; }

.shipping-bar > div::before, .shipping-bar > div::after {
  display: table;
  content: ' '; }

.shipping-bar > div::after {
  clear: both; }

.shipping-bar .progress-text {
  font-size: 0.8125rem;
  font-family: inherit;
  text-align: right;
  line-height: 0.375rem;
  margin-bottom: .25rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5); }
  @media print, screen and (min-width: 40em) {
    .shipping-bar .progress-text {
      width: 50%;
      float: left;
      margin-bottom: 0;
      padding-right: 1.0625rem; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    .shipping-bar .progress-text {
      padding-right: 1.25rem; } }
  @media screen and (min-width: 75em) {
    .shipping-bar .progress-text {
      width: 80%; } }

.shipping-bar .progress-graph {
  height: 0.375rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #fff;
  border: none;
  padding: 0; }
  .shipping-bar .progress-graph.small-shipping-bar {
    height: 0.1875rem;
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .shipping-bar .progress-graph {
      width: 50%;
      float: left; } }
  @media screen and (min-width: 75em) {
    .shipping-bar .progress-graph {
      width: 20%; } }
  .shipping-bar .progress-graph .progress-bar {
    position: relative;
    display: block;
    width: 0%;
    height: 100%;
    background-color: #e24283; }

.top-controls-wrapper {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  z-index: 1;
  margin-top: 1.6rem;
  margin-bottom: 1rem; }
  .top-controls-wrapper::before, .top-controls-wrapper::after {
    display: table;
    content: ' '; }
  .top-controls-wrapper::after {
    clear: both; }
  .cms-index-index .algolia-instant-selector-results .top-controls-wrapper {
    display: block; }
  .top-controls-wrapper .shipping-bar-wrapper::before, .top-controls-wrapper .shipping-bar-wrapper::after {
    display: table;
    content: ' '; }
  .top-controls-wrapper .shipping-bar-wrapper::after {
    clear: both; }
  .cms-index-index .top-controls-wrapper {
    display: none; }
  @media print, screen and (min-width: 48em) {
    .top-controls-wrapper {
      margin-bottom: 1.5rem; } }
  @media screen and (min-width: 75em) {
    .top-controls-wrapper {
      margin-bottom: 1.5rem; } }
  @media print, screen and (min-width: 48em) {
    .page-with-filter .top-controls-wrapper,
    .algolia-instant-selector-results .top-controls-wrapper {
      margin-bottom: 1.5rem; } }
  @media screen and (min-width: 75em) {
    .page-with-filter .top-controls-wrapper,
    .algolia-instant-selector-results .top-controls-wrapper {
      margin-bottom: 1.5rem; } }
  @media screen and (min-width: 75em) {
    .page-with-filter .top-controls-wrapper,
    .algolia-instant-selector-results .top-controls-wrapper {
      margin-bottom: 1.5rem; } }
  @media print, screen and (min-width: 48em) {
    .category-header.has-bg .top-controls-wrapper {
      margin-bottom: 2.75rem; } }
  @media screen and (min-width: 75em) {
    .category-header.has-bg.has-desc .top-controls-wrapper {
      margin-bottom: 5rem; } }
  @media screen and (min-width: 75em) {
    .category-header.has-bg.has-image .top-controls-wrapper {
      margin-bottom: 4.5rem; } }
  .top-controls-wrapper .shipping-bar-container {
    display: none;
    position: relative;
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .top-controls-wrapper .shipping-bar-container {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .top-controls-wrapper .shipping-bar-container:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 48em) {
      .top-controls-wrapper .shipping-bar-container {
        display: block;
        width: 41.66667%; }
        .top-controls-wrapper .shipping-bar-container .shipping-bar-container {
          width: 100%;
          float: none;
          padding-left: 0rem;
          padding-right: 0rem; } }
    @media print, screen and (min-width: 48em) and (min-width: 40em) {
      .top-controls-wrapper .shipping-bar-container .shipping-bar-container {
        padding-left: 0rem; } }
    @media print, screen and (min-width: 48em) and (min-width: 40em) {
      .top-controls-wrapper .shipping-bar-container .shipping-bar-container {
        padding-right: 0rem; } }
  .top-controls-wrapper .vat-label {
    position: absolute;
    bottom: 0;
    right: 1.0625rem;
    font-size: 0.8125rem;
    line-height: 0.375rem;
    color: rgba(0, 0, 0, 0.5); }
    @media print, screen and (min-width: 40em) {
      .top-controls-wrapper .vat-label {
        right: 1.25rem; } }
    @media screen and (max-width: 47.9375em) {
      .top-controls-wrapper .vat-label {
        position: absolute !important;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0); } }

.page-layout-0column .usp-section,
.usp-section ul {
  padding-left: 6px;
  padding-right: 6px; }
  @media print, screen and (min-width: 48em) {
    .page-layout-0column .usp-section,
    .usp-section ul {
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (min-width: 90.625em) {
    .page-layout-0column .usp-section,
    .usp-section ul {
      padding-left: 0;
      padding-right: 0; } }

.usp-section {
  padding: 1.6rem 0;
  position: relative;
  overflow: hidden; }
  @media screen and (min-width: 64em) {
    .usp-section {
      padding: 2.3rem 0; } }
  .usp-section:before {
    content: '';
    display: block;
    visibility: visible;
    border-top: 1px solid rgba(24, 24, 24, 0.15);
    position: absolute;
    top: 0;
    left: -50%;
    right: -50%; }
  .usp-section h3 {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }
  .usp-section ul {
    margin: 0;
    list-style: none;
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    font-size: 1.125rem;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-weight: 300;
    color: rgba(24, 24, 24, 0.75); }
    .usp-section ul li {
      padding: 0; }
      .usp-section ul li:after {
        display: none; }
    .usp-section ul::before, .usp-section ul::after {
      display: table;
      content: ' '; }
    .usp-section ul::after {
      clear: both; }
    .usp-section ul li {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem;
      line-height: 1.3;
      margin-bottom: 0.75rem; }
      @media print, screen and (min-width: 40em) {
        .usp-section ul li {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .usp-section ul li:last-child:not(:first-child) {
        float: right; }
      .usp-section ul li:last-child:last-child {
        float: left; }
      .usp-section ul li:last-child {
        margin-bottom: 0; }
      .usp-section ul li a,
      .usp-section ul li span {
        display: block;
        position: relative;
        padding-left: 2.0625rem; }
        .usp-section ul li a:before,
        .usp-section ul li span:before {
          content: '';
          display: block;
          position: absolute;
          width: 1.0625rem;
          height: 0.75rem;
          top: 0.5rem;
          left: 0;
          background: url("../images/icon-usp.svg") no-repeat;
          background-size: 1.0625rem auto; }
      .usp-section ul li a {
        color: rgba(24, 24, 24, 0.75);
        text-decoration: none; }
        .usp-section ul li a:hover, .usp-section ul li a:focus {
          text-decoration: underline; }
      .usp-section ul li div {
        font-size: 1.125rem;
        margin-top: 0; }
      @media print, screen and (min-width: 40em) {
        .usp-section ul li {
          width: 50%;
          margin-bottom: 1.125rem; } }
      @media print, screen and (min-width: 48em) {
        .usp-section ul li {
          width: 25%;
          margin-bottom: 0; } }

.category-usp-section + .page-main {
  margin-top: 0 !important; }

.category-usp-section .usp-section {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 1rem;
  padding-bottom: 1.25rem;
  padding-left: 1.0625rem;
  padding-right: 17px !important; }
  .category-usp-section .usp-section::before, .category-usp-section .usp-section::after {
    display: table;
    content: ' '; }
  .category-usp-section .usp-section::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .category-usp-section .usp-section {
      padding-left: 1.25rem; } }
  @media print, screen and (min-width: 40em) {
    .category-usp-section .usp-section {
      padding-bottom: 0;
      padding-right: 20px !important; } }
  @media print, screen and (min-width: 48em) {
    .category-usp-section .usp-section {
      padding-top: .25rem;
      padding-bottom: .5rem; } }
  .category-usp-section .usp-section:before {
    display: none; }
  .category-usp-section .usp-section ul {
    max-width: initial;
    width: auto;
    margin-left: -0.53125rem;
    margin-right: -0.53125rem;
    padding-left: 0;
    padding-right: 0; }
    @media print, screen and (min-width: 40em) {
      .category-usp-section .usp-section ul {
        margin-left: -0.625rem; } }
    @media print, screen and (min-width: 40em) {
      .category-usp-section .usp-section ul {
        margin-right: -0.625rem; } }
    @media print, screen and (min-width: 48em) {
      .category-usp-section .usp-section ul {
        display: flex; } }
    .category-usp-section .usp-section ul li {
      display: block;
      padding-left: 0.53125rem;
      padding-right: 0.53125rem; }
      @media print, screen and (min-width: 40em) {
        .category-usp-section .usp-section ul li {
          padding-left: 0.625rem; } }
      @media print, screen and (min-width: 40em) {
        .category-usp-section .usp-section ul li {
          padding-right: 0.625rem; } }
      .category-usp-section .usp-section ul li a,
      .category-usp-section .usp-section ul li span {
        min-height: 100%;
        padding: 1rem .25rem;
        text-align: center;
        font-family: "silka-regular-webfont", sans-serif;
        font-weight: 400;
        font-size: 0.8125rem;
        background-color: #99a0b1; }
        .category-usp-section .usp-section ul li a:before,
        .category-usp-section .usp-section ul li span:before {
          display: inline-block;
          vertical-align: middle;
          position: relative;
          top: auto;
          left: auto;
          margin-right: 2px;
          background-size: 12px auto; }
        @media print, screen and (min-width: 48em) {
          .category-usp-section .usp-section ul li a,
          .category-usp-section .usp-section ul li span {
            padding: 1.25rem 1rem; } }
        @media screen and (min-width: 64em) {
          .category-usp-section .usp-section ul li a,
          .category-usp-section .usp-section ul li span {
            padding: 1.25rem 3rem; } }
        @media screen and (min-width: 75em) {
          .category-usp-section .usp-section ul li a,
          .category-usp-section .usp-section ul li span {
            padding: 1.25rem .25rem;
            font-size: 1rem; } }

.magellan-bar.is-stuck {
  width: 100%;
  max-width: inherit !important;
  left: 0 !important;
  padding: 0.25rem 0 0.5rem;
  background: white;
  box-shadow: 0 0 10px rgba(24, 24, 24, 0.2); }
  .magellan-bar.is-stuck .magellan-col {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .magellan-bar.is-stuck .magellan-col {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .magellan-bar.is-stuck .magellan-col:last-child:not(:first-child) {
      float: right; }
  .magellan-bar.is-stuck .magellan-container {
    margin-bottom: 0; }

.magellan-bar .magellan-row {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto; }
  .magellan-bar .magellan-row::before, .magellan-bar .magellan-row::after {
    display: table;
    content: ' '; }
  .magellan-bar .magellan-row::after {
    clear: both; }

.magellan-container {
  margin-bottom: 1.5rem; }
  @media screen and (min-width: 64em) {
    .magellan-container {
      margin-bottom: 3.5rem; } }
  .magellan-container .letter-nav-label {
    display: none;
    vertical-align: middle;
    line-height: 1;
    font-weight: 300;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-size: 1.375rem;
    padding-right: 1rem; }
    @media print, screen and (min-width: 48em) {
      .magellan-container .letter-nav-label {
        display: inline-block; } }
  .magellan-container .letter-nav-tabs {
    margin: 0;
    list-style: none;
    display: inline-block;
    vertical-align: middle;
    font-weight: 300;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-size: 1.375rem; }
    .magellan-container .letter-nav-tabs li {
      padding: 0; }
      .magellan-container .letter-nav-tabs li:after {
        display: none; }
    .magellan-container .letter-nav-tabs li {
      display: inline-block;
      margin-right: .2rem; }
    .magellan-container .letter-nav-tabs a {
      display: block;
      text-decoration: none;
      line-height: 1;
      color: #181818;
      border-bottom: 1px solid #181818; }
      .magellan-container .letter-nav-tabs a:hover, .magellan-container .letter-nav-tabs a:active {
        border-bottom: 0;
        padding-bottom: 1px; }

.brand-logo-list {
  margin: 0;
  list-style: none;
  text-align: center;
  margin-bottom: 1.5rem; }
  .brand-logo-list li {
    padding: 0; }
    .brand-logo-list li:after {
      display: none; }
  @media screen and (min-width: 64em) {
    .brand-logo-list {
      margin-bottom: 5.5rem; } }
  .brand-logo-list li {
    display: inline-block;
    vertical-align: middle;
    width: 31%;
    padding: 0 1rem 1rem; }
    @media print, screen and (min-width: 48em) {
      .brand-logo-list li {
        width: auto;
        padding: 0;
        margin: 0 1.75rem 1rem; } }

@media print, screen and (min-width: 40em) {
  .item-brands-wrapper {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    margin-right: -1.0625rem;
    margin-left: -1.0625rem; }
    .item-brands-wrapper::before, .item-brands-wrapper::after {
      display: table;
      content: ' '; }
    .item-brands-wrapper::after {
      clear: both; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    .item-brands-wrapper {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 40em) and (min-width: 48em) {
    .item-brands-wrapper {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 40em) and (min-width: 64em) {
    .item-brands-wrapper {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 40em) and (min-width: 75em) {
    .item-brands-wrapper {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 40em) and (min-width: 90.625em) {
    .item-brands-wrapper {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }

.item-brands-wrapper .item-brands-single {
  margin-bottom: 1rem; }
  @media print, screen and (min-width: 40em) {
    .item-brands-wrapper .item-brands-single {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem;
      width: 50%;
      margin-bottom: 1.5rem; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    .item-brands-wrapper .item-brands-single {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  @media print, screen and (min-width: 40em) {
      .item-brands-wrapper .item-brands-single:last-child:not(:first-child) {
        float: right; }
      .item-brands-wrapper .item-brands-single:last-child:last-child {
        float: left; }
      .item-brands-wrapper .item-brands-single:nth-child(odd) {
        clear: left; } }

.item-brands-wrapper .item-brands {
  padding: 1.2rem;
  background: #F1EFED; }
  .item-brands-wrapper .item-brands ul {
    margin: 0;
    list-style: none; }
    .item-brands-wrapper .item-brands ul li {
      padding: 0; }
      .item-brands-wrapper .item-brands ul li:after {
        display: none; }
  .item-brands-wrapper .item-brands .brand-letter {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    width: 25%;
    font-weight: 300;
    line-height: 1;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-size: 3.125rem;
    color: #181818; }
    @media print, screen and (min-width: 40em) {
      .item-brands-wrapper .item-brands .brand-letter {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .item-brands-wrapper .item-brands .brand-letter:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 48em) {
      .item-brands-wrapper .item-brands .brand-letter {
        width: 25%; } }
    @media screen and (min-width: 64em) {
      .item-brands-wrapper .item-brands .brand-letter {
        width: 16.66667%; } }
    @media screen and (min-width: 75em) {
      .item-brands-wrapper .item-brands .brand-letter {
        width: 12.5%; } }
  .item-brands-wrapper .item-brands .brand-list-contianer {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    width: 75%;
    padding-left: 0rem;
    padding-top: .25rem; }
    @media print, screen and (min-width: 40em) {
      .item-brands-wrapper .item-brands .brand-list-contianer {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .item-brands-wrapper .item-brands .brand-list-contianer:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 40em) {
      .item-brands-wrapper .item-brands .brand-list-contianer {
        padding-left: 0rem; } }
    .item-brands-wrapper .item-brands .brand-list-contianer a {
      color: #181818; }
    @media print, screen and (min-width: 48em) {
      .item-brands-wrapper .item-brands .brand-list-contianer {
        width: 75%; } }
    @media screen and (min-width: 64em) {
      .item-brands-wrapper .item-brands .brand-list-contianer {
        width: 83.33333%; } }
    @media screen and (min-width: 75em) {
      .item-brands-wrapper .item-brands .brand-list-contianer {
        width: 87.5%; } }
  .item-brands-wrapper .item-brands .bare-list {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    margin-right: -1.0625rem;
    margin-left: -1.0625rem; }
    .item-brands-wrapper .item-brands .bare-list::before, .item-brands-wrapper .item-brands .bare-list::after {
      display: table;
      content: ' '; }
    .item-brands-wrapper .item-brands .bare-list::after {
      clear: both; }
    @media print, screen and (min-width: 40em) {
      .item-brands-wrapper .item-brands .bare-list {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .item-brands-wrapper .item-brands .bare-list {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .item-brands-wrapper .item-brands .bare-list {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .item-brands-wrapper .item-brands .bare-list {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .item-brands-wrapper .item-brands .bare-list {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    .item-brands-wrapper .item-brands .bare-list li {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem;
      width: 50%;
      padding-right: 0rem; }
      @media print, screen and (min-width: 40em) {
        .item-brands-wrapper .item-brands .bare-list li {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .item-brands-wrapper .item-brands .bare-list li:last-child:not(:first-child) {
        float: right; }
      .item-brands-wrapper .item-brands .bare-list li:last-child:last-child {
        float: left; }
      @media print, screen and (min-width: 40em) {
        .item-brands-wrapper .item-brands .bare-list li {
          padding-right: 0rem; } }

.convert-block.aligned--center {
  text-align: center; }

.convert-block.aligned--right {
  text-align: right; }

.as-h1,
.as-h2,
.as-h3,
.as-h4,
.as-h5,
.as-h6 {
  font-family: "silka-regular-webfont", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: inherit;
  text-rendering: optimizeLegibility; }
  .as-h1 small,
  .as-h2 small,
  .as-h3 small,
  .as-h4 small,
  .as-h5 small,
  .as-h6 small {
    line-height: 0;
    color: rgba(0, 0, 0, 0.7); }

h1,
.as-h1 {
  font-size: 1.75rem;
  line-height: 1.21429;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2,
.as-h2 {
  font-size: 1.75rem;
  line-height: 1.14286;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3,
.as-h3 {
  font-size: 1.625rem;
  line-height: 1.46154;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4,
.as-h4 {
  font-size: 1.5rem;
  line-height: 1.33333;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5,
.as-h5 {
  font-size: 0.9375rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6,
.as-h6 {
  font-size: 0.8125rem;
  line-height: 1.23077;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1,
  .as-h1 {
    font-size: 2.625rem;
    line-height: 1.19048; }
  h2,
  .as-h2 {
    font-size: 2.125rem;
    line-height: 1.17647; }
  h3,
  .as-h3 {
    font-size: 1.6875rem;
    line-height: 1.44444; }
  h4,
  .as-h4 {
    font-size: 1.5625rem;
    line-height: 1.32; }
  h5,
  .as-h5 {
    font-size: 1.0625rem;
    line-height: 1.23529; }
  h6,
  .as-h6 {
    font-size: 0.9375rem;
    line-height: 1.2; } }

@media screen and (min-width: 64em) {
  h1,
  .as-h1 {
    font-size: 2.875rem;
    line-height: 1.45652; }
  h2,
  .as-h2 {
    font-size: 2.5rem;
    line-height: 1.2; }
  h3,
  .as-h3 {
    font-size: 1.75rem;
    line-height: 1.42857; }
  h4,
  .as-h4 {
    font-size: 1.625rem;
    line-height: 1.30769; }
  h5,
  .as-h5 {
    font-size: 1.1875rem;
    line-height: 1.21053; }
  h6,
  .as-h6 {
    font-size: 1.0625rem;
    line-height: 1.17647; } }

.img-responsive {
  display: inline-block;
  max-width: 100%; }

.convert-column {
  min-height: 1px; }

.row.row-fluid {
  max-width: none; }
  .row.row-fluid .img-responsive {
    margin-left: auto;
    margin-right: auto;
    display: block; }
  @media screen and (min-width: 1900px) {
    .row.row-fluid.convert-grid-row.expanded {
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto; } }

.row .row.gutters-03x {
  margin-left: -6px;
  margin-right: -6px; }

.row.gutters-03x .column, .row.gutters-03x .columns,
.row.gutters-03x .columns {
  padding-left: 6px;
  padding-right: 6px; }

.row .row.gutters-05x {
  margin-left: -10px;
  margin-right: -10px; }

.row.gutters-05x .column, .row.gutters-05x .columns,
.row.gutters-05x .columns {
  padding-left: 10px;
  padding-right: 10px; }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .row .row.gutters-2x {
    margin-left: -12px;
    margin-right: -12px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .row .row.gutters-2x {
    margin-left: -20px;
    margin-right: -20px; } }

@media screen and (min-width: 75em) and (max-width: 90.5625em) {
  .row .row.gutters-2x {
    margin-left: -40px;
    margin-right: -40px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .row.gutters-2x > .column, .row.gutters-2x > .columns,
  .row.gutters-2x > .columns {
    padding-left: 12px;
    padding-right: 12px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .row.gutters-2x > .column, .row.gutters-2x > .columns,
  .row.gutters-2x > .columns {
    padding-left: 20px;
    padding-right: 20px; } }

@media screen and (min-width: 75em) {
  .row.gutters-2x > .column, .row.gutters-2x > .columns,
  .row.gutters-2x > .columns {
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .row .row.gutters-4x {
    margin-left: -24px;
    margin-right: -24px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .row .row.gutters-4x {
    margin-left: -40px;
    margin-right: -40px; } }

@media screen and (min-width: 75em) and (max-width: 90.5625em) {
  .row .row.gutters-4x {
    margin-left: -80px;
    margin-right: -80px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .row.gutters-4x > .column, .row.gutters-4x > .columns,
  .row.gutters-4x > .columns {
    padding-left: 24px;
    padding-right: 24px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .row.gutters-4x > .column, .row.gutters-4x > .columns,
  .row.gutters-4x > .columns {
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (min-width: 75em) {
  .row.gutters-4x > .column, .row.gutters-4x > .columns,
  .row.gutters-4x > .columns {
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .row.gutters-8x > .column, .row.gutters-8x > .columns,
  .row.gutters-8x > .columns {
    padding-left: 48px;
    padding-right: 48px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .row.gutters-8x > .column, .row.gutters-8x > .columns,
  .row.gutters-8x > .columns {
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (min-width: 75em) {
  .row.gutters-8x > .column, .row.gutters-8x > .columns,
  .row.gutters-8x > .columns {
    padding-left: 160px;
    padding-right: 160px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .row.gutters-12x > .column, .row.gutters-12x > .columns,
  .row.gutters-12x > .columns {
    padding-left: 72px;
    padding-right: 72px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .row.gutters-12x > .column, .row.gutters-12x > .columns,
  .row.gutters-12x > .columns {
    padding-left: 120px;
    padding-right: 120px; } }

@media screen and (min-width: 75em) {
  .row.gutters-12x > .column, .row.gutters-12x > .columns,
  .row.gutters-12x > .columns {
    padding-left: 240px;
    padding-right: 240px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .row.gutters-16x > .column, .row.gutters-16x > .columns,
  .row.gutters-16x > .columns {
    padding-left: 96px;
    padding-right: 96px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .row.gutters-16x > .column, .row.gutters-16x > .columns,
  .row.gutters-16x > .columns {
    padding-left: 160px;
    padding-right: 160px; } }

@media screen and (min-width: 75em) {
  .row.gutters-16x > .column, .row.gutters-16x > .columns,
  .row.gutters-16x > .columns {
    padding-left: 320px;
    padding-right: 320px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .row.gutters-20x > .column, .row.gutters-20x > .columns,
  .row.gutters-20x > .columns {
    padding-left: 120px;
    padding-right: 120px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .row.gutters-20x > .column, .row.gutters-20x > .columns,
  .row.gutters-20x > .columns {
    padding-left: 200px;
    padding-right: 200px; } }

@media screen and (min-width: 75em) {
  .row.gutters-20x > .column, .row.gutters-20x > .columns,
  .row.gutters-20x > .columns {
    padding-left: 400px;
    padding-right: 400px; } }

.as-h1 {
  font-size: 1.75rem;
  line-height: 1.21429;
  margin-top: 0;
  margin-bottom: 0.5rem; }

.as-h2 {
  font-size: 1.75rem;
  line-height: 1.14286;
  margin-top: 0;
  margin-bottom: 0.5rem; }

.as-h3 {
  font-size: 1.625rem;
  line-height: 1.46154;
  margin-top: 0;
  margin-bottom: 0.5rem; }

.as-h4 {
  font-size: 1.5rem;
  line-height: 1.33333;
  margin-top: 0;
  margin-bottom: 0.5rem; }

.as-h5 {
  font-size: 0.9375rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem; }

.as-h6 {
  font-size: 0.8125rem;
  line-height: 1.23077;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  .as-h1 {
    font-size: 2.625rem;
    line-height: 1.19048; }
  .as-h2 {
    font-size: 2.125rem;
    line-height: 1.17647; }
  .as-h3 {
    font-size: 1.6875rem;
    line-height: 1.44444; }
  .as-h4 {
    font-size: 1.5625rem;
    line-height: 1.32; }
  .as-h5 {
    font-size: 1.0625rem;
    line-height: 1.23529; }
  .as-h6 {
    font-size: 0.9375rem;
    line-height: 1.2; } }

@media screen and (min-width: 64em) {
  .as-h1 {
    font-size: 2.875rem;
    line-height: 1.45652; }
  .as-h2 {
    font-size: 2.5rem;
    line-height: 1.2; }
  .as-h3 {
    font-size: 1.75rem;
    line-height: 1.42857; }
  .as-h4 {
    font-size: 1.625rem;
    line-height: 1.30769; }
  .as-h5 {
    font-size: 1.1875rem;
    line-height: 1.21053; }
  .as-h6 {
    font-size: 1.0625rem;
    line-height: 1.17647; } }

.convert-block.spacing-small,
.convert-grid-row.spacing-small,
.convert-grid-row-flex.spacing-small {
  margin-bottom: 0.625rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.spacing-small,
    .convert-grid-row.spacing-small,
    .convert-grid-row-flex.spacing-small {
      margin-bottom: 0.3125rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.spacing-small,
    .convert-grid-row.spacing-small,
    .convert-grid-row-flex.spacing-small {
      margin-bottom: 0.4375rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.spacing-small,
    .convert-grid-row.spacing-small,
    .convert-grid-row-flex.spacing-small {
      margin-bottom: 0.5rem; } }

.convert-block.spacing-medium,
.convert-grid-row.spacing-medium,
.convert-grid-row-flex.spacing-medium {
  margin-bottom: 1.5625rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.spacing-medium,
    .convert-grid-row.spacing-medium,
    .convert-grid-row-flex.spacing-medium {
      margin-bottom: 0.78125rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.spacing-medium,
    .convert-grid-row.spacing-medium,
    .convert-grid-row-flex.spacing-medium {
      margin-bottom: 1.09375rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.spacing-medium,
    .convert-grid-row.spacing-medium,
    .convert-grid-row-flex.spacing-medium {
      margin-bottom: 1.25rem; } }

.convert-block.spacing-large,
.convert-grid-row.spacing-large,
.convert-grid-row-flex.spacing-large {
  margin-bottom: 3.125rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.spacing-large,
    .convert-grid-row.spacing-large,
    .convert-grid-row-flex.spacing-large {
      margin-bottom: 1.5625rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.spacing-large,
    .convert-grid-row.spacing-large,
    .convert-grid-row-flex.spacing-large {
      margin-bottom: 2.1875rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.spacing-large,
    .convert-grid-row.spacing-large,
    .convert-grid-row-flex.spacing-large {
      margin-bottom: 2.5rem; } }

.convert-block.spacing-xlarge,
.convert-grid-row.spacing-xlarge,
.convert-grid-row-flex.spacing-xlarge {
  margin-bottom: 5rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.spacing-xlarge,
    .convert-grid-row.spacing-xlarge,
    .convert-grid-row-flex.spacing-xlarge {
      margin-bottom: 2.5rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.spacing-xlarge,
    .convert-grid-row.spacing-xlarge,
    .convert-grid-row-flex.spacing-xlarge {
      margin-bottom: 3.5rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.spacing-xlarge,
    .convert-grid-row.spacing-xlarge,
    .convert-grid-row-flex.spacing-xlarge {
      margin-bottom: 4rem; } }

.convert-block.spacing-xxlarge,
.convert-grid-row.spacing-xxlarge,
.convert-grid-row-flex.spacing-xxlarge {
  margin-bottom: 7.8125rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.spacing-xxlarge,
    .convert-grid-row.spacing-xxlarge,
    .convert-grid-row-flex.spacing-xxlarge {
      margin-bottom: 3.90625rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.spacing-xxlarge,
    .convert-grid-row.spacing-xxlarge,
    .convert-grid-row-flex.spacing-xxlarge {
      margin-bottom: 5.46875rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.spacing-xxlarge,
    .convert-grid-row.spacing-xxlarge,
    .convert-grid-row-flex.spacing-xxlarge {
      margin-bottom: 6.25rem; } }

.convert-block.spacing-xxxlarge,
.convert-grid-row.spacing-xxxlarge,
.convert-grid-row-flex.spacing-xxxlarge {
  margin-bottom: 10.9375rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.spacing-xxxlarge,
    .convert-grid-row.spacing-xxxlarge,
    .convert-grid-row-flex.spacing-xxxlarge {
      margin-bottom: 5.46875rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.spacing-xxxlarge,
    .convert-grid-row.spacing-xxxlarge,
    .convert-grid-row-flex.spacing-xxxlarge {
      margin-bottom: 7.65625rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.spacing-xxxlarge,
    .convert-grid-row.spacing-xxxlarge,
    .convert-grid-row-flex.spacing-xxxlarge {
      margin-bottom: 8.75rem; } }

@media print, screen and (min-width: 40em) {
  .convert-block.spacing-none .convert-responsive-image, .convert-block.spacing-fixed-03x .convert-responsive-image, .convert-block.spacing-fixed-05x .convert-responsive-image,
  .convert-grid-row.spacing-none .convert-responsive-image,
  .convert-grid-row.spacing-fixed-03x .convert-responsive-image,
  .convert-grid-row.spacing-fixed-05x .convert-responsive-image,
  .convert-grid-row-flex.spacing-none .convert-responsive-image,
  .convert-grid-row-flex.spacing-fixed-03x .convert-responsive-image,
  .convert-grid-row-flex.spacing-fixed-05x .convert-responsive-image {
    margin-bottom: 0; } }

.convert-block.spacing-none,
.convert-grid-row.spacing-none,
.convert-grid-row-flex.spacing-none {
  margin-bottom: 0 !important; }

.convert-block.spacing-fixed-03x,
.convert-grid-row.spacing-fixed-03x,
.convert-grid-row-flex.spacing-fixed-03x {
  margin-bottom: 12px; }

.convert-block.spacing-fixed-05x,
.convert-grid-row.spacing-fixed-05x,
.convert-grid-row-flex.spacing-fixed-05x {
  margin-bottom: 20px; }

.convert-block.padding-default.padding-small,
.convert-grid-row-flex.padding-default.padding-small {
  padding: 0.625rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-default.padding-small,
    .convert-grid-row-flex.padding-default.padding-small {
      padding: 0.3125rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-default.padding-small,
    .convert-grid-row-flex.padding-default.padding-small {
      padding: 0.4375rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-default.padding-small,
    .convert-grid-row-flex.padding-default.padding-small {
      padding: 0.5rem; } }

.convert-block.padding-default.padding-medium,
.convert-grid-row-flex.padding-default.padding-medium {
  padding: 1.5625rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-default.padding-medium,
    .convert-grid-row-flex.padding-default.padding-medium {
      padding: 0.78125rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-default.padding-medium,
    .convert-grid-row-flex.padding-default.padding-medium {
      padding: 1.09375rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-default.padding-medium,
    .convert-grid-row-flex.padding-default.padding-medium {
      padding: 1.25rem; } }

.convert-block.padding-default.padding-large,
.convert-grid-row-flex.padding-default.padding-large {
  padding: 3.125rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-default.padding-large,
    .convert-grid-row-flex.padding-default.padding-large {
      padding: 1.5625rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-default.padding-large,
    .convert-grid-row-flex.padding-default.padding-large {
      padding: 2.1875rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-default.padding-large,
    .convert-grid-row-flex.padding-default.padding-large {
      padding: 2.5rem; } }

.convert-block.padding-default.padding-xlarge,
.convert-grid-row-flex.padding-default.padding-xlarge {
  padding: 5rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-default.padding-xlarge,
    .convert-grid-row-flex.padding-default.padding-xlarge {
      padding: 2.5rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-default.padding-xlarge,
    .convert-grid-row-flex.padding-default.padding-xlarge {
      padding: 3.5rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-default.padding-xlarge,
    .convert-grid-row-flex.padding-default.padding-xlarge {
      padding: 4rem; } }

.convert-block.padding-default.padding-xxlarge,
.convert-grid-row-flex.padding-default.padding-xxlarge {
  padding: 7.8125rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-default.padding-xxlarge,
    .convert-grid-row-flex.padding-default.padding-xxlarge {
      padding: 3.90625rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-default.padding-xxlarge,
    .convert-grid-row-flex.padding-default.padding-xxlarge {
      padding: 5.46875rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-default.padding-xxlarge,
    .convert-grid-row-flex.padding-default.padding-xxlarge {
      padding: 6.25rem; } }

.convert-block.padding-default.padding-xxxlarge,
.convert-grid-row-flex.padding-default.padding-xxxlarge {
  padding: 10.9375rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-default.padding-xxxlarge,
    .convert-grid-row-flex.padding-default.padding-xxxlarge {
      padding: 5.46875rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-default.padding-xxxlarge,
    .convert-grid-row-flex.padding-default.padding-xxxlarge {
      padding: 7.65625rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-default.padding-xxxlarge,
    .convert-grid-row-flex.padding-default.padding-xxxlarge {
      padding: 8.75rem; } }

.convert-block.padding-default.padding-fixed-03x,
.convert-grid-row-flex.padding-default.padding-fixed-03x {
  padding: 6px; }

.convert-block.padding-default.padding-fixed-05x,
.convert-grid-row-flex.padding-default.padding-fixed-05x {
  padding: 10px; }

.convert-block.padding-vertical.padding-small,
.convert-grid-row.padding-vertical.padding-small,
.convert-grid-row-flex.padding-vertical.padding-small {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-vertical.padding-small,
    .convert-grid-row.padding-vertical.padding-small,
    .convert-grid-row-flex.padding-vertical.padding-small {
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.padding-vertical.padding-small,
    .convert-grid-row.padding-vertical.padding-small,
    .convert-grid-row-flex.padding-vertical.padding-small {
      padding-top: 0.4375rem;
      padding-bottom: 0.4375rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-vertical.padding-small,
    .convert-grid-row.padding-vertical.padding-small,
    .convert-grid-row-flex.padding-vertical.padding-small {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem; } }

.convert-block.padding-vertical.padding-medium,
.convert-grid-row.padding-vertical.padding-medium,
.convert-grid-row-flex.padding-vertical.padding-medium {
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-vertical.padding-medium,
    .convert-grid-row.padding-vertical.padding-medium,
    .convert-grid-row-flex.padding-vertical.padding-medium {
      padding-top: 0.78125rem;
      padding-bottom: 0.78125rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.padding-vertical.padding-medium,
    .convert-grid-row.padding-vertical.padding-medium,
    .convert-grid-row-flex.padding-vertical.padding-medium {
      padding-top: 1.09375rem;
      padding-bottom: 1.09375rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-vertical.padding-medium,
    .convert-grid-row.padding-vertical.padding-medium,
    .convert-grid-row-flex.padding-vertical.padding-medium {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem; } }

.convert-block.padding-vertical.padding-large,
.convert-grid-row.padding-vertical.padding-large,
.convert-grid-row-flex.padding-vertical.padding-large {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-vertical.padding-large,
    .convert-grid-row.padding-vertical.padding-large,
    .convert-grid-row-flex.padding-vertical.padding-large {
      padding-top: 1.5625rem;
      padding-bottom: 1.5625rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.padding-vertical.padding-large,
    .convert-grid-row.padding-vertical.padding-large,
    .convert-grid-row-flex.padding-vertical.padding-large {
      padding-top: 2.1875rem;
      padding-bottom: 2.1875rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-vertical.padding-large,
    .convert-grid-row.padding-vertical.padding-large,
    .convert-grid-row-flex.padding-vertical.padding-large {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem; } }

.convert-block.padding-vertical.padding-xlarge,
.convert-grid-row.padding-vertical.padding-xlarge,
.convert-grid-row-flex.padding-vertical.padding-xlarge {
  padding-top: 5rem;
  padding-bottom: 5rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-vertical.padding-xlarge,
    .convert-grid-row.padding-vertical.padding-xlarge,
    .convert-grid-row-flex.padding-vertical.padding-xlarge {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.padding-vertical.padding-xlarge,
    .convert-grid-row.padding-vertical.padding-xlarge,
    .convert-grid-row-flex.padding-vertical.padding-xlarge {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-vertical.padding-xlarge,
    .convert-grid-row.padding-vertical.padding-xlarge,
    .convert-grid-row-flex.padding-vertical.padding-xlarge {
      padding-top: 4rem;
      padding-bottom: 4rem; } }

.convert-block.padding-vertical.padding-xxlarge,
.convert-grid-row.padding-vertical.padding-xxlarge,
.convert-grid-row-flex.padding-vertical.padding-xxlarge {
  padding-top: 7.8125rem;
  padding-bottom: 7.8125rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-vertical.padding-xxlarge,
    .convert-grid-row.padding-vertical.padding-xxlarge,
    .convert-grid-row-flex.padding-vertical.padding-xxlarge {
      padding-top: 3.90625rem;
      padding-bottom: 3.90625rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.padding-vertical.padding-xxlarge,
    .convert-grid-row.padding-vertical.padding-xxlarge,
    .convert-grid-row-flex.padding-vertical.padding-xxlarge {
      padding-top: 5.46875rem;
      padding-bottom: 5.46875rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-vertical.padding-xxlarge,
    .convert-grid-row.padding-vertical.padding-xxlarge,
    .convert-grid-row-flex.padding-vertical.padding-xxlarge {
      padding-top: 6.25rem;
      padding-bottom: 6.25rem; } }

.convert-block.padding-vertical.padding-xxxlarge,
.convert-grid-row.padding-vertical.padding-xxxlarge,
.convert-grid-row-flex.padding-vertical.padding-xxxlarge {
  padding-top: 10.9375rem;
  padding-bottom: 10.9375rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-vertical.padding-xxxlarge,
    .convert-grid-row.padding-vertical.padding-xxxlarge,
    .convert-grid-row-flex.padding-vertical.padding-xxxlarge {
      padding-top: 5.46875rem;
      padding-bottom: 5.46875rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.padding-vertical.padding-xxxlarge,
    .convert-grid-row.padding-vertical.padding-xxxlarge,
    .convert-grid-row-flex.padding-vertical.padding-xxxlarge {
      padding-top: 7.65625rem;
      padding-bottom: 7.65625rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-vertical.padding-xxxlarge,
    .convert-grid-row.padding-vertical.padding-xxxlarge,
    .convert-grid-row-flex.padding-vertical.padding-xxxlarge {
      padding-top: 8.75rem;
      padding-bottom: 8.75rem; } }

.convert-block.padding-vertical.padding-none,
.convert-grid-row.padding-vertical.padding-none,
.convert-grid-row-flex.padding-vertical.padding-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.convert-block.padding-vertical.padding-fixed-03x,
.convert-grid-row.padding-vertical.padding-fixed-03x,
.convert-grid-row-flex.padding-vertical.padding-fixed-03x {
  padding-top: 6px;
  padding-bottom: 6px; }

.convert-block.padding-vertical.padding-fixed-05x,
.convert-grid-row.padding-vertical.padding-fixed-05x,
.convert-grid-row-flex.padding-vertical.padding-fixed-05x {
  padding-top: 10px;
  padding-bottom: 10px; }

.convert-block.padding-horizontal.padding-small,
.convert-grid-row-flex.padding-horizontal.padding-small {
  padding-left: 0.625rem;
  padding-right: 0.625rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-horizontal.padding-small,
    .convert-grid-row-flex.padding-horizontal.padding-small {
      padding-left: 0.3125rem;
      padding-right: 0.3125rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-horizontal.padding-small,
    .convert-grid-row-flex.padding-horizontal.padding-small {
      padding-left: 0.4375rem;
      padding-right: 0.4375rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-horizontal.padding-small,
    .convert-grid-row-flex.padding-horizontal.padding-small {
      padding-left: 0.5rem;
      padding-right: 0.5rem; } }

.convert-block.padding-horizontal.padding-medium,
.convert-grid-row-flex.padding-horizontal.padding-medium {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-horizontal.padding-medium,
    .convert-grid-row-flex.padding-horizontal.padding-medium {
      padding-left: 0.78125rem;
      padding-right: 0.78125rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-horizontal.padding-medium,
    .convert-grid-row-flex.padding-horizontal.padding-medium {
      padding-left: 1.09375rem;
      padding-right: 1.09375rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-horizontal.padding-medium,
    .convert-grid-row-flex.padding-horizontal.padding-medium {
      padding-left: 1.25rem;
      padding-right: 1.25rem; } }

.convert-block.padding-horizontal.padding-large,
.convert-grid-row-flex.padding-horizontal.padding-large {
  padding-left: 3.125rem;
  padding-right: 3.125rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-horizontal.padding-large,
    .convert-grid-row-flex.padding-horizontal.padding-large {
      padding-left: 1.5625rem;
      padding-right: 1.5625rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-horizontal.padding-large,
    .convert-grid-row-flex.padding-horizontal.padding-large {
      padding-left: 2.1875rem;
      padding-right: 2.1875rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-horizontal.padding-large,
    .convert-grid-row-flex.padding-horizontal.padding-large {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }

.convert-block.padding-horizontal.padding-xlarge,
.convert-grid-row-flex.padding-horizontal.padding-xlarge {
  padding-left: 5rem;
  padding-right: 5rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-horizontal.padding-xlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xlarge {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-horizontal.padding-xlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xlarge {
      padding-left: 3.5rem;
      padding-right: 3.5rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-horizontal.padding-xlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xlarge {
      padding-left: 4rem;
      padding-right: 4rem; } }

.convert-block.padding-horizontal.padding-xxlarge,
.convert-grid-row-flex.padding-horizontal.padding-xxlarge {
  padding-left: 7.8125rem;
  padding-right: 7.8125rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-horizontal.padding-xxlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xxlarge {
      padding-left: 3.90625rem;
      padding-right: 3.90625rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-horizontal.padding-xxlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xxlarge {
      padding-left: 5.46875rem;
      padding-right: 5.46875rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-horizontal.padding-xxlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xxlarge {
      padding-left: 6.25rem;
      padding-right: 6.25rem; } }

.convert-block.padding-horizontal.padding-xxxlarge,
.convert-grid-row-flex.padding-horizontal.padding-xxxlarge {
  padding-left: 10.9375rem;
  padding-right: 10.9375rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-horizontal.padding-xxxlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xxxlarge {
      padding-left: 5.46875rem;
      padding-right: 5.46875rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-horizontal.padding-xxxlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xxxlarge {
      padding-left: 7.65625rem;
      padding-right: 7.65625rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-horizontal.padding-xxxlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xxxlarge {
      padding-left: 8.75rem;
      padding-right: 8.75rem; } }

.convert-block.padding-horizontal.padding-none,
.convert-grid-row-flex.padding-horizontal.padding-none {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.convert-block.padding-horizontal.padding-fixed-03x,
.convert-grid-row-flex.padding-horizontal.padding-fixed-03x {
  padding-left: 6px;
  padding-right: 6px; }

.convert-block.padding-horizontal.padding-fixed-05x,
.convert-grid-row-flex.padding-horizontal.padding-fixed-05x {
  padding-left: 10px;
  padding-right: 10px; }

.convert-title-h.xxxlarge {
  font-size: 32px; }

.convert-title-h.xxlarge {
  font-size: 24px; }

.convert-title-h.xlarge {
  font-size: 18.72px; }

.convert-title-h.medium {
  font-size: 16px; }

.convert-title-h.small {
  font-size: 13.28px; }

.spacing-small {
  margin-bottom: 0.25rem; }

.spacing-medium {
  margin-bottom: 0.5rem; }

.spacing-xlarge {
  margin-bottom: 1.25rem; }

.spacing-xxlarge {
  margin-bottom: 1.5rem; }

.spacing-xxxlarge {
  margin-bottom: 2rem; }

@media screen and (max-width: 39.9375em) {
  .convert-grid-row > .convert-column:not(:last-child) > .convert-article {
    margin-bottom: 2.5rem; } }

.convert-articles .convert-article {
  margin-bottom: 1.5rem; }
  @media print, screen and (min-width: 48em) {
    .convert-articles .convert-article.large-12 {
      clear: both; }
    .convert-articles .convert-article.large-6:nth-of-type(2n+1) {
      clear: both; }
    .convert-articles .convert-article.large-4:nth-of-type(3n+1) {
      clear: both; } }

@media print, screen and (min-width: 40em) {
  .convert-image-w-text {
    position: relative; }
    .convert-image-w-text .convert-image-w-text-image {
      display: block;
      width: 100%;
      margin: 0; }
      .convert-image-w-text .convert-image-w-text-image img {
        max-width: inherit;
        width: 100%; }
    .convert-image-w-text .convert-image-w-text-content {
      position: absolute;
      margin: 0; }
    .convert-image-w-text.x-left .convert-image-w-text-content {
      left: 1.875rem; }
    .convert-image-w-text.x-center .convert-image-w-text-content {
      left: 50%;
      transform: translateX(-50%);
      text-align: center; }
      .convert-image-w-text.x-center .convert-image-w-text-content p {
        text-align: center; }
    .convert-image-w-text.x-right .convert-image-w-text-content {
      right: 1.875rem; }
    .convert-image-w-text.y-top .convert-image-w-text-content {
      top: 1.875rem; }
    .convert-image-w-text.y-center .convert-image-w-text-content {
      top: 50%; }
    .convert-image-w-text.y-bottom .convert-image-w-text-content {
      bottom: 1.875rem; }
    .convert-image-w-text.x-center.y-center .convert-image-w-text-content {
      transform: translateX(-50%) translateY(-50%); } }

.convert-image-w-text-content[style] .convert-title,
.convert-image-w-text-content[style] .convert-wysiwyg {
  color: inherit; }

.convert-grid-row-flex {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .convert-grid-row-flex .convert-grid-row-flex {
    margin-right: -1.0625rem;
    margin-left: -1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .convert-grid-row-flex .convert-grid-row-flex {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .convert-grid-row-flex .convert-grid-row-flex {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .convert-grid-row-flex .convert-grid-row-flex {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .convert-grid-row-flex .convert-grid-row-flex {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .convert-grid-row-flex .convert-grid-row-flex {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
  .convert-grid-row-flex.expanded {
    max-width: none; }
    .convert-grid-row-flex.expanded .convert-grid-row-flex {
      margin-right: auto;
      margin-left: auto; }
  .convert-grid-row-flex:not(.expanded) .convert-grid-row-flex {
    max-width: none; }
  .convert-grid-row-flex.collapse > .convert-column-flex {
    padding-right: 0;
    padding-left: 0; }
  .convert-grid-row-flex.collapse > .convert-column-flex > .convert-grid-row-flex {
    margin-right: 0;
    margin-left: 0; }
  .convert-grid-row-flex.small-collapse > .convert-column-flex {
    padding-left: 0;
    padding-right: 0; }

.convert-column-flex {
  flex: 1 1 0px;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem;
  min-width: initial; }
  @media print, screen and (min-width: 40em) {
    .convert-column-flex {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }

.convert-column-flex.small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.convert-column-flex.small-offset-0 {
  margin-left: 0%; }

.convert-column-flex.small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.convert-column-flex.small-offset-1 {
  margin-left: 8.33333%; }

.convert-column-flex.small-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.convert-column-flex.small-offset-2 {
  margin-left: 16.66667%; }

.convert-column-flex.small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.convert-column-flex.small-offset-3 {
  margin-left: 25%; }

.convert-column-flex.small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.convert-column-flex.small-offset-4 {
  margin-left: 33.33333%; }

.convert-column-flex.small-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.convert-column-flex.small-offset-5 {
  margin-left: 41.66667%; }

.convert-column-flex.small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.convert-column-flex.small-offset-6 {
  margin-left: 50%; }

.convert-column-flex.small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.convert-column-flex.small-offset-7 {
  margin-left: 58.33333%; }

.convert-column-flex.small-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.convert-column-flex.small-offset-8 {
  margin-left: 66.66667%; }

.convert-column-flex.small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.convert-column-flex.small-offset-9 {
  margin-left: 75%; }

.convert-column-flex.small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.convert-column-flex.small-offset-10 {
  margin-left: 83.33333%; }

.convert-column-flex.small-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.convert-column-flex.small-offset-11 {
  margin-left: 91.66667%; }

.convert-column-flex.small-order-1 {
  order: 1; }

.convert-column-flex.small-order-2 {
  order: 2; }

.convert-column-flex.small-order-3 {
  order: 3; }

.convert-column-flex.small-order-4 {
  order: 4; }

.convert-column-flex.small-order-5 {
  order: 5; }

.convert-column-flex.small-order-6 {
  order: 6; }

.convert-column-flex.small-up-1 {
  flex-wrap: wrap; }
  .convert-column-flex.small-up-1 > .column, .convert-column-flex.small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.convert-column-flex.small-up-2 {
  flex-wrap: wrap; }
  .convert-column-flex.small-up-2 > .column, .convert-column-flex.small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.convert-column-flex.small-up-3 {
  flex-wrap: wrap; }
  .convert-column-flex.small-up-3 > .column, .convert-column-flex.small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.convert-column-flex.small-up-4 {
  flex-wrap: wrap; }
  .convert-column-flex.small-up-4 > .column, .convert-column-flex.small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.convert-column-flex.small-up-5 {
  flex-wrap: wrap; }
  .convert-column-flex.small-up-5 > .column, .convert-column-flex.small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.convert-column-flex.small-up-6 {
  flex-wrap: wrap; }
  .convert-column-flex.small-up-6 > .column, .convert-column-flex.small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.convert-column-flex.small-up-7 {
  flex-wrap: wrap; }
  .convert-column-flex.small-up-7 > .column, .convert-column-flex.small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.convert-column-flex.small-up-8 {
  flex-wrap: wrap; }
  .convert-column-flex.small-up-8 > .column, .convert-column-flex.small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.convert-column-flex.small-collapse > .convert-column-flex {
  padding-right: 0;
  padding-left: 0; }

.convert-column-flex.small-uncollapse > .convert-column-flex {
  padding-right: 1.0625rem;
  padding-left: 1.0625rem; }

@media print, screen and (min-width: 40em) {
  .convert-column-flex.medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .convert-column-flex.medium-offset-0 {
    margin-left: 0%; }
  .convert-column-flex.medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .convert-column-flex.medium-offset-1 {
    margin-left: 8.33333%; }
  .convert-column-flex.medium-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .convert-column-flex.medium-offset-2 {
    margin-left: 16.66667%; }
  .convert-column-flex.medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .convert-column-flex.medium-offset-3 {
    margin-left: 25%; }
  .convert-column-flex.medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .convert-column-flex.medium-offset-4 {
    margin-left: 33.33333%; }
  .convert-column-flex.medium-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .convert-column-flex.medium-offset-5 {
    margin-left: 41.66667%; }
  .convert-column-flex.medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .convert-column-flex.medium-offset-6 {
    margin-left: 50%; }
  .convert-column-flex.medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .convert-column-flex.medium-offset-7 {
    margin-left: 58.33333%; }
  .convert-column-flex.medium-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .convert-column-flex.medium-offset-8 {
    margin-left: 66.66667%; }
  .convert-column-flex.medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .convert-column-flex.medium-offset-9 {
    margin-left: 75%; }
  .convert-column-flex.medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .convert-column-flex.medium-offset-10 {
    margin-left: 83.33333%; }
  .convert-column-flex.medium-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .convert-column-flex.medium-offset-11 {
    margin-left: 91.66667%; }
  .convert-column-flex.medium-order-1 {
    order: 1; }
  .convert-column-flex.medium-order-2 {
    order: 2; }
  .convert-column-flex.medium-order-3 {
    order: 3; }
  .convert-column-flex.medium-order-4 {
    order: 4; }
  .convert-column-flex.medium-order-5 {
    order: 5; }
  .convert-column-flex.medium-order-6 {
    order: 6; }
  .convert-column-flex.medium-up-1 {
    flex-wrap: wrap; }
    .convert-column-flex.medium-up-1 > .column, .convert-column-flex.medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .convert-column-flex.medium-up-2 {
    flex-wrap: wrap; }
    .convert-column-flex.medium-up-2 > .column, .convert-column-flex.medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .convert-column-flex.medium-up-3 {
    flex-wrap: wrap; }
    .convert-column-flex.medium-up-3 > .column, .convert-column-flex.medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .convert-column-flex.medium-up-4 {
    flex-wrap: wrap; }
    .convert-column-flex.medium-up-4 > .column, .convert-column-flex.medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .convert-column-flex.medium-up-5 {
    flex-wrap: wrap; }
    .convert-column-flex.medium-up-5 > .column, .convert-column-flex.medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .convert-column-flex.medium-up-6 {
    flex-wrap: wrap; }
    .convert-column-flex.medium-up-6 > .column, .convert-column-flex.medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .convert-column-flex.medium-up-7 {
    flex-wrap: wrap; }
    .convert-column-flex.medium-up-7 > .column, .convert-column-flex.medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .convert-column-flex.medium-up-8 {
    flex-wrap: wrap; }
    .convert-column-flex.medium-up-8 > .column, .convert-column-flex.medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .convert-column-flex.medium-expand {
    flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .convert-column-flex.medium-flex-dir-row {
    flex-direction: row; }
  .convert-column-flex.medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .convert-column-flex.medium-flex-dir-column {
    flex-direction: column; }
  .convert-column-flex.medium-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .convert-column-flex.medium-flex-child-auto {
    flex: 1 1 auto; }
  .convert-column-flex.medium-flex-child-grow {
    flex: 1 0 auto; }
  .convert-column-flex.medium-flex-child-shrink {
    flex: 0 1 auto; } }

.convert-grid-row-flex.medium-unstack > .convert-column-flex {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 40em) {
    .convert-grid-row-flex.medium-unstack > .convert-column-flex {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .convert-column-flex.medium-collapse > .convert-column-flex {
    padding-right: 0;
    padding-left: 0; }
  .convert-column-flex.medium-uncollapse > .convert-column-flex {
    padding-right: 1.25rem;
    padding-left: 1.25rem; } }

@media print, screen and (min-width: 48em) {
  .convert-column-flex.large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .convert-column-flex.large-offset-0 {
    margin-left: 0%; }
  .convert-column-flex.large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .convert-column-flex.large-offset-1 {
    margin-left: 8.33333%; }
  .convert-column-flex.large-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .convert-column-flex.large-offset-2 {
    margin-left: 16.66667%; }
  .convert-column-flex.large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .convert-column-flex.large-offset-3 {
    margin-left: 25%; }
  .convert-column-flex.large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .convert-column-flex.large-offset-4 {
    margin-left: 33.33333%; }
  .convert-column-flex.large-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .convert-column-flex.large-offset-5 {
    margin-left: 41.66667%; }
  .convert-column-flex.large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .convert-column-flex.large-offset-6 {
    margin-left: 50%; }
  .convert-column-flex.large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .convert-column-flex.large-offset-7 {
    margin-left: 58.33333%; }
  .convert-column-flex.large-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .convert-column-flex.large-offset-8 {
    margin-left: 66.66667%; }
  .convert-column-flex.large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .convert-column-flex.large-offset-9 {
    margin-left: 75%; }
  .convert-column-flex.large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .convert-column-flex.large-offset-10 {
    margin-left: 83.33333%; }
  .convert-column-flex.large-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .convert-column-flex.large-offset-11 {
    margin-left: 91.66667%; }
  .convert-column-flex.large-order-1 {
    order: 1; }
  .convert-column-flex.large-order-2 {
    order: 2; }
  .convert-column-flex.large-order-3 {
    order: 3; }
  .convert-column-flex.large-order-4 {
    order: 4; }
  .convert-column-flex.large-order-5 {
    order: 5; }
  .convert-column-flex.large-order-6 {
    order: 6; }
  .convert-column-flex.large-up-1 {
    flex-wrap: wrap; }
    .convert-column-flex.large-up-1 > .column, .convert-column-flex.large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .convert-column-flex.large-up-2 {
    flex-wrap: wrap; }
    .convert-column-flex.large-up-2 > .column, .convert-column-flex.large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .convert-column-flex.large-up-3 {
    flex-wrap: wrap; }
    .convert-column-flex.large-up-3 > .column, .convert-column-flex.large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .convert-column-flex.large-up-4 {
    flex-wrap: wrap; }
    .convert-column-flex.large-up-4 > .column, .convert-column-flex.large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .convert-column-flex.large-up-5 {
    flex-wrap: wrap; }
    .convert-column-flex.large-up-5 > .column, .convert-column-flex.large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .convert-column-flex.large-up-6 {
    flex-wrap: wrap; }
    .convert-column-flex.large-up-6 > .column, .convert-column-flex.large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .convert-column-flex.large-up-7 {
    flex-wrap: wrap; }
    .convert-column-flex.large-up-7 > .column, .convert-column-flex.large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .convert-column-flex.large-up-8 {
    flex-wrap: wrap; }
    .convert-column-flex.large-up-8 > .column, .convert-column-flex.large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 48em) and (min-width: 48em) {
  .convert-column-flex.large-expand {
    flex: 1 1 0px; } }

@media print, screen and (min-width: 48em) {
  .convert-column-flex.large-flex-dir-row {
    flex-direction: row; }
  .convert-column-flex.large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .convert-column-flex.large-flex-dir-column {
    flex-direction: column; }
  .convert-column-flex.large-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .convert-column-flex.large-flex-child-auto {
    flex: 1 1 auto; }
  .convert-column-flex.large-flex-child-grow {
    flex: 1 0 auto; }
  .convert-column-flex.large-flex-child-shrink {
    flex: 0 1 auto; } }

.convert-grid-row-flex.large-unstack > .convert-column-flex {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 48em) {
    .convert-grid-row-flex.large-unstack > .convert-column-flex {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 48em) {
  .convert-column-flex.large-collapse > .convert-column-flex {
    padding-right: 0;
    padding-left: 0; }
  .convert-column-flex.large-uncollapse > .convert-column-flex {
    padding-right: 1.25rem;
    padding-left: 1.25rem; } }

@media screen and (min-width: 64em) {
  .convert-column-flex.xlarge-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .convert-column-flex.xlarge-offset-0 {
    margin-left: 0%; }
  .convert-column-flex.xlarge-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .convert-column-flex.xlarge-offset-1 {
    margin-left: 8.33333%; }
  .convert-column-flex.xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .convert-column-flex.xlarge-offset-2 {
    margin-left: 16.66667%; }
  .convert-column-flex.xlarge-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .convert-column-flex.xlarge-offset-3 {
    margin-left: 25%; }
  .convert-column-flex.xlarge-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .convert-column-flex.xlarge-offset-4 {
    margin-left: 33.33333%; }
  .convert-column-flex.xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .convert-column-flex.xlarge-offset-5 {
    margin-left: 41.66667%; }
  .convert-column-flex.xlarge-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .convert-column-flex.xlarge-offset-6 {
    margin-left: 50%; }
  .convert-column-flex.xlarge-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .convert-column-flex.xlarge-offset-7 {
    margin-left: 58.33333%; }
  .convert-column-flex.xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .convert-column-flex.xlarge-offset-8 {
    margin-left: 66.66667%; }
  .convert-column-flex.xlarge-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .convert-column-flex.xlarge-offset-9 {
    margin-left: 75%; }
  .convert-column-flex.xlarge-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .convert-column-flex.xlarge-offset-10 {
    margin-left: 83.33333%; }
  .convert-column-flex.xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .convert-column-flex.xlarge-offset-11 {
    margin-left: 91.66667%; }
  .convert-column-flex.xlarge-order-1 {
    order: 1; }
  .convert-column-flex.xlarge-order-2 {
    order: 2; }
  .convert-column-flex.xlarge-order-3 {
    order: 3; }
  .convert-column-flex.xlarge-order-4 {
    order: 4; }
  .convert-column-flex.xlarge-order-5 {
    order: 5; }
  .convert-column-flex.xlarge-order-6 {
    order: 6; }
  .convert-column-flex.xlarge-up-1 {
    flex-wrap: wrap; }
    .convert-column-flex.xlarge-up-1 > .column, .convert-column-flex.xlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .convert-column-flex.xlarge-up-2 {
    flex-wrap: wrap; }
    .convert-column-flex.xlarge-up-2 > .column, .convert-column-flex.xlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .convert-column-flex.xlarge-up-3 {
    flex-wrap: wrap; }
    .convert-column-flex.xlarge-up-3 > .column, .convert-column-flex.xlarge-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .convert-column-flex.xlarge-up-4 {
    flex-wrap: wrap; }
    .convert-column-flex.xlarge-up-4 > .column, .convert-column-flex.xlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .convert-column-flex.xlarge-up-5 {
    flex-wrap: wrap; }
    .convert-column-flex.xlarge-up-5 > .column, .convert-column-flex.xlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .convert-column-flex.xlarge-up-6 {
    flex-wrap: wrap; }
    .convert-column-flex.xlarge-up-6 > .column, .convert-column-flex.xlarge-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .convert-column-flex.xlarge-up-7 {
    flex-wrap: wrap; }
    .convert-column-flex.xlarge-up-7 > .column, .convert-column-flex.xlarge-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .convert-column-flex.xlarge-up-8 {
    flex-wrap: wrap; }
    .convert-column-flex.xlarge-up-8 > .column, .convert-column-flex.xlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 64em) and (min-width: 64em) {
  .convert-column-flex.xlarge-expand {
    flex: 1 1 0px; } }

@media screen and (min-width: 64em) {
  .convert-column-flex.xlarge-flex-dir-row {
    flex-direction: row; }
  .convert-column-flex.xlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .convert-column-flex.xlarge-flex-dir-column {
    flex-direction: column; }
  .convert-column-flex.xlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .convert-column-flex.xlarge-flex-child-auto {
    flex: 1 1 auto; }
  .convert-column-flex.xlarge-flex-child-grow {
    flex: 1 0 auto; }
  .convert-column-flex.xlarge-flex-child-shrink {
    flex: 0 1 auto; } }

.convert-grid-row-flex.xlarge-unstack > .convert-column-flex {
  flex: 0 0 100%; }
  @media screen and (min-width: 64em) {
    .convert-grid-row-flex.xlarge-unstack > .convert-column-flex {
      flex: 1 1 0px; } }

@media screen and (min-width: 64em) {
  .convert-column-flex.xlarge-collapse > .convert-column-flex {
    padding-right: 0;
    padding-left: 0; }
  .convert-column-flex.xlarge-uncollapse > .convert-column-flex {
    padding-right: 1.25rem;
    padding-left: 1.25rem; } }

@media screen and (min-width: 75em) {
  .convert-column-flex.xxlarge-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .convert-column-flex.xxlarge-offset-0 {
    margin-left: 0%; }
  .convert-column-flex.xxlarge-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .convert-column-flex.xxlarge-offset-1 {
    margin-left: 8.33333%; }
  .convert-column-flex.xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .convert-column-flex.xxlarge-offset-2 {
    margin-left: 16.66667%; }
  .convert-column-flex.xxlarge-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .convert-column-flex.xxlarge-offset-3 {
    margin-left: 25%; }
  .convert-column-flex.xxlarge-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .convert-column-flex.xxlarge-offset-4 {
    margin-left: 33.33333%; }
  .convert-column-flex.xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .convert-column-flex.xxlarge-offset-5 {
    margin-left: 41.66667%; }
  .convert-column-flex.xxlarge-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .convert-column-flex.xxlarge-offset-6 {
    margin-left: 50%; }
  .convert-column-flex.xxlarge-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .convert-column-flex.xxlarge-offset-7 {
    margin-left: 58.33333%; }
  .convert-column-flex.xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .convert-column-flex.xxlarge-offset-8 {
    margin-left: 66.66667%; }
  .convert-column-flex.xxlarge-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .convert-column-flex.xxlarge-offset-9 {
    margin-left: 75%; }
  .convert-column-flex.xxlarge-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .convert-column-flex.xxlarge-offset-10 {
    margin-left: 83.33333%; }
  .convert-column-flex.xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .convert-column-flex.xxlarge-offset-11 {
    margin-left: 91.66667%; }
  .convert-column-flex.xxlarge-order-1 {
    order: 1; }
  .convert-column-flex.xxlarge-order-2 {
    order: 2; }
  .convert-column-flex.xxlarge-order-3 {
    order: 3; }
  .convert-column-flex.xxlarge-order-4 {
    order: 4; }
  .convert-column-flex.xxlarge-order-5 {
    order: 5; }
  .convert-column-flex.xxlarge-order-6 {
    order: 6; }
  .convert-column-flex.xxlarge-up-1 {
    flex-wrap: wrap; }
    .convert-column-flex.xxlarge-up-1 > .column, .convert-column-flex.xxlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .convert-column-flex.xxlarge-up-2 {
    flex-wrap: wrap; }
    .convert-column-flex.xxlarge-up-2 > .column, .convert-column-flex.xxlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .convert-column-flex.xxlarge-up-3 {
    flex-wrap: wrap; }
    .convert-column-flex.xxlarge-up-3 > .column, .convert-column-flex.xxlarge-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .convert-column-flex.xxlarge-up-4 {
    flex-wrap: wrap; }
    .convert-column-flex.xxlarge-up-4 > .column, .convert-column-flex.xxlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .convert-column-flex.xxlarge-up-5 {
    flex-wrap: wrap; }
    .convert-column-flex.xxlarge-up-5 > .column, .convert-column-flex.xxlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .convert-column-flex.xxlarge-up-6 {
    flex-wrap: wrap; }
    .convert-column-flex.xxlarge-up-6 > .column, .convert-column-flex.xxlarge-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .convert-column-flex.xxlarge-up-7 {
    flex-wrap: wrap; }
    .convert-column-flex.xxlarge-up-7 > .column, .convert-column-flex.xxlarge-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .convert-column-flex.xxlarge-up-8 {
    flex-wrap: wrap; }
    .convert-column-flex.xxlarge-up-8 > .column, .convert-column-flex.xxlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 75em) and (min-width: 75em) {
  .convert-column-flex.xxlarge-expand {
    flex: 1 1 0px; } }

@media screen and (min-width: 75em) {
  .convert-column-flex.xxlarge-flex-dir-row {
    flex-direction: row; }
  .convert-column-flex.xxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .convert-column-flex.xxlarge-flex-dir-column {
    flex-direction: column; }
  .convert-column-flex.xxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .convert-column-flex.xxlarge-flex-child-auto {
    flex: 1 1 auto; }
  .convert-column-flex.xxlarge-flex-child-grow {
    flex: 1 0 auto; }
  .convert-column-flex.xxlarge-flex-child-shrink {
    flex: 0 1 auto; } }

.convert-grid-row-flex.xxlarge-unstack > .convert-column-flex {
  flex: 0 0 100%; }
  @media screen and (min-width: 75em) {
    .convert-grid-row-flex.xxlarge-unstack > .convert-column-flex {
      flex: 1 1 0px; } }

@media screen and (min-width: 75em) {
  .convert-column-flex.xxlarge-collapse > .convert-column-flex {
    padding-right: 0;
    padding-left: 0; }
  .convert-column-flex.xxlarge-uncollapse > .convert-column-flex {
    padding-right: 1.25rem;
    padding-left: 1.25rem; } }

@media screen and (min-width: 90.625em) {
  .convert-column-flex.xxxlarge-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .convert-column-flex.xxxlarge-offset-0 {
    margin-left: 0%; }
  .convert-column-flex.xxxlarge-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .convert-column-flex.xxxlarge-offset-1 {
    margin-left: 8.33333%; }
  .convert-column-flex.xxxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .convert-column-flex.xxxlarge-offset-2 {
    margin-left: 16.66667%; }
  .convert-column-flex.xxxlarge-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .convert-column-flex.xxxlarge-offset-3 {
    margin-left: 25%; }
  .convert-column-flex.xxxlarge-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .convert-column-flex.xxxlarge-offset-4 {
    margin-left: 33.33333%; }
  .convert-column-flex.xxxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .convert-column-flex.xxxlarge-offset-5 {
    margin-left: 41.66667%; }
  .convert-column-flex.xxxlarge-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .convert-column-flex.xxxlarge-offset-6 {
    margin-left: 50%; }
  .convert-column-flex.xxxlarge-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .convert-column-flex.xxxlarge-offset-7 {
    margin-left: 58.33333%; }
  .convert-column-flex.xxxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .convert-column-flex.xxxlarge-offset-8 {
    margin-left: 66.66667%; }
  .convert-column-flex.xxxlarge-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .convert-column-flex.xxxlarge-offset-9 {
    margin-left: 75%; }
  .convert-column-flex.xxxlarge-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .convert-column-flex.xxxlarge-offset-10 {
    margin-left: 83.33333%; }
  .convert-column-flex.xxxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .convert-column-flex.xxxlarge-offset-11 {
    margin-left: 91.66667%; }
  .convert-column-flex.xxxlarge-order-1 {
    order: 1; }
  .convert-column-flex.xxxlarge-order-2 {
    order: 2; }
  .convert-column-flex.xxxlarge-order-3 {
    order: 3; }
  .convert-column-flex.xxxlarge-order-4 {
    order: 4; }
  .convert-column-flex.xxxlarge-order-5 {
    order: 5; }
  .convert-column-flex.xxxlarge-order-6 {
    order: 6; }
  .convert-column-flex.xxxlarge-up-1 {
    flex-wrap: wrap; }
    .convert-column-flex.xxxlarge-up-1 > .column, .convert-column-flex.xxxlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .convert-column-flex.xxxlarge-up-2 {
    flex-wrap: wrap; }
    .convert-column-flex.xxxlarge-up-2 > .column, .convert-column-flex.xxxlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .convert-column-flex.xxxlarge-up-3 {
    flex-wrap: wrap; }
    .convert-column-flex.xxxlarge-up-3 > .column, .convert-column-flex.xxxlarge-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .convert-column-flex.xxxlarge-up-4 {
    flex-wrap: wrap; }
    .convert-column-flex.xxxlarge-up-4 > .column, .convert-column-flex.xxxlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .convert-column-flex.xxxlarge-up-5 {
    flex-wrap: wrap; }
    .convert-column-flex.xxxlarge-up-5 > .column, .convert-column-flex.xxxlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .convert-column-flex.xxxlarge-up-6 {
    flex-wrap: wrap; }
    .convert-column-flex.xxxlarge-up-6 > .column, .convert-column-flex.xxxlarge-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .convert-column-flex.xxxlarge-up-7 {
    flex-wrap: wrap; }
    .convert-column-flex.xxxlarge-up-7 > .column, .convert-column-flex.xxxlarge-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .convert-column-flex.xxxlarge-up-8 {
    flex-wrap: wrap; }
    .convert-column-flex.xxxlarge-up-8 > .column, .convert-column-flex.xxxlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 90.625em) and (min-width: 90.625em) {
  .convert-column-flex.xxxlarge-expand {
    flex: 1 1 0px; } }

@media screen and (min-width: 90.625em) {
  .convert-column-flex.xxxlarge-flex-dir-row {
    flex-direction: row; }
  .convert-column-flex.xxxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .convert-column-flex.xxxlarge-flex-dir-column {
    flex-direction: column; }
  .convert-column-flex.xxxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .convert-column-flex.xxxlarge-flex-child-auto {
    flex: 1 1 auto; }
  .convert-column-flex.xxxlarge-flex-child-grow {
    flex: 1 0 auto; }
  .convert-column-flex.xxxlarge-flex-child-shrink {
    flex: 0 1 auto; } }

.convert-grid-row-flex.xxxlarge-unstack > .convert-column-flex {
  flex: 0 0 100%; }
  @media screen and (min-width: 90.625em) {
    .convert-grid-row-flex.xxxlarge-unstack > .convert-column-flex {
      flex: 1 1 0px; } }

@media screen and (min-width: 90.625em) {
  .convert-column-flex.xxxlarge-collapse > .convert-column-flex {
    padding-right: 0;
    padding-left: 0; }
  .convert-column-flex.xxxlarge-uncollapse > .convert-column-flex {
    padding-right: 1.25rem;
    padding-left: 1.25rem; } }

.align-hz-right {
  justify-content: flex-end; }

.align-hz-center {
  justify-content: center; }

.align-hz-justify {
  justify-content: space-between; }

.align-hz-spaced {
  justify-content: space-around; }

.align-vt-top {
  align-items: flex-start; }

.align-vt-self-top {
  align-self: flex-start; }

.align-vt-bottom {
  align-items: flex-end; }

.align-vt-self-bottom {
  align-self: flex-end; }

.align-vt-middle {
  align-items: center; }

.align-vt-self-middle {
  align-self: center; }

.align-vt-stretch {
  align-items: stretch; }

.align-vt-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.convert-grid-row-vc {
  justify-content: flex-start;
  align-items: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 48em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

@media screen and (min-width: 64em) {
  .xlarge-order-1 {
    order: 1; }
  .xlarge-order-2 {
    order: 2; }
  .xlarge-order-3 {
    order: 3; }
  .xlarge-order-4 {
    order: 4; }
  .xlarge-order-5 {
    order: 5; }
  .xlarge-order-6 {
    order: 6; } }

@media screen and (min-width: 75em) {
  .xxlarge-order-1 {
    order: 1; }
  .xxlarge-order-2 {
    order: 2; }
  .xxlarge-order-3 {
    order: 3; }
  .xxlarge-order-4 {
    order: 4; }
  .xxlarge-order-5 {
    order: 5; }
  .xxlarge-order-6 {
    order: 6; } }

@media screen and (min-width: 90.625em) {
  .xxxlarge-order-1 {
    order: 1; }
  .xxxlarge-order-2 {
    order: 2; }
  .xxxlarge-order-3 {
    order: 3; }
  .xxxlarge-order-4 {
    order: 4; }
  .xxxlarge-order-5 {
    order: 5; }
  .xxxlarge-order-6 {
    order: 6; } }

.convert-divider {
  max-width: inherit; }

.convert-teaser {
  overflow: hidden;
  background: #F1EFED;
  text-align: center;
  margin-bottom: 2rem; }
  .convert-teaser .convert-teaser-content {
    padding: 1.4rem 2rem 2.8rem;
    position: relative; }
  .convert-teaser .convert-title {
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-weight: 300;
    font-size: 1.75rem;
    color: #181818;
    line-height: 1;
    margin-bottom: .5rem; }
  .convert-teaser .convert-subtitle {
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-weight: 400;
    font-size: 0.8125rem;
    color: rgba(24, 24, 24, 0.7);
    line-height: 1.2;
    margin-bottom: 0; }
  .convert-teaser .convert-teaser-image {
    text-align: center; }
  .convert-teaser .convert-wysiwyg {
    margin-bottom: 1.7rem; }
    .convert-teaser .convert-wysiwyg p {
      font-size: 0.8125rem;
      line-height: 1.6;
      text-align: left; }
      .convert-teaser .convert-wysiwyg p:last-child {
        margin-bottom: 0; }
      .convert-teaser .convert-wysiwyg p.text-small {
        font-size: 0.6875rem; }
      .convert-teaser .convert-wysiwyg p.text-normal {
        font-size: 0.8125rem; }
      .convert-teaser .convert-wysiwyg p.text-large {
        font-size: 0.9375rem; }
      .convert-teaser .convert-wysiwyg p.text-xlarge {
        font-size: 1.0625rem; }
      .convert-teaser .convert-wysiwyg p.text-xxlarge {
        font-size: 1.1875rem; }
        @media screen and (max-width: 47.9375em) {
          .convert-teaser .convert-wysiwyg p.text-xxlarge {
            font-size: 1.0625rem; } }
  .convert-teaser hr {
    margin: 1.1rem 0; }
  .convert-teaser p {
    text-align: left; }
  .convert-teaser a.link {
    text-transform: uppercase;
    font-family: "silka-regular-webfont", sans-serif;
    text-transform: uppercase;
    text-decoration: underline;
    margin-top: 1.5rem;
    display: inline-block; }
    .convert-teaser a.link:hover {
      text-decoration: none; }
  .convert-teaser.rounded .convert-teaser-image img {
    width: 81px;
    height: 81px;
    border-radius: 100%;
    max-width: none;
    transform: translateX(-50%);
    position: relative;
    left: 50%; }
  .convert-teaser.small .convert-teaser-image,
  .convert-teaser.small .convert-teaser-content {
    display: table-cell !important; }
  .convert-teaser.small .convert-teaser-content {
    text-align: left !important; }
  .convert-teaser.small .convert-title {
    font-size: 0.9375rem; }
  .convert-teaser.small .subheader {
    margin-bottom: 0; }
  .convert-teaser.small a.link {
    margin-top: 0; }
  .convert-teaser .button {
    min-width: 12.5rem;
    margin-bottom: 0; }
    @media screen and (max-width: 39.9375em) {
      .convert-teaser .button {
        min-width: inherit;
        width: 100%; } }
  @media screen and (min-width: 40em) and (max-width: 74.9375em) {
    .medium-3 > .convert-teaser .convert-teaser-image,
    .medium-2 > .convert-teaser .convert-teaser-image {
      position: relative;
      overflow: hidden; }
    .medium-3 > .convert-teaser .convert-teaser-content,
    .medium-2 > .convert-teaser .convert-teaser-content {
      padding: 1.5rem 1.5rem 1.75rem;
      position: relative; }
    .medium-3 > .convert-teaser .convert-title,
    .medium-2 > .convert-teaser .convert-title {
      font-size: 1.375rem; }
    .medium-3 > .convert-teaser .convert-subtitle,
    .medium-2 > .convert-teaser .convert-subtitle {
      font-size: 0.6875rem; }
    .medium-3 > .convert-teaser .convert-wysiwyg,
    .medium-2 > .convert-teaser .convert-wysiwyg {
      margin-bottom: 1rem; }
    .medium-3 > .convert-teaser hr,
    .medium-2 > .convert-teaser hr {
      margin: .75rem 0; }
    .medium-3 > .convert-teaser .button,
    .medium-2 > .convert-teaser .button {
      min-width: inherit;
      width: 100%; }
    .medium-4 > .convert-teaser .convert-teaser-image {
      position: relative;
      overflow: hidden; }
    .medium-4 > .convert-teaser .convert-teaser-content {
      padding: 1.2rem 1.5rem 2rem;
      position: relative; }
    .medium-4 > .convert-teaser .button {
      min-width: inherit;
      width: 100%; }
    .medium-8 > .convert-teaser,
    .medium-9 > .convert-teaser,
    .medium-12 > .convert-teaser {
      text-align: left;
      overflow: hidden;
      display: flex;
      flex-direction: row; }
      .medium-8 > .convert-teaser .convert-teaser-image,
      .medium-9 > .convert-teaser .convert-teaser-image,
      .medium-12 > .convert-teaser .convert-teaser-image {
        float: left;
        width: 25%;
        overflow: hidden;
        position: relative; }
        .medium-8 > .convert-teaser .convert-teaser-image img,
        .medium-9 > .convert-teaser .convert-teaser-image img,
        .medium-12 > .convert-teaser .convert-teaser-image img {
          max-width: inherit;
          min-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%); }
      .medium-8 > .convert-teaser .convert-teaser-content,
      .medium-9 > .convert-teaser .convert-teaser-content,
      .medium-12 > .convert-teaser .convert-teaser-content {
        float: left;
        width: 75%;
        padding: 2.25rem 2rem 1rem 2rem; }
      .medium-8 > .convert-teaser .button,
      .medium-9 > .convert-teaser .button,
      .medium-12 > .convert-teaser .button {
        position: absolute;
        top: 2.25rem;
        right: 2rem;
        min-width: inherit;
        width: 8.125rem; }
      .medium-8 > .convert-teaser .convert-title,
      .medium-8 > .convert-teaser .convert-subtitle,
      .medium-9 > .convert-teaser .convert-title,
      .medium-9 > .convert-teaser .convert-subtitle,
      .medium-12 > .convert-teaser .convert-title,
      .medium-12 > .convert-teaser .convert-subtitle {
        margin-right: 8.75rem; }
      .medium-8 > .convert-teaser hr,
      .medium-9 > .convert-teaser hr,
      .medium-12 > .convert-teaser hr {
        margin: .95rem 0; } }
  @media screen and (min-width: 64em) {
    .xlarge-3 > .convert-teaser .convert-teaser-image,
    .xlarge-2 > .convert-teaser .convert-teaser-image {
      position: relative;
      overflow: hidden; }
    .xlarge-3 > .convert-teaser .convert-teaser-content,
    .xlarge-2 > .convert-teaser .convert-teaser-content {
      padding: 1.8rem 1.4rem 1.8rem 2.2rem;
      position: relative; }
    .xlarge-3 > .convert-teaser .convert-wysiwyg,
    .xlarge-2 > .convert-teaser .convert-wysiwyg {
      margin-bottom: 1rem; }
    .xlarge-3 > .convert-teaser hr,
    .xlarge-2 > .convert-teaser hr {
      margin: .75rem 0; }
    .xlarge-3 > .convert-teaser .button,
    .xlarge-2 > .convert-teaser .button {
      min-width: inherit;
      width: 100%; }
    .xlarge-4 > .convert-teaser .convert-teaser-image {
      position: relative;
      overflow: hidden; }
    .xlarge-4 > .convert-teaser .convert-teaser-content {
      padding: 1.8rem 1.4rem 1.8rem 2.2rem;
      position: relative; }
    .xlarge-4 > .convert-teaser .button {
      min-width: inherit;
      width: 100%; }
    .xlarge-6 > .convert-teaser {
      text-align: left; }
      .xlarge-6 > .convert-teaser .convert-teaser-content {
        padding: 1.8rem 1.4rem 1.8rem 2.2rem; }
      .xlarge-6 > .convert-teaser .button {
        position: absolute;
        top: 1.8rem;
        right: 1.4rem;
        max-width: 15.625rem; }
      .xlarge-6 > .convert-teaser .convert-title,
      .xlarge-6 > .convert-teaser .convert-subtitle {
        margin-right: 17.5rem; }
      .xlarge-6 > .convert-teaser hr {
        margin: .95rem 0; }
    .xlarge-8 > .convert-teaser,
    .xlarge-9 > .convert-teaser,
    .xlarge-12 > .convert-teaser {
      text-align: left;
      overflow: hidden;
      display: flex;
      flex-direction: row; }
      .xlarge-8 > .convert-teaser .convert-teaser-image,
      .xlarge-9 > .convert-teaser .convert-teaser-image,
      .xlarge-12 > .convert-teaser .convert-teaser-image {
        float: left;
        width: 32%;
        overflow: hidden;
        position: relative; }
        .xlarge-8 > .convert-teaser .convert-teaser-image img,
        .xlarge-9 > .convert-teaser .convert-teaser-image img,
        .xlarge-12 > .convert-teaser .convert-teaser-image img {
          max-width: inherit;
          min-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%); }
      .xlarge-8 > .convert-teaser .convert-teaser-content,
      .xlarge-9 > .convert-teaser .convert-teaser-content,
      .xlarge-12 > .convert-teaser .convert-teaser-content {
        float: left;
        width: 68%;
        padding: 1.8rem 1.4rem 1.8rem 2.2rem; }
      .xlarge-8 > .convert-teaser .button,
      .xlarge-9 > .convert-teaser .button,
      .xlarge-12 > .convert-teaser .button {
        position: absolute;
        top: 1.8rem;
        right: 1.4rem;
        width: 9.375rem;
        min-width: inherit; }
      .xlarge-8 > .convert-teaser .convert-title,
      .xlarge-8 > .convert-teaser .convert-subtitle,
      .xlarge-9 > .convert-teaser .convert-title,
      .xlarge-9 > .convert-teaser .convert-subtitle,
      .xlarge-12 > .convert-teaser .convert-title,
      .xlarge-12 > .convert-teaser .convert-subtitle {
        margin-right: 11.25rem; }
      .xlarge-8 > .convert-teaser hr,
      .xlarge-9 > .convert-teaser hr,
      .xlarge-12 > .convert-teaser hr {
        margin: .95rem 0; } }
  @media screen and (min-width: 75em) {
    .xxlarge-3 > .convert-teaser .convert-teaser-image,
    .xxlarge-2 > .convert-teaser .convert-teaser-image {
      position: relative;
      overflow: hidden; }
    .xxlarge-3 > .convert-teaser .convert-teaser-content,
    .xxlarge-2 > .convert-teaser .convert-teaser-content {
      padding: 3rem;
      position: relative; }
    .xxlarge-3 > .convert-teaser .convert-wysiwyg,
    .xxlarge-2 > .convert-teaser .convert-wysiwyg {
      margin-bottom: 1rem; }
    .xxlarge-3 > .convert-teaser hr,
    .xxlarge-2 > .convert-teaser hr {
      margin: .75rem 0; }
    .xxlarge-3 > .convert-teaser .button,
    .xxlarge-2 > .convert-teaser .button {
      min-width: inherit;
      width: 100%; }
    .xxlarge-4 > .convert-teaser .convert-teaser-image {
      position: relative;
      overflow: hidden; }
    .xxlarge-4 > .convert-teaser .convert-teaser-content {
      padding: 3rem;
      position: relative; }
    .xxlarge-4 > .convert-teaser .button {
      min-width: inherit;
      width: 100%; }
    .xxlarge-6 > .convert-teaser {
      text-align: left; }
      .xxlarge-6 > .convert-teaser .convert-teaser-content {
        padding: 1.8rem 1.4rem 1.8rem 2.2rem; }
      .xxlarge-6 > .convert-teaser .button {
        position: absolute;
        top: 1.8rem;
        right: 1.4rem;
        max-width: 15.625rem; }
      .xxlarge-6 > .convert-teaser .convert-title,
      .xxlarge-6 > .convert-teaser .convert-subtitle {
        margin-right: 17.5rem; }
      .xxlarge-6 > .convert-teaser hr {
        margin: .95rem 0; }
    .xxlarge-8 > .convert-teaser,
    .xxlarge-9 > .convert-teaser,
    .xxlarge-12 > .convert-teaser {
      text-align: left;
      overflow: hidden;
      display: flex;
      flex-direction: row; }
      .xxlarge-8 > .convert-teaser .convert-teaser-image,
      .xxlarge-9 > .convert-teaser .convert-teaser-image,
      .xxlarge-12 > .convert-teaser .convert-teaser-image {
        float: left;
        width: 32%;
        overflow: hidden;
        position: relative; }
        .xxlarge-8 > .convert-teaser .convert-teaser-image img,
        .xxlarge-9 > .convert-teaser .convert-teaser-image img,
        .xxlarge-12 > .convert-teaser .convert-teaser-image img {
          max-width: inherit;
          min-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%); }
      .xxlarge-8 > .convert-teaser .convert-teaser-content,
      .xxlarge-9 > .convert-teaser .convert-teaser-content,
      .xxlarge-12 > .convert-teaser .convert-teaser-content {
        width: 68%;
        float: left;
        padding: 3rem; }
      .xxlarge-8 > .convert-teaser .convert-title,
      .xxlarge-8 > .convert-teaser .convert-subtitle,
      .xxlarge-9 > .convert-teaser .convert-title,
      .xxlarge-9 > .convert-teaser .convert-subtitle,
      .xxlarge-12 > .convert-teaser .convert-title,
      .xxlarge-12 > .convert-teaser .convert-subtitle {
        margin-right: 14.375rem; }
      .xxlarge-8 > .convert-teaser .button,
      .xxlarge-9 > .convert-teaser .button,
      .xxlarge-12 > .convert-teaser .button {
        position: absolute;
        top: 3rem;
        right: 3.25rem;
        min-width: inherit;
        width: 12.5rem; }
      .xxlarge-8 > .convert-teaser hr,
      .xxlarge-9 > .convert-teaser hr,
      .xxlarge-12 > .convert-teaser hr {
        margin: .95rem 0; } }

.convert-responsive-image {
  margin-bottom: 1rem; }
  .convert-responsive-image + .convert-wysiwyg {
    margin-bottom: 2rem; }
    .convert-responsive-image + .convert-wysiwyg .convert-title {
      font-family: "silka-regular-webfont", sans-serif;
      font-weight: 300;
      font-size: 1.375rem;
      line-height: 1;
      margin-bottom: .8rem; }
  @media print, screen and (min-width: 40em) {
    .convert-responsive-image {
      margin-bottom: 1.4rem; }
      .convert-responsive-image + .convert-wysiwyg {
        margin-bottom: 4rem; }
        .convert-responsive-image + .convert-wysiwyg .convert-title {
          font-size: 1.75rem;
          margin-bottom: 1.25rem; } }

.googlemap {
  height: 17.8125rem;
  overflow: hidden; }

.convert-wysiwyg .intro,
.convert-wysiwyg .tagline {
  font-family: "silka-regular-webfont", sans-serif;
  letter-spacing: normal;
  letter-spacing: normal;
  font-weight: 400;
  font-size: 1.0625rem;
  line-height: 1.35; }
  @media print, screen and (min-width: 48em) {
    .convert-wysiwyg .intro,
    .convert-wysiwyg .tagline {
      font-size: 1.1875rem;
      line-height: 1.47; } }
  @media screen and (min-width: 75em) {
    .convert-wysiwyg .intro,
    .convert-wysiwyg .tagline {
      font-size: 1.25rem; } }

.convert-wysiwyg p {
  font-size: 13px;
  line-height: 1.6;
  text-align: left; }
  .convert-wysiwyg p:last-child {
    margin-bottom: 0; }
  .convert-wysiwyg p.text-small {
    font-size: 11px; }
  .convert-wysiwyg p.text-normal {
    font-size: 13px; }
  .convert-wysiwyg p.text-large {
    font-size: 15px; }
  .convert-wysiwyg p.text-xlarge {
    font-size: 17px; }
  .convert-wysiwyg p.text-xxlarge {
    font-size: 19px; }
    @media screen and (max-width: 47.9375em) {
      .convert-wysiwyg p.text-xxlarge {
        font-size: 17px; } }

.convert-wysiwyg.video-full {
  height: 500px;
  overflow: hidden;
  position: relative; }
  .convert-wysiwyg.video-full iframe {
    width: 100%;
    height: 56.25vw !important;
    position: absolute;
    top: 50%;
    display: block;
    transform: translateY(-50%); }

.convert-article.convert-block:not(:empty) {
  margin-bottom: 2rem; }

.convert-article .convert-article-image {
  margin-bottom: 1rem; }

.convert-article .convert-article-content > a {
  color: #181818;
  text-decoration: none; }
  .convert-article .convert-article-content > a:hover, .convert-article .convert-article-content > a:focus {
    color: #1A2746; }

.convert-articles .convert-article:last-child:last-child {
  float: left; }

@media screen and (min-width: 64em) {
  .convert-articles .convert-article.xlarge-4:nth-child(3n+1) {
    clear: both; } }

@media screen and (min-width: 75em) {
  h1.convert-title {
    font-size: 56px; } }

h1.convert-title.as-h1 {
  font-size: 1.75rem; }

h1.convert-title.as-h2 {
  font-size: 1.75rem; }

h1.convert-title.as-h3 {
  font-size: 1.625rem; }

h1.convert-title.as-h4 {
  font-size: 1.5rem; }

h1.convert-title.as-h5 {
  font-size: 0.9375rem; }

h1.convert-title.as-h6 {
  font-size: 0.8125rem; }

@media print, screen and (min-width: 40em) {
  h1.convert-title.as-h1 {
    font-size: 2.625rem; }
  h1.convert-title.as-h2 {
    font-size: 2.125rem; }
  h1.convert-title.as-h3 {
    font-size: 1.6875rem; }
  h1.convert-title.as-h4 {
    font-size: 1.5625rem; }
  h1.convert-title.as-h5 {
    font-size: 1.0625rem; }
  h1.convert-title.as-h6 {
    font-size: 0.9375rem; } }

@media screen and (min-width: 64em) {
  h1.convert-title.as-h1 {
    font-size: 2.875rem; }
  h1.convert-title.as-h2 {
    font-size: 2.5rem; }
  h1.convert-title.as-h3 {
    font-size: 1.75rem; }
  h1.convert-title.as-h4 {
    font-size: 1.625rem; }
  h1.convert-title.as-h5 {
    font-size: 1.1875rem; }
  h1.convert-title.as-h6 {
    font-size: 1.0625rem; } }

body:not(.cms-page-view) .navbar-wrapper .page-header {
  margin-bottom: 1.6rem; }
  @media print, screen and (min-width: 48em) {
    body:not(.cms-page-view) .navbar-wrapper .page-header {
      margin-bottom: 1.5rem; } }
  @media screen and (min-width: 75em) {
    body:not(.cms-page-view) .navbar-wrapper .page-header {
      margin-bottom: 3rem; } }

@media screen and (max-width: 63.9375em) {
  .tablet-full-width .convert-column {
    width: 100%; }
  .tablet-full-width .convert-responsive-image {
    text-align: center;
    padding-bottom: 60px; } }

nav.convert-document-menu:not(.simple-doc-menu) ul {
  margin: 0;
  list-style: none;
  margin-top: 1rem;
  margin-bottom: 1rem; }
  nav.convert-document-menu:not(.simple-doc-menu) ul li {
    padding: 0; }
    nav.convert-document-menu:not(.simple-doc-menu) ul li:after {
      display: none; }
  @media screen and (min-width: 64em) {
    nav.convert-document-menu:not(.simple-doc-menu) ul {
      text-align: right;
      margin-top: 0; } }

nav.convert-document-menu:not(.simple-doc-menu) li + li {
  border-top: 1px solid rgba(24, 24, 24, 0.2); }

nav.convert-document-menu:not(.simple-doc-menu) li:after {
  display: none; }

nav.convert-document-menu:not(.simple-doc-menu) a {
  display: block;
  font-size: 1rem;
  font-family: "silka-regular-webfont", sans-serif;
  color: #181818;
  padding: .45rem .5rem .4rem;
  text-decoration: none; }
  nav.convert-document-menu:not(.simple-doc-menu) a:hover, nav.convert-document-menu:not(.simple-doc-menu) a:focus, nav.convert-document-menu:not(.simple-doc-menu) a:active {
    background-color: #F1EFED; }

.convert-snippet-inline-buttons .convert-block {
  margin-bottom: 0; }

.convert-snippet-inline-buttons > .column > .convert-block, .convert-snippet-inline-buttons > .columns > .convert-block {
  margin-bottom: 1rem; }

.convert-snippet-inline-buttons .convert-snippet .convert-snippet {
  display: inline-block;
  vertical-align: top; }

.convert-image-metadata {
  position: relative; }
  .convert-image-metadata img {
    width: 100%;
    height: auto; }
  .convert-image-metadata .convert-image-marker,
  .convert-image-metadata .convert-image-hotspot {
    position: absolute; }
    .convert-image-metadata .convert-image-marker a,
    .convert-image-metadata .convert-image-hotspot a {
      display: block;
      width: 100%;
      height: 100%; }
  .convert-image-metadata .convert-image-marker {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    background: url("../images/icon-marker.svg") no-repeat;
    background-size: 100% 100%;
    border-radius: 100%;
    box-shadow: 2px 2px 7px rgba(24, 24, 24, 0.1); }

.convert-image-hotspot {
  position: absolute; }
  .convert-image-hotspot a:hover {
    background: rgba(255, 255, 255, 0.15); }

.convert-image-marker a,
.convert-image-hotspot a {
  width: 100%;
  height: 100%;
  border-bottom: none;
  cursor: pointer; }

.convert-block .block-products-list,
.convert-block .block-new-products {
  margin-top: 0;
  margin-bottom: 0; }

.convert-button.has-subtext > * {
  white-space: nowrap; }

.convert-button.has-subtext h4,
.convert-button.has-subtext p {
  margin: 0; }

.convert-accordion {
  margin: 0;
  list-style: none; }
  .convert-accordion li {
    padding: 0; }
    .convert-accordion li:after {
      display: none; }
  .convert-accordion .accordion-title {
    font-weight: bold;
    text-decoration: none; }
  .convert-accordion .is-active .accordion-title {
    background-color: white; }
  .convert-accordion .is-active .accordion-content {
    border-top-color: white; }
  .convert-accordion .convert-wysiwyg p {
    line-height: 1.7; }
  .convert-accordion .accordion-item:not(.is-active) .accordion-content {
    border-top-color: transparent; }

.convert-banner {
  position: relative; }
  @media screen and (max-width: 47.9375em) {
    .convert-banner {
      margin-bottom: .5rem; } }
  .convert-banner:not[class^="spacing-"], .convert-banner:not[class*=" spacing-"] {
    margin-bottom: 0 !important; }
  .convert-banner .convert-banner-image {
    position: relative; }
    .convert-banner .convert-banner-image img {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 0; }
    .convert-banner .convert-banner-image .convert-banner-overlay {
      background-color: rgba(24, 24, 24, 0.6);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
  .convert-banner .convert-banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    width: 80%; }
    .convert-banner .convert-banner-content .convert-wysiwyg {
      margin-left: auto;
      margin-right: auto; }
      @media print, screen and (min-width: 48em) {
        .convert-banner .convert-banner-content .convert-wysiwyg {
          max-width: 53ch; } }
    .convert-banner .convert-banner-content.banner-text-left {
      left: 34px;
      right: 34px;
      transform: translateY(-50%);
      width: auto;
      text-align: left; }
      @media screen and (min-width: 64em) {
        .convert-banner .convert-banner-content.banner-text-left {
          left: 40px;
          right: 40px; } }
      .convert-banner .convert-banner-content.banner-text-left .convert-wysiwyg {
        margin-left: 0; }
        .convert-banner .convert-banner-content.banner-text-left .convert-wysiwyg > * {
          text-align: left; }
    .convert-banner .convert-banner-content.banner-text-right {
      left: 34px;
      right: 34px;
      transform: translateY(-50%);
      width: auto;
      text-align: right; }
      @media screen and (min-width: 64em) {
        .convert-banner .convert-banner-content.banner-text-right {
          left: 40px;
          right: 40px; } }
      .convert-banner .convert-banner-content.banner-text-right .convert-wysiwyg {
        margin-right: 0; }
        .convert-banner .convert-banner-content.banner-text-right .convert-wysiwyg > * {
          text-align: right; }
    .convert-banner .convert-banner-content > *,
    .convert-banner .convert-banner-content .convert-wysiwyg * {
      text-align: center; }
    .convert-banner .convert-banner-content .convert-wysiwyg p {
      line-height: 1.3; }
    .convert-banner .convert-banner-content *:last-child {
      margin-bottom: 0 !important; }
    .convert-banner .convert-banner-content .button {
      font-size: 15px !important;
      padding: 0.62em 2.3em 0.87em !important;
      margin-top: 1rem; }
  .convert-banner.cs-black .convert-banner-image .convert-banner-overlay {
    background-color: rgba(255, 255, 255, 0.6); }
  .convert-banner.cs-black .convert-wysiwyg * {
    color: rgba(24, 24, 24, 0.8); }
  .convert-banner.cs-black.has-content-overlay .convert-banner-content {
    background-color: rgba(255, 255, 255, 0.6); }
  .convert-banner.cs-white .convert-wysiwyg * {
    color: rgba(255, 255, 255, 0.8); }
  .convert-banner.cs-white .button {
    border-color: rgba(255, 255, 255, 0.5);
    background-color: transparent;
    color: white; }
    .convert-banner.cs-white .button:hover {
      background-color: rgba(255, 255, 255, 0.15); }
  .convert-banner.cs-white.has-content-overlay .convert-banner-content {
    background-color: rgba(24, 24, 24, 0.6); }
  .convert-banner.hover-jump:hover .convert-wysiwyg {
    transform: translate(0, -0.75rem) !important; }
  .convert-banner.hover-brightness img {
    transition: all 450ms cubic-bezier(0, 0.275, 0.125, 1); }
  .convert-banner.hover-brightness:hover img {
    filter: brightness(75%); }
  .convert-banner.has-content-overlay .convert-banner-content {
    padding: 34px; }
  .convert-banner.content--hover .convert-banner-content {
    opacity: 0;
    transition: all 450ms cubic-bezier(0, 0.275, 0.125, 1); }
  .convert-banner.content--hover:hover .convert-banner-content {
    opacity: 1; }

.convert-button.aligned {
  display: table; }
  .convert-button.aligned--center {
    margin-left: auto;
    margin-right: auto; }
  .convert-button.aligned--right {
    margin-left: auto;
    margin-right: 0; }

.convert-image.image--full-width img {
  display: block;
  width: 100%; }

.convert-image.aligned {
  display: table; }
  .convert-image.aligned--center {
    margin-left: auto;
    margin-right: auto; }
  .convert-image.aligned--right {
    margin-left: auto;
    margin-right: 0; }

.convert-image-metadata {
  position: relative; }
  .convert-image-metadata img {
    width: 100%; }

.convert-image-marker {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("../images/icon-marker.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 100%;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1); }

.convert-image-hotspot {
  position: absolute; }
  .convert-image-hotspot a:hover {
    background: rgba(255, 255, 255, 0.15); }

.convert-image-marker a,
.convert-image-hotspot a {
  width: 100%;
  height: 100%;
  border-bottom: none;
  cursor: pointer; }

.convert-grid-row.has-bg,
.convert-grid-row-flex.has-bg {
  overflow: hidden;
  position: relative; }
  .convert-grid-row.has-bg .convert-column,
  .convert-grid-row.has-bg .convert-column-flex,
  .convert-grid-row-flex.has-bg .convert-column,
  .convert-grid-row-flex.has-bg .convert-column-flex {
    position: relative; }

.convert-video {
  position: relative; }
  .convert-video .convert-video-poster {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center; }
  .convert-video .vjs-playing ~ .convert-video-poster,
  .convert-video .vjs-playing ~ .convert-video-content {
    visibility: hidden; }
  .convert-video .convert-video-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: white;
    text-align: center; }
    @media screen and (max-width: 47.9375em) {
      .convert-video .convert-video-content {
        width: 80%; } }
  .convert-video .convert-video-button {
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 auto 10px;
    cursor: pointer;
    background-image: url("../images/icon-video-play.svg");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 40px auto;
    border-radius: 100%;
    box-shadow: 3px 2px 25px rgba(24, 24, 24, 0.35); }
    @media screen and (max-width: 47.9375em) {
      .convert-video .convert-video-button {
        width: 30px;
        height: 30px;
        margin-bottom: 6px; } }
    @media screen and (max-width: 47.9375em) {
      .convert-video .convert-video-button {
        background-size: 30px auto; } }
    .convert-video .convert-video-button:hover {
      opacity: 0.75; }
  .convert-video .convert-video-button-label {
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    text-align: center;
    text-transform: none; }
    .convert-video .convert-video-button-label:hover {
      color: white;
      cursor: pointer; }
  .convert-video .convert-wysiwyg {
    margin-top: 10px; }

.convert-videobg {
  position: absolute; }
  .convert-videobg .convert-video-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0; }
  .convert-videobg.bg-overlay-light .convert-video-overlay {
    background-color: rgba(255, 255, 255, 0.5); }
  .convert-videobg.bg-overlay-dark .convert-video-overlay {
    background-color: rgba(0, 0, 0, 0.5); }

.convert-video-asset {
  left: 0;
  right: 0; }
  @supports (object-fit: cover) {
    .convert-video-asset .convert-video-ctr {
      padding-bottom: 0 !important; } }
  .convert-video-asset.bg-y-top {
    top: 0; }
  .convert-video-asset.bg-y-bottom {
    bottom: 0; }
  .convert-video-asset.bg-y-center {
    top: 50%;
    transform: translateY(-50%); }
  .convert-video-asset video {
    display: block;
    position: absolute; }
    @supports (object-fit: cover) {
      .convert-video-asset video {
        position: static;
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.convert-video-vimeo,
.convert-video-youtube {
  left: 0;
  right: 0; }
  .convert-video-vimeo.bg-y-top,
  .convert-video-youtube.bg-y-top {
    top: 0; }
  .convert-video-vimeo.bg-y-bottom,
  .convert-video-youtube.bg-y-bottom {
    bottom: 0; }
  .convert-video-vimeo.bg-y-center,
  .convert-video-youtube.bg-y-center {
    top: 50%;
    transform: translateY(-50%); }
  .convert-video-vimeo iframe,
  .convert-video-youtube iframe {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute; }

.has-img-bg .convert-img-bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat; }
  .has-img-bg .convert-img-bg.bg-x-left {
    background-position-x: 0; }
  .has-img-bg .convert-img-bg.bg-x-right {
    background-position-x: 100%; }
  .has-img-bg .convert-img-bg.bg-y-top {
    background-position-y: 0; }
  .has-img-bg .convert-img-bg.bg-y-bottom {
    background-position-y: 100%; }
  .has-img-bg .convert-img-bg.bg-size-cover {
    background-size: cover; }
  .has-img-bg .convert-img-bg .convert-image-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0; }
  .has-img-bg .convert-img-bg.bg-overlay-light .convert-image-overlay {
    background-color: rgba(255, 255, 255, 0.5); }
  .has-img-bg .convert-img-bg.bg-overlay-dark .convert-image-overlay {
    background-color: rgba(0, 0, 0, 0.5); }

.filter-options-content.has-images .swatch-option, .filter-options-content.has-images-labels .am-swatch-options .item .swatch-option, .filter-options-content.has-images-labels .swatch-attribute-options .item .swatch-option {
  display: block;
  width: 36px;
  height: 36px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
  border-radius: 100%; }
  .touchevents .filter-options-content.has-images .swatch-option:hover:before, .touchevents .filter-options-content.has-images-labels .am-swatch-options .item .swatch-option:hover:before, .filter-options-content.has-images-labels .am-swatch-options .touchevents .item .swatch-option:hover:before, .touchevents .filter-options-content.has-images-labels .swatch-attribute-options .item .swatch-option:hover:before, .filter-options-content.has-images-labels .swatch-attribute-options .touchevents .item .swatch-option:hover:before, .touchevents .filter-options-content.has-images .swatch-option:active:before, .touchevents .filter-options-content.has-images-labels .am-swatch-options .item .swatch-option:active:before, .filter-options-content.has-images-labels .am-swatch-options .touchevents .item .swatch-option:active:before, .touchevents .filter-options-content.has-images-labels .swatch-attribute-options .item .swatch-option:active:before, .filter-options-content.has-images-labels .swatch-attribute-options .touchevents .item .swatch-option:active:before {
    display: block; }
  .filter-options-content.has-images .swatch-option:hover:after, .filter-options-content.has-images-labels .am-swatch-options .item .swatch-option:hover:after, .filter-options-content.has-images-labels .swatch-attribute-options .item .swatch-option:hover:after, .filter-options-content.has-images .swatch-option:active:after, .filter-options-content.has-images-labels .am-swatch-options .item .swatch-option:active:after, .filter-options-content.has-images-labels .swatch-attribute-options .item .swatch-option:active:after, .filter-options-content.has-images .swatch-option.selected:after, .filter-options-content.has-images-labels .am-swatch-options .item .swatch-option.selected:after, .filter-options-content.has-images-labels .swatch-attribute-options .item .swatch-option.selected:after, .filter-options-content.has-images .swatch-option.checked:after, .filter-options-content.has-images-labels .am-swatch-options .item .swatch-option.checked:after, .filter-options-content.has-images-labels .swatch-attribute-options .item .swatch-option.checked:after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100%;
    height: 100%;
    background: url("../images/icon-check-filter.none") 50% no-repeat;
    background-size: 16px auto; }
  .product-items .filter-options-content.has-images .swatch-option, .product-items .filter-options-content.has-images-labels .am-swatch-options .item .swatch-option, .filter-options-content.has-images-labels .am-swatch-options .product-items .item .swatch-option, .product-items .filter-options-content.has-images-labels .swatch-attribute-options .item .swatch-option, .filter-options-content.has-images-labels .swatch-attribute-options .product-items .item .swatch-option {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    vertical-align: top;
    margin: 0.5rem;
    margin-left: 0; }
    .product-items .filter-options-content.has-images .swatch-option.selected, .product-items .filter-options-content.has-images-labels .am-swatch-options .item .swatch-option.selected, .filter-options-content.has-images-labels .am-swatch-options .product-items .item .swatch-option.selected, .product-items .filter-options-content.has-images-labels .swatch-attribute-options .item .swatch-option.selected, .filter-options-content.has-images-labels .swatch-attribute-options .product-items .item .swatch-option.selected {
      height: 10px;
      box-shadow: 0 0 0 1px #181818; }
      .product-items .filter-options-content.has-images .swatch-option.selected:after, .product-items .filter-options-content.has-images-labels .am-swatch-options .item .swatch-option.selected:after, .filter-options-content.has-images-labels .am-swatch-options .product-items .item .swatch-option.selected:after, .product-items .filter-options-content.has-images-labels .swatch-attribute-options .item .swatch-option.selected:after, .filter-options-content.has-images-labels .swatch-attribute-options .product-items .item .swatch-option.selected:after {
        display: none; }
  .filter-options-content.has-images .swatch-option.disabled, .filter-options-content.has-images-labels .am-swatch-options .item .swatch-option.disabled, .filter-options-content.has-images-labels .swatch-attribute-options .item .swatch-option.disabled {
    opacity: .25; }
  .filter-options-content.has-images .swatch-option[option-label="White"], .filter-options-content.has-images-labels .am-swatch-options .item .swatch-option[option-label="White"], .filter-options-content.has-images-labels .swatch-attribute-options .item .swatch-option[option-label="White"], .filter-options-content.has-images .swatch-option[option-label="Hvit"], .filter-options-content.has-images-labels .am-swatch-options .item .swatch-option[option-label="Hvit"], .filter-options-content.has-images-labels .swatch-attribute-options .item .swatch-option[option-label="Hvit"] {
    box-shadow: inset 0 0 0 1px rgba(24, 24, 24, 0.1); }

.filter-options-content ol,
.filter-options-content ul {
  margin: 0;
  list-style: none; }
  .filter-options-content ol li,
  .filter-options-content ul li {
    padding: 0; }
    .filter-options-content ol li:after,
    .filter-options-content ul li:after {
      display: none; }

.filter-options-content.has-labels ol,
.filter-options-content.has-labels ul {
  font-size: 1.125rem;
  border-top: 1px solid rgba(24, 24, 24, 0.15); }
  .filter-options-content.has-labels ol.am-filter-items-attr_price,
  .filter-options-content.has-labels ul.am-filter-items-attr_price {
    border-top: 0; }
  .filter-options-content.has-labels ol .item,
  .filter-options-content.has-labels ul .item {
    border-bottom: 1px solid rgba(24, 24, 24, 0.15); }
    .filter-options-content.has-labels ol .item:last-child,
    .filter-options-content.has-labels ul .item:last-child {
      border-bottom: 0; }
    .filter-options-content.has-labels ol .item a,
    .filter-options-content.has-labels ul .item a {
      display: block;
      text-transform: none;
      font-family: inherit;
      font-size: 0.97875rem;
      font-weight: inherit;
      color: rgba(24, 24, 24, 0.9);
      padding: 0.5rem 1.8rem 0.5rem 0;
      cursor: pointer; }
      @media screen and (min-width: 64em) {
        .filter-options-content.has-labels ol .item a,
        .filter-options-content.has-labels ul .item a {
          font-size: 1.125rem; } }
      .filter-options-content.has-labels ol .item a .label,
      .filter-options-content.has-labels ul .item a .label {
        cursor: inherit;
        text-decoration: none; }
      .filter-options-content.has-labels ol .item a:hover,
      .filter-options-content.has-labels ul .item a:hover {
        color: #181818;
        background-color: transparent;
        text-decoration: none; }
        .filter-options-content.has-labels ol .item a:hover .label,
        .filter-options-content.has-labels ul .item a:hover .label {
          text-decoration: underline; }
      .filter-options-content.has-labels ol .item a.am_shopby_link_selected, .filter-options-content.has-labels ol .item a.amshopby-link-selected,
      .filter-options-content.has-labels ul .item a.am_shopby_link_selected,
      .filter-options-content.has-labels ul .item a.amshopby-link-selected {
        color: #181818;
        background-color: transparent;
        text-decoration: none; }
        .filter-options-content.has-labels ol .item a.am_shopby_link_selected .label, .filter-options-content.has-labels ol .item a.amshopby-link-selected .label,
        .filter-options-content.has-labels ul .item a.am_shopby_link_selected .label,
        .filter-options-content.has-labels ul .item a.amshopby-link-selected .label {
          padding-left: 1rem;
          font-weight: 700;
          text-decoration: none;
          background: url("../images/icon-valid-black.svg") 0 50% no-repeat;
          background-size: 12px auto; }
    .filter-options-content.has-labels ol .item > .label,
    .filter-options-content.has-labels ul .item > .label {
      text-transform: none;
      font-family: inherit;
      font-size: 0.97875rem;
      font-weight: inherit;
      color: rgba(24, 24, 24, 0.9);
      padding: 0.5rem 1.8rem 0.5rem 0; }
      @media screen and (min-width: 64em) {
        .filter-options-content.has-labels ol .item > .label,
        .filter-options-content.has-labels ul .item > .label {
          font-size: 1.125rem; } }
    .filter-options-content.has-labels ol .item .count,
    .filter-options-content.has-labels ul .item .count {
      display: inline-block;
      vertical-align: middle;
      color: #8b8b8b;
      font-size: 85%; }
      .filter-options-content.has-labels ol .item .count:before,
      .filter-options-content.has-labels ul .item .count:before {
        content: '('; }
      .filter-options-content.has-labels ol .item .count:after,
      .filter-options-content.has-labels ul .item .count:after {
        content: ')'; }
      .filter-options-content.has-labels ol .item .count .filter-count-label,
      .filter-options-content.has-labels ul .item .count .filter-count-label {
        position: absolute !important;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0); }

.filter-options-content.has-images {
  padding-top: 0;
  padding-bottom: 1rem; }
  .filter-options-content.has-images .swatch-option-link-layered,
  .filter-options-content.has-images .ais-refinement-list--item {
    display: inline-block;
    vertical-align: top;
    border-radius: 100%;
    margin: 0 4px 3px 0;
    padding: 0;
    position: relative;
    font-size: 0; }
    .filter-options-content.has-images .swatch-option-link-layered.checked, .filter-options-content.has-images .swatch-option-link-layered.ais-refinement-list--item__active,
    .filter-options-content.has-images .ais-refinement-list--item.checked,
    .filter-options-content.has-images .ais-refinement-list--item.ais-refinement-list--item__active {
      box-shadow: 0 0 0 1px rgba(195, 195, 195, 0.07);
      background: white url("../images/icon-check-filter.none") 50% calc(100% - 3px) no-repeat;
      background-size: 16px auto; }

.filter-options-content.has-images-labels .am-swatch-options,
.filter-options-content.has-images-labels .swatch-attribute-options {
  font-size: 1.125rem;
  border-top: 1px solid rgba(24, 24, 24, 0.15); }
  .filter-options-content.has-images-labels .am-swatch-options .item,
  .filter-options-content.has-images-labels .swatch-attribute-options .item {
    display: block;
    position: relative;
    border-bottom: 1px solid rgba(24, 24, 24, 0.15); }
    .filter-options-content.has-images-labels .am-swatch-options .item:last-child,
    .filter-options-content.has-images-labels .swatch-attribute-options .item:last-child {
      border-bottom: 0; }
    .filter-options-content.has-images-labels .am-swatch-options .item .am-swatch-link,
    .filter-options-content.has-images-labels .swatch-attribute-options .item .am-swatch-link {
      display: inline-block; }
    .filter-options-content.has-images-labels .am-swatch-options .item .swatch-option,
    .filter-options-content.has-images-labels .swatch-attribute-options .item .swatch-option {
      width: 18px;
      height: 18px; }
    .filter-options-content.has-images-labels .am-swatch-options .item .am_shopby_swatch_label,
    .filter-options-content.has-images-labels .am-swatch-options .item .am-shopby-swatch-label,
    .filter-options-content.has-images-labels .swatch-attribute-options .item .am_shopby_swatch_label,
    .filter-options-content.has-images-labels .swatch-attribute-options .item .am-shopby-swatch-label {
      display: block;
      text-transform: none;
      font-family: inherit;
      font-size: 0.97875rem;
      font-weight: inherit;
      color: rgba(24, 24, 24, 0.9);
      padding: 0.5rem 1.8rem 0.5rem 0;
      cursor: pointer; }
      @media screen and (min-width: 64em) {
        .filter-options-content.has-images-labels .am-swatch-options .item .am_shopby_swatch_label,
        .filter-options-content.has-images-labels .am-swatch-options .item .am-shopby-swatch-label,
        .filter-options-content.has-images-labels .swatch-attribute-options .item .am_shopby_swatch_label,
        .filter-options-content.has-images-labels .swatch-attribute-options .item .am-shopby-swatch-label {
          font-size: 1.125rem; } }
      .filter-options-content.has-images-labels .am-swatch-options .item .am_shopby_swatch_label .label,
      .filter-options-content.has-images-labels .am-swatch-options .item .am-shopby-swatch-label .label,
      .filter-options-content.has-images-labels .swatch-attribute-options .item .am_shopby_swatch_label .label,
      .filter-options-content.has-images-labels .swatch-attribute-options .item .am-shopby-swatch-label .label {
        cursor: inherit;
        text-decoration: none;
        margin-left: .25rem; }
      .filter-options-content.has-images-labels .am-swatch-options .item .am_shopby_swatch_label:hover,
      .filter-options-content.has-images-labels .am-swatch-options .item .am-shopby-swatch-label:hover,
      .filter-options-content.has-images-labels .swatch-attribute-options .item .am_shopby_swatch_label:hover,
      .filter-options-content.has-images-labels .swatch-attribute-options .item .am-shopby-swatch-label:hover {
        color: #181818;
        background-color: transparent;
        text-decoration: none; }
        .filter-options-content.has-images-labels .am-swatch-options .item .am_shopby_swatch_label:hover .label,
        .filter-options-content.has-images-labels .am-swatch-options .item .am-shopby-swatch-label:hover .label,
        .filter-options-content.has-images-labels .swatch-attribute-options .item .am_shopby_swatch_label:hover .label,
        .filter-options-content.has-images-labels .swatch-attribute-options .item .am-shopby-swatch-label:hover .label {
          text-decoration: underline; }
    .filter-options-content.has-images-labels .am-swatch-options .item .count,
    .filter-options-content.has-images-labels .swatch-attribute-options .item .count {
      display: inline-block;
      vertical-align: middle;
      color: #8b8b8b;
      font-size: 85%; }
      .filter-options-content.has-images-labels .am-swatch-options .item .count:before,
      .filter-options-content.has-images-labels .swatch-attribute-options .item .count:before {
        content: '('; }
      .filter-options-content.has-images-labels .am-swatch-options .item .count:after,
      .filter-options-content.has-images-labels .swatch-attribute-options .item .count:after {
        content: ')'; }
    .filter-options-content.has-images-labels .am-swatch-options .item .filter-count-label,
    .filter-options-content.has-images-labels .swatch-attribute-options .item .filter-count-label {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }

.filter-options-content.has-text-swatch {
  padding-top: 0;
  padding-bottom: 1rem; }
  .filter-options-content.has-text-swatch .swatch-option-link-layered,
  .filter-options-content.has-text-swatch .ais-refinement-list--item {
    display: inline-block;
    vertical-align: top;
    min-width: 36px;
    margin: 0 5px 3px 0;
    cursor: pointer;
    font-size: 0; }
    .filter-options-content.has-text-swatch .swatch-option-link-layered[href="javascript:void();"],
    .filter-options-content.has-text-swatch .ais-refinement-list--item[href="javascript:void();"] {
      display: none; }
  .filter-options-content.has-text-swatch .swatch-option {
    width: auto;
    min-width: 36px;
    height: 36px;
    padding: 0 4px;
    line-height: 36px;
    position: relative;
    text-align: center;
    cursor: pointer;
    color: #181818;
    font-size: 0.9375rem;
    font-weight: 400;
    font-family: inherit;
    box-shadow: 0 0 10px 2px rgba(24, 24, 24, 0.07);
    background-color: white;
    cursor: pointer;
    letter-spacing: normal;
    word-spacing: normal; }
    .filter-options-content.has-text-swatch .swatch-option:before, .filter-options-content.has-text-swatch .swatch-option:after {
      display: none; }
    .filter-options-content.has-text-swatch .swatch-option.disabled:before, .filter-options-content.has-text-swatch .swatch-option.disabled:after {
      display: none; }
    .filter-options-content.has-text-swatch .swatch-option:hover, .filter-options-content.has-text-swatch .swatch-option:active, .filter-options-content.has-text-swatch .swatch-option.selected, .filter-options-content.has-text-swatch .swatch-option.checked {
      color: white;
      background-color: #1A2746; }

.filter-options-content.has-ui-slider .am_shopby_slider_container {
  position: relative; }

.filter-options-content.has-ui-slider .ui-slider {
  position: relative;
  height: 0;
  border-top: 1px solid rgba(24, 24, 24, 0.15);
  border-radius: 0;
  margin: 0.5rem 1.0625rem 0.625rem 0;
  text-align: left;
  /* support: IE8 - See #6727 */ }
  .filter-options-content.has-ui-slider .ui-slider:after {
    display: block;
    content: '';
    position: absolute;
    width: 14px;
    left: 100%;
    top: 50%;
    height: 1px;
    margin-top: -1px;
    background: rgba(24, 24, 24, 0.15); }
  .filter-options-content.has-ui-slider .ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: #1A2746;
    border-radius: 100%;
    border: none;
    top: -8.5px;
    margin-left: -1px;
    margin-top: 0;
    cursor: pointer;
    -ms-touch-action: none;
    touch-action: none; }
    .filter-options-content.has-ui-slider .ui-slider .ui-slider-handle:focus {
      outline: none; }
    .filter-options-content.has-ui-slider .ui-slider .ui-slider-handle .amshopby-slider-tooltip {
      display: none; }
  .filter-options-content.has-ui-slider .ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: .7em;
    display: block;
    border: 0;
    background: #a0a0a0;
    background-position: 0 0;
    height: 100%; }
  .filter-options-content.has-ui-slider .ui-slider.ui-state-disabled .ui-slider-handle,
  .filter-options-content.has-ui-slider .ui-slider.ui-state-disabled .ui-slider-range {
    filter: inherit; }

.filter-options-content.has-ui-slider .min-value-container,
.filter-options-content.has-ui-slider .max-value-container {
  font-size: 0.9375rem;
  font-weight: inherit;
  color: inherit;
  position: absolute;
  padding-top: 0.1875rem; }

.filter-options-content.has-ui-slider .min-value-container {
  left: 0; }

.filter-options-content.has-ui-slider .max-value-container {
  right: 0; }

.filter-options-content.has-dropdown.has-multiselect {
  border-top: 0; }
  .filter-options-content.has-dropdown.has-multiselect .chosen-container .chosen-choices {
    height: 3.25rem;
    background-repeat: no-repeat;
    background-size: 12px auto;
    background-position: calc(100% - 17px) 50%;
    padding: 0.625rem 1.6875rem;
    padding-top: 6px;
    padding-bottom: 6px;
    border: 1px solid #9DA3AF;
    padding-right: 3rem;
    background: #fff url("../images/icon-select.svg") calc(100% - 22px) 50% no-repeat;
    background-size: 16px auto; }
    .filter-options-content.has-dropdown.has-multiselect .chosen-container .chosen-choices .search-field input {
      margin: 0;
      color: #494949;
      font-weight: 400;
      font-family: "silka-regular-webfont", sans-serif;
      font-size: 0.9375rem;
      text-transform: none; }
    .filter-options-content.has-dropdown.has-multiselect .chosen-container .chosen-choices li.search-choice {
      box-shadow: none;
      border-color: rgba(26, 39, 70, 0.1);
      border-radius: 4px;
      background: rgba(26, 39, 70, 0.1); }
  .filter-options-content.has-dropdown.has-multiselect .chosen-container .chosen-drop {
    border: 1px solid #9DA3AF;
    border-top: 0; }
  .filter-options-content.has-dropdown.has-multiselect .chosen-container.chosen-container-active .chosen-choices {
    border: 1px solid #9DA3AF;
    box-shadow: none; }
  .filter-options-content.has-dropdown.has-multiselect .chosen-container .chosen-results .active-result.highlighted {
    background: #1A2746; }

.filter-options-content .am-show-more {
  display: none; }

.filter-options-content .am-show-more::before {
  margin-top: 10px; }

.filter-options-content .am-show-more::after {
  margin-top: 10px; }

.swatch-option-tooltip {
  display: none;
  width: 157px;
  min-height: 20px;
  min-width: 20px;
  position: absolute;
  padding: 6px;
  text-align: center;
  line-height: 1.3;
  background: white;
  box-shadow: 0 0 0 1px rgba(24, 24, 24, 0.02), 2px 0 10px rgba(24, 24, 24, 0.1);
  z-index: 999; }
  .touchevents .swatch-option-tooltip,
  body.catalog-product-view .swatch-option-tooltip {
    display: none !important; }
  .swatch-option-tooltip .title {
    height: 20px;
    overflow: hidden; }
  .swatch-option-tooltip .image {
    height: 132px;
    display: block;
    margin-bottom: 6px; }
  .swatch-option-tooltip .corner {
    width: 20px;
    height: 10px;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    position: absolute;
    top: 100%;
    border-color: white transparent transparent transparent; }

.swatch-option-tooltip {
  display: none !important; }

.swatch-attribute-label {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  margin-bottom: .25rem; }
  .swatch-attribute-label:after {
    content: ':'; }
  .product-info-main .swatch-attribute-label {
    font-size: 1rem;
    font-weight: 300;
    font-family: "silka-regular-webfont", sans-serif;
    font-style: normal;
    text-transform: none; }

.swatch-attribute-selected-option {
  margin-left: .5rem;
  display: inline-block;
  vertical-align: middle; }

.product-info-main .swatch-attribute-options,
.product-info-main .ais-refinement-list--list {
  width: 100%;
  display: block; }
  .product-info-main .swatch-attribute-options .ais-refinement-list--item,
  .product-info-main .ais-refinement-list--list .ais-refinement-list--item {
    display: inline-block;
    vertical-align: top;
    min-width: 3rem;
    width: auto;
    border: 0; }
  .product-info-main .swatch-attribute-options .swatch-option,
  .product-info-main .ais-refinement-list--list .swatch-option {
    display: inline-block;
    vertical-align: middle;
    min-width: 3rem;
    width: auto;
    height: 2.25rem;
    padding: 0 2px;
    text-align: center;
    color: #181818;
    line-height: 2.25rem;
    border-radius: 0;
    font-size: 0.9375rem;
    font-weight: 700;
    font-family: inherit;
    margin: 0;
    white-space: nowrap;
    box-shadow: none;
    background-color: white;
    cursor: pointer;
    border-left: 1px solid rgba(24, 24, 24, 0.15); }
    .product-info-main .swatch-attribute-options .swatch-option:first-child,
    .product-info-main .ais-refinement-list--list .swatch-option:first-child {
      border-left: 0; }
    .product-info-main .swatch-attribute-options .swatch-option:hover, .product-info-main .swatch-attribute-options .swatch-option:active, .product-info-main .swatch-attribute-options .swatch-option.selected,
    .product-info-main .ais-refinement-list--list .swatch-option:hover,
    .product-info-main .ais-refinement-list--list .swatch-option:active,
    .product-info-main .ais-refinement-list--list .swatch-option.selected {
      color: white;
      background-color: #181818;
      border-left-color: #181818;
      box-shadow: none; }
      .product-info-main .swatch-attribute-options .swatch-option:hover:after, .product-info-main .swatch-attribute-options .swatch-option:active:after, .product-info-main .swatch-attribute-options .swatch-option.selected:after,
      .product-info-main .ais-refinement-list--list .swatch-option:hover:after,
      .product-info-main .ais-refinement-list--list .swatch-option:active:after,
      .product-info-main .ais-refinement-list--list .swatch-option.selected:after {
        display: none; }
      .product-info-main .swatch-attribute-options .swatch-option:hover + .swatch-option, .product-info-main .swatch-attribute-options .swatch-option:active + .swatch-option, .product-info-main .swatch-attribute-options .swatch-option.selected + .swatch-option,
      .product-info-main .ais-refinement-list--list .swatch-option:hover + .swatch-option,
      .product-info-main .ais-refinement-list--list .swatch-option:active + .swatch-option,
      .product-info-main .ais-refinement-list--list .swatch-option.selected + .swatch-option {
        border-left-color: #181818; }
        .product-info-main .swatch-attribute-options .swatch-option:hover + .swatch-option:hover, .product-info-main .swatch-attribute-options .swatch-option:active + .swatch-option:hover, .product-info-main .swatch-attribute-options .swatch-option.selected + .swatch-option:hover,
        .product-info-main .ais-refinement-list--list .swatch-option:hover + .swatch-option:hover,
        .product-info-main .ais-refinement-list--list .swatch-option:active + .swatch-option:hover,
        .product-info-main .ais-refinement-list--list .swatch-option.selected + .swatch-option:hover {
          border-left-color: rgba(24, 24, 24, 0.15); }
    .product-info-main .swatch-attribute-options .swatch-option.disabled,
    .product-info-main .ais-refinement-list--list .swatch-option.disabled {
      opacity: 1;
      position: relative;
      color: #bbbbbb; }
      .product-info-main .swatch-attribute-options .swatch-option.disabled:hover, .product-info-main .swatch-attribute-options .swatch-option.disabled:active,
      .product-info-main .ais-refinement-list--list .swatch-option.disabled:hover,
      .product-info-main .ais-refinement-list--list .swatch-option.disabled:active {
        cursor: help;
        color: #bbbbbb;
        background: transparent;
        border-left-color: rgba(24, 24, 24, 0.15); }
        .product-info-main .swatch-attribute-options .swatch-option.disabled:hover + .swatch-option, .product-info-main .swatch-attribute-options .swatch-option.disabled:active + .swatch-option,
        .product-info-main .ais-refinement-list--list .swatch-option.disabled:hover + .swatch-option,
        .product-info-main .ais-refinement-list--list .swatch-option.disabled:active + .swatch-option {
          border-left-color: rgba(24, 24, 24, 0.15); }
          .product-info-main .swatch-attribute-options .swatch-option.disabled:hover + .swatch-option:hover, .product-info-main .swatch-attribute-options .swatch-option.disabled:active + .swatch-option:hover,
          .product-info-main .ais-refinement-list--list .swatch-option.disabled:hover + .swatch-option:hover,
          .product-info-main .ais-refinement-list--list .swatch-option.disabled:active + .swatch-option:hover {
            border-left-color: rgba(24, 24, 24, 0.15); }
        .product-info-main .swatch-attribute-options .swatch-option.disabled:hover:before, .product-info-main .swatch-attribute-options .swatch-option.disabled:hover:after, .product-info-main .swatch-attribute-options .swatch-option.disabled:active:before, .product-info-main .swatch-attribute-options .swatch-option.disabled:active:after,
        .product-info-main .ais-refinement-list--list .swatch-option.disabled:hover:before,
        .product-info-main .ais-refinement-list--list .swatch-option.disabled:hover:after,
        .product-info-main .ais-refinement-list--list .swatch-option.disabled:active:before,
        .product-info-main .ais-refinement-list--list .swatch-option.disabled:active:after {
          opacity: 1; }
      .product-info-main .swatch-attribute-options .swatch-option.disabled:before,
      .product-info-main .ais-refinement-list--list .swatch-option.disabled:before {
        opacity: 0;
        display: block;
        content: attr(option-label);
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 0.625rem;
        font-size: 0.6875rem;
        line-height: 0;
        margin: 0;
        color: white;
        white-space: nowrap;
        background: #181818;
        transition: opacity .3s ease;
        z-index: 1; }
      .product-info-main .swatch-attribute-options .swatch-option.disabled:after,
      .product-info-main .ais-refinement-list--list .swatch-option.disabled:after {
        opacity: 0;
        content: '';
        width: 0;
        height: 0;
        display: block;
        border-style: solid;
        border-width: 0 6px 6px 6px;
        border-color: transparent transparent #181818 transparent;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: opacity .3s ease; }

.filter-options[role="tablist"] {
  border-top: 0; }
  .filter-options[role="tablist"] ol,
  .filter-options[role="tablist"] ul {
    border-top: 0; }
    .filter-options[role="tablist"] ol .item:last-child,
    .filter-options[role="tablist"] ul .item:last-child {
      border-bottom: 1px solid rgba(24, 24, 24, 0.15); }
    .filter-options[role="tablist"] ol .item a,
    .filter-options[role="tablist"] ul .item a {
      padding: 0.5rem 0.25rem 0.5rem 1.625rem; }
  .filter-options[role="tablist"] .filter-options-content:last-child {
    border-top: 1px solid rgba(24, 24, 24, 0.15); }
  .filter-options[role="tablist"] .filter-options-content.has-images-labels .swatch-attribute {
    padding-top: 0; }
  .filter-options[role="tablist"] .filter-options-content.has-images-labels .swatch-attribute-options {
    border-top: 0;
    padding-left: 0; }
    .filter-options[role="tablist"] .filter-options-content.has-images-labels .swatch-attribute-options .item {
      padding: 0; }
      .filter-options[role="tablist"] .filter-options-content.has-images-labels .swatch-attribute-options .item:last-child {
        border-bottom: 1px solid rgba(24, 24, 24, 0.15); }
      .filter-options[role="tablist"] .filter-options-content.has-images-labels .swatch-attribute-options .item .am_shopby_swatch_label {
        padding: 0.5rem 0.25rem 0.5rem 1.625rem; }
  .filter-options[role="tablist"] .filter-options-title {
    cursor: pointer; }
    .filter-options[role="tablist"] .filter-options-title:nth-last-child(2) {
      border-bottom: 0; }
  .filter-options[role="tablist"] .filter-options-title {
    padding: 0.5625rem 0.5625rem 0.5625rem 1.625rem;
    position: relative;
    border-bottom: 1px solid rgba(24, 24, 24, 0.15); }
    .filter-options[role="tablist"] .filter-options-title:before {
      display: block;
      content: '+';
      line-height: 1;
      position: absolute;
      top: 50%;
      left: 2px;
      font-size: 1.25em;
      transform: translateY(-50%); }
    .filter-options[role="tablist"] .filter-options-title.active:before {
      content: '—';
      font-size: .75em; }
  .filter-options[role="tablist"] .swatch-attribute-options {
    padding-left: 1.625rem; }
  .filter-options[role="tablist"] .swatch-attribute {
    padding: 0.9375rem 0 0; }

.ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item .swatch-option, .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item .swatch-option, .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item .swatch-option {
  width: 36px;
  height: 36px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
  border-radius: 100%; }
  .touchevents .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item .swatch-option:hover:before, .ais-RefinementList.has-images-labels .ais-RefinementList-list .touchevents .ais-RefinementList-item .swatch-option:hover:before, .touchevents .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item .swatch-option:hover:before, .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .touchevents .ais-RefinementList-item .swatch-option:hover:before, .touchevents .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item .swatch-option:hover:before, .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .touchevents .ais-RefinementList-item .swatch-option:hover:before, .touchevents .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item .swatch-option:focus:before, .ais-RefinementList.has-images-labels .ais-RefinementList-list .touchevents .ais-RefinementList-item .swatch-option:focus:before, .touchevents .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item .swatch-option:focus:before, .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .touchevents .ais-RefinementList-item .swatch-option:focus:before, .touchevents .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item .swatch-option:focus:before, .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .touchevents .ais-RefinementList-item .swatch-option:focus:before {
    display: block; }
  .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item .swatch-option:hover:after, .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item .swatch-option:hover:after, .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item .swatch-option:hover:after, .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item .swatch-option:focus:after, .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item .swatch-option:focus:after, .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item .swatch-option:focus:after, .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item .swatch-option.selected:after, .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item .swatch-option.selected:after, .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item .swatch-option.selected:after, .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item .swatch-option.checked:after, .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item .swatch-option.checked:after, .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item .swatch-option.checked:after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100%;
    height: 100%;
    background: url("../images/icon-check-filter.none") 50% no-repeat;
    background-size: 16px auto; }
  .product-items .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item .swatch-option, .ais-RefinementList.has-images-labels .ais-RefinementList-list .product-items .ais-RefinementList-item .swatch-option, .product-items .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item .swatch-option, .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .product-items .ais-RefinementList-item .swatch-option, .product-items .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item .swatch-option, .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .product-items .ais-RefinementList-item .swatch-option {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    vertical-align: top;
    margin: 0.5rem;
    margin-left: 0; }
    .product-items .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item .swatch-option.selected, .ais-RefinementList.has-images-labels .ais-RefinementList-list .product-items .ais-RefinementList-item .swatch-option.selected, .product-items .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item .swatch-option.selected, .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .product-items .ais-RefinementList-item .swatch-option.selected, .product-items .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item .swatch-option.selected, .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .product-items .ais-RefinementList-item .swatch-option.selected {
      height: 10px;
      box-shadow: 0 0 0 1px #181818; }
      .product-items .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item .swatch-option.selected:after, .ais-RefinementList.has-images-labels .ais-RefinementList-list .product-items .ais-RefinementList-item .swatch-option.selected:after, .product-items .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item .swatch-option.selected:after, .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .product-items .ais-RefinementList-item .swatch-option.selected:after, .product-items .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item .swatch-option.selected:after, .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .product-items .ais-RefinementList-item .swatch-option.selected:after {
        display: none; }
  .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item .swatch-option.disabled, .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item .swatch-option.disabled, .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item .swatch-option.disabled {
    opacity: .25; }
  .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item .swatch-option[option-label="White"], .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item .swatch-option[option-label="White"], .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item .swatch-option[option-label="White"], .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item .swatch-option[option-label="Hvit"], .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item .swatch-option[option-label="Hvit"], .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item .swatch-option[option-label="Hvit"] {
    box-shadow: inset 0 0 0 1px rgba(24, 24, 24, 0.1); }

.ais-RefinementList-searchBox {
  display: none; }

.ais-RefinementList-list,
.ais-CurrentRefinements-list,
.ais-HierarchicalMenu-list {
  font-size: 1.125rem;
  border-top: 1px solid rgba(24, 24, 24, 0.15); }
  .ais-RefinementList-list .ais-RefinementList-item:after,
  .ais-RefinementList-list .ais-CurrentRefinements-item:after,
  .ais-RefinementList-list .ais-HierarchicalMenu-item:after,
  .ais-CurrentRefinements-list .ais-RefinementList-item:after,
  .ais-CurrentRefinements-list .ais-CurrentRefinements-item:after,
  .ais-CurrentRefinements-list .ais-HierarchicalMenu-item:after,
  .ais-HierarchicalMenu-list .ais-RefinementList-item:after,
  .ais-HierarchicalMenu-list .ais-CurrentRefinements-item:after,
  .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item:after {
    display: none; }
  .ais-RefinementList-list .ais-CurrentRefinements-item,
  .ais-RefinementList-list .ais-HierarchicalMenu-item,
  .ais-CurrentRefinements-list .ais-CurrentRefinements-item,
  .ais-CurrentRefinements-list .ais-HierarchicalMenu-item,
  .ais-HierarchicalMenu-list .ais-CurrentRefinements-item,
  .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item {
    position: relative;
    border-bottom: 1px solid rgba(24, 24, 24, 0.15);
    padding: 0; }
    .ais-RefinementList-list .ais-CurrentRefinements-item:last-child,
    .ais-RefinementList-list .ais-HierarchicalMenu-item:last-child,
    .ais-CurrentRefinements-list .ais-CurrentRefinements-item:last-child,
    .ais-CurrentRefinements-list .ais-HierarchicalMenu-item:last-child,
    .ais-HierarchicalMenu-list .ais-CurrentRefinements-item:last-child,
    .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item:last-child {
      border-bottom: 0; }

.ais-root.swatches.visual .ais-refinement-list--list, .ais-root.swatches.text .ais-refinement-list--list {
  padding-top: 0;
  padding-bottom: 1rem;
  border-top: 0; }
  .ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item, .ais-root.swatches.text .ais-refinement-list--list .ais-refinement-list--item {
    display: inline-block;
    vertical-align: top;
    margin: 0 2px 3px;
    padding: 0;
    position: relative;
    font-size: 0;
    border: 0; }
    .ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item.checked .swatch-option.color:after, .ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item.ais-refinement-list--item__active .swatch-option.color:after, .ais-root.swatches.text .ais-refinement-list--list .ais-refinement-list--item.checked .swatch-option.color:after, .ais-root.swatches.text .ais-refinement-list--list .ais-refinement-list--item.ais-refinement-list--item__active .swatch-option.color:after {
      opacity: 1; }
    .ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item.checked .swatch-option.text, .ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item.ais-refinement-list--item__active .swatch-option.text, .ais-root.swatches.text .ais-refinement-list--list .ais-refinement-list--item.checked .swatch-option.text, .ais-root.swatches.text .ais-refinement-list--list .ais-refinement-list--item.ais-refinement-list--item__active .swatch-option.text {
      color: white;
      background-color: #1A2746; }
    .ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item .swatch-option, .ais-root.swatches.text .ais-refinement-list--list .ais-refinement-list--item .swatch-option {
      cursor: pointer;
      position: relative;
      text-align: center;
      display: inline-block;
      vertical-align: middle; }

.ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item .swatch-option.color {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: white; }
  .ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item .swatch-option.color:after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100%;
    height: 100%;
    background: url("../images/icon-check-filter.none") 50% no-repeat;
    background-size: 16px auto;
    transition: opacity .3s ease;
    opacity: 0; }
  .touchevents .ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item .swatch-option.color:hover:before, .touchevents .ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item .swatch-option.color:focus:before {
    display: block; }
  .ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item .swatch-option.color:hover:after, .ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item .swatch-option.color:focus:after, .ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item .swatch-option.color.selected:after, .ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item .swatch-option.color.checked:after {
    opacity: 1; }
  .ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item .swatch-option.color.disabled {
    opacity: .25; }
  .ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item .swatch-option.color[option-label="White"], .ais-root.swatches.visual .ais-refinement-list--list .ais-refinement-list--item .swatch-option.color[option-label="Hvit"] {
    box-shadow: inset 0 0 0 1px rgba(24, 24, 24, 0.1); }

.ais-root.swatches.text .ais-refinement-list--list .ais-refinement-list--item .swatch-option.text {
  width: auto;
  min-width: 36px;
  height: 36px;
  padding: 0 4px;
  line-height: 36px;
  font-size: 0.9375rem;
  font-weight: 400;
  font-family: inherit;
  color: #181818;
  box-shadow: 0 0 10px 2px rgba(24, 24, 24, 0.07);
  background-color: white;
  letter-spacing: normal;
  word-spacing: normal; }
  .ais-root.swatches.text .ais-refinement-list--list .ais-refinement-list--item .swatch-option.text:before, .ais-root.swatches.text .ais-refinement-list--list .ais-refinement-list--item .swatch-option.text:after {
    display: none; }
  .ais-root.swatches.text .ais-refinement-list--list .ais-refinement-list--item .swatch-option.text.disabled:before, .ais-root.swatches.text .ais-refinement-list--list .ais-refinement-list--item .swatch-option.text.disabled:after {
    display: none; }
  .ais-root.swatches.text .ais-refinement-list--list .ais-refinement-list--item .swatch-option.text:hover, .ais-root.swatches.text .ais-refinement-list--list .ais-refinement-list--item .swatch-option.text:focus, .ais-root.swatches.text .ais-refinement-list--list .ais-refinement-list--item .swatch-option.text.selected, .ais-root.swatches.text .ais-refinement-list--list .ais-refinement-list--item .swatch-option.text.checked {
    color: white;
    background-color: #1A2746; }
  .ais-root.swatches.text .ais-refinement-list--list .ais-refinement-list--item .swatch-option.text .ais-refinement-list--count {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }

.ais-Panel {
  position: relative;
  background: transparent;
  margin-bottom: 0.5rem; }
  .ais-Panel a {
    text-decoration: none; }
  .ais-Panel-header {
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-size: 1.625rem;
    color: #181818;
    font-weight: 300;
    margin-bottom: 0;
    line-height: 1.2;
    padding: 0 0 2.6875rem;
    padding-bottom: 0.75rem;
    padding-left: 0; }
  .ais-Panel-body {
    padding: 0 0 2.6875rem;
    padding-top: 1px; }
    .ais-Panel-body ul,
    .ais-Panel-body ol {
      margin: 0;
      list-style: none; }
      .ais-Panel-body ul li,
      .ais-Panel-body ol li {
        padding: 0; }
        .ais-Panel-body ul li:after,
        .ais-Panel-body ol li:after {
          display: none; }

#instant-search-facets-container {
  position: relative; }

#clear-refinements {
  position: absolute;
  top: 14px;
  right: 34px; }

.ais-ClearRefinements-button {
  background-color: transparent !important;
  color: #1A2746 !important;
  text-decoration: underline;
  padding: 0 !important;
  font-size: 12px !important; }

.ais-CurrentRefinements {
  font-size: 1.125rem;
  border-top: 1px solid rgba(24, 24, 24, 0.15);
  margin-bottom: 0.5rem;
  background-color: transparent; }
  .ais-CurrentRefinements .ais-CurrentRefinements-item {
    position: relative;
    border-bottom: 1px solid rgba(24, 24, 24, 0.15); }
    .ais-CurrentRefinements .ais-CurrentRefinements-item:last-child {
      border-bottom: 0; }
    .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-label,
    .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-link {
      display: block;
      text-transform: none;
      font-family: inherit;
      font-size: 1.125rem;
      font-weight: inherit;
      color: rgba(24, 24, 24, 0.9);
      line-height: 1.7;
      padding: 0.5rem 1.8rem 0.5rem 0;
      cursor: pointer;
      position: relative; }
      .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-label:hover,
      .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-link:hover {
        color: #181818;
        background-color: transparent; }
    .ais-CurrentRefinements .ais-CurrentRefinements-item.ais-CurrentRefinements-item--selected > div > .ais-CurrentRefinements-label,
    .ais-CurrentRefinements .ais-CurrentRefinements-item.ais-CurrentRefinements-item--selected > div > .ais-CurrentRefinements-link {
      font-weight: bolder; }
      .ais-CurrentRefinements .ais-CurrentRefinements-item.ais-CurrentRefinements-item--selected > div > .ais-CurrentRefinements-label:after,
      .ais-CurrentRefinements .ais-CurrentRefinements-item.ais-CurrentRefinements-item--selected > div > .ais-CurrentRefinements-link:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 16px;
        height: 13px;
        background: url("../images/icon-check-white.svg") no-repeat;
        background-size: 100% auto; }
    .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-count {
      color: #8b8b8b;
      font-size: 85%; }
      .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-count:before {
        content: '('; }
      .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-count:after {
        content: ')'; }
    .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-HierarchicalMenu-count {
      position: relative;
      margin-left: .25rem; }
    .ais-CurrentRefinements .ais-CurrentRefinements-item input[type="checkbox"],
    .ais-CurrentRefinements .ais-CurrentRefinements-item input[type="radio"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0; }
  .ais-CurrentRefinements-list {
    margin: 0;
    list-style: none; }
    .ais-CurrentRefinements-list li {
      padding: 0; }
      .ais-CurrentRefinements-list li:after {
        display: none; }
    .ais-CurrentRefinements-list:not(:empty) {
      padding: 0 0 2.6875rem; }
  .ais-CurrentRefinements-item {
    padding: 0.5rem 0 !important; }
    .ais-CurrentRefinements-item + .ais-CurrentRefinements-item {
      border-top: 1px solid rgba(24, 24, 24, 0.15) !important; }
  .ais-CurrentRefinements-label {
    padding: 0 !important;
    margin-right: 10px;
    font-weight: bold !important;
    padding-left: 18px !important; }
    .ais-CurrentRefinements-label:before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      content: '';
      width: .75rem;
      height: .75rem;
      opacity: .5;
      background: url("../images/icon-check-white.svg") no-repeat 0 50%;
      background-size: 100% auto; }
  .ais-CurrentRefinements-category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    flex-basis: 100%;
    padding-left: 18px; }
  .ais-CurrentRefinements-delete {
    padding: 4px;
    color: #1A2746;
    cursor: pointer; }
    .ais-CurrentRefinements-delete:hover {
      color: #D41367; }
  .ais-CurrentRefinements-categoryLabel {
    white-space: nowrap; }

.ais-HierarchicalMenu-list {
  font-size: 1.125rem;
  border-top: 1px solid rgba(24, 24, 24, 0.15); }
  .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item {
    position: relative;
    border-bottom: 1px solid rgba(24, 24, 24, 0.15); }
    .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item:last-child {
      border-bottom: 0; }
    .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-label,
    .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link {
      display: block;
      text-transform: none;
      font-family: inherit;
      font-size: 1.125rem;
      font-weight: inherit;
      color: rgba(24, 24, 24, 0.9);
      line-height: 1.7;
      padding: 0.5rem 1.8rem 0.5rem 0;
      cursor: pointer;
      position: relative; }
      .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-label:hover,
      .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link:hover {
        color: #181818;
        background-color: transparent; }
    .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected > div > .ais-HierarchicalMenu-label,
    .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected > div > .ais-HierarchicalMenu-link {
      font-weight: bolder; }
      .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected > div > .ais-HierarchicalMenu-label:after,
      .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected > div > .ais-HierarchicalMenu-link:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 16px;
        height: 13px;
        background: url("../images/icon-check-white.svg") no-repeat;
        background-size: 100% auto; }
    .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-count {
      color: #8b8b8b;
      font-size: 85%; }
      .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-count:before {
        content: '('; }
      .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-count:after {
        content: ')'; }
    .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-count {
      position: relative;
      margin-left: .25rem; }
    .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item input[type="checkbox"],
    .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item input[type="radio"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0; }
  .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected .ais-HierarchicalMenu-link--selected {
    padding-left: 23px;
    font-weight: 700;
    background: none; }
    .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected .ais-HierarchicalMenu-link--selected:after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 16px;
      height: 13px;
      background: url("../images/icon-check-white.svg") no-repeat;
      background-size: 100% auto; }
  .ais-HierarchicalMenu-list .ais-HierarchicalMenu-link--selected .cross-circle {
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    background: url("data:image/svg+xml;utf8,<svg width='34' height='34' viewBox='0 0 34 34' xmlns='http://www.w3.org/2000/svg'><title>testvg</title><g fill='%23000' fill-rule='evenodd'><path d='M17.163 0C7.95 0 .41 7.578.353 16.893c-.03 4.542 1.693 8.82 4.847 12.053 3.156 3.23 7.367 5.026 11.857 5.054h.11c9.21 0 16.75-7.578 16.81-16.893C34.035 7.735 26.54.06 17.163 0zm.015 30.842v1.08l-.09-1.08c-3.656-.023-7.085-1.485-9.654-4.115-2.57-2.63-3.97-6.116-3.948-9.814C3.533 9.33 9.673 3.158 17.262 3.158c7.548.048 13.65 6.297 13.605 13.93-.05 7.585-6.19 13.754-13.69 13.754z'/><path d='M22.362 10.23l-5.186 5.245-5.186-5.244c-.417-.42-1.092-.42-1.51 0-.416.422-.416 1.105 0 1.526L15.668 17l-5.186 5.244c-.416.42-.416 1.104 0 1.525.21.21.483.316.755.316.273 0 .546-.106.755-.317l5.186-5.245 5.186 5.244c.208.21.482.316.754.316.273 0 .546-.106.755-.317.417-.422.417-1.105 0-1.526L18.685 17l5.187-5.244c.417-.42.417-1.104 0-1.525-.416-.42-1.09-.42-1.508 0z'/></g></svg>") no-repeat center center/contain;
    opacity: 0;
    vertical-align: middle;
    margin-left: 1rem; }
  .ais-HierarchicalMenu-list .ais-HierarchicalMenu-link--selected:hover .cross-circle {
    opacity: 1; }
  .ais-HierarchicalMenu-list .ais-HierarchicalMenu-list--child .ais-HierarchicalMenu-item {
    padding-left: 1rem; }

.ais-RefinementList-list {
  font-size: 1.125rem;
  border-top: 1px solid rgba(24, 24, 24, 0.15); }
  .ais-RefinementList-list .ais-RefinementList-item {
    position: relative;
    border-bottom: 1px solid rgba(24, 24, 24, 0.15); }
    .ais-RefinementList-list .ais-RefinementList-item:last-child {
      border-bottom: 0; }
    .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-label,
    .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-link {
      display: block;
      text-transform: none;
      font-family: inherit;
      font-size: 1.125rem;
      font-weight: inherit;
      color: rgba(24, 24, 24, 0.9);
      line-height: 1.7;
      padding: 0.5rem 1.8rem 0.5rem 0;
      cursor: pointer;
      position: relative; }
      .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-label:hover,
      .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-link:hover {
        color: #181818;
        background-color: transparent; }
    .ais-RefinementList-list .ais-RefinementList-item.ais-RefinementList-item--selected > div > .ais-RefinementList-label,
    .ais-RefinementList-list .ais-RefinementList-item.ais-RefinementList-item--selected > div > .ais-RefinementList-link {
      font-weight: bolder; }
      .ais-RefinementList-list .ais-RefinementList-item.ais-RefinementList-item--selected > div > .ais-RefinementList-label:after,
      .ais-RefinementList-list .ais-RefinementList-item.ais-RefinementList-item--selected > div > .ais-RefinementList-link:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 16px;
        height: 13px;
        background: url("../images/icon-check-white.svg") no-repeat;
        background-size: 100% auto; }
    .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-count {
      color: #8b8b8b;
      font-size: 85%; }
      .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-count:before {
        content: '('; }
      .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-count:after {
        content: ')'; }
    .ais-RefinementList-list .ais-RefinementList-item .ais-HierarchicalMenu-count {
      position: relative;
      margin-left: .25rem; }
    .ais-RefinementList-list .ais-RefinementList-item input[type="checkbox"],
    .ais-RefinementList-list .ais-RefinementList-item input[type="radio"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0; }

.rheostat {
  position: relative;
  font-size: 0.9375rem;
  font-weight: inherit;
  color: inherit;
  margin: 10px 0 36px; }
  .rheostat:after {
    display: block;
    content: '';
    position: absolute;
    width: 14px;
    left: 100%;
    top: 0;
    height: 1px;
    background: rgba(24, 24, 24, 0.15); }
  .rheostat .rheostat-background {
    width: 100%;
    text-align: left;
    position: relative;
    margin: 0.5rem 1.0625rem 0.625rem 0;
    border-top: 1px solid rgba(24, 24, 24, 0.15);
    z-index: 1; }
  .rheostat .rheostat-progress {
    position: absolute;
    top: 0;
    border-top: 1px solid #1A2746; }
  .rheostat .ais-range-slider--marker,
  .rheostat .rheostat-marker {
    margin-left: 0 !important;
    position: absolute;
    padding-top: 0.1875rem;
    text-align: center; }
    .rheostat .ais-range-slider--marker .ais-range-slider--value,
    .rheostat .ais-range-slider--marker .rheostat-value,
    .rheostat .rheostat-marker .ais-range-slider--value,
    .rheostat .rheostat-marker .rheostat-value {
      display: none; }
  .rheostat .rheostat-progress + .rheostat-marker.rheostat-marker-large {
    left: 0 !important; }
  .rheostat .ais-range-slider--marker.ais-range-slider--marker-large:last-child,
  .rheostat .rheostat-marker.rheostat-marker-large:last-child {
    left: auto !important;
    right: -8.5px; }
    .rheostat .ais-range-slider--marker.ais-range-slider--marker-large:last-child .ais-range-slider--value,
    .rheostat .ais-range-slider--marker.ais-range-slider--marker-large:last-child .rheostat-value,
    .rheostat .rheostat-marker.rheostat-marker-large:last-child .ais-range-slider--value,
    .rheostat .rheostat-marker.rheostat-marker-large:last-child .rheostat-value {
      display: block; }
  .rheostat .rheostat-marker.rheostat-marker-large:last-child {
    left: auto !important;
    right: 0; }
  .rheostat .rheostat-progress + .ais-range-slider--marker.ais-range-slider--marker-large .ais-range-slider--value,
  .rheostat .rheostat-progress + .ais-range-slider--marker.ais-range-slider--marker-large .rheostat-value,
  .rheostat .rheostat-progress + .rheostat-marker.rheostat-marker-large .ais-range-slider--value,
  .rheostat .rheostat-progress + .rheostat-marker.rheostat-marker-large .rheostat-value {
    display: block; }
  .rheostat .ais-range-slider--handle,
  .rheostat .rheostat-handle {
    position: absolute;
    z-index: 2;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: #1A2746;
    border-radius: 100%;
    border: none;
    top: -8.5px;
    margin-left: -1px;
    margin-top: 0;
    cursor: pointer;
    -ms-touch-action: none;
    touch-action: none; }
    .rheostat .ais-range-slider--handle.rheostat-handle-upper,
    .rheostat .rheostat-handle.rheostat-handle-upper {
      margin-left: -17px; }
    .rheostat .ais-range-slider--handle:focus, .rheostat .ais-range-slider--handle:active, .rheostat .ais-range-slider--handle:hover,
    .rheostat .rheostat-handle:focus,
    .rheostat .rheostat-handle:active,
    .rheostat .rheostat-handle:hover {
      outline: none; }
      .rheostat .ais-range-slider--handle:focus .ais-range-slider--tooltip,
      .rheostat .ais-range-slider--handle:focus .rheostat-tooltip, .rheostat .ais-range-slider--handle:active .ais-range-slider--tooltip,
      .rheostat .ais-range-slider--handle:active .rheostat-tooltip, .rheostat .ais-range-slider--handle:hover .ais-range-slider--tooltip,
      .rheostat .ais-range-slider--handle:hover .rheostat-tooltip,
      .rheostat .rheostat-handle:focus .ais-range-slider--tooltip,
      .rheostat .rheostat-handle:focus .rheostat-tooltip,
      .rheostat .rheostat-handle:active .ais-range-slider--tooltip,
      .rheostat .rheostat-handle:active .rheostat-tooltip,
      .rheostat .rheostat-handle:hover .ais-range-slider--tooltip,
      .rheostat .rheostat-handle:hover .rheostat-tooltip {
        opacity: 1; }
    .rheostat .ais-range-slider--handle .ais-range-slider--tooltip,
    .rheostat .ais-range-slider--handle .rheostat-tooltip,
    .rheostat .rheostat-handle .ais-range-slider--tooltip,
    .rheostat .rheostat-handle .rheostat-tooltip {
      opacity: 0;
      min-width: 1.875rem;
      position: absolute;
      color: white;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 0.3125rem;
      padding: 0.125rem 0.3125rem;
      font-size: 0.6875rem;
      line-height: 1.2;
      text-align: center;
      white-space: nowrap;
      background: #181818;
      transition: opacity .3s ease; }
      .rheostat .ais-range-slider--handle .ais-range-slider--tooltip:after,
      .rheostat .ais-range-slider--handle .rheostat-tooltip:after,
      .rheostat .rheostat-handle .ais-range-slider--tooltip:after,
      .rheostat .rheostat-handle .rheostat-tooltip:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 6px 0 6px;
        border-color: #181818 transparent transparent transparent;
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translateX(-50%); }

.pages-grid .ais-Hits {
  margin-bottom: 1rem; }
  @media print, screen and (min-width: 48em) {
    .pages-grid .ais-Hits {
      margin-bottom: 1.5rem; } }
  .pages-grid .ais-Hits .extra-title, .pages-grid .ais-Hits .block.grid .block-title strong, .block.grid .block-title .pages-grid .ais-Hits strong, .pages-grid .ais-Hits .block.related .block-title strong, .block.related .block-title .pages-grid .ais-Hits strong, .pages-grid .ais-Hits .block.crosssell .block-title strong, .block.crosssell .block-title .pages-grid .ais-Hits strong, .pages-grid .ais-Hits .block.upsell .block-title strong, .block.upsell .block-title .pages-grid .ais-Hits strong, .pages-grid .ais-Hits .block.block-new-products-names .block-title strong, .block.block-new-products-names .block-title .pages-grid .ais-Hits strong {
    font-size: 1.375rem;
    font-weight: 400; }

.pages-grid .ais-Hits--body {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  margin-right: -1.0625rem;
  margin-left: -1.0625rem; }
  .pages-grid .ais-Hits--body::before, .pages-grid .ais-Hits--body::after {
    display: table;
    content: ' '; }
  .pages-grid .ais-Hits--body::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .pages-grid .ais-Hits--body {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media print, screen and (min-width: 48em) {
    .pages-grid .ais-Hits--body {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 64em) {
    .pages-grid .ais-Hits--body {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 75em) {
    .pages-grid .ais-Hits--body {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }
  @media screen and (min-width: 90.625em) {
    .pages-grid .ais-Hits--body {
      margin-right: -1.25rem;
      margin-left: -1.25rem; } }

.pages-grid .ais-Hits-item,
.pages-grid .ais-InfiniteHits-item {
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem;
  margin-bottom: 1rem; }
  @media print, screen and (min-width: 40em) {
    .pages-grid .ais-Hits-item,
    .pages-grid .ais-InfiniteHits-item {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .pages-grid .ais-Hits-item:last-child:not(:first-child),
  .pages-grid .ais-InfiniteHits-item:last-child:not(:first-child) {
    float: right; }
  .pages-grid .ais-Hits-item:last-child:last-child,
  .pages-grid .ais-InfiniteHits-item:last-child:last-child {
    float: left; }
  @media print, screen and (min-width: 48em) {
    .pages-grid .ais-Hits-item,
    .pages-grid .ais-InfiniteHits-item {
      width: 50%;
      margin-bottom: 1.5rem; } }
  .pages-grid .ais-Hits-item:nth-child(odd),
  .pages-grid .ais-InfiniteHits-item:nth-child(odd) {
    clear: left; }

.pages-grid .ais-hits--more-items {
  display: inline-block;
  color: #181818;
  cursor: pointer; }
  .pages-grid .ais-hits--more-items .text {
    text-decoration: underline; }
  .pages-grid .ais-hits--more-items .count {
    color: #181818; }
  .pages-grid .ais-hits--more-items:hover .text {
    text-decoration: none; }

.pages-grid .algoliasearch-page-hit {
  display: block;
  font-family: "silka-regular-webfont", sans-serif;
  letter-spacing: normal;
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: .25rem; }
  .pages-grid .algoliasearch-page-hit em {
    font-style: normal; }

.column.main .ais-infinite-hits--showmore button, .main.columns .ais-infinite-hits--showmore button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.82em 1em 0.87em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 50px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 1.1875rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #30A95E;
  color: #fff;
  font-family: "silka-semibold-webfont", sans-serif;
  letter-spacing: normal;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 0;
  background-color: #1A2746;
  color: #fff;
  border: 1px solid #1A2746;
  color: #1A2746;
  box-shadow: inset 0 0 0 1px #1A2746;
  transition: background-color 0.25s ease-out, box-shadow 0.25s ease-out, color 0.25s ease-out;
  display: block;
  width: 100%;
  max-width: 15.625rem;
  margin-left: auto;
  margin-right: auto;
  clear: both;
  color: #181818;
  cursor: pointer; }
  [data-whatinput='mouse'] .column.main .ais-infinite-hits--showmore button, [data-whatinput='mouse'] .main.columns .ais-infinite-hits--showmore button {
    outline: 0; }
  .column.main .ais-infinite-hits--showmore button:hover, .main.columns .ais-infinite-hits--showmore button:hover, .column.main .ais-infinite-hits--showmore button:focus, .main.columns .ais-infinite-hits--showmore button:focus {
    background-color: rgba(48, 169, 94, 0.75);
    color: #fff; }
  .column.main .ais-infinite-hits--showmore button:active, .main.columns .ais-infinite-hits--showmore button:active {
    color: rgba(255, 255, 255, 0.8) !important; }
  .column.main .ais-infinite-hits--showmore button:hover, .main.columns .ais-infinite-hits--showmore button:hover, .column.main .ais-infinite-hits--showmore button:focus, .main.columns .ais-infinite-hits--showmore button:focus {
    background-color: rgba(48, 169, 94, 0.75);
    color: #fff; }
  .column.main .ais-infinite-hits--showmore button, .main.columns .ais-infinite-hits--showmore button, .column.main .ais-infinite-hits--showmore button:hover, .main.columns .ais-infinite-hits--showmore button:hover, .column.main .ais-infinite-hits--showmore button:focus, .main.columns .ais-infinite-hits--showmore button:focus {
    background-color: transparent; }
  .column.main .ais-infinite-hits--showmore button:hover, .main.columns .ais-infinite-hits--showmore button:hover, .column.main .ais-infinite-hits--showmore button:focus, .main.columns .ais-infinite-hits--showmore button:focus {
    border-color: #0d1423;
    color: #0d1423;
    box-shadow: inset 0 0 0 1px #0d1423; }
  .column.main .ais-infinite-hits--showmore button:hover, .main.columns .ais-infinite-hits--showmore button:hover, .column.main .ais-infinite-hits--showmore button:focus, .main.columns .ais-infinite-hits--showmore button:focus {
    color: white;
    border-color: #1A2746;
    box-shadow: inset 0 0 0 1px #1A2746;
    background: #1A2746; }
  .column.main .ais-infinite-hits--showmore button[disabled], .main.columns .ais-infinite-hits--showmore button[disabled] {
    display: none;
    opacity: .25;
    cursor: default; }
    .column.main .ais-infinite-hits--showmore button[disabled]:hover, .main.columns .ais-infinite-hits--showmore button[disabled]:hover, .column.main .ais-infinite-hits--showmore button[disabled]:focus, .main.columns .ais-infinite-hits--showmore button[disabled]:focus {
      color: #181818;
      border: 1px solid #1A2746;
      box-shadow: inset 0 0 0 1px #1A2746;
      background: transparent; }

.algolia-current-refinements .ais-root.ais-hierarchical-menu .ais-body {
  margin-bottom: 0;
  padding-bottom: 1rem; }
  .algolia-current-refinements .ais-root.ais-hierarchical-menu .ais-body .ais-hierarchical-menu--link {
    font-size: 1.1875rem;
    padding: 0.625rem 3.125rem 0.625rem 0.5rem;
    color: white;
    background: url("../images/icon-arrow-next-gray.svg") calc(100% - 8px) 50% no-repeat;
    background-size: 7px auto; }
    .algolia-current-refinements .ais-root.ais-hierarchical-menu .ais-body .ais-hierarchical-menu--link:hover {
      color: #181818; }

.algolia-instant-selector-results .page-main .sidebar.algolia-left-container .algolia-current-refinements {
  margin-top: 0; }

.algolia-instant-selector-results .page-main .category-header {
  position: static;
  width: auto;
  clear: both; }
  .algolia-instant-selector-results .page-main .category-header .category-view {
    display: block; }
  .algolia-instant-selector-results .page-main .category-header .category-header-mobile {
    display: none !important; }
  @media screen and (max-width: 47.9375em) {
    .algolia-instant-selector-results .page-main .category-header .page-title-wrapper,
    .algolia-instant-selector-results .page-main .category-header .top-controls-wrapper {
      position: static !important;
      width: auto;
      height: auto;
      overflow: visible;
      clip: auto; } }

.algolia-instant-selector-results .page-title-wrapper .page-title {
  font-size: 1.75rem; }
  @media print, screen and (min-width: 40em) {
    .algolia-instant-selector-results .page-title-wrapper .page-title {
      font-size: 2.625rem; } }
  @media screen and (min-width: 64em) {
    .algolia-instant-selector-results .page-title-wrapper .page-title {
      font-size: 2.875rem; } }

@media screen and (max-width: 47.9375em) {
  .toolbar-sorter.sorter {
    display: none !important; } }

.algolia-mobile-filter-buttons {
  display: flex;
  justify-content: space-between; }
  @media print, screen and (min-width: 48em) {
    .algolia-mobile-filter-buttons {
      display: none; } }
  .algolia-mobile-filter-buttons .button {
    flex-basis: 48%;
    background-color: #30A95E;
    font-size: 1.1875rem; }
    .algolia-mobile-filter-buttons .button:hover {
      color: #fff;
      background-color: rgba(48, 169, 94, 0.75); }
    .algolia-mobile-filter-buttons .button:active, .algolia-mobile-filter-buttons .button:focus {
      color: #fff;
      background-color: rgba(48, 169, 94, 0.75); }
    .algolia-mobile-filter-buttons .button:before {
      width: 22px;
      height: 13px;
      display: inline-block;
      vertical-align: middle;
      content: '';
      margin-right: 0.5rem;
      background-image: url("../images/icon-sorter.svg");
      background-size: 100% auto;
      background-repeat: no-repeat; }
    .algolia-mobile-filter-buttons .button.refine-button:before {
      width: 15px;
      height: 10px;
      background-image: url("../images/icon-filter.svg"); }

.toolbar-sorter-modal .sorter-title,
.toolbar-sorter-modal .sorter-action {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.toolbar-sorter-modal .instant-search-sorter-options {
  margin: 0;
  list-style: none;
  border-top: 1px solid rgba(24, 24, 24, 0.15); }
  .toolbar-sorter-modal .instant-search-sorter-options li {
    padding: 0; }
    .toolbar-sorter-modal .instant-search-sorter-options li:after {
      display: none; }
  .toolbar-sorter-modal .instant-search-sorter-options .sorter-options {
    position: relative;
    padding: 0.625rem 3.125rem 0.625rem 0.5rem;
    padding-left: 2.1875rem;
    color: white;
    font-size: 1.1875rem;
    font-weight: 400;
    font-family: inherit;
    text-transform: none;
    border-bottom: 1px solid rgba(24, 24, 24, 0.15);
    background: url("../images/icon-arrow-next-gray.svg") calc(100% - 8px) 50% no-repeat;
    background-size: 7px auto; }
    .toolbar-sorter-modal .instant-search-sorter-options .sorter-options:last-child {
      border-bottom: 0; }
    .toolbar-sorter-modal .instant-search-sorter-options .sorter-options.selected {
      font-weight: 700;
      background-image: none; }
      .toolbar-sorter-modal .instant-search-sorter-options .sorter-options.selected:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 16px;
        height: 13px;
        background: url("../images/icon-check-white.svg") no-repeat;
        background-size: 100% auto; }

.ais-breadcrumb--root {
  display: flex;
  font-size: 0.8125rem; }
  .ais-breadcrumb--root .ais-breadcrumb--label {
    margin-right: 0.4rem;
    font-weight: 400;
    font-family: inherit;
    text-decoration: none; }
    .ais-breadcrumb--root .ais-breadcrumb--label div {
      display: inline-block;
      vertical-align: middle; }
    .ais-breadcrumb--root .ais-breadcrumb--label:hover div {
      text-decoration: underline; }
    .ais-breadcrumb--root .ais-breadcrumb--label:not(:last-child)::after {
      width: auto;
      height: auto;
      content: "/";
      display: inline-block;
      vertical-align: baseline;
      position: relative;
      top: auto;
      left: auto;
      margin-left: 0.4rem;
      font-size: 0.8125rem;
      color: rgba(0, 0, 0, 0.5);
      background-color: transparent; }
    .ais-breadcrumb--root .ais-breadcrumb--label.ais-breadcrumb--disabledLabel {
      text-decoration: none !important;
      color: rgba(0, 0, 0, 0.3);
      cursor: text; }
      .ais-breadcrumb--root .ais-breadcrumb--label.ais-breadcrumb--disabledLabel div {
        text-decoration: none !important; }
  .ais-breadcrumb--root .ais-breadcrumb--separator {
    display: none; }

.accordion {
  margin-left: 0;
  background: white;
  list-style-type: none; }

.accordion-item:first-child > :first-child {
  border-radius: 4px 4px 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 4px 4px; }

.accordion-title {
  position: relative;
  display: block;
  padding: 21px 25px 21px 60px;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 1.125rem;
  line-height: 1;
  color: #181818; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 4px 4px; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }

.accordion-content {
  display: none;
  padding: 20px 40px 20px 58px;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

footer.page-footer .cookies {
  color: rgba(255, 255, 255, 0.4);
  text-align: right;
  font-size: 0.6875rem;
  font-family: inherit; }
  footer.page-footer .cookies p {
    font-size: 0.6875rem;
    margin: 0; }
  footer.page-footer .cookies a {
    color: rgba(255, 255, 255, 0.4);
    text-decoration: underline; }
    footer.page-footer .cookies a:hover, footer.page-footer .cookies a:active {
      text-decoration: none; }
  footer.page-footer .cookies .cookies-magento a {
    color: rgba(255, 255, 255, 0.4); }

.header .top-links,
.header .links {
  margin: 0;
  list-style: none;
  font-size: 0; }
  .header .top-links li,
  .header .links li {
    padding: 0; }
    .header .top-links li:after,
    .header .links li:after {
      display: none; }
  .header .top-links li,
  .header .links li {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.75rem;
    color: white;
    font-weight: 400; }
    .header .top-links li.customer-section,
    .header .links li.customer-section {
      padding-left: 0.3125rem; }
      @media screen and (min-width: 75em) {
        .header .top-links li.customer-section,
        .header .links li.customer-section {
          padding-left: 0.625rem; } }
      .header .top-links li.customer-section a,
      .header .links li.customer-section a {
        padding-left: 1.25rem; }
        .header .top-links li.customer-section a:before,
        .header .links li.customer-section a:before {
          display: block;
          content: '';
          width: 15px;
          height: 13px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background: url("../images/icon-user-account.svg") 50% 50% no-repeat;
          background-size: 100% auto; }
        .header .top-links li.customer-section a:after,
        .header .links li.customer-section a:after {
          display: block;
          content: '';
          width: 1px;
          position: absolute;
          top: -.25rem;
          bottom: -.25rem;
          right: 0;
          background: rgba(255, 255, 255, 0.2); }
    .header .top-links li a,
    .header .links li a {
      display: block;
      font-size: 15px;
      color: white;
      line-height: 1.6;
      text-transform: none;
      padding: 0 0.27778rem;
      position: relative;
      font-family: "silka-regular-webfont", sans-serif;
      font-weight: inherit;
      text-decoration: none; }
      @media screen and (min-width: 64em) {
        .header .top-links li a,
        .header .links li a {
          padding: 0 0.41667rem; } }
      @media screen and (min-width: 75em) {
        .header .top-links li a,
        .header .links li a {
          padding: 0 0.625rem; } }
      .header .top-links li a:hover,
      .header .links li a:hover {
        text-decoration: underline; }
    @media screen and (min-width: 90.625em) {
      .header .top-links li:last-child a,
      .header .links li:last-child a {
        padding-right: 0; } }

.header .top-links,
.header .header.links {
  margin-left: -0.27778rem; }
  @media screen and (min-width: 64em) {
    .header .top-links,
    .header .header.links {
      margin-left: -0.41667rem; } }
  @media screen and (min-width: 75em) {
    .header .top-links,
    .header .header.links {
      margin-left: -0.625rem; } }

.header .links .greet,
.header .links .counter,
.header .links #invitation-send-link,
.header .links .customer-welcome,
.header .links .compare {
  display: none; }

@media screen and (max-width: 63.9375em) {
  .header .links .wishlist {
    display: none !important; } }

.footer-social li {
  display: inline-block;
  margin-right: 6px;
  font-size: 0; }
  .footer-social li:last-child {
    margin-right: 0; }

.footer-social a {
  display: inline-block;
  color: #1A2746;
  opacity: 1;
  position: relative;
  line-height: 47px; }
  .footer-social a:hover {
    color: #181818;
    opacity: 0.75; }
    .footer-social a:hover:before {
      background-color: transparent; }
  .footer-social a:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 47px;
    height: 47px;
    background-color: transparent;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    transition: background-color .5s ease;
    border-radius: 100%; }

.footer-social .icon-facebook:before {
  background-image: url("../images/icon-facebook.svg"); }

.footer-social .icon-instagram:before {
  background-image: url("../images/icon-instagram.svg"); }

.footer-social .icon-youtube:before {
  background-image: url("../images/icon-youtube.svg"); }

.footer-social .icon-linkedin:before {
  background-image: url("../images/icon-linkedin.svg"); }

.page-with-filter .shipping-bar-container {
  display: none; }
  @media print, screen and (min-width: 40em) {
    .page-with-filter .shipping-bar-container {
      display: block; } }

.breadcrumbs {
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem; }
  @media print, screen and (min-width: 40em) {
    .breadcrumbs {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .breadcrumbs:last-child:not(:first-child) {
    float: right; }
  .breadcrumbs:last-child:last-child {
    float: left; }
  @media print, screen and (min-width: 40em) {
    .breadcrumbs {
      width: 58.33333%; } }
  .breadcrumbs + .vat-label {
    bottom: auto;
    top: .25rem; }
  .breadcrumbs .items {
    margin: 0 0 1rem 0;
    list-style: none;
    margin: 0;
    list-style: none;
    margin-bottom: 0; }
    .breadcrumbs .items::before, .breadcrumbs .items::after {
      display: table;
      content: ' '; }
    .breadcrumbs .items::after {
      clear: both; }
    .breadcrumbs .items li {
      float: left;
      font-size: 0.8125rem;
      color: rgba(0, 0, 0, 0.5);
      cursor: default; }
      .breadcrumbs .items li:not(:last-child)::after {
        position: relative;
        top: 1px;
        margin: 0 0.4rem;
        opacity: 1;
        content: "/";
        color: rgba(0, 0, 0, 0.5); }
    .breadcrumbs .items a {
      color: rgba(0, 0, 0, 0.5); }
      .breadcrumbs .items a:hover {
        text-decoration: underline; }
    .breadcrumbs .items li {
      padding: 0; }
      .breadcrumbs .items li:after {
        display: none; }
    .breadcrumbs .items .disabled {
      color: rgba(0, 0, 0, 0.3);
      cursor: not-allowed; }
    .breadcrumbs .items li {
      margin-right: 0.4rem;
      font-weight: 400;
      font-family: inherit; }
      .breadcrumbs .items li:not(:last-child)::after {
        width: auto;
        height: auto;
        content: "/";
        display: inline-block;
        vertical-align: baseline;
        position: relative;
        top: auto;
        left: auto;
        font-size: 0.8125rem;
        color: rgba(0, 0, 0, 0.5);
        background-color: transparent; }
      .breadcrumbs .items li strong {
        font-weight: inherit; }
      .breadcrumbs .items li a {
        text-decoration: none; }
        .breadcrumbs .items li a:hover {
          text-decoration: underline; }

.shipping-bar > div::before, .shipping-bar > div::after {
  display: table;
  content: ' '; }

.shipping-bar > div::after {
  clear: both; }

.shipping-bar .progress-text {
  font-size: 0.8125rem;
  font-family: inherit;
  text-align: right;
  line-height: 0.375rem;
  margin-bottom: .25rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5); }
  @media print, screen and (min-width: 40em) {
    .shipping-bar .progress-text {
      width: 50%;
      float: left;
      margin-bottom: 0;
      padding-right: 1.0625rem; } }
  @media print, screen and (min-width: 40em) and (min-width: 40em) {
    .shipping-bar .progress-text {
      padding-right: 1.25rem; } }
  @media screen and (min-width: 75em) {
    .shipping-bar .progress-text {
      width: 80%; } }

.shipping-bar .progress-graph {
  height: 0.375rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #fff;
  border: none;
  padding: 0; }
  .shipping-bar .progress-graph.small-shipping-bar {
    height: 0.1875rem;
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .shipping-bar .progress-graph {
      width: 50%;
      float: left; } }
  @media screen and (min-width: 75em) {
    .shipping-bar .progress-graph {
      width: 20%; } }
  .shipping-bar .progress-graph .progress-bar {
    position: relative;
    display: block;
    width: 0%;
    height: 100%;
    background-color: #e24283; }

.top-controls-wrapper {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  z-index: 1;
  margin-top: 1.6rem;
  margin-bottom: 1rem; }
  .top-controls-wrapper::before, .top-controls-wrapper::after {
    display: table;
    content: ' '; }
  .top-controls-wrapper::after {
    clear: both; }
  .cms-index-index .algolia-instant-selector-results .top-controls-wrapper {
    display: block; }
  .top-controls-wrapper .shipping-bar-wrapper::before, .top-controls-wrapper .shipping-bar-wrapper::after {
    display: table;
    content: ' '; }
  .top-controls-wrapper .shipping-bar-wrapper::after {
    clear: both; }
  .cms-index-index .top-controls-wrapper {
    display: none; }
  @media print, screen and (min-width: 48em) {
    .top-controls-wrapper {
      margin-bottom: 1.5rem; } }
  @media screen and (min-width: 75em) {
    .top-controls-wrapper {
      margin-bottom: 1.5rem; } }
  @media print, screen and (min-width: 48em) {
    .page-with-filter .top-controls-wrapper,
    .algolia-instant-selector-results .top-controls-wrapper {
      margin-bottom: 1.5rem; } }
  @media screen and (min-width: 75em) {
    .page-with-filter .top-controls-wrapper,
    .algolia-instant-selector-results .top-controls-wrapper {
      margin-bottom: 1.5rem; } }
  @media screen and (min-width: 75em) {
    .page-with-filter .top-controls-wrapper,
    .algolia-instant-selector-results .top-controls-wrapper {
      margin-bottom: 1.5rem; } }
  @media print, screen and (min-width: 48em) {
    .category-header.has-bg .top-controls-wrapper {
      margin-bottom: 2.75rem; } }
  @media screen and (min-width: 75em) {
    .category-header.has-bg.has-desc .top-controls-wrapper {
      margin-bottom: 5rem; } }
  @media screen and (min-width: 75em) {
    .category-header.has-bg.has-image .top-controls-wrapper {
      margin-bottom: 4.5rem; } }
  .top-controls-wrapper .shipping-bar-container {
    display: none;
    position: relative;
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .top-controls-wrapper .shipping-bar-container {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .top-controls-wrapper .shipping-bar-container:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 48em) {
      .top-controls-wrapper .shipping-bar-container {
        display: block;
        width: 41.66667%; }
        .top-controls-wrapper .shipping-bar-container .shipping-bar-container {
          width: 100%;
          float: none;
          padding-left: 0rem;
          padding-right: 0rem; } }
    @media print, screen and (min-width: 48em) and (min-width: 40em) {
      .top-controls-wrapper .shipping-bar-container .shipping-bar-container {
        padding-left: 0rem; } }
    @media print, screen and (min-width: 48em) and (min-width: 40em) {
      .top-controls-wrapper .shipping-bar-container .shipping-bar-container {
        padding-right: 0rem; } }
  .top-controls-wrapper .vat-label {
    position: absolute;
    bottom: 0;
    right: 1.0625rem;
    font-size: 0.8125rem;
    line-height: 0.375rem;
    color: rgba(0, 0, 0, 0.5); }
    @media print, screen and (min-width: 40em) {
      .top-controls-wrapper .vat-label {
        right: 1.25rem; } }
    @media screen and (max-width: 47.9375em) {
      .top-controls-wrapper .vat-label {
        position: absolute !important;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0); } }

.page-layout-0column .usp-section,
.usp-section ul {
  padding-left: 6px;
  padding-right: 6px; }
  @media print, screen and (min-width: 48em) {
    .page-layout-0column .usp-section,
    .usp-section ul {
      padding-left: 20px;
      padding-right: 20px; } }
  @media screen and (min-width: 90.625em) {
    .page-layout-0column .usp-section,
    .usp-section ul {
      padding-left: 0;
      padding-right: 0; } }

.usp-section {
  padding: 1.6rem 0;
  position: relative;
  overflow: hidden; }
  @media screen and (min-width: 64em) {
    .usp-section {
      padding: 2.3rem 0; } }
  .usp-section:before {
    content: '';
    display: block;
    visibility: visible;
    border-top: 1px solid rgba(24, 24, 24, 0.15);
    position: absolute;
    top: 0;
    left: -50%;
    right: -50%; }
  .usp-section h3 {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }
  .usp-section ul {
    margin: 0;
    list-style: none;
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    font-size: 1.125rem;
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-weight: 300;
    color: rgba(24, 24, 24, 0.75); }
    .usp-section ul li {
      padding: 0; }
      .usp-section ul li:after {
        display: none; }
    .usp-section ul::before, .usp-section ul::after {
      display: table;
      content: ' '; }
    .usp-section ul::after {
      clear: both; }
    .usp-section ul li {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem;
      line-height: 1.3;
      margin-bottom: 0.75rem; }
      @media print, screen and (min-width: 40em) {
        .usp-section ul li {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .usp-section ul li:last-child:not(:first-child) {
        float: right; }
      .usp-section ul li:last-child:last-child {
        float: left; }
      .usp-section ul li:last-child {
        margin-bottom: 0; }
      .usp-section ul li a,
      .usp-section ul li span {
        display: block;
        position: relative;
        padding-left: 2.0625rem; }
        .usp-section ul li a:before,
        .usp-section ul li span:before {
          content: '';
          display: block;
          position: absolute;
          width: 1.0625rem;
          height: 0.75rem;
          top: 0.5rem;
          left: 0;
          background: url("../images/icon-usp.svg") no-repeat;
          background-size: 1.0625rem auto; }
      .usp-section ul li a {
        color: rgba(24, 24, 24, 0.75);
        text-decoration: none; }
        .usp-section ul li a:hover, .usp-section ul li a:focus {
          text-decoration: underline; }
      .usp-section ul li div {
        font-size: 1.125rem;
        margin-top: 0; }
      @media print, screen and (min-width: 40em) {
        .usp-section ul li {
          width: 50%;
          margin-bottom: 1.125rem; } }
      @media print, screen and (min-width: 48em) {
        .usp-section ul li {
          width: 25%;
          margin-bottom: 0; } }

.category-usp-section + .page-main {
  margin-top: 0 !important; }

.category-usp-section .usp-section {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 1rem;
  padding-bottom: 1.25rem;
  padding-left: 1.0625rem;
  padding-right: 17px !important; }
  .category-usp-section .usp-section::before, .category-usp-section .usp-section::after {
    display: table;
    content: ' '; }
  .category-usp-section .usp-section::after {
    clear: both; }
  @media print, screen and (min-width: 40em) {
    .category-usp-section .usp-section {
      padding-left: 1.25rem; } }
  @media print, screen and (min-width: 40em) {
    .category-usp-section .usp-section {
      padding-bottom: 0;
      padding-right: 20px !important; } }
  @media print, screen and (min-width: 48em) {
    .category-usp-section .usp-section {
      padding-top: .25rem;
      padding-bottom: .5rem; } }
  .category-usp-section .usp-section:before {
    display: none; }
  .category-usp-section .usp-section ul {
    max-width: initial;
    width: auto;
    margin-left: -0.53125rem;
    margin-right: -0.53125rem;
    padding-left: 0;
    padding-right: 0; }
    @media print, screen and (min-width: 40em) {
      .category-usp-section .usp-section ul {
        margin-left: -0.625rem; } }
    @media print, screen and (min-width: 40em) {
      .category-usp-section .usp-section ul {
        margin-right: -0.625rem; } }
    @media print, screen and (min-width: 48em) {
      .category-usp-section .usp-section ul {
        display: flex; } }
    .category-usp-section .usp-section ul li {
      display: block;
      padding-left: 0.53125rem;
      padding-right: 0.53125rem; }
      @media print, screen and (min-width: 40em) {
        .category-usp-section .usp-section ul li {
          padding-left: 0.625rem; } }
      @media print, screen and (min-width: 40em) {
        .category-usp-section .usp-section ul li {
          padding-right: 0.625rem; } }
      .category-usp-section .usp-section ul li a,
      .category-usp-section .usp-section ul li span {
        min-height: 100%;
        padding: 1rem .25rem;
        text-align: center;
        font-family: "silka-regular-webfont", sans-serif;
        font-weight: 400;
        font-size: 0.8125rem;
        background-color: #99a0b1; }
        .category-usp-section .usp-section ul li a:before,
        .category-usp-section .usp-section ul li span:before {
          display: inline-block;
          vertical-align: middle;
          position: relative;
          top: auto;
          left: auto;
          margin-right: 2px;
          background-size: 12px auto; }
        @media print, screen and (min-width: 48em) {
          .category-usp-section .usp-section ul li a,
          .category-usp-section .usp-section ul li span {
            padding: 1.25rem 1rem; } }
        @media screen and (min-width: 64em) {
          .category-usp-section .usp-section ul li a,
          .category-usp-section .usp-section ul li span {
            padding: 1.25rem 3rem; } }
        @media screen and (min-width: 75em) {
          .category-usp-section .usp-section ul li a,
          .category-usp-section .usp-section ul li span {
            padding: 1.25rem .25rem;
            font-size: 1rem; } }

.convert-label-container {
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  z-index: 3;
  display: block;
  font-weight: 700;
  line-height: normal;
  width: 5rem;
  height: 2.5rem !important; }
  @media screen and (min-width: 64em) {
    .convert-label-container {
      width: 5rem;
      top: 0 !important;
      right: 0 !important;
      height: 2.5rem !important; } }
  .convert-label-container .convert-label {
    width: 100%;
    height: 100%;
    border-radius: 0;
    text-align: center;
    font-family: "silka-regular-webfont", sans-serif;
    line-height: 2.5rem; }
    @media screen and (min-width: 64em) {
      .convert-label-container .convert-label {
        line-height: 2.5rem; } }
    .convert-label-container .convert-label-discount {
      background: #1A2746;
      font-size: 0.875rem; }
      .convert-label-container .convert-label-discount .convert-label-text {
        color: white; }
        .convert-label-container .convert-label-discount .convert-label-text b,
        .convert-label-container .convert-label-discount .convert-label-text strong {
          font-size: 0.875rem;
          font-family: "silka-regular-webfont", sans-serif;
          font-weight: 700; }
          .checkout-cart-index .cart-container .convert-label-container .convert-label-discount .convert-label-text b,
          .courses-list .convert-label-container .convert-label-discount .convert-label-text b,
          .table-order-items .convert-label-container .convert-label-discount .convert-label-text b,
          .form-wishlist-items .convert-label-container .convert-label-discount .convert-label-text b,
          .add-to-cart-popup .convert-label-container .convert-label-discount .convert-label-text b, .checkout-cart-index .cart-container
          .convert-label-container .convert-label-discount .convert-label-text strong,
          .courses-list
          .convert-label-container .convert-label-discount .convert-label-text strong,
          .table-order-items
          .convert-label-container .convert-label-discount .convert-label-text strong,
          .form-wishlist-items
          .convert-label-container .convert-label-discount .convert-label-text strong,
          .add-to-cart-popup
          .convert-label-container .convert-label-discount .convert-label-text strong {
            font-size: 1.25rem; }
        .convert-label-container .convert-label-discount .convert-label-text span {
          display: none;
          font-size: 0.8125rem;
          font-family: "silka-regular-webfont", sans-serif;
          font-weight: 400;
          color: inherit;
          margin: .5rem auto 0;
          max-width: 75%;
          white-space: nowrap;
          text-transform: none; }
    .convert-label-container .convert-label-is_new {
      text-transform: none;
      background: #181818;
      font-size: 1.125rem; }
      .convert-label-container .convert-label-is_new .convert-label-text {
        color: white; }

.product.media .convert-label-container {
  z-index: 2;
  top: 0 !important;
  right: 17px !important; }
  @media print, screen and (min-width: 40em) {
    .product.media .convert-label-container {
      right: 20px !important; } }

.mm-hidden {
  display: none !important; }

.mm-wrapper {
  overflow-x: hidden;
  position: relative; }

.mm-menu {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: #1A2746;
  border-color: rgba(255, 255, 255, 0.3);
  color: #1A2746;
  line-height: 24px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .mm-menu a,
  .mm-menu a:link,
  .mm-menu a:active,
  .mm-menu a:visited,
  .mm-menu a:hover {
    text-decoration: none;
    color: inherit; }

[dir="rtl"] .mm-menu {
  direction: rtl; }

.mm-panel {
  z-index: 0;
  box-sizing: border-box;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  color: #1A2746;
  border-color: rgba(255, 255, 255, 0.3);
  background: #1A2746;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.4s ease; }
  .mm-panel:after {
    height: 52px; }
  .mm-panel:not(.mm-hidden) {
    display: block; }
  .mm-panel:after {
    content: '';
    display: block;
    height: 35px; }
  .mm-panel_opened {
    z-index: 1;
    transform: translate3d(0%, 0, 0); }
  .mm-panel_opened-parent {
    transform: translate3d(0%, 0, 0); }
  .mm-panel_highest {
    z-index: 2; }
  .mm-panel_noanimation {
    transition: none !important; }
    .mm-panel_noanimation.mm-panel_opened-parent {
      transform: translate3d(0, 0, 0); }
  .mm-panels > .mm-panel {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }

.mm-panel__content {
  padding: 20px 20px 0; }

.mm-panels {
  position: relative;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
  background: #1A2746;
  border-color: rgba(255, 255, 255, 0.3);
  color: #1A2746; }

[dir='rtl'] .mm-panel:not(.mm-panel_opened) {
  transform: translate3d(-100%, 0, 0); }

[dir='rtl'] .mm-panel.mm-panel_opened-parent {
  transform: translate3d(30%, 0, 0); }

.mm-listitem_vertical > .mm-panel {
  display: none;
  width: 100%;
  padding: 10px 0 10px 10px;
  transform: none !important; }
  .mm-listitem_vertical > .mm-panel:before, .mm-listitem_vertical > .mm-panel:after {
    content: none;
    display: none; }

.mm-listitem_opened > .mm-panel {
  display: block; }

.mm-listitem_vertical > .mm-listitem__btn {
  height: 52px;
  bottom: auto; }

.mm-listitem_vertical .mm-listitem:last-child:after {
  border-color: transparent; }

.mm-listitem_opened > .mm-listitem__btn:after {
  transform: rotate(225deg);
  right: 27px; }

.mm-btn {
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 0; }
  .mm-btn:before, .mm-btn:after {
    border: 2px solid rgba(255, 255, 255, 0.5); }
  .mm-btn_prev:before, .mm-btn_prev:after {
    border: 2px solid rgba(255, 255, 255, 0.5); }

.mm-btn_prev:before, .mm-btn_next:after {
  content: '';
  border-bottom: none;
  border-right: none;
  box-sizing: content-box;
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0.5; }

.mm-btn_prev {
  width: 50px; }

.mm-btn_prev:before {
  transform: rotate(-45deg);
  left: 35px;
  right: auto;
  width: 15px;
  height: 15px;
  opacity: 1;
  top: 0; }

.mm-btn_next:after {
  transform: rotate(135deg);
  right: 35px;
  left: auto; }

.mm-btn_close:before, .mm-btn_close:after {
  content: '';
  box-sizing: content-box;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  transform: rotate(-45deg); }

.mm-btn_close:before {
  border-right: none;
  border-bottom: none;
  right: 26px; }

.mm-btn_close:after {
  border-left: none;
  border-top: none;
  right: 33px; }

[dir="rtl"] .mm-btn_next:after {
  transform: rotate(-45deg);
  left: 35px;
  right: auto; }

[dir="rtl"] .mm-btn_prev:before {
  transform: rotate(135deg);
  right: 35px;
  left: auto; }

[dir="rtl"] .mm-btn_close:before, [dir="rtl"] .mm-btn_close:after {
  right: auto; }

[dir="rtl"] .mm-btn_close:before {
  left: 33px; }

[dir="rtl"] .mm-btn_close:after {
  left: 26px; }

.mm-navbar {
  display: flex;
  min-height: 104px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  background: #1A2746;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  opacity: 1;
  transition: opacity 0.4s ease; }
  .mm-navbar > * {
    min-height: 104px; }
  @supports (position: sticky) {
    .mm-navbar_sticky {
      position: sticky;
      top: 0;
      z-index: 1; } }
  .mm-navbar > * {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box; }

.mm-navbar__btn {
  flex-grow: 0; }

.mm-navbar__title {
  flex: 1 1 50%;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  font-family: "silka-regular-webfont", sans-serif;
  color: #181818;
  line-height: normal; }
  .mm-navbar__title:not(:last-child) {
    padding-right: 0; }
  .mm-navbar__title > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    position: relative;
    top: 0; }
  .mm-navbar__btn:not(.mm-hidden) + .mm-navbar__title {
    padding-left: 0; }
    .mm-navbar__btn:not(.mm-hidden) + .mm-navbar__title:last-child {
      padding-right: 50px; }

[dir='rtl'] .mm-navbar {
  flex-direction: row-reverse; }

.mm-listview {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0; }
  .mm-listview .divider {
    margin-bottom: 27px; }
    .mm-listview .divider:after {
      display: none; }

.mm-listitem {
  color: #1A2746;
  border-color: rgba(255, 255, 255, 0.3);
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  .mm-listitem:after {
    content: '';
    border-color: inherit;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    background: none;
    border-radius: 0; }
  .mm-listitem a,
  .mm-listitem a:hover {
    text-decoration: none; }
  .mm-listitem.extra-links:after {
    display: none; }

.mm-listitem__text, .mm-listitem__btn {
  display: block;
  padding: 14px;
  padding-left: 0;
  padding-right: 0;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-family: "silka-regular-webfont", sans-serif;
  line-height: normal; }

.mm-listitem__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 28px;
  padding-right: 10px;
  flex-grow: 1; }

.mm-listitem__btn {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.extra-links .mm-listitem__text, .extra-links
.mm-listitem__btn {
  font-size: 17px;
  color: rgba(24, 24, 24, 0.8); }

.mm-listitem:active > .mm-listitem__text,
.mm-listitem:active > .mm-listitem__text:visited, .mm-listitem.active:active > .mm-listitem__text,
.mm-listitem.active:active > .mm-listitem__text:visited, .mm-listitem_selected > .mm-listitem__text,
.mm-listitem_selected > .mm-listitem__text:visited {
  color: white;
  background: #1A2746; }

.mm-listitem:active > .mm-listitem__btn:after, .mm-listitem:active > .mm-listitem__btn:before, .mm-listitem.active:active > .mm-listitem__btn:after, .mm-listitem.active:active > .mm-listitem__btn:before, .mm-listitem_selected > .mm-listitem__btn:after, .mm-listitem_selected > .mm-listitem__btn:before {
  border-color: white; }

.mm-listitem.active > .mm-listitem__text, .mm-listitem_opened > .mm-listitem__text {
  color: inherit;
  background: inherit; }

.mm-listitem.active > .mm-listitem__btn:after, .mm-listitem.active > .mm-listitem__btn:before, .mm-listitem_opened > .mm-listitem__btn:after, .mm-listitem_opened > .mm-listitem__btn:before {
  border-color: rgba(255, 255, 255, 0.5); }

[dir="rtl"] .mm-listitem:after {
  left: 0;
  right: 28px; }

[dir="rtl"] .mm-listitem__text {
  padding-left: 10px;
  padding-right: 28px; }

[dir="rtl"] .mm-listitem__btn {
  padding-left: 100%; }
  [dir="rtl"] .mm-listitem__btn:not(.mm-listitem__text) {
    padding-right: 0; }

.mm-sronly {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  min-width: 1px !important;
  height: 1px !important;
  min-height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important; }

.mm-page {
  box-sizing: border-box;
  position: relative; }

.mm-slideout {
  transition: transform 0.4s ease; }
  @media screen and (max-width: 47.9375em) {
    .mm-slideout {
      z-index: 1; } }

.mm-wrapper_opened {
  overflow-x: hidden;
  position: relative; }
  .mm-wrapper_opened .mm-page {
    min-height: 100vh; }

.mm-wrapper_background .mm-page {
  background: inherit; }

.mm-menu_offcanvas {
  position: fixed;
  right: auto;
  z-index: 2; }
  .mm-menu_offcanvas:not(.mm-menu_opened) {
    display: none; }

.mm-menu_offcanvas {
  width: 80%;
  min-width: 240px;
  max-width: 440px; }

.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
  transform: translate3d(80vw, 0, 0); }

@media all and (max-width: 300px) {
  .mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
    transform: translate3d(240px, 0, 0); } }

@media all and (min-width: 550px) {
  .mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
    transform: translate3d(440px, 0, 0); } }

.mm-wrapper__blocker {
  background: rgba(3, 2, 1, 0);
  overflow: hidden;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2; }

.mm-wrapper_blocking {
  overflow: hidden; }
  .mm-wrapper_blocking body {
    overflow: hidden; }
  .mm-wrapper_blocking .mm-wrapper__blocker {
    display: block; }

.mm-offcanvas-close {
  width: 61px;
  height: 104px;
  background-image: url("../images/icon-close--white.svg");
  background-size: 21px auto;
  background-repeat: no-repeat;
  background-position: 14px 50%;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2; }
  .mm-offcanvas-close span {
    font-size: 13px;
    font-weight: normal;
    font-family: inherit;
    line-height: normal; }
  .mm-offcanvas-close span {
    display: none; }

.mm-offcanvas-links {
  display: block !important;
  position: static !important;
  transform: translate3d(0%, 0, 0);
  margin-top: 35px; }
  .mm-offcanvas-links .mm-navbar {
    display: none; }
  .mm-offcanvas-links .offcanvas-links {
    padding: 0 0 0 28px;
    border-bottom: none; }
    .mm-offcanvas-links .offcanvas-links h3,
    .mm-offcanvas-links .offcanvas-links .title {
      font-size: 24px;
      font-family: inherit;
      font-weight: inherit;
      color: inherit;
      margin-bottom: 10px;
      text-transform: none;
      display: none; }
    .mm-offcanvas-links .offcanvas-links ul {
      font-size: 15px;
      font-family: "silka-regular-webfont", sans-serif;
      font-weight: normal;
      margin: 0;
      list-style: none; }
      .mm-offcanvas-links .offcanvas-links ul li {
        padding: 0; }
        .mm-offcanvas-links .offcanvas-links ul li:after {
          display: none; }
      .mm-offcanvas-links .offcanvas-links ul li {
        padding: 0 0 17px; }
        .mm-offcanvas-links .offcanvas-links ul li a {
          color: inherit;
          text-decoration: none;
          text-transform: none; }
          .mm-offcanvas-links .offcanvas-links ul li a:hover {
            color: inherit; }
        .mm-offcanvas-links .offcanvas-links ul li strong {
          font-weight: inherit; }
        .mm-offcanvas-links .offcanvas-links ul li.current {
          color: inherit; }
  .mm-offcanvas-links .top-links {
    display: flex;
    flex-direction: column-reverse; }

.mm-menu .menu > li {
  display: block; }
  .mm-menu .menu > li > a {
    padding-left: 28px;
    padding-right: 10px;
    line-height: inherit; }

.mm-menu .menu .active > a {
  background: inherit; }

.mm-listitem:after {
  top: auto; }

[dir="rtl"] .mm-menu_offcanvas {
  /*left: unset;
		right: 0;*/ }

.mm-menu_fullscreen {
  width: 100%;
  min-width: 140px;
  max-width: 10000px; }

.mm-wrapper_opening .mm-menu_fullscreen.mm-menu_opened ~ .mm-slideout {
  transform: translate3d(100vw, 0, 0); }

@media all and (max-width: 140px) {
  .mm-wrapper_opening .mm-menu_fullscreen.mm-menu_opened ~ .mm-slideout {
    transform: translate3d(140px, 0, 0); } }

@media all and (min-width: 10000px) {
  .mm-wrapper_opening .mm-menu_fullscreen.mm-menu_opened ~ .mm-slideout {
    transform: translate3d(10000px, 0, 0); } }

.mm-wrapper_opening .mm-menu_fullscreen.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
  transform: translate3d(-100vw, 0, 0); }

@media all and (max-width: 140px) {
  .mm-wrapper_opening .mm-menu_fullscreen.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    transform: translate3d(-140px, 0, 0); } }

@media all and (min-width: 10000px) {
  .mm-wrapper_opening .mm-menu_fullscreen.mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
    transform: translate3d(-10000px, 0, 0); } }

.mm-menu_fullscreen.mm-menu_position-top {
  height: 100vh;
  min-height: 140px;
  max-height: 10000px; }

.mm-menu_fullscreen.mm-menu_position-bottom {
  height: 100vh;
  min-height: 140px;
  max-height: 10000px; }

.is-not-sticky .header .header-row {
  position: relative; }

#off-canvas-header {
  display: none; }

.main-menu {
  outline: none; }
  .main-menu__list {
    list-style: none;
    margin: 0;
    display: flex;
    padding-left: 13px; }
    @media screen and (min-width: 64em) {
      .main-menu__list {
        padding-left: 5px; } }
  .main-menu li.level0 {
    margin-left: 7px;
    margin-right: 7px;
    padding: 0; }
    @media screen and (min-width: 64em) {
      .main-menu li.level0 {
        margin-left: 15px;
        margin-right: 15px; } }
    .main-menu li.level0.active > .level0__title:before,
    .main-menu li.level0.active > .wrapper__title:before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -20px;
      transform: translateX(-50%);
      z-index: 1;
      margin-left: -12px;
      border-bottom: 12px solid white;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent; }
  .main-menu .main-menu__link,
  .main-menu .level0__title,
  .main-menu li.level0 > .wrapper__title,
  .main-menu li.level0 > a {
    font-family: "silka-regular-webfont", sans-serif;
    color: #181818;
    display: block;
    position: relative;
    font-size: 26px; }
    @media screen and (min-width: 64em) {
      .main-menu .main-menu__link,
      .main-menu .level0__title,
      .main-menu li.level0 > .wrapper__title,
      .main-menu li.level0 > a {
        font-size: 20px; } }
  .main-menu .level0__title,
  .main-menu li.level0 > .wrapper__title {
    padding-right: 20px;
    cursor: default; }
    .main-menu .level0__title:after,
    .main-menu li.level0 > .wrapper__title:after {
      content: "";
      position: absolute;
      width: 20px;
      height: 24px;
      right: 0;
      top: 1px;
      background-image: url("../images/icon-menu-arrow.svg");
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 10px auto; }
      @media screen and (min-width: 64em) {
        .main-menu .level0__title:after,
        .main-menu li.level0 > .wrapper__title:after {
          height: 30px; } }
    .main-menu .level0__title span,
    .main-menu li.level0 > .wrapper__title span {
      display: block;
      color: inherit;
      user-select: none;
      white-space: nowrap; }
  .main-menu ul {
    list-style: none;
    margin: 0; }
  .is-sticky .header .header-row .main-menu .submenu a {
    color: #181818; }
  .main-menu__submenu.level0 li {
    padding: 0;
    line-height: 1.4;
    margin-bottom: 9px; }
    .main-menu__submenu.level0 li.level2 > a {
      margin: 0 0 9px; }
  .main-menu__submenu.level0 a {
    display: block; }
  .main-menu__submenu.level0 > li.level1 {
    flex-basis: 33.33333%;
    font-size: 0.8125rem;
    margin-bottom: 25px;
    padding: 0 10px; }
    @media screen and (min-width: 64em) {
      .main-menu__submenu.level0 > li.level1 {
        flex-basis: 25%;
        font-size: 0.9375rem;
        margin-bottom: 50px;
        padding: 0; } }
    .main-menu__submenu.level0 > li.level1 > a {
      display: inline-block;
      font-family: "silka-regular-webfont", sans-serif;
      line-height: 1.2;
      font-size: 16px;
      margin: 0 0 12px; }
      @media screen and (min-width: 75em) {
        .main-menu__submenu.level0 > li.level1 > a {
          font-size: 24px; } }
      @media screen and (min-width: 75em) {
        .main-menu__submenu.level0 > li.level1 > a {
          margin: 0 0 20px; } }
  .main-menu__submenu.level0 {
    position: absolute;
    left: 0;
    right: 0;
    background-color: white;
    display: none;
    margin: 20px 0 0;
    flex-wrap: wrap;
    box-shadow: 4px 4px 16px rgba(24, 24, 24, 0.2);
    padding: 20px; }
    @media screen and (min-width: 64em) {
      .main-menu__submenu.level0 {
        padding: 30px 50px; } }
  .main-menu a {
    color: #181818; }

span.preview-uploaded-image {
  background-repeat: no-repeat;
  display: inline-block;
  width: 150px;
  height: 150px; }

span.preview-type-bmp {
  background-image: url("../images/preview-bmp.png"); }

span.preview-type-unknown {
  background-image: url("../images/preview-unknown.png"); }

span.preview-type-svg {
  background-image: url("../images/preview-svg.png"); }

span.preview-type-ai {
  background-image: url("../images/preview-ai.png"); }

span.preview-type-tiff {
  background-image: url("../images/preview-tiff.png"); }

span.preview-type-eps {
  background-image: url("../images/preview-eps.png"); }

span.preview-type-ps {
  background-image: url("../images/preview-ps.png"); }

span.preview-type-pdf {
  background-image: url("../images/preview-pdf.png"); }

.add-to-cart-popup {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  display: none; }
  .add-to-cart-popup::before, .add-to-cart-popup::after {
    display: table;
    content: ' '; }
  .add-to-cart-popup::after {
    clear: both; }
  [data-whatinput='mouse'] .add-to-cart-popup {
    outline: 0; }
  .add-to-cart-popup .add-popup-container {
    position: absolute; }
    @media screen and (max-width: 47.9375em) {
      .add-to-cart-popup .add-popup-container {
        left: 0 !important;
        right: 0; } }
    @media print, screen and (min-width: 48em) {
      .add-to-cart-popup .add-popup-container {
        width: 43.75rem; }
        .add-to-cart-popup .add-popup-container .close-button {
          display: none; } }
  .add-to-cart-popup .add-popup-showcart {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    padding-right: 0 !important; }
    @media print, screen and (min-width: 48em) {
      .add-to-cart-popup .add-popup-showcart {
        display: block; } }
  .add-to-cart-popup .add-popup-block {
    position: relative;
    padding: 1.25rem;
    text-align: center;
    background: white; }
    @media print, screen and (min-width: 48em) {
      .add-to-cart-popup .add-popup-block {
        margin-top: 4.8125rem;
        padding: 2.5rem 3rem 8rem; } }
  .add-to-cart-popup .add-popup-message {
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-weight: 300;
    line-height: 1;
    letter-spacing: normal;
    font-size: 1.25rem;
    margin-bottom: 1.5rem; }
    .add-to-cart-popup .add-popup-message a {
      display: inline-block;
      text-decoration: none;
      max-width: 6.25rem; }
    .add-to-cart-popup .add-popup-message span {
      display: block;
      margin-top: 1.5rem; }
    @media print, screen and (min-width: 48em) {
      .add-to-cart-popup .add-popup-message span {
        display: inline-block;
        margin-top: 0;
        margin-left: 1rem; } }
    @media screen and (min-width: 75em) {
      .add-to-cart-popup .add-popup-message {
        font-size: 2rem; }
        .add-to-cart-popup .add-popup-message span {
          margin-left: 2.5rem; } }
  .add-to-cart-popup .add-popup-product-image {
    position: relative; }
  .add-to-cart-popup .add-popup-actions {
    overflow: hidden; }
    .add-to-cart-popup .add-popup-actions .button {
      display: block;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: .5rem; }
    .add-to-cart-popup .add-popup-actions .btn-viewcart {
      background-color: #1A2746;
      color: #fff; }
      .add-to-cart-popup .add-popup-actions .btn-viewcart:hover, .add-to-cart-popup .add-popup-actions .btn-viewcart:focus {
        background-color: rgba(48, 169, 94, 0.75);
        color: #fff; }
    @media print, screen and (min-width: 48em) {
      .add-to-cart-popup .add-popup-actions {
        padding: 2rem 2.5rem;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #F1EFED; }
        .add-to-cart-popup .add-popup-actions .button {
          display: inline-block;
          width: auto;
          min-width: 45%;
          padding: 0.85em 2em 0.9em;
          margin-bottom: 0;
          position: relative; }
          .add-to-cart-popup .add-popup-actions .button:after {
            display: block;
            content: '';
            width: 7px;
            height: 12px;
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            background-image: url("../images/icon-arrow-next-white.svg");
            background-repeat: no-repeat;
            background-size: 100% auto; }
          .add-to-cart-popup .add-popup-actions .button.btn-continue:after {
            opacity: .4;
            right: auto;
            left: 1rem;
            transform: translateY(-50%) rotate(180deg); }
        .add-to-cart-popup .add-popup-actions .btn-viewcart {
          float: right; }
        .add-to-cart-popup .add-popup-actions .btn-continue {
          float: left; } }
    @media screen and (min-width: 75em) {
      .add-to-cart-popup .add-popup-actions .button {
        min-width: 13.75rem; } }
  .add-to-cart-popup .block.grid {
    margin: 0; }
    .add-to-cart-popup .block.grid .block-title strong {
      font-size: 1rem; }
  @media print, screen and (min-width: 48em) {
    .add-to-cart-popup .product-items {
      display: flex; } }
  .add-to-cart-popup .product-items .product-item {
    width: 50%;
    margin: 1rem 0; }
    @media print, screen and (min-width: 48em) {
      .add-to-cart-popup .product-items .product-item {
        width: 33.33333%;
        margin: 0; }
        .add-to-cart-popup .product-items .product-item:last-child {
          display: none; } }
    .add-to-cart-popup .product-items .product-item:before {
      top: 0;
      bottom: 0; }
    .add-to-cart-popup .product-items .product-item .product-item-photo {
      margin-bottom: .5rem; }
      .add-to-cart-popup .product-items .product-item .product-item-photo .product-image-wrapper {
        display: block;
        height: 7.5rem;
        line-height: 7.5rem; }
        .add-to-cart-popup .product-items .product-item .product-item-photo .product-image-wrapper img {
          max-height: 100%; }
    .add-to-cart-popup .product-items .product-item .product-item-name {
      font-weight: 400;
      line-height: 1.2;
      font-size: 1rem;
      margin-bottom: .5rem; }
  .add-to-cart-popup .product-items .product-item-details {
    padding-left: .75rem;
    padding-right: .75rem; }
  .add-to-cart-popup .product-items .product-price-stock {
    margin-bottom: 0; }
  .add-to-cart-popup .product-items .product-price-stock .stock,
  .add-to-cart-popup .product-items .product-item-actions {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }

.reveal-overlay {
  background: transparent; }
  .reveal-overlay .add-popup-container {
    width: 100%; }
    @media print, screen and (min-width: 48em) {
      .reveal-overlay .add-popup-container {
        width: 24.6875rem; } }
    .reveal-overlay .add-popup-container .add-popup-actions {
      display: none; }
  .reveal-overlay .add-popup-block {
    padding: 0;
    background: transparent; }
  .reveal-overlay .add-popup-message {
    margin-bottom: 0;
    font-size: 0;
    text-align: left;
    padding: 0.75rem;
    position: relative;
    box-shadow: 0 0 25px rgba(24, 24, 24, 0.15);
    background: white; }
    .reveal-overlay .add-popup-message:after {
      display: none;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 1rem 1rem 1rem;
      border-color: transparent transparent white transparent;
      position: absolute;
      bottom: 100%;
      right: 2.75rem;
      transform: translateX(-50%);
      right: 0;
      transform: translateX(0); }
      body.is-sticky .reveal-overlay .add-popup-message:after {
        right: 0;
        transform: translateX(0); }
      @media print, screen and (min-width: 48em) {
        .reveal-overlay .add-popup-message:after {
          display: block; } }
    .reveal-overlay .add-popup-message .add-popup-product-image {
      display: inline-block;
      vertical-align: middle;
      width: 33%;
      max-width: inherit;
      text-align: center;
      height: 9.375rem; }
      .reveal-overlay .add-popup-message .add-popup-product-image:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        margin-right: -.3em; }
      .reveal-overlay .add-popup-message .add-popup-product-image img {
        max-width: 98%;
        display: inline-block;
        vertical-align: middle; }
    .reveal-overlay .add-popup-message > .txt {
      width: 67%;
      display: inline-block;
      vertical-align: middle;
      padding-left: 1rem;
      padding-right: 1rem;
      text-transform: uppercase;
      font-weight: 400;
      line-height: 1.3;
      margin: 0;
      text-align: center;
      font-size: 1rem; }
      .reveal-overlay .add-popup-message > .txt a {
        display: inline;
        text-decoration: underline; }
        .reveal-overlay .add-popup-message > .txt a:hover, .reveal-overlay .add-popup-message > .txt a:active {
          text-decoration: none; }

.column.main .ias-trigger, .main.columns .ias-trigger,
.column.main .ias-spinner,
.main.columns .ias-spinner {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.82em 1em 0.87em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 50px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 1.1875rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #30A95E;
  color: #fff;
  font-family: "silka-semibold-webfont", sans-serif;
  letter-spacing: normal;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 0;
  background-color: #1A2746;
  color: #fff;
  border: 1px solid #7891cd;
  color: #1A2746;
  box-shadow: inset 0 0 0 1px #7891cd;
  transition: background-color 0.25s ease-out, box-shadow 0.25s ease-out, color 0.25s ease-out;
  display: block;
  max-width: 15.625rem;
  margin-left: auto;
  margin-right: auto;
  clear: both; }
  [data-whatinput='mouse'] .column.main .ias-trigger, [data-whatinput='mouse'] .main.columns .ias-trigger, [data-whatinput='mouse'] .column.main .ias-spinner, [data-whatinput='mouse'] .main.columns .ias-spinner {
    outline: 0; }
  .column.main .ias-trigger:hover, .main.columns .ias-trigger:hover, .column.main .ias-trigger:focus, .main.columns .ias-trigger:focus,
  .column.main .ias-spinner:hover,
  .main.columns .ias-spinner:hover,
  .column.main .ias-spinner:focus,
  .main.columns .ias-spinner:focus {
    background-color: rgba(48, 169, 94, 0.75);
    color: #fff; }
  .column.main .ias-trigger:active, .main.columns .ias-trigger:active,
  .column.main .ias-spinner:active,
  .main.columns .ias-spinner:active {
    color: rgba(255, 255, 255, 0.8) !important; }
  .column.main .ias-trigger:hover, .main.columns .ias-trigger:hover, .column.main .ias-trigger:focus, .main.columns .ias-trigger:focus,
  .column.main .ias-spinner:hover,
  .main.columns .ias-spinner:hover,
  .column.main .ias-spinner:focus,
  .main.columns .ias-spinner:focus {
    background-color: rgba(48, 169, 94, 0.75);
    color: #fff; }
  .column.main .ias-trigger, .main.columns .ias-trigger, .column.main .ias-trigger:hover, .main.columns .ias-trigger:hover, .column.main .ias-trigger:focus, .main.columns .ias-trigger:focus,
  .column.main .ias-spinner,
  .main.columns .ias-spinner,
  .column.main .ias-spinner:hover,
  .main.columns .ias-spinner:hover,
  .column.main .ias-spinner:focus,
  .main.columns .ias-spinner:focus {
    background-color: transparent; }
  .column.main .ias-trigger:hover, .main.columns .ias-trigger:hover, .column.main .ias-trigger:focus, .main.columns .ias-trigger:focus,
  .column.main .ias-spinner:hover,
  .main.columns .ias-spinner:hover,
  .column.main .ias-spinner:focus,
  .main.columns .ias-spinner:focus {
    border-color: #2c4277;
    color: #0d1423;
    box-shadow: inset 0 0 0 1px #2c4277; }
  .column.main .ias-trigger a, .main.columns .ias-trigger a,
  .column.main .ias-spinner a,
  .main.columns .ias-spinner a {
    text-decoration: none;
    color: #1A2746; }

.column.main .ias-spinner span:before, .main.columns .ias-spinner span:before,
.column.main .ias-spinner a:before,
.main.columns .ias-spinner a:before,
.column.main .ias-spinner em:before,
.main.columns .ias-spinner em:before {
  display: inline-block;
  vertical-align: middle;
  content: "";
  width: 18px;
  height: 18px;
  margin-right: 2px;
  margin-top: -5px;
  background: url("../images/ripple.svg") no-repeat 50%;
  background-size: 100% auto; }
  .ie11 .column.main .ias-spinner span:before, .ie11 .main.columns .ias-spinner span:before,
  .no-smil .column.main .ias-spinner span:before,
  .no-smil .main.columns .ias-spinner span:before, .ie11 .column.main .ias-spinner a:before, .ie11 .main.columns .ias-spinner a:before,
  .no-smil .column.main .ias-spinner a:before,
  .no-smil .main.columns .ias-spinner a:before, .ie11 .column.main .ias-spinner em:before, .ie11 .main.columns .ias-spinner em:before,
  .no-smil .column.main .ias-spinner em:before,
  .no-smil .main.columns .ias-spinner em:before {
    background-image: url("../images/ripple.gif"); }

.column.main .ias-spinner span span:before, .main.columns .ias-spinner span span:before,
.column.main .ias-spinner span a:before,
.main.columns .ias-spinner span a:before,
.column.main .ias-spinner span em:before,
.main.columns .ias-spinner span em:before,
.column.main .ias-spinner a span:before,
.main.columns .ias-spinner a span:before,
.column.main .ias-spinner a a:before,
.main.columns .ias-spinner a a:before,
.column.main .ias-spinner a em:before,
.main.columns .ias-spinner a em:before,
.column.main .ias-spinner em span:before,
.main.columns .ias-spinner em span:before,
.column.main .ias-spinner em a:before,
.main.columns .ias-spinner em a:before,
.column.main .ias-spinner em em:before,
.main.columns .ias-spinner em em:before {
  display: none; }

.column.main .ias-spinner img, .main.columns .ias-spinner img {
  display: none !important; }

.product-info-main .similar.product-info-form-row {
  clear: both;
  margin: 0 0 3rem; }
  .product-info-main .similar.product-info-form-row .product-options-wrapper {
    display: flex;
    flex-wrap: wrap; }
  .product-info-main .similar.product-info-form-row .swatch-attribute-label {
    flex-basis: 100%; }
  .product-info-main .similar.product-info-form-row .product {
    width: 70px;
    height: 80px;
    margin: 0 0.25rem 0.5rem 0;
    text-align: center;
    box-shadow: 0 0 10px transparent;
    transition: box-shadow .3s ease;
    background-color: white;
    border: 2px solid transparent;
    border-radius: 4px;
    position: relative; }
    .product-info-main .similar.product-info-form-row .product:hover {
      box-shadow: 0 0 13px rgba(24, 24, 24, 0.3); }
    .product-info-main .similar.product-info-form-row .product--current {
      border-color: rgba(26, 39, 70, 0.9); }
    .product-info-main .similar.product-info-form-row .product__info {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0 0 10px; }
      .product-info-main .similar.product-info-form-row .product__info .product-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 75px; }
    .product-info-main .similar.product-info-form-row .product__variation {
      color: rgba(24, 24, 24, 0.7);
      font-weight: normal;
      font-size: 13px;
      line-height: 1.15385;
      margin: 8px 8px 0;
      min-height: 36px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .product-info-main .similar.product-info-form-row .product .product-label,
    .product-info-main .similar.product-info-form-row .product .convert-label-container {
      display: none !important; }
    .product-info-main .similar.product-info-form-row .product .product-image-container {
      width: auto !important; }
    .product-info-main .similar.product-info-form-row .product .product-image-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important; }
  .product-info-main .similar.product-info-form-row .product-availability {
    position: absolute;
    left: 0;
    right: 0;
    margin: 8px 0 0; }
    .product-info-main .similar.product-info-form-row .product-availability .stock {
      margin: 0; }

.convert-block.aligned--center {
  text-align: center; }

.convert-block.aligned--right {
  text-align: right; }

.as-h1,
.as-h2,
.as-h3,
.as-h4,
.as-h5,
.as-h6 {
  font-family: "silka-regular-webfont", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: inherit;
  text-rendering: optimizeLegibility; }
  .as-h1 small,
  .as-h2 small,
  .as-h3 small,
  .as-h4 small,
  .as-h5 small,
  .as-h6 small {
    line-height: 0;
    color: rgba(0, 0, 0, 0.7); }

h1,
.as-h1 {
  font-size: 1.75rem;
  line-height: 1.21429;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2,
.as-h2 {
  font-size: 1.75rem;
  line-height: 1.14286;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3,
.as-h3 {
  font-size: 1.625rem;
  line-height: 1.46154;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4,
.as-h4 {
  font-size: 1.5rem;
  line-height: 1.33333;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5,
.as-h5 {
  font-size: 0.9375rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6,
.as-h6 {
  font-size: 0.8125rem;
  line-height: 1.23077;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1,
  .as-h1 {
    font-size: 2.625rem;
    line-height: 1.19048; }
  h2,
  .as-h2 {
    font-size: 2.125rem;
    line-height: 1.17647; }
  h3,
  .as-h3 {
    font-size: 1.6875rem;
    line-height: 1.44444; }
  h4,
  .as-h4 {
    font-size: 1.5625rem;
    line-height: 1.32; }
  h5,
  .as-h5 {
    font-size: 1.0625rem;
    line-height: 1.23529; }
  h6,
  .as-h6 {
    font-size: 0.9375rem;
    line-height: 1.2; } }

@media screen and (min-width: 64em) {
  h1,
  .as-h1 {
    font-size: 2.875rem;
    line-height: 1.45652; }
  h2,
  .as-h2 {
    font-size: 2.5rem;
    line-height: 1.2; }
  h3,
  .as-h3 {
    font-size: 1.75rem;
    line-height: 1.42857; }
  h4,
  .as-h4 {
    font-size: 1.625rem;
    line-height: 1.30769; }
  h5,
  .as-h5 {
    font-size: 1.1875rem;
    line-height: 1.21053; }
  h6,
  .as-h6 {
    font-size: 1.0625rem;
    line-height: 1.17647; } }

.img-responsive {
  display: inline-block;
  max-width: 100%; }

.convert-column {
  min-height: 1px; }

.row.row-fluid {
  max-width: none; }
  .row.row-fluid .img-responsive {
    margin-left: auto;
    margin-right: auto;
    display: block; }
  @media screen and (min-width: 1900px) {
    .row.row-fluid.convert-grid-row.expanded {
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto; } }

.row .row.gutters-03x {
  margin-left: -6px;
  margin-right: -6px; }

.row.gutters-03x .column, .row.gutters-03x .columns,
.row.gutters-03x .columns {
  padding-left: 6px;
  padding-right: 6px; }

.row .row.gutters-05x {
  margin-left: -10px;
  margin-right: -10px; }

.row.gutters-05x .column, .row.gutters-05x .columns,
.row.gutters-05x .columns {
  padding-left: 10px;
  padding-right: 10px; }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .row .row.gutters-2x {
    margin-left: -12px;
    margin-right: -12px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .row .row.gutters-2x {
    margin-left: -20px;
    margin-right: -20px; } }

@media screen and (min-width: 75em) and (max-width: 90.5625em) {
  .row .row.gutters-2x {
    margin-left: -40px;
    margin-right: -40px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .row.gutters-2x > .column, .row.gutters-2x > .columns,
  .row.gutters-2x > .columns {
    padding-left: 12px;
    padding-right: 12px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .row.gutters-2x > .column, .row.gutters-2x > .columns,
  .row.gutters-2x > .columns {
    padding-left: 20px;
    padding-right: 20px; } }

@media screen and (min-width: 75em) {
  .row.gutters-2x > .column, .row.gutters-2x > .columns,
  .row.gutters-2x > .columns {
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .row .row.gutters-4x {
    margin-left: -24px;
    margin-right: -24px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .row .row.gutters-4x {
    margin-left: -40px;
    margin-right: -40px; } }

@media screen and (min-width: 75em) and (max-width: 90.5625em) {
  .row .row.gutters-4x {
    margin-left: -80px;
    margin-right: -80px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .row.gutters-4x > .column, .row.gutters-4x > .columns,
  .row.gutters-4x > .columns {
    padding-left: 24px;
    padding-right: 24px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .row.gutters-4x > .column, .row.gutters-4x > .columns,
  .row.gutters-4x > .columns {
    padding-left: 40px;
    padding-right: 40px; } }

@media screen and (min-width: 75em) {
  .row.gutters-4x > .column, .row.gutters-4x > .columns,
  .row.gutters-4x > .columns {
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .row.gutters-8x > .column, .row.gutters-8x > .columns,
  .row.gutters-8x > .columns {
    padding-left: 48px;
    padding-right: 48px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .row.gutters-8x > .column, .row.gutters-8x > .columns,
  .row.gutters-8x > .columns {
    padding-left: 80px;
    padding-right: 80px; } }

@media screen and (min-width: 75em) {
  .row.gutters-8x > .column, .row.gutters-8x > .columns,
  .row.gutters-8x > .columns {
    padding-left: 160px;
    padding-right: 160px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .row.gutters-12x > .column, .row.gutters-12x > .columns,
  .row.gutters-12x > .columns {
    padding-left: 72px;
    padding-right: 72px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .row.gutters-12x > .column, .row.gutters-12x > .columns,
  .row.gutters-12x > .columns {
    padding-left: 120px;
    padding-right: 120px; } }

@media screen and (min-width: 75em) {
  .row.gutters-12x > .column, .row.gutters-12x > .columns,
  .row.gutters-12x > .columns {
    padding-left: 240px;
    padding-right: 240px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .row.gutters-16x > .column, .row.gutters-16x > .columns,
  .row.gutters-16x > .columns {
    padding-left: 96px;
    padding-right: 96px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .row.gutters-16x > .column, .row.gutters-16x > .columns,
  .row.gutters-16x > .columns {
    padding-left: 160px;
    padding-right: 160px; } }

@media screen and (min-width: 75em) {
  .row.gutters-16x > .column, .row.gutters-16x > .columns,
  .row.gutters-16x > .columns {
    padding-left: 320px;
    padding-right: 320px; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .row.gutters-20x > .column, .row.gutters-20x > .columns,
  .row.gutters-20x > .columns {
    padding-left: 120px;
    padding-right: 120px; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .row.gutters-20x > .column, .row.gutters-20x > .columns,
  .row.gutters-20x > .columns {
    padding-left: 200px;
    padding-right: 200px; } }

@media screen and (min-width: 75em) {
  .row.gutters-20x > .column, .row.gutters-20x > .columns,
  .row.gutters-20x > .columns {
    padding-left: 400px;
    padding-right: 400px; } }

.as-h1 {
  font-size: 1.75rem;
  line-height: 1.21429;
  margin-top: 0;
  margin-bottom: 0.5rem; }

.as-h2 {
  font-size: 1.75rem;
  line-height: 1.14286;
  margin-top: 0;
  margin-bottom: 0.5rem; }

.as-h3 {
  font-size: 1.625rem;
  line-height: 1.46154;
  margin-top: 0;
  margin-bottom: 0.5rem; }

.as-h4 {
  font-size: 1.5rem;
  line-height: 1.33333;
  margin-top: 0;
  margin-bottom: 0.5rem; }

.as-h5 {
  font-size: 0.9375rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem; }

.as-h6 {
  font-size: 0.8125rem;
  line-height: 1.23077;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  .as-h1 {
    font-size: 2.625rem;
    line-height: 1.19048; }
  .as-h2 {
    font-size: 2.125rem;
    line-height: 1.17647; }
  .as-h3 {
    font-size: 1.6875rem;
    line-height: 1.44444; }
  .as-h4 {
    font-size: 1.5625rem;
    line-height: 1.32; }
  .as-h5 {
    font-size: 1.0625rem;
    line-height: 1.23529; }
  .as-h6 {
    font-size: 0.9375rem;
    line-height: 1.2; } }

@media screen and (min-width: 64em) {
  .as-h1 {
    font-size: 2.875rem;
    line-height: 1.45652; }
  .as-h2 {
    font-size: 2.5rem;
    line-height: 1.2; }
  .as-h3 {
    font-size: 1.75rem;
    line-height: 1.42857; }
  .as-h4 {
    font-size: 1.625rem;
    line-height: 1.30769; }
  .as-h5 {
    font-size: 1.1875rem;
    line-height: 1.21053; }
  .as-h6 {
    font-size: 1.0625rem;
    line-height: 1.17647; } }

.convert-block.spacing-small,
.convert-grid-row.spacing-small,
.convert-grid-row-flex.spacing-small {
  margin-bottom: 0.625rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.spacing-small,
    .convert-grid-row.spacing-small,
    .convert-grid-row-flex.spacing-small {
      margin-bottom: 0.3125rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.spacing-small,
    .convert-grid-row.spacing-small,
    .convert-grid-row-flex.spacing-small {
      margin-bottom: 0.4375rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.spacing-small,
    .convert-grid-row.spacing-small,
    .convert-grid-row-flex.spacing-small {
      margin-bottom: 0.5rem; } }

.convert-block.spacing-medium,
.convert-grid-row.spacing-medium,
.convert-grid-row-flex.spacing-medium {
  margin-bottom: 1.5625rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.spacing-medium,
    .convert-grid-row.spacing-medium,
    .convert-grid-row-flex.spacing-medium {
      margin-bottom: 0.78125rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.spacing-medium,
    .convert-grid-row.spacing-medium,
    .convert-grid-row-flex.spacing-medium {
      margin-bottom: 1.09375rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.spacing-medium,
    .convert-grid-row.spacing-medium,
    .convert-grid-row-flex.spacing-medium {
      margin-bottom: 1.25rem; } }

.convert-block.spacing-large,
.convert-grid-row.spacing-large,
.convert-grid-row-flex.spacing-large {
  margin-bottom: 3.125rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.spacing-large,
    .convert-grid-row.spacing-large,
    .convert-grid-row-flex.spacing-large {
      margin-bottom: 1.5625rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.spacing-large,
    .convert-grid-row.spacing-large,
    .convert-grid-row-flex.spacing-large {
      margin-bottom: 2.1875rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.spacing-large,
    .convert-grid-row.spacing-large,
    .convert-grid-row-flex.spacing-large {
      margin-bottom: 2.5rem; } }

.convert-block.spacing-xlarge,
.convert-grid-row.spacing-xlarge,
.convert-grid-row-flex.spacing-xlarge {
  margin-bottom: 5rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.spacing-xlarge,
    .convert-grid-row.spacing-xlarge,
    .convert-grid-row-flex.spacing-xlarge {
      margin-bottom: 2.5rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.spacing-xlarge,
    .convert-grid-row.spacing-xlarge,
    .convert-grid-row-flex.spacing-xlarge {
      margin-bottom: 3.5rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.spacing-xlarge,
    .convert-grid-row.spacing-xlarge,
    .convert-grid-row-flex.spacing-xlarge {
      margin-bottom: 4rem; } }

.convert-block.spacing-xxlarge,
.convert-grid-row.spacing-xxlarge,
.convert-grid-row-flex.spacing-xxlarge {
  margin-bottom: 7.8125rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.spacing-xxlarge,
    .convert-grid-row.spacing-xxlarge,
    .convert-grid-row-flex.spacing-xxlarge {
      margin-bottom: 3.90625rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.spacing-xxlarge,
    .convert-grid-row.spacing-xxlarge,
    .convert-grid-row-flex.spacing-xxlarge {
      margin-bottom: 5.46875rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.spacing-xxlarge,
    .convert-grid-row.spacing-xxlarge,
    .convert-grid-row-flex.spacing-xxlarge {
      margin-bottom: 6.25rem; } }

.convert-block.spacing-xxxlarge,
.convert-grid-row.spacing-xxxlarge,
.convert-grid-row-flex.spacing-xxxlarge {
  margin-bottom: 10.9375rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.spacing-xxxlarge,
    .convert-grid-row.spacing-xxxlarge,
    .convert-grid-row-flex.spacing-xxxlarge {
      margin-bottom: 5.46875rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.spacing-xxxlarge,
    .convert-grid-row.spacing-xxxlarge,
    .convert-grid-row-flex.spacing-xxxlarge {
      margin-bottom: 7.65625rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.spacing-xxxlarge,
    .convert-grid-row.spacing-xxxlarge,
    .convert-grid-row-flex.spacing-xxxlarge {
      margin-bottom: 8.75rem; } }

@media print, screen and (min-width: 40em) {
  .convert-block.spacing-none .convert-responsive-image, .convert-block.spacing-fixed-03x .convert-responsive-image, .convert-block.spacing-fixed-05x .convert-responsive-image,
  .convert-grid-row.spacing-none .convert-responsive-image,
  .convert-grid-row.spacing-fixed-03x .convert-responsive-image,
  .convert-grid-row.spacing-fixed-05x .convert-responsive-image,
  .convert-grid-row-flex.spacing-none .convert-responsive-image,
  .convert-grid-row-flex.spacing-fixed-03x .convert-responsive-image,
  .convert-grid-row-flex.spacing-fixed-05x .convert-responsive-image {
    margin-bottom: 0; } }

.convert-block.spacing-none,
.convert-grid-row.spacing-none,
.convert-grid-row-flex.spacing-none {
  margin-bottom: 0 !important; }

.convert-block.spacing-fixed-03x,
.convert-grid-row.spacing-fixed-03x,
.convert-grid-row-flex.spacing-fixed-03x {
  margin-bottom: 12px; }

.convert-block.spacing-fixed-05x,
.convert-grid-row.spacing-fixed-05x,
.convert-grid-row-flex.spacing-fixed-05x {
  margin-bottom: 20px; }

.convert-block.padding-default.padding-small,
.convert-grid-row-flex.padding-default.padding-small {
  padding: 0.625rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-default.padding-small,
    .convert-grid-row-flex.padding-default.padding-small {
      padding: 0.3125rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-default.padding-small,
    .convert-grid-row-flex.padding-default.padding-small {
      padding: 0.4375rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-default.padding-small,
    .convert-grid-row-flex.padding-default.padding-small {
      padding: 0.5rem; } }

.convert-block.padding-default.padding-medium,
.convert-grid-row-flex.padding-default.padding-medium {
  padding: 1.5625rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-default.padding-medium,
    .convert-grid-row-flex.padding-default.padding-medium {
      padding: 0.78125rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-default.padding-medium,
    .convert-grid-row-flex.padding-default.padding-medium {
      padding: 1.09375rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-default.padding-medium,
    .convert-grid-row-flex.padding-default.padding-medium {
      padding: 1.25rem; } }

.convert-block.padding-default.padding-large,
.convert-grid-row-flex.padding-default.padding-large {
  padding: 3.125rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-default.padding-large,
    .convert-grid-row-flex.padding-default.padding-large {
      padding: 1.5625rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-default.padding-large,
    .convert-grid-row-flex.padding-default.padding-large {
      padding: 2.1875rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-default.padding-large,
    .convert-grid-row-flex.padding-default.padding-large {
      padding: 2.5rem; } }

.convert-block.padding-default.padding-xlarge,
.convert-grid-row-flex.padding-default.padding-xlarge {
  padding: 5rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-default.padding-xlarge,
    .convert-grid-row-flex.padding-default.padding-xlarge {
      padding: 2.5rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-default.padding-xlarge,
    .convert-grid-row-flex.padding-default.padding-xlarge {
      padding: 3.5rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-default.padding-xlarge,
    .convert-grid-row-flex.padding-default.padding-xlarge {
      padding: 4rem; } }

.convert-block.padding-default.padding-xxlarge,
.convert-grid-row-flex.padding-default.padding-xxlarge {
  padding: 7.8125rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-default.padding-xxlarge,
    .convert-grid-row-flex.padding-default.padding-xxlarge {
      padding: 3.90625rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-default.padding-xxlarge,
    .convert-grid-row-flex.padding-default.padding-xxlarge {
      padding: 5.46875rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-default.padding-xxlarge,
    .convert-grid-row-flex.padding-default.padding-xxlarge {
      padding: 6.25rem; } }

.convert-block.padding-default.padding-xxxlarge,
.convert-grid-row-flex.padding-default.padding-xxxlarge {
  padding: 10.9375rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-default.padding-xxxlarge,
    .convert-grid-row-flex.padding-default.padding-xxxlarge {
      padding: 5.46875rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-default.padding-xxxlarge,
    .convert-grid-row-flex.padding-default.padding-xxxlarge {
      padding: 7.65625rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-default.padding-xxxlarge,
    .convert-grid-row-flex.padding-default.padding-xxxlarge {
      padding: 8.75rem; } }

.convert-block.padding-default.padding-fixed-03x,
.convert-grid-row-flex.padding-default.padding-fixed-03x {
  padding: 6px; }

.convert-block.padding-default.padding-fixed-05x,
.convert-grid-row-flex.padding-default.padding-fixed-05x {
  padding: 10px; }

.convert-block.padding-vertical.padding-small,
.convert-grid-row.padding-vertical.padding-small,
.convert-grid-row-flex.padding-vertical.padding-small {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-vertical.padding-small,
    .convert-grid-row.padding-vertical.padding-small,
    .convert-grid-row-flex.padding-vertical.padding-small {
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.padding-vertical.padding-small,
    .convert-grid-row.padding-vertical.padding-small,
    .convert-grid-row-flex.padding-vertical.padding-small {
      padding-top: 0.4375rem;
      padding-bottom: 0.4375rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-vertical.padding-small,
    .convert-grid-row.padding-vertical.padding-small,
    .convert-grid-row-flex.padding-vertical.padding-small {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem; } }

.convert-block.padding-vertical.padding-medium,
.convert-grid-row.padding-vertical.padding-medium,
.convert-grid-row-flex.padding-vertical.padding-medium {
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-vertical.padding-medium,
    .convert-grid-row.padding-vertical.padding-medium,
    .convert-grid-row-flex.padding-vertical.padding-medium {
      padding-top: 0.78125rem;
      padding-bottom: 0.78125rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.padding-vertical.padding-medium,
    .convert-grid-row.padding-vertical.padding-medium,
    .convert-grid-row-flex.padding-vertical.padding-medium {
      padding-top: 1.09375rem;
      padding-bottom: 1.09375rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-vertical.padding-medium,
    .convert-grid-row.padding-vertical.padding-medium,
    .convert-grid-row-flex.padding-vertical.padding-medium {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem; } }

.convert-block.padding-vertical.padding-large,
.convert-grid-row.padding-vertical.padding-large,
.convert-grid-row-flex.padding-vertical.padding-large {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-vertical.padding-large,
    .convert-grid-row.padding-vertical.padding-large,
    .convert-grid-row-flex.padding-vertical.padding-large {
      padding-top: 1.5625rem;
      padding-bottom: 1.5625rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.padding-vertical.padding-large,
    .convert-grid-row.padding-vertical.padding-large,
    .convert-grid-row-flex.padding-vertical.padding-large {
      padding-top: 2.1875rem;
      padding-bottom: 2.1875rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-vertical.padding-large,
    .convert-grid-row.padding-vertical.padding-large,
    .convert-grid-row-flex.padding-vertical.padding-large {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem; } }

.convert-block.padding-vertical.padding-xlarge,
.convert-grid-row.padding-vertical.padding-xlarge,
.convert-grid-row-flex.padding-vertical.padding-xlarge {
  padding-top: 5rem;
  padding-bottom: 5rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-vertical.padding-xlarge,
    .convert-grid-row.padding-vertical.padding-xlarge,
    .convert-grid-row-flex.padding-vertical.padding-xlarge {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.padding-vertical.padding-xlarge,
    .convert-grid-row.padding-vertical.padding-xlarge,
    .convert-grid-row-flex.padding-vertical.padding-xlarge {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-vertical.padding-xlarge,
    .convert-grid-row.padding-vertical.padding-xlarge,
    .convert-grid-row-flex.padding-vertical.padding-xlarge {
      padding-top: 4rem;
      padding-bottom: 4rem; } }

.convert-block.padding-vertical.padding-xxlarge,
.convert-grid-row.padding-vertical.padding-xxlarge,
.convert-grid-row-flex.padding-vertical.padding-xxlarge {
  padding-top: 7.8125rem;
  padding-bottom: 7.8125rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-vertical.padding-xxlarge,
    .convert-grid-row.padding-vertical.padding-xxlarge,
    .convert-grid-row-flex.padding-vertical.padding-xxlarge {
      padding-top: 3.90625rem;
      padding-bottom: 3.90625rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.padding-vertical.padding-xxlarge,
    .convert-grid-row.padding-vertical.padding-xxlarge,
    .convert-grid-row-flex.padding-vertical.padding-xxlarge {
      padding-top: 5.46875rem;
      padding-bottom: 5.46875rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-vertical.padding-xxlarge,
    .convert-grid-row.padding-vertical.padding-xxlarge,
    .convert-grid-row-flex.padding-vertical.padding-xxlarge {
      padding-top: 6.25rem;
      padding-bottom: 6.25rem; } }

.convert-block.padding-vertical.padding-xxxlarge,
.convert-grid-row.padding-vertical.padding-xxxlarge,
.convert-grid-row-flex.padding-vertical.padding-xxxlarge {
  padding-top: 10.9375rem;
  padding-bottom: 10.9375rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-vertical.padding-xxxlarge,
    .convert-grid-row.padding-vertical.padding-xxxlarge,
    .convert-grid-row-flex.padding-vertical.padding-xxxlarge {
      padding-top: 5.46875rem;
      padding-bottom: 5.46875rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .convert-block.padding-vertical.padding-xxxlarge,
    .convert-grid-row.padding-vertical.padding-xxxlarge,
    .convert-grid-row-flex.padding-vertical.padding-xxxlarge {
      padding-top: 7.65625rem;
      padding-bottom: 7.65625rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-vertical.padding-xxxlarge,
    .convert-grid-row.padding-vertical.padding-xxxlarge,
    .convert-grid-row-flex.padding-vertical.padding-xxxlarge {
      padding-top: 8.75rem;
      padding-bottom: 8.75rem; } }

.convert-block.padding-vertical.padding-none,
.convert-grid-row.padding-vertical.padding-none,
.convert-grid-row-flex.padding-vertical.padding-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.convert-block.padding-vertical.padding-fixed-03x,
.convert-grid-row.padding-vertical.padding-fixed-03x,
.convert-grid-row-flex.padding-vertical.padding-fixed-03x {
  padding-top: 6px;
  padding-bottom: 6px; }

.convert-block.padding-vertical.padding-fixed-05x,
.convert-grid-row.padding-vertical.padding-fixed-05x,
.convert-grid-row-flex.padding-vertical.padding-fixed-05x {
  padding-top: 10px;
  padding-bottom: 10px; }

.convert-block.padding-horizontal.padding-small,
.convert-grid-row-flex.padding-horizontal.padding-small {
  padding-left: 0.625rem;
  padding-right: 0.625rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-horizontal.padding-small,
    .convert-grid-row-flex.padding-horizontal.padding-small {
      padding-left: 0.3125rem;
      padding-right: 0.3125rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-horizontal.padding-small,
    .convert-grid-row-flex.padding-horizontal.padding-small {
      padding-left: 0.4375rem;
      padding-right: 0.4375rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-horizontal.padding-small,
    .convert-grid-row-flex.padding-horizontal.padding-small {
      padding-left: 0.5rem;
      padding-right: 0.5rem; } }

.convert-block.padding-horizontal.padding-medium,
.convert-grid-row-flex.padding-horizontal.padding-medium {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-horizontal.padding-medium,
    .convert-grid-row-flex.padding-horizontal.padding-medium {
      padding-left: 0.78125rem;
      padding-right: 0.78125rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-horizontal.padding-medium,
    .convert-grid-row-flex.padding-horizontal.padding-medium {
      padding-left: 1.09375rem;
      padding-right: 1.09375rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-horizontal.padding-medium,
    .convert-grid-row-flex.padding-horizontal.padding-medium {
      padding-left: 1.25rem;
      padding-right: 1.25rem; } }

.convert-block.padding-horizontal.padding-large,
.convert-grid-row-flex.padding-horizontal.padding-large {
  padding-left: 3.125rem;
  padding-right: 3.125rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-horizontal.padding-large,
    .convert-grid-row-flex.padding-horizontal.padding-large {
      padding-left: 1.5625rem;
      padding-right: 1.5625rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-horizontal.padding-large,
    .convert-grid-row-flex.padding-horizontal.padding-large {
      padding-left: 2.1875rem;
      padding-right: 2.1875rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-horizontal.padding-large,
    .convert-grid-row-flex.padding-horizontal.padding-large {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }

.convert-block.padding-horizontal.padding-xlarge,
.convert-grid-row-flex.padding-horizontal.padding-xlarge {
  padding-left: 5rem;
  padding-right: 5rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-horizontal.padding-xlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xlarge {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-horizontal.padding-xlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xlarge {
      padding-left: 3.5rem;
      padding-right: 3.5rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-horizontal.padding-xlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xlarge {
      padding-left: 4rem;
      padding-right: 4rem; } }

.convert-block.padding-horizontal.padding-xxlarge,
.convert-grid-row-flex.padding-horizontal.padding-xxlarge {
  padding-left: 7.8125rem;
  padding-right: 7.8125rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-horizontal.padding-xxlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xxlarge {
      padding-left: 3.90625rem;
      padding-right: 3.90625rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-horizontal.padding-xxlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xxlarge {
      padding-left: 5.46875rem;
      padding-right: 5.46875rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-horizontal.padding-xxlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xxlarge {
      padding-left: 6.25rem;
      padding-right: 6.25rem; } }

.convert-block.padding-horizontal.padding-xxxlarge,
.convert-grid-row-flex.padding-horizontal.padding-xxxlarge {
  padding-left: 10.9375rem;
  padding-right: 10.9375rem; }
  @media screen and (max-width: 47.9375em) {
    .convert-block.padding-horizontal.padding-xxxlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xxxlarge {
      padding-left: 5.46875rem;
      padding-right: 5.46875rem; } }
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {
    .convert-block.padding-horizontal.padding-xxxlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xxxlarge {
      padding-left: 7.65625rem;
      padding-right: 7.65625rem; } }
  @media screen and (min-width: 75em) and (max-width: 90.5625em) {
    .convert-block.padding-horizontal.padding-xxxlarge,
    .convert-grid-row-flex.padding-horizontal.padding-xxxlarge {
      padding-left: 8.75rem;
      padding-right: 8.75rem; } }

.convert-block.padding-horizontal.padding-none,
.convert-grid-row-flex.padding-horizontal.padding-none {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.convert-block.padding-horizontal.padding-fixed-03x,
.convert-grid-row-flex.padding-horizontal.padding-fixed-03x {
  padding-left: 6px;
  padding-right: 6px; }

.convert-block.padding-horizontal.padding-fixed-05x,
.convert-grid-row-flex.padding-horizontal.padding-fixed-05x {
  padding-left: 10px;
  padding-right: 10px; }

.convert-title-h.xxxlarge {
  font-size: 32px; }

.convert-title-h.xxlarge {
  font-size: 24px; }

.convert-title-h.xlarge {
  font-size: 18.72px; }

.convert-title-h.medium {
  font-size: 16px; }

.convert-title-h.small {
  font-size: 13.28px; }

.spacing-small {
  margin-bottom: 0.25rem; }

.spacing-medium {
  margin-bottom: 0.5rem; }

.spacing-xlarge {
  margin-bottom: 1.25rem; }

.spacing-xxlarge {
  margin-bottom: 1.5rem; }

.spacing-xxxlarge {
  margin-bottom: 2rem; }

@media screen and (max-width: 39.9375em) {
  .convert-grid-row > .convert-column:not(:last-child) > .convert-article {
    margin-bottom: 2.5rem; } }

.convert-articles .convert-article {
  margin-bottom: 1.5rem; }
  @media print, screen and (min-width: 48em) {
    .convert-articles .convert-article.large-12 {
      clear: both; }
    .convert-articles .convert-article.large-6:nth-of-type(2n+1) {
      clear: both; }
    .convert-articles .convert-article.large-4:nth-of-type(3n+1) {
      clear: both; } }

@media print, screen and (min-width: 40em) {
  .convert-image-w-text {
    position: relative; }
    .convert-image-w-text .convert-image-w-text-image {
      display: block;
      width: 100%;
      margin: 0; }
      .convert-image-w-text .convert-image-w-text-image img {
        max-width: inherit;
        width: 100%; }
    .convert-image-w-text .convert-image-w-text-content {
      position: absolute;
      margin: 0; }
    .convert-image-w-text.x-left .convert-image-w-text-content {
      left: 1.875rem; }
    .convert-image-w-text.x-center .convert-image-w-text-content {
      left: 50%;
      transform: translateX(-50%);
      text-align: center; }
      .convert-image-w-text.x-center .convert-image-w-text-content p {
        text-align: center; }
    .convert-image-w-text.x-right .convert-image-w-text-content {
      right: 1.875rem; }
    .convert-image-w-text.y-top .convert-image-w-text-content {
      top: 1.875rem; }
    .convert-image-w-text.y-center .convert-image-w-text-content {
      top: 50%; }
    .convert-image-w-text.y-bottom .convert-image-w-text-content {
      bottom: 1.875rem; }
    .convert-image-w-text.x-center.y-center .convert-image-w-text-content {
      transform: translateX(-50%) translateY(-50%); } }

.convert-image-w-text-content[style] .convert-title,
.convert-image-w-text-content[style] .convert-wysiwyg {
  color: inherit; }

.convert-grid-row-flex {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .convert-grid-row-flex .convert-grid-row-flex {
    margin-right: -1.0625rem;
    margin-left: -1.0625rem; }
    @media print, screen and (min-width: 40em) {
      .convert-grid-row-flex .convert-grid-row-flex {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .convert-grid-row-flex .convert-grid-row-flex {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .convert-grid-row-flex .convert-grid-row-flex {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .convert-grid-row-flex .convert-grid-row-flex {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .convert-grid-row-flex .convert-grid-row-flex {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
  .convert-grid-row-flex.expanded {
    max-width: none; }
    .convert-grid-row-flex.expanded .convert-grid-row-flex {
      margin-right: auto;
      margin-left: auto; }
  .convert-grid-row-flex:not(.expanded) .convert-grid-row-flex {
    max-width: none; }
  .convert-grid-row-flex.collapse > .convert-column-flex {
    padding-right: 0;
    padding-left: 0; }
  .convert-grid-row-flex.collapse > .convert-column-flex > .convert-grid-row-flex {
    margin-right: 0;
    margin-left: 0; }
  .convert-grid-row-flex.small-collapse > .convert-column-flex {
    padding-left: 0;
    padding-right: 0; }

.convert-column-flex {
  flex: 1 1 0px;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem;
  min-width: initial; }
  @media print, screen and (min-width: 40em) {
    .convert-column-flex {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }

.convert-column-flex.small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.convert-column-flex.small-offset-0 {
  margin-left: 0%; }

.convert-column-flex.small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.convert-column-flex.small-offset-1 {
  margin-left: 8.33333%; }

.convert-column-flex.small-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.convert-column-flex.small-offset-2 {
  margin-left: 16.66667%; }

.convert-column-flex.small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.convert-column-flex.small-offset-3 {
  margin-left: 25%; }

.convert-column-flex.small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.convert-column-flex.small-offset-4 {
  margin-left: 33.33333%; }

.convert-column-flex.small-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.convert-column-flex.small-offset-5 {
  margin-left: 41.66667%; }

.convert-column-flex.small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.convert-column-flex.small-offset-6 {
  margin-left: 50%; }

.convert-column-flex.small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.convert-column-flex.small-offset-7 {
  margin-left: 58.33333%; }

.convert-column-flex.small-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.convert-column-flex.small-offset-8 {
  margin-left: 66.66667%; }

.convert-column-flex.small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.convert-column-flex.small-offset-9 {
  margin-left: 75%; }

.convert-column-flex.small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.convert-column-flex.small-offset-10 {
  margin-left: 83.33333%; }

.convert-column-flex.small-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.convert-column-flex.small-offset-11 {
  margin-left: 91.66667%; }

.convert-column-flex.small-order-1 {
  order: 1; }

.convert-column-flex.small-order-2 {
  order: 2; }

.convert-column-flex.small-order-3 {
  order: 3; }

.convert-column-flex.small-order-4 {
  order: 4; }

.convert-column-flex.small-order-5 {
  order: 5; }

.convert-column-flex.small-order-6 {
  order: 6; }

.convert-column-flex.small-up-1 {
  flex-wrap: wrap; }
  .convert-column-flex.small-up-1 > .column, .convert-column-flex.small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.convert-column-flex.small-up-2 {
  flex-wrap: wrap; }
  .convert-column-flex.small-up-2 > .column, .convert-column-flex.small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.convert-column-flex.small-up-3 {
  flex-wrap: wrap; }
  .convert-column-flex.small-up-3 > .column, .convert-column-flex.small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.convert-column-flex.small-up-4 {
  flex-wrap: wrap; }
  .convert-column-flex.small-up-4 > .column, .convert-column-flex.small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.convert-column-flex.small-up-5 {
  flex-wrap: wrap; }
  .convert-column-flex.small-up-5 > .column, .convert-column-flex.small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.convert-column-flex.small-up-6 {
  flex-wrap: wrap; }
  .convert-column-flex.small-up-6 > .column, .convert-column-flex.small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.convert-column-flex.small-up-7 {
  flex-wrap: wrap; }
  .convert-column-flex.small-up-7 > .column, .convert-column-flex.small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.convert-column-flex.small-up-8 {
  flex-wrap: wrap; }
  .convert-column-flex.small-up-8 > .column, .convert-column-flex.small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.convert-column-flex.small-collapse > .convert-column-flex {
  padding-right: 0;
  padding-left: 0; }

.convert-column-flex.small-uncollapse > .convert-column-flex {
  padding-right: 1.0625rem;
  padding-left: 1.0625rem; }

@media print, screen and (min-width: 40em) {
  .convert-column-flex.medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .convert-column-flex.medium-offset-0 {
    margin-left: 0%; }
  .convert-column-flex.medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .convert-column-flex.medium-offset-1 {
    margin-left: 8.33333%; }
  .convert-column-flex.medium-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .convert-column-flex.medium-offset-2 {
    margin-left: 16.66667%; }
  .convert-column-flex.medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .convert-column-flex.medium-offset-3 {
    margin-left: 25%; }
  .convert-column-flex.medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .convert-column-flex.medium-offset-4 {
    margin-left: 33.33333%; }
  .convert-column-flex.medium-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .convert-column-flex.medium-offset-5 {
    margin-left: 41.66667%; }
  .convert-column-flex.medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .convert-column-flex.medium-offset-6 {
    margin-left: 50%; }
  .convert-column-flex.medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .convert-column-flex.medium-offset-7 {
    margin-left: 58.33333%; }
  .convert-column-flex.medium-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .convert-column-flex.medium-offset-8 {
    margin-left: 66.66667%; }
  .convert-column-flex.medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .convert-column-flex.medium-offset-9 {
    margin-left: 75%; }
  .convert-column-flex.medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .convert-column-flex.medium-offset-10 {
    margin-left: 83.33333%; }
  .convert-column-flex.medium-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .convert-column-flex.medium-offset-11 {
    margin-left: 91.66667%; }
  .convert-column-flex.medium-order-1 {
    order: 1; }
  .convert-column-flex.medium-order-2 {
    order: 2; }
  .convert-column-flex.medium-order-3 {
    order: 3; }
  .convert-column-flex.medium-order-4 {
    order: 4; }
  .convert-column-flex.medium-order-5 {
    order: 5; }
  .convert-column-flex.medium-order-6 {
    order: 6; }
  .convert-column-flex.medium-up-1 {
    flex-wrap: wrap; }
    .convert-column-flex.medium-up-1 > .column, .convert-column-flex.medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .convert-column-flex.medium-up-2 {
    flex-wrap: wrap; }
    .convert-column-flex.medium-up-2 > .column, .convert-column-flex.medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .convert-column-flex.medium-up-3 {
    flex-wrap: wrap; }
    .convert-column-flex.medium-up-3 > .column, .convert-column-flex.medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .convert-column-flex.medium-up-4 {
    flex-wrap: wrap; }
    .convert-column-flex.medium-up-4 > .column, .convert-column-flex.medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .convert-column-flex.medium-up-5 {
    flex-wrap: wrap; }
    .convert-column-flex.medium-up-5 > .column, .convert-column-flex.medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .convert-column-flex.medium-up-6 {
    flex-wrap: wrap; }
    .convert-column-flex.medium-up-6 > .column, .convert-column-flex.medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .convert-column-flex.medium-up-7 {
    flex-wrap: wrap; }
    .convert-column-flex.medium-up-7 > .column, .convert-column-flex.medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .convert-column-flex.medium-up-8 {
    flex-wrap: wrap; }
    .convert-column-flex.medium-up-8 > .column, .convert-column-flex.medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .convert-column-flex.medium-expand {
    flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .convert-column-flex.medium-flex-dir-row {
    flex-direction: row; }
  .convert-column-flex.medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .convert-column-flex.medium-flex-dir-column {
    flex-direction: column; }
  .convert-column-flex.medium-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .convert-column-flex.medium-flex-child-auto {
    flex: 1 1 auto; }
  .convert-column-flex.medium-flex-child-grow {
    flex: 1 0 auto; }
  .convert-column-flex.medium-flex-child-shrink {
    flex: 0 1 auto; } }

.convert-grid-row-flex.medium-unstack > .convert-column-flex {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 40em) {
    .convert-grid-row-flex.medium-unstack > .convert-column-flex {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .convert-column-flex.medium-collapse > .convert-column-flex {
    padding-right: 0;
    padding-left: 0; }
  .convert-column-flex.medium-uncollapse > .convert-column-flex {
    padding-right: 1.25rem;
    padding-left: 1.25rem; } }

@media print, screen and (min-width: 48em) {
  .convert-column-flex.large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .convert-column-flex.large-offset-0 {
    margin-left: 0%; }
  .convert-column-flex.large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .convert-column-flex.large-offset-1 {
    margin-left: 8.33333%; }
  .convert-column-flex.large-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .convert-column-flex.large-offset-2 {
    margin-left: 16.66667%; }
  .convert-column-flex.large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .convert-column-flex.large-offset-3 {
    margin-left: 25%; }
  .convert-column-flex.large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .convert-column-flex.large-offset-4 {
    margin-left: 33.33333%; }
  .convert-column-flex.large-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .convert-column-flex.large-offset-5 {
    margin-left: 41.66667%; }
  .convert-column-flex.large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .convert-column-flex.large-offset-6 {
    margin-left: 50%; }
  .convert-column-flex.large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .convert-column-flex.large-offset-7 {
    margin-left: 58.33333%; }
  .convert-column-flex.large-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .convert-column-flex.large-offset-8 {
    margin-left: 66.66667%; }
  .convert-column-flex.large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .convert-column-flex.large-offset-9 {
    margin-left: 75%; }
  .convert-column-flex.large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .convert-column-flex.large-offset-10 {
    margin-left: 83.33333%; }
  .convert-column-flex.large-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .convert-column-flex.large-offset-11 {
    margin-left: 91.66667%; }
  .convert-column-flex.large-order-1 {
    order: 1; }
  .convert-column-flex.large-order-2 {
    order: 2; }
  .convert-column-flex.large-order-3 {
    order: 3; }
  .convert-column-flex.large-order-4 {
    order: 4; }
  .convert-column-flex.large-order-5 {
    order: 5; }
  .convert-column-flex.large-order-6 {
    order: 6; }
  .convert-column-flex.large-up-1 {
    flex-wrap: wrap; }
    .convert-column-flex.large-up-1 > .column, .convert-column-flex.large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .convert-column-flex.large-up-2 {
    flex-wrap: wrap; }
    .convert-column-flex.large-up-2 > .column, .convert-column-flex.large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .convert-column-flex.large-up-3 {
    flex-wrap: wrap; }
    .convert-column-flex.large-up-3 > .column, .convert-column-flex.large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .convert-column-flex.large-up-4 {
    flex-wrap: wrap; }
    .convert-column-flex.large-up-4 > .column, .convert-column-flex.large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .convert-column-flex.large-up-5 {
    flex-wrap: wrap; }
    .convert-column-flex.large-up-5 > .column, .convert-column-flex.large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .convert-column-flex.large-up-6 {
    flex-wrap: wrap; }
    .convert-column-flex.large-up-6 > .column, .convert-column-flex.large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .convert-column-flex.large-up-7 {
    flex-wrap: wrap; }
    .convert-column-flex.large-up-7 > .column, .convert-column-flex.large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .convert-column-flex.large-up-8 {
    flex-wrap: wrap; }
    .convert-column-flex.large-up-8 > .column, .convert-column-flex.large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 48em) and (min-width: 48em) {
  .convert-column-flex.large-expand {
    flex: 1 1 0px; } }

@media print, screen and (min-width: 48em) {
  .convert-column-flex.large-flex-dir-row {
    flex-direction: row; }
  .convert-column-flex.large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .convert-column-flex.large-flex-dir-column {
    flex-direction: column; }
  .convert-column-flex.large-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .convert-column-flex.large-flex-child-auto {
    flex: 1 1 auto; }
  .convert-column-flex.large-flex-child-grow {
    flex: 1 0 auto; }
  .convert-column-flex.large-flex-child-shrink {
    flex: 0 1 auto; } }

.convert-grid-row-flex.large-unstack > .convert-column-flex {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 48em) {
    .convert-grid-row-flex.large-unstack > .convert-column-flex {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 48em) {
  .convert-column-flex.large-collapse > .convert-column-flex {
    padding-right: 0;
    padding-left: 0; }
  .convert-column-flex.large-uncollapse > .convert-column-flex {
    padding-right: 1.25rem;
    padding-left: 1.25rem; } }

@media screen and (min-width: 64em) {
  .convert-column-flex.xlarge-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .convert-column-flex.xlarge-offset-0 {
    margin-left: 0%; }
  .convert-column-flex.xlarge-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .convert-column-flex.xlarge-offset-1 {
    margin-left: 8.33333%; }
  .convert-column-flex.xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .convert-column-flex.xlarge-offset-2 {
    margin-left: 16.66667%; }
  .convert-column-flex.xlarge-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .convert-column-flex.xlarge-offset-3 {
    margin-left: 25%; }
  .convert-column-flex.xlarge-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .convert-column-flex.xlarge-offset-4 {
    margin-left: 33.33333%; }
  .convert-column-flex.xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .convert-column-flex.xlarge-offset-5 {
    margin-left: 41.66667%; }
  .convert-column-flex.xlarge-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .convert-column-flex.xlarge-offset-6 {
    margin-left: 50%; }
  .convert-column-flex.xlarge-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .convert-column-flex.xlarge-offset-7 {
    margin-left: 58.33333%; }
  .convert-column-flex.xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .convert-column-flex.xlarge-offset-8 {
    margin-left: 66.66667%; }
  .convert-column-flex.xlarge-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .convert-column-flex.xlarge-offset-9 {
    margin-left: 75%; }
  .convert-column-flex.xlarge-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .convert-column-flex.xlarge-offset-10 {
    margin-left: 83.33333%; }
  .convert-column-flex.xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .convert-column-flex.xlarge-offset-11 {
    margin-left: 91.66667%; }
  .convert-column-flex.xlarge-order-1 {
    order: 1; }
  .convert-column-flex.xlarge-order-2 {
    order: 2; }
  .convert-column-flex.xlarge-order-3 {
    order: 3; }
  .convert-column-flex.xlarge-order-4 {
    order: 4; }
  .convert-column-flex.xlarge-order-5 {
    order: 5; }
  .convert-column-flex.xlarge-order-6 {
    order: 6; }
  .convert-column-flex.xlarge-up-1 {
    flex-wrap: wrap; }
    .convert-column-flex.xlarge-up-1 > .column, .convert-column-flex.xlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .convert-column-flex.xlarge-up-2 {
    flex-wrap: wrap; }
    .convert-column-flex.xlarge-up-2 > .column, .convert-column-flex.xlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .convert-column-flex.xlarge-up-3 {
    flex-wrap: wrap; }
    .convert-column-flex.xlarge-up-3 > .column, .convert-column-flex.xlarge-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .convert-column-flex.xlarge-up-4 {
    flex-wrap: wrap; }
    .convert-column-flex.xlarge-up-4 > .column, .convert-column-flex.xlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .convert-column-flex.xlarge-up-5 {
    flex-wrap: wrap; }
    .convert-column-flex.xlarge-up-5 > .column, .convert-column-flex.xlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .convert-column-flex.xlarge-up-6 {
    flex-wrap: wrap; }
    .convert-column-flex.xlarge-up-6 > .column, .convert-column-flex.xlarge-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .convert-column-flex.xlarge-up-7 {
    flex-wrap: wrap; }
    .convert-column-flex.xlarge-up-7 > .column, .convert-column-flex.xlarge-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .convert-column-flex.xlarge-up-8 {
    flex-wrap: wrap; }
    .convert-column-flex.xlarge-up-8 > .column, .convert-column-flex.xlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 64em) and (min-width: 64em) {
  .convert-column-flex.xlarge-expand {
    flex: 1 1 0px; } }

@media screen and (min-width: 64em) {
  .convert-column-flex.xlarge-flex-dir-row {
    flex-direction: row; }
  .convert-column-flex.xlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .convert-column-flex.xlarge-flex-dir-column {
    flex-direction: column; }
  .convert-column-flex.xlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .convert-column-flex.xlarge-flex-child-auto {
    flex: 1 1 auto; }
  .convert-column-flex.xlarge-flex-child-grow {
    flex: 1 0 auto; }
  .convert-column-flex.xlarge-flex-child-shrink {
    flex: 0 1 auto; } }

.convert-grid-row-flex.xlarge-unstack > .convert-column-flex {
  flex: 0 0 100%; }
  @media screen and (min-width: 64em) {
    .convert-grid-row-flex.xlarge-unstack > .convert-column-flex {
      flex: 1 1 0px; } }

@media screen and (min-width: 64em) {
  .convert-column-flex.xlarge-collapse > .convert-column-flex {
    padding-right: 0;
    padding-left: 0; }
  .convert-column-flex.xlarge-uncollapse > .convert-column-flex {
    padding-right: 1.25rem;
    padding-left: 1.25rem; } }

@media screen and (min-width: 75em) {
  .convert-column-flex.xxlarge-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .convert-column-flex.xxlarge-offset-0 {
    margin-left: 0%; }
  .convert-column-flex.xxlarge-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .convert-column-flex.xxlarge-offset-1 {
    margin-left: 8.33333%; }
  .convert-column-flex.xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .convert-column-flex.xxlarge-offset-2 {
    margin-left: 16.66667%; }
  .convert-column-flex.xxlarge-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .convert-column-flex.xxlarge-offset-3 {
    margin-left: 25%; }
  .convert-column-flex.xxlarge-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .convert-column-flex.xxlarge-offset-4 {
    margin-left: 33.33333%; }
  .convert-column-flex.xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .convert-column-flex.xxlarge-offset-5 {
    margin-left: 41.66667%; }
  .convert-column-flex.xxlarge-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .convert-column-flex.xxlarge-offset-6 {
    margin-left: 50%; }
  .convert-column-flex.xxlarge-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .convert-column-flex.xxlarge-offset-7 {
    margin-left: 58.33333%; }
  .convert-column-flex.xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .convert-column-flex.xxlarge-offset-8 {
    margin-left: 66.66667%; }
  .convert-column-flex.xxlarge-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .convert-column-flex.xxlarge-offset-9 {
    margin-left: 75%; }
  .convert-column-flex.xxlarge-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .convert-column-flex.xxlarge-offset-10 {
    margin-left: 83.33333%; }
  .convert-column-flex.xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .convert-column-flex.xxlarge-offset-11 {
    margin-left: 91.66667%; }
  .convert-column-flex.xxlarge-order-1 {
    order: 1; }
  .convert-column-flex.xxlarge-order-2 {
    order: 2; }
  .convert-column-flex.xxlarge-order-3 {
    order: 3; }
  .convert-column-flex.xxlarge-order-4 {
    order: 4; }
  .convert-column-flex.xxlarge-order-5 {
    order: 5; }
  .convert-column-flex.xxlarge-order-6 {
    order: 6; }
  .convert-column-flex.xxlarge-up-1 {
    flex-wrap: wrap; }
    .convert-column-flex.xxlarge-up-1 > .column, .convert-column-flex.xxlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .convert-column-flex.xxlarge-up-2 {
    flex-wrap: wrap; }
    .convert-column-flex.xxlarge-up-2 > .column, .convert-column-flex.xxlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .convert-column-flex.xxlarge-up-3 {
    flex-wrap: wrap; }
    .convert-column-flex.xxlarge-up-3 > .column, .convert-column-flex.xxlarge-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .convert-column-flex.xxlarge-up-4 {
    flex-wrap: wrap; }
    .convert-column-flex.xxlarge-up-4 > .column, .convert-column-flex.xxlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .convert-column-flex.xxlarge-up-5 {
    flex-wrap: wrap; }
    .convert-column-flex.xxlarge-up-5 > .column, .convert-column-flex.xxlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .convert-column-flex.xxlarge-up-6 {
    flex-wrap: wrap; }
    .convert-column-flex.xxlarge-up-6 > .column, .convert-column-flex.xxlarge-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .convert-column-flex.xxlarge-up-7 {
    flex-wrap: wrap; }
    .convert-column-flex.xxlarge-up-7 > .column, .convert-column-flex.xxlarge-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .convert-column-flex.xxlarge-up-8 {
    flex-wrap: wrap; }
    .convert-column-flex.xxlarge-up-8 > .column, .convert-column-flex.xxlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 75em) and (min-width: 75em) {
  .convert-column-flex.xxlarge-expand {
    flex: 1 1 0px; } }

@media screen and (min-width: 75em) {
  .convert-column-flex.xxlarge-flex-dir-row {
    flex-direction: row; }
  .convert-column-flex.xxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .convert-column-flex.xxlarge-flex-dir-column {
    flex-direction: column; }
  .convert-column-flex.xxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .convert-column-flex.xxlarge-flex-child-auto {
    flex: 1 1 auto; }
  .convert-column-flex.xxlarge-flex-child-grow {
    flex: 1 0 auto; }
  .convert-column-flex.xxlarge-flex-child-shrink {
    flex: 0 1 auto; } }

.convert-grid-row-flex.xxlarge-unstack > .convert-column-flex {
  flex: 0 0 100%; }
  @media screen and (min-width: 75em) {
    .convert-grid-row-flex.xxlarge-unstack > .convert-column-flex {
      flex: 1 1 0px; } }

@media screen and (min-width: 75em) {
  .convert-column-flex.xxlarge-collapse > .convert-column-flex {
    padding-right: 0;
    padding-left: 0; }
  .convert-column-flex.xxlarge-uncollapse > .convert-column-flex {
    padding-right: 1.25rem;
    padding-left: 1.25rem; } }

@media screen and (min-width: 90.625em) {
  .convert-column-flex.xxxlarge-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .convert-column-flex.xxxlarge-offset-0 {
    margin-left: 0%; }
  .convert-column-flex.xxxlarge-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .convert-column-flex.xxxlarge-offset-1 {
    margin-left: 8.33333%; }
  .convert-column-flex.xxxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .convert-column-flex.xxxlarge-offset-2 {
    margin-left: 16.66667%; }
  .convert-column-flex.xxxlarge-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .convert-column-flex.xxxlarge-offset-3 {
    margin-left: 25%; }
  .convert-column-flex.xxxlarge-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .convert-column-flex.xxxlarge-offset-4 {
    margin-left: 33.33333%; }
  .convert-column-flex.xxxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .convert-column-flex.xxxlarge-offset-5 {
    margin-left: 41.66667%; }
  .convert-column-flex.xxxlarge-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .convert-column-flex.xxxlarge-offset-6 {
    margin-left: 50%; }
  .convert-column-flex.xxxlarge-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .convert-column-flex.xxxlarge-offset-7 {
    margin-left: 58.33333%; }
  .convert-column-flex.xxxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .convert-column-flex.xxxlarge-offset-8 {
    margin-left: 66.66667%; }
  .convert-column-flex.xxxlarge-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .convert-column-flex.xxxlarge-offset-9 {
    margin-left: 75%; }
  .convert-column-flex.xxxlarge-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .convert-column-flex.xxxlarge-offset-10 {
    margin-left: 83.33333%; }
  .convert-column-flex.xxxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .convert-column-flex.xxxlarge-offset-11 {
    margin-left: 91.66667%; }
  .convert-column-flex.xxxlarge-order-1 {
    order: 1; }
  .convert-column-flex.xxxlarge-order-2 {
    order: 2; }
  .convert-column-flex.xxxlarge-order-3 {
    order: 3; }
  .convert-column-flex.xxxlarge-order-4 {
    order: 4; }
  .convert-column-flex.xxxlarge-order-5 {
    order: 5; }
  .convert-column-flex.xxxlarge-order-6 {
    order: 6; }
  .convert-column-flex.xxxlarge-up-1 {
    flex-wrap: wrap; }
    .convert-column-flex.xxxlarge-up-1 > .column, .convert-column-flex.xxxlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .convert-column-flex.xxxlarge-up-2 {
    flex-wrap: wrap; }
    .convert-column-flex.xxxlarge-up-2 > .column, .convert-column-flex.xxxlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .convert-column-flex.xxxlarge-up-3 {
    flex-wrap: wrap; }
    .convert-column-flex.xxxlarge-up-3 > .column, .convert-column-flex.xxxlarge-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .convert-column-flex.xxxlarge-up-4 {
    flex-wrap: wrap; }
    .convert-column-flex.xxxlarge-up-4 > .column, .convert-column-flex.xxxlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .convert-column-flex.xxxlarge-up-5 {
    flex-wrap: wrap; }
    .convert-column-flex.xxxlarge-up-5 > .column, .convert-column-flex.xxxlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .convert-column-flex.xxxlarge-up-6 {
    flex-wrap: wrap; }
    .convert-column-flex.xxxlarge-up-6 > .column, .convert-column-flex.xxxlarge-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .convert-column-flex.xxxlarge-up-7 {
    flex-wrap: wrap; }
    .convert-column-flex.xxxlarge-up-7 > .column, .convert-column-flex.xxxlarge-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .convert-column-flex.xxxlarge-up-8 {
    flex-wrap: wrap; }
    .convert-column-flex.xxxlarge-up-8 > .column, .convert-column-flex.xxxlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 90.625em) and (min-width: 90.625em) {
  .convert-column-flex.xxxlarge-expand {
    flex: 1 1 0px; } }

@media screen and (min-width: 90.625em) {
  .convert-column-flex.xxxlarge-flex-dir-row {
    flex-direction: row; }
  .convert-column-flex.xxxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .convert-column-flex.xxxlarge-flex-dir-column {
    flex-direction: column; }
  .convert-column-flex.xxxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .convert-column-flex.xxxlarge-flex-child-auto {
    flex: 1 1 auto; }
  .convert-column-flex.xxxlarge-flex-child-grow {
    flex: 1 0 auto; }
  .convert-column-flex.xxxlarge-flex-child-shrink {
    flex: 0 1 auto; } }

.convert-grid-row-flex.xxxlarge-unstack > .convert-column-flex {
  flex: 0 0 100%; }
  @media screen and (min-width: 90.625em) {
    .convert-grid-row-flex.xxxlarge-unstack > .convert-column-flex {
      flex: 1 1 0px; } }

@media screen and (min-width: 90.625em) {
  .convert-column-flex.xxxlarge-collapse > .convert-column-flex {
    padding-right: 0;
    padding-left: 0; }
  .convert-column-flex.xxxlarge-uncollapse > .convert-column-flex {
    padding-right: 1.25rem;
    padding-left: 1.25rem; } }

.align-hz-right {
  justify-content: flex-end; }

.align-hz-center {
  justify-content: center; }

.align-hz-justify {
  justify-content: space-between; }

.align-hz-spaced {
  justify-content: space-around; }

.align-vt-top {
  align-items: flex-start; }

.align-vt-self-top {
  align-self: flex-start; }

.align-vt-bottom {
  align-items: flex-end; }

.align-vt-self-bottom {
  align-self: flex-end; }

.align-vt-middle {
  align-items: center; }

.align-vt-self-middle {
  align-self: center; }

.align-vt-stretch {
  align-items: stretch; }

.align-vt-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.convert-grid-row-vc {
  justify-content: flex-start;
  align-items: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 48em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

@media screen and (min-width: 64em) {
  .xlarge-order-1 {
    order: 1; }
  .xlarge-order-2 {
    order: 2; }
  .xlarge-order-3 {
    order: 3; }
  .xlarge-order-4 {
    order: 4; }
  .xlarge-order-5 {
    order: 5; }
  .xlarge-order-6 {
    order: 6; } }

@media screen and (min-width: 75em) {
  .xxlarge-order-1 {
    order: 1; }
  .xxlarge-order-2 {
    order: 2; }
  .xxlarge-order-3 {
    order: 3; }
  .xxlarge-order-4 {
    order: 4; }
  .xxlarge-order-5 {
    order: 5; }
  .xxlarge-order-6 {
    order: 6; } }

@media screen and (min-width: 90.625em) {
  .xxxlarge-order-1 {
    order: 1; }
  .xxxlarge-order-2 {
    order: 2; }
  .xxxlarge-order-3 {
    order: 3; }
  .xxxlarge-order-4 {
    order: 4; }
  .xxxlarge-order-5 {
    order: 5; }
  .xxxlarge-order-6 {
    order: 6; } }

.convert-divider {
  max-width: inherit; }

.convert-teaser {
  overflow: hidden;
  background: #F1EFED;
  text-align: center;
  margin-bottom: 2rem; }
  .convert-teaser .convert-teaser-content {
    padding: 1.4rem 2rem 2.8rem;
    position: relative; }
  .convert-teaser .convert-title {
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-weight: 300;
    font-size: 1.75rem;
    color: #181818;
    line-height: 1;
    margin-bottom: .5rem; }
  .convert-teaser .convert-subtitle {
    font-family: "silka-regular-webfont", sans-serif;
    letter-spacing: normal;
    font-weight: 400;
    font-size: 0.8125rem;
    color: rgba(24, 24, 24, 0.7);
    line-height: 1.2;
    margin-bottom: 0; }
  .convert-teaser .convert-teaser-image {
    text-align: center; }
  .convert-teaser .convert-wysiwyg {
    margin-bottom: 1.7rem; }
    .convert-teaser .convert-wysiwyg p {
      font-size: 0.8125rem;
      line-height: 1.6;
      text-align: left; }
      .convert-teaser .convert-wysiwyg p:last-child {
        margin-bottom: 0; }
      .convert-teaser .convert-wysiwyg p.text-small {
        font-size: 0.6875rem; }
      .convert-teaser .convert-wysiwyg p.text-normal {
        font-size: 0.8125rem; }
      .convert-teaser .convert-wysiwyg p.text-large {
        font-size: 0.9375rem; }
      .convert-teaser .convert-wysiwyg p.text-xlarge {
        font-size: 1.0625rem; }
      .convert-teaser .convert-wysiwyg p.text-xxlarge {
        font-size: 1.1875rem; }
        @media screen and (max-width: 47.9375em) {
          .convert-teaser .convert-wysiwyg p.text-xxlarge {
            font-size: 1.0625rem; } }
  .convert-teaser hr {
    margin: 1.1rem 0; }
  .convert-teaser p {
    text-align: left; }
  .convert-teaser a.link {
    text-transform: uppercase;
    font-family: "silka-regular-webfont", sans-serif;
    text-transform: uppercase;
    text-decoration: underline;
    margin-top: 1.5rem;
    display: inline-block; }
    .convert-teaser a.link:hover {
      text-decoration: none; }
  .convert-teaser.rounded .convert-teaser-image img {
    width: 81px;
    height: 81px;
    border-radius: 100%;
    max-width: none;
    transform: translateX(-50%);
    position: relative;
    left: 50%; }
  .convert-teaser.small .convert-teaser-image,
  .convert-teaser.small .convert-teaser-content {
    display: table-cell !important; }
  .convert-teaser.small .convert-teaser-content {
    text-align: left !important; }
  .convert-teaser.small .convert-title {
    font-size: 0.9375rem; }
  .convert-teaser.small .subheader {
    margin-bottom: 0; }
  .convert-teaser.small a.link {
    margin-top: 0; }
  .convert-teaser .button {
    min-width: 12.5rem;
    margin-bottom: 0; }
    @media screen and (max-width: 39.9375em) {
      .convert-teaser .button {
        min-width: inherit;
        width: 100%; } }
  @media screen and (min-width: 40em) and (max-width: 74.9375em) {
    .medium-3 > .convert-teaser .convert-teaser-image,
    .medium-2 > .convert-teaser .convert-teaser-image {
      position: relative;
      overflow: hidden; }
    .medium-3 > .convert-teaser .convert-teaser-content,
    .medium-2 > .convert-teaser .convert-teaser-content {
      padding: 1.5rem 1.5rem 1.75rem;
      position: relative; }
    .medium-3 > .convert-teaser .convert-title,
    .medium-2 > .convert-teaser .convert-title {
      font-size: 1.375rem; }
    .medium-3 > .convert-teaser .convert-subtitle,
    .medium-2 > .convert-teaser .convert-subtitle {
      font-size: 0.6875rem; }
    .medium-3 > .convert-teaser .convert-wysiwyg,
    .medium-2 > .convert-teaser .convert-wysiwyg {
      margin-bottom: 1rem; }
    .medium-3 > .convert-teaser hr,
    .medium-2 > .convert-teaser hr {
      margin: .75rem 0; }
    .medium-3 > .convert-teaser .button,
    .medium-2 > .convert-teaser .button {
      min-width: inherit;
      width: 100%; }
    .medium-4 > .convert-teaser .convert-teaser-image {
      position: relative;
      overflow: hidden; }
    .medium-4 > .convert-teaser .convert-teaser-content {
      padding: 1.2rem 1.5rem 2rem;
      position: relative; }
    .medium-4 > .convert-teaser .button {
      min-width: inherit;
      width: 100%; }
    .medium-8 > .convert-teaser,
    .medium-9 > .convert-teaser,
    .medium-12 > .convert-teaser {
      text-align: left;
      overflow: hidden;
      display: flex;
      flex-direction: row; }
      .medium-8 > .convert-teaser .convert-teaser-image,
      .medium-9 > .convert-teaser .convert-teaser-image,
      .medium-12 > .convert-teaser .convert-teaser-image {
        float: left;
        width: 25%;
        overflow: hidden;
        position: relative; }
        .medium-8 > .convert-teaser .convert-teaser-image img,
        .medium-9 > .convert-teaser .convert-teaser-image img,
        .medium-12 > .convert-teaser .convert-teaser-image img {
          max-width: inherit;
          min-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%); }
      .medium-8 > .convert-teaser .convert-teaser-content,
      .medium-9 > .convert-teaser .convert-teaser-content,
      .medium-12 > .convert-teaser .convert-teaser-content {
        float: left;
        width: 75%;
        padding: 2.25rem 2rem 1rem 2rem; }
      .medium-8 > .convert-teaser .button,
      .medium-9 > .convert-teaser .button,
      .medium-12 > .convert-teaser .button {
        position: absolute;
        top: 2.25rem;
        right: 2rem;
        min-width: inherit;
        width: 8.125rem; }
      .medium-8 > .convert-teaser .convert-title,
      .medium-8 > .convert-teaser .convert-subtitle,
      .medium-9 > .convert-teaser .convert-title,
      .medium-9 > .convert-teaser .convert-subtitle,
      .medium-12 > .convert-teaser .convert-title,
      .medium-12 > .convert-teaser .convert-subtitle {
        margin-right: 8.75rem; }
      .medium-8 > .convert-teaser hr,
      .medium-9 > .convert-teaser hr,
      .medium-12 > .convert-teaser hr {
        margin: .95rem 0; } }
  @media screen and (min-width: 64em) {
    .xlarge-3 > .convert-teaser .convert-teaser-image,
    .xlarge-2 > .convert-teaser .convert-teaser-image {
      position: relative;
      overflow: hidden; }
    .xlarge-3 > .convert-teaser .convert-teaser-content,
    .xlarge-2 > .convert-teaser .convert-teaser-content {
      padding: 1.8rem 1.4rem 1.8rem 2.2rem;
      position: relative; }
    .xlarge-3 > .convert-teaser .convert-wysiwyg,
    .xlarge-2 > .convert-teaser .convert-wysiwyg {
      margin-bottom: 1rem; }
    .xlarge-3 > .convert-teaser hr,
    .xlarge-2 > .convert-teaser hr {
      margin: .75rem 0; }
    .xlarge-3 > .convert-teaser .button,
    .xlarge-2 > .convert-teaser .button {
      min-width: inherit;
      width: 100%; }
    .xlarge-4 > .convert-teaser .convert-teaser-image {
      position: relative;
      overflow: hidden; }
    .xlarge-4 > .convert-teaser .convert-teaser-content {
      padding: 1.8rem 1.4rem 1.8rem 2.2rem;
      position: relative; }
    .xlarge-4 > .convert-teaser .button {
      min-width: inherit;
      width: 100%; }
    .xlarge-6 > .convert-teaser {
      text-align: left; }
      .xlarge-6 > .convert-teaser .convert-teaser-content {
        padding: 1.8rem 1.4rem 1.8rem 2.2rem; }
      .xlarge-6 > .convert-teaser .button {
        position: absolute;
        top: 1.8rem;
        right: 1.4rem;
        max-width: 15.625rem; }
      .xlarge-6 > .convert-teaser .convert-title,
      .xlarge-6 > .convert-teaser .convert-subtitle {
        margin-right: 17.5rem; }
      .xlarge-6 > .convert-teaser hr {
        margin: .95rem 0; }
    .xlarge-8 > .convert-teaser,
    .xlarge-9 > .convert-teaser,
    .xlarge-12 > .convert-teaser {
      text-align: left;
      overflow: hidden;
      display: flex;
      flex-direction: row; }
      .xlarge-8 > .convert-teaser .convert-teaser-image,
      .xlarge-9 > .convert-teaser .convert-teaser-image,
      .xlarge-12 > .convert-teaser .convert-teaser-image {
        float: left;
        width: 32%;
        overflow: hidden;
        position: relative; }
        .xlarge-8 > .convert-teaser .convert-teaser-image img,
        .xlarge-9 > .convert-teaser .convert-teaser-image img,
        .xlarge-12 > .convert-teaser .convert-teaser-image img {
          max-width: inherit;
          min-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%); }
      .xlarge-8 > .convert-teaser .convert-teaser-content,
      .xlarge-9 > .convert-teaser .convert-teaser-content,
      .xlarge-12 > .convert-teaser .convert-teaser-content {
        float: left;
        width: 68%;
        padding: 1.8rem 1.4rem 1.8rem 2.2rem; }
      .xlarge-8 > .convert-teaser .button,
      .xlarge-9 > .convert-teaser .button,
      .xlarge-12 > .convert-teaser .button {
        position: absolute;
        top: 1.8rem;
        right: 1.4rem;
        width: 9.375rem;
        min-width: inherit; }
      .xlarge-8 > .convert-teaser .convert-title,
      .xlarge-8 > .convert-teaser .convert-subtitle,
      .xlarge-9 > .convert-teaser .convert-title,
      .xlarge-9 > .convert-teaser .convert-subtitle,
      .xlarge-12 > .convert-teaser .convert-title,
      .xlarge-12 > .convert-teaser .convert-subtitle {
        margin-right: 11.25rem; }
      .xlarge-8 > .convert-teaser hr,
      .xlarge-9 > .convert-teaser hr,
      .xlarge-12 > .convert-teaser hr {
        margin: .95rem 0; } }
  @media screen and (min-width: 75em) {
    .xxlarge-3 > .convert-teaser .convert-teaser-image,
    .xxlarge-2 > .convert-teaser .convert-teaser-image {
      position: relative;
      overflow: hidden; }
    .xxlarge-3 > .convert-teaser .convert-teaser-content,
    .xxlarge-2 > .convert-teaser .convert-teaser-content {
      padding: 3rem;
      position: relative; }
    .xxlarge-3 > .convert-teaser .convert-wysiwyg,
    .xxlarge-2 > .convert-teaser .convert-wysiwyg {
      margin-bottom: 1rem; }
    .xxlarge-3 > .convert-teaser hr,
    .xxlarge-2 > .convert-teaser hr {
      margin: .75rem 0; }
    .xxlarge-3 > .convert-teaser .button,
    .xxlarge-2 > .convert-teaser .button {
      min-width: inherit;
      width: 100%; }
    .xxlarge-4 > .convert-teaser .convert-teaser-image {
      position: relative;
      overflow: hidden; }
    .xxlarge-4 > .convert-teaser .convert-teaser-content {
      padding: 3rem;
      position: relative; }
    .xxlarge-4 > .convert-teaser .button {
      min-width: inherit;
      width: 100%; }
    .xxlarge-6 > .convert-teaser {
      text-align: left; }
      .xxlarge-6 > .convert-teaser .convert-teaser-content {
        padding: 1.8rem 1.4rem 1.8rem 2.2rem; }
      .xxlarge-6 > .convert-teaser .button {
        position: absolute;
        top: 1.8rem;
        right: 1.4rem;
        max-width: 15.625rem; }
      .xxlarge-6 > .convert-teaser .convert-title,
      .xxlarge-6 > .convert-teaser .convert-subtitle {
        margin-right: 17.5rem; }
      .xxlarge-6 > .convert-teaser hr {
        margin: .95rem 0; }
    .xxlarge-8 > .convert-teaser,
    .xxlarge-9 > .convert-teaser,
    .xxlarge-12 > .convert-teaser {
      text-align: left;
      overflow: hidden;
      display: flex;
      flex-direction: row; }
      .xxlarge-8 > .convert-teaser .convert-teaser-image,
      .xxlarge-9 > .convert-teaser .convert-teaser-image,
      .xxlarge-12 > .convert-teaser .convert-teaser-image {
        float: left;
        width: 32%;
        overflow: hidden;
        position: relative; }
        .xxlarge-8 > .convert-teaser .convert-teaser-image img,
        .xxlarge-9 > .convert-teaser .convert-teaser-image img,
        .xxlarge-12 > .convert-teaser .convert-teaser-image img {
          max-width: inherit;
          min-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%); }
      .xxlarge-8 > .convert-teaser .convert-teaser-content,
      .xxlarge-9 > .convert-teaser .convert-teaser-content,
      .xxlarge-12 > .convert-teaser .convert-teaser-content {
        width: 68%;
        float: left;
        padding: 3rem; }
      .xxlarge-8 > .convert-teaser .convert-title,
      .xxlarge-8 > .convert-teaser .convert-subtitle,
      .xxlarge-9 > .convert-teaser .convert-title,
      .xxlarge-9 > .convert-teaser .convert-subtitle,
      .xxlarge-12 > .convert-teaser .convert-title,
      .xxlarge-12 > .convert-teaser .convert-subtitle {
        margin-right: 14.375rem; }
      .xxlarge-8 > .convert-teaser .button,
      .xxlarge-9 > .convert-teaser .button,
      .xxlarge-12 > .convert-teaser .button {
        position: absolute;
        top: 3rem;
        right: 3.25rem;
        min-width: inherit;
        width: 12.5rem; }
      .xxlarge-8 > .convert-teaser hr,
      .xxlarge-9 > .convert-teaser hr,
      .xxlarge-12 > .convert-teaser hr {
        margin: .95rem 0; } }

.convert-responsive-image {
  margin-bottom: 1rem; }
  .convert-responsive-image + .convert-wysiwyg {
    margin-bottom: 2rem; }
    .convert-responsive-image + .convert-wysiwyg .convert-title {
      font-family: "silka-regular-webfont", sans-serif;
      font-weight: 300;
      font-size: 1.375rem;
      line-height: 1;
      margin-bottom: .8rem; }
  @media print, screen and (min-width: 40em) {
    .convert-responsive-image {
      margin-bottom: 1.4rem; }
      .convert-responsive-image + .convert-wysiwyg {
        margin-bottom: 4rem; }
        .convert-responsive-image + .convert-wysiwyg .convert-title {
          font-size: 1.75rem;
          margin-bottom: 1.25rem; } }

.googlemap {
  height: 17.8125rem;
  overflow: hidden; }

.convert-wysiwyg .intro,
.convert-wysiwyg .tagline {
  font-family: "silka-regular-webfont", sans-serif;
  letter-spacing: normal;
  letter-spacing: normal;
  font-weight: 400;
  font-size: 1.0625rem;
  line-height: 1.35; }
  @media print, screen and (min-width: 48em) {
    .convert-wysiwyg .intro,
    .convert-wysiwyg .tagline {
      font-size: 1.1875rem;
      line-height: 1.47; } }
  @media screen and (min-width: 75em) {
    .convert-wysiwyg .intro,
    .convert-wysiwyg .tagline {
      font-size: 1.25rem; } }

.convert-wysiwyg p {
  font-size: 13px;
  line-height: 1.6;
  text-align: left; }
  .convert-wysiwyg p:last-child {
    margin-bottom: 0; }
  .convert-wysiwyg p.text-small {
    font-size: 11px; }
  .convert-wysiwyg p.text-normal {
    font-size: 13px; }
  .convert-wysiwyg p.text-large {
    font-size: 15px; }
  .convert-wysiwyg p.text-xlarge {
    font-size: 17px; }
  .convert-wysiwyg p.text-xxlarge {
    font-size: 19px; }
    @media screen and (max-width: 47.9375em) {
      .convert-wysiwyg p.text-xxlarge {
        font-size: 17px; } }

.convert-wysiwyg.video-full {
  height: 500px;
  overflow: hidden;
  position: relative; }
  .convert-wysiwyg.video-full iframe {
    width: 100%;
    height: 56.25vw !important;
    position: absolute;
    top: 50%;
    display: block;
    transform: translateY(-50%); }

.convert-article.convert-block:not(:empty) {
  margin-bottom: 2rem; }

.convert-article .convert-article-image {
  margin-bottom: 1rem; }

.convert-article .convert-article-content > a {
  color: #181818;
  text-decoration: none; }
  .convert-article .convert-article-content > a:hover, .convert-article .convert-article-content > a:focus {
    color: #1A2746; }

.convert-articles .convert-article:last-child:last-child {
  float: left; }

@media screen and (min-width: 64em) {
  .convert-articles .convert-article.xlarge-4:nth-child(3n+1) {
    clear: both; } }

@media screen and (min-width: 75em) {
  h1.convert-title {
    font-size: 56px; } }

h1.convert-title.as-h1 {
  font-size: 1.75rem; }

h1.convert-title.as-h2 {
  font-size: 1.75rem; }

h1.convert-title.as-h3 {
  font-size: 1.625rem; }

h1.convert-title.as-h4 {
  font-size: 1.5rem; }

h1.convert-title.as-h5 {
  font-size: 0.9375rem; }

h1.convert-title.as-h6 {
  font-size: 0.8125rem; }

@media print, screen and (min-width: 40em) {
  h1.convert-title.as-h1 {
    font-size: 2.625rem; }
  h1.convert-title.as-h2 {
    font-size: 2.125rem; }
  h1.convert-title.as-h3 {
    font-size: 1.6875rem; }
  h1.convert-title.as-h4 {
    font-size: 1.5625rem; }
  h1.convert-title.as-h5 {
    font-size: 1.0625rem; }
  h1.convert-title.as-h6 {
    font-size: 0.9375rem; } }

@media screen and (min-width: 64em) {
  h1.convert-title.as-h1 {
    font-size: 2.875rem; }
  h1.convert-title.as-h2 {
    font-size: 2.5rem; }
  h1.convert-title.as-h3 {
    font-size: 1.75rem; }
  h1.convert-title.as-h4 {
    font-size: 1.625rem; }
  h1.convert-title.as-h5 {
    font-size: 1.1875rem; }
  h1.convert-title.as-h6 {
    font-size: 1.0625rem; } }

body:not(.cms-page-view) .navbar-wrapper .page-header {
  margin-bottom: 1.6rem; }
  @media print, screen and (min-width: 48em) {
    body:not(.cms-page-view) .navbar-wrapper .page-header {
      margin-bottom: 1.5rem; } }
  @media screen and (min-width: 75em) {
    body:not(.cms-page-view) .navbar-wrapper .page-header {
      margin-bottom: 3rem; } }

@media screen and (max-width: 63.9375em) {
  .tablet-full-width .convert-column {
    width: 100%; }
  .tablet-full-width .convert-responsive-image {
    text-align: center;
    padding-bottom: 60px; } }

nav.convert-document-menu:not(.simple-doc-menu) ul {
  margin: 0;
  list-style: none;
  margin-top: 1rem;
  margin-bottom: 1rem; }
  nav.convert-document-menu:not(.simple-doc-menu) ul li {
    padding: 0; }
    nav.convert-document-menu:not(.simple-doc-menu) ul li:after {
      display: none; }
  @media screen and (min-width: 64em) {
    nav.convert-document-menu:not(.simple-doc-menu) ul {
      text-align: right;
      margin-top: 0; } }

nav.convert-document-menu:not(.simple-doc-menu) li + li {
  border-top: 1px solid rgba(24, 24, 24, 0.2); }

nav.convert-document-menu:not(.simple-doc-menu) li:after {
  display: none; }

nav.convert-document-menu:not(.simple-doc-menu) a {
  display: block;
  font-size: 1rem;
  font-family: "silka-regular-webfont", sans-serif;
  color: #181818;
  padding: .45rem .5rem .4rem;
  text-decoration: none; }
  nav.convert-document-menu:not(.simple-doc-menu) a:hover, nav.convert-document-menu:not(.simple-doc-menu) a:focus, nav.convert-document-menu:not(.simple-doc-menu) a:active {
    background-color: #F1EFED; }

.convert-snippet-inline-buttons .convert-block {
  margin-bottom: 0; }

.convert-snippet-inline-buttons > .column > .convert-block, .convert-snippet-inline-buttons > .columns > .convert-block {
  margin-bottom: 1rem; }

.convert-snippet-inline-buttons .convert-snippet .convert-snippet {
  display: inline-block;
  vertical-align: top; }

.convert-image-metadata {
  position: relative; }
  .convert-image-metadata img {
    width: 100%;
    height: auto; }
  .convert-image-metadata .convert-image-marker,
  .convert-image-metadata .convert-image-hotspot {
    position: absolute; }
    .convert-image-metadata .convert-image-marker a,
    .convert-image-metadata .convert-image-hotspot a {
      display: block;
      width: 100%;
      height: 100%; }
  .convert-image-metadata .convert-image-marker {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    background: url("../images/icon-marker.svg") no-repeat;
    background-size: 100% 100%;
    border-radius: 100%;
    box-shadow: 2px 2px 7px rgba(24, 24, 24, 0.1); }

.convert-image-hotspot {
  position: absolute; }
  .convert-image-hotspot a:hover {
    background: rgba(255, 255, 255, 0.15); }

.convert-image-marker a,
.convert-image-hotspot a {
  width: 100%;
  height: 100%;
  border-bottom: none;
  cursor: pointer; }

.convert-block .block-products-list,
.convert-block .block-new-products {
  margin-top: 0;
  margin-bottom: 0; }

.convert-button.has-subtext > * {
  white-space: nowrap; }

.convert-button.has-subtext h4,
.convert-button.has-subtext p {
  margin: 0; }

.convert-accordion {
  margin: 0;
  list-style: none; }
  .convert-accordion li {
    padding: 0; }
    .convert-accordion li:after {
      display: none; }
  .convert-accordion .accordion-title {
    font-weight: bold;
    text-decoration: none; }
  .convert-accordion .is-active .accordion-title {
    background-color: white; }
  .convert-accordion .is-active .accordion-content {
    border-top-color: white; }
  .convert-accordion .convert-wysiwyg p {
    line-height: 1.7; }
  .convert-accordion .accordion-item:not(.is-active) .accordion-content {
    border-top-color: transparent; }

.convert-banner {
  position: relative; }
  @media screen and (max-width: 47.9375em) {
    .convert-banner {
      margin-bottom: .5rem; } }
  .convert-banner:not[class^="spacing-"], .convert-banner:not[class*=" spacing-"] {
    margin-bottom: 0 !important; }
  .convert-banner .convert-banner-image {
    position: relative; }
    .convert-banner .convert-banner-image img {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 0; }
    .convert-banner .convert-banner-image .convert-banner-overlay {
      background-color: rgba(24, 24, 24, 0.6);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
  .convert-banner .convert-banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    width: 80%; }
    .convert-banner .convert-banner-content .convert-wysiwyg {
      margin-left: auto;
      margin-right: auto; }
      @media print, screen and (min-width: 48em) {
        .convert-banner .convert-banner-content .convert-wysiwyg {
          max-width: 53ch; } }
    .convert-banner .convert-banner-content.banner-text-left {
      left: 34px;
      right: 34px;
      transform: translateY(-50%);
      width: auto;
      text-align: left; }
      @media screen and (min-width: 64em) {
        .convert-banner .convert-banner-content.banner-text-left {
          left: 40px;
          right: 40px; } }
      .convert-banner .convert-banner-content.banner-text-left .convert-wysiwyg {
        margin-left: 0; }
        .convert-banner .convert-banner-content.banner-text-left .convert-wysiwyg > * {
          text-align: left; }
    .convert-banner .convert-banner-content.banner-text-right {
      left: 34px;
      right: 34px;
      transform: translateY(-50%);
      width: auto;
      text-align: right; }
      @media screen and (min-width: 64em) {
        .convert-banner .convert-banner-content.banner-text-right {
          left: 40px;
          right: 40px; } }
      .convert-banner .convert-banner-content.banner-text-right .convert-wysiwyg {
        margin-right: 0; }
        .convert-banner .convert-banner-content.banner-text-right .convert-wysiwyg > * {
          text-align: right; }
    .convert-banner .convert-banner-content > *,
    .convert-banner .convert-banner-content .convert-wysiwyg * {
      text-align: center; }
    .convert-banner .convert-banner-content .convert-wysiwyg p {
      line-height: 1.3; }
    .convert-banner .convert-banner-content *:last-child {
      margin-bottom: 0 !important; }
    .convert-banner .convert-banner-content .button {
      font-size: 15px !important;
      padding: 0.62em 2.3em 0.87em !important;
      margin-top: 1rem; }
  .convert-banner.cs-black .convert-banner-image .convert-banner-overlay {
    background-color: rgba(255, 255, 255, 0.6); }
  .convert-banner.cs-black .convert-wysiwyg * {
    color: rgba(24, 24, 24, 0.8); }
  .convert-banner.cs-black.has-content-overlay .convert-banner-content {
    background-color: rgba(255, 255, 255, 0.6); }
  .convert-banner.cs-white .convert-wysiwyg * {
    color: rgba(255, 255, 255, 0.8); }
  .convert-banner.cs-white .button {
    border-color: rgba(255, 255, 255, 0.5);
    background-color: transparent;
    color: white; }
    .convert-banner.cs-white .button:hover {
      background-color: rgba(255, 255, 255, 0.15); }
  .convert-banner.cs-white.has-content-overlay .convert-banner-content {
    background-color: rgba(24, 24, 24, 0.6); }
  .convert-banner.hover-jump:hover .convert-wysiwyg {
    transform: translate(0, -0.75rem) !important; }
  .convert-banner.hover-brightness img {
    transition: all 450ms cubic-bezier(0, 0.275, 0.125, 1); }
  .convert-banner.hover-brightness:hover img {
    filter: brightness(75%); }
  .convert-banner.has-content-overlay .convert-banner-content {
    padding: 34px; }
  .convert-banner.content--hover .convert-banner-content {
    opacity: 0;
    transition: all 450ms cubic-bezier(0, 0.275, 0.125, 1); }
  .convert-banner.content--hover:hover .convert-banner-content {
    opacity: 1; }

.convert-button.aligned {
  display: table; }
  .convert-button.aligned--center {
    margin-left: auto;
    margin-right: auto; }
  .convert-button.aligned--right {
    margin-left: auto;
    margin-right: 0; }

.convert-image.image--full-width img {
  display: block;
  width: 100%; }

.convert-image.aligned {
  display: table; }
  .convert-image.aligned--center {
    margin-left: auto;
    margin-right: auto; }
  .convert-image.aligned--right {
    margin-left: auto;
    margin-right: 0; }

.convert-image-metadata {
  position: relative; }
  .convert-image-metadata img {
    width: 100%; }

.convert-image-marker {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("../images/icon-marker.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 100%;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1); }

.convert-image-hotspot {
  position: absolute; }
  .convert-image-hotspot a:hover {
    background: rgba(255, 255, 255, 0.15); }

.convert-image-marker a,
.convert-image-hotspot a {
  width: 100%;
  height: 100%;
  border-bottom: none;
  cursor: pointer; }

.convert-grid-row.has-bg,
.convert-grid-row-flex.has-bg {
  overflow: hidden;
  position: relative; }
  .convert-grid-row.has-bg .convert-column,
  .convert-grid-row.has-bg .convert-column-flex,
  .convert-grid-row-flex.has-bg .convert-column,
  .convert-grid-row-flex.has-bg .convert-column-flex {
    position: relative; }

.convert-video {
  position: relative; }
  .convert-video .convert-video-poster {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center; }
  .convert-video .vjs-playing ~ .convert-video-poster,
  .convert-video .vjs-playing ~ .convert-video-content {
    visibility: hidden; }
  .convert-video .convert-video-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: white;
    text-align: center; }
    @media screen and (max-width: 47.9375em) {
      .convert-video .convert-video-content {
        width: 80%; } }
  .convert-video .convert-video-button {
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 auto 10px;
    cursor: pointer;
    background-image: url("../images/icon-video-play.svg");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 40px auto;
    border-radius: 100%;
    box-shadow: 3px 2px 25px rgba(24, 24, 24, 0.35); }
    @media screen and (max-width: 47.9375em) {
      .convert-video .convert-video-button {
        width: 30px;
        height: 30px;
        margin-bottom: 6px; } }
    @media screen and (max-width: 47.9375em) {
      .convert-video .convert-video-button {
        background-size: 30px auto; } }
    .convert-video .convert-video-button:hover {
      opacity: 0.75; }
  .convert-video .convert-video-button-label {
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    text-align: center;
    text-transform: none; }
    .convert-video .convert-video-button-label:hover {
      color: white;
      cursor: pointer; }
  .convert-video .convert-wysiwyg {
    margin-top: 10px; }

.convert-videobg {
  position: absolute; }
  .convert-videobg .convert-video-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0; }
  .convert-videobg.bg-overlay-light .convert-video-overlay {
    background-color: rgba(255, 255, 255, 0.5); }
  .convert-videobg.bg-overlay-dark .convert-video-overlay {
    background-color: rgba(0, 0, 0, 0.5); }

.convert-video-asset {
  left: 0;
  right: 0; }
  @supports (object-fit: cover) {
    .convert-video-asset .convert-video-ctr {
      padding-bottom: 0 !important; } }
  .convert-video-asset.bg-y-top {
    top: 0; }
  .convert-video-asset.bg-y-bottom {
    bottom: 0; }
  .convert-video-asset.bg-y-center {
    top: 50%;
    transform: translateY(-50%); }
  .convert-video-asset video {
    display: block;
    position: absolute; }
    @supports (object-fit: cover) {
      .convert-video-asset video {
        position: static;
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.convert-video-vimeo,
.convert-video-youtube {
  left: 0;
  right: 0; }
  .convert-video-vimeo.bg-y-top,
  .convert-video-youtube.bg-y-top {
    top: 0; }
  .convert-video-vimeo.bg-y-bottom,
  .convert-video-youtube.bg-y-bottom {
    bottom: 0; }
  .convert-video-vimeo.bg-y-center,
  .convert-video-youtube.bg-y-center {
    top: 50%;
    transform: translateY(-50%); }
  .convert-video-vimeo iframe,
  .convert-video-youtube iframe {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute; }

.has-img-bg .convert-img-bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat; }
  .has-img-bg .convert-img-bg.bg-x-left {
    background-position-x: 0; }
  .has-img-bg .convert-img-bg.bg-x-right {
    background-position-x: 100%; }
  .has-img-bg .convert-img-bg.bg-y-top {
    background-position-y: 0; }
  .has-img-bg .convert-img-bg.bg-y-bottom {
    background-position-y: 100%; }
  .has-img-bg .convert-img-bg.bg-size-cover {
    background-size: cover; }
  .has-img-bg .convert-img-bg .convert-image-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0; }
  .has-img-bg .convert-img-bg.bg-overlay-light .convert-image-overlay {
    background-color: rgba(255, 255, 255, 0.5); }
  .has-img-bg .convert-img-bg.bg-overlay-dark .convert-image-overlay {
    background-color: rgba(0, 0, 0, 0.5); }

button.action.secondary, button.action.captcha-reload {
  background-color: #1A2746;
  color: #fff; }
  button.action.secondary:hover, button.action.secondary:focus, button.action.captcha-reload:hover, button.action.captcha-reload:focus {
    background-color: black;
    color: #fff; }

.header .tab-bar .middle-side .logo {
  display: flex;
  justify-content: center;
  align-items: flex-start; }

.header .tab-bar .right-side .minicart-wrapper {
  opacity: initial; }

div.off-canvas {
  background-image: url("../images/bg__offcanvas.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100% - 2 * 1.875rem); }
  div.off-canvas .offcanvas-title {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }
  div.off-canvas .close-menu {
    position: absolute;
    right: 1.5rem;
    top: 0.75rem; }
    div.off-canvas .close-menu .close-button {
      color: white;
      position: static; }
    div.off-canvas .close-menu .txt {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }

.product-items .product-item .product.product-item-actions,
.product-items .ais-hits--item .product.product-item-actions,
.product-items .ais-infinite-hits--item .product.product-item-actions,
.ais-infinite-hits .product-item .product.product-item-actions,
.ais-infinite-hits .ais-hits--item .product.product-item-actions,
.ais-infinite-hits .ais-infinite-hits--item .product.product-item-actions {
  display: inherit; }

@media screen and (max-width: 63.9375em) {
  .product-items .ais-hits--item .product-item-details .product-item-inner {
    position: inherit !important;
    width: inherit;
    height: inherit;
    overflow: inherit;
    clip: inherit; }
  .product-items .product-item .product-item-details .product-item-actions,
  .product-items .product-item .product-item-details .product-item-inner {
    position: inherit !important;
    width: inherit;
    height: inherit;
    overflow: inherit;
    clip: inherit; }
  .no-touchevents .product-items .ais-hits--item .product-item-actions button.action,
  .no-touchevents .product-items .product-item .product-item-actions button.action {
    opacity: 1;
    z-index: 1; } }

#clear-refinements {
  position: relative;
  top: auto;
  right: auto; }

.ais-ClearRefinements-button {
  width: 100%;
  background-color: #1A2746 !important;
  color: #fff !important;
  text-decoration: none;
  font-size: 1.1875rem !important;
  margin-bottom: 1.15rem !important;
  padding: 0.82em 1em 0.87em !important; }
  .ais-ClearRefinements-button:hover, .ais-ClearRefinements-button:focus {
    background-color: #1A2746 !important;
    color: #fff !important; }
  .ais-ClearRefinements-button--disabled {
    display: none !important; }

.ais-CurrentRefinements {
  margin-bottom: 0;
  border-top: none; }
  .ais-CurrentRefinements .ais-CurrentRefinements-list {
    border-top: none; }
  .ais-CurrentRefinements .ais-CurrentRefinements-item {
    padding: 0.5rem 1.8rem 0.5rem 0 !important; }
    .ais-CurrentRefinements .ais-CurrentRefinements-item + .ais-CurrentRefinements-item {
      border-top: none !important; }
    .ais-CurrentRefinements .ais-CurrentRefinements-item:hover .ais-CurrentRefinements-label,
    .ais-CurrentRefinements .ais-CurrentRefinements-item:hover .ais-CurrentRefinements-category, .ais-CurrentRefinements .ais-CurrentRefinements-item:focus .ais-CurrentRefinements-label,
    .ais-CurrentRefinements .ais-CurrentRefinements-item:focus .ais-CurrentRefinements-category {
      text-decoration: line-through; }
    .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-label {
      display: inline-block;
      margin-right: 0;
      padding-left: 0 !important; }
      .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-label:before {
        display: none; }
    .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-category {
      display: inline-block;
      padding-left: 0; }
    .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-delete {
      padding: 0;
      font-size: 0;
      background-size: 24px auto;
      background-position: center right;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }

.ais-Hits,
.ais-InfiniteHits {
  padding-top: 0; }

.ais-HierarchicalMenu-list .ais-HierarchicalMenu-link--selected .cross-circle {
  display: none; }

.is-widget-container-categories .ais-Panel .ais-Panel-header {
  display: none; }

.is-widget-container-categories .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item:last-child {
  border-bottom: 1px solid rgba(24, 24, 24, 0.15); }

.is-widget-container-categories .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link {
  font-size: 0.9375rem;
  padding: 0.5rem 1.8rem 0.5rem 0;
  background: url("../images/icon-arrow-next-gray.svg") calc(100% - 8px) 50% no-repeat;
  background-size: 7px auto; }
  @media print, screen and (min-width: 48em) {
    .is-widget-container-categories .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link {
      font-size: 1.0625rem; } }
  @media screen and (min-width: 64em) {
    .is-widget-container-categories .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link {
      font-size: 1.125rem; } }
  .is-widget-container-categories .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link:before, .is-widget-container-categories .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link:after {
    display: none !important; }
  .is-widget-container-categories .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link:hover, .is-widget-container-categories .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link:focus {
    text-decoration: underline; }
  .is-widget-container-categories .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link .ais-HierarchicalMenu-count {
    display: none; }

.is-widget-container-categories .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected .ais-HierarchicalMenu-link {
  background: url("../images/icon-valid-black.svg") calc(100% - 8px) 50% no-repeat;
  background-size: 15px auto;
  padding-left: 0;
  font-weight: bold; }

.is-widget-container-categories .ais-HierarchicalMenu-list .ais-HierarchicalMenu-list--child .ais-HierarchicalMenu-item {
  border-bottom: none; }
  .is-widget-container-categories .ais-HierarchicalMenu-list .ais-HierarchicalMenu-list--child .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link {
    font-size: 0.875rem; }
    @media print, screen and (min-width: 48em) {
      .is-widget-container-categories .ais-HierarchicalMenu-list .ais-HierarchicalMenu-list--child .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link {
        font-size: 0.9375rem; } }
    @media screen and (min-width: 64em) {
      .is-widget-container-categories .ais-HierarchicalMenu-list .ais-HierarchicalMenu-list--child .ais-HierarchicalMenu-item .ais-HierarchicalMenu-link {
        font-size: 1rem; } }

.ais-RangeSlider {
  padding-top: .75rem; }
  .ais-RangeSlider .rheostat:after {
    display: none; }
  .ais-RangeSlider .rheostat-progress {
    display: none; }

.ais-RefinementList.has-images-labels.color .ais-RefinementList-list {
  display: flex;
  flex-wrap: wrap;
  border: none; }
  .ais-RefinementList.has-images-labels.color .ais-RefinementList-list .ais-RefinementList-item {
    margin: 5px 8px 5px 0; }
    .ais-RefinementList.has-images-labels.color .ais-RefinementList-list .ais-RefinementList-item.ais-RefinementList-item--selected > div {
      background-color: #1A2746;
      box-shadow: 1px 1px 2px rgba(24, 24, 24, 0.5) inset; }
      .ais-RefinementList.has-images-labels.color .ais-RefinementList-list .ais-RefinementList-item.ais-RefinementList-item--selected > div .label {
        color: white; }
    .ais-RefinementList.has-images-labels.color .ais-RefinementList-list .ais-RefinementList-item > div {
      padding: 0.5625rem 1.25rem 0.5625rem 0.5625rem;
      box-shadow: inset 0 0 0 1px rgba(24, 24, 24, 0.15);
      border-radius: 18px;
      line-height: 18px;
      font-size: 0;
      background: white; }
      .ais-RefinementList.has-images-labels.color .ais-RefinementList-list .ais-RefinementList-item > div .swatch-option.color {
        box-shadow: 0 0 0 1px rgba(24, 24, 24, 0.15) inset; }
      .ais-RefinementList.has-images-labels.color .ais-RefinementList-list .ais-RefinementList-item > div .label {
        font-size: 13px;
        line-height: inherit;
        margin-left: 6px; }
      .ais-RefinementList.has-images-labels.color .ais-RefinementList-list .ais-RefinementList-item > div .count {
        display: none; }

.ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item,
.ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item,
.ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item {
  display: block;
  position: relative;
  text-transform: none;
  font-family: inherit;
  font-size: 0.97875rem;
  font-weight: inherit;
  color: rgba(24, 24, 24, 0.9);
  border: none;
  padding: 0;
  cursor: pointer; }
  @media screen and (min-width: 64em) {
    .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item,
    .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item,
    .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item {
      font-size: 1.125rem; } }
  .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item:last-child,
  .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item:last-child,
  .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item:last-child {
    border-bottom: 0; }
  .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item .swatch-option,
  .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item .swatch-option,
  .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item .swatch-option {
    width: 18px;
    height: 18px; }
  .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item .label,
  .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item .label,
  .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item .label {
    cursor: inherit;
    text-decoration: none;
    margin-left: .25rem;
    padding: 0;
    vertical-align: middle;
    color: #181818;
    font-size: inherit;
    line-height: inherit;
    white-space: normal;
    background: none; }
  .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item:hover,
  .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item:hover,
  .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item:hover {
    color: #181818;
    background-color: transparent;
    text-decoration: none; }
    .ais-RefinementList.has-images-labels .ais-RefinementList-list .ais-RefinementList-item:hover .label,
    .ais-RefinementList.has-images-labels .ais-CurrentRefinements-list .ais-RefinementList-item:hover .label,
    .ais-RefinementList.has-images-labels .ais-HierarchicalMenu-list .ais-RefinementList-item:hover .label {
      text-decoration: underline; }

.page.messages {
  max-width: 90.625rem;
  position: relative;
  margin: 0 auto;
  float: none; }

.mm-menu div.mm-panel {
  background-image: url("../images/bg__offcanvas.png");
  background-position: 100% 100%;
  background-repeat: no-repeat; }
  .mm-menu div.mm-panel .mm-navbar__title {
    align-items: flex-end;
    justify-content: left;
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.8125rem;
    padding: 0 0 10px 28px; }
  .mm-menu div.mm-panel .mm-navbar__btn:not(.mm-hidden) + .mm-navbar__title {
    margin-left: -20px; }
  .mm-menu div.mm-panel a {
    color: white; }
  .mm-menu div.mm-panel .mm-listitem.active > .mm-listitem__text,
  .mm-menu div.mm-panel .mm-listitem_opened > .mm-listitem__text {
    color: white; }

.mm-menu .extra-links .mm-listitem__btn,
.mm-menu .extra-links .mm-listitem__text {
  color: rgba(255, 255, 255, 0.8); }

.mm-offcanvas-links .offcanvas-links ul li {
  color: rgba(255, 255, 255, 0.5); }

.minisearch input.input-text {
  border-radius: 26px;
  transition: box-shadow .3s ease; }

.minisearch button.search.disabled, .minisearch button.search[disabled] {
  background-color: transparent; }

.minisearch .actions {
  right: 8px; }

#algolia-searchbox input.algolia-search-input:hover, #algolia-searchbox input.algolia-search-input:active, #algolia-searchbox input.algolia-search-input:focus, #algolia-searchbox input.algolia-search-input:focus:not([value=""]), #algolia-searchbox input.algolia-search-input.focus {
  border-width: 0; }

.mobile-search-wrapper {
  transition: none; }
  .mobile-search-wrapper .mobile-search-bar {
    box-shadow: none;
    padding: 3px 23px 14px !important;
    background-color: #1A2746; }
    .mobile-search-wrapper .mobile-search-bar #algolia-searchbox .clear-cross, .mobile-search-wrapper .mobile-search-bar #algolia-searchbox .ais-search-box--reset-wrapper {
      background-image: url("../images/icon-deselect-white.svg");
      background-size: 1rem auto; }
    .mobile-search-wrapper .mobile-search-bar #algolia-searchbox .algolia-search-input,
    .mobile-search-wrapper .mobile-search-bar .minisearch .input-text {
      border-radius: 2.9375rem;
      color: white; }
  .mobile-search-wrapper.in-scroll {
    z-index: 901; }
    .mobile-search-wrapper.in-scroll .mobile-search-bar {
      padding: 7px 0 0 !important;
      border-bottom: 0; }

.is-sticky .page-header,
.in-scroll-main .page-header {
  z-index: 901; }

.page-header .header .minisearch .actions {
  right: 8px; }

.page-header .header .sticky-mm {
  box-shadow: none; }
  .page-header .header .sticky-mm.in-scroll {
    z-index: 901; }
    .page-header .header .sticky-mm.in-scroll:after {
      display: none; }
    .page-header .header .sticky-mm.in-scroll .tab-bar {
      padding-top: 0;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3), 0 0 15px rgba(0, 0, 0, 0.15); }
    .page-header .header .sticky-mm.in-scroll .middle-side {
      height: 4.5rem;
      line-height: 4.5rem;
      opacity: 0; }
    .page-header .header .sticky-mm.in-scroll .right-side,
    .page-header .header .sticky-mm.in-scroll .left-side {
      height: 4.5rem; }
      .page-header .header .sticky-mm.in-scroll .right-side .minicart-wrapper,
      .page-header .header .sticky-mm.in-scroll .right-side .nav-toggle,
      .page-header .header .sticky-mm.in-scroll .left-side .minicart-wrapper,
      .page-header .header .sticky-mm.in-scroll .left-side .nav-toggle {
        height: 4.5rem;
        line-height: 4.5rem; }

.page-header .header .tab-bar {
  display: flex;
  justify-content: space-between; }
  .page-header .header .tab-bar:before, .page-header .header .tab-bar:after {
    display: none; }
  .page-header .header .tab-bar .middle-side {
    display: flex;
    align-items: center;
    line-height: normal;
    padding: 0; }
    .page-header .header .tab-bar .middle-side .logo {
      height: auto; }
  .page-header .header .tab-bar .right-side {
    width: auto;
    padding-left: 0;
    margin-left: auto; }
    .page-header .header .tab-bar .right-side .minicart-wrapper {
      opacity: 1; }
      .page-header .header .tab-bar .right-side .minicart-wrapper .action.showcart {
        transform: none; }
    .page-header .header .tab-bar .right-side .miniquote-wrapper {
      height: 4.5rem;
      line-height: 4.5rem; }
  .page-header .header .tab-bar .left-side {
    width: auto;
    padding-right: 0;
    margin-right: auto; }
    .page-header .header .tab-bar .left-side .nav-toggle {
      min-width: 44px;
      background-size: 20px auto; }

.page-header .header .minicart-wrapper {
  margin: 0; }

body:not(.checkout-index-index) .page-header .header .header-row .sticky-header-row {
  display: flex; }

.page-header .header .header-row .logo {
  width: auto; }
  .page-header .header .header-row .logo:before {
    display: none; }
  .page-header .header .header-row .logo img {
    width: 123px; }
    .is-sticky .page-header .header .header-row .logo img {
      width: 80px; }

@media screen and (min-width: 64em) {
  .page-header .header .header-row .top-menu {
    margin-right: 16px; } }

.page-header .header .header-row .nav-search-wrapper {
  width: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: 1.3rem; }
  .page-header .header .header-row .nav-search-wrapper .panel.wrapper {
    justify-content: flex-end;
    width: auto;
    padding: 0; }
  .page-header .header .header-row .nav-search-wrapper #search {
    border-radius: 56px; }
    .page-header .header .header-row .nav-search-wrapper #search::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.7); }
    .page-header .header .header-row .nav-search-wrapper #search:-moz-placeholder {
      color: rgba(255, 255, 255, 0.7); }
    .page-header .header .header-row .nav-search-wrapper #search::-moz-placeholder {
      color: rgba(255, 255, 255, 0.7); }
    .page-header .header .header-row .nav-search-wrapper #search:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.7); }
  .page-header .header .header-row .nav-search-wrapper .clear-cross, .page-header .header .header-row .nav-search-wrapper .mobile-search-bar #algolia-searchbox .clear-cross, .mobile-search-bar #algolia-searchbox .page-header .header .header-row .nav-search-wrapper .clear-cross, .page-header .header .header-row .nav-search-wrapper #algolia-searchbox .ais-search-box--reset-wrapper, #algolia-searchbox .page-header .header .header-row .nav-search-wrapper .ais-search-box--reset-wrapper {
    background-image: url("../images/icon-deselect-white.svg"); }

.page-header .header .header-row .top-panel-wrapper {
  display: flex;
  height: 100%;
  align-items: center; }
  .page-header .header .header-row .top-panel-wrapper:before, .page-header .header .header-row .top-panel-wrapper:after {
    display: none; }
  .page-header .header .header-row .top-panel-wrapper .top-nav-wrapper {
    flex-grow: 1; }
    .page-header .header .header-row .top-panel-wrapper .top-nav-wrapper:before, .page-header .header .header-row .top-panel-wrapper .top-nav-wrapper:after {
      display: none; }
    .page-header .header .header-row .top-panel-wrapper .top-nav-wrapper .nav-sections {
      width: auto !important;
      height: auto; }
  .page-header .header .header-row .top-panel-wrapper .top-search-wrapper {
    padding-right: 0 !important;
    flex: 1 1 auto;
    margin-left: auto;
    max-width: 738px; }
    .page-header .header .header-row .top-panel-wrapper .top-search-wrapper .block-search {
      height: auto;
      display: block;
      background: transparent;
      box-shadow: none; }
  .page-header .header .header-row .top-panel-wrapper .header-control-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 20px; }
  .page-header .header .header-row .top-panel-wrapper .panel.header {
    margin: 0; }

.is-sticky .page-header .header .header-row .logo {
  width: 150px; }
  @media screen and (min-width: 64em) {
    .is-sticky .page-header .header .header-row .logo {
      width: 123px; } }

.is-sticky .page-header .header .header-row .switcher-website {
  display: none; }

body:not(.checkout-index-index) .is-sticky .page-header .header .header-row .sticky-header-row {
  display: flex; }

.is-sticky .page-header .header .header-row .sticky-header-row .top-menu {
  order: 1; }

.is-sticky .page-header .header .header-row .sticky-header-row .top-panel-wrapper {
  padding-top: 0;
  margin-left: 0; }
  .is-sticky .page-header .header .header-row .sticky-header-row .top-panel-wrapper .top-search-wrapper {
    width: 70%;
    order: 2; }

.is-sticky .page-header .header .header-row .sticky-header-row .header-control-wrapper {
  order: 3; }

.is-sticky .page-header .header .header-row .sticky-header-row .nav-search-wrapper {
  padding-top: 0;
  width: 100%;
  padding-left: 170px;
  order: 2; }
  @media screen and (min-width: 64em) {
    .is-sticky .page-header .header .header-row .sticky-header-row .nav-search-wrapper {
      padding-left: 163px; } }
  .is-sticky .page-header .header .header-row .sticky-header-row .nav-search-wrapper .header-control-wrapper {
    align-items: center; }
  .is-sticky .page-header .header .header-row .sticky-header-row .nav-search-wrapper .links li {
    padding-top: 0; }
    .is-sticky .page-header .header .header-row .sticky-header-row .nav-search-wrapper .links li a {
      font-size: 0; }
      .is-sticky .page-header .header .header-row .sticky-header-row .nav-search-wrapper .links li a:before {
        margin-bottom: 0; }
  .is-sticky .page-header .header .header-row .sticky-header-row .nav-search-wrapper .block-search {
    position: static;
    padding: 0; }

.page-header .header .links {
  display: flex;
  margin: -2px 13px 0 0; }
  @media screen and (min-width: 75em) {
    .page-header .header .links {
      margin-right: 26px; } }
  .page-header .header .links li {
    margin-left: 13px; }
  .page-header .header .links li.link {
    text-align: center;
    padding: 0; }
    .page-header .header .links li.link a {
      display: block;
      width: 52px;
      height: 52px;
      transform: translateY(0);
      margin: 0;
      font-size: 0;
      padding: 0;
      background-size: 36px auto;
      background-repeat: no-repeat;
      background-position: 50%; }
      .page-header .header .links li.link a:before {
        display: none; }
    .page-header .header .links li.link.customer-section {
      order: 2; }
      .page-header .header .links li.link.customer-section a {
        padding: 0;
        background-image: url("../images/icon-user.svg"); }
        .page-header .header .links li.link.customer-section a:after {
          display: none; }
    .page-header .header .links li.link.wishlist {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      order: 1;
      display: inline-block !important; }
      .page-header .header .links li.link.wishlist a {
        background-size: 40px auto;
        background-image: url("../images/icon-wishlist.svg"); }
        .page-header .header .links li.link.wishlist a:hover, .page-header .header .links li.link.wishlist a:active, .page-header .header .links li.link.wishlist a:focus {
          background-image: url("../images/icon-wishlist-active.svg"); }
    .page-header .header .links li.link.authorization-link {
      order: 4; }
      .page-header .header .links li.link.authorization-link a {
        background-image: url("../images/icon-user.svg"); }
      @media print, screen and (min-width: 48em) {
        .page-header .header .links li.link.authorization-link:not(.loggedout) {
          display: none; } }

.page-header .header .miniquote-wrapper {
  margin-left: 10px;
  margin-top: 0;
  position: relative; }
  @media screen and (min-width: 64em) {
    .page-header .header .miniquote-wrapper {
      margin-left: 15px; } }
  .page-header .header .miniquote-wrapper a {
    font-family: "silka-medium-webfont", sans-serif;
    font-size: 0.875rem;
    color: #1A2746;
    text-decoration: underline; }
    .page-header .header .miniquote-wrapper a:hover {
      text-decoration: none; }
  .page-header .header .miniquote-wrapper .label {
    font-family: "silka-medium-webfont", sans-serif;
    font-size: 0.875rem;
    color: #1A2746;
    margin-bottom: 4px; }
  .page-header .header .miniquote-wrapper .action.showquote {
    margin: 0;
    position: relative;
    vertical-align: middle; }
    .page-header .header .miniquote-wrapper .action.showquote:before {
      content: '' !important;
      font-size: 0;
      display: block;
      width: 44px;
      height: 44px;
      background-image: url("../images/icon-check-quote.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 31px 34px; }
    .page-header .header .miniquote-wrapper .action.showquote .counter.qty {
      position: absolute;
      bottom: auto;
      top: 5px;
      right: 3px;
      min-width: 1.125rem;
      height: 1.125rem;
      line-height: 1.125rem;
      font-size: 0.75rem;
      padding: 0;
      border-radius: 50%;
      background-color: #30A95E; }
  .page-header .header .miniquote-wrapper .action.close {
    top: 5px;
    right: 8px; }
    .page-header .header .miniquote-wrapper .action.close:before {
      color: #000;
      font-size: 30px;
      line-height: 30px; }
  .page-header .header .miniquote-wrapper.active .block-miniquote {
    margin-top: -11px; }
    @media print, screen and (min-width: 48em) {
      .page-header .header .miniquote-wrapper.active .block-miniquote {
        margin-top: 0; } }
  .page-header .header .miniquote-wrapper .block-miniquote {
    width: 280px;
    padding: 60px 15px 30px;
    right: 0;
    text-align: left;
    line-height: 1;
    border: none;
    box-shadow: 3px 3px 10px rgba(24, 24, 24, 0.2); }
    @media print, screen and (min-width: 40em) {
      .page-header .header .miniquote-wrapper .block-miniquote {
        width: 390px;
        padding: 60px 25px 50px; } }
    .page-header .header .miniquote-wrapper .block-miniquote:after {
      display: none; }
    .page-header .header .miniquote-wrapper .block-miniquote:before {
      left: auto;
      right: 10px;
      border: solid 12px;
      border-color: transparent transparent #fff transparent;
      top: -24px; }
    .page-header .header .miniquote-wrapper .block-miniquote .items-total {
      display: none; }
    .page-header .header .miniquote-wrapper .block-miniquote .block-content > .actions > .primary {
      margin: 0; }
      .page-header .header .miniquote-wrapper .block-miniquote .block-content > .actions > .primary .action.primary {
        font-size: 1.0625rem;
        padding: 8px 17px; }
        @media print, screen and (min-width: 40em) {
          .page-header .header .miniquote-wrapper .block-miniquote .block-content > .actions > .primary .action.primary {
            font-size: 1.1875rem;
            padding: 12px 17px; } }
    .page-header .header .miniquote-wrapper .block-miniquote .actions.primary {
      text-align: center;
      margin-top: 26px; }
  .page-header .header .miniquote-wrapper .miniquote-items-wrapper {
    border: none;
    margin: 0 -25px;
    padding: 0 25px;
    overflow-x: inherit;
    overflow-y: auto;
    max-height: 500px; }
  .page-header .header .miniquote-wrapper .miniquote-items .product-item-details {
    padding-left: 65px;
    padding-right: 35px;
    position: relative; }
    @media print, screen and (min-width: 40em) {
      .page-header .header .miniquote-wrapper .miniquote-items .product-item-details {
        padding-left: 100px; } }
    .page-header .header .miniquote-wrapper .miniquote-items .product-item-details .price {
      font-family: "silka-semibold-webfont", sans-serif;
      font-size: 1rem;
      font-weight: normal; }
      @media print, screen and (min-width: 40em) {
        .page-header .header .miniquote-wrapper .miniquote-items .product-item-details .price {
          font-size: 1.125rem; } }
    .page-header .header .miniquote-wrapper .miniquote-items .product-item-details .options {
      display: none; }
  .page-header .header .miniquote-wrapper .miniquote-items .product .product-top {
    overflow: hidden;
    margin-bottom: 20px; }
    .page-header .header .miniquote-wrapper .miniquote-items .product .product-top .product-item-photo,
    .page-header .header .miniquote-wrapper .miniquote-items .product .product-top .product-image-container {
      float: left;
      width: 50px !important;
      text-align: center; }
      @media print, screen and (min-width: 40em) {
        .page-header .header .miniquote-wrapper .miniquote-items .product .product-top .product-item-photo,
        .page-header .header .miniquote-wrapper .miniquote-items .product .product-top .product-image-container {
          width: 80px !important; } }
  .page-header .header .miniquote-wrapper .miniquote-items .product .product.actions {
    float: none;
    margin: 0;
    position: absolute;
    top: -3px;
    right: -4px; }
  .page-header .header .miniquote-wrapper .miniquote-items .product-item-name {
    display: inline-block;
    margin: 0 0 3px; }
    .page-header .header .miniquote-wrapper .miniquote-items .product-item-name a {
      font-size: 0.8125rem;
      line-height: 1;
      color: #1A2746; }
      @media print, screen and (min-width: 40em) {
        .page-header .header .miniquote-wrapper .miniquote-items .product-item-name a {
          font-size: 0.9375rem; } }
  .page-header .header .miniquote-wrapper .miniquote-items .action.edit {
    display: none; }
  .page-header .header .miniquote-wrapper .miniquote-items .action.delete:before {
    content: '';
    width: 30px;
    height: 30px;
    background-image: url("../images/icon-trash.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 44px; }
  .page-header .header .miniquote-wrapper .miniquote-items .update-quote-item {
    background-color: #1A2746;
    color: white;
    font-family: "silka-medium-webfont", sans-serif;
    font-size: 0.875rem;
    padding: 9px 30px;
    border-radius: 50px;
    float: right;
    cursor: pointer; }
    .page-header .header .miniquote-wrapper .miniquote-items .update-quote-item:hover, .page-header .header .miniquote-wrapper .miniquote-items .update-quote-item:focus {
      background-color: #283c6b;
      color: white; }
    @media print, screen and (min-width: 40em) {
      .page-header .header .miniquote-wrapper .miniquote-items .update-quote-item {
        font-size: 0.9375rem;
        padding: 20px 40px; } }
  .page-header .header .miniquote-wrapper .miniquote-widgets {
    margin: 0; }
  .page-header .header .miniquote-wrapper #miniquote-movetocart {
    background: transparent;
    width: auto;
    color: #1A2746;
    font-family: "silka-medium-webfont", sans-serif;
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid #D41367;
    border-radius: 0;
    padding: 0 0 5px; }
    .page-header .header .miniquote-wrapper #miniquote-movetocart:hover {
      border: none;
      border-bottom-color: transparent; }
    @media print, screen and (min-width: 40em) {
      .page-header .header .miniquote-wrapper #miniquote-movetocart {
        font-size: 1.1875rem; } }
  .page-header .header .miniquote-wrapper .details-qty {
    overflow: hidden; }
    .page-header .header .miniquote-wrapper .details-qty .label {
      display: block;
      margin-bottom: 7px; }
    .page-header .header .miniquote-wrapper .details-qty .qty-field {
      margin-bottom: 0; }
      @media screen and (max-width: 39.9375em) {
        .page-header .header .miniquote-wrapper .details-qty .qty-field input[type="number"] {
          font-size: 15px;
          height: 32px;
          width: 100px; }
        .page-header .header .miniquote-wrapper .details-qty .qty-field .qty-field__control {
          width: 30px;
          height: 30px; }
          .page-header .header .miniquote-wrapper .details-qty .qty-field .qty-field__control span {
            font-size: 24px;
            line-height: 24px; } }
  .page-header .header .miniquote-wrapper .product-link {
    text-align: center;
    margin-top: 20px; }

.page-header .header .section-item-title {
  display: none; }

.nav-sections-items > div:last-child {
  display: none; }

.action.showcart {
  background-size: auto 38px; }
  .action.showcart .counter {
    bottom: auto;
    top: 3px;
    right: 0;
    padding-left: 0;
    padding-right: 0; }

.add-to-cart-popup .add-popup-showcart .action.showcart, .add-to-cart-popup .add-popup-showcart .action.showquote {
  top: auto;
  left: auto;
  transform: none; }

.add-to-cart-popup .add-popup-showcart .action.showquote {
  display: inline-block;
  width: 44px;
  height: 44px;
  position: relative;
  background-image: url("../images/icon-check-quote.svg");
  background-size: 31px 34px;
  background-position: 50%;
  background-repeat: no-repeat; }
  .add-to-cart-popup .add-popup-showcart .action.showquote > .text {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }
  .add-to-cart-popup .add-popup-showcart .action.showquote .counter.qty {
    display: inline-block;
    position: absolute;
    bottom: auto;
    top: 5px;
    right: 3px;
    min-width: 1.125rem;
    height: 1.125rem;
    line-height: 1.125rem;
    font-size: 0.75rem;
    padding: 0;
    border-radius: 50%;
    background-color: #30A95E;
    color: white;
    text-align: center; }

.top-menu {
  position: relative; }
  .top-menu .top-menu-button {
    border: 0; }
    .top-menu .top-menu-button span {
      border-bottom: 1px solid #D52469; }
    .top-menu .top-menu-button.is-active {
      background-color: #30A95E;
      box-shadow: inset 2px 2px 4px rgba(24, 24, 24, 0.5); }
  .top-menu .top-menu-panel {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 6px;
    z-index: 1; }
    @media screen and (min-width: 75em) {
      .top-menu .top-menu-panel {
        left: -55px; } }
    .top-menu .top-menu-panel .nav-sections {
      width: auto;
      min-width: 190px;
      height: auto;
      padding: 0;
      float: none;
      position: relative; }
      @media screen and (min-width: 75em) {
        .top-menu .top-menu-panel .nav-sections {
          min-width: 290px; } }
      .top-menu .top-menu-panel .nav-sections:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 16px 16px 16px;
        border-color: transparent transparent white transparent; }
      .top-menu .top-menu-panel .nav-sections .navigation {
        margin: 0;
        padding: 0; }
        .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu {
          position: relative; }
        .top-menu .top-menu-panel .nav-sections .navigation .ui-menu {
          border-radius: 8px; }
          .top-menu .top-menu-panel .nav-sections .navigation .ui-menu .ui-menu-item:first-child > a {
            border-radius: 8px 8px 0 0; }
          .top-menu .top-menu-panel .nav-sections .navigation .ui-menu .ui-menu-item:last-child > a {
            border-radius: 0 0 8px 8px; }
          .top-menu .top-menu-panel .nav-sections .navigation .ui-menu .ui-menu-item > a {
            display: block;
            font-size: 15px !important;
            font-family: "silka-medium-webfont", sans-serif !important;
            font-weight: normal !important;
            border-bottom: 1px solid rgba(24, 24, 24, 0.15);
            padding: 12px 45px 12px 21px !important;
            color: #1A2746 !important;
            line-height: normal;
            background-image: url("../images/icon-arrow-next-gray.svg") !important;
            background-size: 7px auto !important;
            background-position: calc(100% - 22px) 50% !important;
            background-repeat: no-repeat !important; }
            .top-menu .top-menu-panel .nav-sections .navigation .ui-menu .ui-menu-item > a.ui-state-focus, .top-menu .top-menu-panel .nav-sections .navigation .ui-menu .ui-menu-item > a.ui-state-active, .top-menu .top-menu-panel .nav-sections .navigation .ui-menu .ui-menu-item > a.has-active, .top-menu .top-menu-panel .nav-sections .navigation .ui-menu .ui-menu-item > a:hover, .top-menu .top-menu-panel .nav-sections .navigation .ui-menu .ui-menu-item > a:active, .top-menu .top-menu-panel .nav-sections .navigation .ui-menu .ui-menu-item > a:focus {
              color: white !important;
              background-color: #1A2746;
              background-image: url("../images/icon-arrow-next-white.svg") !important; }
            .top-menu .top-menu-panel .nav-sections .navigation .ui-menu .ui-menu-item > a span {
              display: block; }
        .top-menu .top-menu-panel .nav-sections .navigation > ul,
        .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu {
          display: block;
          background-color: white; }
          .top-menu .top-menu-panel .nav-sections .navigation > ul > li,
          .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item,
          .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li,
          .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item {
            display: block;
            line-height: 1; }
            .top-menu .top-menu-panel .nav-sections .navigation > ul > li.parent > a:after,
            .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.parent > a:after,
            .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.parent > a:after,
            .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.parent > a:after {
              display: none; }
            .top-menu .top-menu-panel .nav-sections .navigation > ul > li:nth-child(2) > a,
            .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item:nth-child(2) > a,
            .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li:nth-child(2) > a,
            .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item:nth-child(2) > a {
              border-radius: 8px 8px 0 0; }
            .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu,
            .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu,
            .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu,
            .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu {
              min-width: 190px;
              width: auto;
              height: auto;
              min-height: 100% !important;
              top: 0 !important;
              bottom: auto;
              left: 100% !important;
              margin: 0;
              padding: 0;
              border: 0 !important;
              background-color: #eaf0f0; }
              .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu:before, .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu:after,
              .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu:before,
              .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu:after,
              .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu:before,
              .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu:after,
              .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu:before,
              .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu:after {
                display: none; }
              @media screen and (min-width: 75em) {
                .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu,
                .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu,
                .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu,
                .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu {
                  min-width: 290px; } }
              .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu .ui-menu,
              .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu,
              .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu .ui-menu,
              .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu {
                line-height: 1;
                background-color: #eaf0f0; }
                .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a,
                .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a,
                .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a,
                .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a {
                  height: auto;
                  line-height: normal;
                  background-color: transparent; }
                  .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:after, .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:before,
                  .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:after,
                  .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:before,
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:after,
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:before,
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:after,
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:before {
                    display: none !important; }
                  .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a.ui-state-focus, .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a.ui-state-active, .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a.has-active, .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:hover, .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:active, .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:focus,
                  .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a.ui-state-focus,
                  .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a.ui-state-active,
                  .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a.has-active,
                  .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:hover,
                  .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:active,
                  .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:focus,
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a.ui-state-focus,
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a.ui-state-active,
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a.has-active,
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:hover,
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:active,
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:focus,
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a.ui-state-focus,
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a.ui-state-active,
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a.has-active,
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:hover,
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:active,
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a:focus {
                    color: white !important;
                    background-color: #1A2746;
                    background-image: url("../images/icon-arrow-next-white.svg") !important; }
                  .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a span:not(.ui-menu-icon),
                  .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a span:not(.ui-menu-icon),
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a span:not(.ui-menu-icon),
                  .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item > a span:not(.ui-menu-icon) {
                    display: block;
                    white-space: nowrap;
                    line-height: inherit;
                    padding: 0; }
                .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item:last-child > a,
                .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item:last-child > a,
                .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item:last-child > a,
                .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu .ui-menu .ui-menu-item:last-child > a {
                  border-radius: 0; }
              .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical .submenu .i-close-submenu,
              .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical .submenu .i-close-submenu,
              .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical .submenu .i-close-submenu,
              .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical .submenu .i-close-submenu {
                display: none; }
            .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical:hover > .submenu, .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical:active > .submenu, .top-menu .top-menu-panel .nav-sections .navigation > ul > li.menu-type-complex-vertical:focus > .submenu,
            .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical:hover > .submenu,
            .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical:active > .submenu,
            .top-menu .top-menu-panel .nav-sections .navigation > ul > .ui-menu-item.menu-type-complex-vertical:focus > .submenu,
            .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical:hover > .submenu,
            .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical:active > .submenu,
            .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > li.menu-type-complex-vertical:focus > .submenu,
            .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical:hover > .submenu,
            .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical:active > .submenu,
            .top-menu .top-menu-panel .nav-sections .navigation > .ui-menu > .ui-menu-item.menu-type-complex-vertical:focus > .submenu {
              display: block !important;
              top: 0 !important; }

.topmenu-fade-layer {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(24, 24, 24, 0.3);
  z-index: 898; }

#off-canvas-header {
  display: none; }

footer.page-footer {
  overflow: visible;
  background-repeat: no-repeat; }
  footer.page-footer::before, footer.page-footer::after {
    display: table;
    content: ' '; }
  footer.page-footer::after {
    clear: both; }
  @media screen and (max-width: 47.9375em) {
    footer.page-footer .footer-content .footer-list {
      border-bottom: 1px solid rgba(255, 255, 255, 0.15); }
      footer.page-footer .footer-content .footer-list .footer-mobile-accordion {
        float: none !important;
        width: auto;
        margin-left: -6px;
        margin-right: -6px;
        padding: 0;
        border-bottom: 0 none;
        border-top: 1px solid rgba(255, 255, 255, 0.15);
        margin-bottom: 0; }
        footer.page-footer .footer-content .footer-list .footer-mobile-accordion h3 {
          font-size: 20px;
          padding: 18px 18px 18px 74px;
          margin-bottom: 0;
          position: relative; }
          footer.page-footer .footer-content .footer-list .footer-mobile-accordion h3:before {
            display: block;
            content: '';
            width: 12px;
            height: 12px;
            position: absolute;
            top: 26px;
            left: 44px;
            background-image: url("../images/icon-plus--active.svg");
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: center; }
        footer.page-footer .footer-content .footer-list .footer-mobile-accordion ul.links {
          display: none;
          padding: 18px 18px 18px 74px; }
        footer.page-footer .footer-content .footer-list .footer-mobile-accordion.is-active h3 {
          background-color: rgba(255, 255, 255, 0.15); } }
  footer.page-footer .footer-content {
    transition: background-image .3s ease, background-position .3s ease;
    background: #1A2746 url("../images/bg__footer--s.png") 100% 0 no-repeat;
    background-size: auto; }
    @media screen and (min-width: 64em) {
      footer.page-footer .footer-content {
        position: relative;
        background: none;
        min-height: 270px; }
        footer.page-footer .footer-content:before {
          width: 304px;
          height: 152px;
          content: "";
          position: absolute;
          left: -152px;
          background: url("../images/footer-bubble.png") 0 0 no-repeat; } }
    footer.page-footer .footer-content .footer-block .footer.links .phone-link {
      background: url("../images/icon-phone-red.svg") 0 50% no-repeat;
      padding: 0 0 0 31px;
      background-size: 18px auto; }
    footer.page-footer .footer-content .footer-block .footer.links .email-link {
      background: url("../images/icon-email.svg") 0 50% no-repeat;
      padding: 0 0 0 31px;
      background-size: 20px auto; }
  footer.page-footer .footer-bottom {
    margin-top: 135px; }
    @media print, screen and (min-width: 48em) {
      footer.page-footer .footer-bottom .footer-list {
        display: flex; } }
    footer.page-footer .footer-bottom .footer-block.social {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 30px; }
      @media screen and (min-width: 64em) {
        footer.page-footer .footer-bottom .footer-block.social {
          flex-basis: 33%; } }
    footer.page-footer .footer-bottom .footer-social {
      text-align: center; }
    footer.page-footer .footer-bottom .footer-copyright-cookies {
      text-align: center; }
      @media print, screen and (min-width: 48em) {
        footer.page-footer .footer-bottom .footer-copyright-cookies {
          order: -1;
          padding-top: 0 !important;
          text-align: left !important; } }
      @media screen and (min-width: 64em) {
        footer.page-footer .footer-bottom .footer-copyright-cookies {
          text-align: left;
          flex-basis: 33%; } }

footer .footer__top {
  background-color: #F1EFED;
  color: #181818;
  text-align: center;
  padding: 40px 20px; }
  @media screen and (min-width: 64em) {
    footer .footer__top {
      padding: 78px 20px 71px; } }
  footer .footer__top .newsletter {
    color: inherit;
    text-align: center; }
    footer .footer__top .newsletter h3 {
      color: #1A2746;
      font-size: 32px; }
      @media screen and (min-width: 64em) {
        footer .footer__top .newsletter h3 {
          font-size: 36px; } }
    footer .footer__top .newsletter .description {
      color: #181818; }
      @media screen and (min-width: 64em) {
        footer .footer__top .newsletter .description {
          font-size: 17px; } }
    footer .footer__top .newsletter .field.newsletter {
      margin-bottom: 20px !important; }
    footer .footer__top .newsletter .field.terms input[type="checkbox"] + label,
    footer .footer__top .newsletter .field.terms input[type="checkbox"] + .label {
      text-align: left; }
    footer .footer__top .newsletter .control input {
      border-radius: 3.125rem;
      box-shadow: 2px 2px 4px rgba(24, 24, 24, 0.2); }
    footer .footer__top .newsletter div.mage-error, footer .footer__top .newsletter div.field-error {
      margin-top: 0.5rem;
      margin-bottom: 0; }
    footer .footer__top .newsletter button.action {
      outline: none;
      width: auto !important;
      margin-left: auto !important;
      margin-right: auto !important;
      height: 60px !important;
      min-width: 178px; }

.main-menu {
  margin-left: -20px;
  margin-right: 0;
  outline: none; }
  @media screen and (min-width: 64em) {
    .main-menu {
      margin-right: -20px; } }
  .main-menu__list {
    list-style: none;
    margin: 0;
    display: flex; }
  .main-menu li.level0 {
    margin-left: 5px;
    margin-right: 5px; }
    @media screen and (min-width: 64em) {
      .main-menu li.level0 {
        margin-left: 20px;
        margin-right: 20px; } }
    .main-menu li.level0.active > .level0__title:before,
    .main-menu li.level0.active > .wrapper__title:before {
      content: "";
      position: absolute;
      left: 50%;
      border-bottom-color: #F1EFED; }
  .main-menu .main-menu__link,
  .main-menu .level0__title,
  .main-menu li.level0 > .wrapper__title,
  .main-menu li.level0 > a {
    font-size: 16px;
    font-family: "silka-semibold-webfont", sans-serif;
    color: white;
    display: block;
    padding-right: 20px;
    position: relative;
    cursor: default; }
    @media screen and (min-width: 64em) {
      .main-menu .main-menu__link,
      .main-menu .level0__title,
      .main-menu li.level0 > .wrapper__title,
      .main-menu li.level0 > a {
        font-size: 20px;
        padding-right: 30px; } }
    .main-menu .main-menu__link:after,
    .main-menu .level0__title:after,
    .main-menu li.level0 > .wrapper__title:after,
    .main-menu li.level0 > a:after {
      content: "";
      position: absolute;
      width: 20px;
      height: 30px;
      right: 0;
      top: 1px;
      background-image: url("../images/icon-menu-arrow.svg");
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 10px auto; }
      @media screen and (min-width: 64em) {
        .main-menu .main-menu__link:after,
        .main-menu .level0__title:after,
        .main-menu li.level0 > .wrapper__title:after,
        .main-menu li.level0 > a:after {
          width: 30px; } }
    .main-menu .main-menu__link span,
    .main-menu .level0__title span,
    .main-menu li.level0 > .wrapper__title span,
    .main-menu li.level0 > a span {
      display: block;
      border-bottom: 1px solid #D52469;
      color: inherit;
      user-select: none;
      white-space: nowrap; }
  .main-menu ul {
    list-style: none;
    margin: 0; }
  .is-sticky .header .header-row .main-menu .submenu a {
    color: #1A2746; }
  .main-menu__submenu.level0 li {
    padding: 0;
    line-height: 1.4;
    margin-bottom: 9px; }
    .main-menu__submenu.level0 li.level2 > a {
      margin-bottom: 9px; }
  .main-menu__submenu.level0 a {
    display: block; }
  .main-menu__submenu.level0 > li.level1 {
    flex-basis: 33.33333%;
    font-size: 0.9375rem;
    margin-bottom: 30px;
    padding: 0 10px; }
    @media screen and (min-width: 64em) {
      .main-menu__submenu.level0 > li.level1 {
        flex-basis: 25%;
        margin-bottom: 50px;
        padding: 0; } }
    .main-menu__submenu.level0 > li.level1 > a {
      display: inline-block;
      font-family: "silka-semibold-webfont", sans-serif;
      font-size: 1.5rem;
      line-height: 1.2;
      margin-bottom: 1.25rem;
      border-bottom: 1px solid #D52469; }
  .main-menu__submenu.level0 {
    position: absolute;
    left: 0;
    right: 0;
    background-color: #F1EFED;
    display: none;
    margin: 20px 0 0;
    flex-wrap: wrap;
    box-shadow: 4px 4px 16px rgba(24, 24, 24, 0.2);
    padding: 20px; }
    @media screen and (min-width: 64em) {
      .main-menu__submenu.level0 {
        padding: 30px 50px; } }
  .main-menu a {
    color: #1A2746; }

.similar-products-wrapper {
  position: relative; }
  .similar-products-wrapper.active .similar-products-dropdown {
    display: block; }
  .similar-products-wrapper.active .similar-products-trigger {
    border-radius: 30px 30px 0 0;
    border-bottom: none; }
  .similar-products-wrapper .similar-products-trigger {
    border: 1px solid rgba(24, 24, 24, 0.15);
    border-radius: 30px;
    cursor: pointer;
    outline: none;
    min-height: 61px;
    padding-right: 60px; }
    .similar-products-wrapper .similar-products-trigger:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 24px;
      width: 18px;
      height: 11px;
      background: url("../images/icon-arrow-down--dark.svg") 0 0 no-repeat;
      background-size: 100%;
      margin-top: -4px; }
  .similar-products-wrapper .similar-products-dropdown {
    display: none;
    position: absolute;
    top: 100%;
    background: #f8f7f6;
    border: 1px solid rgba(24, 24, 24, 0.15);
    border-top: none;
    border-radius: 0 0 30px 30px;
    width: 100%;
    z-index: 10; }
  .similar-products-wrapper .similar-products-item {
    padding: 7px; }
    .similar-products-wrapper .similar-products-item .product__info {
      font-size: 1.0625rem;
      padding: 0 !important;
      flex-direction: row !important;
      align-items: center; }
      .similar-products-wrapper .similar-products-item .product__info .product-image-container {
        flex-grow: 0 !important;
        margin: 0 6px 0 0; }
        .similar-products-wrapper .similar-products-item .product__info .product-image-container .product-image-wrapper {
          padding-bottom: 0 !important; }
          .similar-products-wrapper .similar-products-item .product__info .product-image-container .product-image-wrapper .product-image-photo {
            width: auto;
            max-height: 46px; }

.page-title-wrapper {
  color: #1A2746; }
  .page-title-wrapper .page-title-manufacturer {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    font-family: "silka-regular-webfont", sans-serif;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 10px; }
    @media print, screen and (min-width: 40em) {
      .page-title-wrapper .page-title-manufacturer {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .page-title-wrapper .page-title-manufacturer:last-child:not(:first-child) {
      float: right; }

@media print, screen and (min-width: 40em) {
  .category-view {
    display: flex; } }

.category-view #toolbar-amount {
  display: none; }

.category-view .page-title-wrapper {
  display: flex;
  align-items: center;
  margin-left: 0; }
  .category-view .page-title-wrapper .page-title {
    margin-bottom: 0; }
    @media screen and (max-width: 47.9375em) {
      .category-view .page-title-wrapper .page-title span {
        margin-bottom: 0;
        padding-bottom: 0; }
        .category-view .page-title-wrapper .page-title span:before {
          display: none; } }
    @media screen and (max-width: 39.9375em) {
      .category-view .page-title-wrapper .page-title {
        text-align: center;
        margin-bottom: 0; }
        .category-view .page-title-wrapper .page-title span {
          padding-bottom: 1.375rem; }
          .category-view .page-title-wrapper .page-title span:before {
            display: block;
            border-bottom: 1px solid rgba(24, 24, 24, 0.15); } }

.category-view .toolbar.toolbar-products {
  display: flex;
  align-items: center;
  margin-right: 0; }

.category-view .category-header-mobile {
  margin-bottom: 1.375rem; }
  .category-view .category-header-mobile .filter-options-content ul li,
  .category-view .category-header-mobile .filter-options-content ol li {
    border-bottom: 1px solid rgba(24, 24, 24, 0.15); }
    .category-view .category-header-mobile .filter-options-content ul li:last-child,
    .category-view .category-header-mobile .filter-options-content ol li:last-child {
      border-bottom: 1px solid rgba(24, 24, 24, 0.15); }

.block.filter .action.clear,
.filter-content .action.clear,
.nav-toolbar-sorter-mobile .action.clear {
  background-color: #1A2746;
  color: #fff; }
  .block.filter .action.clear:hover, .block.filter .action.clear:focus,
  .filter-content .action.clear:hover,
  .filter-content .action.clear:focus,
  .nav-toolbar-sorter-mobile .action.clear:hover,
  .nav-toolbar-sorter-mobile .action.clear:focus {
    background-color: #1A2746;
    color: #fff; }

.block.filter dl.filter-options dt.filter-options-title .tooltip,
.filter-content dl.filter-options dt.filter-options-title .tooltip {
  display: none; }

.block.filter dl.filter-options dd.filter-options-content.has-labels ol,
.block.filter dl.filter-options dd.filter-options-content.has-labels ul,
.filter-content dl.filter-options dd.filter-options-content.has-labels ol,
.filter-content dl.filter-options dd.filter-options-content.has-labels ul {
  border-top: none; }
  .block.filter dl.filter-options dd.filter-options-content.has-labels ol .item,
  .block.filter dl.filter-options dd.filter-options-content.has-labels ul .item,
  .filter-content dl.filter-options dd.filter-options-content.has-labels ol .item,
  .filter-content dl.filter-options dd.filter-options-content.has-labels ul .item {
    border-bottom: none; }
    .block.filter dl.filter-options dd.filter-options-content.has-labels ol .item a,
    .block.filter dl.filter-options dd.filter-options-content.has-labels ul .item a,
    .filter-content dl.filter-options dd.filter-options-content.has-labels ol .item a,
    .filter-content dl.filter-options dd.filter-options-content.has-labels ul .item a {
      font-size: 0.9375rem;
      position: relative;
      padding: 0.0625rem 0 0.1875rem 1.5625rem; }
      .block.filter dl.filter-options dd.filter-options-content.has-labels ol .item a:before,
      .block.filter dl.filter-options dd.filter-options-content.has-labels ul .item a:before,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ol .item a:before,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ul .item a:before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 4px;
        box-shadow: 0 0 0 1px #979797;
        position: absolute;
        left: 1px;
        top: 50%;
        transform: translateY(-50%); }
      .block.filter dl.filter-options dd.filter-options-content.has-labels ol .item a:after,
      .block.filter dl.filter-options dd.filter-options-content.has-labels ul .item a:after,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ol .item a:after,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ul .item a:after {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        left: 1px;
        top: 50%;
        transform: translateY(-50%);
        background: url("../images/icon-valid-black.svg") no-repeat center;
        background-size: 11px auto;
        opacity: 0; }
      .block.filter dl.filter-options dd.filter-options-content.has-labels ol .item a:hover:before, .block.filter dl.filter-options dd.filter-options-content.has-labels ol .item a:active:before, .block.filter dl.filter-options dd.filter-options-content.has-labels ol .item a:focus:before,
      .block.filter dl.filter-options dd.filter-options-content.has-labels ul .item a:hover:before,
      .block.filter dl.filter-options dd.filter-options-content.has-labels ul .item a:active:before,
      .block.filter dl.filter-options dd.filter-options-content.has-labels ul .item a:focus:before,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ol .item a:hover:before,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ol .item a:active:before,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ol .item a:focus:before,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ul .item a:hover:before,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ul .item a:active:before,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ul .item a:focus:before {
        box-shadow: 0 0 0 1px #181818; }
      .block.filter dl.filter-options dd.filter-options-content.has-labels ol .item a:hover:after, .block.filter dl.filter-options dd.filter-options-content.has-labels ol .item a:active:after, .block.filter dl.filter-options dd.filter-options-content.has-labels ol .item a:focus:after,
      .block.filter dl.filter-options dd.filter-options-content.has-labels ul .item a:hover:after,
      .block.filter dl.filter-options dd.filter-options-content.has-labels ul .item a:active:after,
      .block.filter dl.filter-options dd.filter-options-content.has-labels ul .item a:focus:after,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ol .item a:hover:after,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ol .item a:active:after,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ol .item a:focus:after,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ul .item a:hover:after,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ul .item a:active:after,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ul .item a:focus:after {
        opacity: 1; }
      .block.filter dl.filter-options dd.filter-options-content.has-labels ol .item a.amshopby-link-selected:before,
      .block.filter dl.filter-options dd.filter-options-content.has-labels ul .item a.amshopby-link-selected:before,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ol .item a.amshopby-link-selected:before,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ul .item a.amshopby-link-selected:before {
        box-shadow: 0 0 0 1px #181818; }
      .block.filter dl.filter-options dd.filter-options-content.has-labels ol .item a.amshopby-link-selected:after,
      .block.filter dl.filter-options dd.filter-options-content.has-labels ul .item a.amshopby-link-selected:after,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ol .item a.amshopby-link-selected:after,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ul .item a.amshopby-link-selected:after {
        opacity: 1; }
      .block.filter dl.filter-options dd.filter-options-content.has-labels ol .item a.amshopby-link-selected .label,
      .block.filter dl.filter-options dd.filter-options-content.has-labels ul .item a.amshopby-link-selected .label,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ol .item a.amshopby-link-selected .label,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ul .item a.amshopby-link-selected .label {
        padding-left: 0;
        background: none;
        font-weight: normal; }
  .block.filter dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids,
  .block.filter dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids,
  .filter-content dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids,
  .filter-content dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids {
    border-top: 1px solid rgba(24, 24, 24, 0.15); }
    .block.filter dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids .item,
    .block.filter dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids .item,
    .filter-content dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids .item,
    .filter-content dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids .item {
      border-bottom: 1px solid rgba(24, 24, 24, 0.15); }
      .block.filter dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids .item a,
      .block.filter dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids .item a,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids .item a,
      .filter-content dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids .item a {
        font-size: 0.9375rem;
        padding: 0.5rem 1.8rem 0.5rem 0;
        background: url("../images/icon-arrow-next-gray.svg") calc(100% - 8px) 50% no-repeat;
        background-size: 7px auto; }
        @media print, screen and (min-width: 48em) {
          .block.filter dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids .item a,
          .block.filter dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids .item a,
          .filter-content dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids .item a,
          .filter-content dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids .item a {
            font-size: 1.0625rem; } }
        @media screen and (min-width: 64em) {
          .block.filter dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids .item a,
          .block.filter dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids .item a,
          .filter-content dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids .item a,
          .filter-content dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids .item a {
            font-size: 1.125rem; } }
        .block.filter dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids .item a:before, .block.filter dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids .item a:after,
        .block.filter dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids .item a:before,
        .block.filter dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids .item a:after,
        .filter-content dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids .item a:before,
        .filter-content dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids .item a:after,
        .filter-content dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids .item a:before,
        .filter-content dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids .item a:after {
          display: none; }
        .block.filter dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids .item a.amshopby-link-selected,
        .block.filter dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids .item a.amshopby-link-selected,
        .filter-content dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids .item a.amshopby-link-selected,
        .filter-content dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids .item a.amshopby-link-selected {
          background: url("../images/icon-valid-black.svg") calc(100% - 8px) 50% no-repeat;
          background-size: 15px auto; }
          .block.filter dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids .item a.amshopby-link-selected .label,
          .block.filter dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids .item a.amshopby-link-selected .label,
          .filter-content dl.filter-options dd.filter-options-content.has-labels ol.am-filter-items-category_ids .item a.amshopby-link-selected .label,
          .filter-content dl.filter-options dd.filter-options-content.has-labels ul.am-filter-items-category_ids .item a.amshopby-link-selected .label {
            padding-left: 0;
            background: none;
            font-weight: bold; }

@media print, screen and (min-width: 48em) {
  .block.filter dl.filter-options dd.filter-options-content.has-ui-slider,
  .filter-content dl.filter-options dd.filter-options-content.has-ui-slider {
    padding-bottom: 6.25rem; } }

.mobile-filter-btn {
  width: 49.5%;
  font-size: 0.9375rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: 1%;
  background-color: #1A2746;
  color: white; }
  .mobile-filter-btn:hover, .mobile-filter-btn:focus {
    background-color: #283c6b;
    color: white; }
  .mobile-filter-btn.open-filter-modal {
    width: 49.5%; }

.toolbar-sorter {
  width: auto; }
  @media print, screen and (min-width: 40em) {
    .toolbar-sorter.sorter {
      display: block !important; } }
  .toolbar-sorter .sorter-label {
    display: none; }
  .toolbar-sorter select.sorter-options,
  .toolbar-sorter .sorter-options select {
    border-radius: 3.25rem;
    padding-left: 1.3125rem;
    padding-right: 3.5rem;
    background-position: calc(100% - 28px) 50%; }

.filter-options-content.has-images-labels .am-swatch-options,
.filter-options-content.has-images-labels .swatch-attribute-options {
  border-top: none; }
  .filter-options-content.has-images-labels .am-swatch-options .item,
  .filter-options-content.has-images-labels .swatch-attribute-options .item {
    border: none; }

.filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .am-swatch-options,
.filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .swatch-attribute-options {
  display: flex;
  flex-wrap: wrap; }
  .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .am-swatch-options .item,
  .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .swatch-attribute-options .item {
    margin: 5px 8px 5px 0; }
    .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .am-swatch-options .item.checked .am_shopby_swatch_label,
    .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .am-swatch-options .item.checked .am-shopby-swatch-label,
    .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .swatch-attribute-options .item.checked .am_shopby_swatch_label,
    .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .swatch-attribute-options .item.checked .am-shopby-swatch-label {
      background-color: #1A2746;
      box-shadow: 1px 1px 2px rgba(24, 24, 24, 0.5) inset; }
      .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .am-swatch-options .item.checked .am_shopby_swatch_label .label,
      .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .am-swatch-options .item.checked .am-shopby-swatch-label .label,
      .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .swatch-attribute-options .item.checked .am_shopby_swatch_label .label,
      .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .swatch-attribute-options .item.checked .am-shopby-swatch-label .label {
        color: white; }
    .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .am-swatch-options .item .am_shopby_swatch_label,
    .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .am-swatch-options .item .am-shopby-swatch-label,
    .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .swatch-attribute-options .item .am_shopby_swatch_label,
    .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .swatch-attribute-options .item .am-shopby-swatch-label {
      padding: 0.5625rem 1.25rem 0.5625rem 0.5625rem;
      box-shadow: inset 0 0 0 1px rgba(24, 24, 24, 0.15);
      border-radius: 18px;
      line-height: 18px;
      font-size: 0;
      background: white; }
      .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .am-swatch-options .item .am_shopby_swatch_label .swatch-option.color,
      .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .am-swatch-options .item .am-shopby-swatch-label .swatch-option.color,
      .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .swatch-attribute-options .item .am_shopby_swatch_label .swatch-option.color,
      .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .swatch-attribute-options .item .am-shopby-swatch-label .swatch-option.color {
        box-shadow: 0 0 0 1px rgba(24, 24, 24, 0.15) inset; }
      .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .am-swatch-options .item .am_shopby_swatch_label .label,
      .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .am-swatch-options .item .am-shopby-swatch-label .label,
      .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .swatch-attribute-options .item .am_shopby_swatch_label .label,
      .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .swatch-attribute-options .item .am-shopby-swatch-label .label {
        font-size: 13px;
        line-height: inherit;
        margin-left: 6px; }

.filter-options-content.has-text-swatch .am_shopby_filter_items_attr_size .am-swatch-options .item.checked .swatch-option,
.filter-options-content.has-text-swatch .am_shopby_filter_items_attr_size .swatch-attribute-options .item.checked .swatch-option {
  background-color: #1A2746;
  box-shadow: 1px 1px 2px rgba(24, 24, 24, 0.5) inset;
  color: white; }

.filter-options-content.has-text-swatch .am_shopby_filter_items_attr_size .am-swatch-options .item .swatch-option:hover, .filter-options-content.has-text-swatch .am_shopby_filter_items_attr_size .am-swatch-options .item .swatch-option:active,
.filter-options-content.has-text-swatch .am_shopby_filter_items_attr_size .swatch-attribute-options .item .swatch-option:hover,
.filter-options-content.has-text-swatch .am_shopby_filter_items_attr_size .swatch-attribute-options .item .swatch-option:active {
  color: #181818;
  background-color: white;
  text-decoration: underline; }

.category-description {
  font-size: 1rem; }
  .category-description div {
    padding: 0 !important; }

.algolia-instant-selector-results .page-main .category-header .category-view {
  display: flex;
  align-items: center;
  margin-bottom: 40px; }
  .algolia-instant-selector-results .page-main .category-header .category-view .page-title-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    margin-bottom: 0; }
    .algolia-instant-selector-results .page-main .category-header .category-view .page-title-wrapper:before, .algolia-instant-selector-results .page-main .category-header .category-view .page-title-wrapper:after {
      display: none; }
  .algolia-instant-selector-results .page-main .category-header .category-view .page-title {
    width: auto;
    float: none;
    margin-bottom: 0; }
  .algolia-instant-selector-results .page-main .category-header .category-view .toolbar-amount {
    font-size: 1.0625rem;
    line-height: 20px;
    margin-bottom: 3px; }
    @media print, screen and (min-width: 40em) {
      .algolia-instant-selector-results .page-main .category-header .category-view .toolbar-amount {
        margin-bottom: 6px; } }
    @media screen and (min-width: 64em) {
      .algolia-instant-selector-results .page-main .category-header .category-view .toolbar-amount {
        margin-bottom: 14px; } }

#refine-toggle {
  margin-bottom: 20px; }

.qty-field {
  position: relative;
  margin-bottom: 1rem;
  display: inline-flex; }
  .qty-field div.mage-error {
    width: 100%;
    font-size: 14px;
    line-height: 1;
    text-align: left;
    margin-bottom: 0;
    position: absolute;
    top: 100%;
    left: 0; }
  .qty-field__wrap .label {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }
  .qty-field input[type="number"] {
    text-align: center;
    font-size: 19px;
    margin: 0;
    padding: 0;
    height: 56px;
    border-radius: 30px;
    width: 160px;
    background-color: #f8f7f6; }
    .qty-field input[type="number"].mage-error {
      background-image: none; }
    .qty-field input[type="number"]::-webkit-inner-spin-button, .qty-field input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .product-print__dropdown .qty-field input[type="number"] {
      font-size: 15px;
      height: 32px;
      width: 100px; }
    .qty-field input[type="number"] + div.mage-error {
      position: absolute;
      top: 100%;
      margin: 0;
      text-align: left;
      font-size: 12px; }
      @media screen and (max-width: 63.9375em) {
        .qty-field input[type="number"] + div.mage-error {
          top: 50%;
          left: 100%;
          width: 200px;
          margin-left: 10px;
          transform: translateY(-50%); } }
  .qty-field__control {
    width: 54px;
    height: 54px;
    padding: 0;
    border: 0 none;
    border-radius: 30px;
    overflow: hidden;
    position: absolute;
    top: 1px;
    box-shadow: 2px 2px 3px rgba(24, 24, 24, 0.15);
    outline: none; }
    .product-print__dropdown .qty-field__control {
      width: 30px;
      height: 30px; }
    .qty-field__control--decrease {
      background-color: white;
      color: #30A95E;
      left: -3px; }
    .qty-field__control--increase {
      background-color: #30A95E;
      color: white;
      right: -3px; }
    .qty-field__control span {
      display: block;
      font-size: 36px;
      line-height: 36px;
      font-family: "silka-regular-webfont", sans-serif;
      text-align: center;
      position: relative;
      top: -1px; }
      .product-print__dropdown .qty-field__control span {
        font-size: 24px;
        line-height: 24px; }

.catalog-product-view .usp-section {
  padding: 1.875rem 0; }
  .catalog-product-view .usp-section:before {
    display: none; }
  .catalog-product-view .usp-section ul {
    font-size: 15px;
    display: flex;
    justify-content: space-between; }
    .catalog-product-view .usp-section ul:before, .catalog-product-view .usp-section ul:after {
      display: none; }
    .catalog-product-view .usp-section ul li {
      width: auto;
      padding-left: 0; }
      .catalog-product-view .usp-section ul li a:before,
      .catalog-product-view .usp-section ul li span:before {
        top: 0;
        width: 22px;
        height: 18px;
        background-size: 22px auto; }

@media print, screen and (min-width: 48em) {
  .catalog-product-view .product-info-wrapper .product-info-sidebar {
    padding-left: 0;
    padding-right: 0; } }

.catalog-product-view .product-info-wrapper .product-info-sidebar .product-description-wrapper {
  font-size: 1.1875rem;
  line-height: 1.6;
  margin-bottom: 2rem; }

@media screen and (min-width: 64em) {
  .catalog-product-view .product-info-wrapper .product-info-mobile-price-social {
    display: none; } }

.catalog-product-view .product-info-wrapper .box-tocart {
  width: 100% !important;
  float: none; }
  @media screen and (min-width: 64em) {
    .catalog-product-view .product-info-wrapper .box-tocart .fieldset {
      display: flex; }
      .catalog-product-view .product-info-wrapper .box-tocart .fieldset .field.qty {
        margin-right: 0.75rem; } }
  @media screen and (min-width: 64em) {
    .catalog-product-view .product-info-wrapper .box-tocart .actions {
      flex-grow: 1;
      display: flex; } }
  .catalog-product-view .product-info-wrapper .box-tocart .actions button.action {
    font-family: "silka-semibold-webfont", sans-serif;
    margin-bottom: 1rem; }
    @media screen and (min-width: 64em) {
      .catalog-product-view .product-info-wrapper .box-tocart .actions button.action:first-child:not(:last-child) {
        margin-right: 0.75rem; } }
  .catalog-product-view .product-info-wrapper .box-tocart .product-addtoquote-button {
    line-height: 1;
    height: auto;
    border: none;
    background-color: #1A2746;
    color: white; }
    .catalog-product-view .product-info-wrapper .box-tocart .product-addtoquote-button:hover, .catalog-product-view .product-info-wrapper .box-tocart .product-addtoquote-button:focus {
      background-color: #283c6b;
      color: white; }

.catalog-product-view .product-stock__container {
  margin-bottom: 40px; }
  @media print, screen and (min-width: 48em) {
    .catalog-product-view .product-stock__container {
      display: flex; } }

.catalog-product-view .product-delivery ~ .product-info-stock-sku {
  display: none; }

@media print, screen and (min-width: 48em) {
  .catalog-product-view .product-delivery ~ .pricing__tier {
    border-left: 1px solid rgba(24, 24, 24, 0.15); } }

.pricing__tier {
  flex-grow: 1;
  padding-left: 12px; }
  .pricing__tier table {
    width: auto;
    margin-bottom: 0; }
    .pricing__tier table tbody tr {
      border: 0 none;
      border-bottom: 0 none; }
    .pricing__tier table th, .pricing__tier table td {
      font-size: 1rem;
      padding: 0.25em 1.5em;
      border: 0 none; }

.product-delivery {
  font-size: 0.9375rem;
  padding-right: 2rem; }
  .product-delivery__item {
    position: relative;
    padding-left: 20px;
    margin-bottom: 0.5em; }
    .product-delivery__item:before {
      position: absolute;
      content: "";
      background-image: url("../images/icon-valid.svg");
      background-repeat: no-repeat;
      background-size: 12px auto;
      top: 50%;
      left: 0;
      width: 12px;
      height: 10px;
      transform: translateY(-50%); }

@media print, screen and (min-width: 40em) {
  .customer-account-forgotpassword .form.password.forget,
  .customer-account-forgotpassword .form.password.reset {
    width: 100%; } }

@media screen and (min-width: 64em) {
  .customer-account-forgotpassword .form.password.forget,
  .customer-account-forgotpassword .form.password.reset {
    width: 75%; } }

@media screen and (min-width: 75em) {
  .customer-account-forgotpassword .form.password.forget,
  .customer-account-forgotpassword .form.password.reset {
    width: 60%; } }

@media screen and (min-width: 90.625em) {
  .customer-account-forgotpassword .form.password.forget,
  .customer-account-forgotpassword .form.password.reset {
    width: 50%; } }

@media print, screen and (min-width: 48em) {
  .customer-account-forgotpassword form.forget .field.captcha .control.captcha {
    display: flex;
    justify-content: space-between; }
    .customer-account-forgotpassword form.forget .field.captcha .control.captcha > .input-text {
      width: 32%; } }

@media print, screen and (min-width: 48em) {
  .customer-account-forgotpassword form.forget .field.captcha .nested .captcha-image {
    display: flex;
    gap: 1rem; } }

.product-info__accordion .accordion-item {
  padding: 0; }
  .product-info__accordion .accordion-item:first-child > :first-child {
    border-radius: 0; }
  .product-info__accordion .accordion-item .product-attr-list {
    list-style: none;
    margin-left: 0; }
  .product-info__accordion .accordion-item.is-active .accordion-title:before {
    background-image: url("../images/icon-minus--red.svg"); }

.product-info__accordion .accordion-title {
  position: relative;
  border-left: 0 none;
  border-right: 0 none;
  text-decoration: none;
  font-size: 17px;
  font-family: "silka-semibold-webfont", sans-serif;
  color: #1A2746;
  padding: 22px 20px 20px 40px; }
  .product-info__accordion .accordion-title:before {
    display: block;
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    top: 24px;
    left: 7px;
    background-image: url("../images/icon-plus--active.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center; }
  :last-child:not(.is-active) > .product-info__accordion .accordion-title {
    border-radius: 0; }

.product-info-main .similar.product-info-form-row {
  margin: 0 0 20px; }

.product-print__container {
  display: flex;
  flex-direction: column; }
  .product-print__container .fieldset .actions {
    display: flex;
    gap: 20px;
    flex-direction: column-reverse; }
    @media screen and (min-width: 75em) {
      .product-print__container .fieldset .actions {
        flex-direction: row; } }
    .product-print__container .fieldset .actions .quick-quote-form {
      width: auto; }
      .product-print__container .fieldset .actions .quick-quote-form button.action {
        width: 100%;
        padding: 1.1rem 1rem;
        background-color: #1A2746;
        color: white; }
        .product-print__container .fieldset .actions .quick-quote-form button.action:hover, .product-print__container .fieldset .actions .quick-quote-form button.action:focus {
          background-color: #283c6b;
          color: white; }
  .product-print__container .product-print__button {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    padding: 0.82em 1em 0.87em;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 50px;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 1.1875rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: #30A95E;
    color: #fff;
    width: 100%;
    margin-bottom: 0;
    position: relative;
    padding: 1.1rem 1rem;
    transition: background-color 0.3s ease-in, border-radius 0.5s ease-in; }
    [data-whatinput='mouse'] .product-print__container .product-print__button {
      outline: 0; }
    .product-print__container .product-print__button:hover, .product-print__container .product-print__button:focus {
      background-color: rgba(48, 169, 94, 0.75);
      color: #fff; }
    @media screen and (max-width: 63.9375em) {
      .product-print__container .product-print__button {
        padding-left: 1.3rem;
        padding-right: 1.3rem; } }
    @media screen and (min-width: 75em) {
      .product-print__container .product-print__button {
        width: 50%; } }
    .product-print__container .product-print__button.active {
      background-color: #1A2746 !important;
      border-radius: 9px 9px 0 0;
      z-index: 202; }
  .product-print__container .mage-dropdown-dialog {
    display: none; }
  .product-print__container .ui-dialog {
    position: relative;
    outline: none;
    z-index: 201; }
  .product-print__container .ui-dialog-titlebar {
    position: absolute;
    right: 25px;
    top: 20px; }
    @media screen and (min-width: 64em) {
      .product-print__container .ui-dialog-titlebar {
        top: 27px;
        right: 28px; } }
  .product-print__container .ui-dialog-titlebar-close {
    outline: none;
    cursor: pointer;
    font-size: 0; }
    .product-print__container .ui-dialog-titlebar-close .ui-icon-closethick {
      width: 30px;
      height: 30px;
      position: relative;
      display: block; }
      .product-print__container .ui-dialog-titlebar-close .ui-icon-closethick:before {
        position: absolute;
        content: "\00d7";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        line-height: 22px;
        font-size: 45px; }
    .product-print__container .ui-dialog-titlebar-close .ui-button-text {
      position: absolute !important;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0); }
  .product-print__container .ui-widget-overlay {
    background: rgba(24, 24, 24, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200; }

.product-info-stock-sku.mobile .stock.available {
  display: none; }

.product-add-form {
  display: none; }
  .product-add-form.saleable {
    display: block; }

.product-print__dropdown .product-add-form.saleable {
  background-color: white;
  box-shadow: 5px 5px 20px rgba(24, 24, 24, 0.2);
  padding: 20px 25px 30px; }
  @media screen and (min-width: 64em) {
    .product-print__dropdown .product-add-form.saleable {
      padding: 30px 50px 50px; } }

.product-print__dropdown .table .table-caption {
  text-align: left;
  font-size: 22px;
  padding-bottom: 0; }

.product-print__dropdown .table th,
.product-print__dropdown .table td {
  border: 0 none; }

.product-print__dropdown .table thead .item,
.product-print__dropdown .table thead .qty {
  visibility: hidden; }

.product-print__dropdown .table thead th {
  color: rgba(24, 24, 24, 0.5);
  padding-bottom: 0; }

.product-print__dropdown .table tbody td,
.product-print__dropdown .table tbody th {
  padding: 0.2rem 0.625rem 0.2rem 0; }
  .product-print__dropdown .table tbody td.col.qty,
  .product-print__dropdown .table tbody th.col.qty {
    padding-right: 20px; }

.product-print__dropdown .table th label {
  font-family: "silka-semibold-webfont", sans-serif;
  font-size: 19px;
  line-height: 1.3; }

.product-print__dropdown .table .qty-field {
  margin-bottom: 0; }

.product-print__dropdown .product-options-wrapper .fieldset {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .product-print__dropdown .product-options-wrapper .fieldset .table-wrapper {
    flex-basis: 100%; }
  .product-print__dropdown .product-options-wrapper .fieldset .field {
    flex-basis: 100%; }
    .product-print__dropdown .product-options-wrapper .fieldset .field .label {
      font-family: "silka-medium-webfont", sans-serif;
      font-size: 1.125rem;
      line-height: 1.375rem;
      margin-bottom: 1.0625rem;
      color: #181818;
      text-align: center; }
      .product-print__dropdown .product-options-wrapper .fieldset .field .label[for="select_1"] {
        font-size: 1.375rem;
        line-height: 1.6875rem;
        margin-bottom: 1.375rem; }
        .product-print__dropdown .product-options-wrapper .fieldset .field .label[for="select_1"] + .control select {
          margin-bottom: 3.5625rem; }
      .product-print__dropdown .product-options-wrapper .fieldset .field .label.type_print_variant, .product-print__dropdown .product-options-wrapper .fieldset .field .label.type_field {
        text-align: center; }
    .product-print__dropdown .product-options-wrapper .fieldset .field:first-of-type .label {
      font-size: 1.375rem;
      text-align: left; }
    .product-print__dropdown .product-options-wrapper .fieldset .field .control .question-block {
      font-size: 0.9375rem;
      text-align: center; }
      .product-print__dropdown .product-options-wrapper .fieldset .field .control .question-block .question-label {
        display: block;
        text-decoration: underline;
        cursor: pointer;
        color: #1A2746;
        margin-bottom: 0.375rem; }
        .product-print__dropdown .product-options-wrapper .fieldset .field .control .question-block .question-label:hover, .product-print__dropdown .product-options-wrapper .fieldset .field .control .question-block .question-label:focus {
          text-decoration: none; }
      .product-print__dropdown .product-options-wrapper .fieldset .field .control .question-block .ui-dialog {
        text-align: left; }
        .product-print__dropdown .product-options-wrapper .fieldset .field .control .question-block .ui-dialog .note {
          margin-bottom: 0.375rem; }
  .product-print__dropdown .product-options-wrapper .fieldset script + .field .label {
    font-size: 1.375rem;
    text-align: left; }
  .product-print__dropdown .product-options-wrapper .fieldset .field.file {
    flex-basis: 100% !important;
    margin-top: 1.875rem;
    margin-bottom: 2.625rem; }
    .product-print__dropdown .product-options-wrapper .fieldset .field.file .label {
      text-align: left; }
    .product-print__dropdown .product-options-wrapper .fieldset .field.file .note {
      margin-bottom: 0.375rem; }
    .product-print__dropdown .product-options-wrapper .fieldset .field.file .file-uploader-wait-message {
      display: inline-block;
      margin-top: 0.625rem;
      color: #D41367; }
    .product-print__dropdown .product-options-wrapper .fieldset .field.file .file-requirements-container {
      font-size: 0.9375rem;
      margin-bottom: 1rem; }
      @media screen and (min-width: 75em) {
        .product-print__dropdown .product-options-wrapper .fieldset .field.file .file-requirements-container {
          margin-top: -37px;
          text-align: right; } }
      @media screen and (min-width: 75em) {
        .product-print__dropdown .product-options-wrapper .fieldset .field.file .file-requirements-container .text-box {
          display: inline-block;
          width: 48%; } }
      .product-print__dropdown .product-options-wrapper .fieldset .field.file .file-requirements-container span {
        text-decoration: underline;
        cursor: pointer;
        color: #1A2746;
        margin-bottom: 0.375rem;
        display: block; }
        .product-print__dropdown .product-options-wrapper .fieldset .field.file .file-requirements-container span:hover, .product-print__dropdown .product-options-wrapper .fieldset .field.file .file-requirements-container span:focus {
          text-decoration: none; }
    .product-print__dropdown .product-options-wrapper .fieldset .field.file .file-uploader-summary {
      margin-top: 20px; }
      .product-print__dropdown .product-options-wrapper .fieldset .field.file .file-uploader-summary .preview-image {
        text-decoration: none; }
      .product-print__dropdown .product-options-wrapper .fieldset .field.file .file-uploader-summary .action-remove {
        display: inline-block;
        vertical-align: middle;
        width: 24px;
        height: 26px;
        opacity: .8;
        background: url("../images/icon-delete.svg") no-repeat;
        background-size: contain;
        cursor: pointer; }
        .product-print__dropdown .product-options-wrapper .fieldset .field.file .file-uploader-summary .action-remove span {
          position: absolute !important;
          width: 1px;
          height: 1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0); }
        .product-print__dropdown .product-options-wrapper .fieldset .field.file .file-uploader-summary .action-remove:hover, .product-print__dropdown .product-options-wrapper .fieldset .field.file .file-uploader-summary .action-remove:active {
          opacity: 1; }
  @media screen and (min-width: 64em) {
    .product-print__dropdown .product-options-wrapper .fieldset .field ~ .field {
      flex-basis: 47%; } }
  .product-print__dropdown .product-options-wrapper .fieldset .file-uploader-button {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    padding: 0.82em 1em 0.87em;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 50px;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 1.1875rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: #30A95E;
    color: #fff;
    font-family: "silka-semibold-webfont", sans-serif;
    letter-spacing: normal;
    font-weight: 400;
    text-transform: none;
    margin-bottom: 0;
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    background-color: #1A2746;
    color: white;
    color: #fff;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
    [data-whatinput='mouse'] .product-print__dropdown .product-options-wrapper .fieldset .file-uploader-button {
      outline: 0; }
    .product-print__dropdown .product-options-wrapper .fieldset .file-uploader-button:hover, .product-print__dropdown .product-options-wrapper .fieldset .file-uploader-button:focus {
      background-color: rgba(48, 169, 94, 0.75);
      color: #fff; }
    .product-print__dropdown .product-options-wrapper .fieldset .file-uploader-button:active {
      color: rgba(255, 255, 255, 0.8) !important; }
    .product-print__dropdown .product-options-wrapper .fieldset .file-uploader-button:hover, .product-print__dropdown .product-options-wrapper .fieldset .file-uploader-button:focus {
      background-color: #283c6b;
      color: white; }

.product-print__dropdown .price-final_price {
  justify-content: center; }

.product-info-wrapper .product-print__dropdown .price-container,
.product-info-wrapper .product-print__dropdown .special-price {
  font-size: 1.25rem; }
  @media screen and (min-width: 75em) {
    .product-info-wrapper .product-print__dropdown .price-container,
    .product-info-wrapper .product-print__dropdown .special-price {
      font-size: 1.375rem; } }

.product-print__dropdown .box-tocart {
  float: none;
  padding-left: 0;
  padding-right: 0; }
  .product-print__dropdown .box-tocart .actions {
    justify-content: center; }
    .product-print__dropdown .box-tocart .actions button.action {
      font-size: 1.0625rem; }
  @media screen and (max-width: 63.9375em) {
    .product-print__dropdown .box-tocart button.tocart {
      padding-left: 1.3rem !important;
      padding-right: 1.3rem !important; } }
  .product-print__dropdown .box-tocart .product-addtoquote-button {
    display: none; }

select {
  background-color: #fff; }

.product-items,
.ais-Hits,
.ais-InfiniteHits {
  margin: 0 !important; }
  .product-items .product-item,
  .product-items .ais-Hits-item,
  .product-items .ais-InfiniteHits-item,
  .ais-Hits .product-item,
  .ais-Hits .ais-Hits-item,
  .ais-Hits .ais-InfiniteHits-item,
  .ais-InfiniteHits .product-item,
  .ais-InfiniteHits .ais-Hits-item,
  .ais-InfiniteHits .ais-InfiniteHits-item {
    box-shadow: 6px 6px 15px transparent; }
    .no-touchevents .product-items .product-item:hover, .no-touchevents .product-items .product-item:focus, .no-touchevents
    .product-items .ais-Hits-item:hover, .no-touchevents
    .product-items .ais-Hits-item:focus, .no-touchevents
    .product-items .ais-InfiniteHits-item:hover, .no-touchevents
    .product-items .ais-InfiniteHits-item:focus, .no-touchevents
    .ais-Hits .product-item:hover, .no-touchevents
    .ais-Hits .product-item:focus, .no-touchevents
    .ais-Hits .ais-Hits-item:hover, .no-touchevents
    .ais-Hits .ais-Hits-item:focus, .no-touchevents
    .ais-Hits .ais-InfiniteHits-item:hover, .no-touchevents
    .ais-Hits .ais-InfiniteHits-item:focus, .no-touchevents
    .ais-InfiniteHits .product-item:hover, .no-touchevents
    .ais-InfiniteHits .product-item:focus, .no-touchevents
    .ais-InfiniteHits .ais-Hits-item:hover, .no-touchevents
    .ais-InfiniteHits .ais-Hits-item:focus, .no-touchevents
    .ais-InfiniteHits .ais-InfiniteHits-item:hover, .no-touchevents
    .ais-InfiniteHits .ais-InfiniteHits-item:focus {
      box-shadow: 6px 6px 15px rgba(24, 24, 24, 0.15); }
    @media screen and (max-width: 47.9375em) {
      .product-items .product-item .product-item-details .product-item-inner,
      .product-items .product-item .product-item-details .product-item-actions,
      .product-items .ais-Hits-item .product-item-details .product-item-inner,
      .product-items .ais-Hits-item .product-item-details .product-item-actions,
      .product-items .ais-InfiniteHits-item .product-item-details .product-item-inner,
      .product-items .ais-InfiniteHits-item .product-item-details .product-item-actions,
      .ais-Hits .product-item .product-item-details .product-item-inner,
      .ais-Hits .product-item .product-item-details .product-item-actions,
      .ais-Hits .ais-Hits-item .product-item-details .product-item-inner,
      .ais-Hits .ais-Hits-item .product-item-details .product-item-actions,
      .ais-Hits .ais-InfiniteHits-item .product-item-details .product-item-inner,
      .ais-Hits .ais-InfiniteHits-item .product-item-details .product-item-actions,
      .ais-InfiniteHits .product-item .product-item-details .product-item-inner,
      .ais-InfiniteHits .product-item .product-item-details .product-item-actions,
      .ais-InfiniteHits .ais-Hits-item .product-item-details .product-item-inner,
      .ais-InfiniteHits .ais-Hits-item .product-item-details .product-item-actions,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-item-details .product-item-inner,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-item-details .product-item-actions {
        position: static !important;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto; } }
    @media screen and (max-width: 39.9375em) {
      .product-items .product-item .product-item-details .product-item-inner button.action,
      .product-items .product-item .product-item-details .product-item-actions button.action,
      .product-items .ais-Hits-item .product-item-details .product-item-inner button.action,
      .product-items .ais-Hits-item .product-item-details .product-item-actions button.action,
      .product-items .ais-InfiniteHits-item .product-item-details .product-item-inner button.action,
      .product-items .ais-InfiniteHits-item .product-item-details .product-item-actions button.action,
      .ais-Hits .product-item .product-item-details .product-item-inner button.action,
      .ais-Hits .product-item .product-item-details .product-item-actions button.action,
      .ais-Hits .ais-Hits-item .product-item-details .product-item-inner button.action,
      .ais-Hits .ais-Hits-item .product-item-details .product-item-actions button.action,
      .ais-Hits .ais-InfiniteHits-item .product-item-details .product-item-inner button.action,
      .ais-Hits .ais-InfiniteHits-item .product-item-details .product-item-actions button.action,
      .ais-InfiniteHits .product-item .product-item-details .product-item-inner button.action,
      .ais-InfiniteHits .product-item .product-item-details .product-item-actions button.action,
      .ais-InfiniteHits .ais-Hits-item .product-item-details .product-item-inner button.action,
      .ais-InfiniteHits .ais-Hits-item .product-item-details .product-item-actions button.action,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-item-details .product-item-inner button.action,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-item-details .product-item-actions button.action {
        font-size: 1rem; } }
    .product-items .product-item .product-item-name,
    .product-items .ais-Hits-item .product-item-name,
    .product-items .ais-InfiniteHits-item .product-item-name,
    .ais-Hits .product-item .product-item-name,
    .ais-Hits .ais-Hits-item .product-item-name,
    .ais-Hits .ais-InfiniteHits-item .product-item-name,
    .ais-InfiniteHits .product-item .product-item-name,
    .ais-InfiniteHits .ais-Hits-item .product-item-name,
    .ais-InfiniteHits .ais-InfiniteHits-item .product-item-name {
      margin-bottom: 7px; }
      .product-items .product-item .product-item-name mark,
      .product-items .ais-Hits-item .product-item-name mark,
      .product-items .ais-InfiniteHits-item .product-item-name mark,
      .ais-Hits .product-item .product-item-name mark,
      .ais-Hits .ais-Hits-item .product-item-name mark,
      .ais-Hits .ais-InfiniteHits-item .product-item-name mark,
      .ais-InfiniteHits .product-item .product-item-name mark,
      .ais-InfiniteHits .ais-Hits-item .product-item-name mark,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-item-name mark {
        background-color: transparent;
        color: #1A2746; }
    .product-items .product-item .product-item-photo-container,
    .product-items .ais-Hits-item .product-item-photo-container,
    .product-items .ais-InfiniteHits-item .product-item-photo-container,
    .ais-Hits .product-item .product-item-photo-container,
    .ais-Hits .ais-Hits-item .product-item-photo-container,
    .ais-Hits .ais-InfiniteHits-item .product-item-photo-container,
    .ais-InfiniteHits .product-item .product-item-photo-container,
    .ais-InfiniteHits .ais-Hits-item .product-item-photo-container,
    .ais-InfiniteHits .ais-InfiniteHits-item .product-item-photo-container {
      position: relative; }
      .product-items .product-item .product-item-photo-container .similar-group,
      .product-items .ais-Hits-item .product-item-photo-container .similar-group,
      .product-items .ais-InfiniteHits-item .product-item-photo-container .similar-group,
      .ais-Hits .product-item .product-item-photo-container .similar-group,
      .ais-Hits .ais-Hits-item .product-item-photo-container .similar-group,
      .ais-Hits .ais-InfiniteHits-item .product-item-photo-container .similar-group,
      .ais-InfiniteHits .product-item .product-item-photo-container .similar-group,
      .ais-InfiniteHits .ais-Hits-item .product-item-photo-container .similar-group,
      .ais-InfiniteHits .ais-InfiniteHits-item .product-item-photo-container .similar-group {
        display: none; }
        .product-items .product-item .product-item-photo-container .similar-group .similar-group__title,
        .product-items .ais-Hits-item .product-item-photo-container .similar-group .similar-group__title,
        .product-items .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__title,
        .ais-Hits .product-item .product-item-photo-container .similar-group .similar-group__title,
        .ais-Hits .ais-Hits-item .product-item-photo-container .similar-group .similar-group__title,
        .ais-Hits .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__title,
        .ais-InfiniteHits .product-item .product-item-photo-container .similar-group .similar-group__title,
        .ais-InfiniteHits .ais-Hits-item .product-item-photo-container .similar-group .similar-group__title,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__title {
          display: none; }
        .product-items .product-item .product-item-photo-container .similar-group .similar-group__items,
        .product-items .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items,
        .product-items .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items,
        .ais-Hits .product-item .product-item-photo-container .similar-group .similar-group__items,
        .ais-Hits .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items,
        .ais-Hits .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items,
        .ais-InfiniteHits .product-item .product-item-photo-container .similar-group .similar-group__items,
        .ais-InfiniteHits .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #f8f7f6;
          font-size: 0;
          padding: 7%;
          text-align: left;
          z-index: 1; }
          .product-items .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item,
          .product-items .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items,
          .product-items .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item,
          .product-items .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items,
          .product-items .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item,
          .product-items .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items,
          .ais-Hits .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item,
          .ais-Hits .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items,
          .ais-Hits .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item,
          .ais-Hits .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items,
          .ais-Hits .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item,
          .ais-Hits .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items,
          .ais-InfiniteHits .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item,
          .ais-InfiniteHits .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items,
          .ais-InfiniteHits .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item,
          .ais-InfiniteHits .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items,
          .ais-InfiniteHits .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item,
          .ais-InfiniteHits .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items {
            display: inline-block;
            width: 33.33333%;
            height: 33.33333%;
            text-align: center;
            vertical-align: top;
            padding: 3%;
            position: relative;
            font-size: 1.75rem;
            color: #4c4b4a; }
            .product-items .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .product-image-container,
            .product-items .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .product-image-container,
            .product-items .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .product-image-container,
            .product-items .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .product-image-container,
            .product-items .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .product-image-container,
            .product-items .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .product-image-container,
            .ais-Hits .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .product-image-container,
            .ais-Hits .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .product-image-container,
            .ais-Hits .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .product-image-container,
            .ais-Hits .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .product-image-container,
            .ais-Hits .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .product-image-container,
            .ais-Hits .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .product-image-container,
            .ais-InfiniteHits .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .product-image-container,
            .ais-InfiniteHits .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .product-image-container,
            .ais-InfiniteHits .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .product-image-container,
            .ais-InfiniteHits .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .product-image-container,
            .ais-InfiniteHits .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .product-image-container,
            .ais-InfiniteHits .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .product-image-container {
              top: 50%;
              transform: translateY(-50%); }
            .product-items .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .similar-group__inside-box,
            .product-items .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .similar-group__inside-box,
            .product-items .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .similar-group__inside-box,
            .product-items .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .similar-group__inside-box,
            .product-items .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .similar-group__inside-box,
            .product-items .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .similar-group__inside-box,
            .ais-Hits .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .similar-group__inside-box,
            .ais-Hits .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .similar-group__inside-box,
            .ais-Hits .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .similar-group__inside-box,
            .ais-Hits .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .similar-group__inside-box,
            .ais-Hits .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .similar-group__inside-box,
            .ais-Hits .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .similar-group__inside-box,
            .ais-InfiniteHits .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .similar-group__inside-box,
            .ais-InfiniteHits .product-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .similar-group__inside-box,
            .ais-InfiniteHits .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .similar-group__inside-box,
            .ais-InfiniteHits .ais-Hits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .similar-group__inside-box,
            .ais-InfiniteHits .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__item .similar-group__inside-box,
            .ais-InfiniteHits .ais-InfiniteHits-item .product-item-photo-container .similar-group .similar-group__items .similar-group__more-items .similar-group__inside-box {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%); }
        .product-items .product-item .product-item-photo-container .similar-group .product-image-container,
        .product-items .ais-Hits-item .product-item-photo-container .similar-group .product-image-container,
        .product-items .ais-InfiniteHits-item .product-item-photo-container .similar-group .product-image-container,
        .ais-Hits .product-item .product-item-photo-container .similar-group .product-image-container,
        .ais-Hits .ais-Hits-item .product-item-photo-container .similar-group .product-image-container,
        .ais-Hits .ais-InfiniteHits-item .product-item-photo-container .similar-group .product-image-container,
        .ais-InfiniteHits .product-item .product-item-photo-container .similar-group .product-image-container,
        .ais-InfiniteHits .ais-Hits-item .product-item-photo-container .similar-group .product-image-container,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-item-photo-container .similar-group .product-image-container {
          display: inline-block;
          padding: 0;
          height: auto; }
          .product-items .product-item .product-item-photo-container .similar-group .product-image-container .product-image-wrapper,
          .product-items .ais-Hits-item .product-item-photo-container .similar-group .product-image-container .product-image-wrapper,
          .product-items .ais-InfiniteHits-item .product-item-photo-container .similar-group .product-image-container .product-image-wrapper,
          .ais-Hits .product-item .product-item-photo-container .similar-group .product-image-container .product-image-wrapper,
          .ais-Hits .ais-Hits-item .product-item-photo-container .similar-group .product-image-container .product-image-wrapper,
          .ais-Hits .ais-InfiniteHits-item .product-item-photo-container .similar-group .product-image-container .product-image-wrapper,
          .ais-InfiniteHits .product-item .product-item-photo-container .similar-group .product-image-container .product-image-wrapper,
          .ais-InfiniteHits .ais-Hits-item .product-item-photo-container .similar-group .product-image-container .product-image-wrapper,
          .ais-InfiniteHits .ais-InfiniteHits-item .product-item-photo-container .similar-group .product-image-container .product-image-wrapper {
            position: relative !important; }
    .product-items .product-item:hover .product-item-photo-container .similar-group, .product-items .product-item:focus .product-item-photo-container .similar-group,
    .product-items .ais-Hits-item:hover .product-item-photo-container .similar-group,
    .product-items .ais-Hits-item:focus .product-item-photo-container .similar-group,
    .product-items .ais-InfiniteHits-item:hover .product-item-photo-container .similar-group,
    .product-items .ais-InfiniteHits-item:focus .product-item-photo-container .similar-group,
    .ais-Hits .product-item:hover .product-item-photo-container .similar-group,
    .ais-Hits .product-item:focus .product-item-photo-container .similar-group,
    .ais-Hits .ais-Hits-item:hover .product-item-photo-container .similar-group,
    .ais-Hits .ais-Hits-item:focus .product-item-photo-container .similar-group,
    .ais-Hits .ais-InfiniteHits-item:hover .product-item-photo-container .similar-group,
    .ais-Hits .ais-InfiniteHits-item:focus .product-item-photo-container .similar-group,
    .ais-InfiniteHits .product-item:hover .product-item-photo-container .similar-group,
    .ais-InfiniteHits .product-item:focus .product-item-photo-container .similar-group,
    .ais-InfiniteHits .ais-Hits-item:hover .product-item-photo-container .similar-group,
    .ais-InfiniteHits .ais-Hits-item:focus .product-item-photo-container .similar-group,
    .ais-InfiniteHits .ais-InfiniteHits-item:hover .product-item-photo-container .similar-group,
    .ais-InfiniteHits .ais-InfiniteHits-item:focus .product-item-photo-container .similar-group {
      display: block; }
    .product-items .product-item .action.tocart,
    .product-items .ais-Hits-item .action.tocart,
    .product-items .ais-InfiniteHits-item .action.tocart,
    .ais-Hits .product-item .action.tocart,
    .ais-Hits .ais-Hits-item .action.tocart,
    .ais-Hits .ais-InfiniteHits-item .action.tocart,
    .ais-InfiniteHits .product-item .action.tocart,
    .ais-InfiniteHits .ais-Hits-item .action.tocart,
    .ais-InfiniteHits .ais-InfiniteHits-item .action.tocart {
      display: none; }
      .product-items .product-item .action.tocart.toquote,
      .product-items .ais-Hits-item .action.tocart.toquote,
      .product-items .ais-InfiniteHits-item .action.tocart.toquote,
      .ais-Hits .product-item .action.tocart.toquote,
      .ais-Hits .ais-Hits-item .action.tocart.toquote,
      .ais-Hits .ais-InfiniteHits-item .action.tocart.toquote,
      .ais-InfiniteHits .product-item .action.tocart.toquote,
      .ais-InfiniteHits .ais-Hits-item .action.tocart.toquote,
      .ais-InfiniteHits .ais-InfiniteHits-item .action.tocart.toquote {
        display: block; }
        .product-items .product-item .action.tocart.toquote:hover, .product-items .product-item .action.tocart.toquote:focus,
        .product-items .ais-Hits-item .action.tocart.toquote:hover,
        .product-items .ais-Hits-item .action.tocart.toquote:focus,
        .product-items .ais-InfiniteHits-item .action.tocart.toquote:hover,
        .product-items .ais-InfiniteHits-item .action.tocart.toquote:focus,
        .ais-Hits .product-item .action.tocart.toquote:hover,
        .ais-Hits .product-item .action.tocart.toquote:focus,
        .ais-Hits .ais-Hits-item .action.tocart.toquote:hover,
        .ais-Hits .ais-Hits-item .action.tocart.toquote:focus,
        .ais-Hits .ais-InfiniteHits-item .action.tocart.toquote:hover,
        .ais-Hits .ais-InfiniteHits-item .action.tocart.toquote:focus,
        .ais-InfiniteHits .product-item .action.tocart.toquote:hover,
        .ais-InfiniteHits .product-item .action.tocart.toquote:focus,
        .ais-InfiniteHits .ais-Hits-item .action.tocart.toquote:hover,
        .ais-InfiniteHits .ais-Hits-item .action.tocart.toquote:focus,
        .ais-InfiniteHits .ais-InfiniteHits-item .action.tocart.toquote:hover,
        .ais-InfiniteHits .ais-InfiniteHits-item .action.tocart.toquote:focus {
          border: 1px solid transparent; }
    .no-touchevents .product-items .product-item:hover .price-box + .stock.unavailable, .no-touchevents .product-items .product-item:focus .price-box + .stock.unavailable, .no-touchevents
    .product-items .ais-Hits-item:hover .price-box + .stock.unavailable, .no-touchevents
    .product-items .ais-Hits-item:focus .price-box + .stock.unavailable, .no-touchevents
    .product-items .ais-InfiniteHits-item:hover .price-box + .stock.unavailable, .no-touchevents
    .product-items .ais-InfiniteHits-item:focus .price-box + .stock.unavailable, .no-touchevents
    .ais-Hits .product-item:hover .price-box + .stock.unavailable, .no-touchevents
    .ais-Hits .product-item:focus .price-box + .stock.unavailable, .no-touchevents
    .ais-Hits .ais-Hits-item:hover .price-box + .stock.unavailable, .no-touchevents
    .ais-Hits .ais-Hits-item:focus .price-box + .stock.unavailable, .no-touchevents
    .ais-Hits .ais-InfiniteHits-item:hover .price-box + .stock.unavailable, .no-touchevents
    .ais-Hits .ais-InfiniteHits-item:focus .price-box + .stock.unavailable, .no-touchevents
    .ais-InfiniteHits .product-item:hover .price-box + .stock.unavailable, .no-touchevents
    .ais-InfiniteHits .product-item:focus .price-box + .stock.unavailable, .no-touchevents
    .ais-InfiniteHits .ais-Hits-item:hover .price-box + .stock.unavailable, .no-touchevents
    .ais-InfiniteHits .ais-Hits-item:focus .price-box + .stock.unavailable, .no-touchevents
    .ais-InfiniteHits .ais-InfiniteHits-item:hover .price-box + .stock.unavailable, .no-touchevents
    .ais-InfiniteHits .ais-InfiniteHits-item:focus .price-box + .stock.unavailable {
      z-index: 2; }
    .product-items .product-item .product-price-stock .price-box,
    .product-items .ais-Hits-item .product-price-stock .price-box,
    .product-items .ais-InfiniteHits-item .product-price-stock .price-box,
    .ais-Hits .product-item .product-price-stock .price-box,
    .ais-Hits .ais-Hits-item .product-price-stock .price-box,
    .ais-Hits .ais-InfiniteHits-item .product-price-stock .price-box,
    .ais-InfiniteHits .product-item .product-price-stock .price-box,
    .ais-InfiniteHits .ais-Hits-item .product-price-stock .price-box,
    .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .price-box {
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-size: 14px;
      color: #1A2746; }
      @media screen and (min-width: 64em) {
        .product-items .product-item .product-price-stock .price-box,
        .product-items .ais-Hits-item .product-price-stock .price-box,
        .product-items .ais-InfiniteHits-item .product-price-stock .price-box,
        .ais-Hits .product-item .product-price-stock .price-box,
        .ais-Hits .ais-Hits-item .product-price-stock .price-box,
        .ais-Hits .ais-InfiniteHits-item .product-price-stock .price-box,
        .ais-InfiniteHits .product-item .product-price-stock .price-box,
        .ais-InfiniteHits .ais-Hits-item .product-price-stock .price-box,
        .ais-InfiniteHits .ais-InfiniteHits-item .product-price-stock .price-box {
          font-size: 15px; } }

.product-items .product-item .product-image-container:before,
.product-items .ais-Hits-item .product-image-container:before,
.product-items .ais-InfiniteHits-item .product-image-container:before,
.ais-Hits .product-item .product-image-container:before,
.ais-Hits .ais-Hits-item .product-image-container:before,
.ais-Hits .ais-InfiniteHits-item .product-image-container:before,
.ais-InfiniteHits .product-item .product-image-container:before,
.ais-InfiniteHits .ais-Hits-item .product-image-container:before,
.ais-InfiniteHits .ais-InfiniteHits-item .product-image-container:before, table.cart .product-image-container:before, .add-to-cart-popup .add-popup-product-image:before {
  mix-blend-mode: multiply; }

.fotorama__wrap--slide .fotorama__stage__frame {
  background-color: rgba(70, 55, 23, 0.04); }
  .fotorama__wrap--slide .fotorama__stage__frame .fotorama__img {
    mix-blend-mode: multiply; }

.category-header {
  margin-bottom: 1rem; }
  @media screen and (min-width: 64em) {
    .category-header {
      margin-bottom: 2rem; } }

.algolia-instant-results-wrapper {
  clear: both; }

.checkout-index-index .legal-info {
  font-weight: bold; }

.customer-address-index .actions-toolbar {
  margin-bottom: 20px; }

.checkout-cart-index table.cart,
.quotation-quote-index table.cart,
.dibs_easy_checkout-index-index table.cart {
  background: #f8f7f6; }
  .checkout-cart-index table.cart tbody tr.item-info:not(:first-child),
  .quotation-quote-index table.cart tbody tr.item-info:not(:first-child),
  .dibs_easy_checkout-index-index table.cart tbody tr.item-info:not(:first-child) {
    border-top: 16px solid white; }
  .checkout-cart-index table.cart th, .checkout-cart-index table.cart td,
  .quotation-quote-index table.cart th,
  .quotation-quote-index table.cart td,
  .dibs_easy_checkout-index-index table.cart th,
  .dibs_easy_checkout-index-index table.cart td {
    font-size: 0.9375rem;
    padding: 5px 10px; }
    @media screen and (min-width: 75em) {
      .checkout-cart-index table.cart th, .checkout-cart-index table.cart td,
      .quotation-quote-index table.cart th,
      .quotation-quote-index table.cart td,
      .dibs_easy_checkout-index-index table.cart th,
      .dibs_easy_checkout-index-index table.cart td {
        padding: 5px 0.8125rem; } }
    .checkout-cart-index table.cart th:first-child, .checkout-cart-index table.cart td:first-child,
    .quotation-quote-index table.cart th:first-child,
    .quotation-quote-index table.cart td:first-child,
    .dibs_easy_checkout-index-index table.cart th:first-child,
    .dibs_easy_checkout-index-index table.cart td:first-child {
      width: 130px;
      vertical-align: top; }
      @media screen and (max-width: 74.9375em) {
        .checkout-cart-index table.cart th:first-child, .checkout-cart-index table.cart td:first-child,
        .quotation-quote-index table.cart th:first-child,
        .quotation-quote-index table.cart td:first-child,
        .dibs_easy_checkout-index-index table.cart th:first-child,
        .dibs_easy_checkout-index-index table.cart td:first-child {
          width: 110px;
          padding-left: 15px; } }
  @media screen and (max-width: 74.9375em) {
    .checkout-cart-index table.cart tr,
    .quotation-quote-index table.cart tr,
    .dibs_easy_checkout-index-index table.cart tr {
      display: block;
      position: relative; }
      .checkout-cart-index table.cart tr::before, .checkout-cart-index table.cart tr::after,
      .quotation-quote-index table.cart tr::before,
      .quotation-quote-index table.cart tr::after,
      .dibs_easy_checkout-index-index table.cart tr::before,
      .dibs_easy_checkout-index-index table.cart tr::after {
        display: table;
        content: ' '; }
      .checkout-cart-index table.cart tr::after,
      .quotation-quote-index table.cart tr::after,
      .dibs_easy_checkout-index-index table.cart tr::after {
        clear: both; } }
  .checkout-cart-index table.cart tr.item-info,
  .quotation-quote-index table.cart tr.item-info,
  .dibs_easy_checkout-index-index table.cart tr.item-info {
    position: relative; }
    @media screen and (max-width: 74.9375em) {
      .checkout-cart-index table.cart tr.item-info,
      .quotation-quote-index table.cart tr.item-info,
      .dibs_easy_checkout-index-index table.cart tr.item-info {
        padding-top: 15px;
        padding-bottom: 15px; } }
    .checkout-cart-index table.cart tr.item-info th, .checkout-cart-index table.cart tr.item-info td,
    .quotation-quote-index table.cart tr.item-info th,
    .quotation-quote-index table.cart tr.item-info td,
    .dibs_easy_checkout-index-index table.cart tr.item-info th,
    .dibs_easy_checkout-index-index table.cart tr.item-info td {
      padding-top: 35px;
      padding-bottom: 30px; }
      @media screen and (max-width: 74.9375em) {
        .checkout-cart-index table.cart tr.item-info th, .checkout-cart-index table.cart tr.item-info td,
        .quotation-quote-index table.cart tr.item-info th,
        .quotation-quote-index table.cart tr.item-info td,
        .dibs_easy_checkout-index-index table.cart tr.item-info th,
        .dibs_easy_checkout-index-index table.cart tr.item-info td {
          padding-top: 0;
          padding-bottom: 0;
          vertical-align: top;
          display: block;
          float: left; } }
      .checkout-cart-index table.cart tr.item-info th:first-child, .checkout-cart-index table.cart tr.item-info td:first-child,
      .quotation-quote-index table.cart tr.item-info th:first-child,
      .quotation-quote-index table.cart tr.item-info td:first-child,
      .dibs_easy_checkout-index-index table.cart tr.item-info th:first-child,
      .dibs_easy_checkout-index-index table.cart tr.item-info td:first-child {
        width: 130px;
        padding-left: 2.5rem;
        vertical-align: top; }
        @media screen and (max-width: 74.9375em) {
          .checkout-cart-index table.cart tr.item-info th:first-child, .checkout-cart-index table.cart tr.item-info td:first-child,
          .quotation-quote-index table.cart tr.item-info th:first-child,
          .quotation-quote-index table.cart tr.item-info td:first-child,
          .dibs_easy_checkout-index-index table.cart tr.item-info th:first-child,
          .dibs_easy_checkout-index-index table.cart tr.item-info td:first-child {
            width: 110px;
            padding-left: 15px; } }
    .checkout-cart-index table.cart tr.item-info .col.price, .checkout-cart-index table.cart tr.item-info .col.subtotal,
    .quotation-quote-index table.cart tr.item-info .col.price,
    .quotation-quote-index table.cart tr.item-info .col.subtotal,
    .dibs_easy_checkout-index-index table.cart tr.item-info .col.price,
    .dibs_easy_checkout-index-index table.cart tr.item-info .col.subtotal {
      text-align: right; }
  .checkout-cart-index table.cart tr.print-price-method-label td,
  .quotation-quote-index table.cart tr.print-price-method-label td,
  .dibs_easy_checkout-index-index table.cart tr.print-price-method-label td {
    font-family: "silka-medium-webfont", sans-serif;
    font-size: 18px;
    line-height: 1.2;
    padding-top: 11px; }
  @media screen and (max-width: 74.9375em) {
    .checkout-cart-index table.cart tr.print-price-calculations,
    .quotation-quote-index table.cart tr.print-price-calculations,
    .dibs_easy_checkout-index-index table.cart tr.print-price-calculations {
      display: flex;
      align-items: flex-end; } }
  .checkout-cart-index table.cart tr.print-price-calculations .print-code-flex,
  .quotation-quote-index table.cart tr.print-price-calculations .print-code-flex,
  .dibs_easy_checkout-index-index table.cart tr.print-price-calculations .print-code-flex {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 74.9375em) {
      .checkout-cart-index table.cart tr.print-price-calculations .print-code-flex,
      .quotation-quote-index table.cart tr.print-price-calculations .print-code-flex,
      .dibs_easy_checkout-index-index table.cart tr.print-price-calculations .print-code-flex {
        flex-direction: column;
        align-items: flex-start; } }
    .checkout-cart-index table.cart tr.print-price-calculations .print-code-flex .print-code-name,
    .quotation-quote-index table.cart tr.print-price-calculations .print-code-flex .print-code-name,
    .dibs_easy_checkout-index-index table.cart tr.print-price-calculations .print-code-flex .print-code-name {
      margin-right: 20px; }
      @media screen and (max-width: 74.9375em) {
        .checkout-cart-index table.cart tr.print-price-calculations .print-code-flex .print-code-name,
        .quotation-quote-index table.cart tr.print-price-calculations .print-code-flex .print-code-name,
        .dibs_easy_checkout-index-index table.cart tr.print-price-calculations .print-code-flex .print-code-name {
          width: 0;
          white-space: nowrap;
          margin-bottom: 5px;
          margin-right: 0; } }
  .checkout-cart-index table.cart tr.print-price-calculations .print-price,
  .quotation-quote-index table.cart tr.print-price-calculations .print-price,
  .dibs_easy_checkout-index-index table.cart tr.print-price-calculations .print-price {
    text-align: right; }
    @media screen and (max-width: 74.9375em) {
      .checkout-cart-index table.cart tr.print-price-calculations .print-price.subtotal,
      .quotation-quote-index table.cart tr.print-price-calculations .print-price.subtotal,
      .dibs_easy_checkout-index-index table.cart tr.print-price-calculations .print-price.subtotal {
        flex: 1;
        padding-right: 30px; } }
    @media screen and (min-width: 75em) {
      .checkout-cart-index table.cart tr.print-price-calculations .print-price.subtotal,
      .quotation-quote-index table.cart tr.print-price-calculations .print-price.subtotal,
      .dibs_easy_checkout-index-index table.cart tr.print-price-calculations .print-price.subtotal {
        padding-right: 0.8125rem; } }
  .checkout-cart-index table.cart tr.print-price-options .item-options,
  .quotation-quote-index table.cart tr.print-price-options .item-options,
  .dibs_easy_checkout-index-index table.cart tr.print-price-options .item-options {
    margin: 0 0 10px; }
    .checkout-cart-index table.cart tr.print-price-options .item-options .item-option:not(:last-child),
    .quotation-quote-index table.cart tr.print-price-options .item-options .item-option:not(:last-child),
    .dibs_easy_checkout-index-index table.cart tr.print-price-options .item-options .item-option:not(:last-child) {
      margin: 0 0 10px; }
    .checkout-cart-index table.cart tr.print-price-options .item-options dt,
    .checkout-cart-index table.cart tr.print-price-options .item-options dd,
    .checkout-cart-index table.cart tr.print-price-options .item-options .item-label,
    .checkout-cart-index table.cart tr.print-price-options .item-options .item-name,
    .quotation-quote-index table.cart tr.print-price-options .item-options dt,
    .quotation-quote-index table.cart tr.print-price-options .item-options dd,
    .quotation-quote-index table.cart tr.print-price-options .item-options .item-label,
    .quotation-quote-index table.cart tr.print-price-options .item-options .item-name,
    .dibs_easy_checkout-index-index table.cart tr.print-price-options .item-options dt,
    .dibs_easy_checkout-index-index table.cart tr.print-price-options .item-options dd,
    .dibs_easy_checkout-index-index table.cart tr.print-price-options .item-options .item-label,
    .dibs_easy_checkout-index-index table.cart tr.print-price-options .item-options .item-name {
      line-height: 1.3;
      padding-top: 6px;
      padding-bottom: 6px; }
    .checkout-cart-index table.cart tr.print-price-options .item-options .item-name a,
    .quotation-quote-index table.cart tr.print-price-options .item-options .item-name a,
    .dibs_easy_checkout-index-index table.cart tr.print-price-options .item-options .item-name a {
      margin-right: 5px; }
    .checkout-cart-index table.cart tr.print-price-options .item-options .item-size,
    .quotation-quote-index table.cart tr.print-price-options .item-options .item-size,
    .dibs_easy_checkout-index-index table.cart tr.print-price-options .item-options .item-size {
      display: inline-block; }
    .checkout-cart-index table.cart tr.print-price-options .item-options .item-print-image,
    .quotation-quote-index table.cart tr.print-price-options .item-options .item-print-image,
    .dibs_easy_checkout-index-index table.cart tr.print-price-options .item-options .item-print-image {
      display: inline-block;
      margin-top: 11px; }
      @media screen and (max-width: 74.9375em) {
        .checkout-cart-index table.cart tr.print-price-options .item-options .item-print-image,
        .quotation-quote-index table.cart tr.print-price-options .item-options .item-print-image,
        .dibs_easy_checkout-index-index table.cart tr.print-price-options .item-options .item-print-image {
          position: absolute;
          left: 22px;
          top: -37px; } }
      .checkout-cart-index table.cart tr.print-price-options .item-options .item-print-image img,
      .quotation-quote-index table.cart tr.print-price-options .item-options .item-print-image img,
      .dibs_easy_checkout-index-index table.cart tr.print-price-options .item-options .item-print-image img {
        max-height: 80px; }
        @media screen and (max-width: 74.9375em) {
          .checkout-cart-index table.cart tr.print-price-options .item-options .item-print-image img,
          .quotation-quote-index table.cart tr.print-price-options .item-options .item-print-image img,
          .dibs_easy_checkout-index-index table.cart tr.print-price-options .item-options .item-print-image img {
            max-width: 70px;
            max-height: none; } }
  .checkout-cart-index table.cart tr.print-price-total,
  .quotation-quote-index table.cart tr.print-price-total,
  .dibs_easy_checkout-index-index table.cart tr.print-price-total {
    display: flex;
    border: none;
    padding: 0;
    margin: 0; }
    @media screen and (min-width: 75em) {
      .checkout-cart-index table.cart tr.print-price-total,
      .quotation-quote-index table.cart tr.print-price-total,
      .dibs_easy_checkout-index-index table.cart tr.print-price-total {
        display: table-row; } }
    .checkout-cart-index table.cart tr.print-price-total td,
    .quotation-quote-index table.cart tr.print-price-total td,
    .dibs_easy_checkout-index-index table.cart tr.print-price-total td {
      padding-top: 38px;
      padding-bottom: 52px; }
      @media screen and (max-width: 74.9375em) {
        .checkout-cart-index table.cart tr.print-price-total td,
        .quotation-quote-index table.cart tr.print-price-total td,
        .dibs_easy_checkout-index-index table.cart tr.print-price-total td {
          padding-top: 20px;
          padding-bottom: 30px; } }
    .checkout-cart-index table.cart tr.print-price-total td.total-cell,
    .quotation-quote-index table.cart tr.print-price-total td.total-cell,
    .dibs_easy_checkout-index-index table.cart tr.print-price-total td.total-cell {
      font-family: "silka-medium-webfont", sans-serif;
      font-size: 1.125rem;
      position: relative; }
      @media screen and (max-width: 74.9375em) {
        .checkout-cart-index table.cart tr.print-price-total td.total-cell,
        .quotation-quote-index table.cart tr.print-price-total td.total-cell,
        .dibs_easy_checkout-index-index table.cart tr.print-price-total td.total-cell {
          width: calc(100% - 110px);
          padding-right: 30px; } }
      @media screen and (min-width: 75em) {
        .checkout-cart-index table.cart tr.print-price-total td.total-cell,
        .quotation-quote-index table.cart tr.print-price-total td.total-cell,
        .dibs_easy_checkout-index-index table.cart tr.print-price-total td.total-cell {
          padding-right: 0.8125rem; } }
      .checkout-cart-index table.cart tr.print-price-total td.total-cell:before,
      .quotation-quote-index table.cart tr.print-price-total td.total-cell:before,
      .dibs_easy_checkout-index-index table.cart tr.print-price-total td.total-cell:before {
        content: '';
        display: block;
        position: absolute;
        top: 18px;
        left: 0.8125rem;
        right: 0.8125rem;
        border-top: 1px solid #d3d2d1; }
        @media screen and (max-width: 74.9375em) {
          .checkout-cart-index table.cart tr.print-price-total td.total-cell:before,
          .quotation-quote-index table.cart tr.print-price-total td.total-cell:before,
          .dibs_easy_checkout-index-index table.cart tr.print-price-total td.total-cell:before {
            top: 8px;
            left: 10px;
            right: 30px; } }
      .checkout-cart-index table.cart tr.print-price-total td.total-cell .amount,
      .quotation-quote-index table.cart tr.print-price-total td.total-cell .amount,
      .dibs_easy_checkout-index-index table.cart tr.print-price-total td.total-cell .amount {
        float: right; }
  .checkout-cart-index table.cart .product-item-name,
  .quotation-quote-index table.cart .product-item-name,
  .dibs_easy_checkout-index-index table.cart .product-item-name {
    line-height: 1.2;
    margin: 4px 0 12px; }
    .checkout-cart-index table.cart .product-item-name a,
    .quotation-quote-index table.cart .product-item-name a,
    .dibs_easy_checkout-index-index table.cart .product-item-name a {
      color: #1A2746; }
      .checkout-cart-index table.cart .product-item-name a:hover,
      .quotation-quote-index table.cart .product-item-name a:hover,
      .dibs_easy_checkout-index-index table.cart .product-item-name a:hover {
        color: #1A2746; }
  .checkout-cart-index table.cart .product-item-sku,
  .checkout-cart-index table.cart .product-item-supplier,
  .quotation-quote-index table.cart .product-item-sku,
  .quotation-quote-index table.cart .product-item-supplier,
  .dibs_easy_checkout-index-index table.cart .product-item-sku,
  .dibs_easy_checkout-index-index table.cart .product-item-supplier {
    color: rgba(24, 24, 24, 0.7); }
  .checkout-cart-index table.cart .cart-item-stock-status,
  .quotation-quote-index table.cart .cart-item-stock-status,
  .dibs_easy_checkout-index-index table.cart .cart-item-stock-status {
    display: none; }
  .checkout-cart-index table.cart .field.qty,
  .quotation-quote-index table.cart .field.qty,
  .dibs_easy_checkout-index-index table.cart .field.qty {
    display: flex;
    align-items: center;
    gap: 10px; }
    .checkout-cart-index table.cart .field.qty .input-text,
    .quotation-quote-index table.cart .field.qty .input-text,
    .dibs_easy_checkout-index-index table.cart .field.qty .input-text {
      border: none;
      border-radius: 0;
      height: 30px;
      font-size: 0.9375rem;
      text-align: left;
      background-color: #f8f7f6; }
      .checkout-cart-index table.cart .field.qty .input-text:focus, .checkout-cart-index table.cart .field.qty .input-text[readonly],
      .quotation-quote-index table.cart .field.qty .input-text:focus,
      .quotation-quote-index table.cart .field.qty .input-text[readonly],
      .dibs_easy_checkout-index-index table.cart .field.qty .input-text:focus,
      .dibs_easy_checkout-index-index table.cart .field.qty .input-text[readonly] {
        background-color: #f8f7f6; }
      @media screen and (min-width: 75em) {
        .checkout-cart-index table.cart .field.qty .input-text,
        .quotation-quote-index table.cart .field.qty .input-text,
        .dibs_easy_checkout-index-index table.cart .field.qty .input-text {
          height: 50px;
          text-align: right; } }
    .checkout-cart-index table.cart .field.qty:after,
    .quotation-quote-index table.cart .field.qty:after,
    .dibs_easy_checkout-index-index table.cart .field.qty:after {
      font-size: 1.375rem;
      padding-left: 5px;
      color: #181818;
      line-height: 30px; }
      @media screen and (min-width: 64em) {
        .checkout-cart-index table.cart .field.qty:after,
        .quotation-quote-index table.cart .field.qty:after,
        .dibs_easy_checkout-index-index table.cart .field.qty:after {
          padding-left: 5px; } }
    .checkout-cart-index table.cart .field.qty .control label,
    .quotation-quote-index table.cart .field.qty .control label,
    .dibs_easy_checkout-index-index table.cart .field.qty .control label {
      line-height: 30px; }
  .checkout-cart-index table.cart .print-qty,
  .quotation-quote-index table.cart .print-qty,
  .dibs_easy_checkout-index-index table.cart .print-qty {
    display: flex;
    align-items: center;
    text-align: right; }
    .checkout-cart-index table.cart .print-qty:after,
    .quotation-quote-index table.cart .print-qty:after,
    .dibs_easy_checkout-index-index table.cart .print-qty:after {
      content: '×';
      display: inline-block;
      font-size: 1.375rem;
      line-height: 20px;
      padding-left: 13px;
      padding-right: 1px;
      vertical-align: bottom; }
  .checkout-cart-index table.cart .tier-qty .field-tooltip,
  .quotation-quote-index table.cart .tier-qty .field-tooltip,
  .dibs_easy_checkout-index-index table.cart .tier-qty .field-tooltip {
    display: none; }
  .checkout-cart-index table.cart .print-price.subtotal,
  .quotation-quote-index table.cart .print-price.subtotal,
  .dibs_easy_checkout-index-index table.cart .print-price.subtotal {
    font-family: "silka-semibold-webfont", sans-serif;
    text-align: right; }
  @media screen and (max-width: 74.9375em) {
    .checkout-cart-index table.cart .col.item-image,
    .quotation-quote-index table.cart .col.item-image,
    .dibs_easy_checkout-index-index table.cart .col.item-image {
      margin-bottom: 15px; } }
  .checkout-cart-index table.cart .col.item-image .product-image-container,
  .quotation-quote-index table.cart .col.item-image .product-image-container,
  .dibs_easy_checkout-index-index table.cart .col.item-image .product-image-container {
    max-width: 4.375rem; }
    @media screen and (min-width: 75em) {
      .checkout-cart-index table.cart .col.item-image .product-image-container,
      .quotation-quote-index table.cart .col.item-image .product-image-container,
      .dibs_easy_checkout-index-index table.cart .col.item-image .product-image-container {
        max-width: 6.875rem; } }
  @media screen and (max-width: 74.9375em) {
    .checkout-cart-index table.cart .col.item-info,
    .checkout-cart-index table.cart .col.item,
    .quotation-quote-index table.cart .col.item-info,
    .quotation-quote-index table.cart .col.item,
    .dibs_easy_checkout-index-index table.cart .col.item-info,
    .dibs_easy_checkout-index-index table.cart .col.item {
      float: none;
      clear: right;
      margin-left: 110px;
      margin-bottom: 15px;
      width: auto;
      padding: 0 20px 0 0; } }
  @media screen and (max-width: 74.9375em) {
    .checkout-cart-index table.cart .col.qty,
    .quotation-quote-index table.cart .col.qty,
    .dibs_easy_checkout-index-index table.cart .col.qty {
      margin-left: 110px;
      padding-left: 0; } }
  @media screen and (max-width: 400px) {
    .checkout-cart-index table.cart .col.qty,
    .quotation-quote-index table.cart .col.qty,
    .dibs_easy_checkout-index-index table.cart .col.qty {
      margin-left: 20px; } }
  @media screen and (max-width: 74.9375em) {
    .checkout-cart-index table.cart .print-info,
    .quotation-quote-index table.cart .print-info,
    .dibs_easy_checkout-index-index table.cart .print-info {
      float: none;
      clear: right;
      margin-left: 110px;
      width: auto;
      padding-right: 20px; } }
  .checkout-cart-index table.cart .col.subtotal .price-including-tax,
  .checkout-cart-index table.cart .col.subtotal .price-excluding-tax,
  .checkout-cart-index table.cart .col.subtotal .wishlist-price .price-box,
  .quotation-quote-index table.cart .col.subtotal .price-including-tax,
  .quotation-quote-index table.cart .col.subtotal .price-excluding-tax,
  .quotation-quote-index table.cart .col.subtotal .wishlist-price .price-box,
  .dibs_easy_checkout-index-index table.cart .col.subtotal .price-including-tax,
  .dibs_easy_checkout-index-index table.cart .col.subtotal .price-excluding-tax,
  .dibs_easy_checkout-index-index table.cart .col.subtotal .wishlist-price .price-box {
    font-family: "silka-semibold-webfont", sans-serif;
    line-height: initial; }
  .checkout-cart-index table.cart .price-including-tax,
  .checkout-cart-index table.cart .price-excluding-tax,
  .checkout-cart-index table.cart .wishlist-price .price-box,
  .quotation-quote-index table.cart .price-including-tax,
  .quotation-quote-index table.cart .price-excluding-tax,
  .quotation-quote-index table.cart .wishlist-price .price-box,
  .dibs_easy_checkout-index-index table.cart .price-including-tax,
  .dibs_easy_checkout-index-index table.cart .price-excluding-tax,
  .dibs_easy_checkout-index-index table.cart .wishlist-price .price-box {
    line-height: 30px; }
  .checkout-cart-index table.cart .item-options,
  .quotation-quote-index table.cart .item-options,
  .dibs_easy_checkout-index-index table.cart .item-options {
    display: flex;
    flex-wrap: wrap;
    color: #181818;
    margin: 27px 0 28px; }
    .checkout-cart-index table.cart .item-options .item-bubble,
    .quotation-quote-index table.cart .item-options .item-bubble,
    .dibs_easy_checkout-index-index table.cart .item-options .item-bubble {
      display: flex;
      align-items: center;
      margin: 0 6px 10px 0;
      padding: 6px 20px;
      border-radius: 30px;
      background: white;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
      font-size: 17px;
      color: #1A2746;
      /* Firefox */ }
      @media screen and (max-width: 74.9375em) {
        .checkout-cart-index table.cart .item-options .item-bubble,
        .quotation-quote-index table.cart .item-options .item-bubble,
        .dibs_easy_checkout-index-index table.cart .item-options .item-bubble {
          padding: 6px 12px; } }
      .checkout-cart-index table.cart .item-options .item-bubble .name,
      .quotation-quote-index table.cart .item-options .item-bubble .name,
      .dibs_easy_checkout-index-index table.cart .item-options .item-bubble .name {
        font-family: "silka-semibold-webfont", sans-serif;
        margin-right: 10px; }
        @media screen and (max-width: 74.9375em) {
          .checkout-cart-index table.cart .item-options .item-bubble .name,
          .quotation-quote-index table.cart .item-options .item-bubble .name,
          .dibs_easy_checkout-index-index table.cart .item-options .item-bubble .name {
            margin-right: 8px; } }
      .checkout-cart-index table.cart .item-options .item-bubble input[type="number"],
      .quotation-quote-index table.cart .item-options .item-bubble input[type="number"],
      .dibs_easy_checkout-index-index table.cart .item-options .item-bubble input[type="number"] {
        margin: 0;
        height: auto;
        text-align: center;
        width: auto;
        max-width: 50px;
        padding: 10px 6px;
        font-size: 15px; }
        @media screen and (max-width: 74.9375em) {
          .checkout-cart-index table.cart .item-options .item-bubble input[type="number"],
          .quotation-quote-index table.cart .item-options .item-bubble input[type="number"],
          .dibs_easy_checkout-index-index table.cart .item-options .item-bubble input[type="number"] {
            padding: 6px; } }
      .checkout-cart-index table.cart .item-options .item-bubble input::-webkit-outer-spin-button,
      .checkout-cart-index table.cart .item-options .item-bubble input::-webkit-inner-spin-button,
      .quotation-quote-index table.cart .item-options .item-bubble input::-webkit-outer-spin-button,
      .quotation-quote-index table.cart .item-options .item-bubble input::-webkit-inner-spin-button,
      .dibs_easy_checkout-index-index table.cart .item-options .item-bubble input::-webkit-outer-spin-button,
      .dibs_easy_checkout-index-index table.cart .item-options .item-bubble input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .checkout-cart-index table.cart .item-options .item-bubble input[type="number"],
      .quotation-quote-index table.cart .item-options .item-bubble input[type="number"],
      .dibs_easy_checkout-index-index table.cart .item-options .item-bubble input[type="number"] {
        -moz-appearance: textfield; }
  .checkout-cart-index table.cart .control.comment a,
  .quotation-quote-index table.cart .control.comment a,
  .dibs_easy_checkout-index-index table.cart .control.comment a {
    color: #1A2746;
    margin-bottom: 10px; }
    .checkout-cart-index table.cart .control.comment a:hover, .checkout-cart-index table.cart .control.comment a:focus,
    .quotation-quote-index table.cart .control.comment a:hover,
    .quotation-quote-index table.cart .control.comment a:focus,
    .dibs_easy_checkout-index-index table.cart .control.comment a:hover,
    .dibs_easy_checkout-index-index table.cart .control.comment a:focus {
      color: #1A2746; }
  .checkout-cart-index table.cart .control.comment .quotation-product-comment,
  .quotation-quote-index table.cart .control.comment .quotation-product-comment,
  .dibs_easy_checkout-index-index table.cart .control.comment .quotation-product-comment {
    width: 100%;
    max-width: 100%;
    min-height: 172px;
    border-radius: 0;
    text-align: left;
    padding: 10px;
    font-size: 15px; }
  .checkout-cart-index table.cart .control.comment .quotation-product-comment-counter,
  .quotation-quote-index table.cart .control.comment .quotation-product-comment-counter,
  .dibs_easy_checkout-index-index table.cart .control.comment .quotation-product-comment-counter {
    display: none;
    margin-top: 5px; }
    .checkout-cart-index table.cart .control.comment .quotation-product-comment-counter.invalid,
    .quotation-quote-index table.cart .control.comment .quotation-product-comment-counter.invalid,
    .dibs_easy_checkout-index-index table.cart .control.comment .quotation-product-comment-counter.invalid {
      color: #d41367; }
  .checkout-cart-index table.cart .control.comment.active .quotation-product-comment-counter,
  .quotation-quote-index table.cart .control.comment.active .quotation-product-comment-counter,
  .dibs_easy_checkout-index-index table.cart .control.comment.active .quotation-product-comment-counter {
    display: block; }

.checkout-cart-index .opc#checkoutSteps .methods-shipping,
.quotation-quote-index .opc#checkoutSteps .methods-shipping,
.dibs_easy_checkout-index-index .opc#checkoutSteps .methods-shipping {
  padding-left: 23px;
  padding-right: 23px; }
  @media screen and (min-width: 64em) {
    .checkout-cart-index .opc#checkoutSteps .methods-shipping,
    .quotation-quote-index .opc#checkoutSteps .methods-shipping,
    .dibs_easy_checkout-index-index .opc#checkoutSteps .methods-shipping {
      padding-left: 0;
      padding-right: 0; } }

@media print, screen and (min-width: 48em) {
  .checkout-cart-index .cart-form,
  .quotation-quote-index .cart-form,
  .dibs_easy_checkout-index-index .cart-form {
    width: 56.25%; } }

@media screen and (min-width: 64em) {
  .checkout-cart-index .cart-form,
  .quotation-quote-index .cart-form,
  .dibs_easy_checkout-index-index .cart-form {
    width: 65.83333%; } }

.checkout-cart-index .cart-summary,
.quotation-quote-index .cart-summary,
.dibs_easy_checkout-index-index .cart-summary {
  padding: 0 20px;
  background: none; }
  @media print, screen and (min-width: 48em) {
    .checkout-cart-index .cart-summary,
    .quotation-quote-index .cart-summary,
    .dibs_easy_checkout-index-index .cart-summary {
      width: 43.75%;
      float: right; } }
  @media screen and (min-width: 64em) {
    .checkout-cart-index .cart-summary,
    .quotation-quote-index .cart-summary,
    .dibs_easy_checkout-index-index .cart-summary {
      width: 34.16667%; } }
  .checkout-cart-index .cart-summary .block .title strong,
  .quotation-quote-index .cart-summary .block .title strong,
  .dibs_easy_checkout-index-index .cart-summary .block .title strong {
    font-size: 16px;
    border-bottom: none;
    text-decoration: underline; }
    .checkout-cart-index .cart-summary .block .title strong:hover,
    .quotation-quote-index .cart-summary .block .title strong:hover,
    .dibs_easy_checkout-index-index .cart-summary .block .title strong:hover {
      text-decoration: none; }
  .checkout-cart-index .cart-summary table .totals.grand,
  .quotation-quote-index .cart-summary table .totals.grand,
  .dibs_easy_checkout-index-index .cart-summary table .totals.grand {
    border-top: 1px solid #d3d2d1; }
  .checkout-cart-index .cart-summary .cart-totals,
  .quotation-quote-index .cart-summary .cart-totals,
  .dibs_easy_checkout-index-index .cart-summary .cart-totals {
    margin-left: 0;
    margin-right: 0; }
    .checkout-cart-index .cart-summary .cart-totals th,
    .quotation-quote-index .cart-summary .cart-totals th,
    .dibs_easy_checkout-index-index .cart-summary .cart-totals th {
      white-space: nowrap; }
      .checkout-cart-index .cart-summary .cart-totals th .label,
      .quotation-quote-index .cart-summary .cart-totals th .label,
      .dibs_easy_checkout-index-index .cart-summary .cart-totals th .label {
        white-space: nowrap; }
        @media screen and (max-width: 90.5625em) {
          .checkout-cart-index .cart-summary .cart-totals th .label,
          .quotation-quote-index .cart-summary .cart-totals th .label,
          .dibs_easy_checkout-index-index .cart-summary .cart-totals th .label {
            white-space: normal;
            min-width: 225px; } }
  .checkout-cart-index .cart-summary .customers-search .input-text,
  .quotation-quote-index .cart-summary .customers-search .input-text,
  .dibs_easy_checkout-index-index .cart-summary .customers-search .input-text {
    position: relative;
    z-index: 1; }
  .checkout-cart-index .cart-summary .customers-search .customers-dropdown,
  .quotation-quote-index .cart-summary .customers-search .customers-dropdown,
  .dibs_easy_checkout-index-index .cart-summary .customers-search .customers-dropdown {
    margin-top: calc(-1 * (.58333rem + 23px));
    border: 1px solid #9da3af;
    overflow: hidden;
    padding-top: 21px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); }
    .checkout-cart-index .cart-summary .customers-search .customers-dropdown .search-result,
    .quotation-quote-index .cart-summary .customers-search .customers-dropdown .search-result,
    .dibs_easy_checkout-index-index .cart-summary .customers-search .customers-dropdown .search-result {
      border-bottom: 1px solid #9da3af; }
      .checkout-cart-index .cart-summary .customers-search .customers-dropdown .search-result:last-child,
      .quotation-quote-index .cart-summary .customers-search .customers-dropdown .search-result:last-child,
      .dibs_easy_checkout-index-index .cart-summary .customers-search .customers-dropdown .search-result:last-child {
        border-bottom: none; }
      .checkout-cart-index .cart-summary .customers-search .customers-dropdown .search-result .customer,
      .quotation-quote-index .cart-summary .customers-search .customers-dropdown .search-result .customer,
      .dibs_easy_checkout-index-index .cart-summary .customers-search .customers-dropdown .search-result .customer {
        font-size: 1rem;
        padding: 6px 20px;
        cursor: pointer; }
        .checkout-cart-index .cart-summary .customers-search .customers-dropdown .search-result .customer:hover,
        .quotation-quote-index .cart-summary .customers-search .customers-dropdown .search-result .customer:hover,
        .dibs_easy_checkout-index-index .cart-summary .customers-search .customers-dropdown .search-result .customer:hover {
          background-color: #f8f7f6; }
        .checkout-cart-index .cart-summary .customers-search .customers-dropdown .search-result .customer .label,
        .quotation-quote-index .cart-summary .customers-search .customers-dropdown .search-result .customer .label,
        .dibs_easy_checkout-index-index .cart-summary .customers-search .customers-dropdown .search-result .customer .label {
          cursor: pointer; }
  .checkout-cart-index .cart-summary .form.form-shipping-address .control._with-tooltip .field-tooltip.toggle,
  .quotation-quote-index .cart-summary .form.form-shipping-address .control._with-tooltip .field-tooltip.toggle,
  .dibs_easy_checkout-index-index .cart-summary .form.form-shipping-address .control._with-tooltip .field-tooltip.toggle {
    display: none; }

.checkout-cart-index .action.primary.checkout.movetoquote.movetoquote-button,
.checkout-cart-index .action.primary.checkout.movetocart.movetocart-button,
.quotation-quote-index .action.primary.checkout.movetoquote.movetoquote-button,
.quotation-quote-index .action.primary.checkout.movetocart.movetocart-button,
.dibs_easy_checkout-index-index .action.primary.checkout.movetoquote.movetoquote-button,
.dibs_easy_checkout-index-index .action.primary.checkout.movetocart.movetocart-button {
  font-size: 0.89062rem;
  line-height: 1;
  padding-top: 1.1875rem;
  padding-bottom: 1.25rem; }
  @media screen and (min-width: 75em) {
    .checkout-cart-index .action.primary.checkout.movetoquote.movetoquote-button,
    .checkout-cart-index .action.primary.checkout.movetocart.movetocart-button,
    .quotation-quote-index .action.primary.checkout.movetoquote.movetoquote-button,
    .quotation-quote-index .action.primary.checkout.movetocart.movetocart-button,
    .dibs_easy_checkout-index-index .action.primary.checkout.movetoquote.movetoquote-button,
    .dibs_easy_checkout-index-index .action.primary.checkout.movetocart.movetocart-button {
      font-size: 1.1875rem; } }
  .checkout-cart-index .action.primary.checkout.movetoquote.movetoquote-button:hover, .checkout-cart-index .action.primary.checkout.movetoquote.movetoquote-button:focus,
  .checkout-cart-index .action.primary.checkout.movetocart.movetocart-button:hover,
  .checkout-cart-index .action.primary.checkout.movetocart.movetocart-button:focus,
  .quotation-quote-index .action.primary.checkout.movetoquote.movetoquote-button:hover,
  .quotation-quote-index .action.primary.checkout.movetoquote.movetoquote-button:focus,
  .quotation-quote-index .action.primary.checkout.movetocart.movetocart-button:hover,
  .quotation-quote-index .action.primary.checkout.movetocart.movetocart-button:focus,
  .dibs_easy_checkout-index-index .action.primary.checkout.movetoquote.movetoquote-button:hover,
  .dibs_easy_checkout-index-index .action.primary.checkout.movetoquote.movetoquote-button:focus,
  .dibs_easy_checkout-index-index .action.primary.checkout.movetocart.movetocart-button:hover,
  .dibs_easy_checkout-index-index .action.primary.checkout.movetocart.movetocart-button:focus {
    border: 1px solid transparent; }

.checkout-cart-index .action.primary.checkout.movetoquote.movetoquote-button,
.quotation-quote-index .action.primary.checkout.movetoquote.movetoquote-button,
.dibs_easy_checkout-index-index .action.primary.checkout.movetoquote.movetoquote-button {
  display: inline;
  width: auto;
  background: none;
  padding: 0;
  font-family: "silka-medium-webfont", sans-serif;
  font-size: 0.875rem;
  color: #1A2746;
  text-decoration: underline; }
  .checkout-cart-index .action.primary.checkout.movetoquote.movetoquote-button:hover,
  .quotation-quote-index .action.primary.checkout.movetoquote.movetoquote-button:hover,
  .dibs_easy_checkout-index-index .action.primary.checkout.movetoquote.movetoquote-button:hover {
    text-decoration: none; }

.checkout-cart-index .action.action-edit, .checkout-cart-index .action.action-towishlist,
.quotation-quote-index .action.action-edit,
.quotation-quote-index .action.action-towishlist,
.dibs_easy_checkout-index-index .action.action-edit,
.dibs_easy_checkout-index-index .action.action-towishlist {
  display: none; }

.checkout-cart-index .action.action-delete, .checkout-cart-index .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-delete, .ais-CurrentRefinements .ais-CurrentRefinements-item .checkout-cart-index .ais-CurrentRefinements-delete,
.quotation-quote-index .action.action-delete,
.quotation-quote-index .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-delete,
.ais-CurrentRefinements .ais-CurrentRefinements-item .quotation-quote-index .ais-CurrentRefinements-delete,
.dibs_easy_checkout-index-index .action.action-delete,
.dibs_easy_checkout-index-index .ais-CurrentRefinements .ais-CurrentRefinements-item .ais-CurrentRefinements-delete,
.ais-CurrentRefinements .ais-CurrentRefinements-item .dibs_easy_checkout-index-index .ais-CurrentRefinements-delete {
  display: inline-block; }

.checkout-methods-items .cart.main.actions {
  margin-top: 20px;
  text-align: center; }

@media screen and (max-width: 74.9375em) {
  .checkout-cart-index table.cart .col.qty,
  .dibs_easy_checkout-index-index table.cart .col.qty {
    margin-left: 0;
    padding-left: 20px;
    padding-right: 0; } }

@media screen and (max-width: 74.9375em) {
  .checkout-cart-index table.cart .col.price, .checkout-cart-index table.cart .col.subtotal,
  .dibs_easy_checkout-index-index table.cart .col.price,
  .dibs_easy_checkout-index-index table.cart .col.subtotal {
    line-height: 30px;
    padding: 0 10px; } }

@media screen and (min-width: 64em) {
  .checkout-cart-index table.cart .col.actions,
  .dibs_easy_checkout-index-index table.cart .col.actions {
    padding-right: 15px; } }

@media screen and (min-width: 75em) {
  .checkout-cart-index table.cart .col.actions,
  .dibs_easy_checkout-index-index table.cart .col.actions {
    padding-right: 40px; } }

.quotation-quote-index .quote-title-container {
  max-width: 90.625rem;
  margin-right: auto;
  margin-left: auto; }
  .quotation-quote-index .quote-title-container::before, .quotation-quote-index .quote-title-container::after {
    display: table;
    content: ' '; }
  .quotation-quote-index .quote-title-container::after {
    clear: both; }
  .quotation-quote-index .quote-title-container .page-title-wrapper {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
    display: flex;
    align-items: flex-end;
    padding: 0; }
    @media print, screen and (min-width: 40em) {
      .quotation-quote-index .quote-title-container .page-title-wrapper {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    .quotation-quote-index .quote-title-container .page-title-wrapper:last-child:not(:first-child) {
      float: right; }
    @media print, screen and (min-width: 48em) {
      .quotation-quote-index .quote-title-container .page-title-wrapper {
        width: 65.83333%; } }
    @media screen and (max-width: 39.9375em) {
      .quotation-quote-index .quote-title-container .page-title-wrapper {
        flex-wrap: wrap; } }
    .quotation-quote-index .quote-title-container .page-title-wrapper .page-title {
      padding: 0; }
      @media screen and (max-width: 63.9375em) {
        .quotation-quote-index .quote-title-container .page-title-wrapper .page-title {
          font-size: 1.875rem; } }
      @media screen and (max-width: 39.9375em) {
        .quotation-quote-index .quote-title-container .page-title-wrapper .page-title {
          margin-bottom: 10px; } }
  .quotation-quote-index .quote-title-container .quote-totalsum {
    display: flex;
    font-size: 22px;
    color: #1A2746; }
    @media screen and (min-width: 64em) {
      .quotation-quote-index .quote-title-container .quote-totalsum {
        margin-bottom: 10px; } }
    @media screen and (min-width: 75em) {
      .quotation-quote-index .quote-title-container .quote-totalsum {
        font-size: 36px;
        margin-bottom: 5px; } }
    .quotation-quote-index .quote-title-container .quote-totalsum .total {
      margin-right: 10px;
      white-space: nowrap; }
      @media screen and (min-width: 64em) {
        .quotation-quote-index .quote-title-container .quote-totalsum .total {
          margin-right: 15px; } }

.quotation-quote-index table.cart tbody {
  border-bottom: 2px solid white !important; }

.quotation-quote-index table.cart tr {
  position: relative; }
  .quotation-quote-index table.cart tr.item-info {
    border-width: 2px !important; }
    .quotation-quote-index table.cart tr.item-info th, .quotation-quote-index table.cart tr.item-info td {
      padding-left: 10px;
      padding-right: 10px;
      vertical-align: top; }
      @media screen and (max-width: 74.9375em) {
        .quotation-quote-index table.cart tr.item-info th, .quotation-quote-index table.cart tr.item-info td {
          padding-top: 0;
          padding-bottom: 0; }
          .quotation-quote-index table.cart tr.item-info th:first-child, .quotation-quote-index table.cart tr.item-info td:first-child {
            float: left; } }
    .quotation-quote-index table.cart tr.item-info .product-item-details {
      position: relative; }
    .quotation-quote-index table.cart tr.item-info .tier-prices-tooltip {
      display: none;
      padding: 12px 20px;
      position: absolute;
      top: 100%;
      margin-top: 10px;
      background: #f8f7f6;
      box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2);
      z-index: 1; }
      .quotation-quote-index table.cart tr.item-info .tier-prices-tooltip strong {
        display: block;
        margin-bottom: 6px; }
      .quotation-quote-index table.cart tr.item-info .tier-prices-tooltip .quote-pricing-tier {
        margin: 0; }
        .quotation-quote-index table.cart tr.item-info .tier-prices-tooltip .quote-pricing-tier tbody {
          border: none !important; }
        .quotation-quote-index table.cart tr.item-info .tier-prices-tooltip .quote-pricing-tier tr {
          display: table-row; }
          .quotation-quote-index table.cart tr.item-info .tier-prices-tooltip .quote-pricing-tier tr th,
          .quotation-quote-index table.cart tr.item-info .tier-prices-tooltip .quote-pricing-tier tr td {
            display: table-cell;
            float: none !important;
            padding: 2px 0 !important;
            width: auto !important;
            font-size: 14px; }
          .quotation-quote-index table.cart tr.item-info .tier-prices-tooltip .quote-pricing-tier tr th {
            font-weight: bold;
            padding-right: 20px !important; }
    @media print, screen and (min-width: 48em) {
      .quotation-quote-index table.cart tr.item-info:hover .tier-prices-tooltip {
        display: block; } }
  .quotation-quote-index table.cart tr .field.qty {
    padding: 0;
    margin-bottom: 8px; }
    @media screen and (max-width: 63.9375em) {
      .quotation-quote-index table.cart tr .field.qty {
        margin-bottom: 10px; } }
    .quotation-quote-index table.cart tr .field.qty:after {
      display: none;
      content: ''; }
    .quotation-quote-index table.cart tr .field.qty.with-supplier-cost {
      margin-bottom: 38px; }
      @media screen and (max-width: 63.9375em) {
        .quotation-quote-index table.cart tr .field.qty.with-supplier-cost {
          margin-bottom: 42px; } }
    .quotation-quote-index table.cart tr .field.qty .control.qty-field {
      margin-bottom: 0; }
      .quotation-quote-index table.cart tr .field.qty .control.qty-field input[type="number"] {
        text-align: center;
        font-size: 19px;
        margin: 0;
        padding: 0;
        height: 56px;
        border: 1px solid #cecece;
        border-radius: 50px;
        background: #f8f7f6;
        width: 160px; }
      @media screen and (max-width: 74.9375em) {
        .quotation-quote-index table.cart tr .field.qty .control.qty-field input[type="number"] {
          font-size: 15px;
          height: 32px;
          width: 100px; }
        .quotation-quote-index table.cart tr .field.qty .control.qty-field .qty-field__control {
          width: 30px;
          height: 30px; }
          .quotation-quote-index table.cart tr .field.qty .control.qty-field .qty-field__control span {
            font-size: 24px;
            line-height: 24px; } }
    .quotation-quote-index table.cart tr .field.qty .control.qty {
      height: 56px;
      display: flex;
      align-items: center; }
      @media screen and (min-width: 64em) {
        .quotation-quote-index table.cart tr .field.qty .control.qty {
          justify-content: flex-end; } }
      @media screen and (max-width: 74.9375em) {
        .quotation-quote-index table.cart tr .field.qty .control.qty {
          height: 32px; } }
      .quotation-quote-index table.cart tr .field.qty .control.qty .qty-wrapper {
        display: flex;
        justify-content: flex-end; }
        @media screen and (max-width: 63.9375em) {
          .quotation-quote-index table.cart tr .field.qty .control.qty .qty-wrapper {
            justify-content: space-between; } }
        .quotation-quote-index table.cart tr .field.qty .control.qty .qty-wrapper .qty-x {
          margin-left: 5px; }
    .quotation-quote-index table.cart tr .field.qty .actions-toolbar {
      display: none; }
  .quotation-quote-index table.cart tr .col.item-image {
    text-align: center; }
    .quotation-quote-index table.cart tr .col.item-image .all-images-link {
      font-family: "silka-medium-webfont", sans-serif;
      display: block;
      max-width: 110px;
      white-space: normal;
      margin: 10px 0 0; }
      @media screen and (max-width: 74.9375em) {
        .quotation-quote-index table.cart tr .col.item-image .all-images-link {
          font-size: 0.8125rem; } }
      .quotation-quote-index table.cart tr .col.item-image .all-images-link:hover, .quotation-quote-index table.cart tr .col.item-image .all-images-link:focus {
        color: #181818; }
  @media screen and (min-width: 64em) {
    .quotation-quote-index table.cart tr .product-item-sku {
      margin-bottom: 3px; } }
  .quotation-quote-index table.cart tr .col.qty {
    position: relative; }
    @media screen and (max-width: 74.9375em) {
      .quotation-quote-index table.cart tr .col.qty {
        padding-bottom: 0; } }
    .quotation-quote-index table.cart tr .col.qty .min-qty-notification {
      position: absolute;
      bottom: -5px;
      font-size: 13px;
      white-space: nowrap;
      color: #D41367; }
      @media screen and (min-width: 64em) {
        .quotation-quote-index table.cart tr .col.qty .min-qty-notification {
          bottom: 0; } }
      @media screen and (min-width: 75em) {
        .quotation-quote-index table.cart tr .col.qty .min-qty-notification {
          top: 10px;
          bottom: auto;
          font-size: 15px; } }
  .quotation-quote-index table.cart tr .col.price {
    position: relative;
    max-width: 130px; }
    .quotation-quote-index table.cart tr .col.price .quote-item-price-component {
      margin-bottom: 10px; }
      .quotation-quote-index table.cart tr .col.price .quote-item-price-component > .price-wrapper {
        border: 1px solid #cecece;
        border-radius: 50px;
        background: #f8f7f6; }
    .quotation-quote-index table.cart tr .col.price .item-price {
      text-align: center;
      height: 54px;
      padding-left: 10px;
      padding-right: 10px;
      -moz-appearance: textfield; }
      .quotation-quote-index table.cart tr .col.price .item-price::-webkit-outer-spin-button, .quotation-quote-index table.cart tr .col.price .item-price::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      @media screen and (max-width: 74.9375em) {
        .quotation-quote-index table.cart tr .col.price .item-price {
          font-size: 15px;
          height: 32px; } }
    .quotation-quote-index table.cart tr .col.price .product-item-cost {
      position: relative;
      min-height: 20px;
      margin-bottom: 10px; }
      .quotation-quote-index table.cart tr .col.price .product-item-cost .inner {
        white-space: nowrap;
        position: absolute;
        right: 0;
        color: rgba(24, 24, 24, 0.7); }
    .quotation-quote-index table.cart tr .col.price .price-including-tax,
    .quotation-quote-index table.cart tr .col.price .price-excluding-tax,
    .quotation-quote-index table.cart tr .col.price .wishlist-price .price-box {
      line-height: 52px;
      width: 100%;
      text-align: center; }
      @media screen and (max-width: 74.9375em) {
        .quotation-quote-index table.cart tr .col.price .price-including-tax,
        .quotation-quote-index table.cart tr .col.price .price-excluding-tax,
        .quotation-quote-index table.cart tr .col.price .wishlist-price .price-box {
          line-height: 30px;
          margin-top: 0; } }
      @media screen and (min-width: 75em) {
        .quotation-quote-index table.cart tr .col.price .price-including-tax,
        .quotation-quote-index table.cart tr .col.price .price-excluding-tax,
        .quotation-quote-index table.cart tr .col.price .wishlist-price .price-box {
          font-size: 1.125rem; } }
  .quotation-quote-index table.cart tr .col.subtotal {
    text-align: right; }
    .quotation-quote-index table.cart tr .col.subtotal .price-including-tax,
    .quotation-quote-index table.cart tr .col.subtotal .price-excluding-tax,
    .quotation-quote-index table.cart tr .col.subtotal .wishlist-price .price-box {
      font-family: "silka-semibold-webfont", sans-serif;
      line-height: 42px; }
      @media print, screen and (min-width: 48em) {
        .quotation-quote-index table.cart tr .col.subtotal .price-including-tax,
        .quotation-quote-index table.cart tr .col.subtotal .price-excluding-tax,
        .quotation-quote-index table.cart tr .col.subtotal .wishlist-price .price-box {
          font-size: 1.125rem; } }
  .quotation-quote-index table.cart tr .col.actions {
    padding: 0; }
    @media screen and (max-width: 63.9375em) {
      .quotation-quote-index table.cart tr .col.actions {
        position: absolute;
        top: 0;
        right: 0; } }
    .quotation-quote-index table.cart tr .col.actions .action-delete {
      width: 40px;
      height: 40px;
      background: none;
      text-align: center; }
      @media screen and (min-width: 64em) {
        .quotation-quote-index table.cart tr .col.actions .action-delete {
          width: 30px;
          margin-top: -15px; } }
      @media screen and (min-width: 75em) {
        .quotation-quote-index table.cart tr .col.actions .action-delete {
          width: 40px;
          margin-top: 0; } }
      .quotation-quote-index table.cart tr .col.actions .action-delete:before {
        content: '\e905' !important;
        display: inline-block;
        font-family: 'cart2quote-iconset' !important;
        color: #000;
        font-size: 24px;
        line-height: 40px;
        margin: 0;
        overflow: hidden;
        text-align: center;
        vertical-align: top; }
  .quotation-quote-index table.cart tr .action.update {
    width: 100%;
    font-size: 0.9375rem;
    background-color: #1A2746;
    color: white; }
    .quotation-quote-index table.cart tr .action.update:hover, .quotation-quote-index table.cart tr .action.update:focus {
      background-color: #283c6b;
      color: white; }

.quotation-quote-index .page-container .cart-container .form.form-cart {
  margin-bottom: 30px;
  order: 1;
  width: auto;
  margin-left: -6px;
  margin-right: -6px; }
  @media print, screen and (min-width: 48em) {
    .quotation-quote-index .page-container .cart-container .form.form-cart {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem;
      width: 65.83333%;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
      max-width: 100% !important; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .quotation-quote-index .page-container .cart-container .form.form-cart {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  @media print, screen and (min-width: 48em) {
      .quotation-quote-index .page-container .cart-container .form.form-cart:last-child:not(:first-child) {
        float: right; } }
  .quotation-quote-index .page-container .cart-container .form.form-cart .cart.main.actions {
    display: none; }

.quotation-quote-index .page-container .cart-container .quote-details-wrapper {
  margin-left: -6px;
  margin-right: -6px;
  width: auto;
  order: 2;
  padding: 0; }
  @media print, screen and (min-width: 48em) {
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem;
      width: 34.16667%; } }
  @media print, screen and (min-width: 48em) and (min-width: 40em) {
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  @media print, screen and (min-width: 48em) {
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper:last-child:not(:first-child) {
        float: right; } }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .authentication-wrapper {
    display: none; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .step-title {
    border-top: none !important;
    border-bottom: none !important;
    cursor: default; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .step-title strong {
      font-size: 1.125rem;
      text-decoration: none;
      color: #1A2746;
      font-family: "silka-regular-webfont", sans-serif;
      border: none !important;
      padding: 0 !important; }
      @media screen and (min-width: 64em) {
        .quotation-quote-index .page-container .cart-container .quote-details-wrapper .step-title strong {
          font-size: 1.75rem; } }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .step-title:after {
      content: '';
      display: none; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .shipping-address-item {
    border: none;
    width: 100% !important; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .shipping-address-item label:before {
      top: 5px; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .shipping-address-item label:after {
      top: 8px; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .shipping-address-item .col-row .shipping-address {
      width: 100%;
      font-size: 0.9375rem;
      line-height: 1.5;
      padding-left: 25px; }
      @media screen and (min-width: 64em) {
        .quotation-quote-index .page-container .cart-container .quote-details-wrapper .shipping-address-item .col-row .shipping-address {
          font-size: 1.0625rem;
          line-height: 1.76;
          padding-left: 35px; } }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .shipping-address-item .col-row .shipping-address strong {
        font-weight: 400;
        font-size: 0.9375rem;
        line-height: 1.76; }
        @media screen and (min-width: 64em) {
          .quotation-quote-index .page-container .cart-container .quote-details-wrapper .shipping-address-item .col-row .shipping-address strong {
            font-size: 1.0625rem; } }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .quote-details-container {
    width: auto;
    padding: 0; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .quote-details-container .loading-mask {
      min-height: 80px;
      position: relative; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .upload-file-button {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    padding: 0.82em 1em 0.87em;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 50px;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 1.1875rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: #30A95E;
    color: #fff;
    background-color: #1A2746;
    color: white;
    font-size: 0.9375rem;
    font-family: "silka-semibold-webfont", sans-serif;
    height: 46px;
    padding-top: 1rem;
    margin: 0; }
    [data-whatinput='mouse'] .quotation-quote-index .page-container .cart-container .quote-details-wrapper .upload-file-button {
      outline: 0; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .upload-file-button:hover, .quotation-quote-index .page-container .cart-container .quote-details-wrapper .upload-file-button:focus {
      background-color: rgba(48, 169, 94, 0.75);
      color: #fff; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .upload-file-button:hover, .quotation-quote-index .page-container .cart-container .quote-details-wrapper .upload-file-button:focus {
      background-color: #283c6b;
      color: white; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .upload-ext {
    font-size: 0.8125rem;
    font-family: "silka-regular-webfont", sans-serif;
    opacity: .5; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper input[type="text"],
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .input-text,
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper textarea,
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper select {
    font-size: 0.9375rem;
    background-color: white;
    border: 1px solid #9DA3AF; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper input[type="text"],
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .input-text,
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper select {
    height: 46px;
    padding: 3px 14px; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper select {
    padding-right: 40px;
    background-position: calc(100% - 15px) 50%; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper input[type="file"] {
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container {
    width: 100%;
    background: none;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid rgba(60, 71, 97, 0.2); }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .step-title {
      color: #1A2746;
      font-size: 1.0625rem;
      border-top: 1px solid rgba(60, 71, 97, 0.2) !important;
      font-family: "silka-medium-webfont", sans-serif;
      padding: 21px 36px 21px 46px;
      margin: 0;
      cursor: pointer;
      position: relative; }
      @media print, screen and (min-width: 48em) {
        .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .step-title {
          padding: 21px 26px; } }
      @media screen and (min-width: 64em) {
        .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .step-title {
          padding: 21px 36px; } }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .step-title:before {
        display: block;
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        top: 24px;
        left: 24px;
        background-image: url("../images/icon-plus--active.svg");
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center; }
        @media print, screen and (min-width: 48em) {
          .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .step-title:before {
            left: 4px; } }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container [data-role="content"] {
      margin-top: 0;
      padding-left: 23px;
      padding-right: 23px; }
      @media screen and (min-width: 64em) {
        .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container [data-role="content"] {
          padding-left: 0;
          padding-right: 0; } }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container.active .step-title:before {
      background-image: url("../images/icon-minus--red.svg"); }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container ul:not(.uploaded-files) {
      margin-left: 0; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .uploaded-files {
      margin: 0;
      border: none; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .add-file-button {
      font-size: 0.9375rem;
      font-family: "silka-medium-webfont", sans-serif;
      text-decoration: underline;
      color: #1A2746;
      cursor: pointer; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .action.upload {
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 1rem 0;
      padding: 0.82em 1em 0.87em;
      -webkit-appearance: none;
      border: 1px solid transparent;
      border-radius: 50px;
      transition: background-color 0.25s ease-out, color 0.25s ease-out;
      font-size: 1.1875rem;
      line-height: 1;
      text-align: center;
      cursor: pointer;
      background-color: #30A95E;
      color: #fff;
      border: 1px solid #1A2746;
      color: #1A2746;
      font-size: 1.1875rem;
      font-family: "silka-medium-webfont", sans-serif;
      margin: 24px 0 30px; }
      [data-whatinput='mouse'] .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .action.upload {
        outline: 0; }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .action.upload:hover, .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .action.upload:focus {
        background-color: rgba(48, 169, 94, 0.75);
        color: #fff; }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .action.upload:hover, .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .action.upload:focus {
        border-color: #0d1423;
        color: #0d1423; }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .action.upload, .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .action.upload:hover, .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .action.upload:focus {
        background-color: transparent; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .upload-remove-file {
      text-decoration: none; }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .file-upload-container .upload-remove-file .icon-c2q_dustbin {
        font-size: 24px;
        vertical-align: middle;
        margin: -10px 0 0;
        display: inline-block; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .movetocart.movetocart-button {
    background: none;
    margin: 4px 0 0;
    padding: 0;
    color: #1A2746;
    font-size: 1.1875rem;
    font-family: "silka-medium-webfont", sans-serif; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .movetocart.movetocart-button span {
      position: relative; }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .movetocart.movetocart-button span:after {
        position: absolute;
        content: '';
        display: block;
        height: 1px;
        background-color: #D52469;
        left: 0;
        width: 100%;
        bottom: -4px; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper #co-print-quote-form .action.checkout {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    padding: 0.82em 1em 0.87em;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 50px;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 1.1875rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: #30A95E;
    color: #fff;
    border: 1px solid #1A2746;
    color: #1A2746;
    font-size: 1.1875rem;
    font-family: "silka-medium-webfont", sans-serif; }
    [data-whatinput='mouse'] .quotation-quote-index .page-container .cart-container .quote-details-wrapper #co-print-quote-form .action.checkout {
      outline: 0; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper #co-print-quote-form .action.checkout:hover, .quotation-quote-index .page-container .cart-container .quote-details-wrapper #co-print-quote-form .action.checkout:focus {
      background-color: rgba(48, 169, 94, 0.75);
      color: #fff; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper #co-print-quote-form .action.checkout:hover, .quotation-quote-index .page-container .cart-container .quote-details-wrapper #co-print-quote-form .action.checkout:focus {
      border-color: #0d1423;
      color: #0d1423; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper #co-print-quote-form .action.checkout, .quotation-quote-index .page-container .cart-container .quote-details-wrapper #co-print-quote-form .action.checkout:hover, .quotation-quote-index .page-container .cart-container .quote-details-wrapper #co-print-quote-form .action.checkout:focus {
      background-color: transparent; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps {
    margin: 0;
    list-style: none; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps li {
      padding: 0; }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps li:after {
        display: none; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps .checkout-shipping-address,
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps .checkout-quote-details,
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps #billing {
      margin: 48px 0 0;
      padding-left: 23px;
      padding-right: 23px; }
      @media print, screen and (min-width: 48em) {
        .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps .checkout-shipping-address,
        .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps .checkout-quote-details,
        .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps #billing {
          padding-left: 0;
          padding-right: 0; } }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps .additional-fields {
      border-bottom: 1px solid rgba(60, 71, 97, 0.2) !important; }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps .additional-fields .step-title {
        color: #1A2746;
        font-size: 1.0625rem;
        font-family: "silka-medium-webfont", sans-serif;
        border-top: 1px solid rgba(60, 71, 97, 0.2) !important;
        padding: 21px 36px 21px 46px;
        cursor: pointer;
        margin: 0;
        position: relative; }
        @media screen and (min-width: 64em) {
          .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps .additional-fields .step-title {
            padding: 21px 36px; } }
        .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps .additional-fields .step-title:before {
          display: block;
          content: '';
          width: 12px;
          height: 12px;
          position: absolute;
          top: 24px;
          left: 24px;
          background-image: url("../images/icon-plus--active.svg");
          background-repeat: no-repeat;
          background-size: 100% auto;
          background-position: center; }
          @media screen and (min-width: 64em) {
            .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps .additional-fields .step-title:before {
              left: 4px; } }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps .additional-fields [data-role="content"] {
        margin-top: 20px;
        padding-left: 23px;
        padding-right: 23px; }
        @media screen and (min-width: 64em) {
          .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps .additional-fields [data-role="content"] {
            padding-left: 0;
            padding-right: 0; } }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper .opc#checkoutSteps .additional-fields.active .step-title:before {
        background-image: url("../images/icon-minus--red.svg"); }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper #checkout-step-billing .billing-same-as-shipping {
    line-height: 1.2;
    margin-bottom: .75rem;
    position: relative;
    padding: 13px 0; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper #checkout-step-billing .billing-same-as-shipping input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0; }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper #checkout-step-billing .billing-same-as-shipping input[type="checkbox"] + label,
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper #checkout-step-billing .billing-same-as-shipping input[type="checkbox"] + .label {
        cursor: pointer;
        font-weight: normal;
        position: relative;
        margin: 0;
        line-height: 1.2;
        min-height: 1rem;
        padding-left: 1.4rem; }
        .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper #checkout-step-billing .billing-same-as-shipping input[type="checkbox"] + label:before,
        .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper #checkout-step-billing .billing-same-as-shipping input[type="checkbox"] + .label:before {
          display: block;
          content: '';
          height: 1rem;
          width: 1rem;
          line-height: 1rem;
          text-align: center;
          position: absolute;
          top: -1px;
          left: 0;
          border: 1px solid #9DA3AF;
          color: #181818;
          background: white; }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper #checkout-step-billing .billing-same-as-shipping input[type="checkbox"]:checked + label:before,
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper #checkout-step-billing .billing-same-as-shipping input[type="checkbox"]:checked + .label:before {
        content: '✓'; }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper #checkout-step-billing .billing-same-as-shipping input[type="checkbox"]:disabled + label {
        opacity: .4; }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper #checkout-step-billing .billing-same-as-shipping input[type="checkbox"]:disabled + label:before,
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper #checkout-step-billing .billing-same-as-shipping input[type="checkbox"]:disabled + .label:before {
        background: #eee; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .opc-wrapper #checkout-step-shipping .action-show-popup {
    margin-top: 20px;
    font-size: 0.9375rem;
    text-decoration: underline;
    color: #1A2746;
    font-family: "silka-medium-webfont", sans-serif;
    cursor: pointer; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .admin__control-textarea {
    min-height: 130px;
    border-radius: 5px;
    margin: 0; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .cart-summary {
    padding: 0;
    margin-bottom: 10px;
    position: relative;
    position: unset !important; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .cart-summary ol {
      margin-left: 0; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .cart-summary .form.form-login {
      padding: 0;
      box-shadow: none; }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .cart-summary .form.form-login .field {
        padding: 0;
        width: 100%; }
      .quotation-quote-index .page-container .cart-container .quote-details-wrapper .cart-summary .form.form-login .actions-toolbar {
        margin-right: -1.0625rem;
        margin-left: -1.0625rem;
        padding-top: 10px; }
        @media print, screen and (min-width: 40em) {
          .quotation-quote-index .page-container .cart-container .quote-details-wrapper .cart-summary .form.form-login .actions-toolbar {
            margin-right: -1.25rem;
            margin-left: -1.25rem; } }
        @media print, screen and (min-width: 48em) {
          .quotation-quote-index .page-container .cart-container .quote-details-wrapper .cart-summary .form.form-login .actions-toolbar {
            margin-right: -1.25rem;
            margin-left: -1.25rem; } }
        @media screen and (min-width: 64em) {
          .quotation-quote-index .page-container .cart-container .quote-details-wrapper .cart-summary .form.form-login .actions-toolbar {
            margin-right: -1.25rem;
            margin-left: -1.25rem; } }
        @media screen and (min-width: 75em) {
          .quotation-quote-index .page-container .cart-container .quote-details-wrapper .cart-summary .form.form-login .actions-toolbar {
            margin-right: -1.25rem;
            margin-left: -1.25rem; } }
        @media screen and (min-width: 90.625em) {
          .quotation-quote-index .page-container .cart-container .quote-details-wrapper .cart-summary .form.form-login .actions-toolbar {
            margin-right: -1.25rem;
            margin-left: -1.25rem; } }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .cart-summary .field-tooltip .label {
      display: none; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .cart-summary .checkout-methods-items {
      padding: 0;
      margin-top: 30px; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .cart-summary #co-add-to-quote-form {
      margin-top: 43px;
      padding-left: 23px;
      padding-right: 23px; }
      @media screen and (min-width: 64em) {
        .quotation-quote-index .page-container .cart-container .quote-details-wrapper .cart-summary #co-add-to-quote-form {
          padding-left: 0;
          padding-right: 0; } }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .fieldset .field .label {
    font-weight: 400;
    font-family: "silka-medium-webfont", sans-serif; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper .table-checkout-shipping-method {
    table-layout: auto; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .table-checkout-shipping-method td {
      text-align: left;
      padding-left: 0;
      padding-right: 5px; }
    .quotation-quote-index .page-container .cart-container .quote-details-wrapper .table-checkout-shipping-method input[type="radio"] {
      margin-top: 5px; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper #fileUpload .upload-header p,
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper #fileUpload .upload-header div,
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper #fileUpload .upload-row p,
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper #fileUpload .upload-row div {
    font-family: "silka-medium-webfont", sans-serif;
    font-size: 0.9375rem;
    color: #1A2746;
    font-weight: normal !important; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper #fileUpload .upload-header .upload-row-title,
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper #fileUpload .upload-row .upload-row-title {
    padding-right: 20px; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper #fileUpload .upload-header .upload-row-title,
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper #fileUpload .upload-header .upload-first-cell,
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper #fileUpload .upload-row .upload-row-title,
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper #fileUpload .upload-row .upload-first-cell {
    width: 43%; }
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper #fileUpload .upload-header .upload-second-cell,
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper #fileUpload .upload-header .upload-row-file,
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper #fileUpload .upload-row .upload-second-cell,
  .quotation-quote-index .page-container .cart-container .quote-details-wrapper #fileUpload .upload-row .upload-row-file {
    width: 57%; }

.quotation-quote-index div._required label > span::after {
  content: '*'; }

@media screen and (max-width: 47.9375em) {
  aside.modal-popup.modal-slide {
    left: 0; }
    aside.modal-popup.modal-slide .modal-content {
      padding-bottom: 40px; } }

aside.modal-popup.popup-authentication .action-close {
  top: 0;
  right: 0; }

aside.modal-popup.popup-authentication .modal-inner-wrap {
  background-color: white;
  opacity: 1;
  min-height: auto;
  width: 90%;
  margin: 20px auto; }
  @media screen and (max-width: 47.9375em) {
    aside.modal-popup.popup-authentication .modal-inner-wrap {
      background-color: white !important;
      min-height: auto !important; } }
  @media print, screen and (min-width: 40em) {
    aside.modal-popup.popup-authentication .modal-inner-wrap {
      margin: 80px auto; } }
  @media print, screen and (min-width: 48em) {
    aside.modal-popup.popup-authentication .modal-inner-wrap {
      width: 75%; } }
  aside.modal-popup.popup-authentication .modal-inner-wrap .login-container .block-title {
    font-size: 22px; }
    @media screen and (min-width: 75em) {
      aside.modal-popup.popup-authentication .modal-inner-wrap .login-container .block-title {
        font-size: 28px; } }
  aside.modal-popup.popup-authentication .modal-inner-wrap .form-login {
    padding: 20px; }
    @media screen and (min-width: 75em) {
      aside.modal-popup.popup-authentication .modal-inner-wrap .form-login {
        padding: 30px; } }
    aside.modal-popup.popup-authentication .modal-inner-wrap .form-login .field {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem; }
      @media print, screen and (min-width: 40em) {
        aside.modal-popup.popup-authentication .modal-inner-wrap .form-login .field {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      aside.modal-popup.popup-authentication .modal-inner-wrap .form-login .field:last-child:not(:first-child) {
        float: right; }
      @media screen and (min-width: 75em) {
        aside.modal-popup.popup-authentication .modal-inner-wrap .form-login .field {
          width: 50%; } }
      aside.modal-popup.popup-authentication .modal-inner-wrap .form-login .field.additional {
        width: 100%;
        margin-bottom: 20px; }
  aside.modal-popup.popup-authentication .modal-inner-wrap .block-customer-login,
  aside.modal-popup.popup-authentication .modal-inner-wrap .block-new-customer,
  aside.modal-popup.popup-authentication .modal-inner-wrap .block-new-company {
    width: 100%;
    float: left;
    padding-right: 1.0625rem;
    padding-left: 1.0625rem; }
    @media print, screen and (min-width: 40em) {
      aside.modal-popup.popup-authentication .modal-inner-wrap .block-customer-login,
      aside.modal-popup.popup-authentication .modal-inner-wrap .block-new-customer,
      aside.modal-popup.popup-authentication .modal-inner-wrap .block-new-company {
        padding-right: 1.25rem;
        padding-left: 1.25rem; } }
    aside.modal-popup.popup-authentication .modal-inner-wrap .block-customer-login:last-child:not(:first-child),
    aside.modal-popup.popup-authentication .modal-inner-wrap .block-new-customer:last-child:not(:first-child),
    aside.modal-popup.popup-authentication .modal-inner-wrap .block-new-company:last-child:not(:first-child) {
      float: right; }
    @media screen and (min-width: 64em) {
      aside.modal-popup.popup-authentication .modal-inner-wrap .block-customer-login,
      aside.modal-popup.popup-authentication .modal-inner-wrap .block-new-customer,
      aside.modal-popup.popup-authentication .modal-inner-wrap .block-new-company {
        width: 50%; } }

aside.modal-popup.popup-quote-image .modal-inner-wrap {
  max-width: 800px; }

aside.modal-popup.popup-quote-image .modal-header {
  padding-bottom: 30px; }
  aside.modal-popup.popup-quote-image .modal-header .modal-title {
    border-bottom: none; }

aside.modal-popup.popup-quote-image .quote-images .quote-image-label {
  display: inline-block;
  margin: 0 15px 15px 0; }
  aside.modal-popup.popup-quote-image .quote-images .quote-image-label input[type="radio"] {
    display: none; }
    aside.modal-popup.popup-quote-image .quote-images .quote-image-label input[type="radio"]:checked ~ .quote-image {
      border-color: #1A2746; }

aside.modal-popup.popup-quote-image .quote-images .quote-image {
  border: 2px solid transparent;
  cursor: pointer; }

aside.modal-popup.popup-quote-image .modal-footer {
  text-align: center; }
  aside.modal-popup.popup-quote-image .modal-footer button {
    margin: 0 10px; }

aside.modal-popup.nets-popup-wrapper #nets-popup-iframe {
  min-height: 800px;
  width: 100%; }
  @media screen and (max-width: 39.9375em) {
    aside.modal-popup.nets-popup-wrapper #nets-popup-iframe {
      min-height: 900px; } }

aside.modal-popup.nets-popup-wrapper .action-close {
  top: 0;
  right: 0; }

aside.modal-popup.nets-popup-wrapper .modal-inner-wrap {
  max-width: 770px;
  opacity: 1;
  margin: 0; }

aside.modal-popup.nets-popup-wrapper .modal-header {
  padding-bottom: 30px; }

aside.modal-popup.nets-popup-wrapper .modal-title {
  border-bottom: none; }

aside.modal-popup.nets-popup-wrapper .modal-footer {
  display: none; }

aside.modal-popup.filters .action-close {
  width: 35px;
  height: 35px;
  font-size: 0;
  top: 27px;
  right: 20px;
  padding: 0;
  background-image: url("../images/icon-close--white-bold.svg");
  background-size: 20px auto;
  background-repeat: no-repeat;
  background-position: 50%; }
  aside.modal-popup.filters .action-close:before {
    display: none; }
  aside.modal-popup.filters .action-close + .close-filter-modal {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); }

aside.modal-popup.filters .modal-inner-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100%;
  margin: 0;
  box-shadow: none;
  background-color: #1A2746; }

aside.modal-popup.filters .modal-header {
  padding: 3.125rem 2.1875rem 1.125rem;
  text-align: left; }

aside.modal-popup.filters .modal-footer {
  padding-left: 2.1875rem;
  padding-right: 2.1875rem; }

aside.modal-popup.filters .modal-header button:not(.action-close),
aside.modal-popup.filters .modal-footer button:not(.action-close) {
  background-color: #30A95E;
  color: #fff; }
  aside.modal-popup.filters .modal-header button:not(.action-close):hover, aside.modal-popup.filters .modal-header button:not(.action-close):focus,
  aside.modal-popup.filters .modal-footer button:not(.action-close):hover,
  aside.modal-popup.filters .modal-footer button:not(.action-close):focus {
    background-color: rgba(48, 169, 94, 0.75);
    color: #fff; }

aside.modal-popup.filters .modal-title,
aside.modal-popup.filters .sorter-title {
  font-size: 24px;
  font-weight: normal;
  font-family: "silka-semibold-webfont", sans-serif;
  color: white; }

aside.modal-popup.filters .modal-sub-title {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

aside.modal-popup.filters .nav-toolbar-sorter-mobile,
aside.modal-popup.filters .nav-layered-mobile {
  margin-left: -24px;
  margin-right: -24px; }
  aside.modal-popup.filters .nav-toolbar-sorter-mobile .action.clear,
  aside.modal-popup.filters .nav-layered-mobile .action.clear {
    background-color: #30A95E;
    color: #fff; }
    aside.modal-popup.filters .nav-toolbar-sorter-mobile .action.clear:hover, aside.modal-popup.filters .nav-toolbar-sorter-mobile .action.clear:focus,
    aside.modal-popup.filters .nav-layered-mobile .action.clear:hover,
    aside.modal-popup.filters .nav-layered-mobile .action.clear:focus {
      background-color: rgba(48, 169, 94, 0.75);
      color: #fff; }
  aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options,
  aside.modal-popup.filters .nav-layered-mobile .filter-options {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
    aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .tooltip,
    aside.modal-popup.filters .nav-layered-mobile .filter-options .tooltip {
      display: none; }
    aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-title,
    aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-title {
      outline: none;
      padding: 0.625rem 3.125rem 0.625rem 2.1875rem;
      font-size: 1.1875rem;
      line-height: 1.6;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      color: white;
      position: relative; }
      aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-title:first-child,
      aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-title:first-child {
        display: block; }
      aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-title:after,
      aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-title:after {
        content: '';
        display: block;
        width: 10px;
        height: 18px;
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%) rotate(90deg);
        background: url("../images/icon-arrow-next-light.svg") no-repeat center;
        background-size: 10px auto; }
    aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content,
    aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content {
      padding-left: 2.1875rem;
      padding-right: 2.1875rem;
      padding-bottom: 1.5625rem; }
      aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-ui-slider,
      aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-ui-slider {
        padding-bottom: 2.5rem; }
        aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-ui-slider .ui-slider,
        aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-ui-slider .ui-slider {
          border-color: white; }
          aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-ui-slider .ui-slider:after,
          aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-ui-slider .ui-slider:after {
            background: white; }
          aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-ui-slider .ui-slider .ui-slider-handle,
          aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-ui-slider .ui-slider .ui-slider-handle {
            background: #30A95E; }
        aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-ui-slider .min-value-container,
        aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-ui-slider .max-value-container,
        aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-ui-slider .min-value-container,
        aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-ui-slider .max-value-container {
          color: white; }
      aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ol,
      aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ul,
      aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ol,
      aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ul {
        border-top: none; }
        aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item,
        aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item,
        aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item,
        aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item {
          border-bottom: none; }
          aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a,
          aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a,
          aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a,
          aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a {
            font-size: 0.9375rem;
            color: white;
            position: relative;
            padding: 4px 0 4px 34px;
            background: none; }
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a:before,
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a:before,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a:before,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a:before {
              content: '';
              display: block;
              width: 21px;
              height: 21px;
              border-radius: 4px;
              background-color: white;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              box-shadow: none; }
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a:after,
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a:after,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a:after,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a:after {
              content: '';
              display: block;
              width: 21px;
              height: 21px;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              background: url("../images/icon-valid-white.svg") no-repeat center;
              background-size: 15px auto;
              opacity: 0; }
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a:hover:before, aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a:focus:before, aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a:active:before,
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a:hover:before,
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a:focus:before,
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a:active:before,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a:hover:before,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a:focus:before,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a:active:before,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a:hover:before,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a:focus:before,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a:active:before {
              background-color: #d52469; }
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a:hover:after, aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a:focus:after, aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a:active:after,
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a:hover:after,
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a:focus:after,
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a:active:after,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a:hover:after,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a:focus:after,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a:active:after,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a:hover:after,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a:focus:after,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a:active:after {
              opacity: 1; }
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a.amshopby-link-selected:before,
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a.amshopby-link-selected:before,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a.amshopby-link-selected:before,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a.amshopby-link-selected:before {
              background-color: #d52469; }
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a.amshopby-link-selected:after,
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a.amshopby-link-selected:after,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a.amshopby-link-selected:after,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a.amshopby-link-selected:after {
              opacity: 1; }
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a.amshopby-link-selected .label,
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a.amshopby-link-selected .label,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a.amshopby-link-selected .label,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a.amshopby-link-selected .label {
              padding-left: 0;
              background: none;
              font-weight: normal; }
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a .label,
            aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a .label,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ol .item a .label,
            aside.modal-popup.filters .nav-layered-mobile .filter-options .filter-content .filter-options-content.has-labels ul .item a .label {
              color: white; }
  aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .am-swatch-options .item.checked .am_shopby_swatch_label,
  aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .am-swatch-options .item.checked .am-shopby-swatch-label,
  aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .swatch-attribute-options .item.checked .am_shopby_swatch_label,
  aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .swatch-attribute-options .item.checked .am-shopby-swatch-label,
  aside.modal-popup.filters .nav-layered-mobile .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .am-swatch-options .item.checked .am_shopby_swatch_label,
  aside.modal-popup.filters .nav-layered-mobile .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .am-swatch-options .item.checked .am-shopby-swatch-label,
  aside.modal-popup.filters .nav-layered-mobile .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .swatch-attribute-options .item.checked .am_shopby_swatch_label,
  aside.modal-popup.filters .nav-layered-mobile .filter-options-content.has-images-labels .am_shopby_filter_items_attr_color .swatch-attribute-options .item.checked .am-shopby-swatch-label {
    background-color: #30A95E; }
  aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options-content.has-text-swatch .swatch-option:hover, aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options-content.has-text-swatch .swatch-option:active, aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options-content.has-text-swatch .swatch-option.selected, aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-options-content.has-text-swatch .swatch-option.checked,
  aside.modal-popup.filters .nav-layered-mobile .filter-options-content.has-text-swatch .swatch-option:hover,
  aside.modal-popup.filters .nav-layered-mobile .filter-options-content.has-text-swatch .swatch-option:active,
  aside.modal-popup.filters .nav-layered-mobile .filter-options-content.has-text-swatch .swatch-option.selected,
  aside.modal-popup.filters .nav-layered-mobile .filter-options-content.has-text-swatch .swatch-option.checked {
    background-color: #30A95E; }
  aside.modal-popup.filters .nav-toolbar-sorter-mobile .filter-actions,
  aside.modal-popup.filters .nav-layered-mobile .filter-actions {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem; }
  aside.modal-popup.filters .nav-toolbar-sorter-mobile .am-filter-items-attr_price,
  aside.modal-popup.filters .nav-layered-mobile .am-filter-items-attr_price {
    max-width: none; }

aside.modal-popup.filters .nav-toolbar-sorter-mobile ol,
aside.modal-popup.filters .nav-toolbar-sorter-mobile ul {
  border-top: 1px solid rgba(255, 255, 255, 0.3); }
  aside.modal-popup.filters .nav-toolbar-sorter-mobile ol.items .item,
  aside.modal-popup.filters .nav-toolbar-sorter-mobile ul.items .item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
    aside.modal-popup.filters .nav-toolbar-sorter-mobile ol.items .item:last-child,
    aside.modal-popup.filters .nav-toolbar-sorter-mobile ul.items .item:last-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
    aside.modal-popup.filters .nav-toolbar-sorter-mobile ol.items .item.active a,
    aside.modal-popup.filters .nav-toolbar-sorter-mobile ul.items .item.active a {
      background: none; }
      aside.modal-popup.filters .nav-toolbar-sorter-mobile ol.items .item.active a:after,
      aside.modal-popup.filters .nav-toolbar-sorter-mobile ul.items .item.active a:after {
        left: auto;
        right: 1.875rem; }
    aside.modal-popup.filters .nav-toolbar-sorter-mobile ol.items .item a,
    aside.modal-popup.filters .nav-toolbar-sorter-mobile ul.items .item a {
      background-image: url("../images/icon-arrow-next-light.svg");
      background-position: calc(100% - 30px) 50%;
      background-size: 10px auto; }

@media print, screen and (min-width: 40em) {
  aside.modal-popup.confirm .modal-inner-wrap {
    width: 55%; } }

@media screen and (min-width: 90.625em) {
  aside.modal-popup.confirm .modal-inner-wrap {
    width: 37.5%; } }

aside.modal-popup.new-customer-address .modal-inner-wrap {
  max-width: 800px; }
  aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    margin-right: -1.0625rem;
    margin-left: -1.0625rem; }
    aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset::before, aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset::after {
      display: table;
      content: ' '; }
    aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset::after {
      clear: both; }
    @media print, screen and (min-width: 40em) {
      aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem; }
      @media print, screen and (min-width: 40em) {
        aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field:last-child:not(:first-child) {
        float: right; }
      @media print, screen and (min-width: 40em) {
        aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field {
          width: 50%; } }
      aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field label.label, aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field .checkout-steps .checkout-box legend.label, .checkout-steps .checkout-box aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field legend.label, aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field .checkout-steps .form-order-comment legend.label, .checkout-steps .form-order-comment aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field legend.label,
      aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field .checkout-steps .new-address-form legend.label, .checkout-steps .new-address-form aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field legend.label, aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field .shipping-address .action, .shipping-address aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field .action, aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field .modal-add-adress .street legend.label, .modal-add-adress .street aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field legend.label {
        font-family: "silka-medium-webfont", sans-serif; }
      aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field input[type="text"],
      aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field .input-text,
      aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field select {
        height: 46px;
        padding: 3px 14px; }
      aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field .field-tooltip.toggle {
        display: none; }
      aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset > .field.choice {
        width: 100%;
        margin: 20px 0 0; }
    aside.modal-popup.new-customer-address .modal-inner-wrap .form-shipping-address .fieldset legend {
      display: none; }
  aside.modal-popup.new-customer-address .modal-inner-wrap .modal-footer {
    text-align: center; }
    aside.modal-popup.new-customer-address .modal-inner-wrap .modal-footer button.action {
      margin: 0 7px; }

.b-page-checkout .header .header-row .logo img {
  margin-top: -2.1875rem; }

.authentication-wrapper {
  position: relative;
  margin-bottom: 1.875rem;
  top: 0; }

input.input-text {
  background-color: white; }

.checkout-steps .checkout-box input.input-text {
  height: 46px;
  padding: 3px 14px;
  font-size: 0.875rem;
  background-color: white; }

.checkout-steps .checkout-box textarea,
.checkout-steps .checkout-box select {
  font-size: 0.875rem;
  background-color: white; }

.header-controls {
  flex-grow: 1; }

.checkout-onepage-success .block.newsletter {
  display: none; }

.account .page-subtitle-wrapper .page-subtitle {
  margin-bottom: 10px; }

.account .page-subtitle-wrapper span.label {
  vertical-align: baseline; }

.account .page-subtitle-wrapper .actions.quote {
  margin-bottom: 20px; }

.account .pager .toolbar-amount {
  width: 100%; }

.account .pager .pages {
  margin-top: 0; }
  .account .pager .pages .pages-items .item {
    margin: 0 5px 5px; }
    @media screen and (min-width: 64em) {
      .account .pager .pages .pages-items .item {
        margin: 0 5px 5px; } }
    @media screen and (min-width: 75em) {
      .account .pager .pages .pages-items .item {
        margin: 0 5px 5px; } }

.account .pager .limiter {
  width: 100%; }
  .account .pager .limiter .limiter-options {
    height: 40px; }

.account .actions.quote .action.secondary.cart2quote-reject-btn {
  width: auto;
  margin-bottom: 10px; }

.account .order-products-toolbar .convert-paging {
  width: 100%;
  float: left;
  padding-right: 1.0625rem;
  padding-left: 1.0625rem;
  margin-bottom: 20px; }
  @media print, screen and (min-width: 40em) {
    .account .order-products-toolbar .convert-paging {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .account .order-products-toolbar .convert-paging:last-child:not(:first-child) {
    float: right; }

.account .form-add-user .field-over {
  margin-left: 0;
  margin-right: 0; }

.order-details-items .order-title {
  display: none; }

.order-details-items .table-order-items .c2q-price {
  font-size: inherit; }

.order-details-items .table-order-items .price-including span,
.order-details-items .table-order-items .price-excluding span {
  display: inline;
  font-size: inherit;
  padding: 0; }

.order-details-items .table-order-items tfoot {
  border-top: 1px solid #ccc;
  font-size: 16px; }
  .order-details-items .table-order-items tfoot tr th,
  .order-details-items .table-order-items tfoot tr td {
    font-size: inherit; }
  .order-details-items .table-order-items tfoot tr.grand_total th,
  .order-details-items .table-order-items tfoot tr.grand_total td {
    padding-top: 10px;
    font-size: 18px;
    font-weight: bold; }

.order-details-items .items-qty {
  line-height: 1.4; }

.order-details-items .item-options {
  font-size: 13px;
  margin-top: 20px; }
  .order-details-items .item-options dt,
  .order-details-items .item-options dd {
    float: none; }
  .order-details-items .item-options dt {
    font-weight: bold;
    margin-bottom: 5px; }
  .order-details-items .item-options dd {
    margin-bottom: 20px; }

.order-details-items .print-price-cart-totals table tr th,
.order-details-items .print-price-cart-totals table tr td {
  padding-top: 10px;
  padding-bottom: 10px; }
  .order-details-items .print-price-cart-totals table tr th:first-child,
  .order-details-items .print-price-cart-totals table tr td:first-child {
    padding-left: 0; }
  .order-details-items .print-price-cart-totals table tr th:last-child,
  .order-details-items .print-price-cart-totals table tr td:last-child {
    padding-right: 0; }

.order-details-items .item-print-image {
  display: inline-block;
  margin-top: 10px; }
  .order-details-items .item-print-image img {
    max-height: 80px; }

.form-quote .reason_for_rejection textarea {
  border-radius: 0; }

.form-quote .return_to_quotation_view_btn {
  height: auto; }

.checkout-success {
  font-size: 16px; }
  @media print, screen and (min-width: 40em) {
    .checkout-success {
      font-size: 20px; } }

.dibs-easy-checkout-wrapper div.dibs-easy-checkout-review {
  margin-bottom: 40px; }
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items {
    max-width: 90.625rem;
    margin-right: auto;
    margin-left: auto;
    margin-right: -1.0625rem;
    margin-left: -1.0625rem; }
    .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items::before, .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items::after {
      display: table;
      content: ' '; }
    .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items::after {
      clear: both; }
    @media print, screen and (min-width: 40em) {
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 48em) {
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 64em) {
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 75em) {
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media screen and (min-width: 90.625em) {
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items .dibs-easy-checkout-items {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem; }
      @media print, screen and (min-width: 40em) {
        .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items .dibs-easy-checkout-items {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items .dibs-easy-checkout-items:last-child:not(:first-child) {
        float: right; }
      @media print, screen and (min-width: 48em) {
        .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items .dibs-easy-checkout-items {
          width: 50%; } }
      @media screen and (min-width: 64em) {
        .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items .dibs-easy-checkout-items {
          width: 65.83333%; } }
    .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items .dibs-easy-checkout-totals {
      width: 100%;
      float: left;
      padding-right: 1.0625rem;
      padding-left: 1.0625rem; }
      @media print, screen and (min-width: 40em) {
        .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items .dibs-easy-checkout-totals {
          padding-right: 1.25rem;
          padding-left: 1.25rem; } }
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items .dibs-easy-checkout-totals:last-child:not(:first-child) {
        float: right; }
      @media print, screen and (min-width: 48em) {
        .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items .dibs-easy-checkout-totals {
          width: 50%;
          float: right; } }
      @media screen and (min-width: 64em) {
        .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .table-wrapper.order-items .dibs-easy-checkout-totals {
          width: 34.16667%; } }
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .dibs-easy-checkout-shipping,
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .checkout-container,
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .dibs-easy-checkout-extra {
    max-width: 440px;
    margin: 0 auto 20px; }
    @media print, screen and (min-width: 48em) {
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .dibs-easy-checkout-shipping,
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .checkout-container,
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .dibs-easy-checkout-extra {
        margin: 0 auto 40px; } }
  @media screen and (max-width: 47.9375em) {
    .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review {
      margin-bottom: 20px; } }
  @media screen and (max-width: 63.9375em) {
    .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr {
      display: block;
      position: relative; }
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr::before, .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr::after {
        display: table;
        content: ' '; }
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr::after {
        clear: both; } }
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr:first-child th,
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr:first-child td {
    border-top: none; }
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info {
    position: relative; }
    @media screen and (max-width: 63.9375em) {
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info {
        padding-top: 15px;
        padding-bottom: 15px; } }
    .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info th, .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info td {
      padding-top: 40px;
      padding-bottom: 35px; }
      @media screen and (max-width: 63.9375em) {
        .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info th, .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info td {
          padding-top: 0;
          padding-bottom: 0; } }
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info th:first-child, .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info td:first-child {
        width: 130px;
        padding-left: 2.5rem;
        vertical-align: top; }
        @media screen and (max-width: 74.9375em) {
          .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info th:first-child, .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info td:first-child {
            padding-left: 20px; } }
        @media screen and (max-width: 63.9375em) {
          .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info th:first-child, .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info td:first-child {
            width: 90px; } }
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info th.qty .input-text, .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info td.qty .input-text {
        border: none;
        border-radius: 0;
        height: 30px;
        font-size: 0.9375rem;
        text-align: left;
        background-color: #f8f7f6; }
        .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info th.qty .input-text:focus, .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info th.qty .input-text[readonly], .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info td.qty .input-text:focus, .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info td.qty .input-text[readonly] {
          background-color: #f8f7f6; }
        @media screen and (min-width: 64em) {
          .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info th.qty .input-text, .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info td.qty .input-text {
            height: 50px;
            text-align: right; } }
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info th.qty .input-number-decrement,
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info th.qty .input-number-increment, .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info td.qty .input-number-decrement,
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info td.qty .input-number-increment {
        display: none; }
      .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info th.subtotal .price-including-tax, .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info td.subtotal .price-including-tax {
        font-family: "silka-semibold-webfont", sans-serif; }
      @media screen and (min-width: 64em) {
        .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info th.actions, .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review tr.item-info td.actions {
          padding-right: 40px; } }
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review .product-item-sku,
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review .product-item-supplier {
    color: rgba(24, 24, 24, 0.7); }
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review .remove-product {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 26px;
    opacity: .8;
    background: url("../images/icon-delete.svg") no-repeat;
    background-size: contain;
    cursor: pointer; }
    .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review .remove-product:active, .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review .remove-product:hover {
      opacity: 1; }
    .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review table.dibs-easy-checkout-review .remove-product span {
      display: none; }
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review input[type="text"],
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .input-text,
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review textarea,
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review select {
    font-size: 0.9375rem;
    background-color: white;
    border: 1px solid #9DA3AF; }
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review input[type="text"],
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review .input-text,
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review select {
    height: 46px;
    padding: 3px 14px; }
  .dibs-easy-checkout-wrapper div.dibs-easy-checkout-review button.action {
    width: 100%; }

.dibs-easy-checkout-wrapper .dibs-easy-checkout-cart .dibs-easy-checkout-title {
  display: none; }

.dibs-easy-checkout-wrapper .dibs-easy-checkout-review thead {
  display: none; }

.dibs-easy-checkout-wrapper .dibs-easy-checkout-shipping .dibs-easy-checkout-title .shipping-price {
  display: none; }

.dibs-easy-checkout-wrapper .dibs-easy-checkout-shipping .dibs-easy-checkout-section-shipping {
  margin-bottom: 20px; }

.dibs-easy-checkout-wrapper .dibs-easy-checkout-shipping .radio input[type=radio] + .label,
.dibs-easy-checkout-wrapper .dibs-easy-checkout-shipping .radio input[type=radio] + label {
  display: flex;
  justify-content: space-between; }

.dibs-easy-checkout-wrapper .dibs-easy-checkout-shipping button.action {
  font-size: 0.9375rem;
  background-color: #1A2746;
  color: white; }
  .dibs-easy-checkout-wrapper .dibs-easy-checkout-shipping button.action:hover, .dibs-easy-checkout-wrapper .dibs-easy-checkout-shipping button.action:focus {
    background-color: #283c6b;
    color: white; }

.dibs-easy-checkout-wrapper .dibs-easy-checkout-extra .dibs-easy-checkout-extra__right,
.dibs-easy-checkout-wrapper .dibs-easy-checkout-extra .discount {
  margin-bottom: 20px; }

.dibs-easy-checkout-wrapper .dibs-easy-checkout-extra .dibs-easy-checkout-extra__right button.action {
  font-size: 0.9375rem;
  background-color: #1A2746;
  color: white; }
  .dibs-easy-checkout-wrapper .dibs-easy-checkout-extra .dibs-easy-checkout-extra__right button.action:hover, .dibs-easy-checkout-wrapper .dibs-easy-checkout-extra .dibs-easy-checkout-extra__right button.action:focus {
    background-color: #283c6b;
    color: white; }

.dibs-easy-checkout-wrapper .dibs-easy-checkout-extra .grand-total {
  margin-bottom: 20px; }
  .dibs-easy-checkout-wrapper .dibs-easy-checkout-extra .grand-total h4 {
    display: flex; }
    .dibs-easy-checkout-wrapper .dibs-easy-checkout-extra .grand-total h4 .total-title {
      order: 0;
      margin-right: auto; }
    .dibs-easy-checkout-wrapper .dibs-easy-checkout-extra .grand-total h4 .total-currency {
      order: 1; }
    .dibs-easy-checkout-wrapper .dibs-easy-checkout-extra .grand-total h4 .total-amount {
      order: 2; }

.dibs-checkout-wrapper {
  max-width: 820px;
  margin: 0 auto; }

body.product-custom-request-form .algolia-instant-selector-results .page-title-wrapper {
  margin-top: 0;
  margin-bottom: 0; }

body.product-custom-request-form .page-main {
  background: none !important;
  width: auto !important;
  padding: 0 !important;
  margin-bottom: 2rem !important; }
  body.product-custom-request-form .page-main:before {
    display: none; }
  body.product-custom-request-form .page-main h1 {
    background: none;
    border-bottom: none;
    margin: 0;
    padding: 0 17px; }
    @media print, screen and (min-width: 40em) {
      body.product-custom-request-form .page-main h1 {
        padding: 0 20px; } }

body.product-custom-request-form .modal-popup h1 {
  margin: 0;
  background: none;
  padding: 0 0 5px; }

body.product-custom-request-form .product-info-wrapper .product-info-main {
  display: block;
  width: 100%; }
  @media screen and (min-width: 64em) {
    body.product-custom-request-form .product-info-wrapper .product-info-main {
      width: 50%; } }
  body.product-custom-request-form .product-info-wrapper .product-info-main .page-title-wrapper.product {
    display: block;
    margin: -30px -30px 0;
    padding: 0; }
    @media print, screen and (min-width: 48em) {
      body.product-custom-request-form .product-info-wrapper .product-info-main .page-title-wrapper.product {
        margin: -60px -60px 0; } }
    body.product-custom-request-form .product-info-wrapper .product-info-main .page-title-wrapper.product .page-title {
      background: #f9f9f9;
      border-bottom: 1px solid #e3e3e3;
      width: 100%;
      font-size: 1.625rem;
      padding: 30px;
      margin: 0;
      text-align: center; }
      @media print, screen and (min-width: 48em) {
        body.product-custom-request-form .product-info-wrapper .product-info-main .page-title-wrapper.product .page-title {
          font-size: 2.125rem;
          padding: 30px 60px; } }
  body.product-custom-request-form .product-info-wrapper .product-info-main .product-info-sidebar {
    padding-bottom: 0;
    margin-bottom: 0; }
    body.product-custom-request-form .product-info-wrapper .product-info-main .product-info-sidebar .product-description-wrapper {
      display: none; }
  body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form {
    display: block; }
    body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-wrapper .field label.label, body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-wrapper .field .checkout-steps .checkout-box legend.label, .checkout-steps .checkout-box body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-wrapper .field legend.label, body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-wrapper .field .checkout-steps .form-order-comment legend.label, .checkout-steps .form-order-comment body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-wrapper .field legend.label,
    body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-wrapper .field .checkout-steps .new-address-form legend.label, .checkout-steps .new-address-form body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-wrapper .field legend.label, body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-wrapper .field .shipping-address .action, .shipping-address body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-wrapper .field .action, body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-wrapper .field .modal-add-adress .street legend.label, .modal-add-adress .street body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-wrapper .field legend.label,
    body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-wrapper .field legend.legend {
      width: auto;
      float: none;
      padding: 0 !important;
      font-family: "silka-medium-webfont", sans-serif;
      font-size: 0.9375rem;
      font-weight: 400;
      line-height: 2.4;
      color: #1A2746; }
    body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-wrapper .field .control {
      width: auto;
      float: none;
      margin-top: 0; }
    body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form input,
    body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form select {
      height: 46px;
      font-size: 0.9375rem;
      background-color: white;
      border: 1px solid #9DA3AF;
      border-radius: 50px;
      padding: 3px 14px; }
      body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form input:focus,
      body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form select:focus {
        border: 1px solid #1A2746;
        box-shadow: none; }
    body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form select {
      padding-right: 50px; }
    body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-requirements-container {
      font-size: 0.9375rem;
      margin-bottom: 1rem; }
      body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-requirements-container span {
        text-decoration: underline;
        cursor: pointer;
        color: #1A2746;
        margin-bottom: 0.375rem;
        display: block; }
        body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-requirements-container span:hover, body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-requirements-container span:focus {
          text-decoration: none; }
    body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-uploader-button {
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 1rem 0;
      padding: 0.82em 1em 0.87em;
      -webkit-appearance: none;
      border: 1px solid transparent;
      border-radius: 50px;
      transition: background-color 0.25s ease-out, color 0.25s ease-out;
      font-size: 1.1875rem;
      line-height: 1;
      text-align: center;
      cursor: pointer;
      background-color: #30A95E;
      color: #fff;
      font-family: "silka-semibold-webfont", sans-serif;
      letter-spacing: normal;
      font-weight: 400;
      text-transform: none;
      margin-bottom: 0;
      display: block;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      background-color: #1A2746;
      color: white;
      color: #fff;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem; }
      [data-whatinput='mouse'] body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-uploader-button {
        outline: 0; }
      body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-uploader-button:hover, body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-uploader-button:focus {
        background-color: rgba(48, 169, 94, 0.75);
        color: #fff; }
      body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-uploader-button:active {
        color: rgba(255, 255, 255, 0.8) !important; }
      body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-uploader-button:hover, body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-uploader-button:focus {
        background-color: #283c6b;
        color: white; }
    body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-uploader-summary {
      margin-top: 20px; }
      body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-uploader-summary .preview-image {
        text-decoration: none; }
      body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-uploader-summary .action-remove {
        display: inline-block;
        vertical-align: middle;
        width: 24px;
        height: 26px;
        opacity: .8;
        background: url("../images/icon-delete.svg") no-repeat;
        background-size: contain;
        cursor: pointer; }
        body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-uploader-summary .action-remove span {
          position: absolute !important;
          width: 1px;
          height: 1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0); }
        body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-uploader-summary .action-remove:hover, body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .file-uploader-summary .action-remove:active {
          opacity: 1; }
    @media print, screen and (min-width: 40em) {
      body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-bottom {
        margin: 0 -62px; } }
    @media print, screen and (min-width: 48em) {
      body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-bottom {
        margin: 0 -60px; } }
    body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-bottom .box-tocart {
      padding: 0; }
    body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-bottom .actions {
      text-align: center; }
      body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-bottom .actions #product-addtoquote-button {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 1rem 0;
        padding: 0.82em 1em 0.87em;
        -webkit-appearance: none;
        border: 1px solid transparent;
        border-radius: 50px;
        transition: background-color 0.25s ease-out, color 0.25s ease-out;
        font-size: 1.1875rem;
        line-height: 1;
        text-align: center;
        cursor: pointer;
        background-color: #30A95E;
        color: #fff;
        font-family: "silka-semibold-webfont", sans-serif;
        letter-spacing: normal;
        font-weight: 400;
        text-transform: none;
        margin-bottom: 0;
        width: auto;
        margin: 0 auto; }
        [data-whatinput='mouse'] body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-bottom .actions #product-addtoquote-button {
          outline: 0; }
        body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-bottom .actions #product-addtoquote-button:hover, body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-bottom .actions #product-addtoquote-button:focus {
          background-color: rgba(48, 169, 94, 0.75);
          color: #fff; }
        body.product-custom-request-form .product-info-wrapper .product-info-main .product-add-form .product-options-bottom .actions #product-addtoquote-button:active {
          color: rgba(255, 255, 255, 0.8) !important; }

.reveal-overlay .add-popup-message:after {
  border-width: 0 14px 16px;
  right: 8px; }
  body.is-sticky .reveal-overlay .add-popup-message:after {
    right: 8px; }

.manage-company-accounts .block-dashboard-company .block-title {
  display: none; }

.manage-company-accounts .block-dashboard-company .box-actions {
  margin-bottom: 20px; }
  .manage-company-accounts .block-dashboard-company .box-actions .action:after {
    display: none; }
  .manage-company-accounts .block-dashboard-company .box-actions .action:hover {
    color: white; }

.manage-company-accounts .block-dashboard-company .jstree li a {
  height: 36px;
  line-height: 30px;
  padding-top: 3px;
  padding-bottom: 3px; }

.manage-company-accounts .block-dashboard-company .jstree .jstree-clicked {
  background-color: #30A95E; }

.company-users-index .data-grid-filters-wrap {
  margin-bottom: 20px; }

.quotes-grid-toolbar .limiter {
  margin-bottom: 20px; }
  .quotes-grid-toolbar .limiter .control {
    display: inline-block;
    width: 150px; }
    .quotes-grid-toolbar .limiter .control select {
      margin-bottom: 0; }
  .quotes-grid-toolbar .limiter .limiter-text {
    display: inline-block;
    font-size: inherit; }

.customer-address-form .form-address-edit .field.fax, .customer-address-form .form-address-edit .field.region {
  display: none; }

.magnifier-preview img {
  object-fit: contain; }

.mm-slideout .category-all {
  display: none; }

.sales-order-view table tr.print-price-calculations td, .sales-order-view table tr.print-price-calculations th {
  border-top: unset;
  border-bottom: unset; }

.sales-order-view table tr:first-child td,
.sales-order-view table tr:first-child th {
  border-top: 1px solid #cccccc;
  border-bottom-style: dashed; }

.sales-order-view table tr td,
.sales-order-view table tr th {
  border-top-style: dashed;
  border-bottom-style: dashed; }

.form-edit-account .fieldset.password,
.form-address-edit .fieldset.password {
  clear: both; }

.form-edit-account .fieldset .control,
.form-address-edit .fieldset .control {
  font-size: 17px; }

.ais-Pagination-list,
.convert-paging .pages-items {
  display: block;
  font-size: 0; }
  .ais-Pagination-list .ais-Pagination-item,
  .ais-Pagination-list .item,
  .convert-paging .pages-items .ais-Pagination-item,
  .convert-paging .pages-items .item {
    display: inline-block; }
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage, .ais-Pagination-list .ais-Pagination-item.pages-item-next, .ais-Pagination-list .ais-Pagination-item.pages-item-previous,
    .ais-Pagination-list .item.ais-Pagination-item__previous,
    .ais-Pagination-list .item.ais-Pagination-item__next,
    .ais-Pagination-list .item.ais-Pagination-item--previousPage,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage,
    .ais-Pagination-list .item.pages-item-next,
    .ais-Pagination-list .item.pages-item-previous,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-previous,
    .convert-paging .pages-items .item.ais-Pagination-item__previous,
    .convert-paging .pages-items .item.ais-Pagination-item__next,
    .convert-paging .pages-items .item.ais-Pagination-item--previousPage,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage,
    .convert-paging .pages-items .item.pages-item-next,
    .convert-paging .pages-items .item.pages-item-previous {
      position: absolute;
      bottom: 0;
      left: 0; }
      @media screen and (min-width: 64em) {
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage, .ais-Pagination-list .ais-Pagination-item.pages-item-next, .ais-Pagination-list .ais-Pagination-item.pages-item-previous,
        .ais-Pagination-list .item.ais-Pagination-item__previous,
        .ais-Pagination-list .item.ais-Pagination-item__next,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage,
        .ais-Pagination-list .item.pages-item-next,
        .ais-Pagination-list .item.pages-item-previous,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous,
        .convert-paging .pages-items .item.ais-Pagination-item__previous,
        .convert-paging .pages-items .item.ais-Pagination-item__next,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage,
        .convert-paging .pages-items .item.pages-item-next,
        .convert-paging .pages-items .item.pages-item-previous {
          bottom: auto;
          top: 0; } }
      @media screen and (max-width: 63.9375em) {
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage .action, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage .action, .ais-Pagination-list .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-next .action, .ais-Pagination-list .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .link,
        .ais-Pagination-list .ais-Pagination-item.pages-item-previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__previous .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .link,
        .ais-Pagination-list .item.ais-Pagination-item__previous .action,
        .ais-Pagination-list .item.ais-Pagination-item__next .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item__next .link,
        .ais-Pagination-list .item.ais-Pagination-item__next .action,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--previousPage .action,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .link,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage .action,
        .ais-Pagination-list .item.pages-item-next .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-next .link,
        .ais-Pagination-list .item.pages-item-next .action,
        .ais-Pagination-list .item.pages-item-previous .ais-Pagination-link,
        .ais-Pagination-list .item.pages-item-previous .link,
        .ais-Pagination-list .item.pages-item-previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next .action,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .link,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .link,
        .convert-paging .pages-items .item.ais-Pagination-item__previous .action,
        .convert-paging .pages-items .item.ais-Pagination-item__next .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .link,
        .convert-paging .pages-items .item.ais-Pagination-item__next .action,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--previousPage .action,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .ais-Pagination-link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .link,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage .action,
        .convert-paging .pages-items .item.pages-item-next .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-next .link,
        .convert-paging .pages-items .item.pages-item-next .action,
        .convert-paging .pages-items .item.pages-item-previous .ais-Pagination-link,
        .convert-paging .pages-items .item.pages-item-previous .link,
        .convert-paging .pages-items .item.pages-item-previous .action {
          font-size: 14px;
          padding-top: 1.1em;
          padding-bottom: 1em; } }
    .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage, .ais-Pagination-list .ais-Pagination-item.pages-item-next,
    .ais-Pagination-list .item.ais-Pagination-item__next,
    .ais-Pagination-list .item.ais-Pagination-item--nextPage,
    .ais-Pagination-list .item.pages-item-next,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next,
    .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage,
    .convert-paging .pages-items .ais-Pagination-item.pages-item-next,
    .convert-paging .pages-items .item.ais-Pagination-item__next,
    .convert-paging .pages-items .item.ais-Pagination-item--nextPage,
    .convert-paging .pages-items .item.pages-item-next {
      left: auto;
      right: 0; }
      @media screen and (max-width: 47.9375em) {
        .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__next, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--nextPage, .ais-Pagination-list .ais-Pagination-item.pages-item-next,
        .ais-Pagination-list .item.ais-Pagination-item__next,
        .ais-Pagination-list .item.ais-Pagination-item--nextPage,
        .ais-Pagination-list .item.pages-item-next,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__next,
        .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--nextPage,
        .convert-paging .pages-items .ais-Pagination-item.pages-item-next,
        .convert-paging .pages-items .item.ais-Pagination-item__next,
        .convert-paging .pages-items .item.ais-Pagination-item--nextPage,
        .convert-paging .pages-items .item.pages-item-next {
          margin: 0 5px 5px !important; } }
    @media screen and (max-width: 47.9375em) {
      .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item__previous, .ais-Pagination-list .ais-Pagination-item.ais-Pagination-item--previousPage, .ais-Pagination-list .ais-Pagination-item.pages-item-previous,
      .ais-Pagination-list .item.ais-Pagination-item__previous,
      .ais-Pagination-list .item.ais-Pagination-item--previousPage,
      .ais-Pagination-list .item.pages-item-previous,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item__previous,
      .convert-paging .pages-items .ais-Pagination-item.ais-Pagination-item--previousPage,
      .convert-paging .pages-items .ais-Pagination-item.pages-item-previous,
      .convert-paging .pages-items .item.ais-Pagination-item__previous,
      .convert-paging .pages-items .item.ais-Pagination-item--previousPage,
      .convert-paging .pages-items .item.pages-item-previous {
        margin: 0 5px 5px !important; } }

.quote-action-toolbar {
  text-align: right; }
  .quote-action-toolbar a.action.back:before {
    display: none; }
  .quote-action-toolbar a.action {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    padding: 0.82em 1em 0.87em;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 50px;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 1.1875rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: #30A95E;
    color: #fff;
    font-family: "silka-semibold-webfont", sans-serif; }
    [data-whatinput='mouse'] .quote-action-toolbar a.action {
      outline: 0; }
    .quote-action-toolbar a.action:hover, .quote-action-toolbar a.action:focus {
      background-color: rgba(48, 169, 94, 0.75);
      color: #fff; }
    .quote-action-toolbar a.action:hover span, .quote-action-toolbar a.action:focus span {
      text-decoration: none; }

.quotation-quote-index #trigger-file-requirements-modal-popup {
  text-decoration: underline;
  cursor: pointer;
  color: #1A2746;
  margin-bottom: 0.5rem;
  display: block; }

.quotation-quote-index .file-upload-container ul.uploaded-files {
  padding: 10px 0 20px 0; }

body.product-custom-request-form .product-add-form .product-options-wrapper #custom-request-form-custom-options .field:first-of-type {
  margin-top: 0; }

body.product-custom-request-form .product-add-form .product-options-wrapper #custom-request-form-custom-options ul {
  margin-left: 0;
  list-style: none; }
  body.product-custom-request-form .product-add-form .product-options-wrapper #custom-request-form-custom-options ul span.add-file-button {
    text-decoration: underline;
    cursor: pointer;
    color: #1A2746;
    font-size: 0.9375rem;
    font-family: "silka-medium-webfont", sans-serif; }

body.product-custom-request-form .product-add-form .product-options-wrapper #custom-request-form-custom-options .hidden-file-upload {
  display: none; }

.checkout-steps .field.field-country_id:has(div > select > option:only-child) {
  display: none !important; }

.cms-page-view .brand-list {
  display: flex;
  flex-wrap: wrap; }

.cms-page-view .brand-content .brand-item {
  padding: 4%; }

.cms-page-view .brand-title-container {
  text-align: center;
  margin-top: 0.625rem; }

.amshopby-option-link {
  margin-left: 20px;
  margin-top: 5px; }
  .amshopby-option-link .brand-title {
    font-size: 1.25rem;
    margin-bottom: 1.25rem; }
  .amshopby-option-link > a {
    float: left;
    margin-top: -14px; }

.product-items .product-item .product-price-stock:has(.amshopby-option-link) {
  display: flex;
  flex-direction: column;
  margin-right: 80px; }

.brand-image {
  margin-bottom: 2rem; }
  .brand-image > img {
    display: block;
    margin: auto; }
