$ts-algolia--version: 2 !default;

%swatch-option-algolia-color {
    .swatch-option {
        width: $ts-color-swatch-width;
        height: $ts-color-swatch-height;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        text-align: center;
        background-color: #fff;
        cursor: pointer;
        border-radius: $ts-color-swatch-radius;

        @if $ts-color-swatch-border {
            box-shadow: inset 0 0 0 1px $ts-color-swatch-border-color;
        }

        &:hover,
        &:focus {
            &:before {
                .touchevents & {
                    display: block;
                }
            }
        }

        &:hover,
        &:focus,
        &.selected,
        &.checked {
            &:after {
                display: block;
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: 100%;
                height: 100%;
                background: url("#{$ts-path-to-images}/#{$ts-color-swatch-active-image}.#{$ts-color-swatch-active-image-type}") 50% no-repeat;
                background-size: $ts-color-swatch-active-image-width auto;
            }
        }

        .product-items & {
            width: $ts-color-swatch-product-list-width;
            height: $ts-color-swatch-product-list-height;
            border-radius: $ts-color-swatch-product-list-radius;
            vertical-align: top;
            margin: $ts-color-swatch-product-list-indent;
            margin-left: 0;

            &.selected {
                height: $ts-color-swatch-product-list-height;
                box-shadow: $ts-color-swatch-product-list-selected-shadow;

                &:after {
                    display: none;
                }
            }
        }

        &.disabled {
            opacity: .25;
        }

        &[option-label="White"],
        &[option-label="Hvit"] {
            box-shadow: inset 0 0 0 1px rgba($black,.1);
        }
    }// .swatch-option
}// %swatch-option-algolia-color

@if $ts-algolia--version == 2 {
    .ais-root {
        position: relative;

         a {
            text-decoration: none;
        }

        .extra-title {
            font-size: rem-calc(22);
            font-weight: 400;
        }

        &.slider {
            margin: 0;
            height: auto;
            cursor: default;
            background: transparent;
        }

        &.ais-current-refined-values,
        &.ais-refinement-list,
        &.ais-range-slider,
        &.ais-hierarchical-menu {

            .ais-header,
            .ais-body {
                padding: $ts-sidebar-main-padding;
                background: $ts-sidebar-main-background-color;
            }

            .ais-header {
                display: block;
                @include header-font-family($ts-sidebar-main-filter-title-font-family);
                @include clearfix;
                font-size: $ts-sidebar-main-filter-title-font-size;
                color: $ts-sidebar-main-filter-title-font-color;
                font-weight: $ts-sidebar-main-filter-title-font-weight;
                margin-bottom: 0;
                line-height: 1.2;
                padding: $ts-sidebar-main-padding;
                background: $ts-sidebar-main-background-color;
                padding-bottom: $ts-sidebar-main-filter-title-indent;
                padding-left: $ts-sidebar-main-filter-title-indent-left;
                @if ( $ts-sidebar-main-filter-title-text-transform != none ) {
                    text-transform: $ts-sidebar-main-filter-title-text-transform;
                }
            }// .ais-header

            .ais-body {
                padding-top: 0;
                margin-bottom: $ts-sidebar-main-filter-content-indent;

                &.ais-range-slider--body {
                    padding-top: .75rem;
                    padding-bottom: 3.5rem;
                }
            }// .ais-body
        }

        &.ais-current-refined-values {
            .ais-header {
                padding-right: 5rem;
            }
        }// .ais-current-refined-values

        .searchbox {
            margin: 1rem 0;

            .sbx-sffv__wrapper {
                position: relative;
            }

            .sbx-sffv__submit,
            .sbx-sffv__reset {
                position: absolute;
                top: 1px;
                bottom: 1px;
                right: auto;
                width: ($ts-sidebar-search-input-indent - $ts-sidebar-search-input-icon-right-indent);
                right: ($ts-sidebar-search-input-icon-right-indent/2);
                padding: $ts-sidebar-search-input-icon-padding-indent;
                @extend .action.search;
                background-position: 50%;
                background-color: $ts-sidebar-search-input-background-color;
                background-size: 80% auto;
                height: auto;
                z-index: 2;

                svg {
                    display: none;
                }
            }

            .sbx-sffv__reset {
                @extend .clear-cross;
                background-size: 60% auto;
                z-index: 1;
            }

            .sbx-sffv__input {
                height: $ts-sidebar-search-input-height;
                padding-right: $ts-sidebar-search-input-indent;
                padding-left: $ts-sidebar-search-input-left-indent;
                margin-bottom: 0;
                font-family: $ts-sidebar-search-font-family;
                font-size: $ts-sidebar-search-font-size;
                font-weight: $ts-sidebar-search-font-weight;
                color: $ts-sidebar-search-input-color;
                text-transform: $ts-sidebar-search-input-text-transform;
                background-color: $ts-sidebar-search-input-background-color;
                background-position: $ts-sidebar-search-input-icon-bg-position;
                transition: all .3s ease;
                @include placeholder($ts-sidebar-search-input-color);

                &.sbx-sffv__input-disabled {
                    opacity: .5;
                    border: $ts-sidebar-search-input-border !important;

                    + .sbx-sffv__submit {
                        opacity: .5;

                        + .sbx-sffv__reset {
                            display: none;
                        }
                    }
                }

                @if $ts-sidebar-search-input-focus-one-line-border {
                    border-bottom-color: $ts-sidebar-search-input-border-bottom-color;
                } @else {
                    border: $ts-sidebar-search-input-border;
                }

                &:hover,
                &:active,
                &:focus,
                &:focus:not([value=""]) {
                    @if $ts-sidebar-search-input-focus-one-line-border {
                        border-bottom-color: $ts-sidebar-search-input-focus-border-bottom-color;
                    } @else {
                        border-color: $ts-sidebar-search-input-focus-border-color;
                    }
                }
                // &:focus:not([value=""]),
                // &.focus {
                //     + .sbx-sffv__submit {
                //         z-index: 0;
                //     }
                // }

                &::-ms-clear {
                    display: none;
                }
            } // .algolia-search-input
        }
    } // .ais-root

    .ais-current-refined-values--clear-all {
        font-family: $ts-sidebar-main-filter-clear-font-family;
        font-size: $ts-sidebar-main-filter-clear-font-size;
        font-weight: $ts-sidebar-main-filter-clear-font-weight;
        color: $ts-sidebar-main-filter-clear-font-color;
        border-bottom: $ts-sidebar-main-filter-clear-border;
        line-height: 1;
        margin: $ts-sidebar-main-padding;
        margin-bottom: 0;
        position: absolute;
        top: .5rem;
        right: 0;
        text-decoration: none;

        &:hover,
        &:focus {
            border-bottom: 0;
            padding-bottom: 1px;
        }
    } // .clear-filter

    .ais-refinement-list--list,
    .ais-current-refined-values--list,
    .ais-price-ranges--list,
    .ais-hierarchical-menu--list {
        font-size: $ts-sidebar-main-filter-font-size;
        border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

        .ais-refinement-list--item,
        .ais-current-refined-values--item,
        .ais-price-ranges--item,
        .ais-hierarchical-menu--item {
            position: relative;
            border-bottom: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

            &:last-child {
                border-bottom: 0;
            }

            .ais-refinement-list--label,
            .current-refinement-wrapper,
            .ais-price-ranges--link,
            .ais-hierarchical-menu--link,
            .swatch-option.text {
                display: block;
                text-transform: $ts-sidebar-main-filter-text-transform;
                font-family: $ts-sidebar-main-filter-font-family;
                font-size: $ts-sidebar-main-filter-font-size;
                font-weight: $ts-sidebar-main-filter-font-weight;
                color: $ts-sidebar-main-filter-anchor-color;
                line-height: 1.7;
                padding: $ts-sidebar-main-filter-anchor-padding;
                cursor: pointer;
                position: relative;
                // text-decoration: $ts-sidebar-main-filter-anchor-underline;

                &:hover {
                    color: $ts-sidebar-main-filter-anchor-color-hover;
                    background-color: $ts-sidebar-main-filter-anchor-bg-hover;
                    // text-decoration: $ts-sidebar-main-filter-anchor-underline-hover;
                }
            } // .ais-refinement-list--label

            &.ais-refinement-list--item__active {
                .ais-refinement-list--label,
                .current-refinement-wrapper,
                .ais-price-ranges--link,
                .ais-hierarchical-menu--link {
                    font-weight: bolder;

                    &:after {
                        display: block;
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: $ts-mobile-filter-image-position;
                        transform: translateY(-50%);
                        @if ( $ts-mobile-filter-image != false ) {
                            width: $ts-mobile-filter-image-width;
                            height: $ts-mobile-filter-image-height;
                            background: url("#{$ts-path-to-images}/#{$ts-mobile-filter-image}.#{$ts-mobile-filter-image-type}") no-repeat;
                            background-size: 100% auto;
                        }
                    }
                } // .ais-refinement-list--label
            }

            .cross-wrapper {
                position: absolute;
                top: 50%;
                right: .5rem;
                transform: translateY(-50%);

                .clear-refinement {
                    margin-right: 0;
                }

                .txt {
                    display: inline-block;
                    margin-right: 2px;
                    font-family: $ts-sidebar-main-filter-clear-font-family;
                    font-size: $ts-sidebar-main-filter-clear-font-size;
                    font-weight: $ts-sidebar-main-filter-clear-font-weight;
                    color: $ts-sidebar-main-filter-clear-font-color;
                    border-bottom: $ts-sidebar-main-filter-clear-border;
                    line-height: 1;
                }
            }

            .current-refinement-wrapper {
                padding-right: 4rem;
                position: relative;

                &:before {
                    position: absolute;
                    top: 50%;
                    left: .2rem;
                    transform: translateY(-50%);
                    content: '';
                    width: .75rem;
                    height: .75rem;
                    opacity: .5;
                    background: url("#{$ts-path-to-images}/#{$ts-mobile-filter-image}.#{$ts-mobile-filter-image-type}") no-repeat 0 50%;
                    background-size: 100% auto;
                }

                .current-refinement-label {
                    margin-left: 1rem;
                }
            }

            .ais-refinement-list--count,
            .ais-hierarchical-menu--count {
                color: $ts-sidebar-main-filter-count-color;
                font-size: $ts-sidebar-main-filter-count-font-size;

                &:before {
                    content: '(';
                }

                &:after {
                    content: ')';
                }
            } // .ais-refinement-list--count

            .ais-hierarchical-menu--count {
                position: relative;
                margin-left: .25rem;
            }

            .current-refinement-label {
                font-weight: 700;
            } // .current-refinement-label

            input[type="checkbox"],
            input[type="radio"] {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
            } // input

        } // .ais-refinement-list--item

        .ais-current-refined-values--item {
            a {
                display: block;
            }

            &:hover,
            &:focus {
                cursor: pointer;

                .current-refinement-wrapper {
                    text-decoration: line-through;
                }
            }
        }
    } // .ais-refinement-list--list


    @for $i from 1 through 10 {
        .ais-hierarchical-menu--list__lvl#{$i} {
            .ais-hierarchical-menu--item {
                padding-left: $ts-sidebar-main-filter-subcategory-left-indent;
            }
        }
    }

    .has-text-swatch {

        .ais-refinement-list--list,
        .ais-current-refined-values--list,
        .ais-price-ranges--list,
        .ais-hierarchical-menu--list {
            padding-top: $ts-size-swatch-category-top-indent;
            padding-bottom: $ts-size-swatch-category-bottom-indent;

            @if $ts-size-swatch-category-top-border {
                border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;
            } @else {
                border-top: 0;
            }

            .ais-refinement-list--item,
            .ais-current-refined-values--item,
            .ais-price-ranges--item,
            .ais-hierarchical-menu--item {
                display: inline-block;
                vertical-align: top;
                min-width: $ts-size-swatch-width;
                margin: $ts-size-swatch-outer-indent;
                cursor: pointer;
                font-size: 0;
                border:0;

                &[href="javascript:void();"] {
                    display: none;
                }

                &.ais-refinement-list--item__active {
                    .ais-refinement-list--label,
                    .current-refinement-wrapper,
                    .ais-price-ranges--link,
                    .ais-hierarchical-menu--link,
                    .swatch-option.text {
                        color: #fff;
                        background-color: $primary-color;
                    }
                }

                .ais-refinement-list--label,
                .current-refinement-wrapper,
                .ais-price-ranges--link,
                .ais-hierarchical-menu--link,
                .swatch-option.text  {
                    width: auto;
                    min-width: $ts-size-swatch-width;
                    height: $ts-size-swatch-height;
                    padding: $ts-size-swatch-text-inner-indent;
                    line-height: $ts-size-swatch-height;
                    position: relative;
                    text-align: center;
                    cursor: pointer;
                    font-size: $ts-size-swatch-font-size;
                    font-weight: $ts-size-swatch-font-weight;
                    font-family: $ts-size-swatch-font-family;
                    box-shadow: $ts-size-swatch-box-shadow;
                    background-color: $ts-size-swatch-bg-color;
                    cursor: pointer;
                    letter-spacing: $ts-size-swatch-letter-spacing;
                    word-spacing: $ts-size-swatch-word-spacing;

                    &:before,
                    &:after {
                        display: none;
                    }

                    &.disabled {
                        &:before,
                        &:after {
                            display: none;
                        }
                    }

                    &:hover,
                    &:focus {
                        color: #fff;
                        background-color: $primary-color;
                    }

                    .ais-refinement-list--count {
                        @include at;
                    }
                }
            }
        }

    } // .has-text-swatch

    .has-images {
        .ais-refinement-list--list,
        .ais-current-refined-values--list,
        .ais-price-ranges--list,
        .ais-hierarchical-menu--list {
            padding-top: $ts-color-swatch-category-top-indent;
            padding-bottom: $ts-color-swatch-category-bottom-indent;

            @if $ts-color-swatch-category-top-border {
                border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;
            } @else {
                border-top: 0;
            }

            .ais-refinement-list--item,
            .ais-current-refined-values--item,
            .ais-price-ranges--item,
            .ais-hierarchical-menu--item {
                display: inline-block;
                vertical-align: top;
                border-radius: $ts-color-swatch-radius;
                margin: $ts-color-swatch-outer-indent;
                padding: $ts-color-swatch-inner-indent;
                position: relative;
                font-size: 0;
                margin-right: 3px;
                border:0;

                &.checked,
                &.ais-refinement-list--item__active {
                    &:after {
                        display: block;
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate3d(-50%, -50%, 0);
                        width: 100%;
                        height: 100%;
                        background: url("#{$ts-path-to-images}/#{$ts-color-swatch-active-image}.#{$ts-color-swatch-active-image-type}") 50% no-repeat;
                        background-size: $ts-color-swatch-active-image-width auto;
                    }
                }

                @extend %swatch-option-algolia-color;
            }
        }
    } // .is-widget-container-color

    .has-images-labels {
        .ais-refinement-list--list,
        .ais-current-refined-values--list,
        .ais-price-ranges--list,
        .ais-hierarchical-menu--list {
            font-size: $ts-sidebar-main-filter-font-size;
            border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

            .ais-refinement-list--item {
                display: block;
                position: relative;
                text-transform: $ts-sidebar-main-filter-text-transform;
                font-family: $ts-sidebar-main-filter-font-family;
                font-size: ($ts-sidebar-main-filter-font-size*0.87);
                font-weight: $ts-sidebar-main-filter-font-weight;
                color: $ts-sidebar-main-filter-anchor-color;
                padding: $ts-sidebar-main-filter-anchor-padding;
                cursor: pointer;
                border-bottom: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

                @include breakpoint(xlarge) {
                    font-size: $ts-sidebar-main-filter-font-size;
                }

                &:last-child {
                    border-bottom: 0;
                }

                @extend %swatch-option-algolia-color;

                .swatch-option {
                    width: $ts-color-label-swatch-width;
                    height: $ts-color-label-swatch-height;
                }
                .label {
                    cursor: inherit;
                    text-decoration: $ts-sidebar-main-filter-anchor-underline;
                    margin-left: .25rem;
                    @include no-label;
                }

                &:hover {
                    color: $ts-sidebar-main-filter-anchor-color-hover;
                    background-color: $ts-sidebar-main-filter-anchor-bg-hover;
                    text-decoration: none;

                    .label {
                        text-decoration: $ts-sidebar-main-filter-anchor-underline-hover;
                    }
                }

                .count {
                    display: inline-block;
                    vertical-align: middle;
                    color: $ts-sidebar-main-filter-count-color;
                    font-size: $ts-sidebar-main-filter-count-font-size;

                    &:before {
                        content: '(';
                    }

                    &:after {
                        content: ')';
                    }

                    .filter-count-label {
                        @include at;
                    }
                } // .count
            } // .item
        }

    } // .is-widget-container-color

    .ais-price-ranges--form {
        margin-top: 2rem;
        padding-top: 1rem;
        border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

        .ais-price-ranges--label {
            display: table-cell;
            width: 40%;
            line-height: 1;
            position: relative;

            .ais-price-ranges--currency {
                position: absolute;
                top: 3px;
                left: 3px;
                text-transform: uppercase;
            }
        }
        .ais-price-ranges--separator {
            display: table-cell;
            width: 20%;
            vertical-align: middle;
            text-align: center;
        }

        .ais-price-ranges--button {
            @include button-extended;
            @include button-expand;
            @include button-style($secondary-color, $button-background-secondary-hover);
        }
    } // .ais-price-ranges--form


    .ais-range-slider--target {
        position: relative;
        margin-right: 17px;
        font-size: $ts-sidebar-main-price-slider-font-size;
        font-weight: $ts-sidebar-main-price-slider-font-weight;
        color: $ts-sidebar-main-price-slider-color;

        &:after {
            display: block;
            content:'';
            position: absolute;
            width: $ts-sidebar-main-price-slider-point-width - 3px;
            left: 100%;
            top: 50%;
            height: $ts-sidebar-main-price-slider-line-width;
            margin-top: (-1*$ts-sidebar-main-price-slider-line-width);
            background: $ts-sidebar-main-price-slider-line-color;
        }

        .ais-range-slider--base {
            width: 100%;
            text-align: left;
            position: relative;
            margin: $ts-sidebar-main-price-slider-margin;
            border-top: $ts-sidebar-main-price-slider-line-width $ts-sidebar-main-price-slider-line-type $ts-sidebar-main-price-slider-line-color;
            z-index: 1;
        }

        .ais-range-slider--origin {
            position: absolute;
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
        }

        .ais-range-slider--handle {
            position: absolute;
            z-index: 2;
            width: $ts-sidebar-main-price-slider-point-width;
            height: $ts-sidebar-main-price-slider-point-height;
            border-radius: 50%;
            background: $ts-sidebar-main-price-slider-point-bg;
            border-radius: 100%;
            border: $ts-sidebar-main-price-slider-point-border;
            top: -($ts-sidebar-main-price-slider-point-height/2);
            margin-left: -1px;
            margin-top: 0;
            cursor: pointer;
            -ms-touch-action: none;
            touch-action: none;

            &:focus {
                outline: none;
            }
        }

        .ais-range-slider--connect {
            background: lighten(black,62.7%);
        }

        .ais-range-slider--marker {
            display: none;
        }

        .ais-range-slider--active {
            .ais-range-slider--tooltip {
                opacity: 1;
            }
        }

        .ais-range-slider--tooltip {
            opacity: 0;
            min-width: rem-calc(30);
            position: absolute;
            color: $white;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: rem-calc(5);
            padding: rem-calc(2 5);
            font-size: rem-calc(11);
            line-height: 1.2;
            text-align: center;
            white-space: nowrap;
            background: $secondary-color;
            transition: opacity .3s ease;

            &:after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 6px 0 6px;
                border-color: $secondary-color transparent transparent transparent;
                position: absolute;
                left: 50%;
                top:100%;
                transform: translateX(-50%);
            }
        }

        .ais-range-slider--pips {
            position: relative;
            margin-right: 17px;

            .ais-range-slider--value {
                display: none;
                position: absolute;
                padding-top: 0.1875rem;
                text-align: center;

                &:nth-child(2),
                &:last-child {
                    display: block;
                }
            }
        }
    } // .ais-range-slider--target

    .rheostat {
        position: relative;
        margin-right: 17px;
        font-size: $ts-sidebar-main-price-slider-font-size;
        font-weight: $ts-sidebar-main-price-slider-font-weight;
        color: $ts-sidebar-main-price-slider-color;

        &:after {
            display: block;
            content:'';
            position: absolute;
            width: $ts-sidebar-main-price-slider-point-width - 3px;
            left: 100%;
            top: 0;
            height: $ts-sidebar-main-price-slider-line-width;
            background: $ts-sidebar-main-price-slider-line-color;
        }

        .rheostat-background {
            width: 100%;
            text-align: left;
            position: relative;
            margin: $ts-sidebar-main-price-slider-margin;
            border-top: $ts-sidebar-main-price-slider-line-width $ts-sidebar-main-price-slider-line-type $ts-sidebar-main-price-slider-line-color;
            z-index: 1;
        }

        .rheostat-progress {

        }

        .ais-range-slider--marker {
            margin-left: 0 !important;
            position: absolute;
            padding-top: 0.1875rem;
            text-align: center;

            .ais-range-slider--value {
                display: none;
            }
        }

        .ais-range-slider--marker.ais-range-slider--marker-large {
            &:last-child {
                left: auto !important;
                right: -1*($ts-sidebar-main-price-slider-point-width - 3px);
                .ais-range-slider--value {
                    display: block;
                }
            }
        }

        .rheostat-progress {
            + .ais-range-slider--marker.ais-range-slider--marker-large {
                .ais-range-slider--value {
                    display: block;
                }
            }
        }

        .ais-range-slider--handle {
            position: absolute;
            z-index: 2;
            width: $ts-sidebar-main-price-slider-point-width;
            height: $ts-sidebar-main-price-slider-point-height;
            border-radius: 50%;
            background: $ts-sidebar-main-price-slider-point-bg;
            border-radius: 100%;
            border: $ts-sidebar-main-price-slider-point-border;
            top: -($ts-sidebar-main-price-slider-point-height/2);
            margin-left: -1px;
            margin-top: 0;
            cursor: pointer;
            -ms-touch-action: none;
            touch-action: none;

            &:focus,
            &:active,
            &:hover {
                outline: none;

                .ais-range-slider--tooltip {
                    opacity: 1;
                }
            }

            .ais-range-slider--tooltip {
                opacity: 0;
                min-width: rem-calc(30);
                position: absolute;
                color: $white;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
                margin-bottom: rem-calc(5);
                padding: rem-calc(2 5);
                font-size: rem-calc(11);
                line-height: 1.2;
                text-align: center;
                white-space: nowrap;
                background: $secondary-color;
                transition: opacity .3s ease;

                &:after {
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 6px 0 6px;
                    border-color: $secondary-color transparent transparent transparent;
                    position: absolute;
                    left: 50%;
                    top:100%;
                    transform: translateX(-50%);
                }
            }
        }
    } // .rheostat


    .pages-grid {
        .ais-hits {
            margin-bottom: 1rem;

            @include breakpoint(large) {
                margin-bottom: 1.5rem;
            }

            .extra-title {
                font-size: rem-calc(22);
                font-weight: 400;
            }
        }

        .ais-hits--body {
            @include grid-row;
            @include grid-row-nest;
        }

        .ais-hits--item {
            @include grid-col;
            @include grid-col-end;
            margin-bottom: 1rem;

            @include breakpoint(large) {
                @include grid-col-size(6);
                margin-bottom: 1.5rem;
            }

            &:nth-child(odd) {
                clear: left;
            }
        }

        .ais-hits--more-items {
            display: inline-block;
            color: $anchor-color;
            cursor: pointer;

            .text {
                text-decoration: underline;
            }

            .count {
                color: $body-font-color;
            }

            &:hover {
                .text {
                    text-decoration: none;
                }
            }
        }

        .algoliasearch-page-hit {
            display: block;
            @include header-font-family($header-font-family);
            font-weight: 700;
            font-size: rem-calc(18);
            margin-bottom: .25rem;

            em {
                font-style: normal;
            }
        }
    }// .pages-grid

    .column.main {
        @if ($ts-loadmore-btn-view == 'circle') {
            .ais-infinite-hits--showmore {
                color: $anchor-color;
                font-size: rem-calc(16);
                @include header-font-family($ts-loadmore-btn-font-family);
                margin: 1rem auto;
                position: relative;
                line-height: 1;
                text-align: center;
                text-transform: uppercase;
                width: $ts-loadmore-btn-width;
                height: $ts-loadmore-btn-width;
                clear: both;

                button {
                    display: table-cell;
                    text-decoration: none;
                    height: $ts-loadmore-btn-height;
                    line-height: 1.1;
                    vertical-align: middle;
                    width: $ts-loadmore-btn-width;
                }

                &:before {
                    border: $ts-loadmore-btn-border-size $ts-loadmore-btn-border-style $anchor-color;
                    border-radius: 100%;
                    content: "";
                    height: rem-calc(120);
                    left: 0;
                    position: absolute;
                    width: $ts-loadmore-btn-width;
                }

                &:after {
                    $ts-loadmore-btn-spinner-origin-x : ($ts-loadmore-btn-width - $ts-loadmore-btn-spinner-size)/2;

                    display: block;
                    content: "";
                    position: absolute;
                    margin: 0 auto;
                    width: $ts-loadmore-btn-spinner-size;
                    height: $ts-loadmore-btn-spinner-size;
                    background-color: white;
                    -webkit-animation: spin-loading-more 1s linear infinite;
                    animation: spin-loading-more 1s linear infinite;
                    -webkit-transform-origin: -$ts-loadmore-btn-spinner-origin-x $ts-loadmore-btn-spinner-size/2;
                    -ms-transform-origin: -$ts-loadmore-btn-spinner-origin-x $ts-loadmore-btn-spinner-size/2;
                    transform-origin: -$ts-loadmore-btn-spinner-origin-x $ts-loadmore-btn-spinner-size/2;
                    border-radius: 50%;
                    top:  $ts-loadmore-btn-spinner-origin-x;
                    left: $ts-loadmore-btn-width - $ts-loadmore-btn-spinner-size/2;
                }

                &:hover {
                    color: rgba(black,0.9);
                    cursor: pointer;

                    &:before {
                        border-color: rgba(black,0.9);
                    }
                }
            }// .ais-infinite-hits--showmore
        } @else {
            .ais-infinite-hits--showmore {

                button {
                    @include button-extended;
                    @include button-style($primary-color);
                    @include button-hollow;
                    @include button-hollow-style-extend($primary-color, $primary-color);
                    display: block;
                    width: 100%;
                    max-width: rem-calc(250);
                    margin-left: auto;
                    margin-right: auto;
                    clear: both;
                    color: $body-font-color;
                    cursor: pointer;

                    &:hover,
                    &:focus {
                        color: $white;
                        border-color: $primary-color;
                        box-shadow: inset 0 0 0 1px $primary-color;
                        background: $primary-color;
                    }

                    &[disabled] {
                        display: none;
                        opacity: .25;
                        cursor: default;

                        &:hover,
                        &:focus {
                            color: $body-font-color;
                            border: 1px solid $primary-color;
                            box-shadow: inset 0 0 0 1px $primary-color;
                            background: transparent;
                        }
                    }
                }


            }// .ais-infinite-hits--showmore
        }
    }// algolia show-more spinner

}  @elseif $ts-algolia--version > 2 {

    .ais-RefinementList-searchBox {
        display: none;
    }

    .ais-RefinementList-list,
    .ais-CurrentRefinements-list,
    .ais-HierarchicalMenu-list {
        font-size: $ts-sidebar-main-filter-font-size;
        border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;

        .ais-RefinementList-item,
        .ais-CurrentRefinements-item,
        .ais-HierarchicalMenu-item {
            &:after {
                display: none;
            }
        }

        .ais-CurrentRefinements-item,
        .ais-HierarchicalMenu-item {
            position: relative;
            border-bottom: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;
            padding: 0;

            &:last-child {
                border-bottom: 0;
            }
        }
    }
} // @if
