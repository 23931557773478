.convert-divider {
	max-width: inherit;
}

.convert-teaser {
	overflow: hidden;
	background: $ts-pimcore-teaser-bg;
	text-align: center;
	margin-bottom: 2rem;

	.convert-teaser-content {
		padding: map-get($ts-pimcore-teaser-padding, small);
		position: relative;
	}

	.convert-title {
		@include header-font-family($ts-pimcore-teaser-title-font-family);
		font-weight: $ts-pimcore-teaser-title-font-weight;
		font-size: map-get($ts-pimcore-teaser-title-font-size, small);
		color: $ts-pimcore-teaser-title-color;
		line-height: 1;
		margin-bottom: .5rem;
	}

	.convert-subtitle {
		@include header-font-family($ts-pimcore-teaser-subtitle-font-family);
		font-weight: $ts-pimcore-teaser-subtitle-font-weight;
		font-size: map-get($ts-pimcore-teaser-subtitle-font-size, small);
		color: $ts-pimcore-teaser-subtitle-color;
		line-height: 1.2;
		margin-bottom: 0;
	}

	.convert-teaser-image {
		text-align: center;
	}

	.convert-wysiwyg {
		margin-bottom: 1.7rem;
		p {
			font-size: rem-calc(13);
			line-height: 1.6;
			text-align: left;

			&:last-child {
				margin-bottom: 0;
			}

			&.text-small {
				font-size: rem-calc(11);
			}

			&.text-normal {
				font-size: rem-calc(13);
			}

			&.text-large {
				font-size: rem-calc(15);
			}

			&.text-xlarge {
				font-size: rem-calc(17);
			}

			&.text-xxlarge {
				font-size: rem-calc(19);

				@include breakpoint(medium down) {
					font-size: rem-calc(17);
				}
			}
		}
	}

	hr {
		margin: 1.1rem 0;
	}

	p {
		text-align: left;
	}

	a.link {
		text-transform: uppercase;
		font-family: $header-font-family;
		text-transform: uppercase;
		text-decoration: underline;
		margin-top: 1.5rem;
		display: inline-block;

		&:hover {
			text-decoration: none;
		}
	}

	&.rounded {
		.convert-teaser-image {
			img {
				width: 81px;
				height: 81px;
				border-radius: 100%;
				max-width: none;
				transform: translateX(-50%);
				position: relative;
				left: 50%;
			}
		}
	}

	&.small {
		.convert-teaser-image,
		.convert-teaser-content {
			display: table-cell !important;
		}

		.convert-teaser-content {
			text-align: left !important;
		}

		.convert-title {
			font-size: rem-calc(15);
		}

		.subheader {
			margin-bottom: 0;
		}

		a.link {
			margin-top: 0;
		}
	}

	.button {
		min-width: rem-calc(200);
		margin-bottom: 0;

		@include breakpoint(small down) {
			min-width: inherit;
			width: 100%;
		}
	}

	// ------- MEDIUM SIZES -------
	@media screen and #{breakpoint(medium)} and #{breakpoint(xlarge down)} {

		.medium-3 > &,
		.medium-2 > & {
			.convert-teaser-image {
				position: relative;
				overflow: hidden;
			}

			.convert-teaser-content {
				padding: map-get($ts-pimcore-teaser-padding, medium);
				position: relative;
			}

			.convert-title {
				font-size: map-get($ts-pimcore-teaser-title-font-size, medium);
			}

			.convert-subtitle {
				font-size: map-get($ts-pimcore-teaser-subtitle-font-size, medium);
			}

			.convert-wysiwyg {
				margin-bottom: 1rem;
			}

			hr {
				margin: .75rem 0;
			}

			.button {
				min-width: inherit;
				width: 100%;
			}
		}// .medium-3 .medium-2

		.medium-4 > &  {
			.convert-teaser-image {
				position: relative;
				overflow: hidden;
			}

			.convert-teaser-content {
				padding: map-get($ts-pimcore-teaser-padding, medium-4);
				position: relative;
			}

			.button {
				min-width: inherit;
				width: 100%;
			}
		}// .medium-4

		.medium-8 > &,
		.medium-9 > &,
		.medium-12 > & {
			text-align: left;
			overflow: hidden;
			display: flex;
			flex-direction: row;

			.convert-teaser-image {
				float: left;
				width: 25%;
				overflow: hidden;
				position: relative;

				img {
					max-width: inherit;
					min-height: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
				}
			}
			.convert-teaser-content {
				float: left;
				width: 75%;
				padding: map-get($ts-pimcore-teaser-padding, medium-12);
			}

			.button {
				position: absolute;
				top: map-get($ts-pimcore-teaser-button-position-top, medium-12);
				right: map-get($ts-pimcore-teaser-button-position-right, medium-12);
				min-width: inherit;
				width: rem-calc(130);
			}

			.convert-title,
			.convert-subtitle {
				margin-right: rem-calc(140);
			}

			hr {
				margin: .95rem 0;
			}
		}// .medium-8 .medium-9

	}// breakpoint(medium)


	// ------- LARGE SIZES -------
	@include breakpoint(xlarge) {

		.xlarge-3 > &,
		.xlarge-2 > & {
			.convert-teaser-image {
				position: relative;
				overflow: hidden;
			}

			.convert-teaser-content {
				padding: map-get($ts-pimcore-teaser-padding, xlarge);
				position: relative;
			}

			.convert-title {
				font-size: map-get($ts-pimcore-teaser-title-font-size, xlarge);
			}

			.convert-subtitle {
				font-size: map-get($ts-pimcore-teaser-subtitle-font-size, xlarge);
			}

			.convert-wysiwyg {
				margin-bottom: 1rem;
			}

			hr {
				margin: .75rem 0;
			}

			.button {
				min-width: inherit;
				width: 100%;
			}
		}// .xlarge-3 .xlarge-2

		.xlarge-4 > &  {
			.convert-teaser-image {
				position: relative;
				overflow: hidden;
			}

			.convert-teaser-content {
				padding: map-get($ts-pimcore-teaser-padding, xlarge);
				position: relative;
			}

			.button {
				min-width: inherit;
				width: 100%;
			}
		}// .xlarge-4

		.xlarge-6 > & {
			text-align: left;

			.convert-teaser-content {
				padding: map-get($ts-pimcore-teaser-padding, xlarge);
			}

			.button {
				position: absolute;
				top: map-get($ts-pimcore-teaser-button-position-top, xlarge);
				right: map-get($ts-pimcore-teaser-button-position-right, xlarge);
				max-width: rem-calc(250);
			}

			.convert-title,
			.convert-subtitle {
				margin-right: rem-calc(280);
			}

			hr {
				margin: .95rem 0;
			}
		}// .xlarge-6

		.xlarge-8 > &,
		.xlarge-9 > &,
		.xlarge-12 > & {
			text-align: left;
			overflow: hidden;
			display: flex;
			flex-direction: row;

			.convert-teaser-image {
				float: left;
				width: 32%;
				overflow: hidden;
				position: relative;

				img {
					max-width: inherit;
					min-height: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
				}
			}
			.convert-teaser-content {
				float: left;
				width: 68%;
				padding: map-get($ts-pimcore-teaser-padding, xlarge);
			}

			.button {
				position: absolute;
				top: map-get($ts-pimcore-teaser-button-position-top, xlarge);
				right: map-get($ts-pimcore-teaser-button-position-right, xlarge);
				width: rem-calc(150);
				min-width: inherit;
			}

			.convert-title,
			.convert-subtitle {
				margin-right: rem-calc(180);
			}
			hr {
				margin: .95rem 0;
			}
		}// .xlarge-8 .xlarge-9

	}// breakpoint(xlarge)


	// ------- XLARGE SIZES -------
	@include breakpoint(xxlarge) {

		.xxlarge-3 > &,
		.xxlarge-2 > & {
			.convert-teaser-image {
				position: relative;
				overflow: hidden;
			}

			.convert-teaser-content {
				padding: map-get($ts-pimcore-teaser-padding, xxlarge);
				position: relative;
			}

			.convert-title {
				font-size: map-get($ts-pimcore-teaser-title-font-size, xxlarge);
			}

			.convert-subtitle {
				font-size: map-get($ts-pimcore-teaser-subtitle-font-size, xxlarge);
			}

			.convert-wysiwyg {
				margin-bottom: 1rem;
			}

			hr {
				margin: .75rem 0;
			}

			.button {
				min-width: inherit;
				width: 100%;
			}
		}// .xxlarge-3 .xxlarge-2

		.xxlarge-4 > &  {
			.convert-teaser-image {
				position: relative;
				overflow: hidden;
			}

			.convert-teaser-content {
				padding: map-get($ts-pimcore-teaser-padding, xxlarge);
				position: relative;
			}

			.button {
				min-width: inherit;
				width: 100%;
			}
		}// .xlarge-4

		.xxlarge-6 > & {
			text-align: left;
			.convert-teaser-content {
				padding: map-get($ts-pimcore-teaser-padding, xlarge);
			}

			.button {
				position: absolute;
				top: map-get($ts-pimcore-teaser-button-position-top, xlarge);
				right: map-get($ts-pimcore-teaser-button-position-right, xlarge);
				max-width: rem-calc(250);
			}

			.convert-title,
			.convert-subtitle {
				margin-right: rem-calc(280);
			}

			hr {
				margin: .95rem 0;
			}
		}// .xlarge-6

		.xxlarge-8 > &,
		.xxlarge-9 > &,
		.xxlarge-12 > & {
			text-align: left;
			overflow: hidden;
			display: flex;
			flex-direction: row;

			.convert-teaser-image {
				float: left;
				width: 32%;
				overflow: hidden;
				position: relative;

				img {
					max-width: inherit;
					min-height: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
				}
			}
			.convert-teaser-content {
				width: 68%;
				float: left;
				padding: map-get($ts-pimcore-teaser-padding, xxlarge);
			}

			.convert-title,
			.convert-subtitle {
				margin-right: rem-calc(230);
			}

			.button {
				position: absolute;
				top: map-get($ts-pimcore-teaser-button-position-top, xxlarge);
				right: map-get($ts-pimcore-teaser-button-position-right, xxlarge);
				min-width: inherit;
				width: rem-calc(200);
			}
			hr {
				margin: .95rem 0;
			}
		}// .xxlarge-8 .xxlarge-9

	}// breakpoint(xxlarge)

}// .convert-teaser


.convert-responsive-image {
	margin-bottom: 1rem;

	+ .convert-wysiwyg {
		margin-bottom: 2rem;

		.convert-title {
			font-family: $ts-pimcore-content-title-font-family;
			font-weight: $ts-pimcore-content-title-font-weight;
			font-size: map-get($ts-pimcore-content-title-font-size, small);
			line-height: 1;
			margin-bottom: .8rem;
		}
	}

	@include breakpoint(medium) {
		margin-bottom: 1.4rem;

		+ .convert-wysiwyg {
			margin-bottom: 4rem;

			.convert-title {
				font-size: map-get($ts-pimcore-content-title-font-size, medium);
				margin-bottom: 1.25rem;
			}
		}
	}

}// .convert-responsive-image


.googlemap {
	height: $ts-pimcore-map-height;
	overflow: hidden;
}//.googlemap


// Pimcore WYSIWYG
.convert-wysiwyg {
	.intro,
	.tagline {
		@include header-font-family($ts-pimcore-teaser-title-font-family);
		letter-spacing: normal;
		font-weight: 400;
		font-size: rem-calc(17);
		line-height: 1.35;
		@include breakpoint(large) {
			font-size: rem-calc(19);
			line-height: 1.47;
		}
		@include breakpoint(xxlarge) {
			font-size: rem-calc(20);
		}
	}

	p {
		font-size: $ts-pimcore-wysiwyg-font-size-normal;
		line-height: 1.6;
		text-align: left;

		&:last-child {
			margin-bottom: 0;
		}

		&.text-small {
			font-size: $ts-pimcore-wysiwyg-font-size-small;
		}

		&.text-normal {
			font-size: $ts-pimcore-wysiwyg-font-size-normal;
		}

		&.text-large {
			font-size: $ts-pimcore-wysiwyg-font-size-large;
		}

		&.text-xlarge {
			font-size: $ts-pimcore-wysiwyg-font-size-xlarge;
		}

		&.text-xxlarge {
			font-size: $ts-pimcore-wysiwyg-font-size-xxlarge;

			@include breakpoint(medium down) {
				font-size: $ts-pimcore-wysiwyg-font-size-xlarge;
			}
		}
	}

	// TEMP
	&.video-full {
		height: 500px;
		overflow: hidden;
		position: relative;

		iframe {
			width: 100%;
			height: 56.25vw !important;
			position: absolute;
			top: 50%;
			display: block;
			transform: translateY(-50%);
		}
	}

}// .convert-wysiwyg


// Pimcore Articles
.convert-article {

	&.convert-block:not(:empty) {
		margin-bottom: 2rem;
	}
	.convert-article-image {
		margin-bottom: 1rem;
	}// .convert-article-image
	.convert-article-content {
		> a {
			color: $body-font-color;
			text-decoration: none;

			&:hover,
			&:focus {
				color: $primary-color;
			}
		}
	}
}// .convert-article

.convert-articles {
	.convert-article {
		@include grid-col-end();
		&.xlarge-4 {
			&:nth-child(3n+1) {
				@include breakpoint(xlarge) {
					clear: both;
				}
			}
		}
	}
}// .convert-articles


h1 {
	&.convert-title {
		@include breakpoint(xxlarge) {
			font-size: (map-deep-get($header-styles, xlarge, h1, font-size) * 1px) + 10px;
		}

		@each $size, $headers in $header-styles {
			@include breakpoint($size) {
				@each $header, $header-defs in $headers {
			  		$font-size-temp: 1rem;

				&.as-#{$header} {
						@if map-has-key($header-defs, font-size) {
							$font-size-temp: rem-calc(map-get($header-defs, font-size));
					font-size: $font-size-temp;

						} @else if map-has-key($header-defs, fs) {
							$font-size-temp: rem-calc(map-get($header-defs, fs));
					font-size: $font-size-temp;

						} @else if $size == $-zf-zero-breakpoint {
					font-size: $font-size-temp;
						}
				} // .as-h#
				} // @each
			} // @media
		} // @each
	} // .convert-title
} // h1

body:not(.cms-page-view) {
	.navbar-wrapper {
		.page-header {
			margin-bottom: $ts-shipping-bar-top-indent;
			@include breakpoint(large) {
				margin-bottom: 1.5rem;
			}
			@include breakpoint(xxlarge) {
				margin-bottom: 3rem;
			}
		}
	}
}// body:not(.cms-page-view)

.tablet-full-width {
	@include breakpoint(large down) {
		.convert-column {
			width: 100%;
		}
		.convert-responsive-image {
			text-align: center;
			padding-bottom: (map-get($grid-column-gutter, medium)*1.5);
		}
	}
}// .tablet-full-width


// Pimcore Sidebar Menu
nav.convert-document-menu:not(.simple-doc-menu) {
	ul {
		@include lsn;
		margin-top: 1rem;
		margin-bottom: 1rem;

		@include breakpoint(xlarge) {
			text-align: right;
			margin-top: 0;
		}
	}

	li {
		+ li {
			border-top: 1px solid $ts-main-dark-border-color;
		}

		&:after {
			display: none;
		}
	}

	a {
		display: block;
		font-size: 1rem;
		font-family: $body-font-family;
		color: $body-font-color;
		padding: .45rem .5rem .4rem;
		text-decoration: none;

		&:hover,
		&:focus,
		&:active {
			background-color: $box-color;
		}
	}
}// nav.convert-document-menu



.convert-snippet-inline-buttons {
	.convert-block {
		margin-bottom: 0;
	}
	> .column {
		> .convert-block {
			margin-bottom: 1rem;
		}
	}
	.convert-snippet {
		.convert-snippet {
			display: inline-block;
			vertical-align: top;
		}
	}
}

// Pimcore Marker
.convert-image-metadata {
	position: relative;

	img {
		width: 100%;
		height: auto;
	}

	.convert-image-marker,
	.convert-image-hotspot {
		position: absolute;

		a {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	.convert-image-marker {
		position: absolute;
		width: rem-calc(24);
		height: rem-calc(24);
		background: url("#{$ts-path-to-images}/icon-marker.svg") no-repeat;
		background-size: 100% 100%;
		border-radius: 100%;
		box-shadow: 2px 2px 7px rgba($black,.1);
	}
}// .convert-image-metadata

.convert-image-hotspot {
	position: absolute;

	a:hover {
		background: rgba($white,.15);
	}
}// .convert-image-hotspot
.convert-image-marker,
.convert-image-hotspot {
	a {
		width: 100%;
		height: 100%;
		border-bottom: none;
		cursor: pointer;
	}
}

// SPOR-138
.convert-block {
	.block-products-list,
	.block-new-products {
		margin-top: 0;
		margin-bottom: 0;
	}
}


.convert-button {
	&.has-subtext {
		> * {
			white-space: nowrap;
		}

		h4,
		p {
			margin: 0;
		}
	}
} // .convert-button

.convert-accordion {
	@include lsn;

	.accordion-title {
		font-weight: $ts-pimcore-accordion-title__font-weight;
		text-decoration: none;

		@if $accordion-plusminus and $ts-pimcore-accordion__toggler == 'default' {
			&::before {
				font-size: 22px;
				right: 1.1rem;
				margin-top: -0.5em;
			}
		} // @if

		@if $accordion-plusminus and $ts-pimcore-accordion__toggler == 'custom' {
			padding-left: 45px;

			&::before {
				right: auto;
				left: 21px;
				content: "";
				width: 16px;
				height: 16px;
				background-image: url("#{$ts-path-to-images}/icon-arrow-down-thin.svg");
				background-size: contain;
				background-position: 0 50%;
				background-repeat: no-repeat;
				transform: rotate(-90deg);
			}
		}
	}


	.is-active {
		.accordion-title {
			@if $ts-pimcore-accordion-title__background--active {
				background-color: $ts-pimcore-accordion-title__background--active;
			}

			@if $accordion-plusminus and $ts-pimcore-accordion__toggler == 'custom' {
				&::before {
					content: "";
					left: 17px;
					transform: rotate(0);
				}
			}
		}

		@if $ts-pimcore-accordion-title__background--active {
			.accordion-content {
				border-top-color: $ts-pimcore-accordion-title__background--active;
			}
		}
	}

	@if $accordion-content-border != 'none' and  $ts-pimcore-accordion__border == 'lines' {
		.accordion-title,
		.accordion-content {
			border-left: 0 none;
			border-right: 0 none;
		}
	} // @if

	.accordion-content {
		@if $accordion-plusminus and $ts-pimcore-accordion__toggler == 'custom' {
			padding-left: 45px;
		}
	}

	.convert-wysiwyg {
		p {
			line-height: 1.7;
		}
	}

	.accordion-item:not(.is-active) {
		.accordion-content {
			border-top-color: transparent;
		}
	}
}// convert-accordion
