@font-face {
    font-family: 'silka-regular-webfont';
    src: url('../fonts/silka-regular-webfont.eot');
    src: url('../fonts/silka-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/silka-regular-webfont.woff2') format('woff2'),
         url('../fonts/silka-regular-webfont.woff') format('woff'),
         url('../fonts/silka-regular-webfont.ttf') format('truetype'),
         url('../fonts/silka-regular-webfont.svg#silka-regular-webfont') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'silka-regularitalic-webfont';
    src: url('../fonts/silka-regularitalic-webfont.eot');
    src: url('../fonts/silka-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/silka-regularitalic-webfont.woff2') format('woff2'),
         url('../fonts/silka-regularitalic-webfont.woff') format('woff'),
         url('../fonts/silka-regularitalic-webfont.ttf') format('truetype'),
         url('../fonts/silka-regularitalic-webfont.svg#silka-regularitalic-webfont') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'silka-medium-webfont';
    src: url('../fonts/silka-medium-webfont.eot');
    src: url('../fonts/silka-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/silka-medium-webfont.woff2') format('woff2'),
         url('../fonts/silka-medium-webfont.woff') format('woff'),
         url('../fonts/silka-medium-webfont.ttf') format('truetype'),
         url('../fonts/silka-medium-webfont.svg#silka-medium-webfont') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'silka-semibold-webfont';
    src: url('../fonts/silka-semibold-webfont.eot');
    src: url('../fonts/silka-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/silka-semibold-webfont.woff2') format('woff2'),
         url('../fonts/silka-semibold-webfont.woff') format('woff'),
         url('../fonts/silka-semibold-webfont.ttf') format('truetype'),
         url('../fonts/silka-semibold-webfont.svg#silka-semibold-webfont') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}