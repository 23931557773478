// Algolia Instant Search v3 custom styles
#clear-refinements {
    position: relative;
    top: auto;
    right: auto;
}
.ais-ClearRefinements {
    &-button {
        width: 100%;
        background-color: $primary-color !important;
        color: $button-color !important;
        text-decoration: none;
        font-size: map-get($button-sizes, default) !important;
        margin-bottom: 1.15rem !important;

        @if (type-of($button-padding) == 'map') {
            @each $size, $padding in $button-padding {
                @include breakpoint($size) {
                    padding: $padding !important;
                }
            }
        }
        @else {
            padding: $button-padding !important;
        }

        &:hover, &:focus {
            background-color: $primary-color !important;
            color: $button-color !important;
        }

        &--disabled {
            display: none !important;
        }
    }
}
.ais-CurrentRefinements {
    margin-bottom: 0;
    border-top: none;

    .ais-CurrentRefinements-list {
        border-top: none;
    }
    .ais-CurrentRefinements-item {
        padding: $ts-sidebar-main-filter-anchor-padding !important;

        & + .ais-CurrentRefinements-item {
            border-top: none !important;
        }

        &:hover,
        &:focus {
            .ais-CurrentRefinements-label,
            .ais-CurrentRefinements-category {
                text-decoration: line-through;
            }
        }

        .ais-CurrentRefinements-label {
            display: inline-block;
            margin-right: 0;
            padding-left: 0 !important;

            &:before {
                display: none;
            }
        }
        .ais-CurrentRefinements-category {
            display: inline-block;
            padding-left: 0;
        }
        .ais-CurrentRefinements-delete {
            @extend .action.action-delete;
            padding: 0;
            font-size: 0;
            background-size: $ts-delete-icon-width auto;
            background-position: center right;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.#{$ts-algolia-list--selector},
.#{$ts-algolia-infinite-list--selector} {
    padding-top: 0;
}

.ais-HierarchicalMenu-list {
    .ais-HierarchicalMenu-link--selected {
        .cross-circle {
            display: none;
        }
    }
}

.is-widget-container-categories {
    .ais-Panel {
        .ais-Panel-header {
            display: none;
        }
    }
    .ais-HierarchicalMenu-list {
        .ais-HierarchicalMenu-item {

            &:last-child {
                border-bottom: 1px solid rgba($black,0.15);
            }

            .ais-HierarchicalMenu-link  {
                font-size: rem-calc(15);
                padding: $ts-sidebar-main-filter-anchor-padding;
                background: url("#{$ts-path-to-images}/#{$ts-sidebar-mobile-category-image}.#{$ts-sidebar-mobile-category-image-type}") $ts-sidebar-mobile-category-image-position no-repeat;
                background-size: $ts-sidebar-mobile-category-image-size;

                @include breakpoint(large) {
                    font-size: rem-calc(17);
                }
                @include breakpoint(xlarge) {
                    font-size: $ts-sidebar-main-filter-font-size;
                }

                &:before,
                &:after {
                    display: none !important;
                }
                &:hover,
                &:focus {
                    text-decoration: underline;
                }

                .ais-HierarchicalMenu-count {
                    display: none;
                }
            }

            &.ais-HierarchicalMenu-item--selected {
                .ais-HierarchicalMenu-link {
                    background: url("#{$ts-path-to-images}/icon-valid-black.svg") $ts-sidebar-mobile-category-image-position no-repeat;
                    background-size: 15px auto;
                    padding-left: 0;
                    font-weight: bold;
                }
            }
        }

        .ais-HierarchicalMenu-list--child {
            .ais-HierarchicalMenu-item {
                border-bottom: none;

                .ais-HierarchicalMenu-link {
                    font-size: rem-calc(14);

                    @include breakpoint(large) {
                        font-size: rem-calc(15);
                    }
                    @include breakpoint(xlarge) {
                        font-size: rem-calc(16);
                    }
                }
            }
        }
    }
}

.ais-RangeSlider {
    padding-top: .75rem;

    .rheostat {
        &:after {
            display: none;
        }
    }
    .rheostat-progress {
        display: none;
    }
}

.ais-RefinementList {
    &.has-images-labels {
        &.color {
            .ais-RefinementList-list {
                display: flex;
                flex-wrap: wrap;
                border: none;

                .ais-RefinementList-item {
                    margin: 5px 8px 5px 0;

                    &.ais-RefinementList-item--selected {
                        > div {
                            background-color: $primary-color;
                            box-shadow: 1px 1px 2px rgba($black,0.5) inset;

                            .label {
                                color: $white;
                            }
                        }
                    }

                    > div {
                        padding: rem-calc(9 20 9 9);
                        box-shadow: inset 0 0 0 1px $ts-color-swatch-border-color;
                        border-radius: 18px;
                        line-height: 18px;
                        font-size: 0;
                        background: $white;

                        .swatch-option.color {
                            box-shadow: 0 0 0 1px $ts-color-swatch-border-color inset;
                        }

                        .label {
                            font-size: 13px;
                            line-height: inherit;
                            margin-left: 6px;
                        }

                        .count {
                            display: none;
                        }
                    }
                }
            }
        }

        .ais-RefinementList-list,
        .ais-CurrentRefinements-list,
        .ais-HierarchicalMenu-list {
            .ais-RefinementList-item {
                display: block;
                position: relative;
                text-transform: $ts-sidebar-main-filter-text-transform;
                font-family: $ts-sidebar-main-filter-font-family;
                font-size: ($ts-sidebar-main-filter-font-size*0.87);
                font-weight: $ts-sidebar-main-filter-font-weight;
                color: $ts-sidebar-main-filter-anchor-color;
                border: none;
                padding: 0;
                cursor: pointer;

                @include breakpoint(xlarge) {
                    font-size: $ts-sidebar-main-filter-font-size;
                }

                &:last-child {
                    border-bottom: 0;
                }

                @extend %swatch-option-algolia-color;

                .swatch-option {
                    width: $ts-color-label-swatch-width;
                    height: $ts-color-label-swatch-height;
                }
                .label {
                    cursor: inherit;
                    text-decoration: $ts-sidebar-main-filter-anchor-underline;
                    margin-left: .25rem;
                    @include no-label;
                }

                &:hover {
                    color: $ts-sidebar-main-filter-anchor-color-hover;
                    background-color: $ts-sidebar-main-filter-anchor-bg-hover;
                    text-decoration: none;

                    .label {
                        text-decoration: $ts-sidebar-main-filter-anchor-underline-hover;
                    }
                }
            }
        }
    }
}
