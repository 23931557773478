.header {
	.tab-bar {
		.middle-side {
			.logo {
				display: flex;
				justify-content: center;
				align-items: flex-start;
			}
		}

		.right-side {
			.minicart-wrapper {
				opacity: initial;
			}
		}
	}
}