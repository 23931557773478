.ais-breadcrumb--root {
	display: flex;
	font-size: $breadcrumbs-item-font-size;
	.ais-breadcrumb--label {
		margin-right: $breadcrumbs-item-margin;
		font-weight: $breadcrumbs-font-weight;
		font-family: $breadcrumbs-font-family;
		text-decoration: $ts-breadcrumb-link-underline;
		div {
			display: inline-block;
			vertical-align: middle;
		}

		&:hover {
			div {
				text-decoration: $ts-breadcrumb-link-hover-underline;
			}
		}
		&:not(:last-child)::after {
			width: auto;
			height: auto;
			content: $breadcrumbs-item-slash-view;
			display: inline-block;
			vertical-align: baseline;
			position: relative;
			top: auto;
			left: auto;
			margin-left: $breadcrumbs-item-margin;
			font-size: $breadcrumbs-item-font-size;
			color: $breadcrumbs-item-color;
			background-color: transparent;
		}

		@if not $breadcrumbs-item-slash {
			$slash: '';

			&:not(:last-child)::after {
				content: $slash;
				width: 5px;
				height: 8px;
				margin: 0 $breadcrumbs-item-margin;
				background: url("#{$ts-path-to-images}/icon-breadcrumb.#{$ts-breadcrumb-icon-type}") no-repeat;
				background-size: 100% auto;
				background-color: transparent;
			}
		}

		&.ais-breadcrumb--disabledLabel {
			text-decoration: none !important;
			color: $breadcrumbs-item-color-disabled;
			cursor: text;

			div {
				text-decoration: none !important;
			}
		}
	}// label

	.ais-breadcrumb--separator {
		display: none;
	}
}// .ais-breadcrumb--root