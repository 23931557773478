.ais-root {
	&.swatches.visual,
	&.swatches.text {
		.ais-refinement-list--list {
			padding-top: $ts-ct-swatch-category-top-indent;
			padding-bottom: $ts-ct-swatch-category-bottom-indent;

			@if $ts-ct-swatch-category-top-border {
				border-top: $ts-sidebar-main-filter-border-width $ts-sidebar-main-filter-border-style $ts-sidebar-main-filter-border-color;
			} @else {
				border-top: 0;
			}

			.ais-refinement-list--item {
				display: inline-block;
				vertical-align: top;
				margin: $ts-ct-swatch-outer-indent;
				padding: $ts-ct-swatch-inner-indent;
				position: relative;
				font-size: 0;
				border:0;

				&.checked,
				&.ais-refinement-list--item__active {
					.swatch-option {
						&.color {
							&:after {
								opacity: 1;
							}
						}
						&.text {
							color: $ts-size-swatch-color-text-active;
							background-color: $ts-size-swatch-bg-color-active;
						}
					}
				}
				.swatch-option {
					cursor: pointer;
					position: relative;
					text-align: center;
					display: inline-block;
					vertical-align: middle;
				}// .swatch-option
			}
		}
	}// swatches

	&.swatches.visual {
		.ais-refinement-list--list {
			.ais-refinement-list--item {
				.swatch-option {
					&.color {
						width: $ts-color-swatch-width;
						height: $ts-color-swatch-height;
						border-radius: $ts-color-swatch-radius;
						background-color: $white;

						@if $ts-color-swatch-border {
							box-shadow: inset 0 0 0 1px $ts-color-swatch-border-color;
						}

						&:after {
							display: block;
							content: '';
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate3d(-50%, -50%, 0);
							width: 100%;
							height: 100%;
							background: url("#{$ts-path-to-images}/#{$ts-color-swatch-active-image}.#{$ts-color-swatch-active-image-type}") 50% no-repeat;
							background-size: $ts-color-swatch-active-image-width auto;
							transition: opacity .3s ease;
							opacity: 0;
						}
						&:hover,
						&:focus {
							&:before {
								.touchevents & {
									display: block;
								}
							}
						}
						&:hover,
						&:focus,
						&.selected,
						&.checked {
							&:after {
								opacity: 1;
							}
						}
						&.disabled {
							opacity: .25;
						}
						&[option-label="White"],
						&[option-label="Hvit"] {
							box-shadow: inset 0 0 0 1px rgba($black,.1);
						}
					}// color
				}// .swatch-option
			}
		}
	}// visual

	&.swatches.text {
		.ais-refinement-list--list {
			.ais-refinement-list--item {
				.swatch-option {
					&.text {
						width: auto;
						min-width: $ts-size-swatch-width;
						height: $ts-size-swatch-height;
						padding: $ts-size-swatch-text-inner-indent;
						line-height: $ts-size-swatch-height;
						font-size: $ts-size-swatch-font-size;
						font-weight: $ts-size-swatch-font-weight;
						font-family: $ts-size-swatch-font-family;
						color: $ts-size-swatch-color-text;
						box-shadow: $ts-size-swatch-box-shadow;
						background-color: $ts-size-swatch-bg-color;
						letter-spacing: $ts-size-swatch-letter-spacing;
						word-spacing: $ts-size-swatch-word-spacing;

						&:before,
						&:after {
							display: none;
						}
						&.disabled {
							&:before,
							&:after {
								display: none;
							}
						}
						&:hover,
						&:focus,
						&.selected,
						&.checked {
							color: $ts-size-swatch-color-text-active;
							background-color: $ts-size-swatch-bg-color-active;
						}
						.ais-refinement-list--count {
							@include at;
						}
					}// text
				}// .swatch-option
			}
		}
	}// text
}



