$breakpoint-h-list : (7,8,9,10,11,12);
$breakpoint-v-list : (1,2,3,4,5,6);

$cv-spacing : 1rem;

$space : ( small : 10, medium: 25, large: 50, xlarge: 80, xxlarge: 125, xxxlarge: 175 );


// Alignment
.convert-block {
	&.aligned {
		&--center {
			text-align: center;
		}
		&--right {
			text-align: right;
		}
	}
}

// Headings
.as-h1,
.as-h2,
.as-h3,
.as-h4,
.as-h5,
.as-h6 {
	font-family: $header-font-family;
	font-style: $header-font-style;
	font-weight: $header-font-weight;
	color: $header-color;
	text-rendering: $header-text-rendering;

	small {
		line-height: 0;
		color: $header-small-font-color;
	}
}

// Heading styles
@each $size, $headers in $header-styles {
	@include breakpoint($size) {
	@each $header, $header-defs in $headers {
		$font-size-temp: 1rem;
		#{$header},
		.as-#{$header} {

		@if map-has-key($header-defs, font-size) {
			$font-size-temp: rem-calc(map-get($header-defs, font-size));
			font-size: $font-size-temp;
		} @else if map-has-key($header-defs, fs) {
			$font-size-temp: rem-calc(map-get($header-defs, fs));
			font-size: $font-size-temp;
		} @else if $size == $-zf-zero-breakpoint {
			font-size: $font-size-temp;
		}
		@if map-has-key($header-defs, line-height) {
			line-height: unitless-calc(map-get($header-defs, line-height), $font-size-temp);
		} @else if map-has-key($header-defs, lh) {
			line-height: unitless-calc(map-get($header-defs, lh), $font-size-temp);
		} @else if $size == $-zf-zero-breakpoint {
			line-height: unitless-calc($header-lineheight, $font-size-temp);
		}

		@if map-has-key($header-defs, margin-top) {
			margin-top: rem-calc(map-get($header-defs, margin-top));
		} @else if map-has-key($header-defs, mt) {
			margin-top: rem-calc(map-get($header-defs, mt));
		} @else if $size == $-zf-zero-breakpoint {
			margin-top: 0;
		}
		@if map-has-key($header-defs, margin-bottom) {
			margin-bottom: rem-calc(map-get($header-defs, margin-bottom));
		} @else if map-has-key($header-defs, mb) {
			margin-bottom: rem-calc(map-get($header-defs, mb));
		} @else if $size == $-zf-zero-breakpoint {
			margin-bottom: rem-calc($header-margin-bottom);
		}
		}
	}
	}
}

.img-responsive {
	display: inline-block;
	max-width: 100%;
}

.convert-column {
	min-height: 1px;
}

.row.row-fluid {
	max-width: none;

	.img-responsive {
		margin-left: auto;
		margin-right: auto;
		display: block;
	}


	&.convert-grid-row.expanded {
		@media screen and (min-width: 1900px) {
			max-width: 1920px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

@function to-string($value) {
	@return inspect($value);
}

@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

$gutters : (0.3,0.5,2,4,8,12,16,20);

.row.gutters {
	@each $gutter in $gutters {
		@if ($gutter < 8) and ($gutter > 1) {
			.row &-#{$gutter}x {
				@include breakpoint(large only) {
					margin-left: -(map-get($grid-column-gutter, medium)) * ($gutter / 2) * 0.3;
					margin-right: -(map-get($grid-column-gutter, medium)) * ($gutter / 2) * 0.3;
				}

				@include breakpoint(xlarge only) {
					margin-left: -(map-get($grid-column-gutter, medium)) * ($gutter / 2) * 0.5;
					margin-right: -(map-get($grid-column-gutter, medium)) * ($gutter / 2) * 0.5;
				}

				@include breakpoint(xxlarge only) {
					margin-left: -(map-get($grid-column-gutter, medium)) * ($gutter / 2);
					margin-right: -(map-get($grid-column-gutter, medium)) * ($gutter / 2);
				}
			} // .row
		} @elseif ($gutter < 1) {
			.row &-#{str-replace(to-string($gutter),".","")}x {
				margin-left: -(map-get($grid-column-gutter, medium)) * ($gutter / 2);
				margin-right: -(map-get($grid-column-gutter, medium)) * ($gutter / 2);
			}
		} // @if

		@if ($gutter > 1) {
			&-#{$gutter}x {
				> .column,
				> .columns {
					@include breakpoint(large only) {
						padding-left: map-get($grid-column-gutter, medium) * ($gutter / 2) * 0.3;
						padding-right: map-get($grid-column-gutter, medium) * ($gutter / 2) * 0.3;
					}

					@include breakpoint(xlarge only) {
						padding-left: map-get($grid-column-gutter, medium) * ($gutter / 2) * 0.5;
						padding-right: map-get($grid-column-gutter, medium) * ($gutter / 2) * 0.5;
					}

					@include breakpoint(xxlarge) {
						padding-left: map-get($grid-column-gutter, medium) * ($gutter / 2);
						padding-right: map-get($grid-column-gutter, medium) * ($gutter / 2);
					}
				}
			} // .row
		} @else {
			&-#{str-replace(to-string($gutter),".","")}x {
				.column,
				.columns {
					padding-left: map-get($grid-column-gutter, medium) * ($gutter / 2);
					padding-right: map-get($grid-column-gutter, medium) * ($gutter / 2);
				}
			} // .row
		} // @if
	} // @each
}

@each $size, $headers in $header-styles {
	@include breakpoint($size) {
	@each $header, $header-defs in $headers {
		$font-size-temp: 1rem;
		.as-#{$header} {

		@if map-has-key($header-defs, font-size) {
			$font-size-temp: rem-calc(map-get($header-defs, font-size));
			font-size: $font-size-temp;
		} @else if map-has-key($header-defs, fs) {
			$font-size-temp: rem-calc(map-get($header-defs, fs));
			font-size: $font-size-temp;
		} @else if $size == $-zf-zero-breakpoint {
			font-size: $font-size-temp;
		}
		@if map-has-key($header-defs, line-height) {
			line-height: unitless-calc(map-get($header-defs, line-height), $font-size-temp);
		} @else if map-has-key($header-defs, lh) {
			line-height: unitless-calc(map-get($header-defs, lh), $font-size-temp);
		} @else if $size == $-zf-zero-breakpoint {
			line-height: unitless-calc($header-lineheight, $font-size-temp);
		}

		@if map-has-key($header-defs, margin-top) {
			margin-top: rem-calc(map-get($header-defs, margin-top));
		} @else if map-has-key($header-defs, mt) {
			margin-top: rem-calc(map-get($header-defs, mt));
		} @else if $size == $-zf-zero-breakpoint {
			margin-top: 0;
		}
		@if map-has-key($header-defs, margin-bottom) {
			margin-bottom: rem-calc(map-get($header-defs, margin-bottom));
		} @else if map-has-key($header-defs, mb) {
			margin-bottom: rem-calc(map-get($header-defs, mb));
		} @else if $size == $-zf-zero-breakpoint {
			margin-bottom: rem-calc($header-margin-bottom);
		}
		}
	}
	}
}

.convert-block.spacing,
.convert-grid-row.spacing,
.convert-grid-row-flex.spacing {
	@each $size, $value in $space {
		&-#{$size} {
			margin-bottom: rem-calc($value);

			@include breakpoint(medium down) {
				margin-bottom: rem-calc(0.5 * $value);
			}

			@include breakpoint(large only) {
				margin-bottom: rem-calc(0.7 * $value);
			}

			@include breakpoint(xlarge only) {
				margin-bottom: rem-calc(0.8 * $value);
			}
		}
	}

	&-none,
	&-fixed-03x,
	&-fixed-05x {
		.convert-responsive-image {
			@include breakpoint(medium) {
				margin-bottom: 0;
			}
		}
	}

	&-none {
		margin-bottom: 0 !important;
	}

	&-fixed-03x {
		margin-bottom: map-get($grid-column-gutter, medium) * nth($gutters,1);
	}

	&-fixed-05x {
		margin-bottom: map-get($grid-column-gutter, medium) * nth($gutters,2);
	}
}

.convert-block.padding-default.padding,
.convert-grid-row-flex.padding-default.padding {
	@each $size, $value in $space {
		&-#{$size} {
			padding: rem-calc($value);

			@include breakpoint(medium down) {
				padding: rem-calc(0.5 * $value);
			}

			@include breakpoint(xlarge only) {
				padding: rem-calc(0.7 * $value);
			}

			@include breakpoint(xxlarge only) {
				padding: rem-calc(0.8 * $value);
			}
		}
	}

	&-fixed-03x {
		padding: map-get($grid-column-gutter, medium) * (nth($gutters,1) / 2);
	}

	&-fixed-05x {
		padding: map-get($grid-column-gutter, medium) * (nth($gutters,2) / 2);
	}
}

.convert-block.padding-vertical.padding,
.convert-grid-row.padding-vertical.padding,
.convert-grid-row-flex.padding-vertical.padding {
	@each $size, $value in $space {
		&-#{$size} {
			padding-top: rem-calc($value);
			padding-bottom: rem-calc($value);

			@include breakpoint(medium down) {
				padding-top: rem-calc(0.5 * $value);
				padding-bottom: rem-calc(0.5 * $value);
			}

			@include breakpoint(large only) {
				padding-top: rem-calc(0.7 * $value);
				padding-bottom: rem-calc(0.7 * $value);
			}

			@include breakpoint(xlarge only) {
				padding-top: rem-calc(0.8 * $value);
				padding-bottom: rem-calc(0.8 * $value);
			}
		}
	}

	&-none {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	&-fixed-03x {
		padding-top: map-get($grid-column-gutter, medium) * (nth($gutters,1) / 2);
		padding-bottom: map-get($grid-column-gutter, medium) * (nth($gutters,1) / 2);
	}

	&-fixed-05x {
		padding-top: map-get($grid-column-gutter, medium) * (nth($gutters,2) / 2);
		padding-bottom: map-get($grid-column-gutter, medium) * (nth($gutters,2) / 2);
	}
}

.convert-block.padding-horizontal.padding,
.convert-grid-row-flex.padding-horizontal.padding {
	@each $size, $value in $space {
		&-#{$size} {
			padding-left: rem-calc($value);
			padding-right: rem-calc($value);

			@include breakpoint(medium down) {
				padding-left: rem-calc(0.5 * $value);
				padding-right: rem-calc(0.5 * $value);
			}

			@include breakpoint(xlarge only) {
				padding-left: rem-calc(0.7 * $value);
				padding-right: rem-calc(0.7 * $value);
			}

			@include breakpoint(xxlarge only) {
				padding-left: rem-calc(0.8 * $value);
				padding-right: rem-calc(0.8 * $value);
			}
		}
	}

	&-none {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	&-fixed-03x {
		padding-left: map-get($grid-column-gutter, medium) * (nth($gutters,1) / 2);
		padding-right: map-get($grid-column-gutter, medium) * (nth($gutters,1) / 2);
	}

	&-fixed-05x {
		padding-left: map-get($grid-column-gutter, medium) * (nth($gutters,2) / 2);
		padding-right: map-get($grid-column-gutter, medium) * (nth($gutters,2) / 2);
	}
}

.convert-title-h {
	&.xxxlarge {
		font-size: $h1-font-size;
	}

	&.xxlarge {
		font-size: $h2-font-size;
	}

	&.xlarge {
		font-size: $h3-font-size;
	}

	&.medium {
		font-size: $h4-font-size;
	}

	&.small {
		font-size: $h5-font-size;
	}
}

.spacing {

	&-small {
		margin-bottom: 0.25 * $cv-spacing;
	}

	&-medium {
		margin-bottom: 0.5 * $cv-spacing;
	}

	&-xlarge {
		margin-bottom: 1.25 * $cv-spacing;
	}

	&-xxlarge {
		margin-bottom: 1.5 * $cv-spacing;
	}

	&-xxxlarge {
		margin-bottom: 2 * $cv-spacing;
	}

}

.convert-grid-row {
	> .convert-column:not(:last-child) {
		> .convert-article {
			@include breakpoint(small only) {
				margin-bottom: $cv-spacing * 2.5;
			}
		}
	}
}

.convert-articles {
	.convert-article {
		margin-bottom: $cv-spacing * 1.5;

		@include breakpoint(large) {
			&.large-12 {
				clear: both;
			}

			&.large-6:nth-of-type(2n+1) {
				clear: both;
			}

			&.large-4:nth-of-type(3n+1) {
				clear: both;
			}
		}
	}
}

@include breakpoint(medium) {
	.convert-image-w-text {
		position: relative;
		.convert-image-w-text-image {
			display: block;
			width: 100%;
			margin: 0;

			img {
				max-width: inherit;
				width: 100%;
			}
		}
		.convert-image-w-text-content {
			position: absolute;
			margin: 0;
		}
		&.x-left .convert-image-w-text-content {
			left: 1.875rem;
		}
		&.x-center .convert-image-w-text-content {
			left: 50%;
			transform: translateX(-50%);
			text-align: center;

			p {
				text-align: center;
			}
		}
		&.x-right .convert-image-w-text-content {
			right: 1.875rem;
		}
		&.y-top .convert-image-w-text-content {
			top: 1.875rem;
		}
		&.y-center .convert-image-w-text-content {
			top: 50%;
		}
		&.y-bottom .convert-image-w-text-content {
			bottom: 1.875rem;
		}
		&.x-center.y-center .convert-image-w-text-content {
			transform: translateX(-50%) translateY(-50%);
		}
	}
}

.convert-image-w-text-content[style] {
	.convert-title,
	.convert-wysiwyg {
		color: inherit;
	}
}