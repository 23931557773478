span.preview-uploaded-image {
    background-repeat: no-repeat;
    display: inline-block;
    width: 150px;
    height: 150px;
}
span.preview-type-bmp {
    background-image: url("#{$ts-path-to-images}/preview-bmp.png");
}
span.preview-type-unknown {
    background-image: url("#{$ts-path-to-images}/preview-unknown.png");
}
span.preview-type-svg {
    background-image: url("#{$ts-path-to-images}/preview-svg.png");
}
span.preview-type-ai {
    background-image: url("#{$ts-path-to-images}/preview-ai.png");
}
span.preview-type-tiff {
    background-image: url("#{$ts-path-to-images}/preview-tiff.png");
}
span.preview-type-eps {
    background-image: url("#{$ts-path-to-images}/preview-eps.png");
}
span.preview-type-ps {
    background-image: url("#{$ts-path-to-images}/preview-ps.png");
}
span.preview-type-pdf {
    background-image: url("#{$ts-path-to-images}/preview-pdf.png");
}
